import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import useRequest from 'hooks/useRequest';

import endpoints from 'services/endpoints';
import { GetMemberResponse } from 'services/memberService/types';

import * as types from './types';

const MemberContext = createContext<types.ContextData | null>(null);

function MemberProvider({ children }: types.ProviderData) {
  const { id } = useParams<{ id: string }>();
  const {
    data: membersData,
    loading,
    error
  } = useRequest<GetMemberResponse>({
    url: queryString.stringifyUrl({
      url: id ? endpoints.member.replace('{memberId}', id) : endpoints.member
    })
  });

  return (
    <MemberContext.Provider
      value={{
        member: membersData,
        loading,
        error
      }}
    >
      {children}
    </MemberContext.Provider>
  );
}

function useMember(): types.ContextData {
  const context = useContext(MemberContext);

  if (!context)
    throw new Error('useMember must be used within an MemberProvider');

  return context;
}

export { useMember, MemberProvider, MemberContext };
