import PATHS_ROUTES from 'routes/paths';
import { Row, Column } from 'styles/shared/Grid';
import { CardLink, TemplateSearchContent } from 'components';

import { accounts } from './__mocks__/accounts';
import { ReactComponent as ArrowIcon } from 'assets/images/icon-big-arrow-right.svg';

const breadcrumbProps = {
  links: [
    {
      title: 'Minhas contas',
      link: PATHS_ROUTES.APP.ACCOUNTS.DEFAULT
    },
    {
      title: 'Contas',
      link: PATHS_ROUTES.APP.ACCOUNTS.ACCOUNTS
    }
  ]
};

function Accounts() {
  return (
    <TemplateSearchContent
      breadcrumbProps={breadcrumbProps}
      inputSearchProps={{
        placeholder: 'Digite nome ou CNPJ'
      }}
      paginationNavProps={{
        previousAction: () => {},
        nextAction: () => {}
      }}
    >
      <Row>
        {accounts.map(account => (
          <Column key={account.id} lg={4}>
            <CardLink
              to={PATHS_ROUTES.APP.ACCOUNTS.EDIT(account.id)}
              title={account.title}
              subtitle={`CNPJ: ${account.documentNumber}`}
            >
              <ArrowIcon />
            </CardLink>
          </Column>
        ))}
      </Row>
    </TemplateSearchContent>
  );
}

export default Accounts;
