import { UserData } from 'services/userService/types';

const STORAGE_KEY_LOGGED = '@AmePlus:logged';
const STORAGE_KEY_USERDATA = '@AmePlus:userData';
const STORAGE_KEY_USER_PERMISSIONS = '@AmePlus:userPermissions';
const STORAGE_KEY_IS_SUBACCOUNT = '@AmePlus:isSubaccount';
const STORAGE_KEY_SUBACCOUNT_SCOPES = '@AmePlus:subaccountScopes';
const STORAGE_THEME = '@AmePlus:theme';
const STORAGE_KEY_PIX_SETTINGS = '@AmePlus:pixSettings';

type SessionType = {
  logged: boolean | null;
  isSubaccount: boolean;
  subaccountScopes: string[];
  userData: UserData | null;
  userPermissions: string[] | null;
  theme: 'light' | 'dark';
};

/**
 * Return user session
 * @returns {(object|null)}
 */
export function getSessionStorage(): SessionType {
  const logged = localStorage.getItem(STORAGE_KEY_LOGGED);
  const isSubaccount = localStorage.getItem(STORAGE_KEY_IS_SUBACCOUNT);
  const scopes = localStorage.getItem(STORAGE_KEY_SUBACCOUNT_SCOPES);
  const userData = localStorage.getItem(STORAGE_KEY_USERDATA);
  const userPermissions = localStorage.getItem(STORAGE_KEY_USER_PERMISSIONS);
  const theme = localStorage.getItem(STORAGE_THEME);

  return {
    logged: !!logged,
    isSubaccount: !!isSubaccount,
    subaccountScopes: scopes ? JSON.parse(scopes) : [],
    userData: userData ? JSON.parse(userData) : null,
    userPermissions: userPermissions ? JSON.parse(userPermissions) : null,
    theme: theme === 'dark' ? 'dark' : 'light'
  };
}

export function hasSessionStorage() {
  return !!localStorage.getItem(STORAGE_KEY_LOGGED);
}

export function setUserData(userData: UserData) {
  localStorage.setItem(STORAGE_KEY_USERDATA, JSON.stringify(userData));
}

export function setPermissionsData(permissions: string[]) {
  localStorage.setItem(
    STORAGE_KEY_USER_PERMISSIONS,
    JSON.stringify(permissions)
  );
}

export function setLogged(logged: boolean) {
  localStorage.setItem(STORAGE_KEY_LOGGED, String(logged));
}

export function setSubAccount(isSubaccount: boolean, scopes?: string[]) {
  const subaccountScopes = scopes || [];
  localStorage.setItem(STORAGE_KEY_IS_SUBACCOUNT, String(isSubaccount));
  localStorage.setItem(
    STORAGE_KEY_SUBACCOUNT_SCOPES,
    JSON.stringify(subaccountScopes)
  );
}

export function setTheme(theme: 'light' | 'dark') {
  localStorage.setItem(STORAGE_THEME, theme);
}

export function removeSessionStorage() {
  localStorage.removeItem(STORAGE_KEY_LOGGED);
  localStorage.removeItem(STORAGE_KEY_IS_SUBACCOUNT);
  localStorage.removeItem(STORAGE_KEY_SUBACCOUNT_SCOPES);
  localStorage.removeItem(STORAGE_KEY_USERDATA);
  localStorage.removeItem(STORAGE_KEY_USER_PERMISSIONS);
}

export interface Settings {
  pix: {
    tutorial: boolean;
    brCode: boolean;
  };
}

export function getPixSettings(userId: string): Settings {
  const settings = localStorage.getItem(
    `${STORAGE_KEY_PIX_SETTINGS}:${userId}`
  );

  if (!settings) {
    return {
      pix: {
        tutorial: false,
        brCode: false
      }
    };
  }

  return JSON.parse(settings);
}

export function setPixSettings(userId: string, settings: Settings) {
  localStorage.setItem(
    `${STORAGE_KEY_PIX_SETTINGS}:${userId}`,
    JSON.stringify(settings)
  );
}
