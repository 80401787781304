import { BreadcrumbLink } from 'components/Breadcrumb';
import PATHS_ROUTES from 'routes/paths';

import { claimTypes, claimItensTypes } from 'utils/pix';

export const breadcrumbItems = (requestType: claimTypes): BreadcrumbLink[] => {
  const links: BreadcrumbLink[] = [
    {
      link: PATHS_ROUTES.APP.PIX.DEFAULT,
      title: 'Pix'
    },
    {
      link: PATHS_ROUTES.APP.PIX.KEYS.DEFAULT,
      title: 'Minhas chaves'
    }
  ];

  if (requestType === claimItensTypes.SENT)
    links.push({
      link: '#',
      title: 'Solicitações que enviei'
    });
  else
    links.push({
      link: '#',
      title: 'Solicitações que recebi'
    });

  return links;
};

export const STEP_ITENS = {
  INFO: 'info',
  CONFIRM_SUCCESS: 'confirm_success',
  CANCEL_SUCCESS: 'cancel_success',
  FAIL: 'fail'
};
