import {
  Button,
  Text,
  TemplateModal,
  Flex,
  InputSearch,
  CardInfo
} from 'components';

import Divider from 'styles/shared/Divider';

import iconAlert from 'assets/images/icon-alert.svg';
import { GroupAccount } from 'services/groupService';
import { maskDocument, maskDynamicDocument } from 'utils/masks';
import useMutate from 'hooks/useMutate';
import endpoints from 'services/endpoints';
import { useState } from 'react';

import iconError from 'assets/images/icon-error.svg';

type Props = {
  onClose: () => void;
  groups: GroupAccount[];
};

type ListGroupProps = {
  groups: GroupAccount[];
  onChangeGroup: (group: GroupAccount) => void;
};

type ConfirmProps = {
  onCancel: () => void;
  onChangeAccount: () => void;
  group: GroupAccount;
};

const ListGroups = ({ groups, onChangeGroup }: ListGroupProps) => {
  return (
    <>
      <Text align="center" tag="h2" size="xxlarge" weight="medium">
        Selecione a conta
      </Text>

      <Divider size="large" />

      <InputSearch value="" placeholder="Busque a conta desejada" />

      <Divider />

      <Text tag="h3" size="xxmedium" weight="bold">
        Contas
      </Text>

      {groups.map(group => (
        <CardInfo
          key={group.id}
          text={group.name}
          action={() => onChangeGroup(group)}
          infoText="Selecionar"
        >
          <Text color="grayNeutralDark" size="xxsmall">
            CNPJ: {maskDocument(group.document, 'CNPJ')}
          </Text>
        </CardInfo>
      ))}
    </>
  );
};

const Confirm = ({ onChangeAccount, onCancel, group }: ConfirmProps) => (
  <TemplateModal title="">
    <Flex justifyContent="center">
      <img src={iconAlert} height="90rem" alt="Alerta" />
    </Flex>

    <Text align="center" tag="h2" size="xxlarge" weight="medium">
      Você irá gerenciar a conta:
    </Text>

    <Divider size="large" />

    <Text align="center" size="xmedium" weight="medium">
      {group.name}
    </Text>

    <Text align="center" size="medium" color="grayNeutralDark">
      CNPJ: {maskDynamicDocument(group.document)}
    </Text>

    <Divider size="large" />

    <Text align="center" size="xxmedium">
      Todas as movimentações serão atribuidas a essa conta. Deseja continuar?
    </Text>

    <Divider size="large" />

    <Flex justifyContent="center">
      <Button filled onClick={onChangeAccount}>
        Gerenciar
      </Button>

      <Divider />

      <Button secondary onClick={onCancel}>
        Cancelar
      </Button>
    </Flex>

    <Divider size="large" />
  </TemplateModal>
);

const Error = ({
  message,
  onBack
}: {
  message?: string;
  onBack: () => void;
}) => {
  return (
    <>
      <Text tag="h2" size="large" weight="medium" align="center">
        {message || 'Ocorreu um erro inesperado'}
      </Text>

      <Flex justifyContent="center">
        <img width={250} src={iconError} alt="Erro" />
      </Flex>

      <Divider />

      <Button center onClick={onBack}>
        Tentar novamente
      </Button>
    </>
  );
};

const ModalChangeAccount = ({ groups, onClose }: Props) => {
  const [errorMessage, setError] = useState<string | null>(null);
  const [group, setGroup] = useState<GroupAccount | null>(null);
  const { mutate } = useMutate();

  const handleChangeAccount = () => {
    if (!group) return;

    mutate({
      endpoint: endpoints.impersonateByGroup.replace('{groupId}', group.id),
      errorMessage: '',
      onSuccess: () => {
        window.location.reload();
      },
      onFailure: () => {
        setError('');
      }
    });
  };

  const getContent = () => {
    if (errorMessage !== null) {
      return <Error message={errorMessage} onBack={() => setError('')} />;
    }

    if (group) {
      return (
        <Confirm
          group={group}
          onChangeAccount={handleChangeAccount}
          onCancel={() => setGroup(null)}
        />
      );
    }

    return <ListGroups groups={groups} onChangeGroup={setGroup} />;
  };

  return (
    <TemplateModal title="" closeModal={onClose}>
      {getContent()}
    </TemplateModal>
  );
};

export default ModalChangeAccount;
