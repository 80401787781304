import React, { SVGProps } from 'react';

import { ReportType } from 'services/reportService/types';

import { ReactComponent as IconDownloadGray } from 'assets/images/icon-download-gray.svg';
import { ReactComponent as IconDownloadBlue } from 'assets/images/icon-download-all-blue.svg';
import { ThemeColors } from 'types/global';

export const periodsInDays = [
  { value: 1, description: 'Ontem' },
  { value: 0, description: 'Hoje' },
  { value: 7, description: 'Últimos 7 dias' },
  { value: 15, description: 'Últimos 15 dias' },
  { value: 30, description: 'Últimos 30 dias' }
];

export enum REPORT_STATUS {
  REQUESTED = 'REQUESTED',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR'
}

type translateStatusReturn = {
  status: string;
  description: string;
  icon: React.FC<SVGProps<SVGSVGElement>>;
  color: ThemeColors;
};

export const translateStatus = (
  statusReport: string
): translateStatusReturn => {
  let description;
  let status;
  let icon;
  let color;

  switch (statusReport) {
    case REPORT_STATUS.REQUESTED:
      status = REPORT_STATUS.REQUESTED;
      description = 'Solicitado';
      color = 'orangeCategoryWaitingProcessing' as ThemeColors;
      icon = IconDownloadGray;
      break;
    case REPORT_STATUS.PROCESSED:
      status = REPORT_STATUS.PROCESSED;
      description = 'Concluído';
      color = 'blue' as ThemeColors;
      icon = IconDownloadBlue;
      break;
    default:
      status = REPORT_STATUS.ERROR;
      description = 'Erro';
      color = 'red' as ThemeColors;
      icon = IconDownloadGray;
  }

  return {
    status,
    description,
    icon,
    color
  };
};

export enum ReportTypes {
  SALES = 'SALES',
  FINANCIAL = 'FINANCIAL',
  REFUND = 'REFUND',
  CASHOUT = 'CASHOUT'
}

export const REPORT_TYPES_LABELS = {
  SALES: 'Vendas',
  FINANCIAL: 'Liquidações',
  REFUND: 'Estornos',
  CASHOUT: 'Retiradas'
};

export const translateTypes = (type: ReportType) => REPORT_TYPES_LABELS[type];
