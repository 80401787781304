import 'react-circular-progressbar/dist/styles.css';
import * as S from './styles';

export type ProgressBarProps = {
  value: number;
};

const ProgressBar = ({ value }: ProgressBarProps) => (
  <S.Wrapper value={value} />
);

export default ProgressBar;
