import styled, { css, DefaultTheme } from 'styled-components';
import customMedia from 'styles/media-query';
import ReactSelect from 'react-select';

import { Input, Button } from 'components';

import { ReactComponent as IconRightNext } from 'assets/images/icon-right-next.svg';

type WrapperProps = {
  withMargin?: boolean;
  disabled?: boolean;
};

type LabelProps = {
  floating?: boolean;
};

type SelectProps = {
  noBorder?: boolean;
};

const wrapperModifiers = {
  withMargin: (theme: DefaultTheme) => css`
    margin-bottom: ${theme.spacings.medium};
  `,

  disabled: () => css`
    cursor: not-allowed;
  `
};

export const Wrapper = styled.div<WrapperProps>`
  margin-bottom: 0;

  ${({ theme, withMargin, disabled }) => css`
    ${withMargin && wrapperModifiers.withMargin(theme)};
    ${disabled && wrapperModifiers.disabled()};
  `}
`;

const labelModifiers = {
  floating: (theme: DefaultTheme) => css`
    top: 0;
    color: ${theme.colors.grayBlue};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.weights.medium};
  `
};

export const Label = styled.label<LabelProps>`
  ${({ theme, floating }) => css`
    display: block;
    color: ${theme.colors.grayNeutralDark};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.weights.medium};
    line-height: ${theme.font.lineHeight('xsmall')};
    position: relative;
    top: 2.6rem;

    ${floating && labelModifiers.floating(theme)}
  `}
`;

const selectModifiers = {
  noBorder: () => css`
    .${CLASS_NAME_PREFIX} {
      &__control {
        border-bottom: 0;
      }
    }
  `
};

const CLASS_NAME_PREFIX = 'ame-select';

export const Select = styled(ReactSelect).attrs({
  classNamePrefix: CLASS_NAME_PREFIX
})<SelectProps>`
  ${({ theme, noBorder }) => css`
    color: ${theme.colors.blackDark};
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xmedium};
    outline: none;
    display: none;

    ${customMedia.greaterThan('medium')`
      display: block;
    `}

    .${CLASS_NAME_PREFIX} {
      &__control {
        border: 0;
        box-shadow: none;
        background: ${theme.colors.transparent};
        border-radius: 0;
        border-bottom: 2px solid ${theme.colors.grayMedium};
        position: relative;
        padding-left: 0;
        cursor: pointer;

        &:hover {
          border-color: ${theme.colors.grayMedium};
        }

        :focus {
          outline: none;
        }

        &--is-disabled {
          cursor: not-allowed;
          border-bottom: 0;
        }
      }

      &__value-container {
        padding: 0;
      }

      &__single-value {
        margin: 0;
      }

      &__menu {
        border: none;
        border-radius: 0;
        margin-top: 0;
      }

      &__menu-list {
        color: ${theme.colors.black};
      }

      &__option {
        padding: ${theme.spacings.medium} ${theme.spacings.small};
        border-bottom: 2px solid ${theme.colors.grayMedium};
        cursor: pointer;

        &:last-child {
          border-bottom: 0;
        }

        &--is-selected {
          color: ${theme.colors.blueLight};
          background-color: ${theme.colors.transparent};
        }

        &--is-focused,
        &:hover {
          background-color: ${theme.colors.grayMedium};
        }
      }

      &__indicator {
        color: ${theme.colors.grayIcon};

        &-separator {
          background: none;
        }
      }
    }

    ${noBorder && selectModifiers.noBorder()};
  `}
`;

export const SelectMobileWrapper = styled.div`
  display: block;

  ${customMedia.greaterThan('medium')`
    display: none;
  `}
`;

export const SelectMobile = styled(Input)`
  ${({ theme }) => css`
    cursor: pointer;
    border-bottom: 2px solid ${theme.colors.grayMedium};
  `}
`;

export const MobileView = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    background: ${theme.colors.grayLight};
    z-index: ${theme.layers.overlay};
    padding: ${theme.spacings.medium};
    width: 100vw;
    height: 100vh;
    display: block;
    overflow-y: scroll;

    ${customMedia.greaterThan('medium')`
      display: none;
    `};
  `}
`;

export const MobileOption = styled(Button)`
  ${({ theme }) => css`
    border-radius: 0;
    text-align: left;
    color: ${theme.colors.blackDark};
    border-top: 0.1rem solid ${theme.colors.grayMedium};
    justify-content: flex-start;

    &:last-child {
      border-bottom: 0.1rem solid ${theme.colors.grayMedium};
    }

    &:hover {
      color: ${theme.colors.blackDark};
    }
  `}
`;

export const IconBackArrow = styled(IconRightNext)`
  transform: rotate(180deg);
`;
