import {
  AvatarList,
  Button,
  CardInfo,
  CardSmallClosed,
  Flex,
  InputSearch,
  Loading,
  SegmentControl,
  Text
} from 'components';

import Divider from 'styles/shared/Divider';
import * as S from './style';
import useRequest from 'hooks/useRequest';
import endpoints from 'services/endpoints';
import { maskDocument } from 'utils/masks';
import { useState } from 'react';
import { useReport } from 'contexts/Report';

type ReportStep1Props = {
  next: () => void;
};

type Group = {
  id: string;
  document: string;
  groupName: string;
};

const groups = [
  {
    name: 'Parada Certa'
  },
  {
    image:
      'https://media.istockphoto.com/photos/smiling-beard-guy-picture-id846244736?k=6&m=846244736&s=612x612&w=0&h=XoKUB-pW6VH9vUIvHbqh2j-6Ew3lvGKc4fWC1riWsNY=',
    name: 'Via Gás'
  },
  {
    name: 'G&G'
  },
  {
    image:
      'https://media.istockphoto.com/photos/smiling-beard-guy-picture-id846244736?k=6&m=846244736&s=612x612&w=0&h=XoKUB-pW6VH9vUIvHbqh2j-6Ew3lvGKc4fWC1riWsNY=',
    name: 'Posto Express'
  },
  {
    name: 'Abastece Mais'
  },
  {
    name: 'Combustível e Conveniência'
  }
];

export function ReportStep1({ next }: ReportStep1Props) {
  const { reportStateData, handleSetReportStateData } = useReport();
  const [groupsSelected, setGroupsSelected] = useState(
    reportStateData.groups || []
  );
  const [accountsSelected, setAccountsSelected] = useState(
    reportStateData.accounts || []
  );
  const [selectedItem, setSelectedItem] = useState<'accounts' | 'groups'>(
    'accounts'
  );
  const { data, loading, error } = useRequest<Group>({
    url: endpoints.group,
    options: { revalidateOnFocus: false }
  });

  const items = [
    {
      label: 'Contas',
      action: () => setSelectedItem('accounts'),
      active: selectedItem === 'accounts'
    },
    {
      label: 'Grupo',
      action: () => setSelectedItem('groups'),
      active: selectedItem === 'groups'
    }
  ];

  const handleSelectCardItem = (item: Group) => {
    if (selectedItem === 'accounts') {
      setAccountsSelected(accounts => [...accounts, item.id]);
    } else {
      setGroupsSelected(groups => [...groups, item.id]);
    }
  };

  const handleClickButton = () => {
    handleSetReportStateData({
      accounts: accountsSelected,
      groups: groupsSelected
    });
    next();
  };

  if (loading) return <Loading />;

  if (error) return <Text>Erro ao listar grupos e contas </Text>;

  return (
    <S.Wrapper>
      <Text size="xxmedium" color="blackDark">
        Novo relatório
      </Text>
      <Text color="grayNeutralDark">
        Selecione as contas que deseja gerar o relatório
      </Text>

      <Divider />

      <SegmentControl items={items} small />

      <Divider />

      <Flex>
        {accountsSelected.length ? (
          <CardSmallClosed
            text="30 Contas"
            action={() => setAccountsSelected([])}
          />
        ) : (
          ''
        )}

        <Divider direction="horizontal" size="small" />

        {groupsSelected.length ? (
          <CardSmallClosed
            text="30 Grupos"
            action={() => setGroupsSelected([])}
          />
        ) : (
          ''
        )}
      </Flex>

      <InputSearch
        placeholder="Pesquise o nome ou CNPJ"
        value="2"
        onChange={() => {}}
      />

      <Divider size="small" />

      {selectedItem === 'accounts' ? (
        <CardInfo
          text={data?.groupName as string}
          action={() => handleSelectCardItem(data as Group)}
          infoText="Selecionar"
          active={!!accountsSelected.find(it => it === data?.id)}
        >
          <Text color="grayNeutralDark" size="xxsmall">
            CNPJ: {maskDocument(data?.document as string, 'CNPJ')}
          </Text>
        </CardInfo>
      ) : (
        <CardInfo
          text={'Grupo Lojas Americanas'}
          active={!!groupsSelected.find(grp => grp === '1')}
          rightContent={<AvatarList small items={groups} />}
          action={() =>
            handleSelectCardItem({
              id: '1',
              groupName: 'Grupo Lojas Americanas',
              document: '1223'
            })
          }
        />
      )}

      <Flex ml="xlarge" mr="xlarge" mt="xlarge">
        <Button
          center
          onClick={handleClickButton}
          fullWidth
          disabled={!groupsSelected.length && !accountsSelected.length}
        >
          Confirmar
        </Button>
      </Flex>
    </S.Wrapper>
  );
}
