import styled, { css } from 'styled-components';

export const ProfilePictureWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacings.medium};
    margin-bottom: ${theme.spacings.medium};

    & > * {
      margin-bottom: ${theme.spacings.small};
    }
  `}
`;

export const CustomCardLink = styled.a`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: ${theme.shadow.default};
    border-radius: 1rem;
    background: ${theme.colors.white};
    padding: ${theme.spacings.medium};
    transition: all 0.5s ease;

    svg {
      margin-bottom: ${theme.spacings.xsmall};
    }

    &:hover {
      box-shadow: ${theme.shadow.hover};
    }
  `}
`;
