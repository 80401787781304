import styled, { css } from 'styled-components';
import { Input } from 'components';

export const InputText = styled(Input)`
  ${({ theme }) => css`
    color: ${theme.colors.grayNeutralDark};

    &::placeholder {
      color: ${theme.colors.grayNeutralDark};
    }
  `}
`;
