import styled, { css, DefaultTheme } from 'styled-components';

export type CardProps = {
  active?: boolean;
  contentCenter?: boolean;
};

const CardModifiers = {
  contentCenter: () => css`
    text-align: center;
  `,

  active: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.blue};
    color: ${theme.colors.white};
    background: ${theme.colors.blue};
  `
};

export const Card = styled.div<CardProps>`
  ${({ theme, active, contentCenter }) => css`
    position: relative;
    width: 100%;
    box-shadow: ${theme.shadow.default};
    border-radius: 1rem;
    background: ${theme.mode === 'light'
      ? theme.colors.white
      : theme.colors.blackDark};
    padding: ${theme.spacings.medium};
    margin-bottom: ${theme.spacings.medium};
    transition: all 0.5s ease;

    &:hover {
      box-shadow: ${theme.shadow.hover};
    }

    ${contentCenter && CardModifiers.contentCenter()}
    ${active && CardModifiers.active(theme)}
  `}
`;

export default Card;
