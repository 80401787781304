import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import { buildMargin, MarginProps } from 'styles/shared/Margin';

import { BannerProps } from '.';

export const Wrapper = styled.div<MarginProps>`
  ${({ theme, ...props }) => css`
    position: relative;

    ${buildMargin({ theme, ...props })}
  `}
`;

export const BannerLink = styled(Link)<BannerProps>`
  ${({ theme, width, height, image }) => css`
    display: block;
    border-radius: ${theme.border.smallRadius};
    width: ${width};
    height: ${height};
    background-image: url(${image});
    background-position: center center;
    background-size: cover;
    transition: all 1s ease;
    box-shadow: ${theme.shadow.default};

    &:hover {
      background-position: center 47%;
      box-shadow: ${theme.shadow.hover};
    }
  `}
`;

export const CloseWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacings.xsmall};
    right: ${theme.spacings.small};
    color: ${theme.colors.white};
  `}
`;
