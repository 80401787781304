import styled, { css, DefaultTheme } from 'styled-components';

import { Card } from 'styles/shared/Card';

type CardWrapperProps = {
  isClicked?: boolean;
  isActive?: boolean;
  large?: boolean;
  disabled?: boolean;
};

const modifierCardWrapper = {
  noClick: () => css`
    cursor: inherit;

    &:hover {
      box-shadow: 0.1rem 0.1rem 0.8rem rgb(0 0 0 / 10%);
    }
  `,
  large: () => css`
    padding: 2rem;
  `,
  disabled: () => css`
    opacity: 0.3;
    pointer-events: none;
  `,
  active: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.blue};
  `
};

export const CardWrapper = styled(Card)<CardWrapperProps>`
  ${({ theme, isClicked, large, disabled, isActive }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all ${theme.transition.fast};
    margin: ${theme.spacings.small} 0;
    padding: ${theme.spacings.small};
    cursor: pointer;

    svg {
      &:last-child {
        margin-right: 1rem;
      }
    }

    ${!isClicked && modifierCardWrapper.noClick()}
    ${large && modifierCardWrapper.large()}
    ${disabled && modifierCardWrapper.disabled()}
    ${isActive && modifierCardWrapper.active(theme)}
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  svg,
  img {
    margin-right: 1rem;
  }

  div {
    font-size: 1.2rem;
  }

  p {
    line-height: 1.5;
  }
`;
