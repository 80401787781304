import { ThemeProvider } from 'styled-components';

import { AuthProvider } from 'contexts/Auth';
import GlobalStyles from 'styles/global';
import theme from 'styles/theme';
import Routes from './routes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
