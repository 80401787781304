import styled, { css, DefaultTheme } from 'styled-components';
import { darken, transparentize } from 'polished';
import customMedia from 'styles/media-query';
import { ThemeColors } from 'types/global';
import { PositionTypes } from './';

export type WrapperProps = {
  fullWidth?: boolean;
  center?: boolean;
  secondary?: boolean;
  secondaryColor?: ThemeColors;
  hasIcon?: boolean;
  onlyIcon?: boolean;
  removeChildrenWhenMobile?: boolean;
  tertiary?: boolean;
  fullWidthMobile?: boolean;
  filled?: boolean;
  rounded?: boolean;
  filledBgColor?: ThemeColors;
  filledColor?: ThemeColors;
  noBorder: boolean;
  large?: boolean;
  disabled?: boolean;
  position?: PositionTypes;
  bottom?: string;
  width?: string;
} & React.HTMLAttributes<HTMLButtonElement>;

const modifierWrapper = {
  fullWidth: () => css`
    width: 100%;
    padding: 0;
  `,

  center: () => css`
    display: flex;
    margin: 0 auto;
  `,

  secondary: (theme: DefaultTheme, color: ThemeColors) => css`
    background: ${theme.colors.transparent};
    border: 0.1rem solid ${theme.colors[color]};
    color: ${theme.colors[color]};

    &:hover {
      background: ${transparentize(0.9, theme.colors[color])};
    }
  `,

  secondaryDisabled: (theme: DefaultTheme) => css`
    border: 0.1rem solid ${theme.colors.grayMedium};
    color: ${theme.colors.grayDark};
    background: ${theme.colors.transparent};
  `,

  withIcon: (theme: DefaultTheme) => css`
    svg {
      margin-right: ${theme.spacings.xsmall};
    }
  `,

  onlyIcon: () => css`
    svg {
      margin-right: 0;
    }
  `,

  removeChildrenWhenMobile: () => css`
    ${customMedia.lessThan('large')`
        width: auto;

        span {
          display: none;
        }

        ${modifierWrapper.onlyIcon()};
    `}
  `,

  tertiary: (theme: DefaultTheme) => css`
    background: ${theme.colors.transparent};
    border: none;
    color: ${theme.colors.blue};
    padding: 0;

    &:hover {
      background: ${theme.colors.transparent};
      color: ${theme.colors.blueDark};

      svg {
        color: ${theme.colors.blueDark};
      }
    }
  `,

  tertiaryDisabled: (theme: DefaultTheme) => css`
    background: ${theme.colors.transparent};
    color: ${theme.colors.grayDark};
  `,

  fullWidthMobile: () => css`
    ${customMedia.lessThan('large')`
        width: 100%;
    `}
  `,

  filled: (
    theme: DefaultTheme,
    bgColor: ThemeColors,
    color: ThemeColors
  ) => css`
    font-weight: ${theme.font.weights.medium};
    color: ${theme.colors[color]};
    background: ${theme.colors[bgColor]};
    border: 0;

    svg {
      color: ${theme.colors[color]};
    }
  `,

  filledDisabled: (theme: DefaultTheme, bgColor: ThemeColors) => css`
    &:hover {
      background: ${darken(0.2, theme.colors[bgColor])};
    }
  `,

  noBorder: () => css`
    border: 0;
  `,

  large: () => css`
    min-width: 30rem;
  `,

  disabled: (theme: DefaultTheme) => css`
    cursor: not-allowed;
    color: ${theme.colors.grayDark};
    background: ${theme.colors.grayMedium};
    font-weight: ${theme.font.weights.medium};
    pointer-events: none;
  `,

  rounded: () => css`
    border-radius: 500rem;
    padding-left: 2rem;
    padding-right: 2rem;
  `
};

export const Wrapper = styled.button<WrapperProps>`
  ${({
    theme,
    fullWidth,
    center,
    secondary,
    secondaryColor,
    hasIcon,
    onlyIcon,
    removeChildrenWhenMobile,
    fullWidthMobile,
    tertiary,
    filled,
    filledBgColor,
    filledColor,
    rounded,
    noBorder,
    large,
    disabled,
    position,
    bottom,
    width
  }) => css`
    border: none;
    border-radius: 1.2rem;
    font-weight: ${theme.font.weights.regular};
    font-size: ${theme.font.sizes.small};
    height: 4.5rem;
    padding: 0 ${theme.spacings.large};
    background: ${transparentize(0.9, theme.colors.blue)};
    color: ${theme.colors.blue};
    transition: all ${theme.transition.default};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: ${position};
    bottom: ${bottom};
    width: ${width};

    &:hover {
      background: ${transparentize(0.7, theme.colors.blue)};
    }

    ${fullWidth && modifierWrapper.fullWidth()};
    ${center && modifierWrapper.center()};
    ${secondary && modifierWrapper.secondary(theme, secondaryColor!)};
    ${secondary && disabled && modifierWrapper.secondaryDisabled(theme)};
    ${hasIcon && modifierWrapper.withIcon(theme)};
    ${onlyIcon && modifierWrapper.onlyIcon()};
    ${removeChildrenWhenMobile && modifierWrapper.removeChildrenWhenMobile()};
    ${tertiary && modifierWrapper.tertiary(theme)};
    ${fullWidthMobile && modifierWrapper.fullWidthMobile()}
    ${filled && modifierWrapper.filled(theme, filledBgColor!, filledColor!)};
    ${disabled && modifierWrapper.disabled(theme)};
    ${tertiary && disabled && modifierWrapper.tertiaryDisabled(theme)};
    ${filled &&
    !disabled &&
    modifierWrapper.filledDisabled(theme, filledBgColor!)};
    ${noBorder && modifierWrapper.noBorder()};
    ${large && modifierWrapper.large()};
    ${rounded && modifierWrapper.rounded()};
  `}
`;
