import { InputField, Loading, SelectField } from 'components';
import { StepProps } from 'contexts/AccreditationSteps/types';
import { useFormikContext } from 'formik';
import { AccreditationValues } from 'pages/Accreditation/shared/form';

import * as S from 'pages/Accreditation/shared/styles';
import { usePlatforms } from './use-platforms';

const StepDigital2 = ({ names }: StepProps) => {
  const form = useFormikContext<AccreditationValues>();
  const { data, loading } = usePlatforms();

  return (
    <>
      {loading && <Loading fullContent fullWidth />}

      <S.Title>Agora sobre sua loja virtual</S.Title>

      <S.Subtitle>
        Conta pra gente a plataforma de e-commerce que você usa e o endereço do
        seu site :)
      </S.Subtitle>

      <SelectField
        name={names.platformId}
        label="Plataforma de e-commerce"
        options={data}
        onChange={option => {
          form.setFieldValue(names.platform, option);
        }}
      />

      <InputField
        name={names.website}
        label="Digite o endereço web da sua loja"
      />

      <S.Action>Ir para dados pessoais</S.Action>
    </>
  );
};

export default StepDigital2;
