import styled, { css, DefaultTheme } from 'styled-components';

import customMedia from 'styles/media-query';
import { Button, Flex } from 'components';

type ButtonProps = {
  waiting?: boolean;
};

const wrapperModifier = {
  waiting: (theme: DefaultTheme) => css`
    span {
      color: ${theme.colors.grayExtraMedium};
    }
  `
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${customMedia.greaterThan('medium')`
    margin-right: 6rem;
  `}

  ${customMedia.lessThan('medium')`
    margin-bottom: 2rem;
    width: 50%;
  `}
`;

export const ButtonWrapper = styled(Button)<ButtonProps>`
  ${({ theme, waiting }) => css`
    margin-left: 2rem;
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.weights.medium};
    padding: 0.5rem;

    ${customMedia.lessThan('medium')`
      margin-left: 1rem;

      span {
        display: none;
      }

      svg {
        margin: 0;
      }
    `};

    ${waiting && wrapperModifier.waiting(theme)}
  `}
`;

export const DownloadItem = styled(Flex)`
  flex: 1;

  ${ButtonWrapper} {
    span {
      display: block;
      margin-left: 0.5rem;
    }
  }

  ${customMedia.lessThan('medium')`
    justify-content: center;
  `}
`;

export const Badge = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    color: ${theme.colors.blue};
    margin-left: ${theme.spacings.small};
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
