import { Text, Flex, TemplateModal } from 'components';

import * as S from './styles';

export type ModalChallengeProps = {
  description?: string;
};

const ModalChallenge = ({ description }: ModalChallengeProps) => (
  <S.Wrapper className="ame-px-challenge ame-px-challenge--hidden">
    <TemplateModal title="Verificação" layer="alwaysOnTop">
      <Flex direction="column" alignItems="center" justifyContent="center">
        <Text color="grayDark" mb="large">
          {description ||
            'Antes que você volte a navegar, precisamos garantir que você é uma pessoa de verdade, e não um robô.'}
        </Text>

        <div className="px-element" />
      </Flex>
    </TemplateModal>
  </S.Wrapper>
);

export default ModalChallenge;
