import { useMemo, useState } from 'react';

import {
  Breadcrumb,
  CardInfo,
  TemplateShortContent,
  Text,
  Input,
  Popover,
  Loading
} from 'components';

import { ReactComponent as IconAmeDark } from 'assets/images/icon-ame-dark.svg';
import { ReactComponent as IconCopy } from 'assets/images/icon-copy.svg';

import * as S from './styles';
import Divider from 'styles/shared/Divider';
import useWindowSize from 'hooks/useWindowSize';
import { copyToClipBoard } from 'utils/copyToClipboard';

import { usePix } from 'contexts/Pix';
import { useAuth } from 'contexts/Auth';

const AgencyAccount = () => {
  const [width] = useWindowSize();
  const [copied, setCopied] = useState(false);

  const { user } = useAuth();
  const {
    balance: { data, loading }
  } = usePix();

  const accountWithDigit = useMemo(() => {
    if (data?.checkingAccount)
      return `${data?.checkingAccount?.accountNumber}-${data?.checkingAccount?.accountDigit}`;
  }, [data?.checkingAccount]);

  if (loading) {
    return <Loading />;
  }

  return (
    <S.Wrapper>
      <Breadcrumb
        links={[
          {
            link: '/pix',
            title: 'Pix'
          },
          {
            link: '#',
            title: 'Minha Agência e Conta Pix'
          }
        ]}
      />

      <Divider size="xlarge" />

      <TemplateShortContent>
        <Text tag="h1" size="large">
          Use os dados da sua conta{' '}
          <Text tag="span" weight="bold" size="large">
            AME
          </Text>{' '}
          para receber transferências
        </Text>

        <Divider size="large" />

        <Text weight="bold">Instituição</Text>

        <CardInfo icon={<IconAmeDark />} text="32778350 - Ame Digital" large />

        <Divider size="small" />

        <Input
          disabled
          label="Agência"
          value={data?.checkingAccount?.agencyNumber}
          name="agency"
          noBorder
          readOnly
        />

        <Popover
          direction={width < 768 ? 'bottom' : 'right'}
          text="Texto Copiado!"
          show={copied}
        >
          <Input
            disabled
            readOnly
            label="Conta Ame"
            value={accountWithDigit}
            name="account"
            action={() =>
              copyToClipBoard(setCopied, accountWithDigit as string)
            }
            actionChildren={<IconCopy />}
          />
        </Popover>

        <Input
          disabled
          readOnly
          label="Meu CNPJ"
          value={user?.groupDocument}
          name="document"
          maskType={'CNPJ'}
        />
      </TemplateShortContent>
    </S.Wrapper>
  );
};

export default AgencyAccount;
