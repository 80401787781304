import { ReactNode } from 'react';
import * as S from './styles';

export type TColumnProps = {
  verticalCenterContent?: boolean;
  horizontalCenterContent?: boolean;
  isGroup?: boolean;
  colSpan?: number;
  className?: string;
  children: ReactNode;
};

export const TColumn = ({
  verticalCenterContent,
  horizontalCenterContent,
  isGroup,
  colSpan = 1,
  className,
  children
}: TColumnProps) => (
  <S.Wrapper
    verticalCenterContent={verticalCenterContent}
    horizontalCenterContent={horizontalCenterContent}
    isGroup={isGroup}
    colSpan={colSpan}
    className={className}
  >
    <div>{children}</div>
  </S.Wrapper>
);

export default TColumn;
