import { useCallback, useEffect, useMemo, useState } from 'react';
import useRequest from 'hooks/useRequest';
import useMutate from 'hooks/useMutate';
import endpoints from 'services/endpoints';

type Videos = {
  id: string;
  title: string;
  youtubeId: string;
  thumbUrl: string;
  type: string;
  videoOrder: number;
};

type WatchedVideo = {
  videoId: string;
};

type WrapperData<T> = {
  data: T;
};

const NOT_FOUND_VIDEO = 'NOT_FOUND_VIDEO';

export function useTrainingPlaylist({ video }: { video: string | null }) {
  const [watchingId, setWatchingId] = useState<string | null>(video);
  const { mutate } = useMutate();

  const {
    data,
    loading: videosLoading,
    error: videosError
  } = useRequest<WrapperData<Videos[]>>({
    url: endpoints.videos
  });

  const {
    data: watchedVideos,
    loading: watchedLoading,
    error: watchedError,
    revalidate
  } = useRequest<WrapperData<WatchedVideo[]>>({
    url: endpoints.watchedVideos
  });

  const { loading: lastVideoLoading, error: lastVideoError } =
    useRequest<WatchedVideo>({
      url: watchingId ? null : endpoints.lastWatchedVideo,
      options: {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        onSuccess: (video: WatchedVideo) =>
          setWatchingId(video?.videoId || NOT_FOUND_VIDEO),
        onError: () => setWatchingId(NOT_FOUND_VIDEO)
      }
    });

  const saveWatchedVideo = useCallback(
    (videoId: string) => {
      return mutate({
        endpoint: endpoints.watchedVideos,
        body: { videoId },
        onSuccess: () => revalidate()
      });
    },
    [mutate, revalidate]
  );

  const error = videosError || watchedError || lastVideoError;
  const loading = videosLoading || watchedLoading || lastVideoLoading;

  useEffect(() => {
    video && setWatchingId(video);
  }, [video]);

  const videos = useMemo(() => {
    if (loading || error || !data) {
      return [];
    }

    return data.data.map(video => ({
      ...video,
      watched: watchedVideos?.data.some(
        watchedVideo => watchedVideo.videoId === video.id
      )
    }));
  }, [loading, error, data, watchedVideos]);

  const watching = useMemo(() => {
    if (!videos.length || !watchingId) {
      return null;
    }

    if (watchingId === NOT_FOUND_VIDEO) {
      return videos[0];
    }

    return videos.find(video => video.id === watchingId) || videos[0];
  }, [videos, watchingId]);

  return {
    saveWatchedVideo,
    setWatchingId,
    watching,
    videos,
    loading,
    error
  };
}
