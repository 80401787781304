import styled, { css, DefaultTheme } from 'styled-components';

import { Card } from 'styles/shared/Card';
import { CardLinkProps } from '.';

type WrapperProps = {
  filled?: boolean;
  center?: boolean;
} & Pick<CardLinkProps, 'target'>;

const wrapperModifier = {
  filled: (theme: DefaultTheme) => css`
    background: ${theme.colors.blue};

    h5,
    span {
      color: ${theme.colors.white};
    }
  `,

  center: () => css`
    height: 100%;

    .column-left {
      flex-direction: column;

      h5,
      span {
        text-align: center;
      }
    }
  `
};

export const Wrapper = styled(Card)<WrapperProps>`
  ${({ theme, filled, center }) => css`
    cursor: pointer;
    padding: ${theme.spacings.small} ${theme.spacings.medium};
    border: 1px solid transparent;
    margin-bottom: ${theme.spacings.small};
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .column-left {
      display: flex;
      align-items: center;
      flex: 1;
    }

    .column-right {
      text-align: right;
    }

    ${filled && wrapperModifier.filled(theme)}
    ${center && wrapperModifier.center()}
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${theme.spacings.small};
    border-radius: ${theme.profile.radius.medium};
    margin: ${theme.spacings.small} 1.5rem;
    margin-left: 0;
  `}
`;
