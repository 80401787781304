import clsx from 'clsx';
import * as S from './styles';

export type Item = {
  label: string;
  action: () => void;
  active: boolean;
};

export type SegmentControlProps = {
  items: Item[];
  small?: boolean;
};

const SegmentControl = ({ items, small = false }: SegmentControlProps) => (
  <S.Wrapper small={small}>
    {items.map(item => (
      <button
        key={item.label}
        type="button"
        className={clsx({ active: item.active })}
        onClick={item.action}
      >
        {item.label}
      </button>
    ))}
  </S.Wrapper>
);

export default SegmentControl;
