import { ReactNode } from 'react';

import { Text, Flex } from 'components';

import { ReactComponent as SvgIconError } from 'assets/images/icon-error.svg';

import * as S from './styles';

export type ErrorMessageProps = {
  message?: string;
  children?: string | ReactNode;
};

const ErrorMessage = ({
  message = 'Ocorreu um erro inesperado',
  children
}: ErrorMessageProps) => {
  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      <S.Content>
        <SvgIconError />

        <Text weight="bold" mt="medium" align="center">
          Erro
        </Text>

        <Text color="grayNeutralDark" my="xsmall" align="center">
          {children || message}
        </Text>
      </S.Content>
    </Flex>
  );
};

export default ErrorMessage;
