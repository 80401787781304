import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  CardInfo,
  CardShadowDashed,
  Link,
  Loading,
  Text,
  TemplateCloseContent,
  ModalStatus
} from 'components';

import { ReactComponent as IconBankOutline } from 'assets/images/icon-bank-outline.svg';

import { Row, Column } from 'styles/shared/Grid';
import Divider from 'styles/shared/Divider';

import useRequest from 'hooks/useRequest';
import useToggle from 'hooks/useToggle';
import useMutate from 'hooks/useMutate';

import { useBank } from 'contexts/Bank';

import endpoints from 'services/endpoints';
import { IBankAccountData } from 'services/bankService/types';

import { maskDocument } from 'utils/masks';

import PATHS_ROUTES from 'routes/paths';

import ModalEdit from './Modal';

export const BankList = () => {
  const { withdrawData, setEditingAccount, editingAccount } = useBank();
  const navigate = useNavigate();

  const modalEdit = useToggle();
  const modalSuccess = useToggle();
  const modalError = useToggle();

  const { state, mutate } = useMutate();

  const { status, error: deleteError } = state;

  const {
    data: bankAccount,
    loading,
    mutate: listMutate,
    error
  } = useRequest<IBankAccountData[]>({
    url: endpoints.bankAccount
  });

  const handleEditAccount = useCallback(
    (account: IBankAccountData) => {
      setEditingAccount({
        documentNumber: withdrawData?.documentNumber,
        ...account
      });
      modalEdit.toggle();
    },
    [modalEdit, setEditingAccount, withdrawData?.documentNumber]
  );

  const handleCloseModal = useCallback(() => {
    modalEdit.toggle();
    setEditingAccount(null);
  }, [modalEdit, setEditingAccount]);

  const handleDelete = useCallback(() => {
    if (!state.loading) {
      mutate({
        method: 'delete',
        endpoint: endpoints.bankAccountUpdate.replace(
          '{accountId}',
          editingAccount ? editingAccount.id : ''
        ),
        onSuccess: () => {
          listMutate();
          modalSuccess.toggle();
          modalEdit.toggle();
        },
        onFailure: () => {
          modalError.toggle();
          modalEdit.toggle();
        }
      });
    }
  }, [
    editingAccount,
    listMutate,
    modalEdit,
    modalError,
    modalSuccess,
    mutate,
    state.loading
  ]);

  if (loading) return <Loading fullContent />;

  return (
    <>
      <TemplateCloseContent closeLink={PATHS_ROUTES.APP.DASHBOARD.DEFAULT}>
        <Text size="xxmedium" weight="bold">
          Contas cadastradas
        </Text>

        <Text mb="large">Essas são suas contas bancárias cadastradas</Text>

        {!bankAccount?.length ? (
          <CardShadowDashed
            rightText="Adicionar"
            text="Nenhuma conta de destino cadastrada"
            icon={<IconBankOutline />}
            action={() => navigate(PATHS_ROUTES.APP.BANK.ACCOUNT_REGISTRATION)}
          />
        ) : (
          bankAccount?.map((account: IBankAccountData) => (
            <CardInfo
              key={account.id}
              text={account.name}
              icon={<img src={account.image} width="50" />}
              action={() => handleEditAccount(account)}
              infoText="Editar"
            >
              <Text color="grayNeutralDark" size="xxsmall">
                CNPJ:{' '}
                {maskDocument(withdrawData?.documentNumber as string, 'CNPJ')}
              </Text>
              <Text color="blueLightest" size="xxsmall">
                Ag: {account.agency} / Cc: {account.account}
              </Text>
            </CardInfo>
          ))
        )}

        <Divider size="large" />

        <Row>
          <Column xs={12} md={8} offsetMd={2}>
            <Button
              as={Link}
              to={PATHS_ROUTES.APP.BANK.ACCOUNT_REGISTRATION}
              filled
              fullWidth
            >
              Adicionar conta
            </Button>
          </Column>
        </Row>
      </TemplateCloseContent>

      {modalEdit.isActive && !!editingAccount && (
        <ModalEdit onClose={handleCloseModal} cancelAction={handleDelete} />
      )}

      {error && (
        <ModalStatus
          variant="error"
          title="Erro"
          onClose={() => navigate(PATHS_ROUTES.APP.DASHBOARD.DEFAULT)}
        >
          <Text>Ocorreu um erro inesperado</Text>
        </ModalStatus>
      )}

      {modalError.isActive && !!deleteError && (
        <ModalStatus variant="error" title="Erro" onClose={modalError.toggle}>
          <Text>Ocorreu um erro ao tentar excluir a conta</Text>
        </ModalStatus>
      )}

      {modalSuccess.isActive && status === 200 && (
        <ModalStatus
          variant="success"
          title="Sucesso"
          onClose={modalSuccess.toggle}
          action={{
            filled: true,
            text: 'OK',
            onClick: modalSuccess.toggle
          }}
        >
          <Text>Conta excluída com sucesso</Text>
        </ModalStatus>
      )}
    </>
  );
};

export default BankList;
