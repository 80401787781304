import { Flex } from 'components';
import styled, { css } from 'styled-components';

export const KeyCard = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.grayMedium};
    border-radius: ${theme.border.radius};
    padding: ${theme.spacings.small};
  `}
`;

export const KeyContainer = styled(Flex)`
  ${({ theme }) => css`
    gap: ${theme.spacings.large};
  `}
`;
