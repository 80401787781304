import { useMemo, useState } from 'react';

import { ReactComponent as IconPhoto } from 'assets/images/icon-photo.svg';
import { ReactComponent as IconCopy } from 'assets/images/icon-copy.svg';

import {
  Breadcrumb,
  TemplateShortContent,
  Text,
  CardInfo,
  Popover
} from 'components';
import { Row, Column } from 'styles/shared/Grid';
import Divider from 'styles/shared/Divider';

import useWindowSize from 'hooks/useWindowSize';
import { usePix } from 'contexts/Pix';
import { formatPixKeyType, formatKey } from 'utils/pix';
import { copyToClipBoard } from 'utils/copyToClipboard';

import * as S from './styles';
import { breadcrumbItems } from './constants';

const MyQRCode = () => {
  const [width] = useWindowSize();
  const [copied, setCopied] = useState(false);
  const {
    keysList: { data: keysListData },
    qrcode
  } = usePix();

  const encodedQRCode = useMemo(() => {
    if (qrcode && qrcode.pngData)
      return btoa(
        qrcode.pngData.data.reduce(
          (data: string, byte: number) => data + String.fromCharCode(byte),
          ''
        )
      );

    return null;
  }, [qrcode]);

  const downloadQrCode = () => {
    if (encodedQRCode && keysListData && keysListData.length > 0) {
      const link = document.createElement('a');
      link.href = `data:image/*;base64,${encodedQRCode}`;
      link.download = `${keysListData[0].key}-pix-qr-code.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Breadcrumb links={breadcrumbItems} />
      <TemplateShortContent>
        <Text tag="h2" mt="xlarge" mb="small" weight="bold" size="xmedium">
          Meu QR Code PIX
        </Text>

        <Row>
          <Column xs={12} md={6}>
            <S.CardWrapper>
              <S.QRCode src={`data:image/*;base64,${encodedQRCode}`} />

              <Divider withBorder />

              <Text mx="medium" color="blue" weight="regular" size="small">
                {keysListData &&
                  keysListData.length &&
                  formatPixKeyType(keysListData[0].keyType)}
              </Text>

              <Text
                mx="medium"
                color="grayNeutralDark"
                weight="regular"
                size="xsmall"
              >
                {keysListData &&
                  keysListData.length &&
                  formatKey(keysListData[0].keyType, keysListData[0].key)}
              </Text>
            </S.CardWrapper>
          </Column>

          <Column xs={12} md={6}>
            <S.ButtonsWrapper>
              <CardInfo
                arrowIcon
                text="Baixar QR Code"
                icon={<IconPhoto />}
                action={downloadQrCode}
              />

              <S.PopoverWrapper>
                <Popover
                  direction={width < 768 ? 'bottom' : 'right'}
                  text="Texto Copiado!"
                  show={copied}
                >
                  <CardInfo
                    arrowIcon
                    text="Copiar Código PIX"
                    icon={<IconCopy />}
                    action={() =>
                      copyToClipBoard(setCopied, qrcode.rawData || '')
                    }
                  />
                </Popover>
              </S.PopoverWrapper>
            </S.ButtonsWrapper>
          </Column>
        </Row>
      </TemplateShortContent>
    </>
  );
};

export default MyQRCode;
