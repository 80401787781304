import styled, { css } from 'styled-components';

import customMedia from 'styles/media-query';

import { Card } from 'styles/shared/Card';

type WrapperProps = {
  selected?: boolean;
};

export const Wrapper = styled(Card)<WrapperProps>`
  ${({ theme, selected }) => css`
    display: inline-flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    width: 16rem;
    margin: ${theme.spacings.small} ${theme.spacings.xsmall};

    &:last-child:after {
      content: '';
      flex: auto;
    }

    ${customMedia.greaterThan('medium')`
        width: 14.5rem;
    `}

    ${selected &&
    css`
      border: 1px solid ${theme.colors.blue};
    `}

    button, a {
      height: auto;
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.small};

    p {
      margin-bottom: -5px;
    }
  `}
`;
