import styled, { css } from 'styled-components';
import { ProgressBarProps } from '.';

export const Wrapper = styled.div<ProgressBarProps>`
  ${({ theme, value }) => css`
    width: 100%;
    height: 0.8rem;
    background: ${theme.colors.blue};
    border-radius: 0.2rem;
    position: relative;
    overflow: hidden;

    ::before {
      content: '';
      background: ${theme.colors.grayNeutralLight};
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateX(${value}%);
      transition: 0.5s ease-out transform;
    }
  `}
`;
