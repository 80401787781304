import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import endpoints from 'services/endpoints';
import useRequest from 'hooks/useRequest';
import { useSessionToken } from 'hooks/useSessionToken';
import { useQuery } from 'hooks/useQuery';

type InformationType =
  | 'companyName'
  | 'name'
  | 'social_contract'
  | 'procuration';

type Field = {
  informationType: InformationType;
  value?: string;
};

interface Response {
  id: string;
  groupValidationId: string;
  onboardingDeeplinkId: string;
  fieldList: Field[];
  groupDocumentRequest: {
    documentType: 'CNPJ' | 'CPF';
    documentNumber: string;
  };
}

export type FieldType = {
  name: InformationType;
  label: string;
  value?: string;
  visible?: boolean;
  uploadKey?: string;
} & (
  | {
      type: 'text';
      validations: {
        required: string;
      };
    }
  | {
      type: 'file';
      validations: {
        required: string;
        isValidFiles: string;
      };
    }
);

const fieldTypes: FieldType[] = [
  {
    name: 'name',
    type: 'text',
    label: 'Seu nome completo',
    validations: {
      required: 'Nome completo obrigatório'
    }
  },
  {
    name: 'companyName',
    type: 'text',
    label: 'Sua razão social',
    validations: {
      required: 'Razão social obrigatória'
    }
  },
  {
    name: 'social_contract',
    type: 'file',
    label: 'Contrato social',
    uploadKey: 'contrato_social',
    validations: {
      required: 'Documento obrigatório',
      isValidFiles: 'O arquivo precisa ser imagem ou PDF'
    }
  },
  {
    name: 'procuration',
    type: 'file',
    label: 'Declaração de plenos poderes',
    uploadKey: 'plenos_poderes',
    validations: {
      required: 'Documento obrigatório',
      isValidFiles: 'O arquivo precisa ser imagem ou PDF'
    }
  }
];

function useToken(): string {
  const navigate = useNavigate();
  const params = useQuery();
  const token = params.get('token') as string;

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token, navigate]);

  return token;
}

export function useDeeplinkData() {
  const token = useToken();
  const [loading, setLoading] = useState(true);

  const { hasSession, axiosError: errorSession } = useSessionToken();

  const { data, error: errorDeeplink } = useRequest<Response>({
    url: hasSession ? `${endpoints.deeplink}/${token}` : null,
    options: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  });

  const error = errorDeeplink || errorSession;

  useEffect(() => {
    if (data || error) {
      setLoading(false);
    }
  }, [data, error]);

  const fields = useMemo(() => {
    return fieldTypes
      .map(type => {
        const field = data?.fieldList?.find(
          field => type.name === field.informationType
        );

        return {
          ...type,
          value: field?.value,
          visible: !!field
        } as FieldType;
      })
      .filter(({ visible }) => visible);
  }, [data]);

  return {
    document: data?.groupDocumentRequest?.documentNumber,
    token,
    fields,
    loading,
    error
  };
}
