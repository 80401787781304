import { BreadcrumbLink } from 'components/Breadcrumb';
import { SelectOption } from 'components/Select';

import PATHS_ROUTES from 'routes/paths';

import { PIX_OUT_KEYS_TYPES } from 'utils/pix';

import { breadcrumbItems } from '../constants';

export const breadcrumb: BreadcrumbLink[] = [
  ...breadcrumbItems,
  {
    title: 'Agência e conta',
    link: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.BANK)
  }
];

export const accountTypes: SelectOption[] = [
  { label: 'Conta corrente', value: 'CHECKING' },
  { label: 'Conta poupança', value: 'SAVINGS' },
  { label: 'Conta salário', value: 'SALARY' }
];
