import { useState } from 'react';
import {
  Loading,
  ModalStatus,
  PaginationLabels,
  ProgressBar,
  Text
} from 'components';
import Card from 'styles/shared/Card';
import { Formik, Form, FormikHelpers } from 'formik';
import { useAccreditationSteps } from 'contexts/AccreditationSteps';
import { ReactComponent as IconRight } from 'assets/images/icon-right-next.svg';
import { FinishStep } from 'pages/Accreditation/shared/steps';

import {
  initialValues,
  fieldNames,
  partnershipTypes,
  AccreditationValues
} from '../form';

import * as S from './styles';
import { useSessionToken } from 'hooks/useSessionToken';
import {
  useSellerValidation,
  convertValuesToSeller
} from './use-seller-validation';
import { useSellerUpload } from 'hooks/use-seller-upload';

import { getApiError } from 'utils/errors';

interface Props {
  merchantType: 'PHYSICAL' | 'DIGITAL';
}

function AccreditationSteps({ merchantType }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [state, setState] = useState<'steps' | 'finish'>('steps');
  const tokenState = useSessionToken();
  const { validate } = useSellerValidation();
  const { uploadDocuments } = useSellerUpload();

  const {
    currentPage,
    pageLabels,
    allSteps,
    currentStep,
    goToPreviousPage,
    goToPreviousStep,
    goToNextStep,
    goToNextPage,
    isFirstStep,
    isLastStep,
    isFirstPage,
    isLastPage,
    pagePercent
  } = useAccreditationSteps();

  const { Step, validation } = allSteps[currentStep];

  function handlePreviousClick() {
    if (isFirstStep && isFirstPage) return;

    isFirstStep ? goToPreviousPage() : goToPreviousStep();
  }

  function handleNext() {
    isLastStep ? goToNextPage() : goToNextStep();
  }

  async function handleSubmit(
    values: AccreditationValues,
    helpers: FormikHelpers<AccreditationValues>
  ) {
    helpers?.setTouched({});

    if (isLastStep && isLastPage) {
      handleValidate(values);

      return;
    }

    handleNext();
  }

  async function handleValidate(values: AccreditationValues) {
    try {
      setLoading(true);

      await validate(convertValuesToSeller(values, merchantType));

      if (values.sendDocuments) {
        await uploadDocuments({
          docDeclaration: values.docDeclaration,
          docSocial: values.docSocial,
          document: values[fieldNames.cnpj]
        });
      }

      setState('finish');
    } catch (error) {
      const { status, message, type } = getApiError(error);

      if (type === 'UPLOAD_ERROR') {
        setError(
          'Erro ao enviar documentos. Você pode fazer isso em outro momento no seu perfil.'
        );

        setState('finish');

        return;
      }

      if (status !== 'error' && message) {
        setError(message);

        return;
      }

      setError(
        'Ocorreu um erro ao cadastrar sua conta, tente novamente mais tarde.'
      );
    } finally {
      setLoading(false);
    }
  }

  const errorComponent = error && (
    <ModalStatus
      variant="error"
      action={{
        filled: true,
        onClick: () => setError('')
      }}
    >
      <Text weight="bold" size="large" align="center">
        {error}
      </Text>
    </ModalStatus>
  );

  switch (state) {
    case 'finish':
      return (
        <S.Wrapper>
          {errorComponent}

          <S.NextButton icon={<IconRight />} />

          <Card>
            <FinishStep />
          </Card>

          <S.NextButton icon={<IconRight />} />
        </S.Wrapper>
      );

    case 'steps':
      return (
        <S.Wrapper>
          {(tokenState.loading || loading) && <Loading fullContent fullWidth />}

          {errorComponent}

          <div>
            <S.PreviousButton
              hidden={isFirstStep && isFirstPage}
              icon={<IconRight />}
              onClick={handlePreviousClick}
            />
          </div>

          <S.FormWrapper>
            <PaginationLabels
              pageLabels={pageLabels}
              currentPage={currentPage.label}
            />

            <S.ProgressWrapper>
              <ProgressBar value={pagePercent} />
            </S.ProgressWrapper>

            <Card>
              <Formik
                initialValues={initialValues}
                validationSchema={validation}
                validateOnChange={false}
                onSubmit={handleSubmit}
              >
                <Form>
                  <Step
                    hasSession={tokenState.hasSession}
                    partnershipTypes={partnershipTypes}
                    names={fieldNames}
                    goToNextStep={goToNextStep}
                    goToNextPage={goToNextPage}
                    goToPreviousStep={goToPreviousStep}
                  />
                </Form>
              </Formik>
            </Card>
          </S.FormWrapper>

          <S.NextButton icon={<IconRight />} />
        </S.Wrapper>
      );
  }
}

export default AccreditationSteps;
