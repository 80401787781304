import { Button, InputMoney, Loading, Money, Text } from 'components';
import { useBank } from 'contexts/Bank';
import useRequest from 'hooks/useRequest';
import endpoints from 'services/endpoints';
import { BalanceData } from 'services/statementService';

import { Row, Column } from 'styles/shared/Grid';
import Divider from 'styles/shared/Divider';

type Step2Props = {
  next: () => void;
};

export function Step2({ next }: Step2Props) {
  const { setWithdrawDataValues, withdrawData } = useBank();
  const { data, error, loading } = useRequest<BalanceData>({
    url: endpoints.balance,
    options: {
      revalidateOnFocus: false
    }
  });

  if (loading) return <Loading fullContent />;
  if (error)
    return (
      <Text size="large" align="center">
        Erro ao consultar saldo.
      </Text>
    );

  return (
    <>
      <Text size="xxmedium" weight="bold">
        Retirar
      </Text>
      <Text>Selecione o valor da transferência</Text>

      <Divider size="large" />

      <Text size="xsmall" weight="medium" darkColor="white">
        Total disponível
      </Text>
      <Money
        value={data?.cashBalance}
        size="xxlarge"
        color="blueCategoryColorSuccess"
        weight="medium"
      />
      <Text size="small" color="grayDark" darkColor="white">
        Valor disponível na conta de origem
      </Text>

      <Divider size="medium" />

      <InputMoney
        name="value"
        label="Valor"
        value={withdrawData.value as string}
        onChange={value => setWithdrawDataValues({ value })}
      />
      <Text size="small" color="grayDark" darkColor="white">
        O valor deve ser menor ou igual ao total disponível na conta de origem.
        Essa operação pode levar até 24h para ser realizada.
      </Text>

      <Divider size="xlarge" />

      <Row>
        <Column xs={12} md={8} offsetMd={2}>
          <Button
            secondary
            fullWidth
            disabled={
              Number(withdrawData.value) === 0 ||
              Number(withdrawData.value) > Number(data?.cashBalance)
            }
            onClick={next}
          >
            Próximo passo
          </Button>
        </Column>
      </Row>
    </>
  );
}
