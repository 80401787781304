import { useCallback } from 'react';

import endpoints from 'services/endpoints';
import useMutate from 'hooks/useMutate';

type UploadModel = {
  document: string;
  docDeclaration?: File;
  docSocial?: File;
};

export function useUploadDocuments() {
  const { mutate } = useMutate();

  const upload = useCallback(
    (document: string, formData: FormData) => {
      return new Promise((resolve, reject) => {
        mutate({
          endpoint: endpoints.sellerUpload,
          config: {
            params: {
              document
            }
          },
          body: formData,
          onFailure: error =>
            reject({
              type: 'UPLOAD_ERROR',
              error
            }),
          onSuccess: resolve
        });
      });
    },
    [mutate]
  );

  return { upload };
}

export function useSellerUpload() {
  const { upload } = useUploadDocuments();

  const uploadDocuments = useCallback(
    (seller: UploadModel) => {
      const { docDeclaration, docSocial } = seller;
      const formData = new FormData();

      docDeclaration && formData.append('plenos_poderes', docDeclaration);

      docSocial && formData.append('contrato_social', docSocial);

      return upload(seller.document, formData);
    },
    [upload]
  );

  return { uploadDocuments };
}
