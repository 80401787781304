import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const List = styled.ul`
  display: flex;
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    &:not(:first-child) {
      margin-left: -1.8rem;
    }

    &:nth-child(1) {
      & div {
        background-color: ${theme.colors.grayNeutralDark};
      }
    }

    &:nth-child(2) {
      & div {
        background-color: ${theme.colors.grayNeutralBrandDarkest};
      }
    }

    &:nth-child(3) {
      & div {
        background-color: ${theme.colors.black};
      }
    }

    &:nth-child(4) {
      & div {
        background-color: ${theme.colors.blueCategoryColorSuccess};
      }
    }
  `}
`;

export const RestSize = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.grayNeutralBrandDarkest};
    font-weight: ${theme.font.weights.bold};
    font-size: ${theme.font.sizes.small};
    margin-left: ${theme.spacings.small};
  `}
`;
