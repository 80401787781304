import { CustomArrowProps } from 'react-slick';

import ArrowIcon from 'components/Icons/Arrow';

export const ArrowLeft = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => {
  return slideCount && currentSlide === 0 ? null : (
    <div {...props} className="slick-prev slick-arrow">
      <ArrowIcon color="white" />
    </div>
  );
};

export default ArrowLeft;
