import { useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Column } from 'styles/shared/Grid';

import PATHS_ROUTES from 'routes/paths';
import {
  Text,
  Button,
  ErrorMessage,
  Loading,
  ModalStatus,
  ModalAlert,
  InputValue,
  CardValue,
  TemplateCloseContent
} from 'components';

import useRequest from 'hooks/useRequest';
import useToggle from 'hooks/useToggle';
import { Divider } from 'styles/shared/Divider';
import endpoints from 'services/endpoints';
import { GetStatementDetailsResponse, refund } from 'services/statementService';
import { formatSimpleDate } from 'utils/masks';

type ParamsTypes = {
  id: string;
};

function StatementRefund() {
  const { id } = useParams<ParamsTypes>();
  const navigate = useNavigate();
  const successModal = useToggle();
  const alertModal = useToggle();
  const [partialValue, setPartialValue] = useState('');
  const [_resetInputValue, setResetInputValue] = useState<() => void>();
  const [amountError, setAmountError] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);
  const [refundError, setRefundError] = useState('');

  const { data, error } = useRequest<GetStatementDetailsResponse>({
    url: id ? endpoints.statementDetails.replace('{id}', id) : null,
    options: {
      revalidateOnFocus: false
    }
  });

  const handleApplyInput = useCallback(
    (
      value: string,
      hasError: boolean,
      resetInputValue?: () => void | undefined
    ) => {
      setAmountError(hasError);

      if (!hasError) {
        setPartialValue(value);
        setResetInputValue(() => resetInputValue);
      }
    },
    []
  );

  const navigateToDetail = useCallback(() => {
    navigate(PATHS_ROUTES.APP.STATEMENT.DETAILS(id));
  }, [navigate, id]);

  const handleRefundOrder = useCallback(() => {
    if (data) {
      const amount = partialValue
        ? Number(partialValue)
        : data?.refundAvailable;

      setRefundLoading(true);

      refund(data.referenceOrderUuid, amount)
        .then(response => {
          if (response.data.status === 'DENIED') {
            setRefundError('Não foi possível cancelar essa venda.');
            alertModal.toggle();
            return;
          }

          successModal.toggle();
        })
        .catch(error => {
          try {
            setRefundError(error.response.data.message);
          } catch {
            setRefundError(
              'Ocorreu ao tentar cancelar a venda, atualize a página e tente novamente.'
            );
          }

          alertModal.toggle();
        })
        .finally(() => {
          setRefundLoading(false);
        });
    }
  }, [alertModal, data, partialValue, successModal]);

  if (error) {
    return (
      <ErrorMessage
        message="Ocorreu um erro ao carregar os dados, atualize a página e tente
      novamente."
      />
    );
  }

  if (!data) {
    return <Loading fullContent />;
  }

  if (data.refundAvailable <= 0) {
    navigateToDetail();
  }

  return (
    <>
      {refundLoading && <Loading fullContent />}
      <TemplateCloseContent
        closeLink={PATHS_ROUTES.APP.STATEMENT.DETAILS(id)}
        closeTitle="Voltar para o detalhe da venda"
      >
        <Row>
          <Column xs={12}>
            <Text tag="h2" size="xxmedium" weight="medium">
              Cancelar venda
            </Text>
            <Text size="medium" darkColor="white">
              Escolha o quanto quer cancelar dessa operação
            </Text>
          </Column>
        </Row>

        <Divider size="large" />
        <Row>
          <Column xs={12}>
            <Text color="grayDark">
              {formatSimpleDate(data.date, `DD [de] MMMM [às] k:mm`)}
            </Text>

            <h3>{data.description}</h3>

            <Text color="blue">#{data.nsu}</Text>
          </Column>
        </Row>

        <Divider size="large" />

        <Row>
          <Column xs={12}>
            <InputValue
              initialValue={data.refundAvailable.toString()}
              label="Valor total da venda"
              description="O valor a ser estornado deve ser menor ou igual ao da transação. O valor
        cancelado da transação será retirado do seu saldo para estorno imediato."
              onApplyValue={handleApplyInput}
              onEditing={() => {
                setAmountError(true);
              }}
              disabled={refundLoading}
            />
          </Column>
        </Row>

        {_resetInputValue && (
          <>
            <Divider size="large" />
            <Row>
              <Column xs={12}>
                <CardValue
                  labelTop="Valor de cancelamento parcial"
                  labelBottom="Valor parcial da operação"
                  description="Só será estornado o valor solicitado. A operação continua existente"
                  value={parseInt(partialValue)}
                  valueSize="xxlarge"
                  color="blue"
                >
                  <Button
                    secondary
                    onClick={() => {
                      _resetInputValue();
                      setResetInputValue(undefined);
                      setPartialValue('');
                    }}
                  >
                    Excluir
                  </Button>
                </CardValue>
              </Column>
            </Row>
          </>
        )}

        <Divider size="large" />
        <Row>
          <Column xs={12} md={8} offsetMd={2}>
            <Button
              fullWidth
              filled
              onClick={handleRefundOrder}
              disabled={amountError || refundLoading}
            >
              Confirmar cancelamento
            </Button>
          </Column>
        </Row>
      </TemplateCloseContent>

      {successModal.isActive && (
        <ModalStatus
          variant="success"
          title="Cancelar venda"
          onClose={navigateToDetail}
          action={{ text: 'Concluir', onClick: navigateToDetail }}
        >
          <Text size="medium">
            O cancelamento da venda foi realizado com sucesso. Todas as tarifas
            transacionais serão canceladas. O estorno estará disponível na conta
            do comprador em até 24h.
          </Text>
        </ModalStatus>
      )}

      {alertModal.isActive && (
        <ModalAlert
          title="Cancelar venda"
          buttonText="OK"
          onClose={alertModal.toggle}
          buttonAction={alertModal.toggle}
        >
          <Text size="medium">{refundError}</Text>
        </ModalAlert>
      )}
    </>
  );
}

export default StatementRefund;
