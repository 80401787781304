import styled, { css, DefaultTheme } from 'styled-components';

import { ProfileSizes } from 'types/global';

import { ProfilePictureProps } from './';

export type WrapperProps = Omit<ProfilePictureProps, 'name' | 'profileType'>;

const modifiers = {
  onChange: (theme: DefaultTheme, size: ProfileSizes) =>
    size === 'large' &&
    css`
      & > div {
        padding-bottom: ${theme.spacings.large};
      }

      & > img {
        margin-top: -${theme.spacings.small};
      }
    `,
  rounded: css`
    border-radius: 50%;
  `
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, size, width, height, onChange, variant }) => css`
    border-radius: ${theme.profile.radius[size!]};
    width: ${width ? `${width}rem` : theme.profile.sizes[size!]};
    height: ${height ? `${height}rem` : theme.profile.sizes[size!]};
    overflow: hidden;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;

    img {
      height: ${theme.profile.sizes[size!]};

      ${size === 'small' &&
      css`
        margin-left: ${theme.spacings.small};
      `}
    }

    ${variant === 'rounded' && modifiers.rounded}
    ${!!onChange && modifiers.onChange(theme, size!)}
  `}
`;

export const InputFileWrapper = styled.label`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.weights.medium};
    background: ${theme.colors.blue};
    padding: ${theme.spacings.small};
    color: ${theme.colors.white};
    cursor: pointer;

    input {
      display: none;
    }
  `}
`;
