import styled, { css, DefaultTheme } from 'styled-components';

import { ThemeColors } from 'types/global';

import { InitialNameProps } from './';

export type WrapperProps = Omit<InitialNameProps, 'name' | 'profileType'>;

const modifiers = {
  noPadding: css`
    padding: 0;
  `,
  fullWidth: css`
    width: 100%;
    height: auto;
  `,
  appearance: (theme: DefaultTheme, outlineColor?: ThemeColors) => ({
    rounded: css`
      border-radius: 50%;
    `,
    square: css`
      border-radius: 0;
    `,
    outline: css`
      background: none;
      border: 1px solid ${theme.colors[outlineColor!]};
      color: ${theme.colors[outlineColor!]};
    `
  })
};

export const Wrapper = styled.div<WrapperProps>`
  ${({
    theme,
    size,
    fullWidth,
    outlineColor,
    width,
    height,
    noPadding,
    variant
  }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${width ? `${width}rem` : theme.profile.sizes[size!]};
    height: ${height ? `${height}rem` : theme.profile.sizes[size!]};
    font-size: ${theme.profile.fontSizes[size!]};
    background: ${theme.profile.colors.profileTypes};
    border: 1px solid transparent;
    border-radius: ${theme.profile.radius[size!]};
    padding: ${theme.spacings.medium} ${theme.spacings.small};
    color: ${theme.colors.white};
    font-weight: ${theme.font.weights.bold};

    ${fullWidth && modifiers.fullWidth};
    ${modifiers.appearance(theme, outlineColor)[variant!]};
    ${noPadding && modifiers.noPadding};
  `}
`;
