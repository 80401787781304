import { useEffect, useState, useCallback, useRef } from 'react';

import { ErrorMessage, Loading, StatementList } from 'components';

import { StatementContent } from 'services/statementService/types';
import { getStatements } from 'services/statementService';

import {
  convertFiltersToParams,
  Filters,
  joinStatementsById,
  sortByDate
} from 'utils/newStatementHelper';

import Divider from 'styles/shared/Divider';

import Filter from './Filter';
import * as S from './styles';

type FetchStatementsDataProps = {
  page?: number;
  filters?: Filters;
  isRealTime?: boolean;
};

function Statement() {
  const [statementFilters, setStatementFilters] = useState<Filters>({
    nsu: '',
    period: '',
    status: '',
    paymentMethod: [],
    category: [],
    periodDates: null
  });

  const [pag, setPag] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [statementsData, setStatementsData] = useState<StatementContent[]>([]);
  const [hasMoreContent, setHasMoreContent] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const statementsDataRef = useRef<any>([]);
  statementsDataRef.current = { statementsData };
  const intervalRef = useRef(0);

  const fetchStatementsData = useCallback(
    ({ filters, page = 0, isRealTime = false }: FetchStatementsDataProps) => {
      let params = {};
      const oldData = statementsDataRef.current.statementsData;
      const size = isRealTime ? 10 : 20;

      if (filters) {
        params = convertFiltersToParams(filters);
      }

      setLoading(page === 0 && !isRealTime);

      getStatements(page, size, params)
        .then(data => {
          if (!data || !data.content) {
            if (!isRealTime && page === 0) {
              setError(
                'Ocorreu um erro ao carregar as transações, atualize a página para tentar novamente.'
              );
            }
            return;
          }

          if (isRealTime && data?.content.length === 0) {
            return;
          }

          const finalStatementList = joinStatementsById(
            isRealTime || page > 0 ? oldData : [],
            data.content
          ).sort(sortByDate);

          setStatementsData(finalStatementList);

          if (!isRealTime) {
            setHasMoreContent(data.hasMore);
          }
        })
        .catch(() => {
          if (!isRealTime && page === 0) {
            setError(
              'Ocorreu um erro ao carregar as transações, atualize a página para tentar novamente.'
            );
          }
        })
        .finally(() => {
          setLoading(false);
          setLoadingMore(false);
        });
    },
    []
  );

  const startRefreshList = useCallback(() => {
    intervalRef.current = window.setInterval(() => {
      fetchStatementsData({ page: 0, isRealTime: true });
    }, 10000);
  }, [fetchStatementsData]);

  const onApplyFilters = useCallback((filtersSelected: Filters) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      startRefreshList();
    }
    setPag(0);
    setStatementFilters({ ...filtersSelected });
    fetchStatementsData({ filters: filtersSelected });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchStatementsData({});
    startRefreshList();

    return () => {
      clearInterval(intervalRef.current);
      window.onscroll = null;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.onscroll = () => {
    const { scrollTop, offsetHeight } = document.documentElement;
    const currentScroll = window.innerHeight + scrollTop + 100;

    if (
      currentScroll >= offsetHeight &&
      hasMoreContent &&
      !loading &&
      !loadingMore &&
      !error
    ) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        startRefreshList();
      }

      setLoadingMore(true);
      const nextPag = pag + 1;

      fetchStatementsData({ filters: statementFilters, page: nextPag });
      setPag(prevPage => prevPage + 1);
    }
  };

  if (!!error && pag === 0)
    return (
      <>
        <ErrorMessage message={error} />
      </>
    );

  return (
    <S.Wrapper isLoading={loading} className="list">
      {loading ? (
        <Loading fullContent />
      ) : (
        <>
          <Filter
            initialValues={statementFilters}
            onApplyFilters={onApplyFilters}
          />
          {statementsData.length > 0 && (
            <StatementList statements={statementsData} />
          )}
          {loadingMore && statementsData.length > 0 && (
            <>
              <Divider />
              <Loading />
              <Divider />
            </>
          )}
        </>
      )}

      {!loading && !error && statementsData.length === 0 && (
        <S.Message>Você não possui transações disponíveis.</S.Message>
      )}
    </S.Wrapper>
  );
}

export default Statement;
