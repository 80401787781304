import { useNavigate } from 'react-router-dom';

import * as S from './styles';
// import accounts from './__mocks__/accounts';

import PATHS_ROUTES from 'routes/paths';
import { Row, Column } from 'styles/shared/Grid';
import { useAuth } from 'contexts/Auth';
import {
  Text,
  Input,
  ProfilePicture,
  CardLink,
  //Link,
  Loading,
  TemplateShortContent
  // CardAccount
} from 'components';

import { ReactComponent as IconUser } from 'assets/images/icon-user.svg';
import { ReactComponent as IconLock } from 'assets/images/icon-lock.svg';
//import { ReactComponent as IconDocument } from 'assets/images/icon-document.svg';
import { ReactComponent as IconHelp } from 'assets/images/icon-help-outline.svg';
import { formatSimpleDate, formatInput } from 'utils/masks';
// import { ReactComponent as ArrowIcon } from 'assets/images/icon-right-next.svg';

const AccountProfile = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  if (!user) {
    return <Loading fullContent />;
  }

  return (
    <TemplateShortContent>
      <Text tag="h2" size="xxmedium" weight="medium">
        Perfil
      </Text>

      <S.ProfilePictureWrapper>
        {/* <Link to={PATHS_ROUTES.APP.ACCOUNT.EDIT_PHOTO}> */}
        <ProfilePicture
          name={user.name}
          size="large"
          image={user.profilePicture}
          changeImageLabel="Editar foto"
          // onChange={() => {}}
          disabled
        />
        {/* </Link> */}

        <Text size="small" color="grayDark">
          Criado em {formatSimpleDate(user.createdAt, 'DD/MM/YY')}
        </Text>
      </S.ProfilePictureWrapper>

      <Input
        type="text"
        name="login"
        label="Nome"
        value={user.name}
        action={() => navigate(PATHS_ROUTES.APP.ACCOUNT.UPDATE_NAME)}
        actionChildren="Alterar"
        readOnly={true}
        noBorder={true}
      />

      <Input
        type="text"
        name="birthday"
        label="Data de Nascimento"
        value={formatSimpleDate(user.birthday, 'DD/MM/YYYY')}
        action={() => navigate(PATHS_ROUTES.APP.ACCOUNT.UPDATE_BIRTHDAY)}
        actionChildren="Alterar"
        readOnly={true}
        noBorder={true}
      />

      <Input
        type="text"
        name="phone"
        label="Telefone"
        value={
          user.phoneNumber
            ? formatInput.PHONE(user.phoneNumber)
            : 'Telefone não cadastrado!'
        }
        action={() => navigate(PATHS_ROUTES.APP.ACCOUNT.UPDATE_PHONE)}
        actionChildren="Alterar"
        readOnly={true}
        noBorder={true}
      />

      <Input
        type="text"
        name="user"
        label="Usuário"
        value={user.username}
        readOnly={true}
        noBorder={true}
      />

      <Input
        name="password"
        type="password"
        disabled
        action={() => navigate(PATHS_ROUTES.APP.ACCOUNT.UPDATE_PASSWORD)}
        actionChildren="Alterar"
        label="Senha"
        value="Redefinida"
      />

      <Input
        type="text"
        name="permissions"
        label="Permissões"
        value={user.userTypeLabel}
        readOnly={true}
        noBorder={true}
      />

      {/* <>
          <Text tag="h3" size="xsmall" weight="medium">
            Líder direto
          </Text>

          <CardAccount
            title="Cláudio Vieira"
            subtitle="Gerente de conta"
            profileType="ADMIN"
          />

          <Divider />

          <Text tag="h3" size="small" weight="medium">
            Grupos
          </Text>

          <Text tag="h4" size="xsmall" color="grayDark" weight="regular">
            Grupos de que faz parte
          </Text>

          <Divider size="xsmall" />

          {accounts.map(account => (
            <CardAccount key={account.id} hidePicture {...account}>
              <Link to={PATHS_ROUTES.APP.ACCOUNTS.EDIT(account.id)}>
                <ArrowIcon />
              </Link>
            </CardAccount>
          ))}

          <Divider size="medium" />

          <Button
            as={Link}
            center
            tertiary
            to={PATHS_ROUTES.APP.ACCOUNTS.GROUP}
          >
            <Text tag="h2" size="small" weight="medium" color="blue">
              Ver todos
            </Text>
          </Button>

          <Divider />
        </> */}

      <Row>
        {user.userType === 'ADMIN' && (
          <>
            <Column xs={12}>
              <CardLink
                title="Dados do plano"
                icon={<IconUser />}
                to={PATHS_ROUTES.APP.ACCOUNT.PLAN_DATA}
              />

              <CardLink
                icon={<IconLock />}
                title="Privacidade"
                target="_blank"
                to="https://www.amedigital.com/politica-de-privacidade"
                externalLink
              />
            </Column>

            {/* <Column>
              <CardLink
                title="Documentos"
                icon={<IconDocument />}
                center
                to={PATHS_ROUTES.APP.ACCOUNT.UPLOAD_DOCUMENTS}
              />
            </Column> */}
          </>
        )}

        <Column>
          <S.CustomCardLink
            href="https://www.amedigital.com/duvidas-plus"
            target="_blank"
            rel="noreferrer"
          >
            <IconHelp />
            <Text tag="h5" color="blackDark" size="medium" weight="medium">
              Central de ajuda
            </Text>
          </S.CustomCardLink>
        </Column>
      </Row>
    </TemplateShortContent>
  );
};

export default AccountProfile;
