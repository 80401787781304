import { useState, InputHTMLAttributes, ChangeEvent } from 'react';

import * as S from './styles';

export type CodeInputProps = {
  onChangeValue?: (value: string) => void;
  onMatch(value: string): void;
  partialRegex?: RegExp;
  regex?: RegExp;
} & InputHTMLAttributes<HTMLInputElement>;

const PARTIAL_DEFAULT_REGEX = /^\d{0,4}$/;
const DEFAULT_REGEX = /^\d{4}$/;

const CodeInput = ({
  onMatch,
  onChangeValue,
  partialRegex = PARTIAL_DEFAULT_REGEX,
  regex = DEFAULT_REGEX,
  maxLength = 4,
  ...props
}: CodeInputProps) => {
  const [code, setCode] = useState('');

  function handleChangeCode(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    onChangeValue?.(value);

    if (regex.test(value)) {
      onMatch(value);
    }

    if (partialRegex.test(value)) {
      setCode(value);
    }
  }

  return (
    <S.Wrapper
      value={code}
      onChange={handleChangeCode}
      maxLength={maxLength}
      {...props}
    />
  );
};

export default CodeInput;
