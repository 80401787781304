import { fieldNames } from 'pages/Accreditation/shared/form';
import * as Yup from 'yup';
import { checkCNPJ } from 'utils/validations';

export const validation = Yup.object().shape({
  [fieldNames.cnpj]: Yup.mixed()
    .required('CNPJ obrigatório')
    .test('is a valid document number', 'CNPJ inválido', value =>
      checkCNPJ((value as string) || '')
    ),
  [fieldNames.social]: Yup.string().required('Razão social obrigatória'),
  [fieldNames.segment]: Yup.string().required('Tipo de negócio obrigatório')
});
