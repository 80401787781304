import styled, { css } from 'styled-components';

import { WarningBoxProps } from './';

const modifiers = {
  block: () => css`
    display: block;
    width: 100%;
  `
};

export const Wrapper = styled.div<WarningBoxProps>`
  ${({ theme, block }) => css`
    padding: ${theme.spacings.medium};
    border: 1px solid ${theme.colors.yellowDark};
    border-radius: ${theme.spacings.medium};

    ${block && modifiers.block()}
  `}
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacings.small};

    svg {
      margin-right: ${theme.spacings.xsmall};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grayDark};
    font-size: ${theme.font.sizes.xsmall};
  `}
`;
