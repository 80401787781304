import styled, { css } from 'styled-components';
import { Input } from 'components';

export const InputContainer = styled.div`
  position: relative;
`;

export const InputText = styled(Input)`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.grayNeutralDark};

    &::placeholder {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.grayNeutralDark};
    }
  `}
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: ${theme.spacings.xsmall};
    margin-right: ${theme.spacings.medium};
  `}
`;
