import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Formik, Form } from 'formik';

import PATHS_ROUTES from 'routes/paths';

import useMutate from 'hooks/useMutate';
import useToggle from 'hooks/useToggle';

import endpoints from 'services/endpoints';

import {
  Button,
  Text,
  Loading,
  ModalStatus,
  TemplateCloseContent
} from 'components';

import { Row, Column } from 'styles/shared/Grid';
import Divider from 'styles/shared/Divider';

import { useBank } from 'contexts/Bank';

import { useBanks } from './useBanks';
import AccountRegistrationForm from './AccountRegistrationForm';
import * as formConfig from './registrationAccountFormConfig';
import AccountRegistrationConfirmDialog from './AccountRegistrationConfirmDialog';
import { formatAccount } from 'utils/masks';
import useRequest from 'hooks/useRequest';
import { IBankAccountData } from 'services/bankService/types';

interface Texts {
  title: string;
  button: string;
  modal: {
    title: string;
    success: string;
    error: string;
  };
}

const texts = {
  default: {
    title: 'Adicionar conta',
    button: 'Cadastrar',
    modal: {
      title: 'Adicionar conta',
      success: 'Conta cadastrada com sucesso',
      error: 'Falha ao cadastrar conta'
    }
  },
  edit: {
    title: 'Editar conta',
    button: 'Atualizar',
    modal: {
      title: 'Editar conta',
      success: 'Conta atualizada com sucesso',
      error: 'Falha ao atualizar conta'
    }
  }
};

type AccountRegistrationProps = {
  edit?: boolean;
};

function hasBankAccount(
  currentAccounts: IBankAccountData[] | undefined,
  account: formConfig.BankAccountValues
) {
  return currentAccounts?.some(currentAcc => {
    return (
      currentAcc.account === account.account &&
      currentAcc.agency === account.agency &&
      currentAcc.bank === account.bank &&
      currentAcc.type === account.type
    );
  });
}

export const AccountRegistration = ({ edit }: AccountRegistrationProps) => {
  const { data: currentAccounts, loading } = useRequest<IBankAccountData[]>({
    url: endpoints.bankAccount
  });

  const banks = useBanks();
  const modal = useToggle();
  const errorModal = useToggle();
  const confirm = useToggle();
  const navigate = useNavigate();
  const { state, mutate } = useMutate();
  const { editingAccount } = useBank();

  const isEditing = React.useMemo(
    () => editingAccount && edit,
    [edit, editingAccount]
  );

  const initialValues =
    isEditing && editingAccount
      ? ({
          bank: editingAccount.bank,
          agency: editingAccount.agency,
          account: editingAccount.account,
          type: editingAccount.type
        } as formConfig.BankAccountValues)
      : formConfig.initialValues;

  const labels: Texts = isEditing ? texts.edit : texts.default;

  const handleSubmit = React.useCallback(
    (values: formConfig.BankAccountValues) => {
      confirm.toggle();

      if (!confirm.isActive) {
        return;
      }

      if (hasBankAccount(currentAccounts, values)) {
        errorModal.toggle();

        return;
      }

      const endpoint =
        isEditing && editingAccount
          ? endpoints.bankAccountUpdate.replace(
              '{accountId}',
              editingAccount?.id
            )
          : endpoints.bankAccount;
      const method = isEditing ? 'put' : 'post';

      mutate({
        body: {
          ...values,
          account: formatAccount(values.account)
        },
        onSuccess: modal.toggle,
        onFailure: modal.toggle,
        method,
        endpoint
      });
    },
    [
      confirm,
      currentAccounts,
      isEditing,
      editingAccount,
      mutate,
      modal.toggle,
      errorModal
    ]
  );

  const initialStatus = React.useMemo<formConfig.BankAccountStatus>(
    () => ({ banks, closeConfirmDialog: confirm.toggle }),
    [banks, confirm]
  );

  const handleCloseModal = React.useCallback(() => {
    if (!state.data) {
      return modal.toggle();
    }

    navigate(PATHS_ROUTES.APP.BANK.LIST);
  }, [modal, navigate, state.data]);

  const printError = React.useCallback(() => {
    return state.error || labels.modal.error;
  }, [labels.modal.error, state.error]);

  return (
    <TemplateCloseContent
      closeTitle="Fechar"
      closeLink={PATHS_ROUTES.APP.BANK.LIST}
    >
      {(loading || state.loading) && <Loading fullContent />}

      <Text size="xxmedium" weight="bold">
        {labels.title}
      </Text>

      <Text>Preencha os dados da conta</Text>

      <Divider size="large" />

      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialStatus={initialStatus}
        validationSchema={formConfig.schema}
        initialValues={initialValues}
      >
        <Form>
          <AccountRegistrationForm />

          <Divider size="large" />

          <Row>
            <Column xs={12} md={8} offsetMd={2}>
              <Button
                filled
                fullWidth
                type="submit"
                disabled={modal.isActive || state.loading}
              >
                {labels.button}
              </Button>
            </Column>
          </Row>

          {confirm.isActive && <AccountRegistrationConfirmDialog />}
        </Form>
      </Formik>

      {errorModal.isActive && (
        <ModalStatus
          title="Conta já cadastrada"
          variant="error"
          onClose={errorModal.toggle}
          action={{ onClick: errorModal.toggle }}
        />
      )}

      {modal.isActive && (
        <ModalStatus
          title={labels.modal.title}
          variant={state.data ? 'success' : 'error'}
          onClose={handleCloseModal}
          action={{ onClick: handleCloseModal }}
        >
          <Text size="small" color="grayNeutralDark">
            {state.data ? labels.modal.success : printError()}
          </Text>
        </ModalStatus>
      )}
    </TemplateCloseContent>
  );
};
