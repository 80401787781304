import styled, { css } from 'styled-components';

export const WrapperSelect = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid ${theme.colors.grayDark};
    padding: 0.5rem 0;
    cursor: pointer;
  `}
`;
