import styled, { css } from 'styled-components';
import Card from 'styles/shared/Card';
import { Select, Flex } from 'components';
import customMedia from 'styles/media-query';

type OrderIconProps = {
  isCresc: boolean;
};

export const TableHeader = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium};
  `}
`;

export const HeaderInputs = styled(Flex)`
  gap: 20px;
  ${customMedia.lessThan('medium')`
        margin-top: 30px;

        > div {
          width: 100%;
        }
  `}
`;

export const TableCard = styled(Card)`
  padding: 0;
  min-height: 50rem;
`;

export const TableRow = styled.tr`
  ${({ theme }) => css`
    padding-left: ${theme.spacings.medium};
  `}
`;

export const FilterDropdown = styled(Select)`
  ${({ theme }) => css`
    width: 18rem;
    border: 1px solid ${theme.colors.grayMedium};
    border-radius: ${theme.spacings.small};
    padding-left: ${theme.spacings.small};
    height: 45px;
  `}
`;

export const FilterClick = styled(Flex)`
  cursor: default;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const OrderIcon = styled.img<OrderIconProps>`
  ${({ isCresc }) => isCresc && 'transform: rotate(180deg);'}
`;
