import styled, { css, DefaultTheme } from 'styled-components';
import { InputFileStatus } from '.';

export const Input = styled.input`
  display: none;
`;

export const Label = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.grayBlue};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.weights.bold};
    line-height: ${theme.font.lineHeight('xsmall')};
  `}
`;

const modifierStatus = {
  processing: (theme: DefaultTheme) => css`
    color: ${theme.colors.orangeCategoryWaitingProcessing};

    &::before {
      background-color: ${theme.colors.orangeCategoryWaitingProcessing};
    }
  `,
  approved: (theme: DefaultTheme) => css`
    color: ${theme.colors.blueCategoryColorSuccess};

    &::before {
      background-color: ${theme.colors.blueCategoryColorSuccess};
    }
  `,
  refused: (theme: DefaultTheme) => css`
    color: ${theme.colors.red};

    &::before {
      background-color: ${theme.colors.red};
    }
  `
};

type StatusProps = {
  status?: InputFileStatus;
};

export const Status = styled.span<StatusProps>`
  ${({ theme, status }) => css`
    margin-left: 0.7rem;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.weights.bold};
    line-height: ${theme.font.lineHeight('xsmall')};

    ${status && modifierStatus[status](theme)};

    &::before {
      content: '';
      height: 1rem;
      width: 1rem;
      display: inline-block;
      border-radius: 0.5rem;
      margin-right: 0.7rem;
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 3.5rem;
    display: flex;
    align-items: center;
    border-bottom: 2px solid ${theme.colors.grayMedium};
  `}
`;

export const FileName = styled.p`
  ${({ theme }) => css`
    display: flex;
    flex-grow: 1;
    color: ${theme.colors.blackDark};
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xmedium};
  `}
`;

export const AlertMessageWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacings.xsmall};

    & svg {
      margin-right: ${theme.spacings.xsmall};
    }
  `}
`;
