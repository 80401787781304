import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

import Card from 'styles/shared/Card';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    & > p {
      font-size: ${theme.font.sizes.xsmall};
      line-height: ${theme.font.lineHeight('xsmall')};
      margin-bottom: ${theme.spacings.small};
      font-weight: ${theme.font.weights.medium};
    }
  `}
`;

export const CardOptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;

    button {
      margin-right: ${theme.spacings.small};
      line-height: ${theme.font.lineHeight('xxsmall')};
      margin-bottom: ${theme.spacings.small};
      font-weight: ${theme.font.weights.light};

      &:last-child {
        margin-right: 0;
      }

      strong {
        display: block;
        font-size: ${theme.font.sizes.xsmall};
        line-height: ${theme.font.lineHeight('xsmall')};
      }

      span {
        display: block;
        margin-top: ${theme.spacings.xsmall};
        font-size: ${theme.font.sizes.xsmall};
      }

      ${customMedia.greaterThan('medium')`
        margin-bottom: ${theme.spacings.medium};
        font-size: ${theme.font.sizes.small};
        line-height: ${theme.font.lineHeight('small')};

        strong{
          font-size: ${theme.font.sizes.small};
          line-height: ${theme.font.lineHeight('small')};
        }
      `}
    }

    ${Card} {
      width: auto;
      margin-bottom: 0;
      padding: 1.6rem ${theme.spacings.small};
      font-size: ${theme.font.sizes.xsmall};

      ${customMedia.greaterThan('medium')`
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      `}

      &:not(.active):hover {
        background: ${transparentize(0.9, theme.colors.blue)};
        border-color: transparent;
      }

      &.active {
        color: ${theme.colors.white};
        background: ${theme.colors.blue};
        border: 1px solid ${theme.colors.blue};

        svg {
          color: ${theme.colors.white};
        }
      }
    }
  `}
`;
