import { useMemo } from 'react';
import PageItem from '../Item';

import * as S from './styles';

export type PageLabelsProps = {
  pageLabels: string[];
  currentPage: string;
  onClick?: (page: string) => void;
  withProgress?: boolean;
};

const PageLabels = ({
  pageLabels,
  currentPage,
  onClick,
  withProgress = false
}: PageLabelsProps) => {
  const indexOfCurrentPage = useMemo(() => {
    return pageLabels.indexOf(currentPage);
  }, [currentPage, pageLabels]);

  return (
    <S.Wrapper>
      {pageLabels.map((label, idx) => (
        <S.Item
          key={label}
          center={idx !== pageLabels.length - 1 && idx !== 0}
          last={idx === pageLabels.length - 1}
        >
          <S.StepAndProgressItem>
            {idx !== 0 && (
              <S.ProgressBar
                show={withProgress}
                active={indexOfCurrentPage >= idx}
              />
            )}

            <PageItem
              filled
              large
              action={() => !!onClick && onClick(label)}
              active={idx <= indexOfCurrentPage}
            >
              {idx + 1}
            </PageItem>

            {idx !== pageLabels.length - 1 && (
              <S.ProgressBar
                show={withProgress}
                active={indexOfCurrentPage >= idx}
              />
            )}
          </S.StepAndProgressItem>

          <S.TextItem>{label}</S.TextItem>
        </S.Item>
      ))}
    </S.Wrapper>
  );
};

export default PageLabels;
