import { Moment } from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {
  FocusedInputShape,
  DayPickerRangeController,
  DayPickerRangeControllerShape
} from 'react-dates';

import * as S from './styles';

type UseDateRangeHookParams = {
  dates?: { startDate: string | null; endDate: string | null };
  resetDates?: () => void;
  setStartDate: (date: Moment | null) => void;
  setEndDate: (date: Moment | null) => void;
  isOutsideRange: (day: Moment) => boolean;
  onFocusChange: (focused: FocusedInputShape | null) => void;
  focusedInput: FocusedInputShape | null;
  startDate: Moment | null;
  endDate: Moment | null;
};

interface DateRange {
  staticParams: UseDateRangeHookParams;
}

const DateRange = ({
  staticParams,
  ...params
}: DateRange & Partial<DayPickerRangeControllerShape>) => {
  return (
    <S.Wrapper>
      <DayPickerRangeController
        {...params}
        onDatesChange={dates => {
          staticParams.setStartDate(dates.startDate);
          staticParams.setEndDate(dates.endDate);
          if (params.onDatesChange) params.onDatesChange(dates);
        }}
        isOutsideRange={params.isOutsideRange || staticParams.isOutsideRange}
        onFocusChange={params.onFocusChange || staticParams.onFocusChange}
        focusedInput={params.focusedInput || staticParams.focusedInput}
        startDate={params.startDate || staticParams.startDate}
        endDate={params.endDate || staticParams.endDate}
        initialVisibleMonth={params.initialVisibleMonth || null}
        numberOfMonths={params.numberOfMonths || 1}
      />
    </S.Wrapper>
  );
};

export default DateRange;
