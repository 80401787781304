import useRequest from 'hooks/useRequest';

import * as S from './styles';
import {
  Breadcrumb,
  TemplateShortContent,
  Text,
  Accordion,
  Loading,
  ErrorMessage
} from 'components';
import Margin from 'styles/shared/Margin';

import endpoints from 'services/endpoints';
import { IGetFaqData } from 'services/pixService/types';

import { breadcrumbItems } from './constants';

const Faq = () => {
  const { data, error, loading } = useRequest<IGetFaqData[]>({
    url: endpoints.faq
  });

  return (
    <>
      {loading && <Loading fullContent fullWidth />}

      <Breadcrumb links={breadcrumbItems} />

      {error ? (
        <ErrorMessage />
      ) : (
        <TemplateShortContent>
          <Margin my="large">
            <Text weight="bold" size="xmedium">
              O que é Pix?
            </Text>
          </Margin>

          <S.CardWrapper>
            {data &&
              data.map(item => (
                <Accordion key={item.title} title={item.title}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.description
                    }}
                  />
                </Accordion>
              ))}

            <Text color="grayNeutralDark" mt="large">
              Ainda tem dúvidas? Entre em contato com nossa central de
              atendimento pelo telefone{' '}
              <Text color="grayNeutralDark" weight="bold" tag="span">
                4003-9975{' '}
              </Text>
              de segunda a sexta das{' '}
              <Text color="grayNeutralDark" weight="bold" tag="span">
                09h às 18h.
              </Text>
            </Text>
          </S.CardWrapper>
        </TemplateShortContent>
      )}
    </>
  );
};

export default Faq;
