import { ReactComponent as IconDownloadBlue } from 'assets/images/icon-download-all-blue.svg';

import { Text, Loading, Flex, Link, ErrorMessage } from 'components';
import { Divider } from 'styles/shared/Divider';
import { Column, Row } from 'styles/shared/Grid';

import { useTrainingPlaylist } from './use-training-playlist';

import * as S from './styles';
import PATHS from 'routes/paths';
import { useQuery } from 'hooks/useQuery';

function TrainingPlayer() {
  const params = useQuery();

  const { videos, watching, error, loading, saveWatchedVideo } =
    useTrainingPlaylist({
      video: params.get('video')
    });

  function handleDownload() {
    const a = document.createElement('a');
    a.href =
      'https://images.amedigital.com/seller/Portal%20Minha%20Conta%20-%20completo.pdf';
    a.target = 'blank';
    a.rel = 'noreferrer';
    a.click();
  }

  if (error) {
    return (
      <ErrorMessage>
        Ocorreu um erro ao carregar os dados. <br />
        Atualize a página para tentar novamente.
      </ErrorMessage>
    );
  }

  if (loading || !watching) {
    return <Loading fullContent />;
  }

  return (
    <>
      <Text tag="h1" size="xxmedium" weight="bold">
        Encontre treinamentos rápidos e didáticos que te ajudam a gerenciar seu
        negócio na Ame ;)
      </Text>

      <Divider size="large" />

      <Row>
        <Column xs={12} xl={8}>
          <S.Video
            videoId={watching.youtubeId}
            onPlay={() => saveWatchedVideo(watching.id)}
            opts={{
              playerVars: {
                autoplay: 0,
                controls: 2,
                modestbranding: 1,
                rel: 0,
                showinfo: 0
              }
            }}
          />

          <Divider />

          <Text tag="h2" size="xxmedium">
            {watching.title}
          </Text>

          <Divider size="small" />

          <Text tag="p" size="xsmall">
            Ainda com dúvidas? Entre em contato com o nosso
            <Text tag="span" size="xsmall" color="blue">
              {' SAC '}
            </Text>
            no número
            <Text tag="span" size="xsmall" color="blue">
              {' 4004-2020 '}
            </Text>
          </Text>

          <Divider />
        </Column>

        <Column xs={12} xl={4}>
          <S.PlaylistWrapper>
            <S.PlaylistHeader>
              <Text tag="p" weight="bold" size="small" color="white">
                Próximos vídeos
              </Text>

              <Link to={PATHS.APP.TRAINING.LIST}>
                <Text tag="p" size="xsmall" color="white">
                  {'Ver todos >'}
                </Text>
              </Link>
            </S.PlaylistHeader>

            <S.Playlist>
              {videos.map(video => (
                <Link
                  key={video.id}
                  to={`${PATHS.APP.TRAINING.HOME}?video=${video.id}`}
                >
                  <S.Item>
                    <S.Thumbnail src={video.thumbUrl} alt={video.title} />

                    <Flex
                      wrap="nowrap"
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Text tag="p" size="xsmall">
                        {video.title}
                      </Text>

                      {video.watched && (
                        <S.WatchedText>Visualizado</S.WatchedText>
                      )}
                    </Flex>
                  </S.Item>
                </Link>
              ))}
            </S.Playlist>

            <Flex
              justifyContent="space-between"
              alignItems="center"
              mt="medium"
            >
              <Text weight="bold" size="small">
                Treinamento do Portal em PDF
              </Text>

              <S.ButtonWrapper
                role="button-download"
                onClick={() => handleDownload()}
                tertiary
              >
                Baixar <IconDownloadBlue />
              </S.ButtonWrapper>
            </Flex>
          </S.PlaylistWrapper>
        </Column>
      </Row>
    </>
  );
}

export default TrainingPlayer;
