import { AxiosResponse } from 'axios';
import { DEFAULT_RESPONSE_SUCCESS } from 'utils/constants';

import * as types from '../types';

export const loginWithPasswordResponseSuccess: AxiosResponse<types.LoginWithPasswordData> =
  {
    ...DEFAULT_RESPONSE_SUCCESS,
    data: {
      request_verification: true,
      sendTo: 'se......@amedigital.com',
      type: 'EMAIL'
    }
  };
