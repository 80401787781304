import { ReactNode, useCallback, useState } from 'react';

import * as S from './styles';

export type PaginationStepInLineProps = {
  children: ReactNode;
  itemsLength: number;
  changeStep: (e: number) => void;
};

const StepInLine = ({
  children,
  changeStep,
  itemsLength
}: PaginationStepInLineProps) => {
  const [step, setStep] = useState(0);
  const maxStep = itemsLength - 1;
  const values = Array.from(Array(itemsLength).keys());

  const handleChangeNextStep = useCallback(() => {
    setStep(step + 1);
    changeStep(step + 1);
  }, [changeStep, step]);

  const handleChangePrevStep = useCallback(() => {
    setStep(step - 1);
    changeStep(step - 1);
  }, [changeStep, step]);

  return (
    <>
      <S.Wrapper>
        {step !== 0 ? (
          <S.ArrowLeft
            onClick={handleChangePrevStep}
            data-testid="arrow-left"
          />
        ) : (
          <S.NoArrow />
        )}
        {children}
        {step !== maxStep ? (
          <S.ArrowRight
            onClick={handleChangeNextStep}
            data-testid="arrow-right"
          />
        ) : (
          <S.NoArrow />
        )}
      </S.Wrapper>

      <S.Steps data-testid="steps">
        {values.map((_, id) => (
          <S.StepsItem key={id} filled={id < step} disabled={id > step} />
        ))}
      </S.Steps>
    </>
  );
};

export default StepInLine;
