import * as React from 'react';
import { useFormikContext } from 'formik';
import { Text, Button, TemplateModal } from 'components';
import { SelectOption } from 'components/Select';
import Divider from 'styles/shared/Divider';
import { Row, Column, Container } from 'styles/shared/Grid';
import { BankAccountValues } from './registrationAccountFormConfig';
import { formatAccount } from 'utils/masks';

const AccountRegistrationConfirmDialog = () => {
  const { status, values } = useFormikContext<BankAccountValues>();
  const { type, bank, agency, account } = values;

  const institution = React.useMemo(() => {
    const opt = (status.banks as SelectOption[]).find(
      ({ value }) => value === bank
    );

    return opt?.label;
  }, [bank, status.banks]);

  return (
    <TemplateModal
      title="Adicionar conta"
      closeModal={status.closeConfirmDialog}
      subtitle="Confirme os dados da conta cadastrada"
    >
      <Container style={{ padding: 0 }}>
        <Row>
          <Column xs={12} md={6}>
            <Text size="xsmall">Tipo de conta:</Text>
            <Text size="small">
              {`Conta ${type === 'CORRENTE' ? 'corrente' : 'poupança'}`}
            </Text>
            <Divider size="small" />
          </Column>

          <Column xs={12} md={6}>
            <Text size="xsmall">Instituição:</Text>
            <Text size="small">{institution}</Text>
            <Divider size="small" />
          </Column>
        </Row>

        <Row>
          <Column xs={12} md={6}>
            <Text size="xsmall">Agência:</Text>
            <Text size="small">{agency}</Text>
            <Divider size="small" />
          </Column>

          <Column xs={12} md={6}>
            <Text size="xsmall">Conta (com dígito):</Text>
            <Text size="small">{formatAccount(account)}</Text>
            <Divider size="small" />
          </Column>
        </Row>

        <Divider size="medium" />

        <Row>
          <Column md={12}>
            <Button type="submit" center filled>
              Confirmar
            </Button>
          </Column>
        </Row>
      </Container>
    </TemplateModal>
  );
};

export default AccountRegistrationConfirmDialog;
