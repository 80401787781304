import { Button, Link, Text, Flex, TemplateModal } from 'components';

import Divider from 'styles/shared/Divider';

import PATHS_ROUTES from 'routes/paths';

import * as S from './styles';

const ModalUpdateUser = () => (
  <S.Wrapper>
    <TemplateModal title="Atualização cadastral" layer="alwaysOnTop">
      <Flex direction="column" alignItems="center" justifyContent="center">
        <Text weight="bold" mb="large">
          Atualize seus dados
        </Text>

        <Text color="grayDark" mx="large" mb="large">
          Olá! para maior segurança de suas informações, atualizamos os dados de
          contato de sua conta, para atualizar suas novas informações, clique no
          botão abaixo :)
        </Text>

        <Button as={Link} filled to={PATHS_ROUTES.APP.UPDATE_USER}>
          Atualizar dados
        </Button>

        <Divider size="xlarge" />
      </Flex>
    </TemplateModal>
  </S.Wrapper>
);

export default ModalUpdateUser;
