import styled, { css, DefaultTheme } from 'styled-components';
import { ReactComponent as SvgIconClose } from 'assets/images/icon-big-arrow-right.svg';

type StepsItemProps = {
  disabled?: boolean;
  filled?: boolean;
};

const modifierWrapper = {
  iconHover: () => css`
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  `,
  stepDisabled: (theme: DefaultTheme) => css`
    background: ${theme.colors.grayExtraMedium};
    width: 1.3rem;
  `,
  filled: () => css`
    width: 1.3rem;
  `
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ArrowLeft = styled(SvgIconClose)`
  transform: rotate(180deg);
  width: 3.3rem;

  ${modifierWrapper.iconHover()};
`;

export const ArrowRight = styled(SvgIconClose)`
  width: 3.3rem;

  ${modifierWrapper.iconHover()};
`;

export const NoArrow = styled.div`
  width: 3.3rem;
`;

export const Steps = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export const StepsItem = styled.div<StepsItemProps>`
  ${({ theme, disabled, filled }) => css`
    width: 2.7rem;
    height: 1rem;
    background: ${theme.colors.blue};

    ${disabled && modifierWrapper.stepDisabled(theme)}
    ${filled && modifierWrapper.filled()}
  `}
`;
