import useTimer from 'hooks/useTimer';
import { useFormikContext } from 'formik';
import { CodeInput, Flex, Loading, Text, TimeText } from 'components';
import { StepProps } from 'contexts/AccreditationSteps/types';
import { AccreditationValues } from 'pages/Accreditation/shared/form';

import * as S from './styles';
import { useSendCode } from './use-send-email-code';
import { useEffect } from 'react';

const Step5 = ({ names }: StepProps) => {
  const form = useFormikContext<AccreditationValues>();

  const { setFieldError } = form;

  const { resend, error, loading } = useSendCode(form.values[names.email]);

  const { time, restartTimer } = useTimer({
    decrement: true
  });

  const showLoading = loading || form.isSubmitting;

  const handleMatch = async () => {
    setTimeout(form.handleSubmit);
  };

  useEffect(() => {
    if (error) {
      setFieldError(names.email, error);
    }
  }, [error, setFieldError, names.email]);

  return (
    <>
      {showLoading && <Loading fullContent fullWidth />}

      <S.Title>Agora é só validar o e-mail</S.Title>

      <S.Subtitle>Digite o código que enviamos para o seu e-mail</S.Subtitle>

      <S.EmailWrapper>
        <S.Email>{form.values[names.email]}</S.Email>
      </S.EmailWrapper>

      <Flex alignItems="center" direction="column" my="xlarge">
        <CodeInput
          id={names.validateEmail}
          name={names.validateEmail}
          onChangeValue={value =>
            form.setFieldValue(names.validateEmail, value, false)
          }
          onMatch={handleMatch}
          onBlur={form.handleBlur}
        />

        <Text color="red" size="xsmall">
          {form.errors[names.validateEmail]}
        </Text>

        <TimeText
          time={time}
          resendCode={() => {
            resend();
            restartTimer();
          }}
        />
      </Flex>
    </>
  );
};

export default Step5;
