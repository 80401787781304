import styled, { css } from 'styled-components';

interface ItemProps {
  center: boolean;
  last: boolean;
}

interface ProgressBarProps {
  show: boolean;
  active: boolean;
}

export const Wrapper = styled.nav`
  display: flex;
`;

export const Item = styled.div<ItemProps>`
  ${({ center, last }) => css`
    flex: 1;

    ${center && `text-align: center`};
    ${last && `text-align: end`};
  `}
`;

export const StepAndProgressItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const TextItem = styled.p`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xsmall};
    font-size: ${theme.font.sizes.small};
  `}
`;

export const ProgressBar = styled.div<ProgressBarProps>`
  ${({ show, active, theme }) => css`
    flex: 1;
    height: 5px;

    ${!show && `visibility: hidden`};

    ${active && `background-color: ${theme.colors.blue}`};
    ${!active && `background-color: ${theme.colors.grayNeutralLight}`};
  `}
`;
