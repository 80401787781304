import iconSale from 'assets/images/icon-receive.svg';
import iconTransfer from 'assets/images/icon-transfer-outline.svg';
import iconRefund from 'assets/images/icon-receive-invert.svg';
import iconDeposit from 'assets/images/icon-deposit-outline.svg';

import { StatementContent } from 'services/statementService/types';
import { RequestParams } from 'types/global';

export type Categories = 'sale' | 'refund' | 'withdraw' | '';

export type PeriodFilter = { startDate: string | null; endDate: string | null };

export type Filters = {
  period: string;
  status: string;
  category: string[];
  nsu: string;
  paymentMethod: string[];
  periodDates: PeriodFilter | null;
};

export const STATEMENT_STATUS = {
  AUTHORIZED: 'AUTHORIZED',
  CANCELLED: 'CANCELLED',
  CAPTURED: 'CAPTURED',
  CREATED: 'CREATED',
  DENIED: 'DENIED',
  DENIED_BY_RISK: 'DENIED_BY_RISK',
  PENDING: 'PENDING',
  REFUNDED: 'REFUNDED',
  RELEASED: 'RELEASED'
};

export type StatementStatus = keyof typeof STATEMENT_STATUS | string;

export const STATEMENT_TRANSLATED_STATUS = {
  CANCELED: 'Cancelado',
  APPROVED: 'Aprovado',
  PROCESSING: 'Em processamento'
};

export type StatementTranslatedStatus =
  | 'Cancelado'
  | 'Aprovado'
  | 'Em processamento';

export const translateStatus = (status: StatementStatus, isSale: boolean) => {
  switch (status) {
    case STATEMENT_STATUS.CANCELLED:
    case STATEMENT_STATUS.DENIED:
    case STATEMENT_STATUS.DENIED_BY_RISK:
      return STATEMENT_TRANSLATED_STATUS.CANCELED;
    case STATEMENT_STATUS.RELEASED:
    case STATEMENT_STATUS.CAPTURED:
    case STATEMENT_STATUS.REFUNDED:
      return STATEMENT_TRANSLATED_STATUS.APPROVED;
    case STATEMENT_STATUS.AUTHORIZED:
      return isSale
        ? STATEMENT_TRANSLATED_STATUS.APPROVED
        : STATEMENT_TRANSLATED_STATUS.PROCESSING;
    default:
      return STATEMENT_TRANSLATED_STATUS.PROCESSING;
  }
};

export const NEW_STATEMENT_TYPE = {
  PURCHASE: 'PURCHASE',
  CASH_IN: 'CASH_IN',
  RELEASE: 'RELEASE',
  TRANSFER_BETWEEN_WALLETS: 'TRANSFER_BETWEEN_WALLETS',
  REFUND: 'REFUND',
  CASH_BACK: 'CASH_BACK',
  GIFT_CASH_IN: 'GIFT_CASH_IN',
  GIFT_CASHBACK_IN: 'GIFT_CASHBACK_IN',
  STORE_CASH_IN: 'STORE_CASH_IN',
  STORE_CASH_OUT: 'STORE_CASH_OUT',
  CASH_OUT: 'CASH_OUT',
  BANK_CASH_IN: 'BANK_CASH_IN',
  TAX: 'TAX',
  MOBILE_RECHARGE: 'MOBILE_RECHARGE',
  BILL_PAYMENT: 'BILL_PAYMENT',
  LOAN_CASH_IN: 'LOAN_CASH_IN',
  SERVICE: 'SERVICE'
};

export type NewStatementType = keyof typeof NEW_STATEMENT_TYPE | string;

export const NEW_STATEMENT_TRANSLATED_TYPE = {
  PURCHASE: 'Compra',
  CASH_IN: 'Depósito',
  RELEASE: 'Venda',
  TRANSFER: 'Transferência',
  REFUND: 'Estorno',
  CASH_BACK: 'Cashback',
  GIFT_CASH_IN: 'Depósito',
  MOBILE_RECHARGE: 'Recarga de celular',
  CASH_OUT: 'Saque',
  TAX: 'Saque',
  BANK_CASH_IN: 'Transferência',
  GIFT_CASHBACK_IN: 'Depósito',
  STORE_CASH_IN: 'Depósito',
  STORE_CASH_OUT: 'Saque',
  BILL_PAYMENT: 'Pagamento',
  LOAN_CASH_IN: 'LOAN_CASH_IN',
  SERVICE: 'SERVICE'
};

export type StatementTranslatedType =
  | 'Transferência'
  | 'Estorno'
  | 'Depósito'
  | 'Incentivo'
  | 'Saque'
  | 'Cashback'
  | 'Venda'
  | 'Compra'
  | 'Pagamento';

export const STATEMENT_OPERATION_TYPE = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT'
};

export type StatementOperationType =
  | keyof typeof STATEMENT_OPERATION_TYPE
  | string;

export const translateTypes = (type: NewStatementType) => {
  switch (type) {
    case NEW_STATEMENT_TYPE.TRANSFER_BETWEEN_WALLETS:
    case NEW_STATEMENT_TYPE.BANK_CASH_IN:
      return NEW_STATEMENT_TRANSLATED_TYPE.TRANSFER;

    case NEW_STATEMENT_TYPE.REFUND:
      return NEW_STATEMENT_TRANSLATED_TYPE.REFUND;

    case NEW_STATEMENT_TYPE.CASH_IN:
    case NEW_STATEMENT_TYPE.GIFT_CASH_IN:
    case NEW_STATEMENT_TYPE.STORE_CASH_IN:
    case NEW_STATEMENT_TYPE.GIFT_CASHBACK_IN:
      return NEW_STATEMENT_TRANSLATED_TYPE.CASH_IN;

    case NEW_STATEMENT_TYPE.STORE_CASH_OUT:
    case NEW_STATEMENT_TYPE.CASH_OUT:
    case NEW_STATEMENT_TYPE.TAX:
      return NEW_STATEMENT_TRANSLATED_TYPE.CASH_OUT;

    case NEW_STATEMENT_TYPE.CASH_BACK:
      return NEW_STATEMENT_TRANSLATED_TYPE.CASH_BACK;

    case NEW_STATEMENT_TYPE.RELEASE:
      return NEW_STATEMENT_TRANSLATED_TYPE.RELEASE;

    case NEW_STATEMENT_TYPE.PURCHASE:
      return NEW_STATEMENT_TRANSLATED_TYPE.PURCHASE;

    case NEW_STATEMENT_TYPE.BILL_PAYMENT:
      return NEW_STATEMENT_TRANSLATED_TYPE.BILL_PAYMENT;

    case NEW_STATEMENT_TYPE.MOBILE_RECHARGE:
      return NEW_STATEMENT_TRANSLATED_TYPE.MOBILE_RECHARGE;

    default:
      return '';
  }
};

export const groupedByDate = (statements: StatementContent[]) => {
  const grouped: Map<string, StatementContent[]> = new Map();

  statements.forEach(item => {
    const year = item.date.substr(0, 4);
    const month = item.date.substr(5, 2);

    const monthYearGroup = `${year}-${month}-01`;

    const groupedData = grouped.get(monthYearGroup);

    if (groupedData) {
      grouped.set(monthYearGroup, [...groupedData, item]);
    } else {
      grouped.set(monthYearGroup, [item]);
    }
  });

  return Array.from(grouped, ([date, items]) => ({ date, items }));
};

export const renderTypeIcon = (type: string) => {
  const iconProps = {
    width: 33,
    height: 33
  };

  switch (type) {
    case NEW_STATEMENT_TYPE.TRANSFER_BETWEEN_WALLETS:
    case NEW_STATEMENT_TYPE.BANK_CASH_IN:
      return <img src={iconTransfer} {...iconProps} alt="Transferência" />;

    case NEW_STATEMENT_TYPE.RELEASE:
      return <img src={iconSale} {...iconProps} alt="Venda" />;

    case NEW_STATEMENT_TYPE.PURCHASE:
    case NEW_STATEMENT_TYPE.REFUND:
      return <img src={iconRefund} {...iconProps} alt="Estorno" />;

    case NEW_STATEMENT_TYPE.CASH_IN:
    case NEW_STATEMENT_TYPE.STORE_CASH_IN:
    case NEW_STATEMENT_TYPE.GIFT_CASHBACK_IN:
    case NEW_STATEMENT_TYPE.GIFT_CASH_IN:
      return <img src={iconDeposit} {...iconProps} alt="Deposito" />;

    default:
      return null;
  }
};

export const sortByDate = (a: StatementContent, b: StatementContent) =>
  new Date(b.date).getTime() - new Date(a.date).getTime();

export const getTypeByFilterCategory = (category: Categories[]) => {
  const types = {
    // vendas
    sale: {
      types: null,
      transactionTypes: STATEMENT_OPERATION_TYPE.CREDIT
    },
    // Retiradas
    withdraw: {
      types: null,
      transactionTypes: STATEMENT_OPERATION_TYPE.DEBIT
    },
    // Estorno
    refund: {
      types: `${NEW_STATEMENT_TYPE.REFUND}`,
      transactionTypes: null
    },
    '': { types: '', transactionTypes: '' }
  };

  const transactionTypes = category.map(cat => types[cat].transactionTypes);
  const type = category.map(cat => types[cat].types);

  return {
    types: type.filter(val => val).join(','),
    transactionTypes: transactionTypes.filter(val => val).join(',')
  };
};

export const convertFiltersToParams = (filters: Filters): RequestParams => {
  let params = {};

  if (filters.category.length) {
    params = {
      ...getTypeByFilterCategory(filters.category as Categories[])
    };
  }

  if (filters.status) {
    params = {
      ...params,
      status: filters.status
    };
  }

  if (filters.periodDates) {
    params = {
      ...params,
      ...filters.periodDates
    };
  }

  if (filters.nsu) {
    params = {
      ...params,
      nsu: filters.nsu
    };
  }

  if (filters.paymentMethod.length) {
    params = {
      ...params,
      paymentMethods: filters.paymentMethod.join(',')
    };
  }

  return params;
};

export const joinStatementsById = (
  data: StatementContent[],
  newData: StatementContent[]
) => {
  const newStatements = [...data];

  if (newData.length > 0) {
    newData.forEach(element => {
      if (!newStatements.find(s => element.id === s.id)) {
        newStatements.push(element);
      }
    });
  }

  return newStatements;
};

export const getQtdFiltersSelected = (filters: Filters) => {
  let count = 0;

  if (filters.category.length > 0) {
    count++;
  }

  if (filters.period !== '') {
    count++;
  }

  if (filters.status !== '') {
    count++;
  }

  if (filters.nsu !== '') {
    count++;
  }

  if (filters.paymentMethod.length > 0) {
    count++;
  }

  return count;
};
