import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import theme from 'styles/theme';
import 'react-circular-progressbar/dist/styles.css';

export type ProgressCircleProps = {
  value: number;
  strokeWidth?: number;
};

const ProgressCircle = ({ value, strokeWidth = 4 }: ProgressCircleProps) => (
  <CircularProgressbar
    value={value}
    text={`${value}%`}
    strokeWidth={strokeWidth}
    styles={buildStyles({
      textSize: theme.font.sizes.xxmedium,
      pathColor: theme.colors.blue,
      textColor: theme.colors.blue,
      trailColor: theme.colors.grayMedium
    })}
  />
);

export default ProgressCircle;
