import styled from 'styled-components';
import { Text } from 'components';

export const InfoIcon = styled.div`
  cursor: pointer;
`;

export const FormatText = styled(Text)`
  font-size: large;
  line-height: 3rem;
`;
