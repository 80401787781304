import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

export type NumberColumns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type NumberOffset = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export type ColumnProps = {
  xs?: NumberColumns;
  sm?: NumberColumns;
  md?: NumberColumns;
  lg?: NumberColumns;
  xl?: NumberColumns;
  offsetXs?: NumberOffset;
  offsetSm?: NumberOffset;
  offsetMd?: NumberOffset;
  offsetLg?: NumberOffset;
  offsetXl?: NumberOffset;
  orderXs?: NumberColumns;
  orderSm?: NumberColumns;
  orderMd?: NumberColumns;
  orderLg?: NumberColumns;
  orderXl?: NumberColumns;
};

type Sizes = '' | 'small' | 'medium' | 'large' | 'xlarge';

const columnModifiers = {
  calcWidthColumn: (size: Sizes, columns: NumberColumns) => css`
    ${!size &&
    css`
      flex: 0 0 ${(100 / 12) * columns}%;
      max-width: ${(100 / 12) * columns}%;
    `}

    ${size &&
    customMedia.greaterThan(size)`
        flex: 0 0 ${(100 / 12) * columns}%;
        max-width: ${(100 / 12) * columns}%;
    `}
  `,

  orderByBreakpoint: (size: Sizes, order: NumberColumns) => css`
    ${!size &&
    css`
      order: ${order};
    `}

    ${size &&
    customMedia.greaterThan(size)`
        order: ${order}
    `}
  `,

  calcOffsetColumn: (size: Sizes, columns: NumberOffset) => css`
    ${!size &&
    css`
      margin-left: ${(100 / 12) * columns}%;
    `}

    ${size &&
    customMedia.greaterThan(size)`
      margin-left: ${(100 / 12) * columns}%;
  `}
  `
};

export const Column = styled.div<ColumnProps>`
  ${({
    theme,
    xs,
    sm,
    md,
    lg,
    xl,
    offsetXs,
    offsetSm,
    offsetMd,
    offsetLg,
    offsetXl,
    orderXs,
    orderSm,
    orderMd,
    orderLg,
    orderXl
  }) => css`
    position: relative;
    width: 100%;
    flex-basis: 0;
    flex-grow: 1;
    padding-right: ${theme.grid.gutter};
    padding-left: ${theme.grid.gutter};

    ${orderXs && columnModifiers.orderByBreakpoint('', orderXs)}
    ${orderSm && columnModifiers.orderByBreakpoint('small', orderSm)}
    ${orderMd && columnModifiers.orderByBreakpoint('medium', orderMd)}
    ${orderLg && columnModifiers.orderByBreakpoint('large', orderLg)}
    ${orderXl && columnModifiers.orderByBreakpoint('xlarge', orderXl)}


    ${!!xs && columnModifiers.calcWidthColumn('', xs)}
    ${!!sm && columnModifiers.calcWidthColumn('small', sm)}
    ${!!md && columnModifiers.calcWidthColumn('medium', md)}
    ${!!lg && columnModifiers.calcWidthColumn('large', lg)}
    ${!!xl && columnModifiers.calcWidthColumn('xlarge', xl)}

    ${!!offsetXs && columnModifiers.calcOffsetColumn('', offsetXs)}
    ${!!offsetSm && columnModifiers.calcOffsetColumn('small', offsetSm)}
    ${!!offsetMd && columnModifiers.calcOffsetColumn('medium', offsetMd)}
    ${!!offsetLg && columnModifiers.calcOffsetColumn('large', offsetLg)}
    ${!!offsetXl && columnModifiers.calcOffsetColumn('xlarge', offsetXl)}
  `}
`;

export default Column;
