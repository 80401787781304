import styled, { css } from 'styled-components';
import { Button } from 'components';
import { Card } from 'styles/shared/Card';

export const Content = styled(Card)`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.large};
    padding: ${theme.spacings.medium} 0 ${theme.spacings.large};
  `}
`;

export const AbsoluteButton = styled(Button)`
  ${({ theme }) => css`
    position: absolute;
    right: ${theme.spacings.xsmall};
  `}
`;

export const WrapperTable = styled.div`
  overflow-y: auto;
`;
