import { ChangeEventHandler } from 'react';

import * as S from './styles';

import { ProfileSizes } from 'types/global';
import InitialName, { InitialNameVariants } from 'components/InitialName';

export type ProfilePictureProps = {
  image?: string;
  name: string;
  size?: ProfileSizes;
  changeImageLabel?: string;
  width?: number;
  height?: number;
  noPadding?: boolean;
  variant?: InitialNameVariants;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
};

const ProfilePicture = ({
  image,
  name,
  size = 'medium',
  changeImageLabel = 'Editar imagem',
  width,
  height,
  variant,
  noPadding,
  onChange = undefined,
  disabled = false
}: ProfilePictureProps) => (
  <S.Wrapper
    size={size}
    width={width}
    height={height}
    variant={variant}
    onChange={onChange}
  >
    {!!onChange && size === 'large' && (
      <S.InputFileWrapper>
        {changeImageLabel}
        <input
          type="file"
          accept="image/*"
          onChange={onChange}
          disabled={disabled}
        />
      </S.InputFileWrapper>
    )}

    {image ? (
      <img src={image} alt={`Foto de ${name}`} />
    ) : (
      <InitialName
        name={name}
        variant={variant}
        size={size}
        width={width}
        height={height}
        noPadding={noPadding}
      />
    )}
  </S.Wrapper>
);

export default ProfilePicture;
