import * as React from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { validationSchema } from './validationSchema';

import PATHS_ROUTES from 'routes/paths';
import {
  Text,
  TemplateCloseContent,
  Button,
  Warning,
  Loading,
  ModalStatus,
  Input
} from 'components';
import Divider from 'styles/shared/Divider';
import useToggle from 'hooks/useToggle';
import useMutate from 'hooks/useMutate';
import endpoints from 'services/userService/endpoints.json';

type UpdatePasswordFormValues = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const initialValues: UpdatePasswordFormValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
};

const AccountUpdatePassword = () => {
  const modalSuccess = useToggle();
  const navigate = useNavigate();
  const { state, mutate, clearError } = useMutate();

  const handleSubmit = React.useCallback(
    (values: UpdatePasswordFormValues) => {
      const endpoint = endpoints.changePassword;
      const { confirmPassword, ...rest } = values;
      const newPasswordCheck = confirmPassword;

      mutate({
        endpoint,
        method: 'put',
        onSuccess: modalSuccess.toggle,
        body: { ...rest, newPasswordCheck },
        errorMessage:
          'Ocorreu um erro ao alterar a senha, verifique se os dados informados estão corretos'
      });
    },
    [mutate, modalSuccess.toggle]
  );

  const handleChange = React.useCallback(
    (
      e: React.ChangeEvent,
      formikHandleChange: (e: React.ChangeEvent) => void
    ) => {
      if (state.error) clearError();

      formikHandleChange(e);
    },
    [clearError, state.error]
  );

  return (
    <>
      {state.loading && <Loading fullContent />}

      <TemplateCloseContent
        closeLink={PATHS_ROUTES.APP.ACCOUNT.PROFILE}
        closeTitle="Voltar para o perfil"
      >
        <Text tag="h2" size="xxmedium" weight="medium" mb="large">
          Escolha sua nova senha :)
        </Text>

        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({
            values,
            handleChange: formikHandleChange,
            errors,
            isValid,
            touched,
            setFieldTouched
          }) => (
            <Form>
              <Input
                name="oldPassword"
                label="Senha atual"
                type="password"
                placeholder="********"
                value={values.oldPassword}
                onChange={e => handleChange(e, formikHandleChange)}
                errorMessage={
                  touched.oldPassword ? errors.oldPassword : undefined
                }
                onBlur={() => setFieldTouched('oldPassword')}
              />

              <Input
                name="newPassword"
                label="Nova senha"
                type="password"
                maxLength={50}
                placeholder="********"
                value={values.newPassword}
                onChange={e => handleChange(e, formikHandleChange)}
                errorMessage={
                  touched.newPassword ? errors.newPassword : undefined
                }
                onBlur={() => setFieldTouched('newPassword')}
              />

              <Input
                name="confirmPassword"
                label="Confirmar senha"
                type="password"
                maxLength={50}
                placeholder="********"
                value={values.confirmPassword}
                onChange={e => handleChange(e, formikHandleChange)}
                errorMessage={
                  touched.confirmPassword ? errors.confirmPassword : undefined
                }
                onBlur={() => setFieldTouched('confirmPassword')}
              />

              {!!state.error && (
                <Text align="center" color="red">
                  {state.error}
                </Text>
              )}

              <Divider size="large" />

              <Warning>
                Sua senha deve ter números, letras e no mínimo 8 caracteres.
              </Warning>

              <Divider size="xlarge" />

              <Button type="submit" filled center large disabled={!isValid}>
                Alterar senha
              </Button>
            </Form>
          )}
        </Formik>

        {modalSuccess.isActive && (
          <ModalStatus
            variant="success"
            title="Redefinição de senha"
            action={{
              onClick: () => navigate(PATHS_ROUTES.APP.ACCOUNT.PROFILE)
            }}
          >
            <Text size="large" weight="bold" align="center">
              Senha alterada ;)
            </Text>
          </ModalStatus>
        )}
      </TemplateCloseContent>
    </>
  );
};

export default AccountUpdatePassword;
