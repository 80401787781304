import Image1 from '../assets/dooca1.jpg';
import Image2 from '../assets/dooca2.jpg';
import Image3 from '../assets/dooca3.jpg';
import Image4 from '../assets/dooca4.jpg';
import { TutorialGenerateKeys } from '../components';
import * as S from '../../styles';

const Dooca = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Dooca
      </S.Title>

      <TutorialGenerateKeys name="Dooca" />

      <S.Paragraph tag="p" color="grayBlue">
        Na sua conta Dooca, no menu lateral, acesse
        <strong>{' Configurações > Pagamento '}</strong> e clique no logo da{' '}
        <strong>Ame</strong>
      </S.Paragraph>

      <S.ImgWrapper>
        <img src={Image1} alt="Menu lateral e destaque na logo da ame" />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Preencha os campos <strong>“Application Key”</strong> e
        <strong> “Application Token”</strong> com as chaves geradas nas etapas
        acima. Em seguida, clique em <strong>Salvar</strong>.
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image2}
          alt="Campos Application Key e Application Token na Dooca"
        />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Na mesma página, role a tela até o bloco <strong>“Aplicativos”</strong>{' '}
        e clique no botão azul <strong>“Adicionar Novo”</strong>.
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image3}
          alt="Bloco Aplicativos para adicionar novo pagamento"
        />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        No campo <strong>“Selecione um gateway”</strong> escolha
        <strong> “Ame”</strong>.
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image4}
          alt="Tela da Dooca para adicionar novo pagamento com Ame"
        />
      </S.ImgWrapper>
    </div>
  );
};

export default Dooca;
