import * as Yup from 'yup';
import { fieldNames } from 'pages/Accreditation/shared/form';

const isValidType = ({ type }: File) =>
  type.startsWith('image') || type === 'application/pdf';

const isValidFiles = (files: File | FileList) => {
  if (files instanceof File) {
    return isValidType(files);
  }

  return Array.from(files || []).every(isValidType);
};

const fileSchema = Yup.mixed().test(
  'is-valid-files',
  'O arquivo precisa ser imagem ou PDF',
  isValidFiles
);

export const validation = Yup.object().shape({
  [fieldNames.docDeclaration]: fileSchema,
  [fieldNames.docSocial]: fileSchema
});
