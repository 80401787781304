import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    h5 {
      font-weight: normal;
      margin-bottom: ${theme.spacings.medium};
    }

    & > button {
      margin-top: ${theme.spacings.large};
      max-width: 310px;
      background: ${theme.colors.blue};
      color: ${theme.colors.white};

      &:hover {
        background: ${transparentize(0.3, theme.colors.blue)};
      }
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .DayPicker {
      clear: both;
    }

    .CalendarDay {
      line-height: 38px;
    }

    .CalendarMonth_table {
      margin-top: 20px;
    }

    .CalendarMonthGrid {
      left: 0;
    }

    .CalendarDay__default,
    .CalendarDay__default:hover,
    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
      border: none;
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover,
    .CalendarDay__default:hover {
      background: ${theme.colors.blue};
      color: ${theme.colors.white};
    }

    .DayPickerKeyboardShortcuts_show {
      display: none;
    }

    .CalendarDay__selected_span,
    .CalendarDay__hovered_span {
      color: ${theme.colors.grayDark};
      background: #d6f3ff;
    }

    .DateRangePickerInput_arrow {
      margin-top: 2px;
    }

    .DateRangePickerInput_clearDates {
      padding: 5px;

      &:hover {
        background: ${props => props.theme.colors.blue};

        svg {
          fill: #fff;
        }
      }

      svg {
        vertical-align: initial;
      }
    }
  `}

  .DateInput_input__focused {
    border-bottom: 2px solid ${props => props.theme.colors.blue};
  }

  .DayPickerNavigation_button__default {
    border: 1px solid ${props => props.theme.colors.blue};
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.blue};

    svg {
      fill: ${props => props.theme.colors.blue};
    }
  }
`;

export const Period = styled.div`
  position: relative;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 1px;
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const ContainerArrow = styled.div`
  position: absolute;

  height: 16px;
  width: 16px;

  background-color: #fff;
  left: calc(32% + 2vw);
  top: 0px;

  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%) rotate(45deg);
`;
