import styled, { css } from 'styled-components';
import { ReactComponent as IconCheck } from 'assets/images/icon-check.svg';

export const Check = styled(IconCheck)`
  ${({ theme }) => css`
    cursor: pointer;
    border: 1px solid ${theme.colors.grayExtraMedium};
    color: ${theme.colors.transparent};
    border-radius: 5px;
  `}
`;
export const Wrapper = styled.label`
  ${({ theme }) => css`
    display: inline-block;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + ${Check} {
        border: 1px solid ${theme.colors.blue};
        color: ${theme.colors.blue};
      }

      &:focus + ${Check} {
        box-shadow: 0 0 2px ${theme.colors.blue};
      }

      &[type='radio'] {
        opacity: 1;
        width: 24px;
        height: 24px;
        margin-right: ${theme.spacings.small};

        color: ${theme.colors.grayExtraMedium};

        :checked {
          accent-color: ${theme.colors.blue};
        }
      }

      &[type='radio'] + ${Check} {
        display: none;
      }
    }
  `}
`;
