import styled, { css, DefaultTheme } from 'styled-components';
import { transparentize } from 'polished';

import { getSessionStorage } from 'utils/storage';

const { theme: themeMode } = getSessionStorage();

const isDarken = themeMode !== 'light';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: ${theme.spacings.small};
  `}
`;

export const LabelsWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacings.xsmall} ${theme.spacings.medium};
  `}
`;

const modifierValuesWrapper = {
  darken: (theme: DefaultTheme) => css`
    background: ${theme.colors.blackDark};
  `
};

export const ValuesWrapper = styled(LabelsWrapper)`
  ${({ theme }) => css`
    background: ${transparentize(0.8, theme.colors.grayMedium)};

    ${isDarken && modifierValuesWrapper.darken(theme)};
  `}
`;
