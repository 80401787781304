import * as React from 'react';
import { Link, Outlet } from 'react-router-dom';

import { Header, ProfilePicture, Button, Flex } from 'components';

import { Column, Container, Row } from 'styles/shared/Grid';

import { useAuth } from 'contexts/Auth';

import useRequest from 'hooks/useRequest';
import useToggle from 'hooks/useToggle';
import RoutePaths from 'routes/paths';

import { ReactComponent as IconTeam } from 'assets/images/icon-team-color.svg';

import Nav from './Nav';

import * as S from './styles';
import ModalChangeAccount from './ModalChangeAccount';
import ModalChangeToRootAccount from './ModalChangeToRootAccount';
import { GroupAccount } from 'services/groupService';
import endpoints from 'services/endpoints';

export type AppLayoutProps = {
  children?: React.ReactNode;
};

type AppLayoutContextState = {
  sidebarOpened: boolean;
};

const AppLayoutContext = React.createContext<AppLayoutContextState>({
  sidebarOpened: true
});

const useAppLayout = () => React.useContext(AppLayoutContext);

const AppLayout = ({ children }: AppLayoutProps) => {
  const backToRootAccount = useToggle(false);
  const changeAccount = useToggle(false);
  const compressMenu = useToggle(false); // Desktop
  const toggleMenuMobile = useToggle(false); // Mobile
  const sidebarOpened = !compressMenu.isActive;

  const { user, permissions } = useAuth();

  const { data: groups } = useRequest<GroupAccount[]>({
    url: user?.groupId
      ? endpoints.groupAccounts.replace(':id', user?.groupId)
      : null
  });

  const userInfo = !!user && (
    <S.Profile>
      <ProfilePicture
        name={user.name}
        size="small"
        image={user.profilePicture}
      />

      <div>
        <h5>Olá, {user.name}</h5>
        <span>{user.impersonateGroup?.groupName || user.userTypeLabel}</span>
      </div>
    </S.Profile>
  );

  return (
    <AppLayoutContext.Provider value={{ sidebarOpened }}>
      <S.Wrapper
        compress={compressMenu.isActive}
        openMenuMobile={toggleMenuMobile.isActive}
      >
        <Header hasMenu onClick={toggleMenuMobile.toggle}>
          <Flex justifyContent="flex-end">
            {!user?.isImpersonate && !!groups?.length && (
              <Button
                rounded
                secondary
                secondaryColor="secondaryPlusDarkest"
                icon={<IconTeam />}
                onClick={changeAccount.toggle}
              >
                Gerenciar contas
              </Button>
            )}

            {user?.isImpersonate && (
              <Button
                rounded
                filled
                filledColor="white"
                filledBgColor="secondaryPlus"
                icon={<IconTeam />}
                onClick={backToRootAccount.toggle}
              >
                Parar Gerenciamento
              </Button>
            )}

            {permissions?.includes('MINHA_CONTA') ? (
              <Link to={RoutePaths.APP.ACCOUNT.PROFILE}>{userInfo}</Link>
            ) : (
              userInfo
            )}
          </Flex>
        </Header>

        <Nav
          toggleMenuMobile={toggleMenuMobile.toggle}
          compressMenu={compressMenu}
        />

        <S.Content>
          <Container>
            <Row>
              <Column xs={12}>{children || <Outlet />}</Column>
            </Row>
          </Container>
        </S.Content>
      </S.Wrapper>

      {changeAccount.isActive && groups?.length && (
        <ModalChangeAccount groups={groups} onClose={changeAccount.toggle} />
      )}

      {backToRootAccount.isActive && user?.isImpersonate && (
        <ModalChangeToRootAccount
          onClose={backToRootAccount.toggle}
          groupName={user.impersonateGroup?.groupName}
          document={user.impersonateGroup?.document}
        />
      )}
    </AppLayoutContext.Provider>
  );
};

export { useAppLayout, AppLayout as default };
