import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer
} from 'react';

import {
  Pages,
  Types,
  AccreditationData,
  AccreditationSteps,
  Actions,
  Page,
  PageStep
} from './types';

export const AccreditationStepsContext =
  createContext<AccreditationSteps | null>(null);

export function useAccreditationSteps(): AccreditationSteps {
  const context = useContext(AccreditationStepsContext);

  if (!context)
    throw new Error(
      'useAccreditationSteps must be used within an AccreditationStepsProvider'
    );

  return context;
}

export function AccreditationStepsProvider({
  pages,
  children
}: {
  pages: Pages;
  children: ReactNode;
}) {
  const allSteps = useMemo(() => {
    const auxArr: PageStep[] = [];
    pages.forEach(page => {
      page.steps.forEach(step => auxArr.push(step));
    });
    return auxArr;
  }, [pages]);

  function isLastStep(page: Page, step: number) {
    return page.steps.indexOf(allSteps[step]) === page.steps.length - 1;
  }

  function isFirstStep(page: Page, step: number) {
    return page.steps.indexOf(allSteps[step]) === 0;
  }

  function isLastPage(page: Page, state: AccreditationData) {
    return state.pages.indexOf(page) === state.pages.length - 1;
  }

  function isFirstPage(page: Page, state: AccreditationData) {
    return state.pages.indexOf(page) === 0;
  }

  function getPagePercent(step: number) {
    return (step * 100) / allSteps.length;
  }

  function getPage(state: AccreditationData, modifierIndex: number) {
    return state.pages[state.pages.indexOf(state.currentPage) + modifierIndex];
  }

  function AccreditationStepsReducer(
    state: AccreditationData,
    action: Actions
  ): AccreditationData {
    switch (action.type) {
      case Types.nextStep: {
        const currentStep = state.currentStep + 1;

        return {
          ...state,
          currentStep,
          isFirstStep: false,
          isLastStep: isLastStep(state.currentPage, currentStep),
          pagePercent: getPagePercent(currentStep)
        };
      }

      case Types.previousStep: {
        const currentStep = state.currentStep - 1;

        return {
          ...state,
          currentStep,
          isFirstStep: isFirstStep(state.currentPage, currentStep),
          isLastStep: false,
          pagePercent: getPagePercent(currentStep)
        };
      }

      case Types.nextPage: {
        const currentPage = getPage(state, +1);
        const currentStep = state.currentStep + 1;

        return {
          ...state,
          currentPage,
          currentStep,
          isFirstPage: false,
          isLastPage: isLastPage(currentPage, state),
          isFirstStep: isFirstStep(currentPage, currentStep),
          isLastStep: isLastStep(currentPage, currentStep),
          pagePercent: getPagePercent(currentStep)
        };
      }

      case Types.previousPage: {
        const currentPage = getPage(state, -1);
        const currentStep = state.currentStep - 1;

        return {
          ...state,
          currentPage,
          currentStep,
          isFirstPage: isFirstPage(currentPage, state),
          isLastPage: false,
          isFirstStep: isFirstStep(currentPage, currentStep),
          isLastStep: isLastStep(currentPage, currentStep),
          pagePercent: getPagePercent(currentStep)
        };
      }
    }
  }

  const [state, dispatch] = useReducer(AccreditationStepsReducer, {
    pages: pages,
    currentPage: pages[0],
    pageLabels: pages.map(({ label }) => label),
    allSteps,
    currentStep: 0,
    pagePercent: 0,
    isFirstStep: true,
    isFirstPage: true,
    isLastStep: false,
    isLastPage: false
  });

  const actions = useMemo(
    () => ({
      goToPreviousStep: () => dispatch({ type: Types.previousStep }),
      goToNextStep: () => dispatch({ type: Types.nextStep }),
      goToPreviousPage: () => dispatch({ type: Types.previousPage }),
      goToNextPage: () => dispatch({ type: Types.nextPage })
    }),
    []
  );

  const data = useMemo(() => ({ ...state, ...actions }), [state, actions]);

  return (
    <AccreditationStepsContext.Provider value={data}>
      {children}
    </AccreditationStepsContext.Provider>
  );
}
