import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 33rem;
    display: flex;
    align-items: center;
    padding: ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};
    background: ${theme.colors.white};
    border: 0.2rem solid ${theme.colors.grayNeutralLight};
    border-radius: 1rem;
  `}
`;
