import { Button, Text, TemplateModal, Flex } from 'components';

import Divider from 'styles/shared/Divider';

import iconAlert from 'assets/images/icon-alert.svg';
import useMutate from 'hooks/useMutate';
import endpoints from 'services/endpoints';
import { maskDynamicDocument } from 'utils/masks';

type Props = {
  onClose: () => void;
  groupName?: string;
  document?: string;
};
const ModalChangeToRootAccount = ({ onClose, groupName, document }: Props) => {
  const { mutate } = useMutate();

  return (
    <TemplateModal title="" closeModal={onClose}>
      <TemplateModal title="">
        <Flex justifyContent="center">
          <img src={iconAlert} height="90rem" alt="Alerta" />
        </Flex>

        <Text align="center" tag="h2" size="xxlarge" weight="medium">
          Você irá gerenciar a conta:
        </Text>

        <Divider size="large" />

        <Text align="center" size="xmedium" weight="medium">
          {groupName}
        </Text>

        <Text align="center" size="medium" color="grayNeutralDark">
          CNPJ: {document && maskDynamicDocument(document)}
        </Text>

        <Divider size="large" />

        <Text align="center" size="xxmedium">
          Todas as movimentações serão atribuidas a essa conta. Deseja
          continuar?
        </Text>

        <Divider size="large" />

        <Flex justifyContent="center">
          <Button
            filled
            onClick={() => {
              mutate({
                method: 'delete',
                endpoint: endpoints.impersonate,
                errorMessage: '',
                onSuccess: () => {
                  window.location.reload();
                }
              });
            }}
          >
            Parar gerenciamento
          </Button>

          <Divider />

          <Button secondary onClick={onClose}>
            Cancelar
          </Button>
        </Flex>

        <Divider size="large" />
      </TemplateModal>
    </TemplateModal>
  );
};

export default ModalChangeToRootAccount;
