import Text, { TextProps } from 'components/Text';

import { formatCurrency } from 'utils/masks';

export type MoneyProps = {
  value?: string | number;
  showUnit?: boolean;
} & TextProps;

const Money = ({ value = 0, showUnit = true, ...props }: MoneyProps) => (
  <Text {...props}>{formatCurrency(value, showUnit)}</Text>
);

export default Money;
