import { createContext, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import useRequest from 'hooks/useRequest';

import endpoints from 'services/endpoints';
import { Member } from 'services/memberService/types';

import { useAuth } from 'contexts/Auth';

import * as types from './types';

const TeamContext = createContext<types.ContextData | null>(null);

function TeamProvider({ children }: types.ProviderData) {
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const {
    data: membersData,
    loading,
    error
  } = useRequest<Member[]>({
    url: queryString.stringifyUrl({
      url: endpoints.members,
      query: {
        id: id || user?.id,
        offset: page,
        limit: 10,
        search: search
      }
    })
  });

  return (
    <TeamContext.Provider
      value={{
        setSearch,
        setPage,
        page,
        totalPage: 1,
        members: membersData || [],
        loading,
        error
      }}
    >
      {children}
    </TeamContext.Provider>
  );
}

function useTeam(): types.ContextData {
  const context = useContext(TeamContext);

  if (!context) throw new Error('useTeam must be used within an TeamProvider');

  return context;
}

export { useTeam, TeamProvider, TeamContext };
