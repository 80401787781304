import PATHS from 'routes/paths';
import { Button, Flex, Link } from 'components';
import { ReactComponent as ImageHappy } from 'assets/images/accreditation/happy.svg';

import * as S from 'pages/Accreditation/shared/styles';

const FinishStep = () => {
  return (
    <>
      <Flex direction="column" alignItems="center" my="large">
        <ImageHappy />

        <S.Title align="center">Eba! Seus dados foram enviados :)</S.Title>

        <S.Subtitle align="center">
          Agora que você nos enviou seus dados, eles serão analisados. Você
          receberá atualizações no seu e-mail sobre o andamento da sua conta,
          okay?
        </S.Subtitle>

        <Button as={Link} to={PATHS.AUTH.LOGIN} secondary>
          Ir para o site da Ame Digital
        </Button>
      </Flex>
    </>
  );
};

export default FinishStep;
