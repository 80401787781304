import { useMemo } from 'react';

import { Text, CardInfo } from 'components';
import Divider from 'styles/shared/Divider';

import {
  getIconType,
  claimTypes,
  formatPixKeyType,
  claimItensTypes
} from 'utils/pix';

import { IClaimDataResponse } from 'services/pixService/types';

type ClaimCardsProps = {
  type: claimTypes;
  handleClaimItem: (claim: IClaimDataResponse) => void;
  data: IClaimDataResponse[];
};

const ClaimCards = ({ type, data, handleClaimItem }: ClaimCardsProps) => {
  const getCardInfo = (claimItem: IClaimDataResponse) => {
    const Icon = getIconType(claimItem.keyType);

    return (
      <CardInfo
        key={claimItem.id}
        action={() => handleClaimItem(claimItem)}
        icon={<Icon role="img" />}
        text={formatPixKeyType(claimItem.keyType)}
        arrowIcon
      >
        <Text tag="p" weight="regular" color="grayNeutralDark" size="small">
          Prazo para conclusão:
          <Text tag="span" weight="regular" size="small" ml="xsmall">
            {`${claimItem.daysRemaining} ${
              claimItem.daysRemaining === 1 ? 'dia' : 'dias'
            }`}
          </Text>
        </Text>
      </CardInfo>
    );
  };

  const claimListOwnership = useMemo(() => {
    if (data && data.length)
      return data.filter(claim => claim.type === 'OWNERSHIP');

    return [];
  }, [data]);

  const claimListPortability = useMemo(() => {
    if (data && data.length)
      return data.filter(claim => claim.type === 'PORTABILITY');

    return [];
  }, [data]);

  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        {`Solicitações que ${
          type === claimItensTypes.SENT ? 'enviei' : 'recebi'
        }`}
      </Text>

      {claimListPortability.length > 0 && (
        <>
          <Divider size="medium" />

          <Text size="small" weight="bold">
            Portabilidade
          </Text>
          {claimListPortability.map(claim => getCardInfo(claim))}
        </>
      )}

      {claimListOwnership.length > 0 && (
        <>
          <Divider size="medium" />

          <Text size="small" weight="bold">
            Reivindicação
          </Text>
          {claimListOwnership.map(claim => getCardInfo(claim))}
        </>
      )}
    </>
  );
};

export default ClaimCards;
