import { createContext, useContext } from 'react';
import queryString from 'query-string';

import { ContextData, ProviderData } from './types';
import useRequest from 'hooks/useRequest';
import { Loading, ErrorMessage } from 'components';
import endpoints from 'services/endpoints';
import { BalanceData } from 'services/statementService';
import { IDictKeyData, IQRCodePNGResponse } from 'services/pixService/types';
import { outputItensTypes } from 'utils/pix';

const PixContext = createContext<ContextData | null>(null);

function PixProvider({ children }: ProviderData) {
  const balance = useRequest<BalanceData>({
    url: endpoints.balance
  });

  const keysList = useRequest<IDictKeyData[]>({
    url: queryString.stringifyUrl({
      url: endpoints.listKeys,
      query: {
        limit: 20,
        offset: 0
      }
    })
  });

  const rawData = useRequest<string>({
    url:
      keysList.data && keysList.data.length > 0
        ? queryString.stringifyUrl({
            url: endpoints.pixQRCode,
            query: {
              output: outputItensTypes.RAW,
              key: keysList.data[0].key
            }
          })
        : null
  });

  const pngData = useRequest<IQRCodePNGResponse>({
    url:
      keysList.data && keysList.data.length > 0
        ? queryString.stringifyUrl({
            url: endpoints.pixQRCode,
            query: {
              output: outputItensTypes.PNG,
              key: keysList.data[0].key
            }
          })
        : null
  });

  if (balance.loading || keysList.loading) return <Loading fullContent />;

  if (balance.error || keysList.error)
    return (
      <ErrorMessage>
        Ocorreu um erro ao carregar os dados. <br />
        Atualize a página para tentar novamente.
      </ErrorMessage>
    );

  return (
    <PixContext.Provider
      value={{
        balance,
        keysList,
        qrcode: {
          rawData: rawData.data,
          pngData: pngData.data,
          loading: rawData.loading || pngData.loading
        }
      }}
    >
      {children}
    </PixContext.Provider>
  );
}

function usePix(): ContextData {
  const context = useContext(PixContext);

  if (!context) throw new Error('usePix must be used within an PixProvider');

  return context;
}

export { usePix, PixProvider };
