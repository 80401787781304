import { Flex, ModalStatus, Text } from 'components';
import { reportStatusData } from './constants';

type StatusType = 'error' | 'success';
type StatusColorType = 'red' | 'blue';

type StatusModalProps = {
  type: StatusType;
};

export function StatusModal({ type }: StatusModalProps) {
  return (
    <ModalStatus
      variant={reportStatusData[type].status as StatusType}
      {...reportStatusData[type].defaultProps}
    >
      <Flex justifyContent="center" ml="xlarge" mr="xlarge">
        <Text size="large" weight="bold" align="center">
          {reportStatusData[type].subtitle.text}{' '}
          <Text
            size="large"
            weight="bold"
            tag="span"
            color={reportStatusData[type].subtitle.color as StatusColorType}
          >
            {reportStatusData[type].subtitle.status}
          </Text>
        </Text>
      </Flex>
    </ModalStatus>
  );
}
