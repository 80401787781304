import { AxiosResponse } from 'axios';
import { DEFAULT_RESPONSE_SUCCESS } from 'utils/constants';

import * as types from '../types';

export const logoutResponseSuccess: AxiosResponse<types.Logout> = {
  ...DEFAULT_RESPONSE_SUCCESS,
  data: {
    message: 'Usuário deslogado'
  }
};
