import { ReportType } from 'services/reportService/types';
import { ReportTypes, translateTypes } from 'utils/report';

export const reportOptionsData = {
  typeFiles: {
    title: 'Tipo de relatório',
    subtitle: 'Selecione o típo de relatório que deseja',
    items: Object.values(ReportTypes).map(reportType => ({
      text: translateTypes(reportType as ReportType),
      value: reportType
    }))
  },
  archiveType: {
    title: 'Tipo de arquivo',
    subtitle: 'Selecione o típo de arquivo que deseja',
    items: [
      {
        text: 'PDF',
        value: 'PDF'
      },
      {
        text: 'XML',
        value: 'XML'
      },
      {
        text: 'XXXX',
        value: 'XXXX'
      },
      {
        text: 'XXXXXX',
        value: 'XXXXXX'
      }
    ]
  }
};
