import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import iconWarning from 'assets/images/icon-alert.svg';

import PATHS_ROUTES from 'routes/paths';
import useToggle from 'hooks/useToggle';
import { Divider } from 'styles/shared/Divider';
import useRequest from 'hooks/useRequest';
import endpoints from 'services/endpoints';
import {
  GetAdvanceBalanceResponse,
  advanceFull
} from 'services/advanceService';
import { PlanDataResponse } from 'services/userService';
import { formatCurrency } from 'utils/masks';
import {
  Button,
  Text,
  ModalStatus,
  WarningBox,
  TemplateCloseContent,
  Flex,
  ErrorMessage,
  Loading
} from 'components';

function AnticipateFull() {
  const alertModal = useToggle();
  const navigate = useNavigate();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmError, setConfirmError] = useState('');

  const { data: planData, error: planDataError } = useRequest<PlanDataResponse>(
    {
      url: endpoints.planData,
      options: {
        revalidateOnFocus: false
      }
    }
  );

  const { data, error } = useRequest<GetAdvanceBalanceResponse>({
    url: endpoints.advanceBalance
  });

  if (error || !!planDataError) {
    return (
      <ErrorMessage message="Ocorreu um erro ao carregar os dados, atualize a página e tente novamente." />
    );
  }

  if (!data || !planData) {
    return <Loading fullContent />;
  }

  if (data.status === 'PROCESSING') {
    return (
      <>
        <TemplateCloseContent
          closeLink={PATHS_ROUTES.APP.DASHBOARD.DEFAULT}
          closeTitle="Voltar para o dashboard"
        >
          <Flex direction="column" alignItems="center">
            <img width={100} src={iconWarning} alt="Icone de alerta" />
            <Text weight="bold" mt="medium" align="center">
              Atenção
            </Text>

            <Text color="grayNeutralDark" my="xsmall" align="center">
              Você já tem uma solicitação de <br /> antecipação total em
              andamento
            </Text>
          </Flex>
        </TemplateCloseContent>
      </>
    );
  }

  const handleClose = () => {
    alertModal.toggle();

    if (!confirmError) {
      navigate(PATHS_ROUTES.APP.DASHBOARD.DEFAULT);
    }
  };

  const handleConfirm = async () => {
    try {
      setConfirmLoading(true);
      await advanceFull();
    } catch (error) {
      setConfirmError(
        'Ocorreu um erro ao tentar realizar a antecipação total, tente novamente mais tarde'
      );
    } finally {
      setConfirmLoading(false);
      alertModal.toggle();
    }
  };

  return (
    <>
      {confirmLoading && <Loading fullContent />}

      <TemplateCloseContent
        closeLink={PATHS_ROUTES.APP.DASHBOARD.DEFAULT}
        closeTitle="Voltar para o dashboard"
      >
        <Text tag="h2" size="xxmedium" weight="medium">
          Antecipação total
        </Text>
        <Text size="medium" darkColor="white">
          Confira os dados e valores da antecipação
        </Text>

        <Divider size="large" />

        <Flex justifyContent="space-between">
          <Text size="xmedium" color="blackDark">
            Total disponível
          </Text>

          <Text size="xmedium" color="blackDark" weight="bold">
            {formatCurrency(data.amountInCents)}
          </Text>
        </Flex>

        <Text size="xsmall" color="grayDark">
          O valor disponível para antecipação corresponde as vendas pagas com
          cartão de crédito à vista e parceladas.
        </Text>

        <Divider />

        <Flex justifyContent="space-between">
          <Text size="xmedium" color="blackDark">
            Taxa de antecipação (
            <Text tag="span" size="medium" color="red">
              {formatCurrency(planData.card.takeRateAdvance, false)}% a.m
            </Text>
            ):
          </Text>

          <Text size="xmedium" color="blackDark" mb="medium" weight="bold">
            - {formatCurrency(data.amountInCents - data.simulatedAmount)}
          </Text>
        </Flex>

        <Divider size="large" withBorder />

        <Flex justifyContent="space-between">
          <Text size="large" color="blackDark" weight="bold">
            Total a ser antecipado
          </Text>

          <Text size="large" color="blue" weight="bold">
            {formatCurrency(data.simulatedAmount)}
          </Text>
        </Flex>

        <Divider size="large" />

        <WarningBox block title="Informações importantes">
          <Text size="xsmall" color="grayDark">
            • Após a confirmação, o valor estará disponível na sua conta em até
            24h;
          </Text>
          <Text size="xsmall" color="grayDark">
            • Caso uma venda seja cancelada antes de sua liquidação, o valor
            será retirado do total de antecipação informado acima;
          </Text>
          <Text size="xsmall" color="grayDark">
            • Antecipações feitas manualmente pela aba “extrato” antes do prazo
            de liquidação também serão desconsideradas deste valor total;
          </Text>
          <Text size="xsmall" color="grayDark">
            • Vendas realizadas após confirmar a solicitação de antecipação não
            serão somadas ao valor total informado.
          </Text>
        </WarningBox>

        <Divider size="large" />

        <Button
          large
          filled
          center
          onClick={handleConfirm}
          disabled={!(data.amountInCents > 0)}
        >
          Concluir
        </Button>
      </TemplateCloseContent>

      {alertModal.isActive && (
        <ModalStatus
          variant={confirmError ? 'error' : 'success'}
          title={confirmError ? 'Antecipação' : 'Antecipação concluída'}
          onClose={handleClose}
          action={{ onClick: handleClose }}
        >
          <Text size="medium">
            {confirmError}
            {!confirmError && (
              <>
                A antecipação foi realizada com sucesso e estará disponível em
                até
                <strong> 24h em sua conta.</strong>
              </>
            )}
          </Text>
        </ModalStatus>
      )}
    </>
  );
}

export default AnticipateFull;
