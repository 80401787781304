import styled, { css } from 'styled-components';

export const TimerWrapper = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
    }

    button {
      margin-left: 1rem;
    }
  `}
`;
