import moment from 'moment';

export const checkBornDateIsValid = (value: string) => {
  const minimumDate = new Date(1900, 1, 1);
  const today = new Date();

  return Boolean(
    moment(value, 'DD-MM-YYYY').isValid() &&
      moment(value, 'DD-MM-YYYY').isAfter(minimumDate) &&
      moment(value, 'DD-MM-YYYY').isBefore(today)
  );
};
