import { useState, useCallback } from 'react';

import {
  TemplateCloseContent,
  Text,
  Button,
  InputSearch,
  CardAccount,
  CardTeamMember,
  Flex
} from 'components';
import Divider from 'styles/shared/Divider';
import { useScreenOwner } from 'contexts/ScreenOwner';
import { ProfileTypes } from 'types/global';

export type Account = {
  id: string;
  profileType: ProfileTypes;
  title: string;
  document: string;
};

export type OwnerData = {
  id: string;
  name: string;
};

export type ChooseOwnerGroupProps = {
  onChooseOwner: (owner: OwnerData) => void;
  accounts: Account[];
};

const ChooseOwnerGroup = ({
  onChooseOwner,
  accounts
}: ChooseOwnerGroupProps) => {
  const [inputSearchValue, setInputSearchValue] = useState('');
  const { toggleScreenOwner } = useScreenOwner();

  const onHandleChoose = useCallback(() => {
    onChooseOwner({
      id: 'af81d10a-92fa-11eb-a8b3-0242ac130003',
      name: 'Claudio Enrique de Souza Machado'
    });
  }, [onChooseOwner]);

  return (
    <TemplateCloseContent closeLink={toggleScreenOwner} closeTitle="Voltar">
      <Text color="grayBlue" size="xxmedium" weight="medium">
        Responsável
      </Text>
      <Text color="grayDark" size="small">
        O responsável pode ver e gerenciar as contas e resultados do grupo
      </Text>

      <Divider size="medium" />

      <CardAccount
        title="Claudio Enrique de Souza Machado"
        subtitle="Responsável atual"
        profileType="ADMIN"
        filled
      />

      <Divider size="small" />
      <Divider size="medium" withBorder />

      <InputSearch
        name="search"
        placeholder="Pesquise o nome ou CPF"
        value={inputSearchValue}
        onChange={e => setInputSearchValue(e.target.value)}
      />

      {accounts.length <= 0 && (
        <>
          <Divider size="xlarge" />

          <Text color="grayDark" size="medium" align="center">
            Você não possui administradores disponíveis para direcionar como
            responsável desse grupo
          </Text>
        </>
      )}

      {accounts.length > 0 && (
        <>
          <Divider size="medium" />

          <Text size="small" color="grayDark">
            Selecione entre seus administradores o responsável pelo grupo
          </Text>

          <Divider size="small" />

          <Flex justifyContent="flex-start">
            {accounts.map(account => (
              <CardTeamMember
                key={account.id}
                name={account.title}
                userType={account.profileType}
                document={account.document}
              >
                <Button tertiary fullWidth onClick={() => {}}>
                  Selecionar
                </Button>
              </CardTeamMember>
            ))}
          </Flex>
        </>
      )}

      <Divider size="xlarge" />
      <Button secondary center filled onClick={onHandleChoose} large>
        Concluir
      </Button>
    </TemplateCloseContent>
  );
};

export default ChooseOwnerGroup;
