import styled from 'styled-components';
import customMedia from 'styles/media-query';

import { Text, Flex } from 'components';

export const SectionSubtitleTitle = styled(Text)`
  max-width: 400px;
`;

export const CardContainer = styled(Flex)`
  flex-direction: column;

  ${customMedia.greaterThan('medium')`
    flex-direction: row;
  `};
`;

export const CursorPointer = styled.div`
  cursor: pointer;
`;
