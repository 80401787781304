import React, { useMemo, useState } from 'react';
import { Form, Formik, FormikValues, useFormikContext } from 'formik';

import useToggle from 'hooks/useToggle';
import PATHS_ROUTES from 'routes/paths';
import Divider from 'styles/shared/Divider';
import { getErrorMessage } from 'utils/formik';
import { Column, Row } from 'styles/shared/Grid';
import {
  Button,
  ErrorMessage,
  InputField,
  InputFile,
  Loading,
  ModalStatus,
  TemplateClean,
  TemplateCloseContent,
  Text
} from 'components';

import { getSchema } from './validation';

import { useDeeplinkSubmit } from './useDeeplinkSubmit';
import { useDeeplinkData, FieldType } from './useDeeplinkData';

type ChildrenProps = { children: React.ReactNode };

function Container({ children }: ChildrenProps) {
  return (
    <TemplateClean>
      <TemplateCloseContent
        closeLink={PATHS_ROUTES.AUTH.LOGIN}
        closeTitle="Voltar"
      >
        {children}
      </TemplateCloseContent>
    </TemplateClean>
  );
}

function FieldComponent({ name, type, label }: FieldType) {
  const form = useFormikContext<FormikValues>();

  if (type === 'file') {
    return (
      <InputFile
        name={name}
        label={label}
        errorMessage={getErrorMessage(name, form)}
        onFilesChange={files => form.setFieldValue(name, files)}
        alertMessage="Envie o documento em formato PDF ou JPG"
      />
    );
  }

  return <InputField name={name} label={label} />;
}

function Deeplink() {
  const success = useToggle(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { loading, error, fields, document, token } = useDeeplinkData();

  const handleSubmit = useDeeplinkSubmit({
    fields,
    document,
    token,
    onError: setSubmitError,
    onSuccess: success.toggle
  });

  const validations = useMemo(() => getSchema(fields), [fields]);

  const initialValues = useMemo(
    () =>
      fields.reduce(
        (acc, field) => ({
          ...acc,
          [field.name]: ''
        }),
        {}
      ),
    [fields]
  );

  if (loading) {
    return (
      <Container>
        <Loading fullContent fullWidth />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <ErrorMessage message="Ocorreu um erro inesperado, tente novamente mais tarde." />
      </Container>
    );
  }

  return (
    <Container>
      <Text tag="h1" size="xxmedium" weight="medium">
        Reenvie os dados abaixo para validarmos a sua conta
      </Text>

      <Divider />

      <Formik
        initialValues={initialValues}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Divider />

            {isSubmitting && <Loading fullContent fullWidth />}

            {fields.map(fieldType => (
              <div key={fieldType.name}>
                <FieldComponent {...fieldType} />

                <Divider size="small" />
              </div>
            ))}

            <Divider size="large" />

            <Row justifyContentXs="center">
              <Column xs={8}>
                <Button type="submit" fullWidth filled>
                  Reenviar dados
                </Button>
              </Column>
            </Row>
          </Form>
        )}
      </Formik>

      {submitError && (
        <ModalStatus
          variant="error"
          description={submitError}
          onClose={() => setSubmitError(null)}
        />
      )}

      {success.isActive && (
        <ModalStatus
          variant="success"
          title={'Eba! Seus dados foram enviados :)'}
          action={{
            text: 'Ir para o Login',
            asLink: true,
            to: '/login'
          }}
        />
      )}
    </Container>
  );
}

export default Deeplink;
