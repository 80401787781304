import * as S from './styles';
import { ProfilePicture, Text } from 'components';
import Divider from 'styles/shared/Divider';

type MasterAccount = {
  name: string;
};

type Member = {
  name: string;
  userTypeLabel: string;
  imgSrc: string;
};

export type CardMemberProps = {
  masterAccount: MasterAccount;
  member: Member;
};

const CardMember = ({ masterAccount, member }: CardMemberProps) => (
  <S.Wrapper>
    <ProfilePicture image={member.imgSrc} name={member.name} size="large" />

    <Divider size="medium" direction="horizontal" />

    <div>
      <Text size="small" weight="medium">
        Conta pertencente:
      </Text>
      <Text size="xmedium">{masterAccount.name}</Text>

      <Divider size="small" />

      <Text size="small" weight="medium">
        Nome:
      </Text>
      <Text size="xmedium">{member.name}</Text>

      <Divider size="small" />

      <Text size="small" weight="medium">
        Perfil:
      </Text>
      <Text size="xmedium" color="blue">
        {member.userTypeLabel}
      </Text>
    </div>
  </S.Wrapper>
);

export default CardMember;
