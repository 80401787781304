import { TutorialRequestKey } from '../components';
import * as S from '../../styles';

type RequestKeyType = {
  title: string;
  email: string;
};

function RequestKey({ title, email }: RequestKeyType) {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        {title}
      </S.Title>

      <TutorialRequestKey name={title} showConfigurationMessage={false} />

      <S.Paragraph tag="p" color="grayBlue">
        Recebeu sua chave? Ótimo! Com a sua chave de integração enviei um email
        para <strong>{email}</strong> com cópia para
        <strong> parceria.digital@amedigital.com</strong>. Informar no email que
        quer configurar a integração com Ame Digital, CNPJ, nome fantasia e
        site. Você receberá toda a orientação para a configuração da integração.
      </S.Paragraph>
    </div>
  );
}

export default RequestKey;
