import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';

import {
  Button,
  Input,
  Text,
  TemplateAuthCard,
  ModalStatus,
  Loading
} from 'components';

import { Divider } from 'styles/shared/Divider';

import useMutate from 'hooks/useMutate';

import { useAuth } from 'contexts/Auth';

import { endpoints } from 'services/userService';

import PATHS_ROUTES from 'routes/paths';

import { maskOnlyNumbers, lowerAlphanumericMask } from 'utils/masks';

import { validationSchema } from './validationSchema';

type UpdateUserFormValues = {
  alias: string;
  user: {
    name: string;
    document: string;
    email: string;
    phone: string;
  };
};

const initialValues: UpdateUserFormValues = {
  alias: '',
  user: {
    name: '',
    document: '',
    email: '',
    phone: ''
  }
};

const UpdateUser = () => {
  const { updateUser, signOut } = useAuth();
  const navigate = useNavigate();

  const { state, mutate, clearError } = useMutate();

  const handleSubmit = useCallback(
    ({ alias, user }) => {
      mutate({
        endpoint: endpoints.renew,
        onSuccess: () => {},
        body: {
          alias: lowerAlphanumericMask(alias),
          user: {
            ...user,
            document: maskOnlyNumbers(user.document),
            phone: maskOnlyNumbers(user.phone)
          }
        },
        errorMessage: 'Ocorreu um erro ao atualizar o cadastro'
      });
    },
    [mutate]
  );

  const handleChange = useCallback(
    (
      e: React.ChangeEvent,
      formikHandleChange: (e: React.ChangeEvent) => void
    ) => {
      if (state.error) clearError();

      formikHandleChange(e);
    },
    [clearError, state.error]
  );

  useEffect(() => {
    if (!updateUser) {
      navigate(PATHS_ROUTES.APP.DASHBOARD.DEFAULT);
    }
  }, [navigate, updateUser]);

  return (
    <>
      {state.loading && <Loading fullContent fullWidth />}

      <TemplateAuthCard>
        <Text tag="h1" weight="bold" size="xxlarge">
          Ame Sua Conta{' '}
          <Text tag="span" color="blue" weight="bold" size="xxlarge">
            :)
          </Text>
        </Text>

        <Text>Atualização dados cadastrais</Text>

        <Divider />

        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, errors, dirty, handleChange: formikHandleChange }) => (
            <Form>
              <Input
                name="alias"
                label="Digite o apelido da empresa"
                value={values.alias}
                onChange={e => handleChange(e, formikHandleChange)}
                maskType="LOWER_ALPHANUMERIC"
                errorMessage={errors.alias}
              />

              <Input
                name="username"
                label="Nome do usuário"
                value={values.user.name}
                onChange={e => handleChange(e, formikHandleChange('user.name'))}
                errorMessage={errors.user?.name}
              />

              <Input
                name="document"
                label="CPF"
                value={values.user.document}
                onChange={e =>
                  handleChange(e, formikHandleChange('user.document'))
                }
                maskType="CPF"
                errorMessage={errors.user?.document}
              />

              <Input
                name="email"
                label="E-mail"
                value={values.user.email}
                onChange={e =>
                  handleChange(e, formikHandleChange('user.email'))
                }
                errorMessage={errors.user?.email}
              />

              <Input
                name="phone"
                label="Celular"
                value={values.user.phone}
                onChange={e =>
                  handleChange(e, formikHandleChange('user.phone'))
                }
                maskType="PHONE"
                errorMessage={errors.user?.phone}
              />

              {!!state.error && (
                <Text align="center" color="red">
                  Ocorreu um erro ao atualizar seu cadastro
                </Text>
              )}

              <Divider size="xlarge" />

              <Button type="submit" filled center large disabled={!dirty}>
                Enviar
              </Button>
            </Form>
          )}
        </Formik>

        {state?.status === 204 && (
          <ModalStatus
            variant="success"
            title="Atualização cadastral"
            description={
              <>
                Seus dados foram alterados com sucesso! <br /> Para acessar sua
                conta com seu novo usuário, confirme sua atualização cadastral,
                no e-mail que lhe enviamos. =)
              </>
            }
            onClose={signOut}
            action={{
              onClick: signOut,
              text: 'Entendi'
            }}
          >
            <Text weight="bold" align="center">
              Atualização cadastral{' '}
              <Text tag="span" weight="bold" color="blue">
                :)
              </Text>
            </Text>
          </ModalStatus>
        )}
      </TemplateAuthCard>
    </>
  );
};

export default UpdateUser;
