import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { Card } from 'styles/shared/Card';

import { Row } from 'styles/shared/Grid';

export const Content = styled(Row)`
  align-items: center;
`;

type WrapperProps = {
  disabled: boolean;
};

export const Wrapper = styled(Card)<WrapperProps>`
  ${({ theme, disabled }) => css`
    box-shadow: 0.1rem 0.1rem 0.8rem ${transparentize(0.9, theme.colors.black)};
    margin-bottom: ${theme.spacings.small};

    .column {
      &-left {
        p {
          line-height: ${theme.font.lineHeight('xlarge')};
          margin-bottom: -${theme.spacings.xsmall};
        }
      }

      &-right {
        display: flex;
        justify-content: flex-end;
      }
    }

    ${disabled &&
    css`
      opacity: 0.3;
    `}
  `}
`;
