import styled, { css } from 'styled-components';

export const TextInfo = styled.p`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.xmedium};
    font-weight: ${theme.font.weights.bold};

    & span {
      &.success {
        color: ${theme.colors.blueCategoryColorSuccess};
      }

      &.error {
        color: ${theme.colors.red};
      }
    }
  `}
`;
