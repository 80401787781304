import { CardInfo, Text } from 'components';
import Divider from 'styles/shared/Divider';

import IconECommerce from 'assets/images/sell/notebook-small.png';
import IconStore from 'assets/images/sell/store.png';
import { useScreenSell } from 'contexts/ScreenSell';
import { MaterialsType } from 'contexts/ScreenSell/types';

type Step1Props = {
  next: () => void;
};

function Step1({ next }: Step1Props) {
  const { setMaterialType } = useScreenSell();

  const handleChooseMaterialType = (type: MaterialsType) => {
    setMaterialType(type);
    next();
  };

  return (
    <>
      <Text weight="medium" tag="h2" size="large">
        Onde você vai usar os materiais?
      </Text>

      <Divider size="large" />

      <CardInfo
        action={() => handleChooseMaterialType('store')}
        text="Na loja física"
        arrowIcon
        icon={<img src={IconStore} alt="ícone de loja" />}
      />
      <CardInfo
        action={() => handleChooseMaterialType('eCommerce')}
        text="No e-commerce"
        arrowIcon
        icon={<img src={IconECommerce} alt="ícone de notebook" />}
      />
    </>
  );
}

export default Step1;
