import { createContext, useState, useContext } from 'react';
import moment from 'moment';

import * as types from './types';
import { getSessionStorage } from 'utils/storage';
import { setCashOut } from 'services/bankService';

import { getApiError } from 'utils/errors';

const BankContext = createContext<types.ContextData | null>(null);

function BankProvider({ children }: types.ProviderData) {
  const { userData } = getSessionStorage();
  const [cashOutData, setCashOutData] = useState<types.CashOutData>({
    loading: false,
    error: false,
    success: false
  });
  const [editingAccount, setEditingAccount] =
    useState<types.BankAccountData | null>(null);

  const [withdrawData, setWithdrawData] = useState<types.WithdrawData>({
    dateNow: moment().format('DD/MM/YYYY'),
    value: '0',
    documentNumber: userData?.groupDocument
  });

  const handleSetCashOut = async (accountId: string, amount: number) => {
    setCashOutData({ loading: true });

    try {
      const data = await setCashOut(accountId, amount);

      if (data) setCashOutData({ success: true, loading: false });
    } catch (error) {
      const { message } = getApiError(error);
      setCashOutData({ error: message, loading: false });
    }
  };

  const setWithdrawDataValues = (value: types.WithdrawData) => {
    setWithdrawData({ ...withdrawData, ...value });
  };

  return (
    <BankContext.Provider
      value={{
        withdrawData,
        setWithdrawDataValues,
        handleSetCashOut,
        cashOutData,
        editingAccount,
        setEditingAccount
      }}
    >
      {children}
    </BankContext.Provider>
  );
}

function useBank(): types.ContextData {
  const context = useContext(BankContext);

  if (!context) throw new Error('useBank must be used within an BankProvider');

  return context;
}

export { useBank, BankProvider };
