import { useEffect, useState } from 'react';

const DEFAULT_TIME_IN_SECONDS = 60;

type TimerReturn = {
  time: number;
  started: boolean;
  restartTimer(): void;
  startTimer(): void;
  stopTimer(): void;
};

type Params = {
  initialStart?: boolean;
  maxTimeInSeconds?: number;
  decrement?: boolean;
};

function useTimer({
  initialStart = true,
  maxTimeInSeconds = DEFAULT_TIME_IN_SECONDS,
  decrement
}: Params = {}): TimerReturn {
  const [started, setStarted] = useState(initialStart);
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (started) {
      const id = setInterval(() => {
        setTime(current => {
          const nextTime = current + 1;

          if (nextTime === maxTimeInSeconds) {
            setStarted(false);
          }

          return nextTime;
        });
      }, 1000);

      return () => clearInterval(id);
    }
  }, [maxTimeInSeconds, started]);

  return {
    time: decrement ? maxTimeInSeconds - time : time,
    started,
    restartTimer: () => {
      setTime(0);
      setStarted(true);
    },
    startTimer: () => setStarted(true),
    stopTimer: () => setStarted(false)
  };
}

export default useTimer;
