import styled, { DefaultTheme, css } from 'styled-components';
import { ThemeSpacings } from 'types/global';

export type MarginProps = {
  m?: ThemeSpacings;
  mt?: ThemeSpacings;
  mb?: ThemeSpacings;
  mr?: ThemeSpacings;
  ml?: ThemeSpacings;
  mx?: ThemeSpacings;
  my?: ThemeSpacings;
};

type BuildMargin = MarginProps & {
  theme: DefaultTheme;
};

const modifiers = {
  m: (value: string) => css`
    margin: ${value};
  `,

  mt: (value: string) => css`
    margin-top: ${value};
  `,

  mb: (value: string) => css`
    margin-bottom: ${value};
  `,

  mr: (value: string) => css`
    margin-right: ${value};
  `,

  ml: (value: string) => css`
    margin-left: ${value};
  `,

  mx: (value: string) => css`
    margin-right: ${value};
    margin-left: ${value};
  `,

  my: (value: string) => css`
    margin-bottom: ${value};
    margin-top: ${value};
  `
};

export const buildMargin = ({
  theme,
  m,
  mt,
  mb,
  mr,
  ml,
  mx,
  my
}: BuildMargin) => css`
  ${m && modifiers.m(theme.spacings[m])}
  ${mt && modifiers.mt(theme.spacings[mt])}
  ${mb && modifiers.mb(theme.spacings[mb])}
  ${mr && modifiers.mr(theme.spacings[mr])}
  ${ml && modifiers.ml(theme.spacings[ml])}
  ${mx && modifiers.mx(theme.spacings[mx])}
  ${my && modifiers.my(theme.spacings[my])}
`;

const Margin = styled.div<MarginProps>`
  ${props => buildMargin(props)}
`;

export default Margin;
