import { Text, TemplateProfilePermissionsForm } from 'components';

import { MemberPermissions } from 'services/memberService/types';

import { Data } from '../../types';

import Margin from 'styles/shared/Margin';
import * as S from './styles';

export type Step4Props = {
  initialData: Data;
  setData: (values: Data) => void;
  nextStep: () => void;
};

function Step4({ initialData, setData, nextStep }: Step4Props) {
  function onConfirm(values: MemberPermissions) {
    const { profileId, userType, userTypeLabel, permissions } = values;
    if (userType && userTypeLabel) {
      setData({
        ...initialData,
        profileId,
        userType,
        userTypeLabel,
        permissionIds: permissions
      });
      nextStep();
    }
  }

  return (
    <S.Wrapper>
      <Text color="grayBlue" size="xxmedium" weight="medium">
        Adicionar membro
      </Text>

      <Text color="grayDark" size="small">
        Para finalizar vamos definir o perfil e as permissões de acesso desse
        membro
      </Text>
      <Margin my="large">
        <TemplateProfilePermissionsForm onConfirm={onConfirm} />
      </Margin>
    </S.Wrapper>
  );
}

export default Step4;
