import { ReactComponent as IconOnboarding1 } from 'assets/images/pix/onboarding-1.svg';
import { ReactComponent as IconOnboarding2 } from 'assets/images/pix/onboarding-2.svg';
import { ReactComponent as IconOnboarding3 } from 'assets/images/pix/onboarding-3.svg';

export const modalItem = [
  {
    title: 'O que é o <b>Pix?</b>',
    subtitle:
      'Pague contas, receba pagamentos e faça transferências, e o melhor, <b>cai na hora!</b>',
    description: [
      'Transações grátis para pessoa física e com tarifa de R$ 1,50 ou até 1% do valor da transação para pessoa jurídica.'
    ],
    image: IconOnboarding1
  },
  {
    title: 'Como Funciona?',
    subtitle:
      'Sem dados bancários, cartões ou dinheiro físico para fazer suas <b>transações</b>.',
    description: [
      'Faça suas transações com QR Code ou chaves Pix com toda segurança.'
    ],
    image: IconOnboarding2
  },
  {
    title: 'Suas chaves na <b>Ame :)</b>',
    subtitle:
      'É só informar a chave cadastrada ou gerar um QR Code Pix para <b>receber</b>.',
    description: [
      'Cadastre uma chave com seu celular, e-mail, CPF/CNPJ ou, se preferir, crie uma chave aleatória.'
    ],
    image: IconOnboarding3
  }
];
