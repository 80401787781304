import Image1 from '../assets/xekout1.png';
import Image2 from '../assets/xekout2.png';
import Image3 from '../assets/xekout3.png';
import Image4 from '../assets/xekout4.png';
import { TutorialGenerateKeys } from '../components';
import * as S from '../../styles';

const Xekout = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Xekout
      </S.Title>

      <TutorialGenerateKeys name="Xekout" />

      <S.Paragraph tag="p" color="grayBlue">
        Na sua conta Xekout, no menu lateral, acesse <strong>“Apps”</strong>
      </S.Paragraph>

      <S.ImgWrapper>
        <img src={Image1} alt="Menu lateral com destaque em Apps" />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Na página de <strong>“Apps”</strong> selecione a aba
        <strong> “Meios de pagamento”</strong>, em seguida selecione a opção
        <strong> “Ame Digital”</strong>
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image2}
          alt="Página Apps com opção Meios de Pagamento em destaque"
        />
        <img src={Image3} alt="Opção Ame Digital" />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Preencha os campos <strong>Application Key</strong> e
        <strong> Application Token</strong>, referentes ao{' '}
        <strong>Client ID</strong> e <strong>Secret ID </strong>
        respectivamente no site da Xekout, com os códigos que você gerou nas
        etapas acima. Em seguida, clique em
        <strong> Salvar</strong>.
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image4}
          alt="Campos Client ID e Secret ID no site da xekout"
        />
      </S.ImgWrapper>
    </div>
  );
};

export default Xekout;
