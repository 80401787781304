import { TutorialRequestKey } from '../components';
import * as S from '../../styles';
const DigitalSeller = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Magazord
      </S.Title>

      <TutorialRequestKey name="Magazord" showConfigurationMessage={false} />

      <S.Paragraph tag="p" color="grayBlue">
        Recebeu sua chave? Ótimo! Agora será necessário a configuração da sua
        integração.
      </S.Paragraph>

      <S.Paragraph>
        Quem faz essa configuração é a Magazord, então basta abrir um chamado
        para eles, informando que quer fazer a integração e enviar as chaves.
      </S.Paragraph>
    </div>
  );
};

export default DigitalSeller;
