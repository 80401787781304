import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Senha obrigatória')
    .min(8, 'No mínimo 8 caracteres'),
  passwordConfirmation: yup
    .string()
    .required('Confirmação de senha obrigatória')
    .oneOf([null, yup.ref('password')], 'As senhas precisam ser iguais')
});
