import styled, { css } from 'styled-components';
import { Input } from 'components';

import { ThemeColors } from 'types/global';

type StylesInputMoneyProps = {
  color: ThemeColors;
};

export const InputMoney = styled(Input)<StylesInputMoneyProps>`
  ${({ theme, color }) => css`
    color: ${theme.colors[color]};
    font-size: ${theme.font.sizes.xxxlarge};
    font-weight: ${theme.font.weights.bold};

    &::placeholder {
      color: ${theme.colors[color]};
    }
  `}
`;
