import { TutorialRequestKey } from '../components';
import * as S from '../../styles';
const Woocommerce = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Woocommerce (Apiki)
      </S.Title>

      <TutorialRequestKey name="Woocommerce" />

      <S.Paragraph tag="p" color="grayBlue">
        Antes de iniciar a configuração é necessário que faça o download do
        plugin da Ame Digital para sua plataforma em{' '}
        <S.Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://wordpress.org/plugins/wc-ame-digital/"
        >
          Pagamento QR Code Ame Digital – WordPress plugin | WordPress.org
        </S.Link>
      </S.Paragraph>

      <S.Paragraph>
        Após a instalação do plugin, vamos iniciar a configuração. Na sua conta
        Woocommerce, entre no menu lateral, acesse
        <strong>{' Woocommerce > Configurações > Pagamentos > Ame'}</strong>
      </S.Paragraph>

      <S.Paragraph>
        Preencha o campo <strong>“Access Token”</strong> com a sua chave de
        integração. Após isso preencha os demais campos: Título, descrição,
        título do checkout e status do pedido.
        <strong> Habilite o método e salve as alterações</strong>.
      </S.Paragraph>
    </div>
  );
};

export default Woocommerce;
