import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PATHS_ROUTES from 'routes/paths';

import { TemplatePaginationStep } from 'components';
import { Step1, Step2, Step3 } from './Steps';

export function Withdraw() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const steps = useMemo(
    () => [
      <Step1 key="1" next={() => setStep(2)} />,
      <Step2 key="2" next={() => setStep(3)} />,
      <Step3
        key="3"
        goToStep2={() => setStep(2)}
        goToChangeAccount={() => navigate(PATHS_ROUTES.APP.BANK.SELECT)}
      />
    ],
    [navigate]
  );

  return (
    <TemplatePaginationStep
      steps={steps}
      currentPage={step}
      prevPageItemClick={setStep}
      onClose={() => navigate(PATHS_ROUTES.APP.DASHBOARD.DEFAULT)}
      small
    />
  );
}
