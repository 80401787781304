import useRequest from 'hooks/useRequest';
import { useMemo } from 'react';
import endpoints from 'services/endpoints';

type Videos = {
  id: string;
  title: string;
  youtubeId: string;
  thumbUrl: string;
  type: string;
  videoOrder: number;
};

const initialState = {
  data: []
};

export function useVideos(title?: string) {
  const {
    data: response = initialState,
    loading,
    error
  } = useRequest<{ data: Videos[] }>({
    url: endpoints.videos
  });

  const videos = useMemo(() => {
    if (!title) {
      return response.data;
    }

    return response.data.filter(video =>
      video.title.toLocaleLowerCase().includes(title.toLocaleLowerCase())
    );
  }, [response.data, title]);

  return {
    videos,
    loading,
    error
  };
}
