import useSWR, { SWRResponse, SWRConfiguration } from 'swr';
import { AxiosError } from 'axios';

import api from 'services/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataType<DataType> = (...args: any) => DataType | Promise<DataType>;

export interface Return<T>
  extends Pick<
    SWRResponse<T, AxiosError>,
    'isValidating' | 'revalidate' | 'error' | 'mutate'
  > {
  data: T | undefined;
  loading: boolean;
}

interface useRequestProps<Data> {
  url: string | null;
  fetcher?: DataType<Data>;
  options?: SWRConfiguration;
}

const defaultFetcher = (url: string) => api(url).then(r => r.data);

export default function useRequest<DataType>({
  url,
  fetcher = defaultFetcher,
  options
}: useRequestProps<DataType>): Return<DataType> {
  const { data, error, isValidating, revalidate, mutate } = useSWR<
    DataType,
    AxiosError
  >(url, fetcher, options);

  return {
    data,
    loading: data || error || !url ? false : true,
    error,
    isValidating,
    revalidate,
    mutate
  };
}
