import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'polished';
import { headerHeight } from 'components/Header/styles';

import { LoadingProps } from '.';

const animate = keyframes`
  0% {
    height: 13px;
    width: 13px;
  }
  50% {
    height: 16px;
    width: 16px;
  }
  100% {
    height: 13px;
    width: 13px;
  }
`;

type WrapperPortalProps = {
  style: {
    '--nav-size': string;
  };
};

export const WrapperPortal = styled.div<WrapperPortalProps>`
  ${({ theme }) => css`
    position: fixed;
    left: var(--nav-size);
    z-index: ${theme.layers.alwaysOnTop};
    margin-top: ${headerHeight}rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 8.9rem);
    width: calc(100% - var(--nav-size));
    background-color: ${rgba(theme.colors.white, 0.7)};
  `}
`;

export const Wrapper = styled.div<LoadingProps>`
  display: flex;
  justify-content: space-between;
  width: 55px;
  margin: auto;

  ${({ theme, color, fullPage }) => css`
    color: ${theme.colors[color!]};

    ${fullPage &&
    css`
      height: 100%;
      align-items: center;
    `}
  `};
`;

export const Line = styled.div`
  ${() => css`
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: currentColor;
    display: inline-block;

    &:nth-child(1) {
      animation: ${animate} 1s 0s infinite ease-in-out;
    }

    &:nth-child(2) {
      animation: ${animate} 1s 200ms infinite ease-in-out;
    }

    &:nth-child(3) {
      animation: ${animate} 1s 400ms infinite ease-in-out;
    }
  `}
`;
