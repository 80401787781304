import advanceEndpoints from './advanceService/endpoints.json';
import sessionEndpoints from './sessionService/endpoints.json';
import statementEndpoints from './statementService/endpoints.json';
import userEndpoints from './userService/endpoints.json';
import pixEndpoints from './pixService/endpoints.json';
import saleEndpoints from './saleService/endpoints.json';
import groupEndpoints from './groupService/endpoints.json';
import bankEndpoints from './bankService/endpoints.json';
import reportEndpoints from './reportService/endpoints.json';
import validationEndpoints from './validationService/endpoints.json';
import accreditationEndpoints from './accreditationService/endpoints.json';
import memberEndpoints from './memberService/endpoints.json';
import deeplinkEndpoints from './deeplink/endpoints.json';
import trainingEndpoints from './training/endpoints.json';
import orchestratorEndpoints from './orchestratorService/endpoints.json';
import credentialsEndpoints from './credentialsService/endpoints.json';

export default {
  ...advanceEndpoints,
  ...sessionEndpoints,
  ...statementEndpoints,
  ...userEndpoints,
  ...pixEndpoints,
  ...saleEndpoints,
  ...groupEndpoints,
  ...bankEndpoints,
  ...reportEndpoints,
  ...validationEndpoints,
  ...accreditationEndpoints,
  ...trainingEndpoints,
  ...memberEndpoints,
  ...deeplinkEndpoints,
  ...trainingEndpoints,
  ...orchestratorEndpoints,
  ...credentialsEndpoints
} as const;
