import { Button, Text, Input } from 'components';
import Divider from 'styles/shared/Divider';

type RandomKeyStep3Props = {
  next: () => void;
  createdKey: string;
};

export function RandomKeyStep3({ next, createdKey }: RandomKeyStep3Props) {
  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        Cadastrar com chave aleatória
      </Text>

      <Divider size="large" />

      <Input name="key" label="Sua chave aleatória" value={createdKey} />

      <Divider size="medium" />

      <Button center filled onClick={() => next()}>
        Cadastrar chave Pix
      </Button>
    </>
  );
}

export default RandomKeyStep3;
