import { Text } from 'components';
import Flex from 'components/Flex';
import Toggle from 'components/Toggle';
import Divider from 'styles/shared/Divider';
import * as S from './styles';

export type ToggleLabelProps = {
  title: string;
  subtitle?: string;
  isChecked?: boolean;
  icon?: JSX.Element;
  showBorder?: boolean;
  onCheck: (isChecked: boolean) => void;
};

const ToggleLabel = ({
  icon,
  title,
  onCheck,
  subtitle,
  isChecked = false,
  showBorder = true
}: ToggleLabelProps) => (
  <S.Wrapper showBorder={showBorder}>
    <Flex alignItems="center">
      {icon && icon}

      <S.Texts>
        <Text weight="bold" size="small">
          {title}
        </Text>

        {subtitle && (
          <>
            <Divider size="xsmall" />
            <Text color="grayNeutralDark" size="small">
              {subtitle}
            </Text>
          </>
        )}
      </S.Texts>
    </Flex>

    <Toggle isChecked={isChecked} onCheck={onCheck} />
  </S.Wrapper>
);

export default ToggleLabel;
