import { TutorialRequestKey } from '../components';
import * as S from '../../styles';
const Irroba = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Irroba
      </S.Title>

      <TutorialRequestKey name="Irroba" />

      <S.Paragraph tag="p" color="grayBlue">
        Na sua conta Irroba, acesse
        <strong>
          {' Configurações > Métodos de pagamento > Formas de Pagamento '}
        </strong>
        e clique em instalar no aplicativo Ame Digital.
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Após isso, no campo <strong>“Status”</strong> marque como “Habilitado”,
        cole o Access Token, que é a sua chave de integração que recebeu nas
        etapas anteriores, e salve para habilitar o meio de pagamento.
      </S.Paragraph>
    </div>
  );
};

export default Irroba;
