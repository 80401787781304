import { SyntheticEvent } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Location } from 'history';

import * as S from './styles';
import {
  Categories,
  categories,
  Item,
  ItemPosition,
  Items,
  useMenuItems
} from './items';

import ArrowIcon from 'components/Icons/Arrow';
import { setAnalyticsEvent } from 'utils/analytics';

export type MenuProps = {
  position: ItemPosition;
  menuMobileClick: () => void;
};

const Menu = ({ position, menuMobileClick }: MenuProps) => {
  const menuItems = useMenuItems(position);
  const location = useLocation();
  const listedCategories: Categories[] = [];

  const toogleSubMenu = (e: SyntheticEvent) => {
    e.currentTarget.parentElement!.classList.toggle('expand');
  };

  const handleClick = (item: Item) => {
    menuMobileClick();

    setAnalyticsEvent('Menu', item.id, `Clicou no item de ${item.name}`);

    if (item.onClick) {
      item.onClick();
    }
  };

  const isActive = (itemMenu: Items, location: Location) => {
    if (
      itemMenu.activationPath &&
      location.pathname.includes(itemMenu.activationPath)
    ) {
      return true;
    }

    if (location.pathname.indexOf(itemMenu.link) >= 0) {
      return true;
    } else if (itemMenu.hasSubMenu) {
      const paths = location.pathname.split('/');
      const mainPath = paths[1];

      if (itemMenu.activeMatch) {
        if (itemMenu.activeMatch.includes(mainPath)) {
          return true;
        }
      }
    }

    return false;
  };

  const checkCategories = (item: Item) => {
    if (
      item.category === categories.financialControl &&
      !listedCategories.includes(categories.financialControl)
    ) {
      listedCategories.push(categories.financialControl);
      return <span className="category">{categories.financialControl}</span>;
    } else if (
      item.category === categories.sellWithAme &&
      !listedCategories.includes(categories.sellWithAme)
    ) {
      listedCategories.push(categories.sellWithAme);
      return <span className="category">{categories.sellWithAme}</span>;
    } else if (
      item.category === categories.myCompany &&
      !listedCategories.includes(categories.myCompany)
    ) {
      listedCategories.push(categories.myCompany);
      return <span className="category">{categories.myCompany}</span>;
    }
  };

  return (
    <S.Wrapper>
      {menuItems
        .filter(
          it =>
            !it.hiddenWhenNotActive ||
            (it.hiddenWhenNotActive && isActive(it, location))
        )
        .map(itemMenu => {
          return (
            <div key={itemMenu.id}>
              {checkCategories(itemMenu)}
              <li>
                <NavLink
                  to={itemMenu.link}
                  onClick={() => handleClick(itemMenu)}
                  title={itemMenu.name}
                  className={isActive(itemMenu, location) ? 'active' : ''}
                >
                  <div>
                    {itemMenu.images.map((Image: JSX.Element) => Image)}
                  </div>
                  <span>{itemMenu.name}</span>
                </NavLink>
                {itemMenu.hasSubMenu && (
                  <>
                    <button onClick={toogleSubMenu}>
                      <ArrowIcon direction="down" />
                    </button>

                    <ul>
                      {itemMenu.subMenuItems!.map(itemSubMenu => (
                        <li key={itemSubMenu.id}>
                          <NavLink
                            to={itemSubMenu.link}
                            onClick={menuMobileClick}
                            title={itemSubMenu.name}
                          >
                            <div>{itemSubMenu.images.map(Image => Image)}</div>

                            <span>{itemSubMenu.name}</span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </li>
            </div>
          );
        })}
    </S.Wrapper>
  );
};

export default Menu;
