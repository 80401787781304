import theme from 'styles/theme';
import { ThemeColors } from 'types/global';

const arrowModifiers = {
  directions: {
    up: 'translate(12.5 2) rotate(90)',
    down: 'translate(.5 10) rotate(-90)',
    left: 'translate(2 .5) rotate(0)',
    right: 'translate(11 12.5) rotate(180)'
  }
};

export type ArrowIconProps = {
  color?: ThemeColors | 'currentColor';
  direction?: 'up' | 'down' | 'left' | 'right';
};

const ArrowIcon = ({ color = 'blue', direction = 'left' }: ArrowIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="Arrow"
    width="15"
    height="15"
  >
    <path
      fill={color === 'currentColor' ? color : theme.colors[color]}
      d="M6.363.239.258,5.461a.693.693,0,0,0,0,1.079l6.105,5.22a1.054,1.054,0,0,0,1.34,0,.8.8,0,0,0,0-1.243L2.59,6.144a.185.185,0,0,1,0-.289L7.7,1.482A.8.8,0,0,0,7.7.239a1.057,1.057,0,0,0-1.34,0Z"
      transform={arrowModifiers.directions[direction]}
    />
  </svg>
);

export default ArrowIcon;
