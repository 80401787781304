import * as types from './types';
import endpoints from './endpoints.json';
import api from '../api';

export * from './types';

export { endpoints };

export function getUserProfile() {
  return api.get<types.UserData>(endpoints.user);
}

export async function getPlanData() {
  const result = await api.get<types.PlanDataResponse>(endpoints.planData);

  return result.data;
}

export function setNumberOfInstallments(numberOfInstallments: number) {
  return api.put(endpoints.setInstallments, {
    numberOfInstallments
  });
}

export async function getGroups() {
  const result = await api.get<types.IGroupsData>(endpoints.groups);

  return result.data;
}

export function changePassword({
  oldPassword,
  newPassword,
  newPasswordCheck
}: types.ChangePasswordProps) {
  return api.put(endpoints.changePassword, {
    oldPassword,
    newPassword,
    newPasswordCheck
  });
}

export function changeName({ name }: types.ChangeNameProps) {
  return api.put(endpoints.changeName, {
    name
  });
}

export function changePhone({ phone }: types.ChangePhoneProps) {
  return api.put(endpoints.changePhone, {
    phone
  });
}

export function changeBirthday({ birthday }: types.ChangeBirthdayProps) {
  return api.put(endpoints.changeBirthday, {
    birthday
  });
}

export function changePhoto(formData: FormData) {
  return api.post<types.UserData>(endpoints.changePhoto, formData);
}

export function deletePhoto() {
  return api.delete(endpoints.deletePhoto);
}

export function getPermissions() {
  return api.get<types.GetUserPermissionsResponse>(endpoints.permissions);
}
