import { maskDocument } from 'utils/masks';

import { BankAccountData } from 'contexts/Bank/types';

export const formatData = (data: BankAccountData) => [
  {
    id: 2,
    field1: {
      label: 'Nome:',
      text: data.name
    },
    field2: {
      label: 'CPF ou CNPJ:',
      text: maskDocument(data.documentNumber as string, 'CNPJ')
    }
  },
  {
    id: 3,
    field1: {
      label: 'Tipo de conta:',
      text: data.accountType as string
    },
    field2: {
      label: 'Instituição:',
      text: `(${data.bank}) ${data.name}`
    }
  },
  {
    id: 4,
    field1: {
      label: 'Agência:',
      text: data.agency as string
    },
    field2: {
      label: 'Conta (com dígito):',
      text: data.account as string
    }
  }
];
