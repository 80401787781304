import { useState, useCallback } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-BR');

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {
  isInclusivelyBeforeDay,
  isInclusivelyAfterDay,
  FocusedInputShape
} from 'react-dates';

import { invertDate } from 'utils/masks';

export type CalendarDates = {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
};

type Dates = {
  startDate: string | null;
  endDate: string | null;
};

export type DateRangeProps = {
  initialValues?: Dates & {
    focusedInput?: FocusedInputShape | null;
  };
  rangeDays?: number | null;
  maxDate?: Moment;
};

const useDateRange = ({
  initialValues,
  maxDate = moment(),
  rangeDays = null
}: DateRangeProps) => {
  const [startDate, setStartDate] = useState<Moment | null>(() => {
    if (initialValues?.startDate) {
      const date = invertDate(initialValues.startDate);
      return moment(date);
    }

    return null;
  });
  const [endDate, setEndDate] = useState<Moment | null>(() => {
    if (initialValues?.endDate) {
      return moment(invertDate(initialValues.endDate));
    }

    return null;
  });
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    () => {
      if (initialValues?.focusedInput) {
        return initialValues.focusedInput;
      }

      return null;
    }
  );

  const isOutsideRange = (day: Moment) => {
    if (!rangeDays) {
      return false;
    }

    if (!isInclusivelyBeforeDay(day, maxDate)) {
      return true;
    }

    if (
      startDate &&
      isInclusivelyAfterDay(day, startDate.clone().add(rangeDays, 'days'))
    ) {
      return true;
    }

    return false;
  };

  const onFocusChange = useCallback((focused: FocusedInputShape | null) => {
    if (focused === 'startDate') {
      setEndDate(null);
    }

    setFocusedInput(!focused ? 'startDate' : focused);
  }, []);

  const handleResetDates = () => {
    setStartDate(null);
    setEndDate(null);
    setFocusedInput('startDate');
  };

  return {
    dates: {
      startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
      endDate: endDate ? endDate.format('YYYY-MM-DD') : ''
    },
    resetDates: handleResetDates,
    isOutsideRange,
    onFocusChange,
    focusedInput,
    startDate,
    endDate,
    setStartDate,
    setEndDate
  };
};

export default useDateRange;
