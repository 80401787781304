import { AxiosResponse } from 'axios';
import { DEFAULT_RESPONSE_SUCCESS } from 'utils/constants';

import * as types from '../types';

export const validationSendCodeResponseSuccess: AxiosResponse<types.ValidationSendCodeData> =
  {
    ...DEFAULT_RESPONSE_SUCCESS,
    data: {
      validation: {
        request_verification: true,
        sendTo: 'my......@gmail.com.br',
        type: 'EMAIL'
      }
    }
  };
