import styled, { css } from 'styled-components';
import { Card } from 'styles/shared/Card';

export const CardWrapper = styled(Card)`
  ${({ theme }) => css`
    :hover {
      box-shadow: ${theme.shadow.default};
    }
  `}
`;
