import styled from 'styled-components';

export const ToogleContainer = styled.div`
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  margin: 1rem 0 4rem 0;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
`;
