import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';

import { Flex, Text } from 'components';
import Margin from 'styles/shared/Margin';
import Card from 'styles/shared/Card';
import { Row, Column } from 'styles/shared/Grid';

import { ReactComponent as IconAdd } from 'assets/images/icon-add-outline.svg';

import PATHS_ROUTES from 'routes/paths';

import { usePix } from 'contexts/Pix';
import { formatPixKeyType } from 'utils/pix';

const CardQRCode = () => {
  const navigate = useNavigate();

  const {
    keysList: { data: keysListData },
    qrcode
  } = usePix();

  const encodedQRCode = useMemo(() => {
    if (qrcode && qrcode.pngData)
      return btoa(
        qrcode.pngData.data.reduce(
          (data: string, byte: number) => data + String.fromCharCode(byte),
          ''
        )
      );

    return null;
  }, [qrcode]);

  const handleRedirectToPIXKeysRegisterPage = useCallback(() => {
    navigate(PATHS_ROUTES.APP.PIX.KEYS.REGISTER);
  }, [navigate]);

  const handleRedirectToMyQRCode = useCallback(() => {
    navigate(PATHS_ROUTES.APP.PIX.MY_QRCODE);
  }, [navigate]);

  const handleQRCodeCardOnClickAction = () => {
    !qrcode.pngData
      ? handleRedirectToPIXKeysRegisterPage()
      : handleRedirectToMyQRCode();
  };

  return (
    <>
      {qrcode.pngData ? (
        <>
          <Margin mb="small">
            <Text weight="medium">Pix</Text>
          </Margin>

          <Card
            style={{ cursor: 'pointer' }}
            onClick={handleQRCodeCardOnClickAction}
          >
            <Row alignItemsXs="center">
              <Column xs={12} md={6} lg={6} xl={4}>
                <Flex alignItems="center">
                  <S.QRCode src={`data:image/*;base64,${encodedQRCode}`} />
                  <Margin ml="medium">
                    <Text tag="p" weight="bold" size="xxmedium">
                      Meu QR Code Pix
                    </Text>
                    <Text color="blue" tag="p" weight="regular" size="medium">
                      {keysListData &&
                        formatPixKeyType(keysListData[0].keyType)}
                    </Text>
                  </Margin>
                </Flex>
              </Column>

              <Column xs={10} md={4} lg={4} xl={4}>
                <Flex alignItems="center" justifyContent="center">
                  <Text tag="p" weight="regular" size="small">
                    Clique para ver detalhes
                  </Text>
                </Flex>
              </Column>

              <Column xs={2} md={2} lg={2} xl={4}>
                <Flex direction="column" alignItems="flex-end" my="medium">
                  <S.IconRight />
                </Flex>
              </Column>
            </Row>
          </Card>
        </>
      ) : (
        !keysListData?.length && (
          <>
            <Margin mb="small">
              <Text weight="medium">Pix</Text>
            </Margin>

            <Card
              style={{ cursor: 'pointer' }}
              onClick={handleQRCodeCardOnClickAction}
            >
              <S.CardContainer justifyContent="space-between" wrap="nowrap">
                <Flex alignItems="center">
                  <S.AddPixKey>
                    <IconAdd />
                  </S.AddPixKey>
                  <Margin ml="large">
                    <Text tag="p" color="blue" weight="medium" size="medium">
                      Cadastrar chave Pix
                    </Text>
                  </Margin>
                  <Flex direction="column" alignItems="flex-end" my="medium">
                    <S.IconRight />
                  </Flex>
                </Flex>
              </S.CardContainer>
            </Card>
          </>
        )
      )}
    </>
  );
};

export default CardQRCode;
