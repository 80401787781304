import { transparentize } from 'polished';

import { ThemeFontSizes } from 'types/global';
import { getSessionStorage } from 'utils/storage';

const { theme } = getSessionStorage();

function getLineHeight(size: ThemeFontSizes): string {
  const LINE_HEIGHT = 1.5;
  return `calc(${themeSettings.font.sizes[size]} * ${LINE_HEIGHT})`;
}

const themeSettings = {
  mode: theme,
  grid: {
    sizes: {
      small: '640px',
      medium: '880px',
      large: '980px',
      xlarge: '1140px'
    },
    breakpoints: {
      small: '576px',
      medium: '768px',
      large: '1025px',
      xlarge: '1200px'
    },
    gutter: '.5rem'
  },
  shadow: {
    default: `0.1rem 0.1rem 0.8rem ${transparentize(0.9, '#000')}`,
    hover: `0.1rem 0.1rem 0.8rem ${transparentize(0.8, '#000')}`
  },
  border: {
    smallRadius: '1rem',
    radius: '1.2rem'
  },
  font: {
    family: 'Ubuntu,sans-serif',
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700
    },
    sizes: {
      xxsmall: '1rem',
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      xmedium: '1.8rem',
      xxmedium: '2rem',
      large: '2.2rem',
      xlarge: '2.8rem',
      xxlarge: '3.2rem',
      xxxlarge: '3.4rem'
    },
    lineHeight: getLineHeight
  },
  spacings: {
    xsmall: '0.5rem',
    small: '1rem',
    medium: '2rem',
    large: '4rem',
    xlarge: '8rem'
  },
  colors: {
    blueLight: '#73befd',
    blue: '#0091FF',
    blueDark: '#0c5ec2',
    blueLightest: '#1891FC',
    blueStrongDark: '#12096D',
    grayBlue: '#232846',
    blueCategoryColorSuccess: '#2B9DFF',
    grayLight: '#fafafa',
    grayNeutralLight: '#f2f2f2',
    grayNeutralDark: '#848484',
    grayExtraMedium: '#c7c7c7',
    grayMedium: '#dbdbdb',
    grayDark: '#909090',
    grayIcon: '#313547',
    grayNeutralBrandDarkest: '#313647',
    blackDark: '#161b30',
    yellowDark: '#F5A623',
    red: '#FF2D55',
    white: '#FFFFFF',
    black: '#000',
    purple: '#452EA0',
    transparent: 'transparent',
    turquoise: '#266280',
    orangeCategoryWaitingProcessing: '#F5A623',
    secondaryPlusDarkest: '#064781',
    secondaryPlus: '#136180'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50
  },
  transition: {
    slow: '0.5s ease-in-out',
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  },
  profile: {
    fontSizes: {
      xsmall: '1.3rem',
      small: '2.2rem',
      medium: '3.6rem',
      large: '5.2rem'
    },
    sizes: {
      xsmall: '3.3rem',
      small: '4.5rem',
      medium: '8.6rem',
      large: '15rem'
    },
    radius: {
      xsmall: '1rem',
      small: '1rem',
      medium: '1rem',
      large: '2rem'
    },
    colors: {
      profileTypes: '#73BEFD'
    }
  },
  layout: {
    sidebar: {
      sizeOpened: '30rem',
      sizeClosed: '8rem'
    }
  }
} as const;

export default themeSettings;
