import styled, { css } from 'styled-components';
import YouTube from 'react-youtube';
import { Button } from 'components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Video = styled<any>(YouTube)`
  width: 100%;
  min-height: 40rem;
`;

export const PlaylistWrapper = styled.section`
  height: calc(100vh - 25rem);
`;

export const PlaylistHeader = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.blue};
    height: 6rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
  `}
`;

export const Playlist = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  height: calc(100vh - 32rem);
  overflow: auto;
`;

export const Item = styled.li`
  display: flex;
  gap: 1rem;
  height: 10rem;
  cursor: pointer;
`;

export const WatchedText = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.blue};
    border-bottom: 0.5rem solid ${theme.colors.blue};
    margin-bottom: 1rem;
  `}
`;

export const Thumbnail = styled.img`
  width: 50%;
  max-width: 15rem;
`;

export const ButtonWrapper = styled(Button)`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.weights.medium};

    span {
      display: flex;
      align-items: center;

      svg {
        margin-left: ${theme.spacings.xsmall};
      }
    }
  `}
`;
