import * as S from './styles';

export type LinkProps = {
  link: string;
};

const QrCode = ({ link }: LinkProps) => (
  <S.Wrapper>
    <S.IconWrapper />
    <S.QrCodeImage alt="Imagem de qr code" role="qrcode_image" src={link} />
  </S.Wrapper>
);

export default QrCode;
