import { Button, Flex, TemplateModal, Text } from 'components';
import { ReactComponent as IconMyLimits } from 'assets/images/pix/icon-first-access-my-limits.svg';

export type FirstAccessProps = {
  handleClose: () => void;
};

const FirstAccess = ({ handleClose }: FirstAccessProps) => {
  return (
    <TemplateModal title="Meus limites Pix" closeModal={handleClose}>
      <Flex alignItems="center" direction="column" mx="xlarge">
        <IconMyLimits />

        <Text size="large" tag="h2" weight="bold" align="center" mb="medium">
          Mais segurança nas suas transferências Pix!
        </Text>

        <Text
          size="xsmall"
          tag="p"
          align="center"
          color="grayNeutralDark"
          mx="medium"
          mb="large"
        >
          A partir de agora, o aumento dos limites de transferência para outras
          titularidades ficarão disponíveis entre 24 e 48 horas.
        </Text>

        <Button filled onClick={handleClose}>
          Continuar
        </Button>
      </Flex>
    </TemplateModal>
  );
};

export default FirstAccess;
