import { Button, Text } from 'components';
import styled, { css } from 'styled-components';
import { ThemeColors } from 'types/global';

export const Title = styled(Text).attrs({
  tag: 'h1',
  weight: 'bold',
  size: 'xxlarge'
})`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};
  `}
`;

export const Subtitle = styled(Text).attrs({
  tag: 'h2',
  size: 'xxmedium'
})`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.large};
  `}
`;

type HighlightProps = {
  color?: ThemeColors;
};

export const HighlightText = styled.span<HighlightProps>`
  ${({ theme, color = 'blue' }) => css`
    color: ${theme.colors[color]};
  `}
`;

export const Action = styled(Button).attrs({
  type: 'submit',
  center: true,
  secondary: true
})`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.large};
  `}
`;
