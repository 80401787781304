/* eslint-disable @typescript-eslint/no-unused-vars */
import * as types from './types';
import * as mocks from './__mocks__/';
import endpoints from './endpoints.json';
import api from '../api';
import Ame, { AskUserDataInput } from 'ame-super-app-web';

export { endpoints };

export function refreshToken() {
  return api.put<types.RefreshToken>(endpoints.refreshToken);
}

export function login({ username, password }: types.LoginWithPasswordProps) {
  return api.post<types.LoginWithPasswordData>(endpoints.loginWithPassword, {
    username,
    password
  });
}

export function validationSendCode() {
  return Promise.resolve(mocks.validationSendCodeResponseSuccess);
  //return api.post<types.validationSendCodeData>(endpoints.validationSendCode);
}

export function forgotPassword({ username }: types.ForgotPasswordProps) {
  const url = endpoints.forgotPassword.replace('{username}', username);

  return api.post(url);
}

export function newPassword({
  token,
  newPassword,
  newPasswordCheck
}: types.NewPasswordProps) {
  return api.post(
    endpoints.newPassword,
    {
      newPassword,
      newPasswordCheck
    },
    {
      params: { token }
    }
  );
}

export function validationCheckCode(code: string) {
  return Promise.resolve(mocks.validationSendCodeResponseSuccess);
  //return api.put(endpoints.validationCheckCode.replace('{{code}}', code));
}

export function loginTwoFactorValidate({ code }: types.LoginValidateProps) {
  const url = endpoints.loginValidate.replace('{code}', `${code}`);

  return api.put<types.LoginData>(url);
}

export function logout() {
  Ame.logout();
  return api.delete(endpoints.logout);
}

export function changeTokenToChildAccount(document: string) {
  return api.post(endpoints.changeTokenToChildAccount, {
    id: document
  });
}

export function changeTokenToMasterAccount() {
  return api.post(endpoints.changeTokenToMasterAccount);
}

export function getCSRFToken() {
  return api.get(endpoints.getCSRFToken);
}

export async function askUserDataForMiniApps(input: AskUserDataInput) {
  const { slug, workspaceData } = input;
  const res = await api.post(endpoints.askUserDataForMiniApps, {
    slug,
    ...workspaceData
  });
  return res.data;
}
