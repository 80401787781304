export type Group = {
  id: string;
  title: string;
  items: { name: string; image?: string }[];
};

export const accounts = [...Array(24)].map((_, index) => ({
  id: `e4a4d043-9709-11eb-a8b3-0242a34303${index + 1}`,
  title: `Postos BR Adm ${index + 1}`,
  documentNumber: `85.831.583/0001-${(index + 1).toString().padStart(2, '0')}`
}));
