import styled, { css } from 'styled-components';

import { Card } from 'styles/shared/Card';
import { ThemeColors } from 'types/global';

type CardWrapperProps = {
  filledBgColor: ThemeColors;
};

export const CardWrapper = styled(Card)<CardWrapperProps>`
  ${({ theme, filledBgColor }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${theme.colors.transparent};
    padding: 30px 20px;
    background: ${theme.colors[filledBgColor]};
    width: 100%;
    height: 220px;

    svg,
    img {
      width: 100%;
      max-width: 185px;
      margin-right: 2rem;
    }

    ul {
      max-width: 380px;
    }

    li {
      margin-bottom: 10px;
    }
  `}
`;
