import { useMemo } from 'react';

import useRequest from 'hooks/useRequest';
import { checkCEP } from 'utils/validations';
import { removeSpecialCharacters } from 'utils/masks';
import endpoints from 'services/endpoints';

type UseAddressProps = {
  partnershipType: string;
  partnership: string;
  cep: string;
};

interface CepAddress {
  address: string;
  city: string;
  neighborhood: string;
  number: string;
  state: string;
}

interface ShoppingAddress {
  address: string;
  city: string;
  neighborhood: string;
  number: string;
  state: string;
  zipCode: string;
}

type Address = Partial<ShoppingAddress>;

function formatCepAddress(cep?: CepAddress): Address | null {
  if (!cep) return null;

  return {
    ...cep,
    number: '',
    zipCode: cep.number
  };
}

export function useAccreditationAddress({
  partnershipType,
  partnership,
  cep
}: UseAddressProps) {
  const isShopping = partnershipType === 'shopping';

  const endpointPartner = endpoints.accreditationPartnerAddress.replace(
    ':id',
    partnership
  );

  const endpointCep = endpoints.accreditationCep.replace(
    ':cep',
    removeSpecialCharacters(cep)
  );

  const { data, loading, error } = useRequest<ShoppingAddress>({
    url: isShopping ? endpointPartner : null
  });

  const {
    data: cepData,
    loading: cepLoading,
    error: cepError
  } = useRequest<CepAddress>({
    url: !isShopping && checkCEP(cep) ? endpointCep : null
  });

  const cepAddress = useMemo(() => formatCepAddress(cepData), [cepData]);

  return {
    address: data || cepAddress,
    loading: loading || cepLoading,
    error,
    disabled: !!partnershipType && !!data,
    warning: cepError
  };
}
