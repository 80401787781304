import { useCallback, useMemo, useState } from 'react';

import {
  Breadcrumb,
  Flex,
  TemplateShortContent,
  Text,
  Button,
  Input,
  ErrorMessage
} from 'components';
import Divider from 'styles/shared/Divider';
import { ValidationCode } from '../components/ValidationCode';
import RegisterKeyModals from '../components/RegisterKeyModals';
import { ReactComponent as IconPix } from 'assets/images/pix/icon-pix.svg';

import { useAuth } from 'contexts/Auth';
import { usePixRegister } from 'contexts/Pix/PixRegister';
import { PixRegisterData } from 'contexts/Pix/PixRegister/types';

import {
  maskOnlyNumbers,
  getCodeAndMaskByPhoneNumber,
  formatMask
} from 'utils/masks';

import { breadcrumbItems } from './constants';

const PhoneKey = () => {
  const { user } = useAuth();
  const {
    loading,
    status,
    setStatus,
    handleCreatePixKey,
    sendCodeValidation,
    featureToggle
  } = usePixRegister();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [validationCode, setValidationCode] = useState<string>('');
  const [validationCodeActived, setValidationCodeActived] =
    useState<boolean>(false);

  const [phoneInputValue, codeInputValue, mask] = useMemo(() => {
    let phone = user?.phoneNumber || '';
    const [code, mask] = getCodeAndMaskByPhoneNumber(phone);

    phone = phone.replace(code, '');
    phone = maskOnlyNumbers(phone);
    return [phone, code, mask];
  }, [user]);

  const validationFeatureToogle = () => {
    if (featureToggle.enabled) {
      callSendCodeValidation();
      setValidationCodeActived(true);
    } else {
      setOpenModal(true);
    }
  };

  const submitKey = useCallback(
    data => {
      const body: PixRegisterData = data.validationCode
        ? {
            key: `${codeInputValue}${phoneInputValue}`,
            validationCode: data.validationCode,
            keyType: 'PHONE_NUMBER',
            reason: 'USER_REQUESTED'
          }
        : {
            key: `${codeInputValue}${phoneInputValue}`,
            keyType: 'PHONE_NUMBER',
            reason: 'USER_REQUESTED'
          };

      handleCreatePixKey(body);
    },
    [codeInputValue, handleCreatePixKey, phoneInputValue]
  );

  const callSendCodeValidation = () => {
    sendCodeValidation({
      key: `${codeInputValue}${phoneInputValue}`,
      keyType: 'PHONE_NUMBER'
    });
  };

  const closeModal = () => {
    setStatus('initial');
    setOpenModal(false);
  };

  if (!phoneInputValue) {
    return (
      <ErrorMessage message="Para prosseguir é necessário ter um telefone cadastrado na sua conta." />
    );
  }

  return (
    <>
      <Breadcrumb links={breadcrumbItems} />

      <Divider size="xlarge" />

      <TemplateShortContent>
        <Flex alignItems="center">
          <IconPix />

          <Text ml="small" tag="h1" size="large" weight="medium">
            Minhas Chaves
          </Text>
        </Flex>

        <Divider size="large" />

        {!validationCodeActived && (
          <Flex direction="column">
            <Text tag="h2" weight="bold" size="large">
              Cadastrar chave com o número do seu celular
            </Text>

            <Divider size="medium" />

            <Flex>
              <Input
                name="phoneCode"
                label="País"
                value={codeInputValue}
                disabled
                style={{ width: '5rem' }}
              />

              <Input
                name="phoneNumber"
                label="Número do Celular"
                value={formatMask(phoneInputValue, mask)}
                placeholder={mask}
                disabled
              />
            </Flex>

            <Divider size="medium" />

            <Button
              center
              filled
              onClick={() => validationFeatureToogle()}
              role="button-register-phone"
            >
              Cadastrar chave Pix
            </Button>
          </Flex>
        )}

        {validationCodeActived && (
          <ValidationCode
            sendCodeValidation={() => callSendCodeValidation}
            changeValue={value => setValidationCode(value)}
            onMatch={() => setOpenModal(true)}
            loading={loading}
            description="Digite abaixo o código que enviamos por SMS para o número de celular cadastrado na sua conta"
          />
        )}

        {openModal && (
          <RegisterKeyModals
            status={status}
            createdKey={phoneInputValue}
            submitKey={() =>
              submitKey(
                validationCodeActived
                  ? { phone: phoneInputValue, validationCode }
                  : { phone: phoneInputValue }
              )
            }
            onClose={closeModal}
            loading={loading}
            validationCode={validationCode}
          />
        )}
      </TemplateShortContent>
    </>
  );
};

export default PhoneKey;
