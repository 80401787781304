import * as Yup from 'yup';
import { fieldNames } from 'pages/Accreditation/shared/form';
import api from 'services/api';
import endpoints from 'services/endpoints';

import { getApiError } from 'utils/errors';

const codeSchema = Yup.string()
  .required('Código obrigatório')
  .length(4, 'Código inválido');

export async function isValidCode(
  code?: string,
  email?: string
): Promise<boolean> {
  if (!code || !email) {
    return false;
  }

  await api.put(endpoints.validationCodeEmail.replace(':code', code), {
    email
  });

  return true;
}

export const validation = Yup.object().shape({
  [fieldNames.validateEmail]: codeSchema.test(
    'is valid code',
    'Código inválido',
    async (value, { parent, createError }) => {
      try {
        await codeSchema.validate(value);

        return await isValidCode(value, parent[fieldNames.email]);
      } catch (error) {
        const { code, message } = getApiError(error);

        if (code === 404) {
          return false;
        }

        if (message) {
          return createError({
            message: message,
            path: fieldNames.validateEmail
          });
        }

        return false;
      }
    }
  )
});
