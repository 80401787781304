import { InputHTMLAttributes, useCallback, useMemo, useState } from 'react';

import Text from 'components/Text';
import { MaskTypes, ThemeColors } from 'types/global';
import { formatInput, maskOnlyNumbers } from 'utils/masks';

import { ReactComponent as IconEyeHide } from 'assets/images/icon-eye-hide.svg';
import { ReactComponent as IconEyeShow } from 'assets/images/icon-eye-show.svg';

import * as S from './styles';

export type InputProps = {
  name: string;
  label?: string;
  maskType?: MaskTypes;
  onlyNumbers?: boolean;
  errorMessage?: string;
  alertMessage?: string;
  withMargin?: boolean;
  noBorder?: boolean;
  action?: () => void;
  actionChildren?: string | JSX.Element;
  color?: ThemeColors;
} & InputHTMLAttributes<HTMLInputElement>;

const masksLength = {
  CPF: 14,
  CNPJ: 18,
  DATE: 10,
  PHONE: 15,
  CELLPHONE: 15,
  CURRENCY: 20,
  CEP: 9,
  ACCOUNT: 12,
  DOCUMENT: 18,
  LOWER_LETTERS: 30,
  LOWER_ALPHANUMERIC: 30
};

const Input = ({
  name,
  type,
  label,
  maskType,
  onlyNumbers,
  errorMessage = '',
  withMargin = true,
  noBorder = false,
  maxLength = 999,
  action,
  actionChildren,
  alertMessage,
  placeholder,
  value,
  onChange,
  onBlur,
  color,
  ...rest
}: InputProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const isPasswordType = type === 'password';

  const inputType = isPasswordType && passwordVisible ? 'text' : type;

  const inputMaxLength = maskType ? masksLength[maskType] : maxLength;

  const showPlaceholder = !(label && label === placeholder);

  const hasValue = !!String(value ?? '');

  const isLabelFloating = Boolean(hasValue || placeholder);

  const getFormattedValue = useCallback(
    (value?: string | number | readonly string[]) => {
      const formattedValue = String(value);

      if (maskType && formattedValue) {
        return formatInput[maskType](formattedValue);
      }

      if (onlyNumbers) {
        return maskOnlyNumbers(formattedValue);
      }

      return formattedValue;
    },
    [maskType, onlyNumbers]
  );

  const formattedValue = useMemo(() => {
    return getFormattedValue(value);
  }, [getFormattedValue, value]);

  return (
    <S.Wrapper withMargin={withMargin}>
      {!!label && (
        <S.Label
          htmlFor={name}
          floating={isLabelFloating}
          isDisabled={rest.disabled}
        >
          {label}
        </S.Label>
      )}
      <S.InputWrapper hasAction={!!action || isPasswordType}>
        <S.Input
          name={name}
          type={inputType}
          id={name}
          autoComplete="off"
          {...rest}
          value={formattedValue}
          maxLength={inputMaxLength}
          noBorder={noBorder}
          onChange={e => {
            if (!onChange) return;

            e.target.value = getFormattedValue(e.target.value);

            onChange(e);
          }}
          onBlur={onBlur}
          isPasswordType={isPasswordType}
          placeholder={showPlaceholder ? placeholder : ''}
          color={color}
        />
        {!!action && (
          <button type="button" onClick={action}>
            <Text color="blue" weight="medium" size="small">
              {actionChildren}
            </Text>
          </button>
        )}

        {isPasswordType && !action && (
          <button
            type="button"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            <Text color="blue" weight="medium" size="small">
              {passwordVisible ? <IconEyeShow /> : <IconEyeHide />}
            </Text>
          </button>
        )}
      </S.InputWrapper>
      {!!errorMessage && (
        <Text color="red" size="xsmall">
          {errorMessage}
        </Text>
      )}
      {!!alertMessage && <Text size="xsmall">{alertMessage}</Text>}
    </S.Wrapper>
  );
};

export default Input;
