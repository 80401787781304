import { fieldNames } from 'pages/Accreditation/shared/form';
import { isValidUrl } from 'utils/validations';
import * as Yup from 'yup';

export const validation = Yup.object().shape({
  [fieldNames.website]: Yup.string()
    .required('Endereço web obrigatório')
    .test(
      'isValidUrl',
      'Endereço web inválido',
      value => !!value && isValidUrl(value)
    )
});
