import React from 'react';
import { Button, Text } from 'components';
import Divider from 'styles/shared/Divider';
import { formatInput } from 'utils/masks';

const CNPJSubmit = ({
  documentInputValue,
  handleSetProceedWithRegistration,
  handleStatusModalOpenState
}: {
  documentInputValue: string;
  handleSetProceedWithRegistration: () => void;
  handleStatusModalOpenState: () => void;
}) => {
  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        Revise seus dados
      </Text>

      <Divider size="small" />

      <Text tag="p" size="medium">
        Você está cadastrando uma nova chave com o CNPJ{' '}
        <Text tag="span" size="medium" weight="bold">
          {formatInput['CNPJ'](documentInputValue)}
        </Text>
        . Deseja confirmar o cadastro?
      </Text>

      <Divider size="large" />

      <Button
        filled
        center
        onClick={handleStatusModalOpenState}
        role="button-open-confirmation-modal"
      >
        Confirmar cadastro
      </Button>

      <Button
        center
        filled
        filledBgColor="transparent"
        filledColor="blue"
        onClick={handleSetProceedWithRegistration}
      >
        Cancelar
      </Button>
    </>
  );
};

export default CNPJSubmit;
