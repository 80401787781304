import { Button, Text } from 'components';
import Divider from 'styles/shared/Divider';

type RandomKeyStep2Props = {
  next: () => void;
  cancel: () => void;
};

export function RandomKeyStep2({ next, cancel }: RandomKeyStep2Props) {
  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        Cadastrar com chave aleatória
      </Text>

      <Text tag="p" size="small" mt="small">
        Você está cadastrando uma chave aleatória para usar na sua conta Ame.
        Deseja confirmar o cadastro?
      </Text>

      <Divider size="large" />

      <Button center filled onClick={() => next()} role="button-register-step2">
        Cadastrar chave Pix
      </Button>

      <Button center tertiary onClick={() => cancel()}>
        Cancelar
      </Button>
    </>
  );
}

export default RandomKeyStep2;
