import styled from 'styled-components';
import { CardProfile } from 'components';
import { Column } from 'styles/shared/Grid';

export const Card = styled(CardProfile)`
  height: 100%;
`;

export const VideoColumn = styled(Column)`
  padding-bottom: 2rem;
`;
