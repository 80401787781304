import { Button } from 'components';
import styled, { css } from 'styled-components';

export const ProgressWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.medium};
    margin-bottom: ${theme.spacings.large};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FormWrapper = styled.div`
  flex: 1;
`;

export const NextButton = styled(Button).attrs({
  tertiary: true
})`
  ${({ theme }) => css`
    visibility: hidden;
    margin-left: ${theme.spacings.medium};
  `}
`;

const previousModifiers = {
  hidden: () => css`
    visibility: hidden;
  `
};

export const PreviousButton = styled(Button).attrs({
  tertiary: true
})`
  ${({ theme, hidden }) => css`
    transform: rotate(180deg);
    margin-right: ${theme.spacings.medium};

    ${hidden && previousModifiers.hidden()}
  `}
`;
