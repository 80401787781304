import { ReactElement, useState } from 'react';

import * as S from './styles';

import { Text, Button, IconArrow } from 'components';

import { Divider } from 'styles/shared/Divider';

export type SelectOption = {
  label: string;
  value: string | number;
  component?: ReactElement;
};

export type SelectProps = {
  name: string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  withMargin?: boolean;
  noBorder?: boolean;
  required?: boolean;
  disabled?: boolean;
  value?: SelectOption | null;
  options?: SelectOption[];
  noOptionsMessage?: () => void;
  onChange?: (
    value: SelectOption,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void;
};

const Select = ({
  name,
  label,
  placeholder = '',
  errorMessage = '',
  withMargin = true,
  noBorder = false,
  required,
  value,
  options,
  noOptionsMessage = () => 'Nenhuma opção',
  onChange,
  disabled,
  ...props
}: SelectProps) => {
  const [visibleMobileOptions, setVisibleMobileOptions] = useState(false);
  const [open, setOpen] = useState(false);
  const isFloatingLabel = Boolean(open || value || placeholder);

  return (
    <S.Wrapper withMargin={withMargin} disabled={disabled}>
      {!!label && (
        <S.Label htmlFor={name} floating={isFloatingLabel}>
          {label}
        </S.Label>
      )}

      <S.Select
        id={name}
        name={name}
        placeholder={placeholder}
        required={required}
        options={options}
        defaultValue={value}
        onChange={onChange}
        noBorder={noBorder}
        noOptionsMessage={noOptionsMessage}
        value={value}
        onMenuOpen={() => setOpen(true)}
        onMenuClose={() => setOpen(false)}
        isDisabled={disabled}
        {...props}
      />

      <S.SelectMobileWrapper>
        <S.SelectMobile
          name={name}
          action={() => setVisibleMobileOptions(true)}
          actionChildren={<IconArrow color="grayDark" direction="right" />}
          value={value?.label || ''}
          disabled
        />
      </S.SelectMobileWrapper>

      {!!errorMessage && (
        <Text color="red" size="xsmall">
          {errorMessage}
        </Text>
      )}

      {visibleMobileOptions && (
        <S.MobileView>
          <Button
            tertiary
            onClick={() => setVisibleMobileOptions(false)}
            icon={<S.IconBackArrow />}
          />

          {(!!placeholder || !!label) && (
            <Text size="large" weight="medium">
              {placeholder || label}
            </Text>
          )}

          <Divider />

          {options
            ? options.map((option: SelectOption) => (
                <S.MobileOption
                  key={option.value}
                  tertiary
                  fullWidth
                  onClick={() => {
                    onChange && onChange(option);
                    setVisibleMobileOptions(false);
                  }}
                >
                  {option.label}
                </S.MobileOption>
              ))
            : noOptionsMessage()}
        </S.MobileView>
      )}
    </S.Wrapper>
  );
};

export default Select;
