import { ReactNode } from 'react';

import { Container, Row } from 'styles/shared/Grid';

import logoAmeplus from 'assets/images/icon-logo-ameplus.svg';
import logoAmeplusShort from 'assets/images/icon-logo-ameplus-short.svg';

import * as S from './styles';

export type HeaderProps = {
  hasMenu?: boolean;
  onClick?: () => void;
  children?: ReactNode;
};

const Header = ({ hasMenu, onClick, children }: HeaderProps) => {
  return (
    <S.Header>
      {hasMenu ? (
        <Container>
          <Row justifyContentLg="flex-end" justifyContentXs="space-between">
            <S.LogoMobile onClick={onClick}>
              <img src={logoAmeplusShort} alt="Ame plus - Mobile" />
            </S.LogoMobile>

            {children}
          </Row>
        </Container>
      ) : (
        <>
          <S.Logo src={logoAmeplus} alt="Ame plus" />

          <Container>
            <Row justifyContentXs="flex-end">{children}</Row>
          </Container>
        </>
      )}
    </S.Header>
  );
};

export default Header;
