import styled, { css, DefaultTheme } from 'styled-components';

import { ThemeSpacings } from 'types/global';

type Directions = 'vertical' | 'horizontal';

export type DividerProps = {
  size?: ThemeSpacings;
  direction?: Directions;
  withBorder?: boolean;
};

const DividerModifiers = {
  vertical: (theme: DefaultTheme, size: ThemeSpacings) => css`
    height: ${theme.spacings[size]};
    width: 100%;
  `,

  horizontal: (theme: DefaultTheme, size: ThemeSpacings) => css`
    width: ${theme.spacings[size]};
    height: 1px;
  `,

  withBorder: (theme: DefaultTheme) => css`
    border-top: 1px solid ${theme.colors.grayMedium};
  `
};

export const Divider = styled.div<DividerProps>`
  ${({
    theme,
    size = 'medium',
    direction = 'vertical',
    withBorder = false
  }) => css`
    ${DividerModifiers[direction](theme, size)};
    ${withBorder && DividerModifiers.withBorder(theme)};
  `}
`;

export default Divider;
