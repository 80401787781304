import { useCallback, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'contexts/Auth';

import useTimer from 'hooks/useTimer';
import useMutate from 'hooks/useMutate';

import {
  Button,
  CodeInput,
  Loading,
  Text,
  Link,
  Flex,
  TemplateAuth,
  TimeText
} from 'components';

import { endpoints } from 'services/sessionService';

import { Container, Row, Column } from 'styles/shared/Grid';
import { Divider } from 'styles/shared/Divider';

import PATHS_ROUTES from 'routes/paths';

import * as S from './styles';
import { validationSchema } from './validationSchema';

interface LoginValidateFormValues {
  code: string;
}

export const initialValues: LoginValidateFormValues = {
  code: ''
};

const LoginAuthValidate = () => {
  const { signInValidate, twoFactor } = useAuth();
  const { state, mutate } = useMutate();
  const { time, restartTimer } = useTimer({ decrement: true });
  const navigate = useNavigate();

  const [loadingAuth, setLoadingAuth] = useState(true);
  const [errorAuth, setErrorAuth] = useState<string | null>(null);

  const { loading, error } = state;

  const handleMatch = useCallback(
    async (code: string) => {
      try {
        setLoadingAuth(true);

        await signInValidate({ code });
      } catch (e) {
        setErrorAuth('Erro ao validar o código');

        setLoadingAuth(false);
      }
    },
    [signInValidate]
  );

  const handleSubmit = async ({ code }: LoginValidateFormValues) =>
    handleMatch(code);

  const resendCode = useCallback(() => {
    restartTimer();

    mutate({
      endpoint: endpoints.loginSendCode
    });
  }, [mutate, restartTimer]);

  useEffect(() => {
    if (!twoFactor?.request_verification) {
      navigate(PATHS_ROUTES.AUTH.LOGIN);
    }

    setLoadingAuth(false);
  }, [navigate, twoFactor]);

  return (
    <>
      {(!!loading || !!loadingAuth) && <Loading fullContent fullWidth />}
      <TemplateAuth>
        <Container>
          <Row justifyContentXs="center">
            <Column xs={10}>
              <S.Card>
                <Text tag="h1" weight="bold" size="xxlarge">
                  Ame Sua Conta{' '}
                  <Text tag="span" color="blue" weight="bold" size="xxlarge">
                    :)
                  </Text>
                </Text>

                <Text>
                  Digite abaixo o código que enviamos por e-mail para o endereço
                  eletrônico {twoFactor?.sendTo} cadastrado na sua conta.
                </Text>

                <Divider />

                <Formik
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                >
                  {({ values, handleBlur, setFieldValue }) => (
                    <Form>
                      <Flex
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Divider size="large" />

                        <CodeInput
                          name="code"
                          onChangeValue={value => {
                            if (errorAuth) setErrorAuth(null);
                            setFieldValue('code', value, false);
                          }}
                          onMatch={handleMatch}
                          onBlur={handleBlur}
                          value={values.code}
                        />

                        {(!!error || !!errorAuth) && (
                          <Text
                            color="red"
                            size="xsmall"
                            mt="medium"
                            mb="xsmall"
                          >
                            {error || errorAuth}
                          </Text>
                        )}

                        <Divider size="large" />

                        <TimeText time={time} resendCode={resendCode} />

                        <Divider />

                        <Button
                          as={Link}
                          to={PATHS_ROUTES.AUTH.LOGIN}
                          tertiary
                          center
                        >
                          Voltar
                        </Button>
                      </Flex>

                      <Divider />
                    </Form>
                  )}
                </Formik>
              </S.Card>
            </Column>
          </Row>
        </Container>
      </TemplateAuth>
    </>
  );
};

export default LoginAuthValidate;
