import styled, { css } from 'styled-components';

export const Installment = styled.li`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.grayExtraMedium};

    &:last-child {
      border-bottom: 1px solid ${theme.colors.grayExtraMedium};
    }

    button {
      width: 100%;
      height: 100%;
      padding: ${theme.spacings.medium};
      justify-content: flex-start;
    }
  `}
`;
