import moment from 'moment';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  birthday: yup
    .string()
    .required('Data de nascimento é um campo obrigatório')
    .test('birthday', 'Idade deve ser maior que 18 anos', value => {
      return moment().diff(moment(value, 'DD/MM/YYYY'), 'years') >= 18;
    })
});
