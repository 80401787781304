import { useState } from 'react';
import { useFormik } from 'formik';

import { forgotPassword } from 'services/sessionService';
import { ForgotPasswordProps } from 'services/sessionService/types';

import {
  Loading,
  Button,
  Input,
  Text,
  Link,
  TemplateAuth,
  ModalStatus
} from 'components';

import { Container, Row, Column } from 'styles/shared/Grid';
import { Divider } from 'styles/shared/Divider';

import { getApiError } from 'utils/errors';

import PATHS_ROUTES from 'routes/paths';
import useToggle from 'hooks/useToggle';

import * as S from './styles';
import { validationSchema } from './validationSchema';

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const modalSuccess = useToggle();

  const formik = useFormik({
    initialValues: {
      username: ''
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: ({ username }) => {
      formik.setSubmitting(true);

      setError(null);

      handleForgotPassword({ username });
    }
  });

  const handleForgotPassword = async ({ username }: ForgotPasswordProps) => {
    setLoading(true);

    try {
      await forgotPassword({ username });

      formik.resetForm();

      modalSuccess.toggle();
    } catch (error) {
      const { message } = getApiError(error, {
        message: 'Ocorreu um erro ao tentar redefinir a senha'
      });

      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading fullContent fullWidth />}
      <TemplateAuth>
        <Container>
          <Row justifyContentXs="center">
            <Column xs={10}>
              <S.Card>
                <Text tag="h1" weight="bold" size="xxlarge">
                  Esqueceu a senha?
                </Text>

                <Text>
                  Preencha o campo abaixo e receba as instruções no seu e-mail
                  ;)
                </Text>

                <Divider />

                <form onSubmit={formik.handleSubmit}>
                  <Input
                    name="username"
                    placeholder="Digite o seu usuário"
                    onChange={(e: React.ChangeEvent) => {
                      if (error) setError(null);

                      formik.handleChange(e);
                    }}
                    value={formik.values.username}
                    errorMessage={formik.errors.username}
                  />

                  <Divider size="large" />

                  {!!error && (
                    <Text color="red" size="xsmall" mb="medium">
                      {error}
                    </Text>
                  )}

                  <Row justifyContentXs="center">
                    <Column xs={10} md={5}>
                      <Button type="submit" disabled={loading} fullWidth filled>
                        Redefinir a senha
                      </Button>

                      <Divider />

                      <Button
                        as={Link}
                        to={PATHS_ROUTES.AUTH.LOGIN}
                        tertiary
                        center
                      >
                        Voltar
                      </Button>
                    </Column>
                  </Row>
                </form>
              </S.Card>
            </Column>
          </Row>
        </Container>

        {modalSuccess.isActive && (
          <ModalStatus
            variant="success"
            title="Redefinição de senha"
            onClose={modalSuccess.toggle}
            description="Você vai encontrar no seu e-mail as instruções necessárias para
          recuperar a sua senha esquecida. Caso o problema ainda não seja
          resolvido entre em contato com o nosso SAC no 4003-9975"
            action={{
              asLink: true,
              filled: false,
              tertiary: true,
              text: 'Ir para o início',
              to: PATHS_ROUTES.AUTH.LOGIN
            }}
          >
            <Text size="large" weight="bold" align="center">
              E-mail enviado <br /> com sucesso{' '}
              <Text size="large" weight="bold" tag="span" color="blue">
                ;)
              </Text>
            </Text>
          </ModalStatus>
        )}
      </TemplateAuth>
    </>
  );
}

export default ForgotPassword;
