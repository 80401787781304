import { useState, useCallback, useEffect } from 'react';

import * as S from './styles';

import useRequest from 'hooks/useRequest';

import endpoints from 'services/endpoints';
import { GetProfileTypesRequest } from 'services/memberService/types';

import { ProfileTypes } from 'types/global';

import ProfileType from 'components/ProfileType';

export type ProfileTypeListProps = {
  initialProfileSelected?: string;
  onSelect?: (id: string, type: ProfileTypes, label: string) => void;
  blockEditProfileType?: boolean;
};

const ProfileTypeList = ({
  onSelect,
  initialProfileSelected,
  blockEditProfileType = false
}: ProfileTypeListProps) => {
  const { data: PROFILES } = useRequest<GetProfileTypesRequest[]>({
    url: endpoints.profileTypes
  });
  const [profiles, setProfiles] = useState(PROFILES);
  const [selectedProfile, setSelectedProfile] = useState(
    initialProfileSelected
  );

  useEffect(() => {
    if (blockEditProfileType) {
      const profilesList = PROFILES?.filter(
        profile => profile.id === initialProfileSelected
      );
      setProfiles(
        profilesList?.map(profile => ({
          ...profile,
          selected: profile.id === initialProfileSelected
        }))
      );
    } else {
      setProfiles(
        PROFILES?.map(profile => ({
          ...profile,
          selected: profile.id === initialProfileSelected
        }))
      );
    }
  }, [PROFILES, initialProfileSelected, blockEditProfileType]);

  const handleSelectProfile = useCallback(
    (
      profileSelected: string,
      userType: ProfileTypes,
      userTypeLabel: string
    ) => {
      if (onSelect) {
        setSelectedProfile(profileSelected);

        onSelect(profileSelected, userType, userTypeLabel);
      }
    },
    [onSelect]
  );

  return (
    <S.Wrapper>
      {profiles?.map(profile => (
        <ProfileType
          key={profile.id}
          label={profile.title}
          isSelected={
            profile.id === (selectedProfile || initialProfileSelected)
          }
          onSelect={() =>
            handleSelectProfile(profile.id, profile.userType, profile.title)
          }
        />
      ))}
    </S.Wrapper>
  );
};

export default ProfileTypeList;
