import styled, { css } from 'styled-components';
import { ThemeColors } from 'types/global';

import { buildMargin } from 'styles/shared/Margin';

import { TextProps } from '.';

type WrapperProps = Omit<TextProps & { textColor?: ThemeColors }, 'color'>;

const modifiers = {
  darkColor: (color: string) => css`
    color: ${color};
  `,

  lineThrough: () => css`
    text-decoration: line-through;
  `
};

export const Wrapper = styled.p<WrapperProps>`
  ${({
    theme,
    textColor,
    size,
    weight,
    align,
    darkColor,
    lineThrough,
    transform,
    overflow,
    textOverflow,
    whiteSpace,
    maxWidth,
    ...props
  }) =>
    css`
      color: ${theme.colors[textColor!]};
      font-size: ${theme.font.sizes[size!]};
      font-weight: ${theme.font.weights[weight!]};
      line-height: ${theme.font.lineHeight(size!)};
      text-align: ${align};
      text-transform: ${transform};
      overflow: ${overflow};
      text-overflow: ${textOverflow};
      white-space: ${whiteSpace};
      max-width: ${maxWidth};

      ${darkColor &&
      theme.mode === 'dark' &&
      modifiers.darkColor(theme.colors[darkColor])}

      ${lineThrough && modifiers.lineThrough()}

      ${buildMargin({ theme, ...props })}
    `}
`;
