import { Flex, ModalChange, ModalStatus, Text, Loading } from 'components';
import { useBank } from 'contexts/Bank';
import { useNavigate } from 'react-router-dom';
import { formatData } from './constants';

import PATHS_ROUTES from 'routes/paths';

import * as S from './styles';

type ModalStep3Props = {
  onClose: () => void;
};

export function ModalStep3({ onClose }: ModalStep3Props) {
  const { withdrawData, handleSetCashOut, cashOutData } = useBank();
  const navigate = useNavigate();
  const data = formatData(withdrawData);

  const handleCloseSuccessModal = () => {
    navigate(PATHS_ROUTES.APP.STATEMENT.LIST);
  };

  const handleCloseErrorModal = () => {
    navigate(PATHS_ROUTES.APP.DASHBOARD.DEFAULT);
  };

  if (cashOutData.success)
    return (
      <ModalStatus
        variant="success"
        title="Retirar"
        onClose={handleCloseSuccessModal}
        action={{ onClick: handleCloseSuccessModal }}
      >
        <Text color="grayNeutralDark">Retirada realizada com sucesso</Text>
      </ModalStatus>
    );

  if (cashOutData.error)
    return (
      <ModalStatus
        variant="error"
        title="Retirar"
        onClose={handleCloseErrorModal}
        action={{ onClick: handleCloseErrorModal }}
      >
        <Text>Erro ao retirar saldo</Text>
      </ModalStatus>
    );

  return (
    <>
      <ModalChange
        buttonAction={() =>
          handleSetCashOut(
            withdrawData.bankData!.id,
            Number(withdrawData.value)
          )
        }
        onClose={onClose}
        title="Retirar"
        buttonText="Confirmar"
        warning="Essa operação pode levar até 24h para ser realizada"
        subtitle="Confirme os dados da retirada"
      >
        {data.map(({ field1, field2, id }) => (
          <Flex key={id} alignItems="baseline" justifyContent="space-between">
            <LabelText label={field1.label} text={field1.text} />
            <LabelText label={field2.label} text={field2.text} />
          </Flex>
        ))}
        {cashOutData.loading && <Loading fullPage />}
      </ModalChange>
    </>
  );
}

type LabelTextProps = {
  label: string;
  text: string;
};

const LabelText = ({ label, text }: LabelTextProps) => {
  return (
    <S.LabelTextWrapper>
      <Text size="xsmall" weight="medium">
        {label}
      </Text>
      <Text>{text}</Text>
    </S.LabelTextWrapper>
  );
};
