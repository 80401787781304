import { TutorialRequestKey } from '../components';
import * as S from '../../styles';
function Precode() {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Precode
      </S.Title>

      <TutorialRequestKey name="Precode" showConfigurationMessage={false} />

      <S.Paragraph tag="p" color="grayBlue">
        Recebeu sua chave? Ótimo! Com a sua chave de integração entre em contato
        com seu suporte comercial na plataforma ou enviei um email para
        <strong> contato@precode.com.br</strong> com cópia para
        <strong> parceria.digital@amedigital.com</strong>. Informar no email que
        quer configurar a integração com Ame Digital, CNPJ, nome fantasia e
        site. Você receberá toda a orientação para a configuração da integração.
      </S.Paragraph>
    </div>
  );
}

export default Precode;
