import * as S from './styles';
import Text from 'components/Text';
import Flex from 'components/Flex';
import { useRef, useState, useCallback, ChangeEvent } from 'react';
import { ReactComponent as IconInfo } from 'assets/images/icon-info-light.svg';

export type InputFileStatus = 'processing' | 'approved' | 'refused';

const statusText = {
  processing: 'Em validação',
  approved: 'Validado',
  refused: 'Recusado'
};

export type InputFileProps = {
  name: string;
  label: string;
  multiple?: boolean;
  handlerText?: string;
  alertMessage?: string;
  errorMessage?: string;
  status?: InputFileStatus;
  onFilesChange: (files: File | FileList) => void;
};

const InputFile = ({
  name,
  label,
  status,
  alertMessage,
  errorMessage,
  onFilesChange,
  multiple = false,
  handlerText = 'Buscar'
}: InputFileProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [filename, setFilename] = useState<string>('');

  const handleOpenFileManager = useCallback(() => {
    inputFileRef.current?.click();
  }, [inputFileRef]);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let filename;
      const { value, files } = event.target;

      if (files) {
        if (files.length > 1) {
          filename = `${files?.length} arquivos selecionados`;
        } else {
          filename = value.split('\\').pop() ?? '';
        }

        setFilename(filename);
        onFilesChange(multiple ? files : files[0]);
      }
    },
    [multiple, onFilesChange]
  );

  return (
    <>
      <S.Input
        type="file"
        name={name}
        ref={inputFileRef}
        multiple={multiple}
        accept="image/*,.pdf"
        onChange={handleFileChange}
      />

      <Flex alignItems="center">
        <S.Label>{label}</S.Label>
        {!!status && <S.Status status={status}>{statusText[status]}</S.Status>}
      </Flex>

      <S.Wrapper>
        <S.FileName>{filename}</S.FileName>

        <button type="button" onClick={handleOpenFileManager}>
          <Text color="blue" weight="bold" size="small">
            {handlerText}
          </Text>
        </button>
      </S.Wrapper>

      {!!errorMessage && (
        <Text color="red" size="xsmall">
          {errorMessage}
        </Text>
      )}

      {!!alertMessage && (
        <S.AlertMessageWrapper>
          <IconInfo />

          <Text size="xsmall" color="grayNeutralDark">
            {alertMessage}
          </Text>
        </S.AlertMessageWrapper>
      )}
    </>
  );
};

export default InputFile;
