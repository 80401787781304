import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CardAccordionPix, Text } from 'components';
import useRequest from 'hooks/useRequest';
import { IDictKeyData } from 'services/pixService/types';
import Divider from 'styles/shared/Divider';
import { PixContext } from '../..';
import PATHS_ROUTES from 'routes/paths';
import queryString from 'query-string';
import endpoints from 'services/endpoints';

const SelectPixKey = ({
  handlePixKey
}: {
  handlePixKey: (arg0: IDictKeyData) => void;
}) => {
  const pixContext = useContext(PixContext);
  const { handlePageNavigation } = pixContext;
  const navigate = useNavigate();

  const pixKeysList = useRequest<IDictKeyData[]>({
    url: queryString.stringifyUrl({
      url: endpoints.listKeys,
      query: {
        limit: 20,
        offset: 0
      }
    })
  });

  const handleSelectPixKey = (data: IDictKeyData) => {
    handlePixKey(data);
    handlePageNavigation(1);
  };

  const handleRegisterKeyRedirectButton = useCallback(() => {
    navigate(PATHS_ROUTES.APP.PIX.KEYS.REGISTER);
  }, [navigate]);

  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        Qual chave você quer usar?
      </Text>

      <Divider size="small" />

      <Text size="small">
        Para receber um pagamento por QR Code, escolha uma chave Pix ou cadastre
        uma nova
      </Text>

      <Divider size="small" />

      {pixKeysList.data?.map(key => (
        <CardAccordionPix
          key={key.key}
          date={key.createdAt}
          keyType={key.keyType}
          keyValue={key.key}
          onClick={() => handleSelectPixKey(key)}
          data-testid="card-button-select-pix-key"
        />
      ))}

      <Divider size="medium" />

      <Button center filled onClick={handleRegisterKeyRedirectButton}>
        Cadastrar nova chave
      </Button>
    </>
  );
};

export default SelectPixKey;
