import * as Yup from 'yup';
import { SelectOption } from 'components/Select';

export const fields = {
  bank: 'bank',
  agency: 'agency',
  account: 'account',
  type: 'type'
} as const;

export interface BankAccountValues {
  [fields.bank]: number;
  [fields.agency]: string;
  [fields.account]: string;
  [fields.type]: 'CORRENTE' | 'POUPANCA';
}

export interface BankAccountStatus {
  banks: SelectOption[];
  closeConfirmDialog: () => void;
}

export const initialValues: BankAccountValues = {
  [fields.bank]: 0,
  [fields.agency]: '',
  [fields.account]: '',
  [fields.type]: 'CORRENTE'
};

export const schema = Yup.object().shape({
  [fields.bank]: Yup.number().moreThan(0, 'Instituição obrigatória'),
  [fields.agency]: Yup.string().required('Agência obrigatória'),
  [fields.account]: Yup.string().required('Conta obrigatória'),
  [fields.type]: Yup.string().required('Tipo de conta obrigatório')
});
