import PATHS_ROUTES from 'routes/paths';
import {
  Text,
  Link,
  Button,
  CardAccount,
  CardTeamMember,
  TemplateSearchContent
} from 'components';
import { Column, Row } from 'styles/shared/Grid';
import { BreadcrumbLink } from 'components/Breadcrumb';
import { TemplateSearchContentAction } from 'components/Templates/SearchContent';
import { ReactComponent as IconAdd } from 'assets/images/icon-add-outline.svg';
import { ReactComponent as IconEdit } from 'assets/images/icon-edit-outline.svg';
import teams from '../components/__mocks__/teams';

const links: BreadcrumbLink[] = [
  { title: 'Equipe', link: PATHS_ROUTES.APP.TEAM.DEFAULT },
  { title: 'Times', link: PATHS_ROUTES.APP.TEAM.TEAMS },
  { title: '# Caixa 1', link: PATHS_ROUTES.APP.TEAM.TEAMS }
];

const actions: TemplateSearchContentAction[] = [
  {
    text: 'Adicionar',
    icon: <IconAdd />,
    to: PATHS_ROUTES.APP.TEAM.ADD.MEMBER
  },
  {
    text: 'Editar',
    icon: <IconEdit />,
    to: PATHS_ROUTES.APP.TEAM.MEMBER()
  }
];

const TeamMembers = () => (
  <TemplateSearchContent actions={actions} breadcrumbProps={{ links }}>
    <Row>
      <Column md={12}>
        <Text tag="h5" size="xmedium" weight="medium">
          Liderança
        </Text>
        <Text tag="span" size="small" color="grayDark" weight="regular">
          Essa é a lider direta deste time
        </Text>
      </Column>
    </Row>

    <Row>
      <Column md={12}>
        <CardAccount
          title="Esse é um título teste"
          subtitle="Administrador(a)"
          profileType="USER"
          subTitleColor="blueCategoryColorSuccess"
        />
      </Column>
    </Row>

    <Row>
      <Column md={12}>
        <Text tag="h5" size="xmedium" weight="medium">
          Membros
        </Text>
        <Text tag="span" size="small" color="grayDark" weight="regular">
          Esses são todos os membros desta equipe
        </Text>
      </Column>
    </Row>

    <Row>
      <Column md={12}>
        {teams[0]?.members.map(({ id, ...rest }) => (
          <CardTeamMember key={id} {...rest}>
            <Button
              as={Link}
              fullWidth
              tertiary
              to={PATHS_ROUTES.APP.TEAM.MEMBER(String(id))}
            >
              Ver mais
            </Button>
          </CardTeamMember>
        ))}
      </Column>
    </Row>
  </TemplateSearchContent>
);

export default TeamMembers;
