import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.colors.yellowDark};
    padding: ${theme.spacings.medium};
    border-radius: 0.8rem;

    svg {
      margin-right: ${theme.spacings.small};
    }
  `}
`;
