import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  Text,
  Link,
  CardAccount,
  IconArrow,
  TemplateSearchContent,
  CardLink,
  Flex,
  Loading
} from 'components';
import { cnpjMask } from 'utils/masks';
import PATHS_ROUTES from 'routes/paths';
import { Divider } from 'styles/shared/Divider';
import { Column, Row } from 'styles/shared/Grid';
import { TemplateSearchContentAction } from 'components/Templates/SearchContent';
import { ReactComponent as IconEdit } from 'assets/images/icon-edit-outline.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/icon-big-arrow-right.svg';

import useRequest from 'hooks/useRequest';
import endpoints from 'services/endpoints';
import { GetGroupInfoResponse } from 'services/groupService';

const actions: TemplateSearchContentAction[] = [
  {
    text: 'Editar',
    icon: <IconEdit />,
    to: PATHS_ROUTES.APP.TEAM.ADD.MEMBER
  }
];

type RouteParams = {
  id: string;
};

function GroupDependents() {
  const { id } = useParams<RouteParams>();

  const { error, loading, data } = useRequest<GetGroupInfoResponse>({
    url: id ? endpoints.groupInfo.replace(':id', id) : null
  });

  const links = useMemo(
    () => [
      { title: 'Minhas contas', link: PATHS_ROUTES.APP.TEAM.DEFAULT },
      { title: 'Grupos', link: PATHS_ROUTES.APP.ACCOUNTS.GROUP },
      {
        title: data?.groupName || '',
        link: PATHS_ROUTES.APP.ACCOUNTS.GROUP_EDIT(id)
      }
    ],
    [data, id]
  );

  if (error) {
    return <div>Erro</div>;
  }

  if (loading || !data || !data.admin) {
    return <Loading />;
  }

  const { admin, dependents } = data;

  return (
    <TemplateSearchContent actions={actions} breadcrumbProps={{ links }}>
      <Row>
        <Column md={12}>
          <Text tag="h5" size="xmedium" weight="medium">
            Liderança
          </Text>

          <Text tag="span" size="small" color="grayDark" weight="regular">
            Essa é a líder direta deste grupo
          </Text>
        </Column>
      </Row>

      <Divider size="small" />

      <Row>
        <Column md={12}>
          <CardAccount
            title={admin.name}
            subtitle="Administrador(a)"
            profileType={admin.userType}
            subTitleColor="blueCategoryColorSuccess"
          >
            <Link to={PATHS_ROUTES.APP.ACCOUNTS.DEFAULT}>
              <Flex alignItems="center">
                <Text tag="span" size="small" color="blue">
                  Alterar
                </Text>

                <Divider size="small" direction="horizontal" />

                <IconArrow direction="right" />
              </Flex>
            </Link>
          </CardAccount>
        </Column>
      </Row>

      <Divider size="small" />

      <Row>
        <Column md={12}>
          <Text tag="h5" size="xmedium" weight="medium">
            Contas
          </Text>

          <Text tag="span" size="small" color="grayDark" weight="regular">
            Essas são as demais contas pertencentes a esse grupo
          </Text>
        </Column>
      </Row>

      <Divider size="small" />

      <Row>
        {dependents.map(dependent => (
          <Column key={dependent.id} md={4}>
            <CardLink
              title={dependent.name}
              subtitle={cnpjMask(dependent.document)}
              to={PATHS_ROUTES.APP.ACCOUNTS.DEFAULT}
            >
              <ArrowIcon />
            </CardLink>
          </Column>
        ))}
      </Row>
    </TemplateSearchContent>
  );
}

export default GroupDependents;
