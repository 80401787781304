import { ReactNode } from 'react';

import { TemplateModal, PaginationStepInLine } from 'components';

import * as S from './styles';

export type StepArrowProps = {
  title: string;
  itemsLength: number;
  onClose?: () => void;
  children: ReactNode;
  changeStep: (step: number) => void;
};

const StepArrow = ({
  title,
  onClose,
  itemsLength,
  children,
  changeStep
}: StepArrowProps) => {
  return (
    <TemplateModal title={title} closeModal={onClose}>
      <PaginationStepInLine itemsLength={itemsLength} changeStep={changeStep}>
        <S.Container>{children}</S.Container>
      </PaginationStepInLine>
    </TemplateModal>
  );
};

export default StepArrow;
