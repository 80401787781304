import { useMemo } from 'react';

import { ReactComponent as IconReceive } from 'assets/images/pix/icon-my-limits-receive.svg';
import { ReactComponent as IconSend } from 'assets/images/pix/icon-my-limits-send.svg';
import { ReactComponent as Spinner } from 'assets/images/pix/spinner.svg';

import { Flex, Text } from 'components';
import { formatCurrency } from 'utils/masks';

import * as S from './styles';

type Type = 'send' | 'receive';

export type CardLimitProps = {
  value: number | string;
  description: string;
  type: Type;
  loading?: boolean;
};

const CardLimit = ({ value, description, type, loading }: CardLimitProps) => {
  const parsedValue = useMemo(
    () => (typeof value === 'string' ? value : formatCurrency(value)),
    [value]
  );

  return (
    <S.Wrapper>
      {type == 'send' ? <IconSend /> : <IconReceive />}

      <Flex direction="column" ml="small">
        <Text size="medium" weight="bold">
          {parsedValue}
        </Text>

        <Text size="xsmall" color="grayNeutralDark" mt="xsmall">
          {description}
        </Text>
      </Flex>

      {loading && <Spinner />}
    </S.Wrapper>
  );
};

export default CardLimit;
