import styled, { css, DefaultTheme } from 'styled-components';
import { ReactComponent as IconRightNext } from 'assets/images/icon-right-next.svg';

import { DividerText } from 'components';

interface AccordionProps {
  showContent: boolean;
}

interface ContentProps {
  showContent: boolean;
  contentHeight: number;
}

const contentModifier = {
  showContent: (theme: DefaultTheme, contentHeight: number) => css`
    height: ${`${contentHeight}px`};
    margin-bottom: ${theme.spacings.medium};
  `
};

export const Wrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;

export const Content = styled.div<ContentProps>`
  ${({ theme, showContent, contentHeight }) => css`
    height: 0px;
    overflow: hidden;
    margin-top: ${theme.spacings.small};
    color: ${theme.colors.grayNeutralDark};

    ${showContent && contentModifier.showContent(theme, contentHeight)};

    transition: height ${theme.transition.default};
  `}
`;

export const Title = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding-left: ${theme.spacings.small};
  `}
`;

export const Arrow = styled(IconRightNext)<AccordionProps>`
  ${({ theme, showContent }) => css`
    color: ${theme.colors.grayNeutralBrandDarkest};

    transform: ${showContent
      ? 'rotate(-90deg) scale(0.7) translate(-1rem, 0)'
      : 'rotate(90deg) scale(0.7) translate(-1rem, 0)'};

    transition: all ${theme.transition.default};
  `}
`;

export const Divider = styled(DividerText)`
  ${({ theme }) => css`
    width: 80%;
    transition: all ${theme.transition.slow};
  `}
`;
