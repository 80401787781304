import Image1 from '../assets/magento-1-9-1.png';
import Image2 from '../assets/magento-1-9-2.png';
import Image3 from '../assets/magento-1-9-3.png';
import * as S from '../../styles';

const Magento19 = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Magento 1.9
      </S.Title>

      <S.Title tag="h2" weight="medium" size="xlarge">
        Antes de iniciar o passo a passo, garanta que tenha realizado as
        seguintes ações:
      </S.Title>

      <S.List>
        <li>Envio de IP de saída do website à Ame, para liberação.</li>
      </S.List>

      <S.Paragraph tag="p" color="grayBlue">
        Para configurar a Ame como forma de pagamento em seu e-commerce, você
        precisa gerar dois códigos no <strong>portal Minha Conta</strong>, na
        página de
        <strong> Integrações</strong> ou no
        <strong> botão acima “Gerar códigos”</strong>, e inserir nas
        configurações da Magento versão 1.9. Veja abaixo um passo a passo de
        como fazer a integração:
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Na sua conta Magento 1.9, no menu superior, acesse
        <strong>{' System > Configuration'}</strong> (última opção)
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image1}
          alt="Menu superior com as opções System > Configuration"
        />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Na página de configurações, no menu lateral, desça a tela até encontrar
        a seção <strong>Ame Digital</strong> e clique na opção
        <strong> Configurations</strong>
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image2}
          alt="Seção Ame Digital do menu lateral com opção Configurations"
        />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Na tela apresentada, escolha o <strong>Environment de produção</strong>,
        preencha os campos <strong>Application Key</strong> (API user) e
        <strong> Application Token</strong> (API password) com os códigos que
        você gerou na <strong>página de Integrações da Ame Digital </strong>
        (botão
        <strong> Gerar códigos</strong> no topo da página). Em seguida, clique
        em <strong>Save Config</strong>
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image3}
          alt="Seção de configuração com os campos Environment, Api User e Api Password"
        />
      </S.ImgWrapper>
    </div>
  );
};

export default Magento19;
