import { useEffect, useState } from 'react';

import {
  Breadcrumb,
  CardLink,
  TemplateShortContent,
  Flex,
  Text
} from 'components';

import Margin from 'styles/shared/Margin';
import theme from 'styles/theme';

import { ReactComponent as IconPix } from 'assets/images/pix/icon-pix.svg';
import { ReactComponent as SvgIconRightNext } from 'assets/images/icon-right-next.svg';

import { usePixOutFlux } from 'contexts/Pix/PixOutFlux';

import ModalFirstAccess from './Modals/FirstAccess';

import { breadcrumbItems, transferOptions } from './constants';

const Transfer = () => {
  const { clearData } = usePixOutFlux();
  const [openModalAccess, setOpenModalAccess] = useState(true);

  useEffect(() => {
    clearData();
  }, [clearData]);

  return (
    <>
      {openModalAccess && (
        <ModalFirstAccess handleClose={() => setOpenModalAccess(false)} />
      )}

      <Breadcrumb links={breadcrumbItems} />

      <TemplateShortContent>
        <Margin my="large">
          <Flex alignItems="center">
            <IconPix />

            <Text ml="small" tag="h1" size="large" weight="medium">
              Transferir
            </Text>
          </Flex>

          <Text tag="h2" weight="bold" size="xmedium" mt="large" mb="medium">
            Qual chave Pix você quer usar?
          </Text>

          {transferOptions.map(({ to, title, cardSubtitle, icon: SvgIcon }) => (
            <CardLink
              key={title}
              subtitle={cardSubtitle}
              {...{ to, title }}
              icon={
                <SvgIcon width="30" height="30" color={theme.colors.blue} />
              }
            >
              <SvgIconRightNext color={theme.colors.blue} />
            </CardLink>
          ))}
        </Margin>
      </TemplateShortContent>
    </>
  );
};

export default Transfer;
