import * as types from './types';

type PaginationProps = {
  isTransactional: boolean;
  pageStep: number;
};

export const getPaginationItems = ({
  isTransactional,
  pageStep
}: PaginationProps): types.PaginationItem[] => {
  if (isTransactional && pageStep > 0) {
    return [
      {
        key: types.PAGINATION_TYPE.CREDENTIAL_TYPE,
        label: `Tipo de credencial \n Transacional`,
        step: 0
      },
      {
        key: types.PAGINATION_TYPE.PLATAFORM,
        label: 'Plataforma de integração',
        step: 1
      },
      {
        key: types.PAGINATION_TYPE.CREDENTIAL_GENERATED,
        label: 'Gerar credenciais',
        step: 2
      }
    ];
  }

  return [
    {
      key: types.PAGINATION_TYPE.CREDENTIAL_TYPE,
      label: `Tipo de credencial ${pageStep > 0 ? '\n Conciliação' : ''}`,
      step: 0
    },
    {
      key: types.PAGINATION_TYPE.CREDENTIAL_GENERATED,
      label: 'Gerar credenciais',
      step: 1
    }
  ];
};
