import { CustomArrowProps } from 'react-slick';

import ArrowIcon from 'components/Icons/Arrow';

export const ArrowRight = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => {
  return slideCount && currentSlide === slideCount - 1 ? null : (
    <div {...props} className="slick-next slick-arrow">
      <ArrowIcon color="white" direction="right" />
    </div>
  );
};

export default ArrowRight;
