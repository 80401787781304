import styled, { css } from 'styled-components';

type RangeSliderProps = {
  widthBar: string;
};
export const Wrapper = styled.div`
  width: 100%;
  max-width: 33rem;
`;

export const WrapperRange = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const InputRange = styled.input<RangeSliderProps>`
  ${({ widthBar, theme }) => css`
    width: 100%;
    -webkit-appearance: none;
    background-color: ${theme.colors.grayExtraMedium};
    border-radius: 1rem;
    position: relative;

    ::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      height: 0.5rem;
    }

    ::-moz-range-track {
      -webkit-appearance: none;
      height: 0.5rem;
    }

    ::-ms-track {
      -webkit-appearance: none;
      height: 0.5rem;
    }

    ::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 2.6rem;
      height: 2.6rem;
      background: ${theme.colors.white};
      margin-top: -1.1rem;
      border-radius: 50%;
      border: 0.2rem solid ${theme.colors.blue};
    }

    ::-moz-range-thumb {
      -webkit-appearance: none;
      width: 2.6rem;
      height: 2.6rem;
      background: ${theme.colors.white};
      margin-top: -1.1rem;
      border-radius: 50%;
      border: 0.2rem solid ${theme.colors.blue};
    }

    ::-ms-thumb {
      -webkit-appearance: none;
      width: 2.6rem;
      height: 2.6rem;
      background: ${theme.colors.white};
      margin-top: -1.1rem;
      border-radius: 50%;
      border: 0.2rem solid ${theme.colors.blue};
    }

    ::before {
      content: '';
      display: block;
      width: ${widthBar};
      height: 0.5rem;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${theme.colors.blue};
    }
  `}
`;
