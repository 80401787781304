import styled, { css } from 'styled-components';

export const Content = styled.main`
  ${({ theme }) => css`
    min-height: 100vh;
    padding-bottom: 5rem;
    padding-top: 12.5rem;
    background: ${theme.colors.grayLight};
  `}
`;
