import { useMemo } from 'react';
import * as S from './styles';
import { Row, Column } from 'styles/shared/Grid';

import { ReactComponent as IconFilter } from 'assets/images/icon-filter-outline.svg';

import { Button, Flex } from 'components';
import useToggle from 'hooks/useToggle';
import FilterModal from './Modal';
import { Filters, getQtdFiltersSelected } from 'utils/newStatementHelper';

type FilterProps = {
  initialValues: Filters;
  onApplyFilters: (filters: Filters) => void;
};

const Filter = ({ initialValues, onApplyFilters }: FilterProps) => {
  const filterModal = useToggle();

  const qtdFiltersSelected = useMemo(
    () => getQtdFiltersSelected(initialValues),
    [initialValues]
  );

  return (
    <S.Wrapper>
      <Row>
        <Column offsetXs={8} xs={4} offsetSm={9} sm={3} offsetMd={10} md={2}>
          <Button
            fullWidth
            tertiary={qtdFiltersSelected === 0}
            icon={<IconFilter />}
            filled={qtdFiltersSelected > 0}
            onClick={filterModal.toggle}
          >
            <Flex alignItems="center">
              Filtrar
              {qtdFiltersSelected > 0 && (
                <S.Badge>{qtdFiltersSelected}</S.Badge>
              )}
            </Flex>
          </Button>
        </Column>
      </Row>

      {filterModal.isActive && (
        <FilterModal
          filters={initialValues}
          onCloseModal={filterModal.toggle}
          onApplyFilters={filtersSelected => {
            onApplyFilters(filtersSelected);
            filterModal.toggle();
          }}
        />
      )}
    </S.Wrapper>
  );
};

export default Filter;
