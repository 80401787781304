import { Text } from 'components';
import styled, { css } from 'styled-components';

import Card from 'styles/shared/Card';
export * from 'pages/Accreditation/shared/styles';

export const CardWrapper = styled(Card)`
  cursor: pointer;
`;

export const CardTitle = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.font.lineHeight('medium')};
  `}
`;

export const CardSubtitle = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.grayNeutralDark};
  `}
`;

export const AntecipationText = styled(Text)`
  width: 90%;
  max-width: 470px;
`;
