import styled, { css } from 'styled-components';
import { Wrapper as InputSearch } from 'components/InputSearch/styles';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ${InputSearch} {
      margin: ${theme.spacings.medium} 0;
    }

    > button {
      margin-top: 10rem;
    }
  `}
`;

export const List = styled.div``;
