import styled, { css } from 'styled-components';

import { buildMargin } from 'styles/shared/Margin';

import { DividerTextProps } from '.';

type WrapperProps = Omit<DividerTextProps, 'children'>;

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, size, ...props }) => css`
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    margin: ${theme.spacings[size!]} 0;
    color: ${theme.colors.grayDark};

    &:before,
    &:after {
      content: '';
      flex: 1;
      border-bottom: 0.1rem solid ${theme.colors.grayExtraMedium};
    }

    &:not(:empty):before {
      margin-right: ${theme.spacings.small};
    }

    &:not(:empty):after {
      margin-left: ${theme.spacings.small};
    }

    ${buildMargin({ theme, ...props })}
  `}
`;
