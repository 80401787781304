import { Button, TemplateModal } from 'components';

import { ReactComponent as Spinner } from 'assets/images/pix/spinner.svg';
import CardMember, { CardMemberProps } from 'components/Cards/Member';
import { Divider } from 'styles/shared/Divider';

type ModalAddMemberProps = {
  onConfirm: () => void;
  onCloseModal: () => void;
  loading?: boolean;
} & CardMemberProps;

const ModalAddMember = ({
  onConfirm,
  onCloseModal,
  masterAccount,
  member,
  loading = false
}: ModalAddMemberProps) => {
  return (
    <TemplateModal title="Adicionar membro" closeModal={onCloseModal}>
      <CardMember masterAccount={masterAccount} member={member} />

      <Divider size="large" />

      <Button filled large center onClick={onConfirm}>
        {loading ? <Spinner /> : 'Concluir'}
      </Button>
    </TemplateModal>
  );
};

export default ModalAddMember;
