const INPUT_WIDTHS = {
  '3': 120,
  '4': 140,
  '5': 155,
  '6': 185,
  '7': 205,
  '8': 225,
  '9': 255,
  '10': 275,
  '11': 290,
  '12': 315,
  '13': 340
};

type NumberCaracteres = keyof typeof INPUT_WIDTHS;

export function calcInputWidth(numberCaracteres: number): number {
  let widthType = '3';

  if (numberCaracteres >= 3 && numberCaracteres <= 12) {
    widthType = String(numberCaracteres);
  } else if (numberCaracteres > 12) {
    widthType = '13';
  }

  return INPUT_WIDTHS[widthType as NumberCaracteres];
}
