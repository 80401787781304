import { ReactNode } from 'react';

import * as S from './styles';
import { Column, Container, Row } from 'styles/shared/Grid';

import { Button, Link, Header } from 'components';

import PATHS_ROUTES from 'routes/paths';

export type AccreditationLayoutProps = {
  children: ReactNode;
};

const AccreditationLayout = ({ children }: AccreditationLayoutProps) => {
  return (
    <div>
      <Header>
        <Button as={Link} secondary to={PATHS_ROUTES.AUTH.LOGIN}>
          Entrar
        </Button>
      </Header>

      <S.Content>
        <Container>
          <Row>
            <Column xs={12}>{children}</Column>
          </Row>
        </Container>
      </S.Content>
    </div>
  );
};

export default AccreditationLayout;
