import {
  Button,
  Flex,
  CardHalfItemsBackground,
  Text,
  CardInfo
} from 'components';
import Card from 'styles/shared/Card';

import { ReactComponent as IconQrCodeBlue } from 'assets/images/sell/icon-qrcode-blue.svg';
import SellImage from 'assets/images/sell/sell.png';
import QrCodeArrowImage from 'assets/images/sell/qrcode-arrow.png';
import TicketImage from 'assets/images/sell/ticket.png';

import { Column, Row } from 'styles/shared/Grid';
import Divider from 'styles/shared/Divider';
import * as S from './styles';
import { cardInfoDataOne, cardInfoDataTwo } from './constants';
import { useState } from 'react';
import SellFlux from './SellFlux';
import MaterialsFlux from './MaterialsFlux';
import { useQuery } from 'hooks/useQuery';

interface Props {
  createQrCode?: boolean;
}

function WrapperSell() {
  const query = useQuery();

  return <Sell createQrCode={query.has('criarQrCode')} />;
}

export function Sell({ createQrCode }: Props) {
  const [showSellFlux, setShowSellFlux] = useState(createQrCode);
  const [showMaterialsFlux, setShowMaterialsFlux] = useState(false);

  return (
    <>
      {!showSellFlux && !showMaterialsFlux && (
        <>
          <Row>
            <Column xs={12} sm={12} lg={12}>
              <Card>
                <S.CardContainer
                  justifyContent="space-between"
                  wrap="nowrap"
                  alignItems="center"
                >
                  <IconQrCodeBlue />
                  <Flex direction="column" alignItems="center" my="medium">
                    <Text tag="h2" weight="bold" size="large">
                      Comece a vender com Ame ;)
                    </Text>
                    <S.SectionSubtitleTitle
                      tag="p"
                      color="grayNeutralDark"
                      size="medium"
                      align="center"
                    >
                      Crie seu QR Code com ou sem valor, envie, imprima ou
                      compartilhe com seus clientes.
                    </S.SectionSubtitleTitle>
                  </Flex>
                  <Button filled onClick={() => setShowSellFlux(true)}>
                    Criar QR Code
                  </Button>
                </S.CardContainer>
              </Card>
            </Column>
          </Row>
          <Row>
            <Column xs={12} sm={12} lg={12}>
              <Text tag="h3" weight="bold" size="large">
                Como imprimir e enviar o QR Code
              </Text>

              <Divider size="medium" />

              <Row>
                <Column xs={12} sm={12} lg={6}>
                  <Text tag="p" weight="bold" size="xsmall">
                    Enviando o QR Code
                  </Text>
                  <CardHalfItemsBackground
                    left={
                      <img src={QrCodeArrowImage} alt="Imagem de qr code" />
                    }
                    right={
                      <ul>
                        {cardInfoDataOne.map(card => (
                          <li key={card}>
                            <Text color="white">{card}</Text>
                          </li>
                        ))}
                      </ul>
                    }
                    filledBgColor="blue"
                  />
                </Column>
                <Column xs={12} sm={12} lg={6}>
                  <Text tag="p" weight="bold" size="xsmall">
                    Imprimindo o QR Code
                  </Text>
                  <CardHalfItemsBackground
                    left={<img src={TicketImage} alt="Imagem de qr code" />}
                    right={
                      <ul>
                        {cardInfoDataTwo.map(card => (
                          <li key={card}>
                            <Text color="white">{card}</Text>
                          </li>
                        ))}
                      </ul>
                    }
                    filledBgColor="purple"
                  />
                </Column>
              </Row>
            </Column>
          </Row>

          <Row>
            <Column xs={12} sm={12} lg={6}>
              <CardInfo
                icon={<img src={SellImage} alt="Imagem Ame" />}
                text="Materiais de exposição"
                arrowIcon
                action={() => setShowMaterialsFlux(true)}
              >
                <Text color="grayNeutralDark" size="xsmall">
                  Mostre que você aceita Ame!
                </Text>
              </CardInfo>
            </Column>
          </Row>
        </>
      )}

      {showSellFlux && <SellFlux closeAction={() => setShowSellFlux(false)} />}
      {showMaterialsFlux && (
        <MaterialsFlux closeAction={() => setShowMaterialsFlux(false)} />
      )}
    </>
  );
}

export default WrapperSell;
