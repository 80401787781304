import { Flex, IconArrow } from 'components';

import * as S from './styles';

export type BreadcrumbLink = {
  title: string;
  link: string;
};

export type BreadcrumbProps = {
  links: BreadcrumbLink[];
};

const Breadcrumb = ({ links }: BreadcrumbProps) => (
  <Flex>
    {links.map(link => (
      <S.Link to={link.link} key={link.title}>
        {link.title}

        <IconArrow color="currentColor" direction="right" />
      </S.Link>
    ))}
  </Flex>
);

export default Breadcrumb;
