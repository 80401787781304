import Image1 from '../assets/magento-2-0-1.png';
import Image2 from '../assets/magento-2-0-2.png';
import * as S from '../../styles';

function Magento20() {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Magento 2.0
      </S.Title>

      <S.Title tag="h2" weight="medium" size="xlarge">
        Antes de iniciar o passo a passo, garanta que tenha realizado as
        seguintes ações:
      </S.Title>

      <S.List>
        <li>Envio de IP de saída do website à Ame, para liberação.</li>
      </S.List>

      <S.Paragraph tag="p" color="grayBlue">
        Para configurar a Ame como forma de pagamento em seu e-commerce, você
        precisa gerar dois códigos no <strong>portal Minha Conta</strong>, na
        página de
        <strong> Integrações</strong> ou no
        <strong> botão acima “Gerar códigos”</strong>, e inserir nas
        configurações da Magento versão 2.0. Veja abaixo um passo a passo de
        como fazer a integração:
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Na sua conta Magento 2.0, no menu lateral, acesse
        <strong>{' Ame Digital > Configuration'}</strong>
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image1}
          alt="Menu lateral destacando Ame Digital e Configuration"
        />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Na página de configurações, na seção <strong>Geral</strong>, escolha o
        <strong> Ambiente de produção</strong>, preencha os campos
        <strong> Application Key</strong> e <strong>Application Token </strong>
        com os códigos que você gerou na
        <strong> página de Integrações da Ame Digital</strong>
        (botão <strong>Gerar códigos</strong> no topo da página). Em seguida,
        clique em <strong>Save Config</strong>
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image2}
          alt="Seção de configuração com os campos Application Key e Application Token em destaque"
        />
      </S.ImgWrapper>
    </div>
  );
}

export default Magento20;
