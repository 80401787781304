import { useMemo, useState } from 'react';

import moment from 'moment';
import { useReport } from 'contexts/Report';
import { ReportOptions } from 'contexts/Report/types';
import useDateRange, { CalendarDates } from 'hooks/useDateRange';

import {
  Button,
  Flex,
  Text,
  IconArrow,
  CardSectionOptions,
  DateRange
} from 'components';
import Divider from 'styles/shared/Divider';

import { ReportTypes } from '../ReportType';
import * as S from './style';

type ReportOptionsProps = {
  chooseReportType: (type: ReportTypes) => void;
};

const options = [
  {
    value: '1',
    text: (
      <>
        <p>Dia</p>
        <p>anterior</p>
      </>
    )
  },
  {
    value: '7',
    text: (
      <>
        <p>Últimos</p>
        <p>7 dias</p>
      </>
    )
  },
  {
    value: '15',
    text: (
      <>
        <p>Últimos</p>
        <p>15 dias</p>
      </>
    )
  },
  {
    value: '30',
    text: (
      <>
        <p>Últimos</p>
        <p>30 dias</p>
      </>
    )
  },
  {
    value: 'select',
    text: (
      <>
        <p>Filtrar</p>
        <p>datas</p>
      </>
    )
  }
];

export const ReportOptionsScreen = ({
  chooseReportType
}: ReportOptionsProps) => {
  const { reportStateData, handleCreateReport, handleSetReportStateData } =
    useReport();

  const { resetDates, ...dateParams } = useDateRange({
    initialValues: {
      startDate: reportStateData.dateStart || null,
      endDate: reportStateData.dateEnd || null,
      focusedInput: 'startDate'
    },
    rangeDays: 30,
    maxDate: moment().subtract(1, 'd')
  });

  const isValid = useMemo(() => {
    const { dateStart, archiveType, typeFiles } = reportStateData;

    return !(dateStart && archiveType && typeFiles);
  }, [reportStateData]);

  const [toggleCalendar, setToggleCalendar] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('1');

  const handleSelectItem = (value: string) => {
    setToggleCalendar(value === 'select' ? true : false);
    if (value !== 'select') {
      const initialDate = moment().subtract(value, 'd');
      handleSetReportStateData({
        dateStart: initialDate.format('YYYY-MM-DD'),
        dateEnd: moment().subtract(1, 'd').format('YYYY-MM-DD')
      });
    } else {
      resetDates();
      handleSetReportStateData({ dateStart: undefined, dateEnd: undefined });
      setToggleCalendar(true);
    }
    setSelectedPeriod(value);
  };

  const calendarStartDate = useMemo(
    () =>
      reportStateData.dateStart ? moment(reportStateData.dateStart) : undefined,
    [reportStateData.dateStart]
  );

  const calendarEndDate = useMemo(
    () =>
      reportStateData.dateEnd ? moment(reportStateData.dateEnd) : undefined,
    [reportStateData.dateEnd]
  );

  const handleChangeCalendarDates = (dates: CalendarDates) => {
    handleSetReportStateData({
      dateStart: dates.startDate
        ? dates.startDate.format('YYYY-MM-DD')
        : undefined,
      dateEnd: dates.endDate ? dates.endDate.format('YYYY-MM-DD') : undefined
    });
  };

  return (
    <>
      <Text size="xxmedium" color="blackDark">
        Novo relatório
      </Text>
      <Text color="grayNeutralDark">
        Selecione as opções abaixo para configurar o arquivo
      </Text>

      <Divider />

      <SelectWrapper
        data={reportStateData.typeFiles}
        onClick={() => chooseReportType('typeFiles')}
        text="Tipo de relatório"
      />

      <Divider size="large" />
      {/* <SelectWrapper
        data={reportStateData.archiveType!}
        text="Tipo de arquivo"
      /> */}

      <Divider size="large" />

      <CardSectionOptions
        title="Período"
        options={options.map(card => ({
          value: card.value,
          description: card.text
        }))}
        optionSelected={selectedPeriod}
        onOptionSelected={value => handleSelectItem(value)}
      />

      {toggleCalendar && (
        <Flex mt="small" direction="column">
          <Text size="xsmall" weight="bold">
            Data
          </Text>
          <Flex alignItems="center" mt="small">
            <DateRange
              staticParams={dateParams}
              onDatesChange={handleChangeCalendarDates}
              startDate={calendarStartDate}
              endDate={calendarEndDate}
              numberOfMonths={2}
              initialVisibleMonth={() => moment().subtract(1, 'month')}
            />
          </Flex>
        </Flex>
      )}

      <Flex ml="xlarge" mr="xlarge" mt="large">
        <Button
          center
          onClick={handleCreateReport}
          fullWidth
          disabled={isValid}
        >
          Gerar relatório
        </Button>
      </Flex>
    </>
  );
};

type SelectWrapperProps = {
  data?: ReportOptions[] | ReportOptions;
  onClick?: () => void;
  text: string;
};

const SelectWrapper = ({ data, onClick, text }: SelectWrapperProps) => (
  <>
    {data && (
      <Text weight="bold" size="xsmall">
        {text}
      </Text>
    )}

    <S.WrapperSelect onClick={onClick}>
      {data ? (
        <Text>
          {Array.isArray(data)
            ? data.map(item => ` ${item.text}`).join()
            : data.text}
        </Text>
      ) : (
        <Text color="grayNeutralDark">{text}</Text>
      )}
      {!!onClick && <IconArrow direction="right" />}
    </S.WrapperSelect>
  </>
);
