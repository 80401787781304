import styled from 'styled-components';
import customMedia from 'styles/media-query';

import { Flex } from 'components';

export const Wrapper = styled(Flex)`
  ${customMedia.lessThan('medium')`
      margin-left: 0;
      margin-right: 0;
    `}
`;

export const WrapperTransaction = styled(Flex)`
  width: 100%;
  max-width: 33rem;

  margin-left: auto;
  margin-right: auto;
`;
