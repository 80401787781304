import styled from 'styled-components';

import { Flex } from 'components';
import { Container } from 'styles/shared/Grid';

export const MainContainer = styled(Container)`
  max-width: 490px;
`;

export const IconCLose = styled(Flex)`
  margin-bottom: 2rem;

  svg {
    cursor: pointer;
  }
`;
