import { Loading, SelectField, InputField } from 'components';
import { StepProps } from 'contexts/AccreditationSteps/types';
import { useSegments } from '../../../shared/hooks/use-segments';

import * as S from 'pages/Accreditation/shared/styles';

const StepDigital1 = ({ hasSession, names }: StepProps) => {
  const { segments, loading } = useSegments(hasSession);

  return (
    <>
      {loading && <Loading fullContent fullWidth />}

      <S.Title>
        Que bom ter você aqui <S.HighlightText>:)</S.HighlightText>
      </S.Title>

      <S.Subtitle>
        Para começar nossa nova parceria, conta pra gente um pouco sobre sua
        empresa
      </S.Subtitle>

      <InputField
        name={names.cnpj}
        label="CNPJ da sua empresa"
        maskType="CNPJ"
      />

      <InputField name={names.social} label="Sua razão social" />

      <SelectField
        name={names.segment}
        label="Seu tipo de negócio"
        options={segments}
      />

      <S.Action>Ir para dados da loja</S.Action>
    </>
  );
};

export default StepDigital1;
