/* eslint-disable no-underscore-dangle */

import theme from 'styles/theme';

type handlePxResponseProps = {
  data: {
    appId: string;
    firstPartyEnabled: boolean;
    vid: string;
    uuid: string;
    blockScript: string;
    jsClientSrc: string;
    hostUrl: string;
  };
  onSuccess?: () => void;
};

export const getPxChallenge = () => document.querySelector('.ame-px-challenge');

export const showPxChallenge = () =>
  getPxChallenge()?.classList.remove('ame-px-challenge--hidden');

export const hidePxChallenge = () =>
  getPxChallenge()?.classList.add('ame-px-challenge--hidden');

export const handlePxResponse = ({
  data,
  onSuccess
}: handlePxResponseProps) => {
  // Mandatory window properties
  window._pxAppId = `${data.appId}`; // PerimeterX's application id
  window._pxJsClientSrc = `${data.jsClientSrc}`; // PerimeterX's JavaScript sensor url
  window._pxJsClientSrc = `//client.perimeterx.net/${data.appId}/main.min.js`;
  window._pxFirstPartyEnabled = data.firstPartyEnabled; // A boolean flag indicating wether first party is enabled or not
  window._pxVid = `${data.vid}`; // PerimeterX's visitor id
  window._pxUuid = `${data.uuid}`; // PerimeterX's unique user id
  window._pxHostUrl = `${data.hostUrl}`; // PerimeterX's cloud component URL
  window._pxHostUrl = `https://collector-${data.appId}.perimeterx.net`;
  window._pxSelectedLocale = 'pt-PT';
  window._pxreCaptchaTheme = 'light';

  if (onSuccess) {
    window._pxOnCaptchaSuccess = onSuccess;
  }

  const appIdIndex = `_${data.appId}`;

  window[appIdIndex] = {
    challenge: {
      view: {
        width: 250,
        height: 60,
        borderWidth: 4,
        texSize: 16,
        borderColor: theme.colors.blue,
        textColor: theme.colors.blue,
        fillColor: theme.colors.blue
      }
    }
  };

  // new script element which is used to load the PerimeterX challenge script
  const suffixIndex = data.blockScript.indexOf('captcha.js');
  const temperedBlockScript = data.blockScript.substring(suffixIndex);
  const script = document.createElement('script');
  script.src = `//captcha.px-cdn.net/${data.appId}/${temperedBlockScript}`;

  const div = document.createElement('div');

  div.setAttribute('id', 'px-captcha');

  showPxChallenge();

  // adding the elements to the page
  const pxElement = document.querySelector('.px-element');

  if (pxElement) {
    if (pxElement.childElementCount > 0) {
      let pxElementChilds = 0;

      while (pxElementChilds < pxElement.childElementCount) {
        pxElement.removeChild(pxElement.firstChild!);
        // eslint-disable-next-line no-plusplus
        pxElementChilds++;
      }
    }

    pxElement.appendChild(div);
    document.getElementsByTagName('head')[0].appendChild(script);
  }
};
