import { AxiosResponse } from 'axios';
import { DEFAULT_RESPONSE_SUCCESS } from 'utils/constants';

import * as types from '../types';

export const refreshTokenResponseSuccess: AxiosResponse<types.RefreshToken> = {
  ...DEFAULT_RESPONSE_SUCCESS,
  data: {
    access_token: true
  }
};
