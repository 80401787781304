import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

import Column from '../Column';

type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

type AlignItens = 'stretch' | 'flex-start' | 'center' | 'flex-end';

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

type Sizes = '' | 'small' | 'medium' | 'large' | 'xlarge';

export type RowProps = {
  noGutters?: boolean;
  justifyContentXs?: JustifyContent;
  justifyContentSm?: JustifyContent;
  justifyContentMd?: JustifyContent;
  justifyContentLg?: JustifyContent;
  justifyContentXl?: JustifyContent;

  alignItemsXs?: AlignItens;
  alignItemsSm?: AlignItens;
  alignItemsMd?: AlignItens;
  alignItemsLg?: AlignItens;
  alignItemsXl?: AlignItens;

  flexDirectionXs?: FlexDirection;
  flexDirectionSm?: FlexDirection;
  flexDirectionMd?: FlexDirection;
  flexDirectionLg?: FlexDirection;
  flexDirectionXl?: FlexDirection;
};

const rowModifiers = {
  noGutter: () => css`
    margin-right: 0;
    margin-left: 0;

    ${Column} {
      padding-right: 0;
      padding-left: 0;
    }
  `,

  flexProp: (size: Sizes, prop: string, value: string) => css`
    ${!size &&
    css`
      ${prop}: ${value};
    `}

    ${size &&
    customMedia.greaterThan(size)`
      ${prop}: ${value};
    `}
  `
};

export const Row = styled.div<RowProps>`
  ${({
    theme,
    noGutters,
    justifyContentXs,
    justifyContentSm,
    justifyContentMd,
    justifyContentLg,
    justifyContentXl,
    alignItemsXs,
    alignItemsSm,
    alignItemsMd,
    alignItemsLg,
    alignItemsXl,
    flexDirectionXs,
    flexDirectionSm,
    flexDirectionMd,
    flexDirectionLg,
    flexDirectionXl
  }) => css`
    display: flex;
    flex-wrap: wrap;
    margin-left: -${theme.grid.gutter};
    margin-right: -${theme.grid.gutter};

    ${noGutters && rowModifiers.noGutter()}

    ${justifyContentXs &&
    rowModifiers.flexProp('', 'justify-content', justifyContentXs)}

    ${justifyContentSm &&
    rowModifiers.flexProp('small', 'justify-content', justifyContentSm)}

    ${justifyContentMd &&
    rowModifiers.flexProp('medium', 'justify-content', justifyContentMd)}

    ${justifyContentLg &&
    rowModifiers.flexProp('large', 'justify-content', justifyContentLg)}

    ${justifyContentXl &&
    rowModifiers.flexProp('xlarge', 'justify-content', justifyContentXl)}

    ${alignItemsXs && rowModifiers.flexProp('', 'align-items', alignItemsXs)}

    ${alignItemsSm &&
    rowModifiers.flexProp('small', 'align-items', alignItemsSm)}

    ${alignItemsMd &&
    rowModifiers.flexProp('medium', 'align-items', alignItemsMd)}

    ${alignItemsLg &&
    rowModifiers.flexProp('large', 'align-items', alignItemsLg)}

    ${alignItemsXl &&
    rowModifiers.flexProp('xlarge', 'align-items', alignItemsXl)}

    ${flexDirectionXs &&
    rowModifiers.flexProp('', 'flex-direction', flexDirectionXs)}

    ${flexDirectionSm &&
    rowModifiers.flexProp('small', 'flex-direction', flexDirectionSm)}

    ${flexDirectionMd &&
    rowModifiers.flexProp('medium', 'flex-direction', flexDirectionMd)}

    ${flexDirectionLg &&
    rowModifiers.flexProp('large', 'flex-direction', flexDirectionLg)}

    ${flexDirectionXl &&
    rowModifiers.flexProp('xlarge', 'flex-direction', flexDirectionXl)}
  `}
`;

export default Row;
