import { useState } from 'react';

import { TemplateCloseContent } from 'components';

import PATHS_ROUTES from 'routes/paths';

import { ReportOptionsScreen } from './ReportOptions';
import { ReportType, ReportTypes } from './ReportType';

export const ReportStep2 = () => {
  const [step, setStep] = useState(0);
  const [reportType, setReportType] = useState<ReportTypes>('archiveType');

  const handleChooseReportType = (type: ReportTypes) => {
    setReportType(type);
    setStep(1);
  };

  const steps = [
    <ReportOptionsScreen key="0" chooseReportType={handleChooseReportType} />,
    <ReportType key="1" type={reportType} back={() => setStep(0)} />
  ];

  return (
    <TemplateCloseContent
      {...(step === 1 && { backLink: () => setStep(0) })}
      closeLink={PATHS_ROUTES.APP.REPORT.DEFAULT}
      closeTitle="Voltar para Relatórios"
    >
      {steps[step]}
    </TemplateCloseContent>
  );
};
