import axios from 'axios';
import { createBrowserHistory } from 'history';

import { handlePxResponse, hidePxChallenge } from 'utils/perimeterX';

import { refreshToken, endpoints } from './sessionService';

const ignoreRefreshToken = [
  endpoints.loginWithPassword,
  endpoints.loginCheck,
  endpoints.loginValidate,
  endpoints.subAccountLogin,
  endpoints.newPassword
];

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;

    const history = createBrowserHistory();

    if (
      error.response?.status === 403 &&
      !!error.response.data?.appId &&
      !!error.response.data?.blockScript
    ) {
      return new Promise((resolve, reject) => {
        handlePxResponse({
          data: error.response?.data,
          onSuccess: () =>
            axios
              .request(error.config)
              .then(resolve)
              .catch(reject)
              .finally(() => hidePxChallenge())
        });
      });
    }

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !ignoreRefreshToken.includes(error.config.url)
    ) {
      originalRequest._retry = true;

      try {
        const res = await refreshToken();

        if (res.status === 200) {
          return api(originalRequest);
        }

        history.go(0);

        return Promise.reject(error);
      } catch (e) {
        history.go(0);

        return Promise.reject(e);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
