import { Button, CardInfo, Flex, Money, QrCode, Text } from 'components';
import Divider from 'styles/shared/Divider';

import { ReactComponent as IconPhoto } from 'assets/images/icon-photo.svg';
import { ReactComponent as IconSend } from 'assets/images/icon-share.svg';

import { MobileCenter } from '../../styles';
import { useState } from 'react';
import { useScreenSell } from 'contexts/ScreenSell';

type Step3Props = {
  next: () => void;
};

function Step3({ next }: Step3Props) {
  const [copied, setCopied] = useState(false);
  const { qrCodeData, sellerData } = useScreenSell();

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(qrCodeData.url);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
      <Text weight="medium" tag="h2" size="large">
        Seu QR Code Ame
      </Text>

      <Divider size="small" />

      <Flex>
        <MobileCenter direction="column">
          <Text size="xxsmall" align="center">
            {sellerData?.groupName}
          </Text>

          <Divider size="small" />

          <QrCode link={qrCodeData.qrCodeLink} />
        </MobileCenter>

        <MobileCenter direction="column" ml="small" mt="medium">
          {qrCodeData.amount > 0 ? (
            <>
              <Money value={qrCodeData.amount} size="xxlarge" weight="medium" />
              <Text>{qrCodeData.description}</Text>
            </>
          ) : (
            <>
              <Text size="medium" weight="medium" mt="small">
                Sem valor definido
              </Text>
              <Text size="small" mt="medium" color="grayNeutralDark">
                Depois de ler o QR Code com o nosso app da Ame, seu cliente
                deverá preencher o valor da cobrança ;)
              </Text>
            </>
          )}
        </MobileCenter>
      </Flex>

      <Divider size="medium" />

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={qrCodeData.pdfUrl}
        download="qrcode-bruno"
      >
        <CardInfo text="Salvar QR Code" icon={<IconPhoto />} />
      </a>

      <CardInfo
        text="Compartilhar"
        icon={<IconSend />}
        action={copyToClipBoard}
        infoText={copied ? 'Link copiado com sucesso!' : null}
      />

      <Divider size="medium" />

      <Button tertiary center onClick={next}>
        Criar novo QR Code
      </Button>
    </>
  );
}

export default Step3;
