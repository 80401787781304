import { Button, Flex, TemplateModal, Text } from 'components';

import Divider from 'styles/shared/Divider';

export type ModalInfoButtonsProps = {
  title: string;
  description: string;
  onClose: () => void;
  icon: JSX.Element;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
};

const ModalInfoButtons = ({
  icon,
  onClose,
  title,
  description,
  primaryButtonText = 'OK',
  secondaryButtonText = 'Cancelar',
  primaryButtonAction,
  secondaryButtonAction
}: ModalInfoButtonsProps) => (
  <TemplateModal title={title} closeModal={onClose}>
    <Flex justifyContent="center" mb="large" mt="large">
      {icon}
    </Flex>
    <Text color="grayNeutralDark" align="center" size="small">
      {description}
    </Text>

    <Divider size="medium" />

    <Button large center filled onClick={primaryButtonAction}>
      {primaryButtonText}
    </Button>

    <Divider size="small" />

    <Button
      large
      center
      secondary
      secondaryColor="red"
      onClick={secondaryButtonAction}
    >
      {secondaryButtonText}
    </Button>
  </TemplateModal>
);

export default ModalInfoButtons;
