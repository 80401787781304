import styled, { css } from 'styled-components';
import { Flex } from 'components';
import { Card } from 'styles/shared/Card';

export const CardWrapper = styled(Card)`
  ${({ theme }) => css`
    padding: 0 0 ${theme.spacings.small} 0;

    :hover {
      box-shadow: ${theme.shadow.default};
    }
  `}
`;

export const ButtonsWrapper = styled(Flex)`
  ${({ theme }) => css`
    margin-left: ${theme.spacings.small};
    align-content: center;

    height: 100%;
  `}
`;

export const QRCode = styled.img`
  border-radius: 1rem;

  width: inherit;
  height: auto;
`;

export const PopoverWrapper = styled.div`
  width: 100%;
`;
