import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .slick-slider {
      position: relative;

      display: block;
      box-sizing: border-box;

      user-select: none;

      ${customMedia.greaterThan('medium')`
        user-select: auto;
      `}

      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;

      margin-bottom: 7px;
    }

    .slick-list {
      position: relative;

      display: block;
      overflow: hidden;

      margin: 0 -0.5rem;
      padding: 0;
    }

    .slick-slide > div {
      padding: 0 0.5rem;
    }

    .slick-list:focus {
      outline: none;
    }
    .slick-list.dragging {
      cursor: pointer;
      cursor: hand;
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    .slick-track {
      position: relative;
      margin-top: ${theme.spacings.xsmall};
      left: 0;

      display: block;
    }
    .slick-track:before,
    .slick-track:after {
      display: table;

      content: '';
    }
    .slick-track:after {
      clear: both;
    }
    .slick-loading .slick-track {
      visibility: hidden;
    }

    .slick-slide {
      display: none;
      float: left;

      height: 100%;
      min-height: 1px;
    }
    [dir='rtl'] .slick-slide {
      float: right;
    }
    .slick-slide img {
      display: block;
    }
    .slick-slide.slick-loading img {
      display: none;
    }
    .slick-slide.dragging img {
      pointer-events: none;
    }
    .slick-initialized .slick-slide {
      display: block;
    }
    .slick-loading .slick-slide {
      visibility: hidden;
    }
    .slick-vertical .slick-slide {
      display: block;

      height: auto;

      border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
      display: none;
    }

    /* Arrows */
    .slick-prev,
    .slick-next {
      position: absolute;
      top: 50%;
      display: block;
      transform: translate(0, -85%);
      cursor: pointer;
      z-index: ${theme.layers.base};
    }

    .slick-prev {
      left: 15px;
    }

    [dir='rtl'] .slick-prev {
      right: 15px;
      left: auto;
    }

    .slick-next {
      right: 15px;
    }
    [dir='rtl'] .slick-next {
      right: auto;
      left: 15px;
    }

    /* Dots */
    .slick-dotted.slick-slider {
      margin-bottom: 30px;
    }

    .slick-dots {
      position: absolute;
      left: 0;
      bottom: -7px;
      display: block;
      width: 100%;
      text-align: center;

      li {
        display: inline-block;
        margin: 0 3px;

        button {
          display: block;
          width: 7px;
          height: 7px;

          cursor: pointer;

          color: transparent;
          border: 0;
          outline: none;
          background: ${theme.colors.grayMedium};
          transition: all 0.2s ease-in;
        }

        &.slick-active,
        &:hover {
          button {
            background: ${theme.colors.blue};
            width: 15px;
          }
        }
      }
    }
  `}
`;
