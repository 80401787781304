import * as S from '../../styles';
type TutorialGenerateKeysType = {
  name: string;
};

const TutorialGenerateKeys = ({ name }: TutorialGenerateKeysType) => {
  return (
    <>
      <S.Paragraph tag="p">
        Para configurar a Ame como forma de pagamento em seu e-commerce, você
        irá precisar das suas <strong>chaves de integração</strong>.
      </S.Paragraph>
      <S.Paragraph>
        Para ter acesso as suas chaves basta clicar no botão acima{' '}
        <strong>“Gerar códigos”</strong>. Irá abrir uma janela com as chaves que
        irá utilizar na configuração do seu sistema {name}, e é bem simples!
      </S.Paragraph>
    </>
  );
};

export default TutorialGenerateKeys;
