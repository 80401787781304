import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

import { headerHeight } from 'components/Header/styles';

export const Nav = styled.nav`
  ${({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: ${theme.mode === 'light'
      ? theme.colors.white
      : theme.colors.blackDark};
    color: ${theme.colors.white};
    z-index: ${theme.layers.menu};
    transform: translateX(-100vw);
    transition: transform 0.3s;
    border-right: 1px solid
      ${theme.mode === 'light'
        ? theme.colors.grayNeutralLight
        : theme.colors.blackDark};

    & > div {
      height: calc(100% - ${headerHeight}rem);
      overflow: hidden;
      overflow-y: auto;

      ::-webkit-scrollbar-track {
        background-color: ${theme.colors.grayLight};
      }
      ::-webkit-scrollbar {
        width: 2px;
        background: ${theme.colors.grayLight};
      }
      ::-webkit-scrollbar-thumb {
        background: ${theme.colors.grayMedium};
      }
    }

    ${customMedia.greaterThan('large')`
      transform: translateX(0);
      transition: initial;
    `}
  `};
`;

export const NavOverlay = styled.button`
  ${({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: ${theme.layers.menu};
    display: none;
  `}
`;

export const LogoWrapper = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${theme.mode === 'light'
      ? theme.colors.white
      : theme.colors.blackDark};
    padding: ${theme.spacings.medium};
    border-right: 1px solid
      ${theme.mode === 'light' ? theme.colors.white : theme.colors.blackDark};
    border-bottom: 1px solid
      ${theme.mode === 'light'
        ? theme.colors.grayNeutralLight
        : theme.colors.blackDark};

    width: 100%;
    cursor: default;

    & > div {
      display: none;
    }

    svg.logo-plus {
      color: ${theme.mode === 'light' ? '#232b46' : theme.colors.white};
    }

    ${customMedia.greaterThan('large')`
      width: 101%;
      height: ${headerHeight}rem;
      cursor: pointer;

      & > div {
        display: block;
      }
    `}
  `}
`;

export const Logo = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20rem;
`;

export const ShortLogo = styled.div`
  ${({ theme }) => css`
    margin-left: -${theme.spacings.xsmall};
    svg {
      display: none;
    }

    .${theme.mode} {
      display: block;
    }
  `}
`;

export const Menus = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
