import { ReactNode } from 'react';
import { MarginProps } from 'styles/shared/Margin';

import * as S from './styles';

export type FlexJustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'start'
  | 'end'
  | 'left'
  | 'right';

export type FlexAlignItems =
  | 'stretch'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'first baseline'
  | 'last baseline'
  | 'start'
  | 'end'
  | 'self-start'
  | 'self-end';

export type FlexProps = {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  grow?: number;
  shrink?: number;
  basis?: string;
  justifyContent?: FlexJustifyContent;
  alignItems?: FlexAlignItems;
  children?: ReactNode;
} & MarginProps;

const Flex = ({
  direction = 'row',
  wrap = 'wrap',
  grow = 1,
  shrink,
  basis,
  justifyContent,
  alignItems,
  children,
  ...props
}: FlexProps) => (
  <S.Wrapper
    {...{
      direction,
      wrap,
      grow,
      shrink,
      basis,
      justifyContent,
      alignItems,
      ...props
    }}
  >
    {children}
  </S.Wrapper>
);

export default Flex;
