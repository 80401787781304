import { useMemo, useState } from 'react';

import Divider from 'styles/shared/Divider';
import { Breadcrumb, Flex, TemplateShortContent, Text } from 'components';
import CNPJInput from './components/CNPJInput';
import CNPJSubmit from './components/CNPJSubmit';

import { ReactComponent as IconPix } from 'assets/images/pix/icon-pix.svg';
import { breadcrumbItems } from './constants';
import { useAuth } from 'contexts/Auth';
import { usePixRegister } from 'contexts/Pix/PixRegister';
import RegisterKeyModals from '../components/RegisterKeyModals';

const PixRegisterCNPJ = () => {
  const { loading, status, handleCreatePixKey } = usePixRegister();
  const [proceedWithRegistration, setProceedWithRegistration] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const { user } = useAuth();

  const documentInputValue = useMemo(() => {
    return user?.groupDocument || '';
  }, [user]);

  const handleStatusModalOpenState = () => {
    setIsStatusModalOpen(!isStatusModalOpen);
  };

  const handleSetProceedWithRegistration = () => {
    setProceedWithRegistration(!proceedWithRegistration);
  };

  return (
    <>
      <Breadcrumb links={breadcrumbItems} />

      <Divider size="xlarge" />

      <TemplateShortContent>
        <Flex alignItems="center">
          <IconPix />
          <Text ml="small" tag="h1" size="large" weight="medium">
            Minhas Chaves
          </Text>
        </Flex>

        <Divider size="medium" />

        {!proceedWithRegistration ? (
          <CNPJInput
            documentInputValue={documentInputValue}
            handleSetProceedWithRegistration={handleSetProceedWithRegistration}
          />
        ) : (
          <CNPJSubmit
            documentInputValue={documentInputValue}
            handleSetProceedWithRegistration={handleSetProceedWithRegistration}
            handleStatusModalOpenState={handleStatusModalOpenState}
          />
        )}

        {isStatusModalOpen && (
          <RegisterKeyModals
            status={status}
            createdKey={documentInputValue}
            submitKey={() =>
              handleCreatePixKey({
                keyType: 'CORPORATE_TAX_ID',
                key: documentInputValue,
                reason: 'USER_REQUESTED'
              })
            }
            onClose={handleStatusModalOpenState}
            loading={loading}
          />
        )}
      </TemplateShortContent>
    </>
  );
};

export default PixRegisterCNPJ;
