import { useState, useMemo, useCallback } from 'react';

import {
  TemplatePaginationStep,
  TemplateCloseContent,
  ChooseOwnerGroup,
  ModalStatus,
  Text,
  ModalChange
} from 'components';

import useToggle from 'hooks/useToggle';

import Divider from 'styles/shared/Divider';

import { useScreenOwner } from 'contexts/ScreenOwner';

import PATHS_ROUTES from 'routes/paths';

import * as S from './styles';

import { Step1, Step2 } from '../steps';

import { Account } from '../__mocks__/accounts';

export type Data = {
  name: string;
  accounts: Account[];
};

function ContainerPage() {
  const [currentPage, setCurrentPage] = useState(1);

  const modalConfirmation = useToggle();
  const modalSuccess = useToggle();

  const [data, setData] = useState<Data>({
    name: '',
    accounts: []
  });

  const { owner, screenOwnerActive, onChooseOwner } = useScreenOwner();

  const accountsFormatted = useMemo(() => {
    const accountsNames = data.accounts.map(account => account.name);
    return accountsNames.join(' / ');
  }, [data.accounts]);

  const handleChangeInput = useCallback(
    (event: { target: HTMLInputElement }) =>
      setData(prevValues => ({
        ...prevValues,
        [event.target.name]: event.target.value
      })),
    []
  );

  const saveAccountGroup = useCallback(
    (accounts: Account[]) => {
      setData(prevValues => ({ ...prevValues, accounts }));
      modalConfirmation.toggle();
    },
    [modalConfirmation]
  );

  const handleConfirm = useCallback(() => {
    modalSuccess.toggle();
  }, [modalSuccess]);

  const steps = useMemo(
    () => [
      <Step1
        key="1"
        initialData={{ ...data }}
        handleChangeInput={handleChangeInput}
        nextStep={() => setCurrentPage(2)}
      />,
      <Step2 key="2" initialData={{ ...data }} nextStep={saveAccountGroup} />
    ],
    [data, handleChangeInput, saveAccountGroup]
  );

  return (
    <S.Wrapper>
      {screenOwnerActive ? (
        <ChooseOwnerGroup accounts={[]} onChooseOwner={onChooseOwner} />
      ) : (
        <>
          <TemplateCloseContent
            closeLink={PATHS_ROUTES.APP.TEAM.DEFAULT}
            closeTitle="Voltar para Equipe"
          >
            <TemplatePaginationStep
              steps={steps}
              currentPage={currentPage}
              prevPageItemClick={page => setCurrentPage(page)}
            />
          </TemplateCloseContent>

          {modalConfirmation.isActive && (
            <ModalChange
              title="Criar grupo"
              buttonAction={handleConfirm}
              buttonText="Sim"
              warning="Tem certeza que deseja agrupar esses membros?"
              onClose={modalConfirmation.toggle}
            >
              <Text color="blackDark" size="xsmall" weight="medium">
                Conta pertencente
              </Text>

              <Text color="blue">{owner.name}</Text>

              <Divider size="medium" />

              <Text color="blackDark" size="xsmall" weight="medium">
                Nome do grupo
              </Text>

              <Text color="blue">{data.name}</Text>

              <Divider size="medium" />

              <Text color="blackDark" size="xsmall" weight="medium">
                Equipe selecionada
              </Text>

              <Text color="blackDark">{accountsFormatted}</Text>
            </ModalChange>
          )}

          {modalSuccess.isActive && (
            <ModalStatus
              variant="success"
              title="Criar grupo"
              action={{
                onClick: () => {
                  modalSuccess.toggle();
                  modalConfirmation.toggle();
                }
              }}
              onClose={modalSuccess.toggle}
            >
              <Text size="small" color="grayDark">
                O novo grupo foi adicionado com sucesso.
              </Text>
            </ModalStatus>
          )}
        </>
      )}
    </S.Wrapper>
  );
}

export default ContainerPage;
