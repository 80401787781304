import PATHS_ROUTES from 'routes/paths';

export const reportStatusData = {
  success: {
    status: 'success',
    subtitle: {
      text: 'Solicitação concluída com sucesso! ',
      status: ';)',
      color: 'blue'
    },
    defaultProps: {
      title: '',
      action: { to: PATHS_ROUTES.APP.REPORT.DEFAULT, asLink: true },
      description:
        'Seu pedido está em processamento e assim que concluído disponibilizaremos os arquivos para download.'
    }
  },
  error: {
    status: 'error',
    subtitle: {
      text: 'Erro ao solicitar relatório',
      status: ';(',
      color: 'red'
    },
    defaultProps: {
      title: '',
      action: { to: PATHS_ROUTES.APP.REPORT.DEFAULT, asLink: true },
      description:
        'Não existe dados para a data selecionada. Por gentileza, selecione outro período de data.'
    }
  }
};
