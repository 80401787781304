import { useCallback, useState } from 'react';

import { Button, Checkbox, Flex, Text } from 'components';

import Card from 'styles/shared/Card';
import Divider from 'styles/shared/Divider';

import { useReport } from 'contexts/Report';
import { ReportOptions } from 'contexts/Report/types';

import { reportOptionsData } from './constants';
import * as S from './style';

export type ReportTypes = 'typeFiles' | 'archiveType';

type ReportTypeProps = {
  type: ReportTypes;
  back: () => void;
};

export function ReportType({ type, back }: ReportTypeProps) {
  const { reportStateData, handleSetReportStateData } = useReport();

  const [checks, setChecks] = useState<ReportOptions[]>(
    reportStateData.typeFiles || []
  );

  const isChecked = useCallback(
    item => !!checks.find(checkedItem => item.value === checkedItem.value),
    [checks]
  );

  const handleSelectItem = useCallback(
    (item: ReportOptions) => {
      checks.includes(item)
        ? setChecks(checks => checks.filter(check => check !== item))
        : setChecks(checks => [...checks, item]);
    },
    [checks]
  );

  const handleClickButton = useCallback(() => {
    handleSetReportStateData({ [type]: checks });
    back();
  }, [back, checks, handleSetReportStateData, type]);

  return (
    <>
      <Text size="xxmedium" color="blackDark">
        {reportOptionsData[type].title}
      </Text>
      <Text color="grayNeutralDark">{reportOptionsData[type].subtitle}</Text>

      <Divider size="large" />

      <Flex>
        {reportOptionsData[type].items.map(item => (
          <S.CardWrapper htmlFor={item.value} key={item.text}>
            <Card>
              <Text>{item.text}</Text>

              <Checkbox
                id={item.value}
                checked={isChecked(item)}
                role="checkbox"
                onCheck={() => handleSelectItem(item)}
              />
            </Card>
          </S.CardWrapper>
        ))}
      </Flex>

      <Flex ml="xlarge" mr="xlarge" mt="medium">
        <Button
          center
          onClick={handleClickButton}
          fullWidth
          disabled={!checks.length}
        >
          Confirmar
        </Button>
      </Flex>
    </>
  );
}
