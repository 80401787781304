import { Button, Flex, TemplateModal, Text } from 'components';

import { ReactComponent as OnboardingQR } from 'assets/images/pix/onboarding-qr.svg';

export type FirstAccessProps = {
  handleClose: () => void;
  handleContinue: () => void;
};

const ModalReceiveQRFirstAccess = ({
  handleClose,
  handleContinue
}: FirstAccessProps) => (
  <TemplateModal title="Pix na Ame" closeModal={handleClose}>
    <Flex alignItems="center" direction="column" mx="xlarge">
      <OnboardingQR />

      <Text size="xmedium" weight="bold" align="center" mb="medium">
        Receba com QR Code
      </Text>

      <Text
        size="xsmall"
        tag="p"
        align="center"
        color="grayNeutralDark"
        mx="medium"
        mb="small"
      >
        Escolha por qual chave você quer receber, digite o valor do pagamento
        ou, se preferir, crie um QR Code sem um valor definido. Pronto! Agora é
        só confirmar e compartilhar seu QR Code.
      </Text>

      <Button filled onClick={handleContinue}>
        Gerar QR Code
      </Button>
    </Flex>
  </TemplateModal>
);

export default ModalReceiveQRFirstAccess;
