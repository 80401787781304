import * as React from 'react';
import { getErrorMessage } from 'utils/formik';
import { FormikValues, useFormikContext } from 'formik';
import Select, { SelectProps, SelectOption } from 'components/Select';

type SelectFieldPropsProps = Omit<SelectProps, 'value' | 'errorMessage'>;

export function useSelectFieldProps(props: SelectFieldPropsProps) {
  const form = useFormikContext<FormikValues>();
  const onChangeRef = React.useRef(props.onChange);

  const onChange = React.useCallback(
    (option: SelectOption) => {
      form.setFieldTouched(props.name, true, false);
      form.setFieldValue(props.name, option?.value, true);

      onChangeRef.current?.(option);
    },
    [form, props.name, onChangeRef]
  );

  const formValue = form.values[props.name];

  const value = React.useMemo(
    () => props.options?.find(({ value }) => value === formValue),
    [formValue, props.options]
  );

  const errorMessage = getErrorMessage(props.name, form);

  return { value, onChange, errorMessage };
}

const SelectField = (props: SelectFieldPropsProps) => {
  const fieldProps = useSelectFieldProps(props);

  return <Select {...props} {...fieldProps} />;
};

export default SelectField;
