import styled, { css } from 'styled-components';

import { ReactComponent as SvgIconSwitch } from 'assets/images/icon-switch.svg';

export const Wrapper = styled.button`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;

    svg {
      margin-left: -${theme.spacings.small};
    }
  `}
`;

export const IconSwitch = styled(SvgIconSwitch)`
  ${({ theme }) => css`
    display: table;
    margin: ${theme.spacings.medium} auto;
  `}
`;
