import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

import { ModalStatusProps } from '.';

type WrapperProps = Pick<ModalStatusProps, 'description'>;

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, description }) => css`
    text-align: center;

    h5 {
      padding: 0 5rem 4rem;
    }

    & > img {
      width: 20rem;

      ${customMedia.greaterThan('medium')`
        ${
          !description &&
          css`
            width: 30rem;
          `
        }
      `}
    }

    button {
      min-width: 306px;
      margin-bottom: ${theme.spacings.large};
    }
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xlarge};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.grayNeutralDark};
    text-align: 'center';
    margin-bottom: ${theme.spacings.large};
  `}
`;
