import { ReactNode } from 'react';
import { Toggle } from 'hooks/useToggle';

export type PAGINATION_TYPES =
  | 'CREDENTIAL_TYPE'
  | 'PLATAFORM'
  | 'CREDENTIAL_GENERATED';

export const PAGINATION_TYPE = {
  CREDENTIAL_TYPE: 'CREDENTIAL_TYPE' as PAGINATION_TYPES,
  PLATAFORM: 'PLATAFORM' as PAGINATION_TYPES,
  CREDENTIAL_GENERATED: 'CREDENTIAL_GENERATED' as PAGINATION_TYPES
};

export type PaginationItem = {
  key: PAGINATION_TYPES;
  label: string;
  step: number;
};

export type Platform = { label: string; value: string };

export type CredentialsData = {
  conciliationType: string;
  platform?: Platform;
};

export type CreateCredentialDataRequest = {
  data?: {
    alias: string;
    clientId: string;
    clientSecret: string;
    accessToken: string;
    platform: string;
    saleChannel: string;
    type: string;
    documentNumber: string;
    createdAt: string;
  };
  loading?: boolean;
  error?: string | null;
};

export type ContextData = {
  data: CredentialsData;
  page: PaginationItem;
  nextPage: () => void;
  previousPage: () => void;
  pageLabels: string[];
  nextButtonAvailable: boolean;
  setConciliatonType: (value: 'TRANSACTIONAL' | 'CONCILIATION') => void;
  setPlataform: (platform: Platform) => void;
  isLastStep: boolean;
  credential: CreateCredentialDataRequest;
  modal: Toggle;
};

export type ProviderData = {
  children: ReactNode;
};
