import { createGlobalStyle, css } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &::before,
      &::after {
        box-sizing: inherit;
      }
    }

    html {
      font-size: 62.5%;
    }

    html,
    body,
    #root,
    #loading {
      min-height: 100%;
    }

    body {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.medium};
      line-height: ${theme.font.lineHeight('medium')};
      color: ${theme.mode === 'light'
        ? theme.colors.grayBlue
        : theme.colors.white};
      background: ${theme.mode === 'light'
        ? theme.colors.white
        : theme.colors.grayBlue};
    }

    a {
      text-decoration: none;
      color: ${theme.mode === 'light'
        ? theme.colors.grayBlue
        : theme.colors.white};
    }

    button {
      cursor: pointer;
      border: none;
      font-family: ${theme.font.family};
      background: transparent;
      color: ${theme.mode === 'light'
        ? theme.colors.grayBlue
        : theme.colors.white};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: ${theme.font.weights.medium};
    }

    h1 {
      font-size: ${theme.font.sizes.xxlarge};
      line-height: ${theme.font.lineHeight('xxlarge')};
    }

    h2 {
      font-size: ${theme.font.sizes.xlarge};
      line-height: ${theme.font.lineHeight('xlarge')};
    }

    h3 {
      font-size: ${theme.font.sizes.large};
      line-height: ${theme.font.lineHeight('large')};
    }

    h4 {
      font-size: ${theme.font.sizes.xxmedium};
      line-height: ${theme.font.lineHeight('xxmedium')};
    }

    h5 {
      font-size: ${theme.font.sizes.medium};
      line-height: ${theme.font.lineHeight('medium')};
    }

    h6 {
      font-size: ${theme.font.sizes.xsmall};
      line-height: ${theme.font.lineHeight('xsmall')};
    }

    ul {
      list-style: none;
    }

    *[role='button'] {
      cursor: pointer;
    }
  `}
`;

export default GlobalStyles;
