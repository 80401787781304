import { Breadcrumb, TemplateShortContent, Text } from 'components';
import Margin from 'styles/shared/Margin';

import { breadcrumbItems } from './constants';
import * as S from './styles';

const Tax = () => {
  return (
    <>
      <Breadcrumb links={breadcrumbItems} />

      <TemplateShortContent>
        <Margin my="large">
          <S.CardWrapper>
            <Margin mx="medium" my="small">
              <Text tag="h2" color="blue" size="large">
                Pessoa Física:
              </Text>
              <Text mb="medium">
                O pix é{' '}
                <Text tag="span" weight="bold">
                  gratuito
                </Text>{' '}
                para pessoas físicas.
              </Text>
              <Text tag="h2" color="blue" size="large">
                Pessoa Jurídica:
              </Text>
              <Text>
                É cobrada uma tarifa em cada transação recebida por QR Code Pix
                ou por inserção de dados manuais (chave Pix ou agência e conta).
                O valor máximo da tarifa é de{' '}
                <Text tag="span" weight="bold">
                  R$1,50
                </Text>{' '}
                ou até{' '}
                <Text tag="span" weight="bold">
                  1%
                </Text>{' '}
                sobre o valor da transação.
              </Text>
            </Margin>
          </S.CardWrapper>
        </Margin>
      </TemplateShortContent>
    </>
  );
};

export default Tax;
