import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

import { Column } from 'styles/shared/Grid';
import Card from 'styles/shared/Card';

import { ReactComponent as SvgIconQrCodeBlue } from 'assets/images/sell/icon-qrcode-blue.svg';

export const SectionTitle = styled.h4`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
  `}
`;

export const IconQrCodeBlue = styled(SvgIconQrCodeBlue)`
  ${({ theme }) => css`
    display: block;
    margin: ${theme.spacings.medium} auto;
  `}
`;

export const ColumnLink = styled(Column)`
  ${customMedia.lessThan('large')`
    text-align: center;
  `}
`;

export const SellCard = styled(Card)`
  ${customMedia.greaterThan('medium')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;

type StatementsCardProps = {
  hasStatements?: boolean;
};

const statementsCardModifiers = {
  hasStatements: () => css`
    & > div > div:nth-child(2) {
      flex: 1;
    }
  `
};

export const StatementsCard = styled(Card)<StatementsCardProps>`
  ${({ hasStatements }) => css`
    ${customMedia.greaterThan('medium')`
      display: flex;
      min-height: 48rem;
    `}

    ${hasStatements && statementsCardModifiers.hasStatements()}
  `}
`;
