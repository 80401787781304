import { Button, Text } from 'components';

import { ReactComponent as IconClock } from 'assets/images/icon-clock.svg';

import * as S from './styles';

export type TimeTextProps = {
  time: number;
  resendCode(): void;
};

const TimeText = ({ time, resendCode }: TimeTextProps) => {
  if (!time) {
    return (
      <S.TimerWrapper>
        Não recebeu o código?
        <Button tertiary onClick={resendCode}>
          <strong>Reenviar</strong>
        </Button>
      </S.TimerWrapper>
    );
  }

  return (
    <S.TimerWrapper>
      <IconClock />

      <div>
        Aguarde{' '}
        <Text tag="span" color="blue" weight="bold">
          {time}
        </Text>{' '}
        segundos <br />
        para reenviar o código
      </div>
    </S.TimerWrapper>
  );
};

export default TimeText;
