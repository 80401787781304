import * as S from './styles';
import {
  Flex,
  Link,
  Button,
  Breadcrumb,
  InputSearch,
  PaginationNav
} from 'components';
import { BreadcrumbProps } from 'components/Breadcrumb';
import { InputSearchProps } from 'components/InputSearch';
import { PageNavProps } from 'components/Pagination/Nav';

export type TemplateSearchContentAction = {
  to: string;
  text: string;
  icon: React.ReactElement;
};

export type TemplateSearchContentProps = {
  children: React.ReactNode;
  breadcrumbProps: BreadcrumbProps;
  inputSearchProps?: InputSearchProps;
  paginationNavProps?: PageNavProps;
  actions?: TemplateSearchContentAction[];
};

const TemplateSearchContent = ({
  actions,
  children,
  breadcrumbProps,
  inputSearchProps,
  paginationNavProps
}: TemplateSearchContentProps) => (
  <>
    <Flex mb="small" alignItems="center" justifyContent="space-between">
      <Breadcrumb {...breadcrumbProps} />

      <Flex alignItems="center" justifyContent="flex-end">
        {actions &&
          actions.map(({ to, icon, text }) => (
            <Button
              key={to}
              as={Link}
              secondary
              noBorder
              icon={icon}
              removeChildrenWhenMobile
              to={to}
            >
              {text}
            </Button>
          ))}
      </Flex>
    </Flex>

    {!!inputSearchProps && <InputSearch {...inputSearchProps} />}

    <S.Wrapper>{children}</S.Wrapper>

    {paginationNavProps !== undefined && (
      <PaginationNav {...paginationNavProps} />
    )}
  </>
);

export default TemplateSearchContent;
