import { Fragment } from 'react';

import { CalendarDate, Table, Text, Flex } from 'components';

import { TColumn } from 'components/Table';

import { StatementContent } from 'services/statementService/types';
import { groupedByDate } from 'utils/statementHelper';

import theme from 'styles/theme';

import { ReactComponent as SvgIconMenuStatement } from 'assets/images/menu/icon-statement-outline.svg';

import Statement from '../Item';

import * as S from './styles';

export type StatementListProps = {
  statements?: StatementContent[];
  short?: boolean;
  emptyTransactionMessage?: string;
};

function StatementList({
  statements,
  short,
  emptyTransactionMessage = 'Você não possui transações disponíveis.'
}: StatementListProps) {
  const theadData = short
    ? []
    : [
        'Order ID',
        'Data e hora',
        'NSU',
        'Categoria',
        'Origem',
        'Valor bruto',
        'Status',
        ''
      ];

  return (
    <S.Wrapper data-testid="ame-statement-list">
      {statements && statements.length ? (
        <Table theadData={theadData}>
          {groupedByDate(statements).map(({ date, items }, idx) => (
            <Fragment key={date}>
              {!short && idx > 0 && (
                <tr>
                  <TColumn
                    verticalCenterContent
                    horizontalCenterContent
                    colSpan={8}
                  >
                    <CalendarDate date={date} />
                  </TColumn>
                </tr>
              )}

              {items.map(item => (
                <Statement key={item.id} statement={item} short={short} />
              ))}
            </Fragment>
          ))}
        </Table>
      ) : (
        <Flex direction="column" alignItems="center" justifyContent="center">
          <SvgIconMenuStatement color={theme.colors.grayDark} />

          <Text color="grayDark" my="small">
            {emptyTransactionMessage}
          </Text>
        </Flex>
      )}
    </S.Wrapper>
  );
}

export default StatementList;
