import { useFormikContext } from 'formik';

import { InputField, SelectField } from 'components';
import { SelectOption } from 'components/Select';

import { fields } from './registrationAccountFormConfig';

const accountTypes: SelectOption[] = [
  { label: 'Corrente', value: 'CORRENTE' },
  { label: 'Poupança', value: 'POUPANCA' }
];

const AccountRegistrationForm = () => {
  const { status } = useFormikContext();

  return (
    <>
      <SelectField
        placeholder=""
        name={fields.type}
        label="Tipo de conta corrente"
        options={accountTypes}
      />
      <SelectField
        options={status.banks}
        name={fields.bank}
        label="Instituição"
        placeholder="Selecione uma instituição"
      />

      <InputField
        name={fields.agency}
        label="Agência (com dígito se houver)"
        onlyNumbers
        inputMode="numeric"
        maxLength={5}
      />
      <InputField
        name={fields.account}
        label="Conta (com dígito)"
        onlyNumbers
        inputMode="numeric"
        maskType="ACCOUNT"
        maxLength={12}
      />
    </>
  );
};

export default AccountRegistrationForm;
