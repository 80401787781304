import { useState } from 'react';

import { Button, CardInfo, Flex, Money, Text } from 'components';

import { Row, Column } from 'styles/shared/Grid';
import Divider from 'styles/shared/Divider';
import Margin from 'styles/shared/Margin';

import { useBank } from 'contexts/Bank';

import { maskDocument } from 'utils/masks';

import { ModalStep3 } from './Modal';

type Step3Props = {
  goToChangeAccount: () => void;
  goToStep2: () => void;
};

export function Step3({ goToStep2, goToChangeAccount }: Step3Props) {
  const { withdrawData } = useBank();

  const [modal, setModal] = useState(false);

  return (
    <>
      <Text size="xxmedium" weight="bold">
        Retirar
      </Text>

      <Text>Verifique as informações antes de finalizar a operação</Text>

      <Divider size="large" />

      <Text size="xsmall" weight="medium" darkColor="white">
        Conta bancária de destino
      </Text>
      <CardInfo
        text={withdrawData.bankData?.name as string}
        icon={<img src={withdrawData.bankData?.image} width="50" />}
        action={goToChangeAccount}
        infoText="Alterar"
      >
        <Text color="grayNeutralDark" size="xxsmall">
          CNPJ: {maskDocument(withdrawData?.documentNumber as string, 'CNPJ')}
        </Text>
        <Text color="blueLightest" size="xxsmall">
          Ag: {withdrawData.bankData?.agency} / Cc:{' '}
          {withdrawData.bankData?.account}
        </Text>
      </CardInfo>

      <Divider size="medium" />

      <Flex alignItems="center" justifyContent="space-between">
        <div>
          <Text size="xsmall" weight="medium" darkColor="white">
            Valor da transferência
          </Text>
          <Money value={withdrawData.value} size="xxlarge" weight="medium" />
        </div>
        <Margin mr="small" onClick={goToStep2} role="button">
          <Text color="blueCategoryColorSuccess" weight="bold" size="xsmall">
            Alterar
          </Text>
        </Margin>
      </Flex>

      <Divider size="medium" />

      <Text size="xsmall" weight="medium" darkColor="white">
        Data da transferência
      </Text>
      <Text>{withdrawData.dateNow}</Text>

      <Divider size="xlarge" />

      <Row>
        <Column xs={12} md={8} offsetMd={2}>
          <Button
            fullWidth
            filled
            onClick={() => setModal(true)}
            disabled={modal}
          >
            Finalizar
          </Button>
        </Column>
      </Row>

      {modal && <ModalStep3 onClose={() => setModal(false)} />}
    </>
  );
}
