import * as Yup from 'yup';
import { fieldNames } from 'pages/Accreditation/shared/form';

export const validation = Yup.object().shape({
  [fieldNames.feeId]: Yup.string().required('Taxa obrigatória'),
  [fieldNames.installment]: Yup.string().required(
    'Número de parcelas obrigatório'
  ),
  [fieldNames.terms]: Yup.string().required('Termos e condições obrigatório')
});
