import { ModalPix } from 'components';
import { usePixRegister } from 'contexts/Pix/PixRegister';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { registerModalData, statusDataObj, StatusRequest } from './constants';
import PATHS_ROUTES from 'routes/paths';

type RegisterKeyStatusData = {
  [key in StatusRequest]: {
    key?: string;
    primaryOnClick: () => void;
    secondaryOnClick?: () => void;
  };
};

type RegisterKeyModalsProps = {
  loading?: boolean;
  createdKey: string;
  onClose: () => void;
  onConfirm?: () => void;
  status: StatusRequest;
  submitKey: () => void;
  validationCode?: string;
};

const RegisterKeyModals = ({
  status,
  loading,
  onClose,
  onConfirm,
  submitKey,
  createdKey,
  validationCode
}: RegisterKeyModalsProps) => {
  const navigate = useNavigate();
  const { handleCreateClaim } = usePixRegister();

  const registerModalParams = useMemo(() => {
    return {
      initial: {
        primaryOnClick: submitKey,
        secondaryOnClick: onClose,
        key: createdKey
      },
      success: {
        primaryOnClick: () =>
          onConfirm ? onConfirm() : navigate(PATHS_ROUTES.APP.PIX.KEYS.DEFAULT),
        key: createdKey
      },
      error: {
        primaryOnClick: onClose,
        key: createdKey
      },
      claim: {
        primaryOnClick: () => handleCreateClaim(validationCode),
        secondaryOnClick: onClose,
        key: createdKey
      },
      portability: {
        primaryOnClick: () => handleCreateClaim(validationCode),
        secondaryOnClick: onClose,
        key: createdKey
      },
      successPortability: {
        primaryOnClick: () => navigate(PATHS_ROUTES.APP.PIX.KEYS.DEFAULT),
        key: createdKey
      },
      successClaim: {
        primaryOnClick: () => navigate(PATHS_ROUTES.APP.PIX.KEYS.DEFAULT),
        key: createdKey
      }
    } as RegisterKeyStatusData;
  }, [
    submitKey,
    onClose,
    createdKey,
    navigate,
    handleCreateClaim,
    validationCode,
    onConfirm
  ]);

  return (
    <ModalPix
      modalTitle="Minhas chaves"
      modalAction={onClose}
      loading={loading}
      {...registerModalData(registerModalParams[status])[statusDataObj[status]]}
    />
  );
};

export default RegisterKeyModals;
