import Text from 'components/Text';

import { ReactComponent as IconWarning } from 'assets/images/icon-alert.svg';

import * as S from './styles';

export type WarningAlertProps = {
  description: string;
};

const WarningAlert = ({ description }: WarningAlertProps) => (
  <S.Wrapper>
    <IconWarning />
    <Text size="small" color="grayDark">
      {description}
    </Text>
  </S.Wrapper>
);

export default WarningAlert;
