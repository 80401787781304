import { useCallback, useState } from 'react';
import { Flex, Text, CodeInput, TimeText } from 'components';
import Divider from 'styles/shared/Divider';
import { ReactComponent as Spinner } from 'assets/images/pix/spinner.svg';
import useTimer from 'hooks/useTimer';

type ValidationCodeProps = {
  changeValue: (value: string) => void;
  sendCodeValidation: () => void;
  onMatch: () => void;
  description: string;
  loading: boolean;
};

export function ValidationCode({
  changeValue,
  sendCodeValidation,
  onMatch,
  description,
  loading
}: ValidationCodeProps) {
  const [code, setCode] = useState<string>('');
  const { time, restartTimer } = useTimer({ decrement: true });

  const resendCode = useCallback(() => {
    restartTimer();

    sendCodeValidation();
  }, [restartTimer, sendCodeValidation]);

  const onChange = (value: string) => {
    changeValue(value);

    setCode(value);
  };

  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        Código de Validação
      </Text>

      <Text tag="p" size="small" mt="small">
        {description}
      </Text>

      <Divider size="medium" />

      <Flex
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
        mb="medium"
        ml={loading ? 'large' : undefined}
      >
        <CodeInput
          name="code"
          onChangeValue={value => onChange(value)}
          onMatch={() => {
            onMatch();
          }}
          value={code}
        />

        {loading && (
          <>
            <Divider direction="horizontal" data-testid="loading" />
            <Spinner />
          </>
        )}
      </Flex>

      <Flex justifyContent="center">
        <TimeText time={time} resendCode={resendCode} />
      </Flex>
    </>
  );
}
