import * as React from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { validationSchema } from './validationSchema';

import PATHS_ROUTES from 'routes/paths';
import {
  Text,
  TemplateCloseContent,
  Button,
  Loading,
  ModalStatus,
  Input
} from 'components';
import Divider from 'styles/shared/Divider';
import useToggle from 'hooks/useToggle';
import useMutate from 'hooks/useMutate';
import endpoints from 'services/userService/endpoints.json';
import { formatInput } from 'utils/masks';
import { checkCellPhoneNumber } from 'utils/validations';

type UpdatePhoneFormValues = {
  number: string;
};

const initialValues: UpdatePhoneFormValues = {
  number: ''
};

const AccountUpdatePhone = () => {
  const modalSuccess = useToggle();
  const navigate = useNavigate();
  const { state, mutate, clearError } = useMutate();

  const handleSubmit = React.useCallback(
    (values: UpdatePhoneFormValues) => {
      const endpoint = endpoints.changePhone;
      const { number } = values;
      const isValid = checkCellPhoneNumber(number);
      const formatedNumber = isValid
        ? number
        : formatInput['CELLPHONE'](number);

      mutate({
        endpoint,
        method: 'put',
        onSuccess: modalSuccess.toggle,
        body: { number: formatedNumber },
        errorMessage:
          'Ocorreu um erro ao alterar o telefone, verifique se os dados informados estão corretos'
      });
    },
    [mutate, modalSuccess.toggle]
  );

  const handleChange = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      formikHandleChange: (e: React.ChangeEvent) => void
    ) => {
      if (state.error) clearError();

      formikHandleChange(e);
    },
    [clearError, state.error]
  );

  return (
    <>
      {state.loading && <Loading fullContent />}

      <TemplateCloseContent
        closeLink={PATHS_ROUTES.APP.ACCOUNT.PROFILE}
        closeTitle="Voltar para o perfil"
      >
        <Text tag="h2" size="xxmedium" weight="medium" mb="large">
          Alterar telefone
        </Text>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({
            values,
            dirty,
            handleChange: formikHandleChange,
            touched,
            errors
          }) => (
            <Form>
              <Input
                name="number"
                label="Telefone"
                type="text"
                maskType="CELLPHONE"
                value={values.number}
                onChange={e => handleChange(e, formikHandleChange)}
                errorMessage={touched.number ? errors.number : undefined}
              />

              {!!state.error && (
                <Text align="center" color="red">
                  {state.error}
                </Text>
              )}

              <Divider size="xlarge" />

              <Button type="submit" filled center large disabled={!dirty}>
                Salvar
              </Button>
            </Form>
          )}
        </Formik>

        {modalSuccess.isActive && (
          <ModalStatus
            variant="success"
            action={{
              onClick: () => {
                navigate(PATHS_ROUTES.APP.ACCOUNT.PROFILE);
                window.location.reload();
              }
            }}
          >
            <Text size="large" weight="bold" align="center">
              Telefone alterado!
            </Text>
          </ModalStatus>
        )}
      </TemplateCloseContent>
    </>
  );
};

export default AccountUpdatePhone;
