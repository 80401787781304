import { ReactNode, useState } from 'react';

import { Text, Checkbox, Flex } from 'components';

import * as S from './styles';

export type Item = {
  text: ReactNode;
  icon?: JSX.Element;
  description?: ReactNode;
} & (
  | {
      checked?: boolean;
      items: Item[];
    }
  | {
      checked: boolean;
      items?: Item[];
    }
);

export interface CheckList {
  items: Item[];
  onChange: (item: Item, open: boolean) => void;
}

type CheckItemProps = {
  item: Item;
  onChange: (item: Item, open: boolean) => void;
};

function CheckList({ items, onChange }: CheckList) {
  return (
    <S.ListWrapper>
      {items.map((item, index) => (
        <CheckItem key={index} item={item} onChange={onChange} />
      ))}
    </S.ListWrapper>
  );
}

function CheckItem({ item, onChange }: CheckItemProps) {
  const { icon, text, description, items, checked } = item;
  const [open, setOpen] = useState(false);

  return (
    <S.ItemWrapper checked={!!checked}>
      <Flex alignItems="center" justifyContent="space-between">
        <S.Texts>
          {icon}

          <div>
            <Text size="medium" weight="medium">
              {text}
            </Text>

            <Text size="small">{description}</Text>
          </div>
        </S.Texts>

        {items ? (
          <S.Button
            aria-label={open ? 'Fechar itens' : 'Abrir itens'}
            onClick={() => setOpen(!open)}
          >
            <S.Icon closed={!open} />
          </S.Button>
        ) : (
          <Checkbox
            checked={checked}
            onCheck={isChecked => onChange(item, isChecked)}
          />
        )}
      </Flex>

      {open && (
        <S.Content>
          {items?.map((item, index) => (
            <CheckItem key={index} item={item} onChange={onChange} />
          ))}
        </S.Content>
      )}
    </S.ItemWrapper>
  );
}

export default CheckList;
