import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { TColumn } from 'components/Table';

import { StatementContent } from 'services/statementService/types';

import {
  renderTypeIcon,
  translateStatus,
  translateTypes,
  StatementOperationType,
  STATEMENT_TRANSLATED_STATUS,
  NEW_STATEMENT_TRANSLATED_TYPE
} from 'utils/newStatementHelper';

import { formatSimpleDate, formatCurrency } from 'utils/masks';

import PATHS_ROUTES from 'routes/paths';

import { ReactComponent as ArrowIcon } from 'assets/images/icon-right-next.svg';

import * as S from './styles';

export type StatementProps = {
  statement: StatementContent;
  short?: boolean;
};

const Statement = ({ statement, short }: StatementProps) => {
  const navigate = useNavigate();

  const statementType = translateTypes(statement.type);

  const isSale = statementType === NEW_STATEMENT_TRANSLATED_TYPE.RELEASE;

  const statementStatus = translateStatus(statement.status, isSale);

  const isProcessing =
    statementStatus === STATEMENT_TRANSLATED_STATUS.PROCESSING;

  const isCancel = statementStatus === STATEMENT_TRANSLATED_STATUS.CANCELED;

  const getAmount = useCallback(
    (amount: number, operationType: StatementOperationType) => {
      const amountInOut = operationType === 'CREDIT' ? '+ ' : '- ';

      return amountInOut + formatCurrency(amount);
    },
    []
  );

  const classAmountInOut = useMemo(
    () =>
      statement.operationType === 'CREDIT'
        ? 'grossAmount-in'
        : 'grossAmount-out',
    [statement.operationType]
  );

  return (
    <S.Item
      data-testid="ame-statement-item"
      className={clsx({
        isProcessing,
        isCancel
      })}
      onClick={() => navigate(PATHS_ROUTES.APP.STATEMENT.DETAILS(statement.id))}
    >
      <TColumn
        colSpan={8}
        className={clsx({
          short: true,
          mobile: !short
        })}
      >
        {renderTypeIcon(statement.type)}

        <div>
          <p>{statement.name}</p>

          <p className={clsx(!!short && classAmountInOut)}>{statementType}</p>

          {!short && (
            <p className={classAmountInOut}>
              {getAmount(statement.amount, statement.operationType)}
            </p>
          )}
        </div>

        {short
          ? getAmount(statement.amount, statement.operationType)
          : formatSimpleDate(statement.date, 'DD MMM').toUpperCase()}

        <ArrowIcon title="Ver Detalhes" />
      </TColumn>

      {!short && (
        <>
          <TColumn>{statement.id}</TColumn>

          <TColumn>
            {formatSimpleDate(statement.date, 'DD MMM k:mm').toUpperCase()}
          </TColumn>

          <TColumn>{statement.nsu}</TColumn>

          <TColumn>{statementType}</TColumn>

          <TColumn verticalCenterContent>
            {renderTypeIcon(statement.type)}

            {statement.name}
          </TColumn>

          <TColumn className={classAmountInOut}>
            {getAmount(statement.amount, statement.operationType)}
          </TColumn>

          {/* <TColumn>
            {getAmount(statement.calculatedAmount, statement.operationType)}
          </TColumn> */}

          <TColumn className="status">{statementStatus}</TColumn>

          <TColumn verticalCenterContent>
            <ArrowIcon title="Ver Detalhes" />
          </TColumn>
        </>
      )}
    </S.Item>
  );
};

export default Statement;
