import { useState } from 'react';

import { ReactComponent as IconClose } from 'assets/images/icon-close.svg';
// import { ReactComponent as IconBack } from 'assets/images/icon-back.svg';

import { Step2, Step3 } from './steps';
import * as S from './styles';

import { useScreenSell } from 'contexts/ScreenSell';
import { QrCodeData } from 'contexts/ScreenSell/types';

type SellFluxProps = {
  closeAction: () => void;
};

function SellFlux({ closeAction }: SellFluxProps) {
  const [activeStep, setActiveStep] = useState(0);
  const { setQrCodeData } = useScreenSell();

  const finishStepActionButton = () => {
    // setSellerData(undefined);
    setQrCodeData({} as QrCodeData);
    setActiveStep(0);
  };

  const closeSteps = () => {
    // setSellerData(undefined);
    setQrCodeData({} as QrCodeData);
    closeAction();
  };

  const steps = [
    // <Step1 key="0" next={() => setActiveStep(1)} />,
    <Step2 key="0" next={() => setActiveStep(1)} />,
    <Step3 key="1" next={finishStepActionButton} />
  ];

  return (
    <S.MainContainer>
      <S.IconCLose justifyContent="flex-end" alignItems="flex-end" mb="small">
        {/* {activeStep === 1 && (
          <IconBack role="icon-back" onClick={() => setActiveStep(0)} />
        )} */}
        <IconClose onClick={closeSteps} role="icon-close" />
      </S.IconCLose>
      {steps[activeStep]}
    </S.MainContainer>
  );
}

export default SellFlux;
