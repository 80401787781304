import { useState } from 'react';

import {
  Button,
  CardAccount,
  Input,
  InputMoney,
  Loading,
  ModalStatus,
  Text
} from 'components';
import Divider from 'styles/shared/Divider';
import { useScreenSell } from 'contexts/ScreenSell';

type Step2Props = {
  next: () => void;
};

function Step2({ next }: Step2Props) {
  const [value, setValue] = useState('0');
  const [description, setDescription] = useState('');
  const { handleSetQrCode, loading, qrCodeData, sellerData } = useScreenSell();
  const successDescription =
    'Agora é só mostrar ou enviar o QR Code e pedir para seu cliente fazer a leitura através do botão ”Pagar” disponível no app da Ame Digital.';

  return (
    <>
      {loading && <Loading fullContent />}

      <Text weight="medium" tag="h2" size="large">
        Qual é o valor desse QR Code?
      </Text>

      <Divider size="small" />

      <Text tag="h4" size="small" color="grayNeutralDark">
        Você também pode gerar um QR Code sem valor ;)
      </Text>

      <Divider size="medium" />

      <CardAccount
        profileType="USER"
        title={sellerData?.groupName as string}
        document={sellerData?.documentNumber}
        hidePicture
      />

      <Divider size="medium" />

      <InputMoney
        name="value"
        label="Digite o valor da cobrança (opcional)"
        value={value}
        onChange={value => setValue(value)}
      />

      <Input
        maxLength={140}
        name="description"
        label="Descrição do QR Code (opcional)"
        value={description}
        onChange={e => setDescription(e.target.value)}
      />

      <Divider size="xlarge" />

      <Button filled center onClick={() => handleSetQrCode(value, description)}>
        Gerar QR Code
      </Button>

      {qrCodeData.pdfUrl && (
        <ModalStatus
          key="2"
          variant="success"
          title="Criar QR Code"
          description={successDescription}
          action={{ text: 'Ver meu QR Code', onClick: next }}
        >
          <Text tag="h2" size="xlarge" align="center" weight="bold">
            Eba! Tudo certo com <br />
            seu novo QR Code
            <Text
              tag="span"
              size="xlarge"
              align="center"
              weight="bold"
              color="blueCategoryColorSuccess"
            >
              {` ;)`}
            </Text>
          </Text>
        </ModalStatus>
      )}
    </>
  );
}

export default Step2;
