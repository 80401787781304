import * as types from './types';
import endpoints from './endpoints.json';
import api from '../api';

export async function getCardAuthentication(origin: string) {
  const url = endpoints.cardAuthentication.replace('{origin}', origin);

  const result = await api.get<types.ICardAuthenticationData>(url);

  return result.data;
}
