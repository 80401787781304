import { WithdrawData } from 'contexts/Bank/types';
import { formatCurrency, maskDocument } from 'utils/masks';

export const formatData = (data: WithdrawData) => [
  {
    id: 2,
    field1: {
      label: 'Conta bancária de destino:',
      text: data.bankData?.name as string
    },
    field2: {
      label: 'CNPJ de destino:',
      text: maskDocument(data?.documentNumber as string, 'CNPJ')
    }
  },
  {
    id: 3,
    field1: {
      label: 'Tipo de conta:',
      text: data.bankData?.accountType as string
    },
    field2: {
      label: 'Instituição:',
      text: `(${data.bankData?.bank}) ${data.bankData?.name}`
    }
  },
  {
    id: 4,
    field1: {
      label: 'Agência:',
      text: data.bankData?.agency as string
    },
    field2: {
      label: 'Conta (com dígito):',
      text: data.bankData?.account as string
    }
  },
  {
    id: 5,
    field1: {
      label: 'Valor da retirada:',
      text: formatCurrency(data.value as string, true)
    },
    field2: {
      label: 'Data da transação:',
      text: data.dateNow as string
    }
  }
];
