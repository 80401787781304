import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Wrapper = styled.ul`
  ${({ theme }) => css`
    padding-top: ${theme.spacings.medium};

    .category {
      padding-left: 2.3rem;
      color: ${theme.colors.turquoise};
      font-weight: ${theme.font.weights.bold};
      line-height: 3;
    }

    li {
      position: relative;
      display: block;
      padding: 0 ${theme.spacings.small};
      margin-bottom: ${theme.spacings.small};

      img {
        margin-right: ${theme.spacings.medium};
      }

      a {
        display: flex;
        align-items: center;
        font-size: ${theme.font.sizes.medium};
        line-height: ${theme.font.lineHeight('medium')};
        color: ${theme.mode === 'light'
          ? theme.colors.grayBlue
          : theme.colors.white};
        font-weight: ${theme.font.weights.regular};
        padding: ${theme.spacings.small} ${theme.spacings.small};

        & > div {
          display: flex;
          align-items: center;

          svg:last-child,
          img:last-child {
            display: none;
          }
        }

        svg {
          color: ${theme.mode === 'light'
            ? theme.colors.grayBlue
            : theme.colors.white};
          margin-right: ${theme.spacings.xsmall};
        }

        span {
          flex: 1;
          margin-left: ${theme.spacings.small};
        }

        &.active {
          svg,
          img {
            display: none;
          }

          & > div {
            svg:last-child,
            img:last-child {
              margin-right: 0.5rem;
              display: flex;
            }
          }
        }

        &.active,
        &:hover {
          border-radius: 12px;
          color: ${theme.colors.blue};
          font-weight: ${theme.font.weights.regular};
          background: ${theme.mode === 'light'
            ? '#ebf6ff'
            : transparentize(0.9, theme.colors.blue)};

          svg {
            color: ${theme.colors.blue};
            transition: all 0.2s;
          }
        }
      }

      // SUBMENU
      button {
        position: absolute;
        top: 0;
        right: 20px;
        width: 30px;
        height: 53px;
        z-index: 1;
      }

      ul {
        display: none;

        li {
          padding: 0;

          &:first-child {
            margin-top: ${theme.spacings.xsmall};
          }

          a {
            color: ${theme.colors.blue};
            font-weight: ${theme.font.weights.regular};

            &.active {
              background: ${theme.colors.transparent};
              padding-left: 3rem;
            }

            svg {
              color: inherit;
            }
          }
        }
      }

      &.expand {
        svg[aria-label='Arrow'] {
          transform: rotate(180deg);
          margin-bottom: 6px;
        }

        ul {
          display: initial;
        }
      }
    }
  `}
`;
