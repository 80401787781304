import { Text } from 'components';

import * as S from './styles';

export type CardProfileProps = {
  title: string;
  description?: string;
  image: string;
  className?: string;
};

export const CardProfile = ({
  title,
  description,
  image,
  className
}: CardProfileProps) => {
  return (
    <S.Wrapper className={className}>
      <img src={image} alt={description || title} />
      <S.ContentWrapper>
        <Text tag="p" color="blackDark" size="medium" weight="medium">
          {title}
        </Text>

        {description && (
          <Text tag="span" color="grayDark" size="medium">
            {description}
          </Text>
        )}
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default CardProfile;
