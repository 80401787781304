import { useState } from 'react';

import { ReactComponent as IconCopyOutline } from 'assets/images/icon-copy-outline.svg';

import { Flex, Text, Button, Popover, Loading, ErrorMessage } from 'components';
import Divider from 'styles/shared/Divider';
import Margin from 'styles/shared/Margin';

import { useCredentials } from 'contexts/Credentials';

import { cnpjMask } from 'utils/masks';
import { copyToClipBoard } from 'utils/copyToClipboard';

import * as S from './styles';

const getTokenMasked = (token?: string) => {
  if (token)
    return `${token.substring(0, 8)}${token
      .substring(8, token.length)
      .replace(/[a-z\d]/gi, '*')}`;

  return '';
};

const GenerateConciliation = () => {
  const { credential } = useCredentials();

  const [copiedKey, setCopiedKey] = useState(false);
  const [copiedToken, setCopiedToken] = useState(false);

  if (credential.loading)
    return (
      <Margin my="medium">
        <Loading />
      </Margin>
    );

  if (credential.error) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <ErrorMessage message={credential.error} />
      </Flex>
    );
  }

  return (
    <div>
      <Text tag="h3" size="xmedium" weight="bold">
        {credential.data?.alias}
      </Text>
      <Text tag="p" size="small" color="grayDark">
        CNPJ: {credential.data && cnpjMask(credential.data.documentNumber)}
      </Text>
      <Margin my="medium">
        <Divider withBorder size="xsmall" />
      </Margin>
      <S.KeyContainer justifyContent="flex-start" mb="large">
        <div>
          <Text weight="medium" size="small" color="grayDark" mb="small">
            Application Key
          </Text>
          <Flex alignItems="center">
            <S.KeyCard>
              <Text>{getTokenMasked(credential.data?.clientId)}</Text>
            </S.KeyCard>
            <Popover direction="bottom" text="Key Copiada!" show={copiedKey}>
              <Button
                icon={<IconCopyOutline />}
                tertiary
                onClick={() =>
                  copyToClipBoard(setCopiedKey, credential.data?.clientId || '')
                }
              />
            </Popover>
          </Flex>
        </div>
        <div>
          <Text weight="medium" size="small" color="grayDark" mb="small">
            Application Token
          </Text>
          <Flex alignItems="center">
            <S.KeyCard>
              <Text>{getTokenMasked(credential.data?.clientSecret)}</Text>
            </S.KeyCard>
            <Popover
              direction="bottom"
              text="Token Copiado!"
              show={copiedToken}
            >
              <Button
                icon={<IconCopyOutline />}
                tertiary
                onClick={() =>
                  copyToClipBoard(
                    setCopiedToken,
                    credential.data?.clientSecret || ''
                  )
                }
              />
            </Popover>
          </Flex>
        </div>
      </S.KeyContainer>
    </div>
  );
};

export default GenerateConciliation;
