import { ReactNode } from 'react';

import Text from 'components/Text';

import * as S from './styles';

export type ShadowDashedProps = {
  icon?: JSX.Element;
  text: string;
  action?: () => void;
  children?: ReactNode;
  rightText: string;
};

export const ShadowDashed = ({
  icon,
  text,
  action,
  rightText,
  children
}: ShadowDashedProps) => {
  return (
    <S.CardWrapper onClick={action}>
      <S.Content>
        {icon}
        <div>
          <Text size="xsmall" color="grayNeutralDark">
            {text}
          </Text>
          {children}
        </div>
      </S.Content>
      <Text size="xsmall" color="blue" weight="bold">
        {rightText}
      </Text>
    </S.CardWrapper>
  );
};

export default ShadowDashed;
