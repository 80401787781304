import { useParams } from 'react-router-dom';

import { MemberPermissions } from 'services/memberService/types';
import { editMemberPermissions } from 'services/memberService';
import endpoints from 'services/endpoints';

import PATHS_ROUTES from 'routes/paths';
import {
  TemplateCloseContent,
  TemplateProfilePermissionsForm,
  Text,
  ModalStatus
} from 'components';

import { useMember } from 'contexts/Team/Member';
import useRequest from 'hooks/useRequest';
import useToggle from 'hooks/useToggle';

import Divider from 'styles/shared/Divider';

type ParamsTypes = {
  id: string;
};

const TeamMemberPermission = () => {
  const modalSuccess = useToggle();
  const modalError = useToggle();
  const { member } = useMember();
  const { id } = useParams<ParamsTypes>();
  const { data: memberPermissions } = useRequest<MemberPermissions>({
    url: id ? endpoints.memberPermissions.replace('{memberId}', id) : null
  });

  const handleSavePermissions = async (values: MemberPermissions) => {
    try {
      await editMemberPermissions(id as string, values.permissions);
      modalSuccess.toggle();
    } catch (err) {
      modalError.toggle();
    }
  };

  return (
    <TemplateCloseContent
      closeLink={PATHS_ROUTES.APP.TEAM.MEMBER(id)}
      closeTitle="Voltar"
    >
      {memberPermissions && (
        <>
          <Text tag="h5" size="xxmedium" weight="medium">
            Permissionamento
          </Text>
          <Text size="small" color="grayDark">
            Defina as permissões de {member?.name}
          </Text>

          <Divider size="medium" />

          <TemplateProfilePermissionsForm
            initialValues={memberPermissions}
            onConfirm={handleSavePermissions}
            blockEditProfileType
          />

          {modalSuccess.isActive && (
            <ModalStatus
              variant="success"
              title="Editar permissões"
              onClose={modalSuccess.toggle}
              action={{
                asLink: true,
                filled: false,
                tertiary: true,
                text: 'Retornar',
                to: PATHS_ROUTES.APP.TEAM.MEMBER(id)
              }}
            >
              <Text size="large" weight="bold" align="center">
                Permissões salvas <br /> com sucesso{' '}
                <Text size="large" weight="bold" tag="span" color="blue">
                  ;)
                </Text>
              </Text>
            </ModalStatus>
          )}
          {modalError.isActive && (
            <ModalStatus
              variant="error"
              title="Editar permissões"
              onClose={modalError.toggle}
              action={{ onClick: modalError.toggle }}
            >
              <Text size="small" color="grayDark">
                Ocorreu um erro ao editar as permissões
              </Text>
            </ModalStatus>
          )}
        </>
      )}
    </TemplateCloseContent>
  );
};

export default TeamMemberPermission;
