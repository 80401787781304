import { useState, ReactNode } from 'react';

import { ThemeColors } from 'types/global';

import Button from 'components/Button';

import * as S from './styles';

export type ToggleViewProps = {
  startVisible?: boolean;
  color?: ThemeColors;
  children?: ReactNode;
};

const ToggleView = ({
  color = 'blue',
  startVisible = false,
  children
}: ToggleViewProps) => {
  const [showContent, setShowContent] = useState(startVisible);

  const Icon = showContent ? (
    <S.IconArrowCircleUp color={color} />
  ) : (
    <S.IconArrowCircleDown color={color} />
  );

  return (
    <S.Wrapper>
      <Button
        icon={Icon}
        tertiary
        center
        onClick={() => setShowContent(!showContent)}
      >
        {showContent ? 'Ver menos' : 'Ver mais'}
      </Button>
      {showContent && <S.Content>{children}</S.Content>}
    </S.Wrapper>
  );
};

export default ToggleView;
