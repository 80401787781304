import styled, { css } from 'styled-components';

export * from 'pages/Accreditation/shared/styles';

export const EmailWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: -${theme.spacings.large};
  `}
`;

export const Email = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grayNeutralDark};
    margin-right: ${theme.spacings.medium};
  `}
`;
