import styled, { css } from 'styled-components';
import { Button, Text } from 'components';
export const Container = styled.div`
  padding: 25px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const SelectContainer = styled.div`
  margin-top: 20px;
  max-width: 450px;
`;

export const Body = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacings.large};
  `}
`;

export const Btn = styled(Button)`
  margin: 0;
  max-width: 200px;
`;

export const Paragraph = styled(Text)`
  ${({ theme }) => css`
    line-height: ${theme.spacings.medium};
    margin-bottom: ${theme.spacings.large};
  `}
`;

export const ImgWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => css`
    gap: ${theme.spacings.large};
    margin-bottom: ${theme.spacings.large};
  `}
`;

export const ImgCenter = styled.div`
  text-align: center;
`;

export const List = styled.ul`
  list-style: disc;
  ${({ theme }) => css`
    margin-left: ${theme.spacings.medium};
    margin-bottom: ${theme.spacings.large};
  `}
`;

export const Title = styled(Text)`
  ${({ theme }) => css`
    margin-left: ${theme.spacings.medium};
    line-height: ${theme.spacings.large};
    margin-bottom: ${theme.spacings.medium};
  `}
`;

export const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.blue};
  `}
`;
