import MaterialECommerce1 from 'assets/images/sell/material-ecommerce-1.png';
import MaterialECommerce2 from 'assets/images/sell/material-ecommerce-2.png';
import MaterialStore1 from 'assets/images/sell/material-store-1.png';
import MaterialStore2 from 'assets/images/sell/material-store-2.png';
import NotebookImage from 'assets/images/sell/notebook.png';
import TicketImage from 'assets/images/sell/ticket.png';

export const materialsData = {
  store: {
    mainTitle: {
      text: 'Mostre que sua',
      blueText: 'loja aceita Ame'
    },
    title: 'Baixando e imprimindo os materiais',
    card1: {
      title: 'Material de exposição',
      description: 'Baixe aqui o material e o guia de impressão',
      link: 'https://images.amedigital.com/pdf/material_exposicao.zip',
      image: MaterialStore1
    },
    card2: {
      title: 'Aplique seus impressos',
      description: 'Dicas para aplicar os materiais de exposição',
      link: 'https://images.amedigital.com/pdf/manual_impressao.zip',
      image: MaterialStore2
    },
    card3: {
      image: TicketImage,
      texts: [
        'Baixe os materiais;',
        'Envie para uma gráfica junto com o guia de impressão;',
        'Siga o guia de aplicação e mostre que você é parceiro da Ame!'
      ]
    }
  },
  eCommerce: {
    mainTitle: {
      text: 'Mostre que seu',
      blueText: 'site aceita Ame'
    },
    title: 'Baixando e aplicando os materiais',
    card1: {
      title: 'Material de comunicação',
      description: 'Tudo o que precisa para pintar seu site de rosa',
      link: 'https://images.amedigital.com/pdf/Midiakit-Digital-Ame.zip',
      image: MaterialECommerce1
    },
    card2: {
      title: 'Aprenda a aplicar o material',
      description: 'Dicas para colocar os banners no seu site',
      link: 'https://images.amedigital.com/pdf/Midiakit-Digital-Ame.pdf',
      image: MaterialECommerce2
    },
    card3: {
      image: NotebookImage,
      texts: [
        'Baixe os materiais;',
        'Siga nossas dicas do guia de aplicação e mostre que seu site é parceiro da Ame!'
      ]
    }
  }
};
