import { useState, useMemo } from 'react';

import { Popover } from 'components';

import useRequest from 'hooks/useRequest';
import useToggle from 'hooks/useToggle';

import { cnpjMask } from 'utils/masks';
import { copyFromListToClipBoard } from 'utils/copyToClipboard';

import { useAuth } from 'contexts/Auth';

import endpoints from 'services/endpoints';
import {
  CredentialList,
  disableCredentials,
  getCredentialList
} from 'services/credentialsService';

import {
  Flex,
  Text,
  InputSearch,
  Table,
  Toggle,
  Breadcrumb,
  ModalStatus,
  Loading
} from 'components';
import { ModalChange } from 'components/Modals';

import PATHS_ROUTES from 'routes/paths';

import * as S from './styles';
import SlideCard from './SliderCard';
import { Row, Column } from 'styles/shared/Grid';
import Margin from 'styles/shared/Margin';
import Divider from 'styles/shared/Divider';

import IconArrowDownBlue from 'assets/images/icon-arrow-down-blue.svg';
import { ReactComponent as IconCopy } from 'assets/images/icon-copy.svg';

type AliasGroup = {
  [key: string]: CredentialList[];
};

type Ordenation = {
  order: 'cresc' | 'decresc';
  field: keyof CredentialList;
};

type Filters = {
  search: string;
  type: {
    label: string;
    value: string | number;
  };
};

// const saleChannelLabels = {
//   DEFAULT: 'Físico',
//   DIGITAL: 'Ecommerce',
//   INAPP: 'Miniapp'
// };

const typeLabels = {
  TRANSACTIONS: 'Transacional',
  CONCILIATIONS: 'Conciliação'
};

const typeOptions = [
  {
    label: 'Tipo',
    value: 1
  },
  ...Object.entries(typeLabels).map(entries => ({
    value: entries[0],
    label: entries[1]
  }))
];

function Integration() {
  const { user } = useAuth();
  const modalDisableAll = useToggle();
  const modalDisableOne = useToggle();
  const modalDisableError = useToggle();
  const [selectedCredentials, setSelectedCredentials] = useState<string[]>([]);
  const [filters, setFilters] = useState<Filters>({
    type: typeOptions[0],
    search: ''
  });
  const [copied, setCopied] = useState('');
  const [headerOrder, setHeaderOrder] = useState<Ordenation>({
    order: 'decresc',
    field: 'alias'
  });
  const [loadingDisable, setLoadingDisable] = useState(false);

  const {
    data,
    loading: loadingList,
    mutate: mutateList
  } = useRequest<CredentialList[]>({
    url: endpoints.credentialList
  });

  const credentials = useMemo(() => {
    if (!data) return data;

    const filteredData = data.filter(item => {
      const type = filters.type?.value;

      const filterFields = [item.alias];
      // if (item.platformName) filterFields.push(item.platformName);
      // if (item.disabledBy) filterFields.push(item.disabledBy);
      if (user?.documentNumber) filterFields.push(user?.documentNumber);

      const findFilter = filterFields.find(f =>
        f.toLowerCase().includes(filters.search.toLowerCase())
      );

      return (type === 1 || item.type === type) && findFilter;
    });

    if (headerOrder) {
      const orderedData = filteredData.sort((a, b) => {
        if ((a[headerOrder.field] || '') > (b[headerOrder.field] || '')) {
          if (headerOrder.order === 'cresc') {
            return 1;
          } else {
            return -1;
          }
        } else if (
          (b[headerOrder.field] || '') > (a[headerOrder.field] || '')
        ) {
          if (headerOrder.order === 'cresc') {
            return -1;
          } else {
            return 1;
          }
        }
        return 0;
      });
      return orderedData;
    } else {
      return filteredData;
    }
  }, [data, filters, user, headerOrder]);

  const activeCredentials = useMemo(() => {
    return credentials?.filter(credential => credential.active);
  }, [credentials]);

  const handleDisableAll = async () => {
    setLoadingDisable(true);
    try {
      await disableCredentials(selectedCredentials);
      setSelectedCredentials([]);
      const newList = await getCredentialList();
      mutateList(newList.data);
      modalDisableAll.toggle();
    } catch (e) {
      modalDisableError.toggle();
    } finally {
      setLoadingDisable(false);
    }
  };

  const handleDisableOne = async () => {
    setLoadingDisable(true);
    try {
      await disableCredentials(selectedCredentials);
      setSelectedCredentials([]);
      const newList = await getCredentialList();
      mutateList(newList.data);
      modalDisableOne.toggle();
    } catch (e) {
      modalDisableError.toggle();
    } finally {
      setLoadingDisable(false);
    }
  };

  const handleCheckAll = () => {
    if (activeCredentials && activeCredentials.length > 0) {
      setSelectedCredentials(activeCredentials.map(c => c.clientId));
      modalDisableAll.toggle();
    }
  };

  const handleHeaderOrder = (field: keyof CredentialList) => {
    setHeaderOrder({
      order: headerOrder?.order === 'cresc' ? 'decresc' : 'cresc',
      field
    });
  };

  const credentialsList = useMemo(() => {
    const handleCheckOne = (clientId: string) => {
      setSelectedCredentials([clientId]);
      modalDisableOne.toggle();
    };

    const aliasGroup: AliasGroup = {};

    credentials?.forEach(credential => {
      aliasGroup[credential.alias]
        ? aliasGroup[credential.alias].push(credential)
        : (aliasGroup[credential.alias] = [credential]);
    });

    const aliasRendered: string[] = [];

    return credentials?.map(credential => {
      const aliasColor = aliasGroup[credential.alias].find(c => c.active)
        ? 'grayBlue'
        : 'grayDark';
      const rowColor = credential.active ? 'grayBlue' : 'grayDark';
      const children = (
        <>
          {!aliasRendered.includes(credential.alias) && (
            <td
              style={{ verticalAlign: 'top' }}
              rowSpan={aliasGroup[credential.alias].length}
            >
              <Text color={aliasColor} weight="bold">
                {credential.alias}
              </Text>
              <Text weight="regular" color="grayDark" size="xsmall">
                CNPJ:{' '}
                {user && user.documentNumber && cnpjMask(user.documentNumber)}
              </Text>
            </td>
          )}
          <td>
            <Text color={rowColor}>{typeLabels[credential.type]}</Text>
          </td>
          <td>
            <Text color={rowColor}>{credential.platformName || ''}</Text>
          </td>
          <td>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Text
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                maxWidth="9rem"
                color={rowColor}
              >
                {credential.clientId}
              </Text>
              {credential.active && (
                <Popover
                  direction="bottom"
                  text="Chave Copiada!"
                  show={copied === credential.clientId}
                >
                  <IconCopy
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      copyFromListToClipBoard(setCopied, credential.clientId)
                    }
                  />
                </Popover>
              )}
            </div>
          </td>
          <td>
            <Flex justifyContent="end">
              {credential.active && (
                <Toggle
                  isChecked={selectedCredentials.includes(credential.clientId)}
                  onCheck={() => handleCheckOne(credential.clientId)}
                />
              )}
            </Flex>
          </td>
        </>
      );
      aliasRendered.push(credential.alias);
      return <S.TableRow key={credential.clientId}>{children}</S.TableRow>;
    });
  }, [credentials, modalDisableOne, selectedCredentials, user, copied]);

  const tableHeader = [
    <S.FilterClick key={1} alignItems="center" wrap="nowrap">
      <S.FlexWrapper onClick={() => handleHeaderOrder('alias')}>
        <Margin mr="small">
          <Text weight="bold">Conta</Text>
        </Margin>
        {headerOrder?.field === 'alias' && (
          <S.OrderIcon
            src={IconArrowDownBlue}
            isCresc={headerOrder?.order === 'cresc'}
          />
        )}
      </S.FlexWrapper>
    </S.FilterClick>,
    <S.FilterClick key={2} alignItems="center" wrap="nowrap">
      <Margin mr="small">
        <Text weight="bold">Tipo</Text>
      </Margin>
    </S.FilterClick>,
    <S.FilterClick key={2} alignItems="center" wrap="nowrap">
      <Margin mr="small">
        <Text weight="bold">Plataforma</Text>
      </Margin>
    </S.FilterClick>,
    <S.FilterClick key={3} alignItems="center" wrap="nowrap">
      <Margin mr="small">
        <Text weight="bold">Application key</Text>
      </Margin>
    </S.FilterClick>,
    <Flex key={4} justifyContent="end" alignItems="center" wrap="nowrap">
      <Margin mr="small">
        <Text weight="bold">Desativar todas</Text>
      </Margin>
      <Toggle
        disabled={activeCredentials?.length === 0}
        isChecked={
          selectedCredentials.length === activeCredentials?.length &&
          activeCredentials?.length > 0
        }
        onCheck={handleCheckAll}
      />
    </Flex>
  ];

  return (
    <div>
      <Margin mb="medium">
        <Breadcrumb
          links={[
            { title: 'Início', link: PATHS_ROUTES.APP.DASHBOARD.DEFAULT },
            { title: 'Integração', link: '#' }
          ]}
        />
      </Margin>

      <Row flexDirectionXs="column" flexDirectionSm="row">
        <Column xs={12}>
          <SlideCard />
        </Column>
      </Row>

      <S.TableCard>
        <S.TableHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Text tag="h2" size="large" weight="bold">
              Credenciais geradas
            </Text>

            <S.HeaderInputs justifyContent="end" alignItems="center">
              <InputSearch
                name="search"
                placeholder="Pesquisar conta ou CNPJ"
                value={filters.search}
                onChange={event =>
                  setFilters(filters => ({
                    ...filters,
                    search: event.target.value
                  }))
                }
              />

              {/* <S.FilterDropdown
                name="filter"
                noBorder
                options={typeOptions}
                value={typeOptions.find(
                  option => option.value === filters.type.value
                )}
                onChange={type =>
                  setFilters(filters => ({
                    ...filters,
                    type: type
                  }))
                }
                withMargin={false}
              /> */}
            </S.HeaderInputs>
          </Flex>
        </S.TableHeader>

        <Divider withBorder />
        {loadingList ? (
          <Loading />
        ) : (
          <Table theadData={tableHeader} bordered size="medium">
            {credentialsList}
          </Table>
        )}
      </S.TableCard>
      {modalDisableAll.isActive && (
        <ModalChange
          title="Desativar todas as credenciais"
          warning="Essa ação não pode ser desfeita. Se você desativá-las, terá de gerar
          novas credenciais."
          onClose={() => {
            setSelectedCredentials([]);
            modalDisableAll.toggle();
          }}
          cancelAction={() => {
            setSelectedCredentials([]);
            modalDisableAll.toggle();
          }}
          buttonText="Desativar todas"
          buttonAction={handleDisableAll}
          loadingRequest={loadingDisable}
        >
          <Text size="large" color="grayDark">
            As credenciais podem estar integradas em algum sistema, você quer
            mesmo desativar?
          </Text>
        </ModalChange>
      )}
      {modalDisableOne.isActive && (
        <ModalChange
          title="Desativar credencial"
          warning="Essa ação não pode ser desfeita. Se você desativá-la, terá de gerar
          uma nova credencial."
          onClose={() => {
            setSelectedCredentials([]);
            modalDisableOne.toggle();
          }}
          cancelAction={() => {
            setSelectedCredentials([]);
            modalDisableOne.toggle();
          }}
          buttonText="Desativar"
          buttonAction={handleDisableOne}
          loadingRequest={loadingDisable}
        >
          <Text size="large" color="grayDark">
            A credencial pode estar integrada em algum sistema, você quer mesmo
            desativá-la?
          </Text>
        </ModalChange>
      )}
      {modalDisableError.isActive && (
        <ModalStatus
          variant="error"
          title="Desativar credenciais"
          onClose={modalDisableError.toggle}
          action={{ onClick: modalDisableError.toggle }}
        >
          <Text size="small" color="grayDark">
            Ocorreu um erro ao desativar uma ou mais credenciais
          </Text>
        </ModalStatus>
      )}
    </div>
  );
}

export default Integration;
