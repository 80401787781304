import endpoints from './endpoints.json';
import api from '../api';
import * as types from './types';

export async function getBankAccounts() {
  const result = await api.get<types.IBankAccountData>(endpoints.bankAccount);

  return result.data;
}

export async function setCashOut(accountId: string, amount: number) {
  const url = endpoints.bankCashOut.replace('{accountId}', accountId);

  const result = await api.put(url, { amount });

  return result.data;
}
