import {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo
} from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'contexts/Auth';
import useMutate from 'hooks/useMutate';
import useToggle from 'hooks/useToggle';
import PATHS_ROUTES from 'routes/paths';
import endpoints from 'services/endpoints';

import * as types from './types';
import { getPaginationItems } from './utils';

const CredentialsContext = createContext<types.ContextData | null>(null);

function CredentialsProvider({ children }: types.ProviderData) {
  const navigate = useNavigate();
  const { state, mutate, clearError } = useMutate();
  const { user } = useAuth();
  const modal = useToggle();
  const [data, setData] = useState<types.CredentialsData>({
    conciliationType: ''
  });

  const setConciliatonType = (value: 'TRANSACTIONAL' | 'CONCILIATION') => {
    setData(prevState => ({ ...prevState, conciliationType: value }));
  };

  const setPlataform = (platform: types.Platform) => {
    setData(prevState => ({ ...prevState, platform: platform }));
  };

  const [pageStep, setPageStep] = useState(0);

  const isTransactional = useMemo(
    () => data.conciliationType === 'TRANSACTIONAL',
    [data.conciliationType]
  );

  const pages = useMemo(
    () => getPaginationItems({ isTransactional, pageStep: pageStep }),
    [isTransactional, pageStep]
  );

  const pageLabels = useMemo(() => {
    return pages.map(item => item.label);
  }, [pages]);

  const isLastStep = useMemo(() => {
    if (isTransactional) {
      if (pageStep === 2) return true;
    } else if (pageStep === 1) {
      return true;
    }

    return false;
  }, [isTransactional, pageStep]);

  const handleCreateCredential = useCallback(async () => {
    clearError();
    const body = {
      saleChannel: user?.saleChannel || 'DEFAULT',
      type: isTransactional ? 'TRANSACTIONS' : 'CONCILIATIONS',
      platformName: isTransactional ? data.platform?.value : undefined,
      documentNumber: user?.groupDocument
    };

    mutate({
      endpoint: endpoints.credentialList,
      body: body,
      method: 'post',
      errorMessage: 'Erro ao criar chaves de integração'
    });
  }, [clearError, data.platform?.value, isTransactional, mutate, user]);

  const nextPage = useCallback(() => {
    if (pageStep < pages.length - 1) setPageStep(pageStep + 1);

    if (isTransactional && pageStep === 1) handleCreateCredential();
    else if (!isTransactional && pageStep === 0) handleCreateCredential();

    if (isLastStep && state.data) {
      modal.toggle();
    }
  }, [
    handleCreateCredential,
    isLastStep,
    isTransactional,
    modal,
    pageStep,
    pages.length,
    state.data
  ]);

  const previousPage = useCallback(() => {
    if (pageStep > 0) {
      setPageStep(pageStep - 1);
    } else {
      navigate(PATHS_ROUTES.APP.INTEGRATION.DEFAULT);
    }
  }, [pageStep, navigate]);

  const nextButtonAvailable = useMemo(() => {
    if (state.loading) return false;

    if (pageStep === 0 && data.conciliationType !== '') return true;
    if (pageStep === 1 && !isTransactional && !state.error) return true;
    if (pageStep === 1 && isTransactional && data.platform) return true;
    if (pageStep === 2 && !state.error) return true;

    return false;
  }, [data, isTransactional, pageStep, state]);

  return (
    <CredentialsContext.Provider
      value={{
        data,
        page: pages[pageStep],
        nextPage,
        previousPage,
        pageLabels,
        nextButtonAvailable,
        setConciliatonType,
        setPlataform,
        isLastStep,
        credential: { ...state },
        modal
      }}
    >
      {children}
    </CredentialsContext.Provider>
  );
}

function useCredentials(): types.ContextData {
  const context = useContext(CredentialsContext);

  if (!context)
    throw new Error(
      'useCredentials must be used within an CredentialsProvider'
    );

  return context;
}

export { useCredentials, CredentialsProvider };
