import * as S from './styles';
import Margin from 'styles/shared/Margin';

const ModalContent = () => {
  return (
    <div>
      <S.FormatText tag="p" color="grayNeutralDark">
        Para efetuar compras parceladas o cliente deve ter cartão autenticado. O
        vendedor por sua vez pode ou não aceitar essa forma de pagamento,
        ativando essa opção na área de ser perfil.
      </S.FormatText>
      <Margin my="medium">
        <S.FormatText tag="p" color="grayNeutralDark">
          As parcelas de uma venda podem corresponder ao valor de no mínimo{' '}
          <S.FormatText tag="strong" weight="bold">
            R$ 49,90
          </S.FormatText>
          . O número de máximo de parcelas é de 12 (doze) vezes sem juros. A
          taxa de operação da venda é paga pelo vendedor.
        </S.FormatText>
      </Margin>

      <S.FormatText tag="p" color="grayNeutralDark">
        O vendedor deverá receber a primeira parcela em 30 (trinta) dias após a
        data em que a venda foi realizada e as demais parcelas nos 30 (trinta)
        dias <br />
        subsequentes. Os valores das taxas correspondem a em 1 (uma) vez, taxa
        de{' '}
        <S.FormatText tag="strong" weight="bold">
          2,49%
        </S.FormatText>{' '}
        / de 2 (duas) à 6 (seis) vezes, taxa de{' '}
        <S.FormatText tag="strong" weight="bold">
          2,89%
        </S.FormatText>{' '}
        e de 7 (sete) à 12 (doze) vezes, taxa de{' '}
        <S.FormatText tag="strong" weight="bold">
          3,19%.
        </S.FormatText>
      </S.FormatText>
    </div>
  );
};

export default ModalContent();
