import { InputHTMLAttributes } from 'react';
import * as S from './styles';

export type CheckboxProps = {
  type?: 'checkbox' | 'radio';
  value?: string;
  checked?: boolean;
  onCheck?: (checked: boolean) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const Checkbox = ({
  type = 'checkbox',
  checked,
  onCheck,
  ...props
}: CheckboxProps) => (
  <S.Wrapper>
    <input
      type={type}
      checked={checked}
      onChange={() => onCheck && onCheck(!checked)}
      {...props}
    />
    <S.Check />
  </S.Wrapper>
);

export default Checkbox;
