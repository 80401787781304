import { ButtonHTMLAttributes, ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';
import { ThemeColors } from 'types/global';

import * as S from './styles';

type ButtonTypes = ButtonHTMLAttributes<HTMLButtonElement> | LinkProps;
export type PositionTypes =
  | 'static'
  | 'sticky'
  | 'relative'
  | 'absolute'
  | 'fixed';

export type ButtonProps = {
  children?: string | ReactNode;
  fullWidth?: boolean;
  center?: boolean;
  secondary?: boolean;
  secondaryColor?: ThemeColors;
  disabled?: boolean;
  icon?: JSX.Element;
  removeChildrenWhenMobile?: boolean;
  fullWidthMobile?: boolean;
  tertiary?: boolean;
  rounded?: boolean;
  filled?: boolean;
  filledBgColor?: ThemeColors;
  filledColor?: ThemeColors;
  noBorder?: boolean;
  large?: boolean;
  position?: PositionTypes;
  bottom?: string;
  width?: string;
  as?: React.ElementType;
} & ButtonTypes;

const Button = ({
  children,
  fullWidth,
  center,
  secondary,
  secondaryColor = 'blue',
  icon,
  removeChildrenWhenMobile,
  fullWidthMobile,
  tertiary,
  rounded,
  filled,
  filledBgColor = 'blue',
  filledColor = 'white',
  noBorder = false,
  large = false,
  position = 'static',
  bottom = '0px',
  width = 'auto',
  ...props
}: ButtonProps) => (
  <S.Wrapper
    fullWidth={fullWidth}
    center={center}
    secondary={secondary}
    secondaryColor={secondaryColor}
    hasIcon={!!icon}
    onlyIcon={!!icon && !children}
    removeChildrenWhenMobile={removeChildrenWhenMobile}
    tertiary={tertiary}
    fullWidthMobile={fullWidthMobile}
    filled={filled}
    filledBgColor={filledBgColor}
    filledColor={filledColor}
    noBorder={noBorder}
    rounded={rounded}
    large={large}
    position={position}
    bottom={bottom}
    width={width}
    data-testid="ame-button"
    {...(props as ButtonHTMLAttributes<HTMLButtonElement>)}
  >
    {icon}
    {!!children && <span>{children}</span>}
  </S.Wrapper>
);

export default Button;
