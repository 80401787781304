import styled, { css } from 'styled-components';

import { Row } from 'styles/shared/Grid';

export const Wrapper = styled(Row)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-around;

    & > div {
      display: flex;
      align-items: center;
      flex-direction: column;

      & > div {
        margin-bottom: ${theme.spacings.small};
      }

      & > svg {
        margin-top: -${theme.spacings.medium};
      }
    }
  `}
`;
