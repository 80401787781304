export const registerKeyErrors = {
  keyNotFound: 'key_not_found',
  keyExists: 'key_exists',
  keyExistsAnotherParticipant: 'key_exists_another_participant',
  keyExistsOwnedByOtherPerson: 'key_exists_owned_by_other_person',
  keyLockedByClaim: 'key_locked_by_claim',

  invalidKeyFormat: 'invalid_key_format',
  invalidKeyOperation: 'invalid_key_operation',
  invalidClaimOperation: 'invalid_claim_operation',
  claimsExists: 'claims_exists',
  claimEntryExists: 'claim_entry_exists'
};

export const defaultFeatureToggle = {
  enabled: false,
  name: 'KEY_OWNERSHIP_VALIDATION'
};
