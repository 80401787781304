import { TemplateModal, Text } from 'components';
import { ReactElement } from 'react';
import Margin from 'styles/shared/Margin';

export type ModalInfoProps = {
  title: string;
  subtitle: string;
  onClose: () => void;
  content: ReactElement;
};

const ModalInfo = ({ onClose, title, subtitle, content }: ModalInfoProps) => (
  <TemplateModal title={title} closeModal={onClose}>
    <Margin my="medium" mx="large">
      <Margin mb="large">
        <Text color="black" weight="bold" tag="h2" size="xlarge">
          {subtitle}
        </Text>
      </Margin>
      {content}
    </Margin>
  </TemplateModal>
);

export default ModalInfo;
