import { useEffect, useState } from 'react';

import { createBrowserHistory } from 'history';
import { BrowserRouter as Router } from 'react-router-dom';

import { useAuth } from 'contexts/Auth';

import { setAnalyticsPageView } from 'utils/analytics';

import AuthRoutes from './auth.routes';
import AppRoutes from './app.routes';

import api from 'services/api';
import { getCSRFToken } from 'services/sessionService';
import { Loading } from 'components';

const history = createBrowserHistory();

history.listen(update => {
  setAnalyticsPageView(update.location);
});

const Routes = () => {
  const { signed } = useAuth();
  const [loading, setLoading] = useState(true);

  const handleGetCSRFToken = async () => {
    setLoading(true);

    try {
      const response = await getCSRFToken();
      api.defaults.headers['X-CSRF-Token'] = response.data.CSRFToken;
    } catch (error) {
      api.defaults.headers['X-CSRF-Token'] = null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCSRFToken();
  }, []);

  return loading ? (
    <Loading fullContent />
  ) : (
    <Router>{signed ? <AppRoutes /> : <AuthRoutes />}</Router>
  );
};

export default Routes;
