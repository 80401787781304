import { TutorialGenerateKeys } from '../components';
import * as S from '../../styles';
const NovaOito = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Nova Oito (YouZoom)
      </S.Title>

      <TutorialGenerateKeys name="Youzoom" />

      <S.Paragraph tag="p" color="grayBlue">
        Na sua conta Nova Oito, no menu lateral, acesse
        <strong> Configurações {'>'} Configurações de pagamento</strong>.
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Na lista de gateways, clique em <strong>Ame Digital</strong>, será
        exibido o formulário para configuração de pagamento.
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Preencha os campos <strong>“Application Key”</strong> e
        <strong> “Application Token”</strong>, referentes ao{' '}
        <strong>Client ID</strong> e <strong>Client Secret </strong>
        respectivamente no site da Nova Oito, com as chaves geradas nas etapas
        acima. Em seguida, clique em <strong>Ativar</strong>.
      </S.Paragraph>
    </div>
  );
};

export default NovaOito;
