import { useMemo } from 'react';

import { Text, Flex } from 'components';
import Divider from 'styles/shared/Divider';
import { formatInput, formatSimpleDate } from 'utils/masks';
import { formatPixKeyType, pixTypes, formatKey, getIconType } from 'utils/pix';

import { ReactComponent as IconTrashBlue } from 'assets/images/icon-trash-blue.svg';
import { ReactComponent as IconUser } from 'assets/images/icon-profile-blue.svg';

import { CardAccordion } from '..';

import * as S from './styles';

export type CardAccordionPixProps = {
  date?: string;
  keyType: pixTypes;
  keyValue: string;
  user?: {
    institution: string;
    name: string;
    document: string;
  };
  expanded?: boolean;
  onClick?: () => void;
  removeAction?: () => void;
};

export const CardAccordionPix = ({
  date,
  keyType,
  keyValue,
  user,
  expanded,
  onClick,
  removeAction
}: CardAccordionPixProps) => {
  const Icon = user ? IconUser : getIconType(keyType);
  const formattedDocument = user
    ? user.document.length <= 11
      ? `***.${formatInput['CPF'](user.document || '').substr(4, 6)}*-**`
      : `**.**${formatInput['CNPJ'](user.document || '').substr(5, 8)}**-**`
    : '';

  const dateUTC = useMemo(() => {
    if (date) {
      const stringDate = new Date(date);
      return new Date(`${stringDate} UTC`);
    }
    return null;
  }, [date]);

  return (
    <CardAccordion
      onClick={onClick}
      expanded={expanded}
      icon={<Icon />}
      text={user ? user.name : formatPixKeyType(keyType)}
    >
      <Divider size="xsmall" />

      <Flex alignItems="center" justifyContent="space-between">
        <div>
          {user && (
            <>
              <Text size="small" weight="bold">
                {formattedDocument}
              </Text>

              <Divider size="xsmall" />

              <Text size="small">{user.institution}</Text>
            </>
          )}

          <Divider size="xsmall" />

          <Text size="small" weight="bold">
            Chave{' '}
            <Text size="small" weight="bold" tag="span" color="blue">
              {formatKey(keyType, keyValue)}
            </Text>
          </Text>

          {dateUTC && (
            <>
              <Divider size="xsmall" />

              <Text size="small">
                Criada em{' '}
                <Text size="small" weight="bold" tag="span">
                  {formatSimpleDate(dateUTC, 'DD/MM/YYYY').toUpperCase()}{' '}
                </Text>
                às{' '}
                <Text size="small" weight="bold" tag="span">
                  {formatSimpleDate(dateUTC, 'HH:mm').toUpperCase()}
                </Text>
              </Text>
            </>
          )}
        </div>

        {!!removeAction && (
          <S.TrashContainer
            onClick={e => {
              e.stopPropagation();

              removeAction();
            }}
            data-testid="button-remove"
          >
            <IconTrashBlue />
          </S.TrashContainer>
        )}
      </Flex>
    </CardAccordion>
  );
};

export default CardAccordionPix;
