import { useMemo } from 'react';
import PATHS_ROUTES from 'routes/paths';
import { Text, TemplateSearchContent } from 'components';
import { Column, Row } from 'styles/shared/Grid';
import { BreadcrumbLink } from 'components/Breadcrumb';
import { ReactComponent as IconAdd } from 'assets/images/icon-add-outline.svg';
import { TemplateSearchContentAction } from 'components/Templates/SearchContent';
import { CardTeam } from '../components';
import teams from '../components/__mocks__/teams';

const links: BreadcrumbLink[] = [
  { title: 'Equipe', link: PATHS_ROUTES.APP.TEAM.DEFAULT },
  { title: 'Times', link: PATHS_ROUTES.APP.TEAM.TEAMS }
];

const actions: TemplateSearchContentAction[] = [
  { text: 'Adicionar', icon: <IconAdd />, to: PATHS_ROUTES.APP.TEAM.ADD.GROUP }
];

const Teams = () => {
  const templateSearchContentProps = useMemo(() => {
    const breadcrumbProps = { links };
    const inputSearchProps = { placeholder: 'Pesquise por nome' };

    return { breadcrumbProps, inputSearchProps };
  }, []);

  return (
    <TemplateSearchContent actions={actions} {...templateSearchContentProps}>
      <Row>
        <Column md={12}>
          <Text tag="span" size="small" color="grayDark" weight="regular">
            Esses são todos os times vinculados a essa conta
          </Text>
        </Column>
      </Row>

      <Row>
        {teams.map(({ id, title, ...rest }) => (
          <Column key={id} xs={12} md={6}>
            <CardTeam name={title} {...rest} />
          </Column>
        ))}
      </Row>
    </TemplateSearchContent>
  );
};

export default Teams;
