import { TutorialRequestKey } from '../components';
import * as S from '../../styles';

const Opencart = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Opencart (CodeMarket)
      </S.Title>

      <TutorialRequestKey name="codemarket" showConfigurationMessage={false} />

      <S.Paragraph tag="p" color="grayBlue">
        Recebeu sua chave? Ótimo! Agora vamos seguir na plataforma Opencart.
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Para configurar a Ame na sua plataforma, é necessário baixar o módulo da
        Ame através da página{' '}
        <S.Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.codemarket.com.br/produto/pagamento-ame-digital-oficial-opencart"
        >
          Pagamento Ame Digital Oficial Opencart - CodeMarket
        </S.Link>
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Após baixado e instalado poderá optar por fazer a configuração ou pedir
        ajuda para o suporte do time CodeMarket, através do site que baixou o
        módulo.
      </S.Paragraph>
    </div>
  );
};

export default Opencart;
