import { ReactNode } from 'react';

import { Button, TemplateModal } from 'components';

import * as S from './styles';

import iconAlert from 'assets/images/icon-alert.svg';
import Divider from 'styles/shared/Divider';

export type ModalAlertProps = {
  children: string | ReactNode;
  title: string;
  buttonAction: () => void;
  buttonText: string;
  onClose: () => void;
};

const ModalAlert = ({
  children,
  title,
  buttonAction,
  buttonText,
  onClose
}: ModalAlertProps) => (
  <TemplateModal title={title} closeModal={onClose}>
    <S.Wrapper>
      {children}
      <img src={iconAlert} alt="Alert" />
      <Divider />
      <Button center filled large onClick={buttonAction}>
        {buttonText}
      </Button>
    </S.Wrapper>
  </TemplateModal>
);

export default ModalAlert;
