import { datadogRum } from '@datadog/browser-rum';

const environment = process.env.REACT_APP_AME_ENV || 'prod';

export const initializeDatadog = () => {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID || '',
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_ID || '',
    site: 'datadoghq.com',
    service: 'ame-sua-conta',
    env: environment,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogRum.startSessionReplayRecording();
};
