import styled, { css } from 'styled-components';

type WrapperProps = {
  showBorder: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, showBorder }) => css`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;

    ${showBorder &&
    css`
      border-bottom: 0.1rem solid ${theme.colors.grayExtraMedium};
    `}

    svg {
      width: 3.3rem;
      height: 3.3rem;
      margin-right: 1rem;
    }
  `};
`;

export const Texts = styled.div`
  margin-right: 2rem;
`;
