import { useState, useMemo, useCallback, useEffect } from 'react';

import * as S from './styles';

import { Text, Toggle, Button } from 'components';

import { Permissions } from 'services/memberService/types';

import Divider from 'styles/shared/Divider';
import { Row, Column } from 'styles/shared/Grid';

export type PermissionSectionProps = {
  title: string;
  permissions: Permissions[];
  initialPermissions?: string[];
  onSelect: (permissions: string[]) => void;
};

const PermissionSection = ({
  title,
  permissions,
  initialPermissions = [],
  onSelect
}: PermissionSectionProps) => {
  const [permissionsSelected, setPermissionsSelected] =
    useState(initialPermissions);

  const hasSomePermission = useMemo(
    () =>
      permissions.find(p =>
        permissionsSelected.find((pe: string) => pe === p.id)
      ),
    [permissionsSelected, permissions]
  );

  const handleTogglePermission = useCallback(
    (checked: boolean) => {
      let updatedArray: string[] = [];

      const storage = localStorage.getItem('updateArray');
      const previousSelections: string[] = storage
        ? JSON.parse(storage)
        : permissionsSelected;

      if (checked) {
        updatedArray = [...previousSelections, ...permissions.map(p => p.id)];
        localStorage.setItem('updateArray', JSON.stringify(updatedArray));
      } else {
        const permissionIds = permissions.map(p => p.id);
        updatedArray = previousSelections.filter(
          p => !permissionIds.includes(p)
        );
        localStorage.setItem('updateArray', JSON.stringify(updatedArray));
      }

      setPermissionsSelected(updatedArray);

      !!onSelect && onSelect(updatedArray);
    },
    [permissions, permissionsSelected, onSelect]
  );

  const handleSelectPermission = useCallback(
    (permission: string) => {
      const hasPermission = permissionsSelected.includes(permission);

      const storage = localStorage.getItem('updateArray');
      const previousSelections: string[] = storage
        ? JSON.parse(storage)
        : permissionsSelected;

      let updatedArray;

      if (hasPermission) {
        updatedArray = previousSelections.filter(p => p !== permission);
      } else {
        updatedArray = [...previousSelections, permission];
      }

      localStorage.setItem('updateArray', JSON.stringify(updatedArray));

      setPermissionsSelected(updatedArray);

      !!onSelect && onSelect(updatedArray);
    },
    [onSelect, permissionsSelected]
  );

  useEffect(() => {
    return () => localStorage.removeItem('updateArray');
  }, []);

  return (
    <S.Wrapper>
      <Row noGutters justifyContentXs="space-between" alignItemsXs="center">
        <Text size="small" weight="medium">
          {title}
        </Text>
        <Toggle
          isChecked={!!hasSomePermission}
          onCheck={handleTogglePermission}
        />
      </Row>
      <Divider size="small" />
      <Row>
        {permissions.map(permission => {
          const isSelected = permissionsSelected.includes(permission.id);

          return (
            <Column xs={4} key={permission.id}>
              <Button
                secondary
                fullWidth
                filled={isSelected}
                onClick={() => handleSelectPermission(permission.id)}
              >
                {permission.name}
              </Button>
            </Column>
          );
        })}
      </Row>
      <Divider size="medium" />
      <Divider withBorder />
    </S.Wrapper>
  );
};

export default PermissionSection;
