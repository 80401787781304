import { ReportTypes, REPORT_STATUS } from 'utils/report';
import { FilterReportItems } from './types';

export const filterItems: FilterReportItems[] = [
  {
    text: 'Período',
    type: 'period',
    cards: [
      {
        value: '1',
        text: (
          <>
            <p>Dia</p>
            <p>anterior</p>
          </>
        )
      },
      {
        value: '7',
        text: (
          <>
            <p>Últimos</p>
            <p>7 dias</p>
          </>
        )
      },
      {
        value: '15',
        text: (
          <>
            <p>Últimos</p>
            <p>15 dias</p>
          </>
        )
      },
      {
        value: '30',
        text: (
          <>
            <p>Últimos</p>
            <p>30 dias</p>
          </>
        )
      }
    ]
  },
  {
    text: 'Status',
    type: 'status',
    cards: [
      {
        text: 'Solicitado',
        value: REPORT_STATUS.REQUESTED
      },
      {
        text: 'Concluído',
        value: REPORT_STATUS.PROCESSED
      },
      {
        text: 'Erro',
        value: REPORT_STATUS.ERROR
      }
    ]
  },
  {
    text: 'Tipo',
    type: 'typeFiles',
    cards: [
      {
        text: 'Vendas',
        value: ReportTypes.SALES
      },
      {
        text: 'Liquidações',
        value: ReportTypes.FINANCIAL
      },
      {
        text: 'Estornos',
        value: ReportTypes.REFUND
      },
      {
        text: 'Retiradas',
        value: ReportTypes.CASHOUT
      }
    ]
  }
];
