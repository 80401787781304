import { AxiosResponse } from 'axios';
import CountryCodes from 'assets/json/countryCodes.json';

export const DAY_OF_WEEK = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

export const DEFAULT_RESPONSE_SUCCESS = {
  status: 200,
  statusText: 'OK',
  config: {},
  headers: {},
  data: {}
};

export const DEFAULT_RESPONSE_ERROR = {
  status: 403,
  statusText: '',
  config: {},
  headers: {},
  data: {
    response: {
      data: {
        error: {
          error: '',
          error_description: ''
        }
      }
    }
  }
};

export const responseSuccess: AxiosResponse = {
  ...DEFAULT_RESPONSE_SUCCESS,
  data: {}
};

export type CountryCodesT = {
  name: string;
  code: string;
  iso: string;
  mask: string | string[];
};

export const COUNTRY_CODES: CountryCodesT[] = [...CountryCodes];
