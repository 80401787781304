import { Text, Select, Loading } from 'components';
import Margin from 'styles/shared/Margin';

import useWindowSize from 'hooks/useWindowSize';
import { useCredentials } from 'contexts/Credentials';
import { usePlatforms } from 'hooks/usePlatforms';

const Plataforms = () => {
  const [width] = useWindowSize();
  const { data, setPlataform } = useCredentials();
  const { data: platforms, loading } = usePlatforms();

  return (
    <Margin mt="medium" mb="large" mx={width < 768 ? 'xsmall' : 'xlarge'}>
      {loading && <Loading fullContent fullWidth />}

      <Text tag="h3" size="large" weight="medium">
        Plataforma de integração
      </Text>

      <Margin mt="large">
        <Select
          name="credentials-platformId"
          label="Selecione a plataforma de e-commerce que usa em sua loja"
          options={platforms}
          onChange={option => {
            const plataform = {
              label: option.label,
              value: `${option.value}`
            };
            setPlataform(plataform);
          }}
          value={data.platform}
        />
      </Margin>
    </Margin>
  );
};

export default Plataforms;
