import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import { ReactComponent as IconBank } from 'assets/images/icon-bank-blue.svg';
import {
  Breadcrumb,
  Button,
  CardInfo,
  Flex,
  Input,
  SegmentControl,
  SelectField,
  TemplateShortContent,
  Text
} from 'components';

import Divider from 'styles/shared/Divider';
import { Column } from 'styles/shared/Grid';
import { useAuth } from 'contexts/Auth';
import { ISetPaymentPixBody } from 'services/pixService/types';
import { useBanks } from 'pages/Bank/AccountRegistration/useBanks';
import { usePixOutFlux } from 'contexts/Pix/PixOutFlux';

import { breadcrumb, accountTypes } from './constants';
import { validationSchema } from './validations';

import TransferValue from '../Value';
import TransferConfirmation from '../Confirmation';

const TransferBank = () => {
  const banks = useBanks();
  const { user } = useAuth();
  const [step, setStep] = useState(0);
  const [page, setPage] = useState(0);

  const { setRecipientBankData, setRecipientData } = usePixOutFlux();

  const initialValues = {
    key: '',
    ispb: '',
    institutionName: '',
    accountNumber: '',
    agencyNumber: '',
    documentNumber: user?.documentNumber,
    name: user?.name,
    description: '',
    endToEndId: '',
    accountType: '',
    holderType: ''
  } as ISetPaymentPixBody;

  const handleSubmit = React.useCallback(
    (values: ISetPaymentPixBody) => {
      const accountNumber = values.accountNumber || '';
      values.accountNumber = accountNumber.replace('-', '');

      setRecipientData(null);
      setRecipientBankData(values);
      setPage(1);
    },
    [setRecipientBankData, setRecipientData]
  );

  return (
    <>
      {page === 1 ? (
        <TransferValue
          nextPage={() => setPage(2)}
          previousPage={() => setPage(0)}
        />
      ) : page === 2 ? (
        <TransferConfirmation previousPage={() => setPage(1)} />
      ) : (
        <>
          <Breadcrumb links={breadcrumb} />

          <Column offsetMd={2} sm={9} md={8}>
            <Text
              tag="h1"
              size="xxmedium"
              weight="medium"
              color="grayBlue"
              mt="large"
            >
              Transferência por agência e conta bancária
            </Text>

            <Text tag="h2" color="grayNeutralDark" size="medium" mb="medium">
              Busque os dados da conta de quem vai receber
            </Text>
          </Column>

          <TemplateShortContent>
            <Formik
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              initialValues={initialValues}
              validateOnMount={true}
            >
              {({ values, handleChange, setFieldValue, errors }) => (
                <Form>
                  {step === 0 && (
                    <>
                      <CardInfo
                        icon={<IconBank />}
                        text="Agência e conta"
                        large
                      />

                      <Divider />

                      <SelectField
                        data-testid="select-institution"
                        options={banks}
                        name="ispb"
                        label="Instituição"
                        placeholder="Buscar banco ou instituição financeira"
                        onChange={data => {
                          setFieldValue('ispb', data?.value.toString());
                          setFieldValue('institutionName', data?.label);
                          setStep(1);
                        }}
                      />
                    </>
                  )}

                  {step === 1 && (
                    <>
                      <SegmentControl
                        small
                        items={[
                          {
                            label: 'Mesma Titularidade',
                            active:
                              values.documentNumber === user?.documentNumber,
                            action: () => {
                              setFieldValue('name', user?.name);
                              setFieldValue(
                                'documentNumber',
                                user?.documentNumber
                              );
                            }
                          },
                          {
                            label: 'Outra Titularidade',
                            active:
                              values.documentNumber !== user?.documentNumber,
                            action: () => {
                              setFieldValue('name', '');
                              setFieldValue('documentNumber', '');
                            }
                          }
                        ]}
                      />

                      <Divider size="large" />

                      <Input
                        onlyNumbers
                        label="Agência - sem dígito verificador"
                        placeholder="Digite o número da agência"
                        name="agencyNumber"
                        value={values.agencyNumber}
                        onChange={handleChange}
                      />

                      <Divider size="small" />

                      <Input
                        onlyNumbers
                        maskType="ACCOUNT"
                        label="Conta - com dígito verificador"
                        placeholder="Digite o número da conta"
                        alertMessage="Se o dígito verificador for letra, substituir por 0"
                        name="accountNumber"
                        value={values.accountNumber}
                        onChange={handleChange}
                      />

                      <Divider size="small" />

                      <SelectField
                        options={accountTypes}
                        name="accountType"
                        label="Qual seu tipo de conta?"
                        placeholder="Escolha o tipo de conta"
                        onChange={() => setStep(1)}
                      />

                      {values.documentNumber !== user?.documentNumber && (
                        <>
                          <Divider size="small" />

                          <Input
                            label="Nome"
                            placeholder="Digite o nome completo de quem vai receber"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />

                          <Divider size="small" />

                          <Input
                            label="CPF ou CNPJ"
                            placeholder="Digite o CPF ou CNPJ de quem vai receber"
                            name="documentNumber"
                            value={values.documentNumber}
                            onChange={handleChange}
                            maskType="DOCUMENT"
                          />
                        </>
                      )}
                    </>
                  )}

                  <Flex direction="column" mx="large" mt="large">
                    <Button disabled={Object.keys(errors).length > 0}>
                      Continuar para valor
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>

            <Flex direction="column" mx="large">
              <Button tertiary onClick={() => setStep(0)}>
                Voltar
              </Button>
            </Flex>
          </TemplateShortContent>
        </>
      )}
    </>
  );
};

export default TransferBank;
