import * as S from '../../styles';

type TutorialRequestKeyType = {
  name: string;
  showConfigurationMessage?: boolean;
};

const TutorialRequestKey = ({
  name,
  showConfigurationMessage = true
}: TutorialRequestKeyType) => {
  return (
    <>
      <S.Paragraph tag="p" color="grayBlue">
        Para configurar a Ame como forma de pagamento em seu e-commerce, você
        irá precisar da sua <strong>chave de integração</strong>.
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        É bem simples para solicitar sua chave. Basta mandar um email para
        <strong> support@devamedigital.zendesk.com</strong> informando os
        seguintes dados:
      </S.Paragraph>

      <S.List>
        <li>CNPJ da empresa</li>
        <li>Nome fantasia</li>
        <li>Plataforma utilizada (exemplo: {name})</li>
        <li>Site do ecommerce</li>
      </S.List>

      <S.Paragraph tag="p" color="grayBlue">
        O prazo para recebimento de sua chave é de até 3 dias.
      </S.Paragraph>

      {showConfigurationMessage && (
        <S.Paragraph tag="p" color="grayBlue">
          Recebeu sua chave? Ótimo! Agora vamos configurar no seu sistema {name}
          :
        </S.Paragraph>
      )}
    </>
  );
};

export default TutorialRequestKey;
