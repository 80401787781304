import { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Button,
  CardInfo,
  CardShadowDashed,
  Loading,
  ModalStatus,
  Text
} from 'components';

import { ReactComponent as IconBankOutline } from 'assets/images/icon-bank-outline.svg';

import { Row, Column } from 'styles/shared/Grid';
import Divider from 'styles/shared/Divider';

import { useBank } from 'contexts/Bank';

import { maskDocument } from 'utils/masks';

import useRequest from 'hooks/useRequest';

import endpoints from 'services/endpoints';
import { IBankAccountData } from 'services/bankService/types';

import PATHS_ROUTES from 'routes/paths';

type Step1Props = {
  next: () => void;
};

export function Step1({ next }: Step1Props) {
  const { setWithdrawDataValues, withdrawData } = useBank();

  const navigate = useNavigate();

  const {
    data: bankAccount,
    loading,
    error
  } = useRequest<IBankAccountData[]>({
    url: endpoints.bankAccount
  });

  const handleClickButton = useCallback(() => {
    if (!withdrawData.bankData) {
      setWithdrawDataValues({
        bankData: bankAccount && bankAccount[0]
      });
    }
    next();
  }, [bankAccount, next, setWithdrawDataValues, withdrawData.bankData]);

  const account = useMemo(
    () => withdrawData.bankData || (bankAccount && bankAccount[0]),
    [bankAccount, withdrawData.bankData]
  );

  if (loading) return <Loading fullContent />;

  return (
    <>
      <Text size="xxmedium" weight="bold">
        Retirar
      </Text>
      <Text>Selecione a origem e o destinatário da transferência</Text>

      <Divider size="large" />

      <Text size="xsmall" weight="medium">
        Conta Ame ou conta bancária destinatária
      </Text>

      {!bankAccount || !bankAccount.length ? (
        <CardShadowDashed
          rightText="Adicionar"
          text="Nenhuma conta de destino cadastrada"
          icon={<IconBankOutline />}
          action={() => navigate(PATHS_ROUTES.APP.BANK.ACCOUNT_REGISTRATION)}
        />
      ) : (
        <CardInfo
          text={account!.name}
          icon={<img src={account!.image} width="50" />}
          action={() => navigate(PATHS_ROUTES.APP.BANK.SELECT)}
          infoText="Editar"
        >
          <Text color="grayNeutralDark" size="xxsmall">
            CNPJ: {maskDocument(withdrawData?.documentNumber as string, 'CNPJ')}
          </Text>
          <Text color="blueLightest" size="xxsmall">
            Ag: {account!.agency} / Cc: {account!.account}
          </Text>
        </CardInfo>
      )}

      <Divider size="xlarge" />

      <Row>
        <Column xs={12} md={8} offsetMd={2}>
          <Button
            secondary
            disabled={!bankAccount?.length}
            onClick={handleClickButton}
            fullWidth
          >
            Próximo passo
          </Button>
        </Column>
      </Row>

      {error && (
        <ModalStatus
          variant="error"
          title="Erro"
          onClose={() => navigate(PATHS_ROUTES.APP.DASHBOARD.DEFAULT)}
        >
          <Text>Ocorreu um erro inesperado</Text>
        </ModalStatus>
      )}
    </>
  );
}
