import * as React from 'react';
import { SelectOption } from 'components/Select';
import useRequest from 'hooks/useRequest';
import endpoints from 'services/bankService/endpoints.json';
import { IBankingInstitution } from 'services/bankService/types';

export const useBanks = () => {
  const { data } = useRequest<{ banks: IBankingInstitution[] }>({
    url: endpoints.banks,
    options: { revalidateOnFocus: false }
  });

  const banks = React.useMemo<SelectOption[]>(() => {
    if (data) {
      return data.banks.map(({ code, name }) => ({
        value: code,
        label: `${code.toString().padStart(3, '0')} - ${name}`
      }));
    }

    return [];
  }, [data]);

  return banks;
};
