import useRequest from 'hooks/useRequest';
import { useMemo } from 'react';
import endpoints from 'services/endpoints';

interface Segment {
  id: string;
  name: string;
  description: string;
}

export function useSegments(hasSession?: boolean) {
  const { data, ...rest } = useRequest<Segment[]>({
    url: hasSession ? endpoints.accreditationSegments : null
  });

  const segments = useMemo(() => {
    if (!data) return [];

    return data.map(segment => ({
      label: segment.description,
      value: segment.name
    }));
  }, [data]);

  return {
    segments,
    ...rest
  };
}
