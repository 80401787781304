import { ScreenOwnerProvider } from 'contexts/ScreenOwner';
import ContainerPage from './ContainerPage';

function AccountsAffiliate() {
  return (
    <ScreenOwnerProvider>
      <ContainerPage />
    </ScreenOwnerProvider>
  );
}

export default AccountsAffiliate;
