import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Text,
  TemplateCloseContent,
  Button,
  Loading,
  ModalStatus
} from 'components';

import useMutate from 'hooks/useMutate';

import endpoints from 'services/endpoints';

import PATHS_ROUTES from 'routes/paths';

import * as S from './styles';

const AccountEditInstallments = () => {
  const navigate = useNavigate();
  const { state, mutate } = useMutate();

  const { loading, error } = state;

  const handleSelectInstallment = useCallback(
    async (numberOfInstallments: number) => {
      mutate({
        method: 'put',
        body: { numberOfInstallments },
        onSuccess: () => navigate(PATHS_ROUTES.APP.ACCOUNT.PLAN_DATA),
        endpoint: endpoints.setInstallments
      });
    },
    [navigate, mutate]
  );

  if (loading) {
    return <Loading fullContent />;
  }

  return (
    <>
      <TemplateCloseContent
        closeLink={PATHS_ROUTES.APP.ACCOUNT.PLAN_DATA}
        closeTitle="Voltar para os dados do plano"
      >
        <Text tag="h2" size="xxmedium" weight="medium" mb="large">
          Número de parcelas que você vai aceitar
        </Text>

        <ul>
          <S.Installment>
            <Button
              tertiary
              title="À vista"
              onClick={() => handleSelectInstallment(1)}
            >
              <Text color="grayNeutralBrandDarkest">À vista </Text>
            </Button>
          </S.Installment>

          {new Array(11).fill(null).map((_, index) => (
            <S.Installment key={index}>
              <Button
                tertiary
                title={`Em ${index + 2}x`}
                onClick={() => handleSelectInstallment(index + 2)}
              >
                <Text color="grayNeutralBrandDarkest">Em {index + 2}x</Text>
              </Button>
            </S.Installment>
          ))}
        </ul>
      </TemplateCloseContent>

      {error && (
        <ModalStatus
          variant="error"
          title="Erro"
          description="Ocorreu um erro ao salvar o número de parcelas, tente novamente."
          onClose={() => navigate(PATHS_ROUTES.APP.ACCOUNT.PLAN_DATA)}
        />
      )}
    </>
  );
};

export default AccountEditInstallments;
