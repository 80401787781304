import styled, { css } from 'styled-components';

export const Wrapper = styled.label`
  ${({ theme }) => css`
    position: relative;
    display: inline-block;
    width: 4.6rem;
    height: 2.3rem;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + span {
        background-color: ${theme.colors.blue};
      }

      &:focus + span {
        box-shadow: 0 0 1px ${theme.colors.blue};
      }

      &:checked + span:before {
        transform: translateX(2.3rem);
      }
    }

    span {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0.6rem;
      background-color: ${theme.colors.grayMedium};

      &:before {
        position: absolute;
        content: '';
        height: 2.3rem;
        width: 2.3rem;
        background-color: ${theme.colors.white};
        box-shadow: 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0.6rem;
        transform: translateX(0);
        transition: transform 0.2s;
      }
    }
  `}
`;
