import styled, { css } from 'styled-components';

export const ProfilePictureWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > * {
      margin-bottom: ${theme.spacings.small};
    }
  `}
`;

export const StatusWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid ${theme.colors.grayMedium};
    border-bottom: 1px solid ${theme.colors.grayMedium};
    padding: ${theme.spacings.medium} 0;
  `}
`;
