import { useCallback, useMemo } from 'react';

import { Input, Text, Button } from 'components';

import * as S from './styles';
import { checkCellPhoneNumber, checkEmail } from 'utils/validations';
import { Data } from '../../types';

export type Step2Props = {
  initialData: Data;
  setData: (values: Data) => void;
  nextStep: () => void;
};

function Step2({ nextStep, initialData, setData }: Step2Props) {
  const disableButton = useMemo<boolean>(
    () =>
      !checkCellPhoneNumber(
        initialData.phoneRequest.number ? initialData.phoneRequest.number : ''
      ) || !checkEmail(initialData.emailRequest.email || ''),
    [initialData.emailRequest.email, initialData.phoneRequest.number]
  );

  const handleChangePhone = useCallback(
    (event: { target: HTMLInputElement }) =>
      setData({
        ...initialData,
        phoneRequest: {
          phoneType: 'HOME',
          number: event.target.value
        }
      }),
    [initialData, setData]
  );

  const handleChangeEmail = useCallback(
    (event: { target: HTMLInputElement }) =>
      setData({
        ...initialData,
        emailRequest: {
          emailType: 'PRIMARY',
          email: event.target.value
        }
      }),
    [initialData, setData]
  );
  return (
    <S.Wrapper>
      <Text color="grayBlue" size="xxmedium" weight="medium">
        Adicionar membro
      </Text>

      <Text color="grayDark" size="small">
        Preencha agora os dados de contato com esse membro
      </Text>

      <S.FormContainer>
        <Input
          name="phone"
          label="Celular"
          maskType="CELLPHONE"
          value={initialData.phoneRequest.number}
          onChange={handleChangePhone}
        />

        <Input
          name="email"
          label="E-mail"
          value={initialData.emailRequest.email}
          onChange={handleChangeEmail}
          autoComplete="on"
        />
      </S.FormContainer>

      <Button
        secondary
        center
        onClick={nextStep}
        disabled={disableButton}
        large
        title={disableButton ? 'Preencha todos os dados' : ''}
      >
        Prosseguir
      </Button>
    </S.Wrapper>
  );
}

export default Step2;
