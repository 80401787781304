import { TutorialRequestKey } from '../components';
import * as S from '../../styles';
const VNDA = () => {
  return (
    <div>
      <S.Title size="xlarge" weight="bold" tag="h1">
        VNDA
      </S.Title>

      <TutorialRequestKey name="Vnda" />

      <S.Paragraph tag="p" color="grayBlue">
        Na sua conta Vnda, entre no seu Admin em
        <strong>{' Configurações > Pagamentos > Ame'}</strong>
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Agora basta inserir a chave de integração que recebeu e clicar em
        <strong> “enviar”</strong>.
      </S.Paragraph>
    </div>
  );
};

export default VNDA;
