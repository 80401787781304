import { ReactNode } from 'react';

import * as S from './styles';
import { Container } from 'styles/shared/Grid';

import { Header } from 'components';

export type CleanLayoutProps = {
  children: ReactNode;
};

const CleanLayout = ({ children }: CleanLayoutProps) => {
  return (
    <div>
      <Header />

      <S.Content>
        <Container>{children}</Container>
      </S.Content>
    </div>
  );
};

export default CleanLayout;
