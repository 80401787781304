import { useState, useCallback } from 'react';

export type Toggle = {
  toggle: () => void;
  isActive: boolean;
};

const useToggle = (initialValue = false): Toggle => {
  const [isActive, setIsActive] = useState(initialValue);

  const toggle = useCallback(() => setIsActive(oldValue => !oldValue), []);

  return { toggle, isActive };
};

export default useToggle;
