import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  CardInfo,
  ErrorMessage,
  Flex,
  Loading,
  TemplateShortContent,
  Text
} from 'components';

import { ReactComponent as IconPix } from 'assets/images/pix/icon-pix.svg';

import Divider from 'styles/shared/Divider';
import { breadcrumbItems, cardItems } from './constants';
import CardAccordionPix from 'components/Cards/AccordionPix';

import PATHS_ROUTES from 'routes/paths';

import ModalRemoveKey from './RemoveKey';

import { claimLocationState } from 'utils/pix';
import { usePix } from 'contexts/Pix';

const Keys = () => {
  const [removeKey, setRemoveKey] = useState<string | null>(null);
  const { keysList: keysData } = usePix();
  const navigate = useNavigate();

  const handleClickCard = useCallback(
    (path: string, state?: claimLocationState) => {
      navigate(path, {
        state: state || {}
      });
    },
    [navigate]
  );

  if (keysData.loading) {
    return <Loading />;
  }

  if (keysData.error) {
    return (
      <Flex alignItems="center" justifyContent="center" mt="xlarge">
        <ErrorMessage />
      </Flex>
    );
  }

  return (
    <>
      <Breadcrumb links={breadcrumbItems} />

      <Divider size="xlarge" />

      <TemplateShortContent>
        <Flex alignItems="center">
          <IconPix />
          <Text ml="small" tag="h1" size="large" weight="medium">
            Minhas Chaves
          </Text>
        </Flex>

        <Divider size="medium" />

        <Text tag="h2" weight="bold" size="large">
          Gerencie suas chavex Pix :)
        </Text>

        <Divider size="medium" />

        <Text color="blue" size="small" weight="bold">
          Você cadastrou {keysData.data?.length} de 20 chaves disponíveis
        </Text>

        {keysData.data?.map(key => (
          <CardAccordionPix
            key={key.key}
            date={key.createdAt}
            keyType={key.keyType}
            keyValue={key.key}
            removeAction={() => setRemoveKey(key.key)}
          />
        ))}

        <Divider size="medium" />

        <Text color="blue" size="small" weight="bold">
          Portabilidade ou reivindicação de chaves
        </Text>
        {cardItems.map(card => {
          const Icon = card.icon.element;

          return (
            <CardInfo
              key={card.title}
              action={() => handleClickCard(card.path, card.state)}
              icon={<Icon data-testid={`icon-${card.icon.id}`} role="img" />}
              text={card.title}
              arrowIcon
            />
          );
        })}

        <Divider size="medium" />

        <Flex direction="column">
          <Button
            center
            filled
            onClick={() => handleClickCard(PATHS_ROUTES.APP.PIX.DEFAULT)}
          >
            Começar a usar Pix
          </Button>

          <Divider size="small" />

          <Button
            center
            tertiary
            onClick={() => handleClickCard(PATHS_ROUTES.APP.PIX.KEYS.REGISTER)}
          >
            Cadastrar nova chave
          </Button>
        </Flex>
      </TemplateShortContent>

      {removeKey && (
        <ModalRemoveKey
          handleClose={() => {
            keysData.revalidate();
            setRemoveKey(null);
          }}
          keyValue={removeKey}
        />
      )}
    </>
  );
};

export default Keys;
