import Image1 from '../assets/vtex1.png';
import Image2 from '../assets/vtex2.png';
import Image3 from '../assets/vtex3.png';
import Image4 from '../assets/vtex4.png';
import Image5 from '../assets/vtex5.png';
import Image6 from '../assets/vtex6.png';
import { TutorialGenerateKeys } from '../components';
import * as S from '../../styles';

const Vtex = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        VTEX
      </S.Title>

      <TutorialGenerateKeys name="VTEX" />

      <S.Paragraph tag="p" color="grayBlue">
        Entre na sua conta VTEX e em seguida acesse no menu lateral
        <strong> {'Transações > Pagamentos > Configurações.'}</strong>
      </S.Paragraph>

      <S.ImgWrapper>
        <img src={Image1} alt="Menu com acesso a Configurações" />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Na página de configurações selecione a aba
        <strong> “Afiliações de Gateway”</strong>, em seguida, clique no símbolo
        verde de <strong>+</strong> no canto superior direito.
      </S.Paragraph>

      <S.ImgWrapper>
        <img src={Image2} alt="Aba afiliações de gateway" />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Clique no conector <strong>Ame Digital</strong>.
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image3}
          alt="Destaque em Ame Digital na aba afiliações de gateway"
        />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Preencha os campos <strong>“Application Key” </strong> e
        <strong> “Application Token”</strong> com as chaves geradas nas etapas
        acima. Em seguida clique em
        <strong> Salvar</strong>.
      </S.Paragraph>

      <S.ImgWrapper>
        <img src={Image4} alt="Campos Application Key e Application Token" />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Na mesma página, clique na aba
        <strong> “Condições de pagamento”</strong> e selecione o símbolo verde
        de <strong>+</strong> no canto superior direito.
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Selecione o meio de pagamento <strong>Ame Digital</strong>.
      </S.Paragraph>

      <S.ImgCenter>
        <img src={Image5} alt="Meio de pagamento selecionado" />
      </S.ImgCenter>

      <S.Paragraph tag="p" color="grayBlue">
        Clique no botão <strong>“Status”</strong> para ativar essa condição de
        pagamento. Em seguida, clique em <strong>Salvar</strong>.
      </S.Paragraph>

      <S.ImgWrapper>
        <img src={Image6} alt="Exemplo para ativar condição" />
      </S.ImgWrapper>
    </div>
  );
};

export default Vtex;
