import { createContext, useState, useContext, useMemo } from 'react';

import { ContextData, PixOutDataType, ProviderData } from './types';
import { ISetPaymentBody, ISetPaymentPixBody } from 'services/pixService/types';
import { pixOutPayment } from 'services/pixService';
import { IPixKeyData } from 'services/pixService/types';
import { pixKeysType } from 'utils/pix';
import { getApiError } from 'utils/errors';

const PixOutFluxContext = createContext<ContextData | null>(null);

function PixOutFluxProvider({ children }: ProviderData) {
  const [pixOutRequestData, setPixOutRequestData] = useState<PixOutDataType>({
    success: false,
    error: undefined,
    loading: false
  });

  const [recipientData, setRecipientData] = useState<IPixKeyData | null>(null);
  const [recipientBankData, setRecipientBankData] =
    useState<ISetPaymentPixBody | null>(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [transferValue, setTransferValue] = useState('');
  const [transferDescription, setTransferDescription] = useState('');
  const [keyType, setKeyType] = useState('');

  const pixType = useMemo(
    () =>
      keyType
        ? {
            phone: pixKeysType.PHONE_NUMBER,
            email: pixKeysType.EMAIL,
            random: pixKeysType.EVP,
            document:
              searchInputValue.length > 14
                ? 'CORPORATE_TAX_ID'
                : 'PERSONAL_TAX_ID'
          }[keyType]
        : 'phone',
    [keyType, searchInputValue]
  );

  const clearData = () => {
    setRecipientData(null);
    setRecipientBankData(null);
    setSearchInputValue('');
    setTransferValue('');
    setTransferDescription('');
    setKeyType('');
  };

  const handleSetPixOutPayment = async (data: ISetPaymentBody) => {
    setPixOutRequestData(prevState => ({ ...prevState, loading: true }));

    try {
      const response = await pixOutPayment(data);

      if (response) {
        setPixOutRequestData(prevState => ({
          ...prevState,
          success: true,
          error: undefined
        }));
        clearData();
      }
    } catch (err) {
      const { message } = getApiError(err);
      setPixOutRequestData(prevState => ({
        ...prevState,
        success: false,
        error: message
      }));
    } finally {
      setPixOutRequestData(prevState => ({ ...prevState, loading: false }));
    }
  };

  return (
    <PixOutFluxContext.Provider
      value={{
        pixOutRequestData,
        handleSetPixOutPayment,
        clearData,
        pixType: pixType || '',
        recipientData,
        setRecipientData,
        transferValue,
        setTransferValue,
        transferDescription,
        setTransferDescription,
        searchInputValue,
        setSearchInputValue,
        setKeyType,
        recipientBankData,
        setRecipientBankData
      }}
    >
      {children}
    </PixOutFluxContext.Provider>
  );
}

function usePixOutFlux(): ContextData {
  const context = useContext(PixOutFluxContext);

  if (!context)
    throw new Error('usePixOutFlux must be used within an PixOutFluxProvider');

  return context;
}

export { usePixOutFlux, PixOutFluxProvider };
