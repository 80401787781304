import { ReactNode } from 'react';

import { MarginProps } from 'styles/shared/Margin';
import { ThemeSpacings } from 'types/global';

import * as S from './styles';

export type DividerTextProps = {
  size?: ThemeSpacings;
  children?: ReactNode;
} & MarginProps;

const DividerText = ({
  size = 'medium',
  children,
  ...props
}: DividerTextProps) => (
  <S.Wrapper size={size} {...props}>
    {children}
  </S.Wrapper>
);

export default DividerText;
