import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useRequest from 'hooks/useRequest';

import { ErrorMessage, Flex, Loading, Text, Breadcrumb } from 'components';
import Card from 'styles/shared/Card';
import theme from 'styles/theme';

import { ReactComponent as IconFilter } from 'assets/images/icon-filter-outline.svg';
import { ReactComponent as IconAdd } from 'assets/images/icon-add-outline.svg';
import { ReactComponent as SvgIconMenuReport } from 'assets/images/menu/icon-report-outline.svg';

import { useReportList } from 'contexts/Report/List';
import { REPORT_STATUS, translateStatus, translateTypes } from 'utils/report';
import { formatSimpleDate } from 'utils/masks';

import { InfoModal, FilterModal } from './Modals';
import * as S from './styles';

import { ReportListDataContent } from 'services/reportService/types';
import endpoints from 'services/endpoints';

import PATHS_ROUTES from 'routes/paths';
import Margin from 'styles/shared/Margin';

export function ReportList() {
  const intervalRef = useRef(0);
  const {
    selectedFilters,
    report,
    page,
    setPage,
    loading,
    error,
    finalReportList
  } = useReportList();

  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);

  const [sellerReport, setSellerReport] = useState<ReportListDataContent>();
  const { data: download, loading: loadingDownloading } = useRequest<string>({
    url: sellerReport
      ? endpoints.downloadReport.replace('{reportUudi}', sellerReport.uuid)
      : null
  });

  const filterLength = useMemo(
    () => Object.values(selectedFilters).filter(a => a.length).length,
    [selectedFilters]
  );

  function handleDownload(hrefData: string, name: string) {
    const csvContent = 'data:text/csv;charset=utf-8,' + hrefData;
    const encodedUri = encodeURI(csvContent);
    const a = document.createElement('a');
    a.href = encodedUri;
    a.download = name; //File name Here
    a.click(); //Downloaded file
  }

  async function handleClickButton(
    status: string,
    reportItem: ReportListDataContent
  ) {
    if (status === REPORT_STATUS.PROCESSED && reportItem.uuid) {
      setSellerReport(reportItem);
    } else {
      setModal(true);
    }
  }

  useEffect(() => {
    if (sellerReport && download) {
      handleDownload(
        download,
        `Report_${sellerReport.initialDate}_${sellerReport.conciliationType}.csv`
      );
      setSellerReport(undefined);
    }
  }, [download, sellerReport]);

  const startRefreshList = useCallback(() => {
    intervalRef.current = window.setInterval(() => {
      if (!report) setPage(0);
    }, 10000);
  }, [setPage, report]);

  window.onscroll = () => {
    const { scrollTop, offsetHeight } = document.documentElement;
    const currentScroll = window.innerHeight + scrollTop + 50;

    if (
      currentScroll >= offsetHeight &&
      report?.pageable.hasNext &&
      !loading &&
      !error
    ) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        startRefreshList();
      }

      setPage(page + 1);
    }
  };

  useEffect(() => {
    startRefreshList();

    return () => {
      clearInterval(intervalRef.current);
      window.onscroll = null;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function hasDownloadLoading(reportSellerId: string) {
    if (sellerReport)
      return sellerReport.uuid === reportSellerId && loadingDownloading;

    return false;
  }

  return (
    <>
      <Margin mb="large">
        <Breadcrumb
          links={[
            { title: 'inicio', link: '/resumo' },
            { title: 'Meus relatórios', link: '#' }
          ]}
        />
      </Margin>
      <Flex alignItems="center" justifyContent="space-between" mb="medium">
        {selectedFilters.period === '' && (
          <Text size="xxmedium" color="grayNeutralDark">
            Relatórios últimos 30 dias
          </Text>
        )}

        <Flex alignItems="center" justifyContent="flex-end">
          <S.ButtonWrapper
            tertiary={filterLength === 0}
            icon={<IconFilter />}
            filled={filterLength > 0}
            onClick={() => setFilterModal(true)}
          >
            <Flex alignItems="center">
              Filtrar
              {filterLength > 0 && <S.Badge>{filterLength}</S.Badge>}
            </Flex>
          </S.ButtonWrapper>

          <S.ButtonWrapper
            tertiary
            icon={<IconAdd />}
            onClick={() => navigate(PATHS_ROUTES.APP.REPORT.CREATE)}
          >
            Novo relatório
          </S.ButtonWrapper>
        </Flex>
      </Flex>

      {error ? (
        <ErrorMessage message="Erro ao consultar relatórios." />
      ) : (
        <>
          {!finalReportList.length ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              direction="column"
              mt="xlarge"
            >
              {loading && page !== 1 ? (
                <Loading />
              ) : (
                <>
                  <SvgIconMenuReport color={theme.colors.grayDark} />

                  <Text size="xmedium" align="center" color="grayDark">
                    Não há relatórios a serem mostrados
                  </Text>
                </>
              )}
            </Flex>
          ) : (
            <div>
              {finalReportList.map(item => {
                const statusReport = translateStatus(item.status);
                const Icon = statusReport.icon;

                return (
                  <Card key={item.uuid} role="group">
                    <Flex alignItems="center" wrap="wrap">
                      <S.Wrapper>
                        <Text size="xsmall" weight="medium">
                          Data de Solicitação
                        </Text>

                        <Text size="small">
                          {formatSimpleDate(item.createdAt)}
                        </Text>
                      </S.Wrapper>

                      <S.Wrapper>
                        <Text size="xsmall" weight="medium">
                          Período solicitado
                        </Text>

                        <Text size="small">
                          {formatSimpleDate(item.initialDate)} {' - '}
                          {formatSimpleDate(item.finalDate)}
                        </Text>
                      </S.Wrapper>

                      <S.Wrapper>
                        <Text size="xsmall" weight="medium">
                          Tipo de relatório
                        </Text>

                        <Text size="small">
                          {translateTypes(item.conciliationType)}
                        </Text>
                      </S.Wrapper>

                      <S.Wrapper>
                        <Text size="xsmall" weight="medium">
                          Formato
                        </Text>

                        <Text size="small">CSV</Text>
                      </S.Wrapper>

                      <S.Wrapper>
                        <Text size="xsmall" weight="medium">
                          Status
                        </Text>

                        <Text size="small" color={statusReport.color}>
                          {statusReport.description}
                        </Text>
                      </S.Wrapper>

                      <S.DownloadItem
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <S.ButtonWrapper
                          role="button-download"
                          onClick={() => handleClickButton(item.status, item)}
                          tertiary
                          waiting={item.status !== REPORT_STATUS.PROCESSED}
                          icon={
                            !hasDownloadLoading(item.uuid) ? <Icon /> : <></>
                          }
                        >
                          {hasDownloadLoading(item.uuid) ? (
                            <Loading />
                          ) : (
                            'Download'
                          )}
                        </S.ButtonWrapper>
                      </S.DownloadItem>
                    </Flex>
                  </Card>
                );
              })}

              {loading && <Loading />}
            </div>
          )}
        </>
      )}

      {modal && <InfoModal closeModal={() => setModal(false)} />}
      {filterModal && <FilterModal closeModal={() => setFilterModal(false)} />}
    </>
  );
}
