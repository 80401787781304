import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import PATHS_ROUTES from 'routes/paths';

import { formatSimpleDate } from 'utils/masks';

import { ReactComponent as IconBankOutline } from 'assets/images/icon-bank-outline.svg';
import { ReactComponent as IconDepositOutline } from 'assets/images/icon-deposit-outline.svg';
//import { ReactComponent as IconTransferOutline } from 'assets/images/icon-transfer-outline.svg';
import { ReactComponent as IconFeaturePix } from 'assets/images/icon-pix-outline.svg';

import { Button, Flex, Text, CardIcon, Link, StatementList } from 'components';

import { getBalance, getStatements } from 'services/statementService';

import {
  BalanceData,
  GetStatementResponse
} from 'services/statementService/types';

import { useAuth } from 'contexts/Auth';

import SliderBalance from './SliderBalance';

import Divider from 'styles/shared/Divider';
import { Row, Column } from 'styles/shared/Grid';

import * as S from './styles';

const Dashboard = () => {
  const navigate = useNavigate();

  const { permissions } = useAuth();

  const [balance, setBalance] = useState<BalanceData | undefined>();
  const [statements, setStatements] = useState<
    GetStatementResponse | undefined
  >();

  const callBalanceFn = useCallback(async () => {
    const req = await getBalance();
    setBalance(req);
  }, []);

  const callStatementsFn = useCallback(async () => {
    const req = await getStatements(0, 5);
    setStatements(req);
  }, []);

  useEffect(() => {
    if (permissions) {
      const callBalance =
        permissions.includes('RESUMO_SALDO_TOTAL') ||
        permissions.includes('RESUMO_SALDO_DISPONIVEL') ||
        permissions.includes('RESUMO_SALDO_FUTURO');

      const callStatements = permissions.includes('RESUMO_ULTIMAS_TRANSACOES');

      if (callBalance) callBalanceFn();
      if (callStatements) callStatementsFn();
    }
  }, [permissions, callBalanceFn, callStatementsFn]);

  return (
    <>
      {balance && (
        <Row flexDirectionXs="column" flexDirectionSm="row">
          <Column xs={12}>
            <SliderBalance {...balance} />
          </Column>
        </Row>
      )}

      <Row>
        <Column xs={12} md={4}>
          <Row>
            {permissions?.includes('RESUMO_RETIRAR') && (
              <S.ColumnLink xs={3}>
                <CardIcon
                  icon={<IconDepositOutline />}
                  label="Retirar"
                  onClick={() => navigate(PATHS_ROUTES.APP.BANK.WITHDRAW)}
                />
              </S.ColumnLink>
            )}

            {/* <S.ColumnLink xs={3}>
              <CardIcon
                icon={<IconTransferOutline />}
                label="Transferir"
                onClick={() => {}}
              />
            </S.ColumnLink> */}

            {permissions?.includes('RESUMO_CONTA_BANCARIA') && (
              <S.ColumnLink xs={3}>
                <CardIcon
                  icon={<IconBankOutline />}
                  label="C. Bancária"
                  onClick={() => navigate(PATHS_ROUTES.APP.BANK.LIST)}
                />
              </S.ColumnLink>
            )}

            {permissions?.includes('RESUMO_PIX') && (
              <S.ColumnLink xs={3}>
                <CardIcon
                  icon={<IconFeaturePix />}
                  label="Pix"
                  onClick={() => navigate(PATHS_ROUTES.APP.PIX.DEFAULT)}
                />
              </S.ColumnLink>
            )}
          </Row>

          <Divider />

          {permissions?.includes('RESUMO_VENDER') && (
            <Row>
              <Column xs={12}>
                <S.SellCard>
                  <Text
                    align="center"
                    size="xmedium"
                    weight="medium"
                    mb="small"
                  >
                    Comece a vender com Ame ;)
                  </Text>

                  <S.IconQrCodeBlue />

                  <Text
                    align="center"
                    size="xsmall"
                    color="grayDark"
                    mt="small"
                    mb="medium"
                  >
                    Crie seu QR Code com ou sem valor, envie, imprima ou
                    compartilhe para vender!
                  </Text>

                  <Button
                    as={Link}
                    to={{
                      pathname: PATHS_ROUTES.APP.SELL,
                      search: '?criarQrCode'
                    }}
                    fullWidth
                    filled
                  >
                    Criar Qr Code
                  </Button>
                </S.SellCard>
              </Column>
            </Row>
          )}
        </Column>

        {statements && (
          <Column md={8}>
            <S.StatementsCard hasStatements={!!statements.content.length}>
              <Flex direction="column" justifyContent="space-between">
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  grow={0}
                  mb="medium"
                >
                  <Text weight="medium">Últimas transações</Text>
                </Flex>

                <StatementList
                  short
                  statements={statements.content}
                  emptyTransactionMessage="Você não possui transações disponíveis para a data de hoje."
                />

                {permissions?.includes('RESUMO_TODAS_TRANSACOES') && (
                  <Button
                    as={Link}
                    to={PATHS_ROUTES.APP.STATEMENT.LIST}
                    fullWidth
                    tertiary
                  >
                    Ver todas as transações
                  </Button>
                )}
              </Flex>
            </S.StatementsCard>
          </Column>
        )}
      </Row>
      <Row>
        <span>
          Última atualização {formatSimpleDate(new Date(), 'DD MMM YYYY')}
        </span>
      </Row>
    </>
  );
};

export default Dashboard;
