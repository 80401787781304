import { ReactNode, useMemo } from 'react';

import { ModalPix } from 'components';
import { ButtonModalType } from 'components/Modals/Pix';

import { IClaimDataResponse } from 'services/pixService/types';

import { TEXT_MODALS } from './constants';
import { STEP_ITENS } from '../../constants';

interface IModalItem {
  title: string;
  subtitle: ReactNode;
  primaryButton: ButtonModalType;
  secondaryButton?: ButtonModalType;
}

export type ModalEditProps = {
  onClose: () => void;
  claimData: IClaimDataResponse;
  step: string;
  loading: boolean;
  setStep: (step: string) => void;
  onConfirmButtonClaim: () => void;
  onCancelButtonClaim: () => void;
};

const ModalClaim = ({
  onClose,
  claimData,
  step,
  setStep,
  loading,
  onConfirmButtonClaim,
  onCancelButtonClaim
}: ModalEditProps) => {
  const { key, type } = claimData;

  const primaryButton = useMemo(() => {
    switch (step) {
      case STEP_ITENS.INFO:
        return {
          action: onCancelButtonClaim,
          text: TEXT_MODALS[type][step].buttonText1
        };
      case STEP_ITENS.FAIL:
        return {
          action: () => setStep(STEP_ITENS.INFO),
          text: TEXT_MODALS.fail.buttonText1
        };

      default:
        return {
          action: onClose,
          text: TEXT_MODALS[type][step].buttonText1
        };
    }
  }, [onCancelButtonClaim, onClose, setStep, step, type]);

  const modalItem = useMemo(() => {
    const item = { ...TEXT_MODALS.fail, ...TEXT_MODALS[type][step] };

    if (typeof item.subtitle === 'function') item.subtitle = item.subtitle(key);

    return {
      title: item.title,
      subtitle: item.subtitle,
      primaryButton: primaryButton,
      secondaryButton:
        step === STEP_ITENS.INFO
          ? {
              action: onConfirmButtonClaim,
              text: TEXT_MODALS[type].info.buttonText2
            }
          : undefined
    } as IModalItem;
  }, [type, step, key, primaryButton, onConfirmButtonClaim]);

  return (
    <ModalPix
      modalTitle="Minhas chaves"
      title={modalItem.title}
      modalAction={onClose}
      subtitle={modalItem.subtitle}
      primaryButton={modalItem.primaryButton}
      secondaryButton={modalItem.secondaryButton}
      loading={loading}
    />
  );
};

export default ModalClaim;
