import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.large};

    button {
      font-weight: ${theme.font.weights.medium};
    }

    > input {
      border-radius: 12px;
    }
  `}
`;

export const Badge = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    color: ${theme.colors.blue};
    margin-left: ${theme.spacings.small};
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
