import { transparentize } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

export type WrapperProps = {
  isSelected: boolean;
};

const wrapperModifiers = {
  selected: (theme: DefaultTheme) => css`
    border: 0.15rem solid ${theme.colors.blue};

    button {
      color: ${theme.colors.white};
      background: ${theme.colors.blue};
    }
  `
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isSelected }) => css`
    width: 15rem;
    height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    box-shadow: 0.1rem 0.1rem 0.8rem ${transparentize(0.9, theme.colors.black)};

    background: ${theme.profile.colors.profileTypes};
    border-radius: ${theme.spacings.small};

    p {
      font-size: ${theme.font.sizes.xsmall};
      line-height: ${theme.font.sizes.xsmall};
      color: ${theme.colors.white};
      padding: ${theme.spacings.small};
    }

    ${isSelected && wrapperModifiers.selected(theme)}
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    color: ${theme.colors.blue};
    background: ${theme.colors.white};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.weights.medium};
    padding: ${theme.spacings.small};
    cursor: pointer;
    border-top-left-radius: ${theme.spacings.xsmall};
    border-top-right-radius: ${theme.spacings.xsmall};
  `}
`;
