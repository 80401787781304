import PATHS_ROUTES from 'routes/paths';

import OptionsType from './Steps/OptionsType';
import Plataforms from './Steps/Plataforms';
import GenerateConciliation from './Steps/GenerateConciliation';

export const STEPS = {
  CREDENTIAL_TYPE: <OptionsType />,
  PLATAFORM: <Plataforms />,
  CREDENTIAL_GENERATED: <GenerateConciliation />
};

export const breadcrumbItems = [
  { title: 'Início', link: PATHS_ROUTES.APP.DASHBOARD.DEFAULT },
  { title: 'Integração', link: PATHS_ROUTES.APP.INTEGRATION.DEFAULT },
  { title: 'Gerar credenciais para integração', link: '#' }
];
