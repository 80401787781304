import { useCallback } from 'react';

import { Input, Text, Button } from 'components';

import * as S from './styles';
import { Data } from '../../types';
import { getAddress } from 'services/memberService';

export type Step3Data = {
  addressType: string;
  alias: string;
  address: string;
  number: string;
  district: string;
  additionalInfo: string;
  city: string;
  zipCode: string;
  state: string;
  countryCode: string;
};

export type Step3Props = {
  initialData: Data;
  setData: (values: Data) => void;
  nextStep: () => void;
};

function Step3({ nextStep, initialData, setData }: Step3Props) {
  const handleChangeInput = useCallback(
    (event: { target: HTMLInputElement }) =>
      setData({
        ...initialData,
        addressRequest: {
          ...initialData.addressRequest,
          [event.target.name]: event.target.value
        }
      }),
    [initialData, setData]
  );

  const findAddressInfo = async (cep: string) => {
    const response = await getAddress(cep);

    if (response) {
      const addressInfo = {
        address: response.address || '',
        zipCode: response.number || '',
        district: response.neighborhood || '',
        additionalInfo: response.complement || '',
        city: response.city || '',
        state: response.state || ''
      };

      setData({
        ...initialData,
        addressRequest: {
          ...initialData.addressRequest,
          ...addressInfo
        }
      });
    }
  };

  const handleChangeCep = (event: { target: HTMLInputElement }) => {
    handleChangeInput(event);

    if (event.target.value.length === 9) findAddressInfo(event.target.value);
  };

  return (
    <S.Wrapper>
      <Text color="grayBlue" size="xxmedium" weight="medium">
        Adicionar membro
      </Text>

      <Text color="grayDark" size="small">
        Defina agora os dados de endereço do novo membro. (Opcional)
      </Text>

      <S.FormContainer>
        <Input
          name="alias"
          label="Identificação"
          value={initialData.addressRequest.alias}
          onChange={handleChangeInput}
        />

        <Input
          name="zipCode"
          label="Código Postal"
          value={initialData.addressRequest.zipCode}
          onChange={handleChangeCep}
          maskType="CEP"
        />

        <Input
          name="address"
          label="Rua"
          value={initialData.addressRequest.address}
          onChange={handleChangeInput}
        />

        <Input
          name="number"
          label="Número"
          value={initialData.addressRequest.number}
          onChange={handleChangeInput}
        />

        <Input
          name="district"
          label="Bairro"
          value={initialData.addressRequest.district}
          onChange={handleChangeInput}
        />

        <Input
          name="additionalInfo"
          label="Informação adicional"
          value={initialData.addressRequest.additionalInfo}
          onChange={handleChangeInput}
        />

        <Input
          name="city"
          label="Cidade"
          value={initialData.addressRequest.city}
          onChange={handleChangeInput}
        />

        <Input
          name="state"
          label="Estado"
          value={initialData.addressRequest.state}
          onChange={handleChangeInput}
        />
      </S.FormContainer>

      <Button secondary center onClick={nextStep} large>
        Prosseguir
      </Button>
    </S.Wrapper>
  );
}

export default Step3;
