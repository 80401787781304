import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border: 2px dashed ${theme.colors.grayDark};
    border-radius: 1.2rem;
    background: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 7.3rem;

    p {
      margin-left: ${theme.spacings.small};
    }
  `}
`;
