import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import TemplateShortContent from 'components/Templates/ShortContent';
import { ReactComponent as IconClose } from 'assets/images/icon-close.svg';

import * as S from './styles';

export type TemplateCloseContentTypes = {
  backLink?: string | (() => void);
  backTitle?: string;
  closeLink: string | (() => void);
  closeTitle?: string;
  children: ReactNode;
};

export const TemplateCloseContent = ({
  backLink,
  backTitle,
  closeLink,
  closeTitle,
  children
}: TemplateCloseContentTypes) => (
  <TemplateShortContent>
    <S.CloseWrapper backLink={backLink}>
      {backLink && (
        <>
          {typeof backLink === 'function' ? (
            <button onClick={backLink}>
              <S.IconBack title={backTitle} />
            </button>
          ) : (
            <Link to={backLink}>
              <S.IconBack title={backTitle} />
            </Link>
          )}
        </>
      )}

      {typeof closeLink === 'function' ? (
        <button onClick={closeLink}>
          <IconClose title={closeTitle} />
        </button>
      ) : (
        <Link to={closeLink}>
          <IconClose title={closeTitle} />
        </Link>
      )}
    </S.CloseWrapper>

    <>{children}</>
  </TemplateShortContent>
);

export default TemplateCloseContent;
