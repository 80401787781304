import { useMemo } from 'react';

import * as S from './styles';

import { Input, Text, Button, IconArrow } from 'components';
import { useScreenOwner } from 'contexts/ScreenOwner';

export type Data = {
  name: string;
};

export type Step1Props = {
  initialData: Data;
  handleChangeInput: (event: { target: HTMLInputElement }) => void;
  nextStep: () => void;
};

export type Errors = {
  name?: string;
  owner?: string;
};

function Step1({ nextStep, initialData, handleChangeInput }: Step1Props) {
  const { owner, toggleScreenOwner } = useScreenOwner();

  const disableButton = useMemo<boolean>(
    () => !initialData.name || !owner.name,
    [initialData.name, owner.name]
  );

  return (
    <S.Wrapper>
      <Text color="grayBlue" size="xxmedium" weight="medium">
        Criar grupo
      </Text>

      <Text color="grayDark" size="small">
        Preencha os dados abaixo para criar um novo grupo
      </Text>

      <S.FormContainer>
        <Input
          name="owner"
          label="Conta pertencente"
          value={owner.name || ''}
          placeholder="Em qual conta deseja adicionar esse grupo?"
          action={toggleScreenOwner}
          readOnly
          actionChildren={<IconArrow direction="right" />}
        />

        <Input
          name="name"
          label="Nome"
          value={initialData.name}
          onChange={handleChangeInput}
        />
      </S.FormContainer>

      <Button
        secondary
        center
        onClick={nextStep}
        disabled={disableButton}
        large
        title={disableButton ? 'Preencha todos os dados' : ''}
      >
        Prosseguir
      </Button>
    </S.Wrapper>
  );
}

export default Step1;
