import styled, { css } from 'styled-components';

import { ReactComponent as IconArrowCircle } from 'assets/images/icon-arrow-circle.svg';

import { ToggleViewProps } from './';

export const Wrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;

  button {
    display: flex;
  }
`;

export const Content = styled(Wrapper)``;

export const IconArrowCircleDown = styled(IconArrowCircle)<ToggleViewProps>`
  ${({ theme, color }) => css`
    width: ${theme.font.sizes.xxmedium};
    color: ${theme.colors[color!]};
  `}
`;

export const IconArrowCircleUp = styled(IconArrowCircleDown)`
  transform: rotate(180deg);
`;
