import styled, { css } from 'styled-components';
import { PopoverProps } from '.';

type PopoverStyleProps = Pick<PopoverProps, 'direction' | 'show'>;

type PopoverContainerProps = {
  height: number;
  width: number;
} & PopoverStyleProps;

const modifierPopoverContainer = {
  left: (width: number) => css`
    width: 0;
    left: -${width / 2 + 12}px;
  `,
  right: (width: number) => css`
    width: 0;
    right: -${width / 2 + 12}px;
  `,
  bottom: (height: number) => css`
    align-items: flex-start;
    bottom: -${height + 12}px;
  `,
  top: (height: number) => css`
    top: -${height + 12}px;
    align-items: flex-end;
  `,
  visible: () => css`
    visibility: visible;
    opacity: 1;
  `
};

const modifierPopover = {
  left: () => css`
    right: -1.2rem;
    top: 50%;
  `,
  right: () => css`
    left: 0;
    top: 50%;
  `,
  bottom: () => css`
    top: 0;
    left: 50%;
  `,
  top: () => css`
    bottom: -1.2rem;
    left: 50%;
  `
};

export const PopoverWrapper = styled.div`
  display: block;
  position: relative;
`;

export const PopoverContainer = styled.div<PopoverContainerProps>`
  ${({ theme, height, width, direction, show }) => css`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: ${theme.transition.default};

    ${direction === 'bottom' && modifierPopoverContainer.bottom(height)}
    ${direction === 'top' && modifierPopoverContainer.top(height)}
    ${direction === 'left' && modifierPopoverContainer.left(width)}
    ${direction === 'right' && modifierPopoverContainer.right(width)}
    ${show && modifierPopoverContainer.visible()};
  `}
`;

export const Popover = styled.div<PopoverStyleProps>`
  ${({ theme, direction }) => css`
    background-color: ${theme.colors.blue};
    color: ${theme.colors.white};
    white-space: nowrap;
    padding: 0.2rem 0.5rem;
    border-radius: 0.6rem;
    transform: scale(1);
    font-size: ${theme.font.sizes.xsmall};

    &::before {
      content: '';
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      position: absolute;
      background-color: ${theme.colors.blue};
      transform: translate(-50%, -50%) rotate(45deg);
      border-radius: 0.3rem;

      ${direction === 'bottom' && modifierPopover.bottom()}
      ${direction === 'top' && modifierPopover.top()}
      ${direction === 'left' && modifierPopover.left()}
      ${direction === 'right' && modifierPopover.right()}
    }
  `}
`;
