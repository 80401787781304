import PATHS from 'routes/paths';
import { Row } from 'styles/shared/Grid';
import { CardImageLink, TemplateAccreditation, Warning } from 'components';

import Divider from 'styles/shared/Divider';

// import iconFactory from 'assets/images/accreditation/icon-factory.svg';
import iconWebShopping from 'assets/images/accreditation/icon-web-shopping.svg';
import iconBusinessShop from 'assets/images/accreditation/icon-business-shop.svg';

import * as S from './styles';

const cards = [
  {
    to: PATHS.AUTH.ACCREDITATION.PHYSICAL,
    src: iconBusinessShop,
    alt: 'Pequenos negócios',
    text: (
      <span>
        Faturamento
        <S.CardText> abaixo de </S.CardText>
        R$ 4,8 milhões por ano
      </span>
    )
  },
  // TODO: tela de faturamento ainda não foi feita
  // {
  //   to: PATHS.AUTH.ACCREDITATION.DEFAULT,
  //   src: iconFactory,
  //   alt: 'Grandes negócios',
  //   text: (
  //     <span>
  //       Faturamento
  //       <S.CardText> acima de </S.CardText>
  //       R$ 4,8 milhões por ano
  //     </span>
  //   )
  // },
  {
    to: PATHS.AUTH.ACCREDITATION.DIGITAL,
    src: iconWebShopping,
    alt: 'E-commerce',
    text: (
      <span>
        <S.CardText>E-commerce </S.CardText>
        com integração em plataformas parceiras
      </span>
    )
  }
];

const columns = cards.map(card => (
  <S.CardColumn key={card.src} xs={12} lg={4}>
    <CardImageLink {...card} />
  </S.CardColumn>
));

function Accreditation() {
  return (
    <TemplateAccreditation>
      <S.Title>Qual é o seu tipo de negócio?</S.Title>

      <S.Subtitle>
        Escolha por faixa de faturamento anual ou e-commerce com integração de
        plataforma :)
      </S.Subtitle>

      <Row justifyContentXs="center">{columns}</Row>

      <Divider size="xlarge" />

      <Warning>
        <strong>Não tem CNPJ?</strong> Para vender com Ame usando seu CPF, é só{' '}
        <br /> baixar o app Ame Digital e tocar em ”Vender com Ame”.
      </Warning>
    </TemplateAccreditation>
  );
}

export default Accreditation;
