import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';

import { login, loginTwoFactorValidate, logout } from 'services/sessionService';
import { LoginProps, LoginValidateProps } from 'services/sessionService/types';

import { getUserProfile, getPermissions } from 'services/userService';
import { UserData } from 'services/userService/types';

import {
  hasSessionStorage,
  setLogged,
  setUserData,
  setPermissionsData,
  removeSessionStorage
} from 'utils/storage';

import { Loading, ModalChallenge } from 'components';

import { AuthContextData, AuthProviderProps, TwoFactorData } from './types';

import { getApiError } from 'utils/errors';
import { initializeHotjarUserAPI } from 'utils/hotjar';

export const AuthContext = createContext<AuthContextData | null>(null);

function AuthProvider({ children }: AuthProviderProps) {
  const [twoFactor, setTwoFactor] = useState<TwoFactorData | null>(null);
  const [user, setUser] = useState<UserData | null>(null);
  const [permissions, setPermissions] = useState<string[] | null>(null);
  const [loading, setLoading] = useState(true);

  const signIn = useCallback(async ({ username, password }: LoginProps) => {
    const response = await login({
      username,
      password
    });

    const twoFactorData = response.data;

    setTwoFactor(twoFactorData);
  }, []);

  const signInValidate = useCallback(async ({ code }: LoginValidateProps) => {
    await loginTwoFactorValidate({ code });
    const responseUser = await getUserProfile();
    const userPermissions = await getPermissions();
    const user = responseUser.data;
    const permissions = userPermissions.data.permissions.map(p => p.name);

    setPermissionsData(permissions);
    setPermissions(permissions);
    setUserData(user);
    setUser(user);
    setLogged(true);

    initializeHotjarUserAPI(user.id, user.documentNumber);
  }, []);

  const signOut = useCallback(async () => {
    removeSessionStorage();
    setUser(null);
    setPermissions(null);

    await logout();
  }, []);

  const setProfilePicture = useCallback((profilePicture: string) => {
    setUser(user => ({
      ...user!,
      profilePicture
    }));
  }, []);

  useEffect(() => {
    async function getUser() {
      try {
        if ((!user || !permissions) && hasSessionStorage()) {
          const res = await getPermissions();
          const response = await getUserProfile();

          setPermissions(res.data.permissions.map(p => p.name));
          setUser(response.data);

          initializeHotjarUserAPI(
            response.data.id,
            response.data.documentNumber
          );
        }
      } catch (error) {
        const { code } = getApiError(error);

        if (code !== 403) {
          signOut();
        }
      } finally {
        setLoading(false);
      }
    }

    getUser();
  }, [signOut, user, permissions]);

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loading fullPage />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        updateUser: !user?.isUpToDate,
        twoFactor,
        user,
        permissions,
        signIn,
        signInValidate,
        signOut,
        setProfilePicture
      }}
    >
      <ModalChallenge />
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
}

export { useAuth, AuthProvider };
