import styled, { css } from 'styled-components';

export const WrapperIcon = styled.div`
  ${({ theme }) => css`
    height: 3.3rem;
    width: 3.3rem;

    margin: ${theme.spacings.xsmall} ${theme.spacings.medium}
      ${theme.spacings.xsmall} ${theme.spacings.xsmall};

    svg {
      color: ${theme.colors.black};
      fill: ${theme.colors.black};

      height: 100%;
      width: auto;
    }
  `}
`;

export const QRCode = styled.img`
  ${({ theme }) => css`
    height: 22rem;
    width: 22rem;
    border: 10px solid ${theme.colors.grayMedium};
    border-radius: 8px;
  `}
`;

export const QRCodeWrapper = styled.div`
  position: relative;
`;

export const QRCodeTopBlueBorder = styled.div`
  ${({ theme }) => css`
    &::before {
      content: '';
      top: 0;
      left: 0;
      height: 6rem;
      width: 6rem;
      position: absolute;
      border-top: 2px solid ${theme.colors.blueLight};
      border-left: 2px solid ${theme.colors.blueLight};
      border-top-left-radius: 8px;
    }

    &::after {
      content: '';
      top: 0;
      right: 0;
      height: 6rem;
      width: 6rem;
      position: absolute;
      border-top: 2px solid ${theme.colors.blueLight};
      border-right: 2px solid ${theme.colors.blueLight};
      border-top-right-radius: 8px;
    }
  `}
`;

export const QRCodeBottomBlueBorder = styled.div`
  ${({ theme }) => css`
    &::before {
      content: '';
      bottom: 5px;
      left: 0;
      height: 6rem;
      width: 6rem;
      position: absolute;
      border-bottom: 2px solid ${theme.colors.blueLight};
      border-left: 2px solid ${theme.colors.blueLight};
      border-bottom-left-radius: 8px;
    }

    &::after {
      content: '';
      bottom: 5px;
      right: 0;
      height: 6rem;
      width: 6rem;
      position: absolute;
      border-bottom: 2px solid ${theme.colors.blueLight};
      border-right: 2px solid ${theme.colors.blueLight};
      border-bottom-right-radius: 8px;
    }
  `}
`;

export const PopoverWrapper = styled.div`
  width: 100%;
`;
