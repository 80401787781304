import { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Menu from './Menu';

import PATHS_ROUTES from 'routes/paths';

import useWindowSize from 'hooks/useWindowSize';

import { useAuth } from 'contexts/Auth';

import iconHideMenu from 'assets/images/menu/icon-hide-menu.svg';
import { ReactComponent as LogoAmeplusShort } from 'assets/images/icon-logo-ameplus-short.svg';
import { ReactComponent as LogoAmeplusShortDark } from 'assets/images/icon-logo-ameplus-short-dark.svg';
import { ReactComponent as IconClose } from 'assets/images/icon-close.svg';
import logoAmePlus from 'assets/images/icon-logo-ameplus.svg';

import * as S from './styles';

export type NavProps = {
  toggleMenuMobile: () => void;
  compressMenu: {
    isActive: boolean;
    toggle: () => void;
  };
};

type logoClickType = () => void | null;

const Nav = ({ toggleMenuMobile, compressMenu }: NavProps) => {
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const { permissions } = useAuth();

  const menuMobileClick = useCallback(() => {
    if (width > 1024) {
      return () => {};
    }

    return toggleMenuMobile();
  }, [toggleMenuMobile, width]);

  const logoClick = useMemo<logoClickType>(() => {
    if (width <= 1024) {
      return toggleMenuMobile;
    } else if (compressMenu.isActive) {
      return compressMenu.toggle;
    } else {
      return () => {
        if (permissions?.includes('RESUMO')) {
          navigate(PATHS_ROUTES.APP.DASHBOARD.DEFAULT);
        } else {
          navigate(PATHS_ROUTES.APP.WELCOME);
        }
      };
    }
  }, [
    compressMenu.isActive,
    compressMenu.toggle,
    navigate,
    toggleMenuMobile,
    width,
    permissions
  ]);

  return (
    <>
      <S.NavOverlay onClick={menuMobileClick} />
      <S.Nav>
        <S.LogoWrapper>
          <>
            {compressMenu.isActive && width > 991 ? (
              <S.ShortLogo onClick={logoClick}>
                <LogoAmeplusShort className="light" />
                <LogoAmeplusShortDark className="dark" />
              </S.ShortLogo>
            ) : (
              <S.Logo src={logoAmePlus} onClick={logoClick} />
            )}

            {!compressMenu.isActive && (
              <div onClick={compressMenu.toggle}>
                <img src={iconHideMenu} alt="Esconder o Menu" />
              </div>
            )}

            {width < 991 && (
              <IconClose onClick={logoClick} title="Esconder o Menu" />
            )}
          </>
        </S.LogoWrapper>

        <S.Menus>
          <Menu menuMobileClick={menuMobileClick} position="top" />
          <Menu menuMobileClick={menuMobileClick} position="bottom" />
        </S.Menus>
      </S.Nav>
    </>
  );
};

export default Nav;
