import { Loading, Select, SelectField, InputField } from 'components';
import { SelectOption } from 'components/Select';
import { StepProps } from 'contexts/AccreditationSteps/types';
import { useFormikContext } from 'formik';
import useRequest from 'hooks/useRequest';
import endpoints from 'services/endpoints';
import { AccreditationValues } from '../../../shared/form';

import * as S from './styles';
import { useSegments } from '../../../shared/hooks/use-segments';

const partnerFields = {
  shopping: {
    label: 'Escolha o shopping'
  },
  associacao: {
    label: 'Escolha a associação'
  },
  revendedor: {
    label: 'Escolha sua revendedora'
  }
};

const emptyOption = { value: '', label: '' };
type Partners = { partnershipId: string; partnershipAcronym: string }[];

function usePartnership(partnershipType: string) {
  const { data, loading } = useRequest<Partners>({
    url: partnershipType
      ? `${endpoints.accreditationPartnership}?word=&partnershipType=${partnershipType}`
      : null
  });

  const partners = data?.map(p => ({
    value: p.partnershipId,
    label: p.partnershipAcronym
  }));

  return {
    partners: partners || [],
    loading
  };
}

const Step1 = ({ hasSession, partnershipTypes, names }: StepProps) => {
  const form = useFormikContext<AccreditationValues>();
  const { segments, loading } = useSegments(hasSession);

  const { partners } = usePartnership(form.values[names.partnershipType]);

  const selectedPartnership =
    partners?.find(type => type.value === form.values[names.partnership]) ||
    null;

  const selectedType = partnershipTypes.find(
    type => type.value === form.values[names.partnershipType]
  );

  const handleChangeType = (type: SelectOption) => {
    if (type.value === form.values[names.partnershipType]) return;

    handleChangeValue(emptyOption);
    form.setFieldValue(names.partnershipType, type.value);
  };

  const handleChangeValue = (type: SelectOption) => {
    form.setFieldValue(names.partnership, type.value);
    form.setFieldError(names.partnership, undefined);
  };

  return (
    <>
      {loading && <Loading fullContent fullWidth />}

      <S.Title>
        Que bom ter você aqui <S.HighlightText>:)</S.HighlightText>
      </S.Title>

      <S.Subtitle>
        Para começar nossa nova parceria, conta pra gente um pouco sobre sua
        empresa
      </S.Subtitle>

      <InputField
        name={names.cnpj}
        label="CNPJ da sua empresa"
        maskType="CNPJ"
      />

      <InputField name={names.social} label="Sua razão social" />

      <SelectField
        name={names.segment}
        label="Seu tipo de negócio"
        options={segments}
      />

      <Select
        name={names.partnershipType}
        label="Sua empresa tem uma parceria?"
        options={partnershipTypes}
        value={selectedType}
        onChange={handleChangeType}
      />

      {selectedType?.value && (
        <>
          <Select
            name={names.partnership}
            label={partnerFields[selectedType.value]?.label}
            options={partners}
            value={selectedPartnership}
            onChange={handleChangeValue}
            errorMessage={form.errors[names.partnership]}
          />
        </>
      )}

      <S.Action>Ir para endereço</S.Action>
    </>
  );
};

export default Step1;
