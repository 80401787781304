import styled, { css } from 'styled-components';

import { ReactComponent as IconArrowUp } from 'assets/images/icon-arrow-up.svg';

export const ListWrapper = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.small};
  `}
`;

const itemModifiers = {
  checked: css`
    border-color: ${({ theme }) => theme.colors.blue};
  `
};

export const ItemWrapper = styled.li<{ checked: boolean }>`
  ${({ theme, checked }) => css`
    display: flex;
    flex-direction: column;
    transition: all ${theme.transition.fast};
    padding: ${theme.spacings.small} ${theme.spacings.medium};
    border: 1px solid ${theme.colors.grayMedium};
    border-radius: 1.2rem;

    ${checked && itemModifiers.checked}
  `}
`;

export const Texts = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    svg,
    img {
      margin-right: ${theme.spacings.small};
    }
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    height: ${theme.spacings.large};
  `}
`;

const iconModifiers = {
  closed: css`
    transform: scaleY(-1);
  `
};

export const Icon = styled(IconArrowUp)<{ closed: boolean }>`
  ${({ closed }) => closed && iconModifiers.closed}
`;

export const Content = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacings.small} 0;
    gap: ${theme.spacings.small};
  `}
`;
