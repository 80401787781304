import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Button,
  CardInfo,
  CardShadowDashed,
  Link,
  Loading,
  Text,
  TemplateCloseContent,
  ModalStatus
} from 'components';

import { ReactComponent as IconBankOutline } from 'assets/images/icon-bank-outline.svg';

import { Row, Column } from 'styles/shared/Grid';
import Divider from 'styles/shared/Divider';

import useRequest from 'hooks/useRequest';

import { useBank } from 'contexts/Bank';

import endpoints from 'services/endpoints';
import { IBankAccountData } from 'services/bankService/types';

import { maskDocument } from 'utils/masks';

import PATHS_ROUTES from 'routes/paths';

export const BankSelect = () => {
  const { setWithdrawDataValues, withdrawData } = useBank();

  const navigate = useNavigate();

  const {
    data: bankAccount,
    loading,
    error
  } = useRequest<IBankAccountData[]>({
    url: endpoints.bankAccount
  });

  const isBankSelected = useCallback(
    (bank: IBankAccountData) => bank.id === withdrawData.bankData?.id,
    [withdrawData.bankData?.id]
  );

  if (loading) return <Loading fullContent />;

  return (
    <>
      <TemplateCloseContent closeLink={PATHS_ROUTES.APP.BANK.WITHDRAW}>
        <Text size="xxmedium" weight="bold">
          Contas de destino
        </Text>

        <Text mb="large">Essas são suas contas bancárias cadastradas</Text>

        {!bankAccount?.length ? (
          <CardShadowDashed
            rightText="Adicionar"
            text="Nenhuma conta de destino cadastrada"
            icon={<IconBankOutline />}
            action={() => navigate(PATHS_ROUTES.APP.BANK.ACCOUNT_REGISTRATION)}
          />
        ) : (
          bankAccount?.map(account => (
            <CardInfo
              key={account.id}
              text={account.name}
              icon={<img src={account.image} width="50" />}
              action={() =>
                setWithdrawDataValues({
                  bankData: account
                })
              }
              infoText={isBankSelected(account) ? 'Selecionado' : 'Selecionar'}
              active={isBankSelected(account)}
            >
              <Text color="grayNeutralDark" size="xxsmall">
                CNPJ:{' '}
                {maskDocument(withdrawData?.documentNumber as string, 'CNPJ')}
              </Text>
              <Text color="blueLightest" size="xxsmall">
                Ag: {account.agency} / Cc: {account.account}
              </Text>
            </CardInfo>
          ))
        )}

        <Divider size="large" />

        <Row>
          <Column xs={12} md={8} offsetMd={2}>
            <Button
              as={Link}
              to={PATHS_ROUTES.APP.BANK.WITHDRAW}
              disabled={!withdrawData.bankData}
              filled
              fullWidth
            >
              Confirmar
            </Button>

            <Divider />
          </Column>

          <Column xs={12} md={8} offsetMd={2}>
            <Button
              as={Link}
              to={PATHS_ROUTES.APP.BANK.ACCOUNT_REGISTRATION}
              secondary
              fullWidth
            >
              Adicionar conta
            </Button>
          </Column>
        </Row>
      </TemplateCloseContent>

      {error && (
        <ModalStatus
          variant="error"
          title="Erro"
          onClose={() => navigate(PATHS_ROUTES.APP.DASHBOARD.DEFAULT)}
        >
          <Text>Ocorreu um erro inesperado</Text>
        </ModalStatus>
      )}
    </>
  );
};

export default BankSelect;
