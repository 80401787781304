import { useFormik } from 'formik';

import * as S from './styles';
import { validationSchema } from './validationSchema';
import { TemplateCloseContent, Text, Input, Button } from 'components';
import PATHS_ROUTES from 'routes/paths';
import Divider from 'styles/shared/Divider';

const TeamMemberResetPassword = () => {
  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: ''
    },
    validationSchema,
    onSubmit: values => {
      formik.setSubmitting(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return new Promise((resolve, _) => {
        setTimeout(() => {
          // eslint-disable-next-line no-console
          console.log('VALUES - ', values);
          formik.setSubmitting(false);
        }, 5000);
      });
    }
  });

  return (
    <TemplateCloseContent
      closeLink={PATHS_ROUTES.APP.TEAM.MEMBER('1')}
      closeTitle="Voltar"
    >
      <S.Wrapper>
        <Text color="grayBlue" size="xxmedium" weight="medium">
          Redefinir senha
        </Text>

        <Text color="grayDark" size="small">
          Preencha a nova senha de Carlos H. de Souza
        </Text>

        <S.FormContainer onSubmit={formik.handleSubmit}>
          <Input name="login" label="Login" value="carlos.souza" disabled />

          <Input
            label="Senha"
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            errorMessage={formik.errors.password}
          />

          <Input
            label="Confirmar senha"
            name="password_confirmation"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password_confirmation}
            errorMessage={formik.errors.password_confirmation}
          />

          <Divider size="large" />

          <Button secondary center large disabled={formik.isSubmitting}>
            Concluir
          </Button>
        </S.FormContainer>
      </S.Wrapper>
    </TemplateCloseContent>
  );
};

export default TeamMemberResetPassword;
