import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';

import { ReactComponent as SvgIconSearch } from 'assets/images/icon-search.svg';

import {
  Breadcrumb,
  Button,
  CardAccordionPix,
  CardLink,
  Loading,
  TemplateShortContent,
  Text,
  Select
} from 'components';
import { SelectOption } from 'components/Select';

import theme from 'styles/theme';
import Margin from 'styles/shared/Margin';
import Divider from 'styles/shared/Divider';
import { Row, Column } from 'styles/shared/Grid';

import { MaskTypes } from 'types/global';
import {
  removeSpecialCharacters,
  formatMask,
  maskOnlyNumbers
} from 'utils/masks';
import { pixTypes, PIX_OUT_KEYS_TYPES } from 'utils/pix';
import endpoints from 'services/endpoints';
import { IPixKeyData } from 'services/pixService/types';
import useRequest from 'hooks/useRequest';
import { usePixOutFlux } from 'contexts/Pix/PixOutFlux';

import PATHS_ROUTES from 'routes/paths';

import { COUNTRY_CODES } from 'utils/constants';
import { transferOptions } from '../constants';
import { breadcrumb, BreadcrumbKeys, formatErrorMessage } from './constants';

import TransferValue from '../Value';
import TransferConfirmation from '../Confirmation';
import * as S from './styles';

const TransferKey = () => {
  const { keyType } = useParams<{ keyType: BreadcrumbKeys }>();
  const navigate = useNavigate();
  const {
    recipientData,
    setRecipientData,
    setRecipientBankData,
    searchInputValue,
    setSearchInputValue,
    setKeyType,
    pixType
  } = usePixOutFlux();

  const [errorStatus, setErrorStatus] = useState('');
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [inputLengthOnEnter, setInputLengthOnEnter] = useState(0);
  const [page, setPage] = useState(0);

  const [phoneCountry, setPhoneCountry] = useState<SelectOption>({
    label: '+55',
    value:
      '{"name":"Brasil","code":"+55","iso":"BR","mask":["(##) ####-####","(##) #####-####"]}'
  });
  const [phoneMask, setPhoneMask] = useState<string | string[]>(
    '(99) 99999-9999'
  );

  const keyData = transferOptions.find(option => option.key === keyType);
  const Icon = keyData?.icon;

  const getEndpoint = () => {
    let replaceString = '';
    switch (keyType) {
      case 'random':
        replaceString = searchInputValue;
        break;
      case 'email':
        replaceString = searchInputValue;
        break;
      case 'phone':
        replaceString = `${phoneCountry?.label}${removeSpecialCharacters(
          searchInputValue
        )}`.replace(/\s/g, '');
        break;
      default:
        replaceString = removeSpecialCharacters(searchInputValue);
        break;
    }
    return endpoints.key.replace('{key}', replaceString);
  };

  useRequest<IPixKeyData>({
    url:
      searchInputValue.length && searchInputValue.length === inputLengthOnEnter
        ? getEndpoint()
        : null,
    options: {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onError: error => {
        setErrorStatus(error?.response?.data?.status);
        setLoadingStatus(false);
      },
      onSuccess: data => {
        setRecipientBankData(null);
        setRecipientData(data);
        setLoadingStatus(false);
      }
    }
  });

  useEffect(() => {
    if (searchInputValue.length !== inputLengthOnEnter) {
      setInputLengthOnEnter(0);
      setErrorStatus('');
      setRecipientData(null);
    }
  }, [searchInputValue, inputLengthOnEnter, setRecipientData]);

  useEffect(() => {
    if (keyType) setKeyType(keyType);
  }, [keyType, setKeyType]);

  const countryOptions = useMemo(() => {
    const options: SelectOption[] = [];
    COUNTRY_CODES.forEach(country => {
      options.push({
        label: country.code,
        value: JSON.stringify(country)
      });
    });

    return options;
  }, []);

  const getMask = useCallback(
    (mask: string | string[]) => {
      let maskTemp = '';
      if (typeof phoneMask === 'string') {
        maskTemp = phoneMask.replace(/#/g, '9');
      } else {
        for (let index = 0; index < mask.length; index++) {
          const maskReplaced = phoneMask[index].replace(/#/g, '9');
          if (
            maskOnlyNumbers(maskReplaced).length === searchInputValue.length
          ) {
            maskTemp = maskReplaced;
            break;
          } else {
            maskTemp = maskReplaced;
          }
        }
      }

      return maskTemp;
    },
    [phoneMask, searchInputValue.length]
  );

  const maskType = useMemo(() => {
    if (keyType === PIX_OUT_KEYS_TYPES.DOCUMENT) {
      return 'DOCUMENT';
    }
  }, [keyType]);

  const handleBackButton = useCallback(() => {
    navigate(PATHS_ROUTES.APP.PIX.TRANSFER.DEFAULT, { replace: true });
  }, [navigate]);

  const handleSearchInputValue = (value: string) => {
    if (keyType === PIX_OUT_KEYS_TYPES.PHONE) {
      const mask = getMask(phoneMask);
      setSearchInputValue(formatMask(value, mask));
    } else setSearchInputValue(value);
  };

  const handleSearch = () => {
    if (!inputLengthOnEnter && !loadingStatus) {
      setInputLengthOnEnter(searchInputValue.length);
      setLoadingStatus(true);
    }
  };

  useEffect(() => {
    if (keyType === PIX_OUT_KEYS_TYPES.PHONE) {
      const mask = getMask(phoneMask);
      setSearchInputValue(formatMask(searchInputValue, mask));
    }
  }, [getMask, keyType, phoneMask, searchInputValue, setSearchInputValue]);

  if (!keyType || !breadcrumb[keyType]) {
    return <Navigate to={PATHS_ROUTES.APP.PIX.TRANSFER.DEFAULT} replace />;
  }

  return (
    <>
      {page === 1 ? (
        <TransferValue
          nextPage={() => setPage(2)}
          previousPage={() => setPage(0)}
        />
      ) : page === 2 ? (
        <TransferConfirmation previousPage={() => setPage(1)} />
      ) : (
        <>
          {loadingStatus && <Loading fullPage fullContent />}

          <Breadcrumb links={breadcrumb[keyType]} />

          <TemplateShortContent>
            <Margin my="large">
              <Text tag="h1" size="large" weight="medium">
                Transferência por{' '}
                <Text
                  tag="span"
                  size="large"
                  weight="medium"
                  transform={keyData?.key !== 'document' ? 'lowercase' : 'none'}
                >
                  {keyData?.title}
                </Text>
              </Text>

              <Divider size="xsmall" />

              <Text tag="p">{keyData?.subtitle}</Text>

              <Divider size="medium" />

              <Margin mx="large">
                <CardLink
                  title={keyData?.title || ''}
                  icon={
                    Icon ? (
                      <Icon width="30" height="30" color={theme.colors.blue} />
                    ) : null
                  }
                />

                <Divider size="medium" />

                <Text tag="p" weight="bold" size="small">
                  {keyData?.inputTitle}
                </Text>

                <Divider size="xsmall" />

                <Row alignItemsMd="flex-end" alignItemsXl="flex-end">
                  {keyType === PIX_OUT_KEYS_TYPES.PHONE && (
                    <Column xs={12} md={3} xl={3}>
                      <Select
                        options={countryOptions}
                        value={phoneCountry}
                        name="country"
                        placeholder="Selecione um país"
                        onChange={value => {
                          setPhoneCountry(value);
                          setPhoneMask(JSON.parse(value.value.toString()).mask);
                        }}
                        withMargin={false}
                      />
                    </Column>
                  )}

                  <Column
                    xs={12}
                    md={keyType === PIX_OUT_KEYS_TYPES.PHONE ? 9 : 12}
                    xl={keyType === PIX_OUT_KEYS_TYPES.PHONE ? 9 : 12}
                  >
                    <S.InputContainer>
                      <S.InputText
                        name={keyType}
                        value={searchInputValue}
                        placeholder="Buscar"
                        onChange={el => handleSearchInputValue(el.target.value)}
                        maskType={maskType as MaskTypes | undefined}
                        onlyNumbers={[PIX_OUT_KEYS_TYPES.DOCUMENT].includes(
                          keyType
                        )}
                        withMargin={false}
                        onKeyUp={el => {
                          if (el.key === 'Enter') {
                            el.currentTarget.blur();
                            handleSearch();
                          }
                        }}
                      />

                      <S.IconContainer>
                        <SvgIconSearch
                          width="15"
                          height="15"
                          viewBox="6 6 20 20"
                        />
                      </S.IconContainer>
                    </S.InputContainer>
                  </Column>
                </Row>

                <Divider size="xsmall" />

                {errorStatus && !recipientData && (
                  <Text tag="p" color="red" size="xsmall">
                    {formatErrorMessage(errorStatus)}
                  </Text>
                )}

                {recipientData && (
                  <>
                    <Divider size="medium" />

                    <Text tag="p" weight="bold" size="small">
                      Dados que quem vai receber o Pix:
                    </Text>

                    <CardAccordionPix
                      keyType={pixType as pixTypes}
                      keyValue={recipientData.key}
                      user={{
                        institution: recipientData.institutionName || '',
                        name: recipientData.userName || '',
                        document: recipientData.userTaxId || ''
                      }}
                      expanded
                    />
                  </>
                )}

                <Divider size="medium" />

                <Button
                  center
                  filled
                  onClick={() => {
                    recipientData ? setPage(1) : handleSearch();
                  }}
                >
                  {recipientData ? 'Continuar para valor' : 'Continuar'}
                </Button>

                <Divider size="xsmall" />

                <Button
                  center
                  filled
                  filledBgColor="transparent"
                  filledColor="blue"
                  onClick={handleBackButton}
                >
                  Voltar
                </Button>
              </Margin>
            </Margin>
          </TemplateShortContent>
        </>
      )}
    </>
  );
};

export default TransferKey;
