import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

export const Item = styled.tr`
  ${({ theme }) => css`
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .status {
      color: ${theme.colors.blue};
    }

    &.isCancel {
      td {
        color: ${theme.colors.grayDark};
      }
    }

    .grossAmount {
      &-in {
        min-width: 120px;
        color: ${theme.colors.blue};
      }

      &-out {
        min-width: 120px;
        color: ${theme.colors.red};
      }
    }

    &.isCancel {
      text-decoration: line-through;
    }

    &.isProcessing {
      .status {
        color: ${theme.colors.yellowDark};
      }
    }

    &:hover {
      background: ${transparentize(0.9, theme.colors.blue)};
      font-weight: ${theme.font.weights.medium};

      svg {
        color: ${theme.colors.blue};
      }
    }

    td {
      padding: ${theme.spacings.medium} ${theme.spacings.xsmall};
      font-size: ${theme.font.sizes.xsmall};
      display: none;
      min-width: 100px;

      &.short {
        display: table-cell;
        width: 100%;

        p {
          font-size: ${theme.font.sizes.small};

          &:first-child {
            font-weight: ${theme.font.weights.medium};
          }

          &.grossAmount-out {
            font-size: ${theme.font.sizes.medium};
          }
        }

        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: ${theme.font.sizes.medium};
          line-height: ${theme.font.lineHeight('medium')};

          & > svg {
            align-self: center;
          }

          div {
            flex: 1;
            padding-left: ${theme.spacings.medium};
          }
        }

        a {
          display: flex;
          flex-direction: column;
          font-size: ${theme.font.sizes.medium};
          line-height: ${theme.font.lineHeight('medium')};
          color: ${theme.colors.grayDark};

          svg {
            padding-top: 4px;
          }
        }
      }

      &.mobile {
        ${customMedia.greaterThan('medium')`
            &.mobile{
              display: none;
            }
          `}
      }

      img {
        margin-right: ${theme.spacings.xsmall};
      }

      ${customMedia.greaterThan('medium')`
        display: table-cell;
        &:nth-child(2) {
          padding-left: ${theme.spacings.medium};
        }

        &:last-child {
          padding-right: ${theme.spacings.medium};
        }
      `}
    }
  `}
`;
