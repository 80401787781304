import { BreadcrumbLink } from 'components/Breadcrumb';

import PATHS_ROUTES from 'routes/paths';

import { PIX_OUT_KEYS_TYPES } from 'utils/pix';

// import { ReactComponent as SvgIconFavorite } from 'assets/images/icon-favorite.svg';
import { ReactComponent as SvgIconPixPhoneKey } from 'assets/images/pix/icon-pix-phone-key.svg';
import { ReactComponent as SvgIconPixDocumentKey } from 'assets/images/pix/icon-pix-document-key.svg';
import { ReactComponent as SvgIconPixEmailKey } from 'assets/images/pix/icon-pix-email-key.svg';
import { ReactComponent as SvgIconPixRandomKey } from 'assets/images/pix/icon-pix-random-key.svg';
import { ReactComponent as SvgIconPixBankKey } from 'assets/images/pix/icon-pix-bank-key.svg';

export const breadcrumbItems: BreadcrumbLink[] = [
  {
    title: 'Pix',
    link: PATHS_ROUTES.APP.PIX.DEFAULT
  },
  {
    title: 'Transferir',
    link: PATHS_ROUTES.APP.PIX.TRANSFER.DEFAULT
  }
];

export const transferOptions = [
  {
    key: PIX_OUT_KEYS_TYPES.PHONE,
    to: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.PHONE),
    title: 'Número de celular',
    subtitle: 'Busque pelo número de celular',
    cardSubtitle: 'Digite o número de celular de quem vai receber',
    inputTitle: 'Digite o número de celular de quem vai receber:',
    icon: SvgIconPixPhoneKey
  },
  {
    key: PIX_OUT_KEYS_TYPES.DOCUMENT,
    to: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.DOCUMENT),
    title: 'CPF/CNPJ',
    subtitle: 'Busque pelo número do CPF/CNPJ',
    cardSubtitle: 'Digite o CPF ou CNPJ de quem vai receber',
    inputTitle: 'Digite o número do CPF ou CNPJ de quem vai receber:',
    icon: SvgIconPixDocumentKey
  },
  {
    key: PIX_OUT_KEYS_TYPES.EMAIL,
    to: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.EMAIL),
    title: 'Endereço de e-mail',
    subtitle: 'Busque pelo e-mail',
    cardSubtitle: 'Digite o e-mail de quem vai receber',
    inputTitle: 'Digite o e-mail de quem vai receber:',
    icon: SvgIconPixEmailKey
  },
  {
    key: PIX_OUT_KEYS_TYPES.RANDOM,
    to: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.RANDOM),
    title: 'Chave aleatória',
    subtitle: 'Busque por uma chave aleatória',
    cardSubtitle: 'Digite a chave aletória de quem vai receber',
    inputTitle: 'Digite a chave aletória de quem vai receber:',
    icon: SvgIconPixRandomKey
  },
  {
    key: PIX_OUT_KEYS_TYPES.BANK,
    to: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.BANK),
    title: 'Agência e conta',
    subtitle: 'Busque os dados da conta de quem vai receber',
    cardSubtitle: 'Digite os dados da conta de quem vai receber',
    inputTitle: 'Informe os dados da conta de quem vai receber:',
    icon: SvgIconPixBankKey
  }
];
