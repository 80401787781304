export type Group = {
  id: string;
  title: string;
  items: { name: string; image?: string }[];
};

export const groups = [...Array(12)].map((_, index) => ({
  id: `e4a4d043-9709-11eb-a8b3-0242a34303${index + 1}`,
  title: `Postos BR Adm ${index + 1}`,
  items: [
    {
      name: `Postos BR Adm ${index + 1}`
    },
    {
      name: `Postos BR ${index + 1}`
    },
    {
      name: `BR Adm ${index + 1}`
    },
    {
      name: `Adm ${index + 1}`
    }
  ]
}));
