import { Button, Text } from 'components';
import Divider from 'styles/shared/Divider';

type RandomKeyStep1Props = {
  next: () => void;
};

export function RandomKeyStep1({ next }: RandomKeyStep1Props) {
  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        Cadastrar com chave aleatória
      </Text>

      <Text tag="p" size="small" mt="small">
        A chave aleatória é automaticamente gerada com uma sequência de números
        e letras, você não precisa passar nenhum dado pessoal.
      </Text>

      <Divider size="large" />

      <Button center filled onClick={() => next()} role="button-register-step1">
        Cadastrar chave Pix
      </Button>
    </>
  );
}

export default RandomKeyStep1;
