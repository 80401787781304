import styled, { css, DefaultTheme } from 'styled-components';
import Card from 'styles/shared/Card';
import { GroupProps } from '.';

type WrapperProps = Omit<GroupProps, 'items' | 'title' | 'onSeeMoreClick'>;

export const Heading = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.blackDark};
    font-size: ${theme.font.sizes.xxmedium};
    line-height: ${theme.font.lineHeight('xlarge')};
  `}
`;

export const SeeMore = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: ${theme.spacings.medium};
  `}
`;

export const SeeMoreButton = styled.button`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.weights.bold};
    color: ${theme.colors.blueCategoryColorSuccess};
  `}
`;

const wrapperModifiers = {
  abbreviated: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  small: (theme: DefaultTheme) =>
    css`
      ${Heading} {
        font-size: ${theme.font.sizes.medium};
        line-height: ${theme.font.lineHeight('medium')};
      }

      ${SeeMoreButton} {
        font-size: ${theme.font.sizes.small};
      }
    `
};

export const Wrapper = styled(Card)<WrapperProps>`
  ${({ theme, abbreviated, small }) => css`
    max-width: 36.6rem;

    ${abbreviated && wrapperModifiers.abbreviated}
    ${small && wrapperModifiers.small(theme)}
  `}
`;
