import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Button, TemplateShortContent } from 'components';

import theme from 'styles/theme';

import { ReactComponent as IconEditSolid } from 'assets/images/icon-edit-solid.svg';
import { ReactComponent as IconEditOutline } from 'assets/images/icon-edit-outline.svg';

import * as S from './styles';

export type TemplateEditContentTypes = {
  backLink?: string;
  backTitle?: string;
  onEdit: React.MouseEventHandler<HTMLButtonElement>;
  editing?: boolean;
  children: ReactNode;
};

export const TemplateEditContent = ({
  backLink,
  backTitle,
  onEdit,
  editing,
  children
}: TemplateEditContentTypes) => (
  <TemplateShortContent>
    <S.Wrapper backLink={backLink}>
      {backLink && (
        <Link to={backLink}>
          <S.IconBack title={backTitle} />
        </Link>
      )}

      <Button
        tertiary
        onClick={onEdit}
        filled={editing}
        icon={
          editing ? (
            <IconEditSolid color={theme.colors.white} />
          ) : (
            <IconEditOutline color={theme.colors.grayIcon} />
          )
        }
      />
    </S.Wrapper>

    <>{children}</>
  </TemplateShortContent>
);

export default TemplateEditContent;
