import { useMemo } from 'react';
//import debounce from 'lodash.debounce';

import PATHS_ROUTES from 'routes/paths';

import {
  Link,
  Text,
  Button,
  CardTeamMember,
  TemplateSearchContent,
  Loading
} from 'components';
import { BreadcrumbLink } from 'components/Breadcrumb';
import PageNav from 'components/Pagination/Nav';
import { TemplateSearchContentAction } from 'components/Templates/SearchContent';

import { ReactComponent as IconAdd } from 'assets/images/icon-add-outline.svg';
import Divider from 'styles/shared/Divider';
import { Column, Row } from 'styles/shared/Grid';

import { useTeam } from 'contexts/Team';
import { useAuth } from 'contexts/Auth';

//import { removeSpecialCharacters } from 'utils/masks';

const links: BreadcrumbLink[] = [
  // { title: 'Equipe', link: PATHS_ROUTES.APP.TEAM.DEFAULT },
  { title: 'Membros', link: PATHS_ROUTES.APP.TEAM.MEMBERS }
];

const actions: TemplateSearchContentAction[] = [
  { text: 'Adicionar', icon: <IconAdd />, to: PATHS_ROUTES.APP.TEAM.ADD.MEMBER }
];

const Members = () => {
  const { members, page, totalPage, setPage, loading } = useTeam();
  const { permissions } = useAuth();

  const templateSearchContentProps = useMemo(() => {
    const breadcrumbProps = { links };

    return { breadcrumbProps };
  }, []);

  // const debouncedSearch = debounce(
  //   value => setSearch(removeSpecialCharacters(value)),
  //   500
  // );

  // const searchFn = useCallback(debouncedSearch, [debouncedSearch]);

  // const searchMembers = (event: { target: HTMLInputElement }) => {
  //   const value = event.target.value;
  //   if (value.length >= 3) {
  //     searchFn(value);
  //   }
  // };

  return (
    <TemplateSearchContent
      actions={permissions?.includes('MEMBROS_EDITAR') ? actions : undefined}
      // inputSearchProps={{
      //   type: 'text',
      //   placeholder: 'Pesquisar por nome ou CPF',
      //   onChange: searchMembers
      // }}
      {...templateSearchContentProps}
    >
      <Row>
        <Column md={12}>
          <Text tag="span" size="small" color="grayDark" weight="regular">
            Esses são todos os membros subordinados diretos dessa conta
          </Text>
        </Column>
      </Row>

      {loading && <Loading />}

      <Row>
        <Column md={12}>
          {members?.map(({ id, userType, ...rest }) => (
            <CardTeamMember key={id} userType={userType} {...rest}>
              <Button
                as={Link}
                fullWidth
                tertiary
                to={PATHS_ROUTES.APP.TEAM.MEMBER(String(id))}
              >
                Ver mais
              </Button>
            </CardTeamMember>
          ))}
        </Column>

        <Divider />

        <PageNav
          previousAction={() => setPage(page - 1)}
          nextAction={() => setPage(page + 1)}
          isFirstPage={page === 1}
          isLastPage={page === totalPage}
        />
      </Row>
    </TemplateSearchContent>
  );
};

export default Members;
