import styled, { css } from 'styled-components';

import { Card } from 'styles/shared/Card';

export const Wrapper = styled(Card)`
  ${({ theme }) => css`
    padding: ${theme.spacings.large};
    border: 1px solid ${theme.colors.transparent};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
  `}
`;

export const Image = styled.img`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};
  `}
`;
