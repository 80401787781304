import styled, { css, DefaultTheme } from 'styled-components';
import customMedia from 'styles/media-query';

import { Logo, Nav, NavOverlay } from './Nav/styles';
import { Wrapper as Menu } from './Nav/Menu/styles';

import { Header } from 'components/Header/styles';
import { transparentize } from 'polished';

export type WrapperProps = {
  compress: boolean;
  openMenuMobile: boolean;
};

export const Content = styled.main`
  min-height: 100vh;
  padding-bottom: 5rem;
  padding-top: 12.5rem;
`;

const wrapperModifiers = {
  compress: (theme: DefaultTheme) => css`
    --nav-size: ${theme.layout.sidebar.sizeClosed};

    .category {
      font-size: ${theme.font.sizes.xsmall};
      padding: 0 1rem;
      display: block;
      line-height: 1;
      margin: 1.5rem 0 1rem;
      text-align: center;
    }

    ${Logo} {
      img {
        max-width: initial;
      }
    }

    ${Nav} {
      li {
        button {
          display: none;
        }

        ul {
          display: initial;

          li {
            a:not(.active) {
              color: ${theme.colors.grayBlue};
            }
          }
        }
      }
    }

    ${Menu} {
      li {
        span,
        svg:last-child {
          display: none;
        }

        ul {
          li {
            a {
              &.active {
                background: ${transparentize(0.9, theme.colors.blue)};
                padding-left: ${theme.spacings.small};
              }
            }
          }
        }
      }
    }
  `,

  openMenuMobile: () => css`
    ${customMedia.lessThan('large')`
      ${Nav} {
          transform: translateX(0);
      }

      ${NavOverlay} {
        display: block;
      }
    `}
  `
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, compress, openMenuMobile }) => css`
    background: ${theme.mode === 'light'
      ? theme.colors.grayLight
      : theme.colors.grayBlue};

    ${Logo} {
      img {
        max-width: 145px;
      }
    }

    ${customMedia.greaterThan('large')`
      --nav-size: ${theme.layout.sidebar.sizeOpened};

      ${Header} {
        padding-left: calc(var(--nav-size) + 1rem);
      }

      ${Content} {
        padding-left: var(--nav-size);
      }

      ${Nav} {
        width: var(--nav-size);
      }

      ${compress && wrapperModifiers.compress(theme)};
    `}

    ${openMenuMobile && wrapperModifiers.openMenuMobile()};
  `}
`;

export const Profile = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 100%;

    & > div {
      margin-left: ${theme.spacings.small};
    }

    img {
      margin-right: ${theme.spacings.small};
    }

    h5 {
      font-size: ${theme.font.sizes.xmedium};
      line-height: ${theme.font.lineHeight('xmedium')};
      font-weight: ${theme.font.weights.medium};
    }

    span {
      display: block;
      font-size: ${theme.font.sizes.small};
      line-height: ${theme.font.lineHeight('small')};
      color: ${theme.colors.blue};
    }
  `}
`;
