import { breadcrumb, BreadcrumbKeys } from '../Key/constants';
import { BreadcrumbLink } from 'components/Breadcrumb';
import PATHS_ROUTES from 'routes/paths';
import { PIX_OUT_KEYS_TYPES } from 'utils/pix';

export const breadcrumbTransfer: (arg0: BreadcrumbKeys) => BreadcrumbLink[] = (
  keyType: BreadcrumbKeys
) => [
  ...breadcrumb[keyType],
  {
    title: 'Valor da transferência',
    link: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.PHONE)
  }
];

export const modalMessages = {
  title: 'Transação Pix',
  success: (bankType: boolean) => ({
    title: bankType
      ? 'Transação para conta bancária feita com sucesso :)'
      : 'Ja enviamos o valor para a chave Pix escolhida :)',
    subtitle: 'Você pode ver os detalhes da transação no seu extrato!',
    buttonText: 'Entendi'
  }),
  error: (bankType: boolean, error?: string) => ({
    title: 'Transação não concluída :(',
    subtitle: error
      ? error
      : `Revise os dados ${
          bankType ? '' : 'da chave que você escolheu'
        } e tente mais uma vez.`,
    buttonText: 'Tentar novamente'
  })
};
