import styled, { css } from 'styled-components';

type WrapperType = {
  small: boolean;
};

const modifiers = {
  smallContainer: () => css`
    max-width: 46.5rem;
    margin: 0 auto;
  `
};

export const Wrapper = styled.div<WrapperType>`
  ${({ small }) => css`
    margin-top: -5.6rem;

    ${small && modifiers.smallContainer}

    nav {
      width: calc(100% - 33px);
    }

    svg {
      cursor: pointer;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.medium};
  `}
`;
