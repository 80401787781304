import { useMemo } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import {
  Button,
  ButtonBackPage,
  ErrorMessage,
  Text,
  CardValue,
  TableInstallments,
  ToggleView,
  CapturedValueInfo,
  Loading,
  Flex
} from 'components';

import PATHS_ROUTES from 'routes/paths';

import * as S from './styles';

import endpoints from 'services/endpoints';
import {
  BalanceData,
  GetStatementDetailsResponse
} from 'services/statementService';

import useRequest from 'hooks/useRequest';

import { formatSimpleDate } from 'utils/masks';

import { useAuth } from 'contexts/Auth';

import { ReactComponent as IconHelpOutline } from 'assets/images/icon-help-outline.svg';

type ParamsTypes = {
  id: string;
};

const StatementDetail = () => {
  const { id } = useParams<ParamsTypes>();
  const navigate = useNavigate();
  const { permissions } = useAuth();

  const { data: balanceData, error: balaceError } = useRequest<BalanceData>({
    url: endpoints.balance
  });

  const { data, error } = useRequest<GetStatementDetailsResponse>({
    url: id ? endpoints.statementDetails.replace('{id}', id) : null,
    options: {
      revalidateOnFocus: false
    }
  });

  const buttonBackTitle = useMemo(() => {
    switch (data?.type) {
      case 'REFUND':
        return 'Venda cancelada';
      case 'RELEASE':
        return 'Venda realizada';
      case 'CASH_IN':
      case 'GIFT_CASH_IN':
      case 'GIFT_CASHBACK_IN':
      case 'STORE_CASH_IN':
        return 'Depósito realizado';
      default:
        return 'Voltar';
    }
  }, [data]);

  const isRefunded = useMemo(() => data?.status === 'REFUNDED', [data]);

  const color = useMemo(() => {
    return data?.type === 'REFUND' ? 'grayDark' : 'blue';
  }, [data]);

  if (!!error || !!balaceError) {
    return (
      <ErrorMessage
        message="Ocorreu um erro ao carregar os dados, atualize a página e tente
      novamente."
      />
    );
  }

  if (!data || !balanceData) {
    return <Loading fullContent />;
  }

  return (
    <>
      <S.Row>
        <S.BackColumn xs={12} lg={8} orderXs={1} orderLg={1}>
          <ButtonBackPage
            color={color}
            onClick={() => navigate(PATHS_ROUTES.APP.STATEMENT.LIST)}
          >
            {buttonBackTitle}
          </ButtonBackPage>
          <S.StatementInfo>
            <Text tag="span" size="small" color={color}>
              {formatSimpleDate(data.date, `DD [de] MMMM [às] k:mm`)}
            </Text>
            <Text size="large" weight="medium">
              {data.description}
            </Text>
            <Text tag="span" size="small" color={color}>
              NSU: #{data.nsu}
            </Text>
          </S.StatementInfo>
        </S.BackColumn>

        {data.refundAvailable > 0 && permissions?.includes('EXTRATO_CANCELAR') && (
          <S.Column xs={12} lg={4} orderXs={4} orderLg={3}>
            <S.ButtonWrapper>
              <Button
                onClick={() => navigate(PATHS_ROUTES.APP.STATEMENT.REFUND(id))}
                secondary
                fullWidthMobile
              >
                Cancelar venda
              </Button>
            </S.ButtonWrapper>
          </S.Column>
        )}

        <S.ColumnAsRow lg={12} orderXs={3} orderLg={4}>
          <S.Column xs={12} lg={4}>
            <CardValue
              labelTop="Valor bruto"
              value={data.grossAmountInCents}
              lineThrough={isRefunded}
            >
              <Text size="xsmall">Valor de origem da transação</Text>
            </CardValue>
          </S.Column>
          <S.Column xs={12} lg={4}>
            <CardValue
              color={color}
              labelTop="Valor Liquído"
              value={data.amountInCents}
              lineThrough={isRefunded}
            >
              <Text size="xsmall">
                Total da transação com tarifas aplicadas
              </Text>
            </CardValue>
          </S.Column>

          {permissions?.includes('EXTRATO_ANTECIPAR') && (
            <S.Column xs={12} lg={4}>
              <CardValue
                color={isRefunded ? 'grayDark' : 'blackDark'}
                labelTop="Antecipação"
                value={data.antecipateAmountInCents}
              >
                {data.canAnticipate &&
                  data.grossAmountInCents === data.refundAvailable && (
                    <Link to={PATHS_ROUTES.APP.STATEMENT.ANTICIPATE(id)}>
                      <Text size="small" color="blue" align="right">
                        Antecipar
                      </Text>
                    </Link>
                  )}

                {data.automaticAnticipate && (
                  <Flex direction="row" wrap="nowrap">
                    <IconHelpOutline width="60" />
                    <Text size="xsmall" color="blackDark">
                      Antecipação automática ativa
                    </Text>
                  </Flex>
                )}
              </CardValue>
            </S.Column>
          )}
        </S.ColumnAsRow>
      </S.Row>

      {data.type !== 'REFUND' && (
        <>
          <S.Row>
            <S.CompositionTitle>
              <Text size="xxmedium" weight="medium">
                Composição detalhada
              </Text>
            </S.CompositionTitle>
            <CapturedValueInfo
              color={isRefunded ? 'grayDark' : 'blackDark'}
              title="Pago com saldo"
              value={data.amountDetailed.cashAmountInCents}
              lineThrough={isRefunded}
            />
            <CapturedValueInfo
              color={data.type === 'REFUND' ? 'grayDark' : 'blackDark'}
              title="Pago no crédito"
              value={data.amountDetailed.creditAmountInCents}
              lineThrough={isRefunded}
            />
          </S.Row>

          {data.qtdInstallments > 0 && (
            <S.Row>
              <ToggleView>
                <TableInstallments
                  splits={data.installments}
                  isRefunded={isRefunded}
                />
              </ToggleView>
            </S.Row>
          )}
        </>
      )}
    </>
  );
};

export default StatementDetail;
