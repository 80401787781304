import React, { SVGProps } from 'react';

import { ReactComponent as IconMobile } from 'assets/images/pix/icon-mobile-outline.svg';
import { ReactComponent as IconDocument } from 'assets/images/pix/icon-documents-cpf.svg';
import { ReactComponent as IconMail } from 'assets/images/pix/icon-mail-outline.svg';
import { ReactComponent as IconAddress } from 'assets/images/pix/icon-virtual-address.svg';
import {
  cnpjMask,
  cpfMask,
  formatMask,
  getCodeAndMaskByPhoneNumber
} from './masks';

export type pixTypes =
  | 'PHONE_NUMBER'
  | 'PERSONAL_TAX_ID'
  | 'CORPORATE_TAX_ID'
  | 'EMAIL'
  | 'EVP'
  | 'ACCOUNT';

export const pixKeysType = {
  PHONE_NUMBER: 'PHONE_NUMBER',
  EMAIL: 'EMAIL',
  EVP: 'EVP',
  PERSONAL_TAX_ID: 'PERSONAL_TAX_ID',
  CORPORATE_TAX_ID: 'CORPORATE_TAX_ID'
};

type formatPixKeyTypeObj = {
  [key: string]: string;
};

type getIconTypeObj = {
  [key: string]: React.FC<SVGProps<SVGSVGElement>>;
};

export const keyTypes: pixTypes[] = [
  'PHONE_NUMBER',
  'PERSONAL_TAX_ID',
  'EMAIL',
  'EVP'
];

export function formatPixKeyType(type: pixTypes): string {
  const types: formatPixKeyTypeObj = {
    PHONE_NUMBER: 'Número de celular',
    EMAIL: 'Endereço de e-mail',
    EVP: 'Chave aleatória',
    PERSONAL_TAX_ID: 'CNPJ',
    CORPORATE_TAX_ID: 'CNPJ'
  };

  return types[type];
}

export function getIconType(type: pixTypes) {
  const typesIcon: getIconTypeObj = {
    PHONE_NUMBER: IconMobile,
    EMAIL: IconMail,
    EVP: IconAddress,
    PERSONAL_TAX_ID: IconDocument,
    CORPORATE_TAX_ID: IconDocument
  };

  return typesIcon[type];
}

export function formatKeyPhoneNumber(key: string) {
  let phone = key;
  const [code, mask] = getCodeAndMaskByPhoneNumber(phone);
  phone = phone.replace(code, '');
  return `${code} ${formatMask(phone, mask)}`;
}

export function formatKey(type: pixTypes, key: string) {
  const typesIcon: formatPixKeyTypeObj = {
    PHONE_NUMBER: formatKeyPhoneNumber(key),
    EMAIL: key,
    EVP: key,
    PERSONAL_TAX_ID: cpfMask(key),
    CORPORATE_TAX_ID: cnpjMask(key)
  };

  return typesIcon[type];
}

export type outputTypes = 'PNG' | 'RAW' | 'BASE64';

export const outputItensTypes = {
  PNG: 'PNG' as outputTypes,
  RAW: 'RAW' as outputTypes,
  BASE64: 'BASE64' as outputTypes
};

export type claimStatusTypes =
  | 'PENDING'
  | 'OPEN'
  | 'WAITING_RESOLUTION'
  | 'CONFIRMED'
  | 'CANCELLED'
  | 'COMPLETED';

export type claimTypes = 'SENT' | 'RECEIVED';

export type claimLocationState = { claim: claimTypes };

type formatClaimItenssObj = {
  [key: string]: claimTypes;
};

export const claimItensTypes: formatClaimItenssObj = {
  SENT: 'SENT',
  RECEIVED: 'RECEIVED'
};

type formatClaimStatusObj = {
  [key: string]: claimStatusTypes;
};

export const CLAIM_STATUS: formatClaimStatusObj = {
  PENDING: 'PENDING',
  OPEN: 'OPEN',
  WAITING_RESOLUTION: 'WAITING_RESOLUTION',
  CONFIRMED: 'CONFIRMED',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED'
};

export const PIX_OUT_KEYS_TYPES = {
  FAVORITES: 'favorites',
  PHONE: 'phone',
  DOCUMENT: 'document',
  EMAIL: 'email',
  RANDOM: 'random',
  BANK: 'bank'
};
