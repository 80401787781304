import iconAlert from 'assets/images/icon-alert.svg';

import { Breadcrumb, Button, Flex, PaginationLabels, Text } from 'components';
import Divider from 'styles/shared/Divider';
import Margin from 'styles/shared/Margin';
import Card from 'styles/shared/Card';

import { useCredentials } from 'contexts/Credentials';

import { breadcrumbItems, STEPS } from './constants';

import ModalSuccess from './Components/ModalSuccess';

function GenerateCredential() {
  const {
    page,
    nextPage,
    previousPage,
    pageLabels,
    nextButtonAvailable,
    isLastStep,
    credential,
    modal
  } = useCredentials();

  return (
    <>
      <Breadcrumb links={breadcrumbItems} />

      <Margin mt="xlarge" mb="large">
        <PaginationLabels
          pageLabels={pageLabels}
          currentPage={page.label}
          withProgress
        />
      </Margin>

      <Card>{STEPS[page.key]}</Card>

      <Margin mt="medium">
        <Flex justifyContent="space-between">
          <Button secondary onClick={previousPage}>
            Anterior
          </Button>
          <Button filled onClick={nextPage} disabled={!nextButtonAvailable}>
            {isLastStep ? 'Concluir' : 'Próximo'}
          </Button>
        </Flex>
      </Margin>

      {isLastStep && credential.data && (
        <>
          <Margin mt="xlarge" mb="medium">
            <Divider withBorder size="xsmall" />
          </Margin>

          <Flex alignItems="center" justifyContent="center" direction="column">
            <img height={60} src={iconAlert} alt="Alert" />
            <Text tag="p" size="small" weight="regular">
              Sempre salve suas credenciais em seu sistema, sem isso, não será
              possível acessá-las novamente.
            </Text>
          </Flex>
        </>
      )}

      {modal.isActive && <ModalSuccess />}
    </>
  );
}

export default GenerateCredential;
