import * as Yup from 'yup';
import { fieldNames } from 'pages/Accreditation/shared/form';
import { checkCEP } from 'utils/validations';

export const validation = Yup.object().shape({
  [fieldNames.cep]: Yup.string()
    .required('CEP obrigatório')
    .test('is valid cep', 'CEP inválido', value => !!value && checkCEP(value)),
  [fieldNames.street]: Yup.string().required('Rua obrigatória'),
  [fieldNames.neighborhood]: Yup.string().required('Bairro obrigatório'),
  [fieldNames.city]: Yup.string().required('Cidade obrigatório'),
  [fieldNames.state]: Yup.string().required('Estado obrigatório'),
  [fieldNames.number]: Yup.string().required('Número obrigatório'),
  [fieldNames.complement]: Yup.string()
});
