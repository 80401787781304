import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  alias: yup.string().required('Apelido da empresa é obrigatório'),
  user: yup.object().shape({
    name: yup
      .string()
      .required('Nome do usuário obrigatório')
      .max(50, 'No máximo 50 caracteres'),
    document: yup.string().required('CPF obrigatório').min(14, 'CPF inválido'),
    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    phone: yup
      .string()
      .required('Celular é obrigatório')
      .min(15, 'Celular inválido')
  })
});
