import { createContext, useState, useContext } from 'react';
import queryString from 'query-string';
import useRequest from 'hooks/useRequest';

import endpoints from 'services/endpoints';

import {
  IReportListData,
  ReportListDataContent
} from 'services/reportService/types';
import { defaultFilters, PAGE_SIZE } from './constants';
import * as types from './types';

const ReportListContext = createContext<types.ContextData | null>(null);

function ReportListProvider({ children }: types.ProviderData) {
  const [selectedFilters, setSelectedFilters] = useState<types.Filters>({
    ...defaultFilters
  });
  const [page, setPage] = useState(0);

  const [finalReportList, setFinalReportList] = useState<
    ReportListDataContent[]
  >([]);

  const {
    data: reportData,
    loading,
    error
  } = useRequest<IReportListData>({
    url: queryString.stringifyUrl({
      url: endpoints.report,
      query: {
        period:
          selectedFilters.period !== '' ? selectedFilters.period : undefined,
        status: selectedFilters.status,
        typeFiles: selectedFilters.typeFiles,
        page: page,
        size: PAGE_SIZE
      }
    }),
    options: {
      revalidateOnFocus: false,
      onSuccess(data: IReportListData) {
        setFinalReportList([...finalReportList, ...data.data]);
      }
    }
  });

  return (
    <ReportListContext.Provider
      value={{
        page,
        error,
        setPage,
        loading,
        selectedFilters,
        finalReportList,
        setSelectedFilters,
        report: reportData,
        setFinalReportList
      }}
    >
      {children}
    </ReportListContext.Provider>
  );
}

function useReportList(): types.ContextData {
  const context = useContext(ReportListContext);

  if (!context)
    throw new Error('useReportList must be used within an ReportListProvider');

  return context;
}

export { useReportList, ReportListProvider, ReportListContext };
