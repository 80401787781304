import styled, { css } from 'styled-components';

export const TaxValue = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    padding-bottom: ${theme.spacings.medium};
    border-bottom: 1px solid
      ${theme.mode === 'light' ? theme.colors.grayDark : theme.colors.black};
  `}
`;
