import styled, { css } from 'styled-components';

import { Row } from 'styles/shared/Grid';
import { ReactComponent as IconRightNext } from 'assets/images/icon-right-next.svg';

import { TemplateCloseContentTypes } from './';

type CloseWrapperProps = Pick<TemplateCloseContentTypes, 'backLink'>;

const modifiers = {
  backLink: () => css`
    justify-content: space-between;
  `
};

export const CloseWrapper = styled(Row)<CloseWrapperProps>`
  ${({ theme, backLink }) => css`
    display: flex;
    justify-content: flex-end;
    padding: 0 0 0 ${theme.spacings.xsmall};
    margin-bottom: ${theme.spacings.medium};

    ${backLink && modifiers.backLink()};
  `}
`;

export const IconBack = styled(IconRightNext)`
  transform: rotate(180deg);
`;
