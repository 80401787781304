import { ModalInfoButtons } from 'components';

import { ReactComponent as IconStopwatch } from 'assets/images/icon-stopwatch.svg';

type InfoModalProps = {
  closeModal: () => void;
};

export function InfoModal({ closeModal }: InfoModalProps) {
  return (
    <ModalInfoButtons
      onClose={closeModal}
      primaryButtonText="OK"
      icon={<IconStopwatch />}
      title="Calculando seu relatório"
      primaryButtonAction={closeModal}
      secondaryButtonText="Cancelar cálculo"
      secondaryButtonAction={() => alert('cancelar')}
      description="O cálculo do seu relatório está sendo efetuado. Informaremos assim que
    concluido e pronto para download."
    />
  );
}
