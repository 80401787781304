import SlickSlider, { Settings } from 'react-slick';

import { ArrowLeft, ArrowRight } from './Arrows';

import * as S from './styles';

export type SliderSettings = Settings;

export type SliderProps = {
  settings: SliderSettings;
} & Settings;

const Slider = ({ settings, children }: SliderProps) => {
  const sliderSettings: Settings = {
    ...settings,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />
  };

  return (
    <S.Wrapper>
      <SlickSlider {...sliderSettings}>{children}</SlickSlider>
    </S.Wrapper>
  );
};

export default Slider;
