import { TutorialRequestKey } from '../components';
import * as S from '../../styles';
const Payhow = () => {
  return (
    <div>
      <S.Title tag="h1" size="xlarge" weight="bold">
        Payhow
      </S.Title>

      <TutorialRequestKey name="Payhow" showConfigurationMessage={false} />

      <S.Paragraph tag="p" color="grayBlue">
        Recebeu sua chave? Ótimo! Com a sua chave de integração será necessário
        abrir um chamado para o suporte técnico da Payhow solicitando a
        integração através da página{' '}
        <S.Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6"
        >
          Plataforma PAYHOW - Jira Service Management (atlassian.net)
        </S.Link>
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Caso ainda não tenha cadastro na central de suporte, será necessário se
        cadastrar para abrir um chamado. Após estar logado é só preencher os
        campos em <strong>“Suporte técnico gratuito”</strong> e na mensagem
        solicitar a integração com Ame Digital.
      </S.Paragraph>
    </div>
  );
};

export default Payhow;
