import Text from 'components/Text';
import Link from 'components/Link';

import * as S from './styles';

export type CardImageProps = {
  text: string | React.ReactElement;
  src: string;
  alt: string;
  to: string;
};

export function CardImageLink({ text, src, alt, to }: CardImageProps) {
  return (
    <S.Wrapper as={Link} to={to}>
      <S.Image src={src} alt={alt} />

      <Text align="center" size="small">
        {text}
      </Text>
    </S.Wrapper>
  );
}

export default CardImageLink;
