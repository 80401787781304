import styled from 'styled-components';

import Card from 'styles/shared/Card';

export const Wrapper = styled.div`
  nav {
    width: 100%;
  }
`;

export const WrapperCard = styled(Card)`
  margin-top: 1rem;
  padding: 1.5rem 1rem;
  cursor: pointer;
`;
