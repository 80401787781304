import { useState, useCallback, useMemo } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import useMutate from 'hooks/useMutate';
import useRequest from 'hooks/useRequest';
import queryString from 'query-string';

import { ReactComponent as IconPix } from 'assets/images/pix/icon-pix.svg';
import {
  Text,
  Flex,
  Breadcrumb,
  TemplateShortContent,
  Loading
} from 'components';
import Divider from 'styles/shared/Divider';

import { claimTypes, claimItensTypes, CLAIM_STATUS } from 'utils/pix';

import PATHS_ROUTES from 'routes/paths';
import endpoints from 'services/endpoints';
import { IClaimDataResponse } from 'services/pixService/types';
import { pixKeysType } from 'utils/pix';
import { usePixRegister } from 'contexts/Pix/PixRegister';

import EmptyData from './components/EmptyData';
import ClaimCards from './components/ClaimCards';
import ValidationCode from './components/ValidationCode';
import Modal from './components/Modal';
import { breadcrumbItems, STEP_ITENS } from './constants';

type LocationState = {
  claim: claimTypes;
};

type MutateParams = {
  url: string;
  validationCode?: string;
};

type ClaimBody = {
  reason: string;
  validationCode?: string;
};

const ClaimActions = { cancel: 'CANCEL', confirm: 'CONFIRM' };

const Requests = () => {
  const location = useLocation();
  const state = location.state as LocationState;

  const { sendCodeValidation } = usePixRegister();
  const [step, setStep] = useState(STEP_ITENS.INFO);
  const [action, setAction] = useState('');

  const {
    state: { loading: loadingMutate },
    mutate
  } = useMutate();

  const { loading, data } = useRequest<IClaimDataResponse[]>({
    url: state
      ? queryString.stringifyUrl({
          url: endpoints.pixClaim,
          query: {
            status: CLAIM_STATUS.WAITING_RESOLUTION,
            claimer: state.claim === claimItensTypes.SENT
          }
        })
      : null
  });

  const [modal, setModal] = useState(false);
  const [modalValidationCode, setModalValidationCode] = useState(false);
  const [claimData, setClaimData] = useState<IClaimDataResponse>(
    {} as IClaimDataResponse
  );

  const handleClaimItem = (claim: IClaimDataResponse) => {
    if (state && state.claim === claimItensTypes.RECEIVED) {
      setClaimData(claim);
      setModal(true);
    }
  };

  const handleMutate = useCallback(
    ({ url, validationCode }: MutateParams) => {
      const body: ClaimBody = { reason: 'USER_REQUESTED' };
      if (validationCode) body.validationCode = validationCode;

      mutate({
        method: 'post',
        endpoint: url,
        onSuccess: () => {
          setModalValidationCode(false);
          setModal(true);
          if (action === ClaimActions.cancel)
            setStep(STEP_ITENS.CANCEL_SUCCESS);
          else setStep(STEP_ITENS.CONFIRM_SUCCESS);
        },
        onFailure: () => {
          setModalValidationCode(false);
          setModal(true);
          setStep(STEP_ITENS.FAIL);
        },
        body: body
      });
    },
    [action, mutate]
  );

  const onConfirmClaim = useCallback(
    (validationCode?: string) => {
      const url = endpoints.confirmClaim.replace('{id}', claimData.id);
      handleMutate({
        url,
        validationCode
      });
    },
    [claimData.id, handleMutate]
  );

  const onCancelClaim = useCallback(
    (validationCode?: string) => {
      const url = endpoints.cancelClaim.replace('{id}', claimData.id);
      handleMutate({
        url,
        validationCode
      });
    },
    [claimData.id, handleMutate]
  );

  const handleSendCodeValidation = useCallback(() => {
    sendCodeValidation({
      keyType: claimData.keyType,
      key: claimData.key
    });
  }, [claimData, sendCodeValidation]);

  const actionWithValidation = useCallback(() => {
    setModal(false);
    setModalValidationCode(true);
    handleSendCodeValidation();
  }, [handleSendCodeValidation]);

  const handleConfirmButtonClaim = useCallback(() => {
    setAction(ClaimActions.confirm);
    if (
      claimData.keyType === pixKeysType.EMAIL ||
      claimData.keyType === pixKeysType.PHONE_NUMBER
    ) {
      actionWithValidation();
    } else {
      onConfirmClaim();
    }
  }, [actionWithValidation, claimData.keyType, onConfirmClaim]);

  const handleCancelButtonClaim = useCallback(() => {
    setAction(ClaimActions.cancel);
    if (
      claimData.keyType === pixKeysType.EMAIL ||
      claimData.keyType === pixKeysType.PHONE_NUMBER
    ) {
      actionWithValidation();
    } else {
      onCancelClaim();
    }
  }, [actionWithValidation, claimData.keyType, onCancelClaim]);

  const descriptionValidationCode = useMemo(() => {
    if (claimData.keyType === pixKeysType.EMAIL) {
      return `Digite abaixo o código que enviamos por e-mail para o endereço
      eletrônico cadastrado na sua conta`;
    }
    return `Digite abaixo o código que enviamos por SMS para o número de celular cadastrado na sua conta`;
  }, [claimData.keyType]);

  if (!state) {
    return <Navigate to={PATHS_ROUTES.APP.PIX.KEYS.DEFAULT} replace />;
  }

  if (loading) {
    return <Loading fullContent />;
  }

  return (
    <>
      <Breadcrumb links={breadcrumbItems(state.claim)} />

      <Divider size="xlarge" />

      {!data || data.length === 0 ? (
        <EmptyData claim={state.claim} />
      ) : (
        <TemplateShortContent>
          <Flex alignItems="center">
            <IconPix />
            <Text ml="small" tag="h1" size="large" weight="medium">
              Minhas Chaves
            </Text>
          </Flex>

          <Divider size="medium" />

          {!modalValidationCode && (
            <ClaimCards
              type={state.claim}
              handleClaimItem={handleClaimItem}
              data={data}
            />
          )}

          {modalValidationCode && (
            <ValidationCode
              sendCodeValidation={handleSendCodeValidation}
              changeValue={value => {
                if (action === ClaimActions.cancel) onCancelClaim(value);
                else onConfirmClaim(value);
              }}
              onBackButton={() => {
                setModal(true);
                setModalValidationCode(false);
              }}
              loading={loadingMutate}
              description={descriptionValidationCode}
            />
          )}
        </TemplateShortContent>
      )}

      {modal && (
        <Modal
          onClose={() => setModal(false)}
          claimData={claimData}
          step={step}
          loading={loadingMutate}
          setStep={setStep}
          onConfirmButtonClaim={handleConfirmButtonClaim}
          onCancelButtonClaim={handleCancelButtonClaim}
        />
      )}
    </>
  );
};

export default Requests;
