import { useMemo } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { ReactComponent as IconUser } from 'assets/images/icon-profile-blue.svg';

import {
  Breadcrumb,
  Button,
  CardAccordion,
  CardAccordionPix,
  TemplateShortContent,
  Text
} from 'components';
import Divider from 'styles/shared/Divider';
import Margin from 'styles/shared/Margin';

import { pixTypes } from 'utils/pix';
import { usePixOutFlux } from 'contexts/Pix/PixOutFlux';
import { formatInput, maskOnlyNumbers } from 'utils/masks';
import PATHS_ROUTES from 'routes/paths';

import { BreadcrumbKeys } from '../Key/constants';
import { breadcrumb } from '../Key/constants';

import * as S from './styles';

type TransferValueProps = {
  nextPage: () => void;
  previousPage: () => void;
};

const TransferValue = ({ nextPage, previousPage }: TransferValueProps) => {
  let { keyType } = useParams<{ keyType: BreadcrumbKeys }>();

  const {
    recipientData,
    recipientBankData,
    pixType,
    transferValue,
    setTransferValue,
    transferDescription,
    setTransferDescription
  } = usePixOutFlux();

  const documentMasked = useMemo(() => {
    if (recipientBankData?.documentNumber) {
      if (recipientBankData.documentNumber.length === 11)
        return formatInput.CPF(recipientBankData.documentNumber);
      return formatInput.CNPJ(recipientBankData.documentNumber);
    }
    return '';
  }, [recipientBankData]);

  if (recipientBankData) {
    keyType = 'bank';
  }

  if (!keyType || !breadcrumb[keyType]) {
    return <Navigate to={PATHS_ROUTES.APP.PIX.TRANSFER.DEFAULT} replace />;
  }

  return (
    <>
      <Breadcrumb links={breadcrumb[keyType]} />

      <TemplateShortContent>
        <Margin my="large">
          <Text tag="h1" size="large" weight="medium">
            Destinatário e valor do pagamento
          </Text>

          <Divider size="xsmall" />

          <Text tag="p">Dados de quem vai receber e valor</Text>

          <Divider size="medium" />

          <Margin mx="large">
            {recipientData && (
              <>
                <Divider size="medium" />

                <Text tag="p" weight="bold" size="small">
                  Dados de quem vai receber o Pix:
                </Text>

                <CardAccordionPix
                  keyType={pixType as pixTypes}
                  keyValue={recipientData.key}
                  user={{
                    institution: recipientData.institutionName || '',
                    name: recipientData.userName || '',
                    document: recipientData.userTaxId || ''
                  }}
                  expanded
                />
              </>
            )}

            {recipientBankData && (
              <>
                <Divider size="medium" />

                <Text tag="p" weight="bold" size="small">
                  Dados do destinatário:
                </Text>

                <CardAccordion
                  icon={<IconUser />}
                  text={recipientBankData.name || ''}
                >
                  <Text size="small" my="xsmall" weight="bold">
                    {documentMasked}
                  </Text>
                  <Text size="small">{recipientBankData.institutionName}</Text>
                </CardAccordion>
              </>
            )}

            <Divider size="medium" />

            <S.InputText
              name="value"
              label="Valor da transação"
              maskType="CURRENCY"
              onlyNumbers
              value={transferValue}
              onChange={el =>
                setTransferValue(maskOnlyNumbers(el.target.value))
              }
              placeholder="Digite o valor"
            />

            <Divider size="medium" />

            <S.InputText
              name="description"
              label="Descrição do pagamento (Opcional)"
              value={transferDescription}
              onChange={el => setTransferDescription(el.target.value)}
              placeholder="Digite aqui sua mensagem"
              size={9}
            />

            <Divider size="medium" />

            <Button center filled disabled={!transferValue} onClick={nextPage}>
              Ir para confirmação
            </Button>

            <Button
              center
              filled
              filledBgColor="transparent"
              filledColor="blue"
              onClick={previousPage}
            >
              Voltar
            </Button>
          </Margin>
        </Margin>
      </TemplateShortContent>
    </>
  );
};

export default TransferValue;
