import noClaimImage from 'assets/images/pix/intersection.png';

import { Flex, Text } from 'components';

import { claimTypes, claimItensTypes } from 'utils/pix';
import * as S from '../../styles';

type EmptyDataProps = {
  claim: claimTypes;
};

const EmptyData = ({ claim }: EmptyDataProps) => {
  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      <S.Content>
        <img
          src={noClaimImage}
          alt="imagem de nenhuma portabilidade/reivindicação"
        />

        <Text
          my="xsmall"
          color="grayExtraMedium"
          align="center"
          size="large"
          weight="regular"
        >
          {claim === claimItensTypes.SENT
            ? `Você não fez um pedido de portabilidade ou reivindicação`
            : `Você não recebeu nenhuma solicitação de portabilidade ou reivindicação`}
        </Text>
      </S.Content>
    </Flex>
  );
};

export default EmptyData;
