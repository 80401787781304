import * as S from './styles';

import { getInitialsNameAndSurname } from 'utils/masks';
import { ProfileSizes, ThemeColors } from 'types/global';

export type InitialNameVariants = 'rounded' | 'square' | 'outline';

export type InitialNameProps = {
  name: string;
  size?: ProfileSizes;
  fullWidth?: boolean;
  outlineColor?: ThemeColors;
  width?: number;
  height?: number;
  noPadding?: boolean;
  variant?: InitialNameVariants;
};

const InitialName = ({
  name,
  size = 'medium',
  fullWidth,
  outlineColor = 'blue',
  width,
  height,
  noPadding,
  variant
}: InitialNameProps) => (
  <S.Wrapper
    size={size}
    fullWidth={fullWidth}
    outlineColor={outlineColor}
    width={width}
    height={height}
    variant={variant}
    noPadding={noPadding}
  >
    {getInitialsNameAndSurname(name)}
  </S.Wrapper>
);

export default InitialName;
