import { useCallback } from 'react';

import endpoints from 'services/endpoints';
import useMutate from 'hooks/useMutate';
import { invertDate, minifyLettersMask } from 'utils/masks';
import { fieldNames, AccreditationValues } from '../form';

interface ISellerValidation {
  cnpj: string;
  social: string;
  cep: string;
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  number: string;
  complement: string;
  name: string;
  cpf: string;
  ownerPhone: string;
  username: string;
  email: string;
  news: boolean;
  segment: string;
  installment: string;
  feeId: string;
  feeType: 'PARTNERSHIP' | 'INDIVIDUAL' | 'CITY' | 'PLATFORM';
  merchantType: 'PHYSICAL' | 'DIGITAL';
  partnership?: string;
  website?: string;
  platformName?: string;
  partnershipName?: string;
  receiveAdvance: boolean;
  birthday: string;
  alias: string;
  platformId?: string;
}

function getRawNumber(number: string) {
  return number && number.replace(/[^\d]/g, '');
}

export function convertValuesToSeller(
  values: AccreditationValues,
  merchantType: 'PHYSICAL' | 'DIGITAL'
): ISellerValidation {
  return {
    cnpj: getRawNumber(values[fieldNames.cnpj]),
    social: values[fieldNames.social],
    cep: values[fieldNames.cep],
    street: values[fieldNames.street],
    neighborhood: values[fieldNames.neighborhood],
    city: values[fieldNames.city],
    state: values[fieldNames.state],
    number: values[fieldNames.number].toString(),
    complement: values[fieldNames.complement],
    name: values[fieldNames.name],
    cpf: getRawNumber(values[fieldNames.cpf]),
    ownerPhone: values[fieldNames.ownerPhone],
    username: values[fieldNames.username],
    email: values[fieldNames.email],
    news: !!values[fieldNames.news],
    installment: values[fieldNames.installment],
    feeId: values[fieldNames.feeId],
    segment: values[fieldNames.segment],
    feeType: values[fieldNames.feeType] as
      | 'PARTNERSHIP'
      | 'INDIVIDUAL'
      | 'CITY'
      | 'PLATFORM',
    merchantType,
    partnership: values[fieldNames.partnership],
    receiveAdvance: !!values[fieldNames.receiveAdvance],
    birthday: invertDate(values[fieldNames.birthday]),
    alias: minifyLettersMask(values[fieldNames.social]),
    platformId: values.platform?.id
  };
}

export function useSellerValidation() {
  const { mutate, state } = useMutate();

  const validate = useCallback(
    (seller: ISellerValidation) => {
      return new Promise((resolve, reject) => {
        mutate({
          endpoint: endpoints.sellerValidation,
          body: seller,
          onFailure: reject,
          onSuccess: resolve
        });
      });
    },
    [mutate]
  );

  return {
    ...state,
    validate
  };
}
