import Input, { InputProps } from 'components/Input';
import { Field, FieldProps } from 'formik';
import { getErrorMessage } from 'utils/formik';

type WrapperProps = FieldProps & InputProps;

const Wrapper = ({ field, form, ...props }: WrapperProps) => (
  <Input
    errorMessage={getErrorMessage(field.name, form)}
    {...props}
    {...field}
  />
);

const InputField = (props: InputProps) => (
  <Field component={Wrapper} {...props} />
);

export default InputField;
