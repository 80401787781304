import React, { useMemo, useState, createContext } from 'react';
import useMutate from 'hooks/useMutate';
import endpoints from 'services/endpoints';
import queryString from 'query-string';

import Divider from 'styles/shared/Divider';
import { ReactComponent as IconPix } from 'assets/images/pix/icon-pix.svg';

import {
  Breadcrumb,
  Flex,
  Loading,
  ModalStatus,
  TemplateShortContent,
  Text
} from 'components';

import {
  SelectPixKey,
  ConfirmPixKey,
  SetValueAndInformations,
  ShareQRCode
} from './components';

import {
  breadcrumbItems,
  errorCodes,
  initialContext,
  modalStatusMessages
} from './constants';

import { IDictKeyData } from 'services/pixService/types';
import { outputItensTypes } from 'utils/pix';
import { ErrorCodes, PixContextProps } from './types';

export const PixContext = createContext<PixContextProps>(initialContext);

const PixReiceveQR = () => {
  const [page, setPage] = useState(0);
  const [pixKey, setPixKey] = useState<IDictKeyData | undefined>(undefined);
  const [pixValue, setPixValue] = useState<string | number>('');
  const [pixIdentifier, setPixIdentifier] = useState<string | number>('');
  const [pixDescription, setPixDescription] = useState<string | number>('');
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const pngQRCodeRequest = useMutate();
  const rawQRCodeRequest = useMutate();

  const handleGenerateQRCode = () => {
    const requestBody = {
      destinationKey: pixKey?.key,
      transactionId: pixIdentifier,
      description: pixDescription,
      city: 'SP',
      amount: pixValue
    };

    pngQRCodeRequest.mutate({
      endpoint: queryString.stringifyUrl({
        url: endpoints.pixQRCode,
        query: {
          output: outputItensTypes.PNG
        }
      }),
      body: requestBody
    });

    rawQRCodeRequest.mutate({
      endpoint: queryString.stringifyUrl({
        url: endpoints.pixQRCode,
        query: {
          output: outputItensTypes.RAW
        }
      }),
      body: requestBody
    });

    setModalIsOpened(true);
  };

  const handlePageNavigation = (page: number) => {
    setPage(page);
  };

  const handlePixKey = (data: IDictKeyData) => {
    setPixKey(data);
  };

  const handleInputInformation = (
    key: 'value' | 'identifier' | 'description',
    value: string | number
  ) => {
    if (key === 'value') {
      setPixValue(value);
    }

    if (key === 'identifier') {
      setPixIdentifier(value);
    }

    if (key === 'description') {
      setPixDescription(value);
    }
  };

  const handleModalActionButton = () => {
    setModalIsOpened(!modalIsOpened);
    handlePageNavigation(pngQRCodeRequest.state?.error ? 0 : 3);
  };

  return (
    <PixContext.Provider
      value={{
        pixValue,
        pixIdentifier,
        pixDescription,
        pngQRCodeRequest,
        rawQRCodeRequest,
        handlePageNavigation,
        handleInputInformation
      }}
    >
      <Breadcrumb links={breadcrumbItems} />

      <Divider size="xlarge" />

      {pngQRCodeRequest.state?.loading || rawQRCodeRequest.state?.loading ? (
        <LoadingComponent />
      ) : pngQRCodeRequest.state?.status &&
        rawQRCodeRequest.state?.status &&
        modalIsOpened ? (
        <ModalComponent
          status={pngQRCodeRequest.state.status}
          error={pngQRCodeRequest.state?.error}
          handleModalActionButton={handleModalActionButton}
        />
      ) : (
        <TemplateShortContent>
          <Flex alignItems="center">
            <IconPix />
            <Text ml="small" tag="h1" size="large" weight="medium">
              Receber Pix
            </Text>
          </Flex>

          <Divider size="medium" />

          {page === 0 ? (
            <SelectPixKey handlePixKey={handlePixKey} />
          ) : page === 1 ? (
            <ConfirmPixKey pixKey={pixKey} />
          ) : page === 2 ? (
            <SetValueAndInformations
              handleGenerateQRCode={handleGenerateQRCode}
            />
          ) : page === 3 ? (
            <ShareQRCode />
          ) : null}
        </TemplateShortContent>
      )}
    </PixContext.Provider>
  );
};

const ModalComponent = ({
  status,
  error,
  handleModalActionButton
}: {
  status: number;
  error?: string | null;
  handleModalActionButton: () => void;
}) => {
  const statusCode = useMemo(() => {
    return errorCodes.includes(status) ? status : 500;
  }, [status]) as ErrorCodes;

  return (
    <TemplateShortContent>
      <ModalStatus
        title="Receber Pix"
        description={modalStatusMessages.description[statusCode]}
        onClose={handleModalActionButton}
        action={{
          onClick: handleModalActionButton,
          text: modalStatusMessages.button[statusCode]
        }}
        variant={error ? 'error' : 'success'}
      >
        <Text tag="p" size="medium" align="center" weight="bold">
          {modalStatusMessages.title[statusCode]}
        </Text>
      </ModalStatus>
    </TemplateShortContent>
  );
};

const LoadingComponent = () => (
  <TemplateShortContent>
    <Flex alignItems="center" justifyContent="center">
      <Loading data-testid="loading-component" />
    </Flex>
  </TemplateShortContent>
);

export default PixReiceveQR;
