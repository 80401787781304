import { useState, useEffect, useMemo, useCallback } from 'react';

import * as S from './styles';

import Divider from 'styles/shared/Divider';

import { Text, Button, Input, Money } from 'components';
import { maskOnlyNumbers, formatCurrency } from 'utils/masks';
import { calcInputWidth } from './utils';
import { ThemeColors } from 'types/global';

export type InputValueProps = {
  initialValue: string;
  label: string;
  description?: string;
  editingDescription?: string;
  disabledInput?: boolean;
  disabled?: boolean;
  onEditing: (edditing: boolean) => void;
  onApplyValue: (
    value: string,
    hasError: boolean,
    resetInputValue?: () => void | undefined
  ) => void;
  maxValue?: string;
  resetAmount?: boolean;
  color?: ThemeColors;
};

const InputValue = ({
  initialValue,
  label,
  description,
  editingDescription,
  disabledInput = false,
  disabled = false,
  onEditing,
  onApplyValue,
  maxValue,
  resetAmount = false,
  color
}: InputValueProps) => {
  const [amount, setAmount] = useState(String(initialValue));
  const [showInput, setShowInput] = useState(false);
  const [disabledMoney, setDisabledMoney] = useState(disabledInput);
  const [inputWidth, setInputWidth] = useState(() => {
    return calcInputWidth(amount.length);
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    if (showInput) {
      setInputWidth(() => {
        const value = maskOnlyNumbers(amount);
        return calcInputWidth(value.length);
      });
    }
  }, [amount, showInput]);

  const buttonApplyText = useMemo(() => {
    return showInput ? 'Aplicar' : 'Editar';
  }, [showInput]);

  const handleReset = useCallback(() => {
    setAmount(String(initialValue));
    setShowInput(false);
    setDisabledMoney(false);
  }, [initialValue]);

  const handleEditApplyButton = useCallback(() => {
    setError(false);

    if (showInput) {
      const amountNumbers = maskOnlyNumbers(amount);
      const maxNumbers = maxValue ? parseInt(maxValue) : parseInt(initialValue);

      if (
        parseInt(amountNumbers) > maxNumbers ||
        parseInt(amountNumbers) <= 0 ||
        !amountNumbers
      ) {
        setError(true);
        onApplyValue('', true);
      } else if (parseInt(amountNumbers) === maxNumbers) {
        handleReset();
        onApplyValue(amountNumbers, false);
      } else {
        handleReset();
        setDisabledMoney(true);
        onApplyValue(amountNumbers, false, handleReset);
      }
      resetAmount && handleReset();
    } else {
      setShowInput(true);
      onEditing(true);
    }
  }, [
    showInput,
    amount,
    maxValue,
    initialValue,
    resetAmount,
    handleReset,
    onApplyValue,
    onEditing
  ]);

  const handleInputBlur = useCallback(() => {
    if (!amount) {
      setAmount('0');
    }
  }, [amount]);

  useEffect(() => {
    resetAmount && handleReset();
  }, [handleReset, initialValue, resetAmount]);

  return (
    <S.Wrapper disabled={disabled}>
      <Text size="xsmall" weight="medium" darkColor="white">
        {label}
      </Text>

      <S.AmountWrapper showInput={showInput} disabledMoney={disabledMoney}>
        <div>
          <Money
            value={amount}
            weight="medium"
            size="xxxlarge"
            darkColor="white"
            color={color}
          />
          <Input
            name="amount"
            value={amount}
            maskType="CURRENCY"
            disabled={!showInput}
            withMargin={false}
            onChange={e => setAmount(e.target.value)}
            style={{ width: inputWidth }}
            onBlur={handleInputBlur}
            color={color}
          />
        </div>

        {!disabledMoney && (
          <Button
            tertiary
            onClick={handleEditApplyButton}
            aria-label={buttonApplyText}
            disabled={disabled}
          >
            {buttonApplyText}
          </Button>
        )}
      </S.AmountWrapper>

      {error && (
        <>
          <Divider size="xsmall" />
          <Text color="red" size="small">
            O valor informado deve ser maior que <strong>R$ 0,00</strong> e
            menor ou igual ao valor total de{' '}
            <strong>
              {maxValue
                ? formatCurrency(maxValue)
                : formatCurrency(initialValue)}
            </strong>
            .
          </Text>
        </>
      )}

      {showInput && !!editingDescription && (
        <>
          <Divider size="xsmall" />
          <Text size="small" color="grayDark" darkColor="white">
            {editingDescription}
          </Text>
        </>
      )}

      {!!description && (!showInput || (showInput && !editingDescription)) && (
        <>
          <Divider size="xsmall" />
          <Text size="small" color="grayDark" darkColor="white">
            {description}
          </Text>
        </>
      )}
    </S.Wrapper>
  );
};

export default InputValue;
