import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { initializeAnalytics } from 'utils/analytics';
import { initializeDatadog } from 'utils/datadog';
import { initializeHotjar } from 'utils/hotjar';

import App from './App';
import Ame, { EnvironmentManager, SuperAppTargetEnum } from 'ame-super-app-web';
import { askUserDataForMiniApps } from 'services/sessionService';
import paths from 'routes/paths';

initializeAnalytics();
initializeDatadog();
initializeHotjar();
Ame.init({
  target: SuperAppTargetEnum.AME_EMPRESAS_WEB,
  environment: EnvironmentManager.fromAmeENV(process.env.REACT_APP_AME_ENV),
  url: window.location.href,
  methods: { askUserData: askUserDataForMiniApps },
  urlPatterns: {
    openMiniAppBySlug: paths.APP.MINI_APP.PATTERN_SLUG
  }
});

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);
