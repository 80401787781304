import { Text } from 'components';
import styled, { css } from 'styled-components';

export * from 'pages/Accreditation/shared/styles';

export const PhoneWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: -${theme.spacings.large};
  `}
`;

export const Phone = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grayNeutralDark};
    margin-right: ${theme.spacings.medium};
  `}
`;

export const AlertText = styled(Text).attrs({
  size: 'medium'
})`
  ${({ theme }) => css`
    text-align: center;
    max-width: 46rem;
    margin-top: ${theme.spacings.medium};
  `}
`;
