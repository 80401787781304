import { useCallback } from 'react';

import * as S from './styles';

import { ReactComponent as IconClose } from 'assets/images/icon-close.svg';

import PageNumbers from 'components/Pagination/Numbers';
import Flex from 'components/Flex';

export type TemplatePaginationStepProps = {
  steps: JSX.Element[];
  currentPage?: number;
  prevPageItemClick?: (page: number) => void;
  onClose?: () => void;
  small?: boolean;
};

const TemplatePaginationStep = ({
  steps,
  currentPage = 1,
  prevPageItemClick,
  onClose,
  small
}: TemplatePaginationStepProps) => {
  const pageItemClick = useCallback(
    (page: number) => {
      if (page >= currentPage) return;

      !!prevPageItemClick && prevPageItemClick(page);
    },
    [currentPage, prevPageItemClick]
  );

  return (
    <S.Wrapper small={!!small}>
      <Flex alignItems="center" justifyContent="space-between">
        <PageNumbers
          numberPages={steps.length}
          currentPage={currentPage}
          onClick={pageItemClick}
        />
        {!!onClose && (
          <IconClose
            aria-label="Icon Close"
            role="icon-close"
            onClick={onClose}
          />
        )}
      </Flex>
      <S.Content>{steps[currentPage - 1]}</S.Content>
    </S.Wrapper>
  );
};

export default TemplatePaginationStep;
