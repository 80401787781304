import { Flex, Text } from 'components';
import { ModalPixProps } from 'components/Modals/Pix';
import Divider from 'styles/shared/Divider';

import { ReactComponent as IconSuccess } from 'assets/images/icon-success.svg';
import { ReactComponent as IconError } from 'assets/images/icon-error.svg';
import { PixStatus } from 'contexts/Pix/PixRegister/types';

type StatusRegisterKey =
  | 'PORTABILITY'
  | 'CLAIM'
  | 'ERROR'
  | 'SUCCESS'
  | 'CONSENT'
  | 'SUCCESS_PORTABILITY'
  | 'SUCCESS_CLAIM';

export type StatusRequest = PixStatus;

type ClaimTypesData = {
  [key in StatusRegisterKey]: Omit<ModalPixProps, 'modalTitle' | 'modalAction'>;
};

type ClaimTypesDataParams = {
  primaryOnClick: () => void;
  secondaryOnClick?: () => void;
  key?: string;
};

type StatusObj = {
  [key in string]: StatusRegisterKey;
};

export const statusDataObj: StatusObj = {
  error: 'ERROR',
  success: 'SUCCESS',
  successClaim: 'SUCCESS_CLAIM',
  successPortability: 'SUCCESS_PORTABILITY',
  initial: 'CONSENT',
  portability: 'PORTABILITY',
  claim: 'CLAIM'
};

export const registerModalData = ({
  primaryOnClick,
  secondaryOnClick,
  key
}: ClaimTypesDataParams) =>
  ({
    PORTABILITY: {
      title: (
        <>
          Chave cadastrada em outra conta{' '}
          <Text tag="span" color="red" weight="bold">
            :(
          </Text>
        </>
      ),
      subtitle:
        'Você pode trazer a chave para sua conta Ame fazendo uma portabilidade. Depois, acessar o app da conta onde a chave está cadastrada e confirmar o pedido em até 7 dias. Deseja fazer a portabilidade?',
      primaryButton: {
        action: primaryOnClick,
        text: 'Fazer portabilidade'
      },
      secondaryButton: {
        action: secondaryOnClick,
        text: 'Cancelar'
      }
    },
    CLAIM: {
      title: (
        <>
          Chave cadastrada por outra pessoa{' '}
          <Text tag="span" color="red" weight="bold">
            :(
          </Text>
        </>
      ),
      subtitle:
        'A chave está sendo usada por outra pessoa. Você pode pedir a reivindicação de posse e, caso o dono da chave aceite o pedido em até 14 dias, a chave ficará disponível para você cadastrar onde quiser. Deseja pedir a reivindicação?',
      primaryButton: {
        action: primaryOnClick,
        text: 'Pedir reinvidicação'
      },
      secondaryButton: {
        action: secondaryOnClick,
        text: 'Cancelar'
      }
    },
    CONSENT: {
      title: 'Dados das chaves Pix',
      subtitle: (
        <Flex direction="column" ml="large" mr="large">
          <Text size="medium">
            Ao receber um pagamento, os dados abaixo ficam visíveis para o
            pagador:
          </Text>

          <Divider />

          <Text size="medium">
            • Seu{' '}
            <Text tag="span" weight="bold">
              nome
            </Text>{' '}
            completo;
          </Text>

          <Divider />

          <Text size="medium">
            • Seu{' '}
            <Text tag="span" weight="bold">
              CPF/CNPJ
            </Text>{' '}
            com os três primeiros e dois últimos números escondidos;
          </Text>

          <Divider />

          <Text size="medium">
            • Nome da conta em que a chave está cadastrada (Ame Digital, por
            exemplo).
          </Text>
        </Flex>
      ),
      primaryButton: {
        action: primaryOnClick,
        text: 'Entendi'
      }
    },
    SUCCESS: {
      icon: <IconSuccess />,
      title: (
        <>
          A chave foi cadastrada{' '}
          <Text tag="span" color="blue" weight="bold">
            :)
          </Text>
        </>
      ),
      subtitle: (
        <>
          Compartilhe a chave
          <Text tag="p" color="blue" align="center">
            {key}
          </Text>
          sempre que quiser receber um pagamento ;)
        </>
      ),
      primaryButton: {
        action: primaryOnClick,
        text: 'Entendi'
      }
    },
    ERROR: {
      icon: <IconError />,
      title: (
        <>
          A chave não foi cadastrada{' '}
          <Text tag="span" color="red" weight="bold">
            :(
          </Text>
        </>
      ),
      subtitle: (
        <>
          Não foi possível cadastrar a chave
          <Text tag="p" color="blue" align="center">
            {key}
          </Text>
          devido à não confirmação de posse
        </>
      ),
      primaryButton: {
        action: primaryOnClick,
        text: 'Entendi'
      }
    },
    SUCCESS_PORTABILITY: {
      icon: <IconSuccess />,
      title: (
        <>
          Pedido de portabilidade{' '}
          <Text tag="span" color="blue" weight="bold">
            :)
          </Text>
        </>
      ),
      subtitle: 'Seu pedido de portabilidade foi requerido com sucesso!',
      primaryButton: {
        action: primaryOnClick,
        text: 'Entendi'
      }
    },
    SUCCESS_CLAIM: {
      icon: <IconSuccess />,
      title: (
        <>
          Pedido de reivindicação{' '}
          <Text tag="span" color="blue" weight="bold">
            :)
          </Text>
        </>
      ),
      subtitle: 'Seu pedido de reivindicação foi requerido com sucesso!',
      primaryButton: {
        action: primaryOnClick,
        text: 'Entendi'
      }
    }
  } as ClaimTypesData);
