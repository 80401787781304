import * as yup from 'yup';
import { checkCellPhoneNumber } from 'utils/validations';
import { formatInput } from 'utils/masks';
export const validationSchema = yup.object().shape({
  number: yup
    .string()
    .required('Telefone é um campo obrigatório')
    .test(
      'is valid phone',
      'Celular inválido',
      value => !!value && checkCellPhoneNumber(formatInput['CELLPHONE'](value))
    )
});
