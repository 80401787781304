import styled, { css } from 'styled-components';

import { FlexAlignItems, FlexJustifyContent, FlexProps } from '.';

import { buildMargin } from 'styles/shared/Margin';

type WrapperProps = Omit<FlexProps, 'children'>;

const modifiers = {
  shrink: (shrink: number) => css`
    flex-shrink: ${shrink};
  `,

  basis: (basis: string) => css`
    flex-basis: ${basis};
  `,

  justifyContent: (justifyContent: FlexJustifyContent) => css`
    justify-content: ${justifyContent};
  `,

  alignItems: (alignItems: FlexAlignItems) => css`
    align-items: ${alignItems};
  `
};

export const Wrapper = styled.div<WrapperProps>`
  ${({
    direction,
    wrap,
    grow,
    shrink,
    basis,
    justifyContent,
    alignItems,
    ...props
  }) => css`
    display: flex;
    flex-direction: ${direction};
    flex-wrap: ${wrap};
    flex-grow: ${grow};

    ${shrink !== undefined && modifiers.shrink(shrink)};
    ${!!basis && modifiers.basis(basis)};
    ${!!justifyContent && modifiers.justifyContent(justifyContent)};
    ${!!alignItems && modifiers.alignItems(alignItems)};

    ${buildMargin(props)}
  `}
`;
