import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const FormContainer = styled.form`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.large};

    label[for='login'] {
      color: ${theme.colors.grayMedium};
    }

    input[name='login'] {
      color: ${theme.colors.grayMedium};
      border-bottom: 2px solid ${theme.colors.grayMedium};
    }
  `}
`;
