import { createContext, useState, useContext, useEffect } from 'react';

import { Text, ModalStatus } from 'components';

import { SellerData } from 'pages/Sell/SellFlux/types';

import { ISetOrdersSuccess } from 'services/saleService/types';
import { setOrders, setOrdersNoValue } from 'services/saleService';
import endpoints from 'services/endpoints';

import useToggle from 'hooks/useToggle';
import useRequest from 'hooks/useRequest';

import { ContextData, ProviderData, QrCodeData, MaterialsType } from './types';

const ScreenSellContext = createContext<ContextData | null>(null);

type Group = {
  id: string;
  document: string;
  groupName: string;
};

function ScreenSellProvider({ children }: ProviderData) {
  const [qrCodeData, setQrCodeData] = useState<QrCodeData>({} as QrCodeData);
  const [sellerData, setSellerData] = useState<SellerData>();
  const [materialType, setMaterialType] = useState<MaterialsType>('store');
  const [loading, setLoading] = useState(false);
  const { data: group } = useRequest<Group>({
    url: endpoints.group,
    options: { revalidateOnFocus: false }
  });

  const modalError = useToggle();

  const handleSetQrCode = async (amount: string, description: string) => {
    setLoading(true);
    try {
      const data: ISetOrdersSuccess = await getServiceType(amount, description);

      setQrCodeData(data);
    } catch (e) {
      modalError.toggle();
    } finally {
      setLoading(false);
    }
  };

  function getServiceType(amount: string, description: string) {
    if (Number(amount) === 0) {
      return setOrdersNoValue({
        group_id: sellerData?.id as string
      });
    }

    return setOrders({
      amount: Number(amount),
      description,
      group_id: sellerData?.id as string
    });
  }

  useEffect(() => {
    if (group) {
      const { id, groupName, document } = group;

      setSellerData({ id, groupName, documentNumber: document });
    }
  }, [group]);

  return (
    <ScreenSellContext.Provider
      value={{
        sellerData,
        setSellerData,
        qrCodeData,
        setQrCodeData,
        loading,
        handleSetQrCode,
        setMaterialType,
        materialType
      }}
    >
      {children}

      {!!modalError.isActive && (
        <ModalStatus variant="error" title="Vender" onClose={modalError.toggle}>
          <Text>Ocorreu um erro inesperado, tente novamente mais tarde.</Text>
        </ModalStatus>
      )}
    </ScreenSellContext.Provider>
  );
}

function useScreenSell(): ContextData {
  const context = useContext(ScreenSellContext);

  if (!context)
    throw new Error('useScreenSell must be used within an ScreenSellProvider');

  return context;
}

export { useScreenSell, ScreenSellProvider };
