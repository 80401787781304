import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { Column, Row } from 'styles/shared/Grid';
import { removeSpecialCharacters } from 'utils/masks';
import { Input, Loading, InputField, SelectStateField } from 'components';
import { StepProps } from 'contexts/AccreditationSteps/types';
import { AccreditationValues } from 'pages/Accreditation/shared/form';

import { useAccreditationAddress } from './use-accreditation-address';

import * as S from './styles';
import { getErrorMessage } from 'utils/formik';

const Step2 = ({ names }: StepProps) => {
  const form = useFormikContext<AccreditationValues>();
  const { setFieldValue } = form;

  const { address, loading, error, warning, disabled } =
    useAccreditationAddress({
      partnershipType: form.values[names.partnershipType],
      partnership: form.values[names.partnership],
      cep: form.values[names.cep]
    });

  function getCepErrorMessage(err: boolean, warn: boolean) {
    if (warn) {
      return { errorMessage: 'Erro ao buscar cep' };
    }

    if (err) {
      return { errorMessage: 'Endereço do shopping não encontrado' };
    }

    return {};
  }

  useEffect(() => {
    if (address) {
      if (address.zipCode) setFieldValue(names.cep, address.zipCode);
      if (address.address) setFieldValue(names.street, address.address);
      if (address.neighborhood)
        setFieldValue(names.neighborhood, address.neighborhood);
      if (address.city) setFieldValue(names.city, address.city);
      if (address.state) setFieldValue(names.state, address.state);
      if (address.number) setFieldValue(names.number, address.number);
    }
  }, [address, names, setFieldValue]);

  return (
    <>
      <S.Title>
        Opa, agora o endereço <S.HighlightText>:)</S.HighlightText>
      </S.Title>

      {loading && <Loading fullContent fullWidth />}

      <S.Subtitle>A empresa tá registrada em qual endereço?</S.Subtitle>

      <Row>
        <Column xs={6}>
          <InputField
            disabled={disabled}
            name={names.cep}
            label="CEP"
            maskType="CEP"
            {...getCepErrorMessage(!!error, !!warning)}
          />
        </Column>

        <Column xs={6}>
          <InputField disabled={disabled} name={names.street} label="Rua" />
        </Column>
      </Row>

      <InputField
        disabled={disabled}
        name={names.neighborhood}
        label="Bairro"
      />

      <InputField disabled={disabled} name={names.city} label="Cidade" />

      <Row>
        <Column xs={6}>
          <SelectStateField
            disabled={disabled}
            name={names.state}
            label="Estado"
          />
        </Column>

        <Column xs={6}>
          <Input
            disabled={disabled}
            name={names.number}
            label="Número"
            onlyNumbers
            value={form.values[names.number]}
            errorMessage={getErrorMessage(names.number, form)}
            onChange={event => {
              setFieldValue(
                names.number,
                removeSpecialCharacters(event.target.value)
              );
            }}
          />
        </Column>
      </Row>

      <InputField name={names.complement} label="Complemento" />

      <S.Action>Ir para dados pessoais</S.Action>
    </>
  );
};

export default Step2;
