import { TutorialRequestKey } from '../components';
import * as S from '../../styles';
const BrasilNaWeb = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Brasil Na Web
      </S.Title>

      <TutorialRequestKey
        name="Brasil Na Web"
        showConfigurationMessage={false}
      />

      <S.Paragraph tag="p" color="grayBlue">
        Recebeu sua chave? Ótimo! Abra um chamado pela sua página de cliente na
        Brasil Na Web (
        <S.Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://cliente.brasilnaweb.com.br/"
        >
          Suporte - Brasil na Web
        </S.Link>
        ) Você receberá toda a orientação para a configuração da integração.
      </S.Paragraph>
    </div>
  );
};

export default BrasilNaWeb;
