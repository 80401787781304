import styled, { css } from 'styled-components';

import { getSessionStorage } from 'utils/storage';

const { theme: themeMode } = getSessionStorage();
const isLightTheme = themeMode === 'light';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: relative;

    svg {
      position: absolute;
      top: 0.6rem;
      left: ${theme.spacings.xsmall};
      color: ${isLightTheme ? '#313647' : theme.colors.grayMedium};
    }
  `};
`;

export const Input = styled.input`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.font.lineHeight('medium')};
    background: ${isLightTheme ? theme.colors.white : theme.colors.blackDark};
    outline: none;
    width: 100%;
    height: 45px;
    color: ${isLightTheme ? theme.colors.grayBlue : theme.colors.grayMedium};
    padding: ${theme.spacings.small};
    padding-left: ${theme.spacings.large};
    border: 1px solid
      ${isLightTheme ? theme.colors.grayMedium : theme.colors.blackDark};
    border-radius: 8px;
  `}
`;
