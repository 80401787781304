import styled from 'styled-components';

import customMedia from 'styles/media-query';

export const LabelTextWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  ${customMedia.greaterThan('medium')`
    width: 50%;
  `}

  &:last-child {
    ${customMedia.greaterThan('medium')`
      padding-left: 1rem;
    `}
  }
`;
