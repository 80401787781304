import DefaultTransaction from '../components/DefaultTransaction';
import { PixLimits } from '../types';

export interface SameOwnershipProps {
  data: PixLimits;
  changePixLimits: (data: PixLimits) => void;
  updatePixLimit: () => void;
  loading?: boolean;
}

const SameOwnership = ({
  data,
  changePixLimits,
  updatePixLimit,
  loading
}: SameOwnershipProps) => {
  return (
    <>
      <DefaultTransaction
        data={data}
        changePixLimits={changePixLimits}
        updatePixLimit={updatePixLimit}
        title="Envios Pix para mesmo CNPJ"
        loading={loading}
      />
    </>
  );
};

export default SameOwnership;
