import { useState } from 'react';
import { Breadcrumb, Flex, TemplateShortContent, Text } from 'components';
import { ReactComponent as IconPix } from 'assets/images/pix/icon-pix.svg';

import Divider from 'styles/shared/Divider';
import { breadcrumbItems } from './constants';
import { RandomKeyStep1 as Step1 } from './Steps/Step1';
import { RandomKeyStep2 as Step2 } from './Steps/Step2';
import { RandomKeyStep3 as Step3 } from './Steps/Step3';
import RegisterKeyModals from '../components/RegisterKeyModals';
import { usePixRegister } from 'contexts/Pix/PixRegister';

const RandomKey = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { key, loading, status, setStatus, handleCreatePixKey } =
    usePixRegister();

  const openRegisterModal = () => {
    setOpenModal(true);
  };

  const steps = [
    <Step1 key="0" next={() => setActiveStep(1)} />,
    <Step2
      key="1"
      next={() => openRegisterModal()}
      cancel={() => setActiveStep(0)}
    />,
    <Step3 key="2" next={() => setActiveStep(0)} createdKey={key} />
  ];

  const closeModal = () => {
    setStatus('initial');
    setOpenModal(false);
  };

  return (
    <>
      <Breadcrumb links={breadcrumbItems} />

      <Divider size="xlarge" />

      <TemplateShortContent>
        <Flex alignItems="center">
          <IconPix />

          <Text ml="small" tag="h1" size="large" weight="medium">
            Minhas Chaves
          </Text>
        </Flex>

        <Divider size="large" />

        {steps[activeStep]}
      </TemplateShortContent>

      {openModal && (
        <RegisterKeyModals
          status={status}
          createdKey={key}
          submitKey={() =>
            handleCreatePixKey({
              keyType: 'EVP',
              reason: 'USER_REQUESTED'
            })
          }
          onClose={closeModal}
          loading={loading}
        />
      )}
    </>
  );
};

export default RandomKey;
