import { ReactComponent as IconSale } from 'assets/images/icon-receive.svg';
// import { ReactComponent as IconTransfer } from 'assets/images/icon-transfer-outline.svg';
// import { ReactComponent as IconWithdraw } from 'assets/images/icon-withdraw-outline.svg';
import { ReactComponent as IconRefund } from 'assets/images/icon-receive-invert.svg';
import { ReactComponent as IconWithdraw } from 'assets/images/icon-deposit-outline.svg';

// import { ReactComponent as IconPix } from 'assets/images/icon-pix-outline.svg';
// import { ReactComponent as IconLoan } from 'assets/images/icon-loan-outline.svg';

import { FilterReportItems } from './types';

export const periodOptions = [
  {
    value: '7',
    description: (
      <>
        Últimos <br />7 dias
      </>
    )
  },
  {
    value: '15',
    description: (
      <>
        Últimos <br />
        15 dias
      </>
    )
  },
  {
    value: '30',
    description: (
      <>
        Últimos <br />
        30 dias
      </>
    )
  },
  {
    value: '90',
    description: (
      <>
        Últimos <br />
        90 dias
      </>
    )
  },
  {
    value: 'FILTRAR',
    description: (
      <>
        Filtrar <br />
        Datas
      </>
    )
  }
];

export const statusOptions = [
  {
    value: 'CAPTURED,REFUNDED,RELEASED',
    description: 'Aprovadas'
  },
  {
    value: 'CREATED,PENDING',
    description: 'Em processamento'
  },
  {
    value: 'CANCELLED,DENIED,DENIED_BY_RISK',
    description: 'Canceladas'
  }
];

export const categoryOptions = [
  {
    value: 'sale',
    icon: <IconSale width="45" height="45" />,
    description: 'Vendas'
  },
  // {
  //   value: 'transfer',
  //   icon: <IconTransfer width="45" height="45" />,
  //   description: 'Transferências'
  // },
  {
    value: 'withdraw',
    icon: <IconWithdraw width="45" height="45" />,
    description: 'Retiradas'
  },
  {
    value: 'refund',
    icon: <IconRefund width="45" height="45" />,
    description: 'Estornos'
  }
  // {
  //   value: 'pix',
  //   icon: <IconPix width="45" height="45" />,
  //   description: 'Pix'
  // },
  // {
  //   value: 'loan',
  //   icon: <IconLoan width="45" height="45" />,
  //   description: 'Empréstimos'
  // }
];

export const paymentMethodOptions = [
  // { description: 'Boleto', value: 'BANK_SLIP' },
  // { description: 'Caixa Eletrônico', value: 'BANK_ATM' },
  {
    description: 'Cartão de Crédito',
    value: 'CREDIT_CARD,EXTERNAL_CREDIT_CARD'
  },
  { description: 'Saldo Ame', value: 'CASH_BACK' },
  // { description: 'Dinheiro', value: 'CASH' },
  // { description: 'Provedor de pagamento', value: 'PAYMENT_PROVIDER' },
  { description: 'Transferência Bancária', value: 'BANK_TRANSFER' }
  // { description: 'Vale Alimentação', value: 'VA' },
  // { description: 'Vale Refeição', value: 'VR' }
];

export const filterItems: FilterReportItems[] = [
  {
    text: 'Categoria',
    type: 'category',
    cards: categoryOptions
  },
  {
    text: 'Status',
    type: 'status',
    cards: statusOptions
  },
  {
    text: 'Métodos de Pagamentos',
    type: 'paymentMethod',
    cards: paymentMethodOptions
  }
];
