import * as types from './types';
import endpoints from './endpoints.json';
import api from '../api';

export * from './types';

export function getAdvanceBalance() {
  return api.get<types.GetAdvanceBalanceResponse>(endpoints.advanceBalance);
}

export function setAutomaticAdvance(canReceiveAdvance: boolean) {
  return api.put(endpoints.automaticAdvance, { canReceiveAdvance });
}

export function advanceIndividual(
  orderUuid: string,
  referenceOrderUuid: string
) {
  return api.put(endpoints.advanceIndividual, {
    orderUuid,
    referenceOrderUuid
  });
}

export function advanceFull() {
  return api.put(endpoints.advanceFull);
}

export function getAdvancePreview(orderUuid: string) {
  return api.get<types.GetAdvancePreviewResponse>(
    endpoints.advancePreview.replace('{orderUuid}', orderUuid)
  );
}
