import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    input {
      width: 100%;
      border-bottom: 1px solid ${theme.colors.black};
    }
  `}
`;
