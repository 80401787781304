import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Breadcrumb,
  CardInfo,
  Flex,
  TemplateShortContent,
  Text
} from 'components';

import { ReactComponent as IconPix } from 'assets/images/pix/icon-pix.svg';

import Divider from 'styles/shared/Divider';
import { breadcrumbItems, cardItems } from './constants';
import { usePix } from 'contexts/Pix';
import { pixKeysType } from 'utils/pix';

const RegisterKeys = () => {
  const navigate = useNavigate();
  const { keysList: keysData } = usePix();

  const handleClickCard = (path: string) => {
    navigate(path);
  };

  const itemsToShow = useMemo(() => {
    if (keysData.data) {
      const filteredUnregisteredKeys = cardItems.filter(cardItem => {
        if (cardItem.type === pixKeysType.EVP) return true;

        const keysStringify = JSON.stringify(keysData.data);
        return !keysStringify.includes(cardItem.type);
      });
      return filteredUnregisteredKeys;
    }
    return cardItems;
  }, [keysData]);

  return (
    <>
      <Breadcrumb links={breadcrumbItems} />

      <Divider size="xlarge" />

      <TemplateShortContent>
        <Flex alignItems="center">
          <IconPix />
          <Text ml="small" tag="h1" size="large" weight="medium">
            Minhas Chaves
          </Text>
        </Flex>

        <Divider size="medium" />

        <Text tag="h2" weight="bold" size="large">
          Qual chave você quer cadastrar?
        </Text>

        <Divider size="medium" />

        {itemsToShow.map(card => {
          const Icon = card.icon.element;

          return (
            <CardInfo
              key={card.title}
              action={() => handleClickCard(card.path)}
              icon={<Icon data-testid={`icon-${card.icon.id}`} role="img" />}
              text={card.title}
              arrowIcon
            >
              <Text color="grayNeutralDark" size="xxsmall">
                {card.subtitle}
              </Text>
            </CardInfo>
          );
        })}
      </TemplateShortContent>
    </>
  );
};

export default RegisterKeys;
