import styled, { css } from 'styled-components';
import { Card } from 'styles/shared/Card';

export const Wrapper = styled(Card)`
  ${({ theme, active }) => css`
    p {
      ${active &&
      css`
        color: ${theme.colors.white};
      `}
    }
  `}
`;

export const HeaderInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    margin-bottom: ${theme.spacings.large};

    h5 {
      display: flex;
      align-items: center;

      img,
      svg {
        margin-right: ${theme.spacings.small};
      }
    }

    span {
      color: ${theme.mode === 'light'
        ? theme.colors.grayDark
        : theme.colors.white};
      text-transform: uppercase;
      font-size: ${theme.font.sizes.small};
      line-height: ${theme.font.lineHeight('small')};
    }
  `}
`;

export const FooterInfo = styled.div`
  ${({ theme }) => css`
    h3 {
      width: 100%;
      font-size: 2.6rem;
      display: flex;
      align-items: baseline;

      span {
        &:first-child {
          margin-right: ${theme.spacings.xsmall};
          font-size: ${theme.font.sizes.medium};
          line-height: ${theme.font.lineHeight('medium')};
        }
      }

      svg {
        margin: auto 0 auto ${theme.spacings.xsmall};
      }
    }

    p {
      color: ${theme.mode === 'light'
        ? theme.colors.grayDark
        : theme.colors.white};
      font-size: ${theme.font.sizes.xsmall};
      line-height: ${theme.font.lineHeight('xsmall')};
    }
  `}
`;
