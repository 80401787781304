import * as React from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { validationSchema } from './validationSchema';
import { useAuth } from 'contexts/Auth';
import moment from 'moment';

import PATHS_ROUTES from 'routes/paths';
import {
  Text,
  TemplateCloseContent,
  Button,
  Loading,
  ModalStatus,
  Input
} from 'components';
import Divider from 'styles/shared/Divider';
import useToggle from 'hooks/useToggle';
import useMutate from 'hooks/useMutate';
import endpoints from 'services/userService/endpoints.json';

type UpdateBirthdayFormValues = {
  birthday: string;
};

const AccountUpdateBirthday = () => {
  const { user } = useAuth();
  const modalSuccess = useToggle();
  const navigate = useNavigate();
  const { state, mutate, clearError } = useMutate();

  const initialValues: UpdateBirthdayFormValues = {
    birthday: moment(user?.birthday).format('DD/MM/YYYY') || ''
  };

  const handleSubmit = React.useCallback(
    (values: UpdateBirthdayFormValues) => {
      const endpoint = endpoints.changeBirthday;

      const { birthday } = values;
      const formatDate = moment(birthday, 'DD/MM/YYYY').format('YYYY-MM-DD');

      mutate({
        endpoint,
        method: 'put',
        onSuccess: modalSuccess.toggle,
        body: { birthday: formatDate },
        errorMessage:
          'Ocorreu um erro ao alterar a data de nascimento, verifique se os dados informados estão corretos'
      });
    },
    [mutate, modalSuccess.toggle]
  );

  const handleChange = React.useCallback(
    (
      e: React.ChangeEvent,
      formikHandleChange: (e: React.ChangeEvent) => void
    ) => {
      if (state.error) clearError();

      formikHandleChange(e);
    },
    [clearError, state.error]
  );

  if (!user) {
    return <Loading fullContent />;
  }

  return (
    <>
      {state.loading && <Loading fullContent />}

      <TemplateCloseContent
        closeLink={PATHS_ROUTES.APP.ACCOUNT.PROFILE}
        closeTitle="Voltar para o perfil"
      >
        <Text tag="h2" size="xxmedium" weight="medium" mb="large">
          Alterar data de nascimento
        </Text>

        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({
            values,
            dirty,
            handleChange: formikHandleChange,
            touched,
            errors
          }) => (
            <Form>
              <Input
                name="birthday"
                label="Data de nascimento"
                type="text"
                placeholder="DD/MM/YYYY"
                maskType="DATE"
                value={values.birthday}
                onChange={e => handleChange(e, formikHandleChange)}
                errorMessage={touched.birthday ? errors.birthday : undefined}
              />
              {!!state.error && (
                <Text align="center" color="red">
                  {state.error}
                </Text>
              )}

              <Divider size="xlarge" />

              <Button type="submit" filled center large disabled={!dirty}>
                Salvar
              </Button>
            </Form>
          )}
        </Formik>
        {modalSuccess.isActive && (
          <ModalStatus
            variant="success"
            action={{
              onClick: () => {
                navigate(PATHS_ROUTES.APP.ACCOUNT.PROFILE);
                window.location.reload();
              }
            }}
          >
            <Text size="large" weight="bold" align="center">
              Data de nascimento alterado!
            </Text>
          </ModalStatus>
        )}
      </TemplateCloseContent>
    </>
  );
};

export default AccountUpdateBirthday;
