import styled, { css, DefaultTheme } from 'styled-components';

type AmountWrapperProps = {
  showInput: boolean;
  disabledMoney: boolean;
};

type WrapperProps = {
  disabled: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ disabled }) => css`
    ${disabled &&
    css`
      ${AmountWrapper} {
        opacity: 0.3;
      }
    `}
  `}
`;

const AmountWrapperModifiers = {
  disabled: () => css`
    input {
      display: none;
    }
  `,

  enabled: (theme: DefaultTheme) => css`
    width: 100%;
    border-bottom: 1px solid ${theme.colors.grayDark};

    p {
      display: none;
    }
  `,

  disabledMoney: (theme: DefaultTheme) => css`
    p {
      color: ${theme.colors.grayDark};
    }
  `
};

export const AmountWrapper = styled.div<AmountWrapperProps>`
  ${({ theme, showInput, disabledMoney }) => css`
    display: inline-flex;
    align-items: center;

    input {
      font-weight: ${theme.font.weights.medium};
      font-size: ${theme.font.sizes.xxxlarge};
      border: 0;
      padding: 0;
      background: ${theme.colors.transparent};

      &:disabled {
        padding: 0;
        color: ${theme.colors.grayBlue};
        background: ${theme.colors.grayLight};
        border: 1px solid ${theme.colors.grayLight};
      }
    }

    button {
      font-size: ${theme.font.sizes.small};
      margin-left: ${theme.spacings.medium};
      font-weight: ${theme.font.weights.medium};
    }

    ${showInput && AmountWrapperModifiers.enabled(theme)};
    ${!showInput && AmountWrapperModifiers.disabled()};
    ${disabledMoney && AmountWrapperModifiers.disabledMoney(theme)};
  `}
`;
