import { formatCurrency } from 'utils/masks';
import Text from '../Text';
import * as S from './styles';

export type RangeSliderProps = {
  changeValue: (value: string) => void;
  value: number;
  minValue: number;
  maxValue: number;
  onEditing?: (edditing: boolean) => void;
};

const RangeSlider = ({
  changeValue,
  value,
  minValue,
  maxValue,
  onEditing
}: RangeSliderProps) => {
  const percentage = (value * 100) / maxValue;
  const widthBar = `calc(${percentage}% - ${
    (2.6 * (percentage / 10)) / 10
  }rem)`;

  return (
    <S.Wrapper>
      <S.InputRange
        type="range"
        min={minValue}
        max={maxValue}
        value={value}
        widthBar={widthBar}
        onChange={e => changeValue(e.target.value)}
        onMouseDown={() => onEditing && onEditing(true)}
        onMouseUp={() => onEditing && onEditing(false)}
      />

      <S.WrapperRange>
        <Text tag="p" color="grayNeutralDark" size="xsmall">
          {formatCurrency(minValue)}
        </Text>

        <Text tag="p" color="grayNeutralDark" size="xsmall">
          {formatCurrency(maxValue)}
        </Text>
      </S.WrapperRange>
    </S.Wrapper>
  );
};

export default RangeSlider;
