import { ReactNode } from 'react';
import { ThemeSpacings } from 'types/global';

import * as S from './styles';
export type TableProps = {
  theadData: ReactNode[];
  striped?: boolean;
  bordered?: boolean;
  size?: ThemeSpacings;
  children: ReactNode;
};
export * from './TColumn';
const Table = ({
  theadData,
  striped = false,
  bordered = false,
  size = 'small',
  children
}: TableProps) => (
  <S.Wrapper striped={striped} bordered={bordered} size={size}>
    <thead>
      <tr>
        {theadData.map((data, idx) => (
          <th key={idx}>{data}</th>
        ))}
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </S.Wrapper>
);
export default Table;
