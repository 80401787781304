import styled, { css } from 'styled-components';

import { Card } from 'styles/shared/Card';

export const Wrapper = styled(Card)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 15.6rem;
    height: 4.5rem;
    background: ${theme.colors.blueLight};
    padding: 1rem;
  `}
`;
