import * as React from 'react';

import { ModalChallenge } from 'components';

export type ChallengeLayoutProps = {
  children: React.ReactNode;
};

const ChallengeLayout = ({ children }: ChallengeLayoutProps) => {
  return (
    <>
      {children}

      <ModalChallenge />
    </>
  );
};

export default ChallengeLayout;
