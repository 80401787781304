import { createContext, useState, useContext, useCallback } from 'react';

import { ContextData, ProviderData } from './types';

import * as types from './types';
import useMutate from 'hooks/useMutate';
import endpoints from 'services/endpoints';

const ReportContext = createContext<ContextData | null>(null);

export const INITIAL_REPORT_DATA = {
  archiveType: { text: 'CSV', value: 'text/csv' }
};

function ReportProvider({ children }: ProviderData) {
  const { state, mutate } = useMutate();
  const [reportStateData, setReportStateData] =
    useState<types.ReportStateData>(INITIAL_REPORT_DATA);

  const handleSetReportStateData = useCallback(
    (value: types.ReportStateData) => {
      setReportStateData(prevState => ({
        ...prevState,
        ...value
      }));
    },
    []
  );

  const handleCreateReport = useCallback(async () => {
    mutate({
      endpoint: endpoints.createReport,
      body: {
        typeFiles:
          reportStateData.typeFiles &&
          reportStateData.typeFiles.map(typeFile => typeFile.value),
        dateStart: reportStateData.dateStart,
        dateEnd: reportStateData.dateEnd || reportStateData.dateStart,
        mediaType: reportStateData.archiveType?.value
      },
      method: 'post',
      errorMessage: 'Erro ao criar relatório'
    });
  }, [mutate, reportStateData]);

  return (
    <ReportContext.Provider
      value={{
        reportStateData,
        handleSetReportStateData,
        handleCreateReport,
        createReportData: { ...state }
      }}
    >
      {children}
    </ReportContext.Provider>
  );
}

function useReport(): ContextData {
  const context = useContext(ReportContext);

  if (!context)
    throw new Error('useReport must be used within an ReportProvider');

  return context;
}

export { useReport, ReportProvider, ReportContext };
