import { ReactNode, useEffect, useRef, useState } from 'react';

import * as S from './styles';

export type PopoverProps = {
  text: string;
  show: boolean;
  children: ReactNode;
  direction: 'left' | 'right' | 'bottom' | 'top';
};

type DimensionsType = {
  wrapperHeight: number;
  popoverWidth: number;
};

const Popover = ({ children, direction, text, show }: PopoverProps) => {
  const tollRef = useRef<HTMLInputElement>(null);
  const tollTipRef = useRef<HTMLInputElement>(null);
  const [dimensions, setDimensions] = useState<DimensionsType>({
    wrapperHeight: 0,
    popoverWidth: 0
  });

  useEffect(() => {
    setDimensions({
      wrapperHeight: tollRef?.current?.offsetHeight || 0,
      popoverWidth: tollTipRef?.current?.offsetWidth || 0
    });
  }, []);

  return (
    <S.PopoverWrapper ref={tollRef}>
      <S.PopoverContainer
        height={dimensions.wrapperHeight}
        width={dimensions.popoverWidth}
        direction={direction}
        show={show}
        data-testid="popover"
      >
        <S.Popover ref={tollTipRef} show direction={direction}>
          {text}
        </S.Popover>
      </S.PopoverContainer>
      {children}
    </S.PopoverWrapper>
  );
};

export default Popover;
