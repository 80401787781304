import styled, { css } from 'styled-components';

type WrapperProps = {
  small?: boolean;
};

const wrapperModifiers = {
  small: () => css`
    height: 30px;
  `
};

export const Wrapper = styled.nav<WrapperProps>`
  ${({ theme, small }) => css`
    width: 100%;
    height: 45px;
    display: flex;
    border: 1px solid ${theme.colors.blue};
    border-radius: 12px;
    overflow: hidden;

    ${small && wrapperModifiers.small()};

    button {
      background: ${theme.colors.transparent};
      font-size: ${theme.font.sizes.small};
      font-weight: ${theme.font.weights.medium};
      height: 100%;
      flex: 1;
      border: none;
      color: ${theme.colors.blue};
      cursor: pointer;

      &.active {
        background: ${theme.colors.blue};
        color: ${theme.colors.white};
      }
    }
  `}
`;
