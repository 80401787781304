import styled, { css } from 'styled-components';

import { Card } from 'styles/shared/Card';

export const CardWrapper = styled(Card)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all ${theme.transition.default};
    background: ${theme.colors.grayNeutralLight};
    border: 0.1rem dashed ${theme.colors.grayDark};
    margin: 1rem 0;
    padding: 2.5rem;
    min-height: 9.5rem;
    cursor: pointer;

    &:hover {
      border: 0.1rem dashed ${theme.colors.blue};
      background: ${theme.colors.grayMedium};
    }

    svg {
      opacity: 0.5;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 2rem;
  }
`;
