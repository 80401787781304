import styled, { css } from 'styled-components';

import { Row } from 'styles/shared/Grid';
import { ReactComponent as IconRightNext } from 'assets/images/icon-right-next.svg';

import { TemplateEditContentTypes } from './';

type EditWrapperProps = Pick<TemplateEditContentTypes, 'backLink'>;

const modifiers = {
  backLink: () => css`
    justify-content: space-between;
  `
};

export const Wrapper = styled(Row)<EditWrapperProps>`
  ${({ theme, backLink }) => css`
    display: flex;
    justify-content: flex-end;
    padding: 0 0 0 ${theme.spacings.xsmall};
    margin-bottom: ${theme.spacings.medium};

    button {
      width: 3.3rem;
      height: 3.3rem;
    }

    ${backLink && modifiers.backLink()};
  `}
`;

export const IconBack = styled(IconRightNext)`
  transform: rotate(180deg);
`;
