/* eslint-disable @typescript-eslint/no-unused-vars */
import * as types from './types';
import endpoints from './endpoints.json';
import api from '../api';
import { RequestParams } from 'types/global';

export * from './types';

export async function getBalance() {
  const result = await api.get<types.BalanceData>(endpoints.balance);

  return result.data;
}

export async function getStatements(
  page: number,
  size: number,
  filters?: RequestParams
) {
  const result = await api.get<types.GetStatementResponse>(
    endpoints.statements,
    {
      params: {
        page,
        size,
        ...filters
      }
    }
  );

  return result.data;
}

export async function getStatementDetails(statementId: string) {
  const url = endpoints.statementDetails.replace('{id}', statementId);

  const result = await api.get<types.GetStatementDetailsResponse>(url);

  return result.data;
}

export function refund(referenceOrderUuid: string, amount: number) {
  const url = endpoints.refund.replace(
    '{referenceOrderUuid}',
    referenceOrderUuid
  );

  return api.put<types.RefundResponse>(url, { amount });
}
