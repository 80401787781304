import { Loading } from 'components';
import { ReportStep2 } from './Steps';

import { useReport } from 'contexts/Report';

import { StatusModal } from './StatusModal';

export function ReportCreate() {
  // const [step, setStep] = useState(1);

  const { createReportData } = useReport();

  if (createReportData.loading) {
    return <Loading fullContent />;
  }

  return (
    <>
      {/* {createReportData.loading ? (
        <Loading fullContent />
      ) : (
        <TemplatePaginationStep
          currentPage={step}
          onClose={() => navigate(PATHS_ROUTES.APP.REPORT.DEFAULT)}
          prevPageItemClick={() => setStep(step => step - 1)}
          small
          steps={[
            <ReportStep1 key="1" next={() => setStep(2)} />,
            <ReportStep2 key="2" />
          ]}
        />
      )} */}

      <ReportStep2 />

      {!!createReportData.data && <StatusModal type="success" />}
      {!!createReportData.error && <StatusModal type="error" />}
    </>
  );
}
