import * as S from './styles';

import PageItem from '../Item';
import Arrow from 'components/Icons/Arrow';

export type PageNavProps = {
  previousAction: () => void;
  nextAction: () => void;
  isFirstPage?: boolean;
  isLastPage?: boolean;
};

const PageNav = ({
  previousAction,
  nextAction,
  isFirstPage = true,
  isLastPage = false
}: PageNavProps) => (
  <S.Wrapper>
    <PageItem
      action={previousAction}
      active={!isFirstPage}
      disabled={isFirstPage}
      customStyles={!isFirstPage ? { cursor: 'pointer' } : undefined}
    >
      <Arrow />
    </PageItem>
    <PageItem
      action={nextAction}
      active={!isLastPage}
      disabled={isLastPage}
      customStyles={!isLastPage ? { cursor: 'pointer' } : undefined}
    >
      <Arrow direction="right" />
    </PageItem>
  </S.Wrapper>
);

export default PageNav;
