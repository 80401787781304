import { BreadcrumbLink } from 'components/Breadcrumb';

import PATHS_ROUTES from 'routes/paths';

import { PIX_OUT_KEYS_TYPES } from 'utils/pix';

import { breadcrumbItems } from '../constants';

type Breadcrumb = {
  phone: BreadcrumbLink[];
  document: BreadcrumbLink[];
  email: BreadcrumbLink[];
  random: BreadcrumbLink[];
  bank: BreadcrumbLink[];
};

export const breadcrumb: Breadcrumb = {
  phone: [
    ...breadcrumbItems,
    {
      title: 'Celular',
      link: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.PHONE)
    }
  ],
  document: [
    ...breadcrumbItems,
    {
      title: 'CPF/CNPJ',
      link: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.DOCUMENT)
    }
  ],
  email: [
    ...breadcrumbItems,
    {
      title: 'Endereço de e-mail',
      link: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.EMAIL)
    }
  ],
  random: [
    ...breadcrumbItems,
    {
      title: 'Chave aleatória',
      link: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.RANDOM)
    }
  ],
  bank: [
    ...breadcrumbItems,
    {
      title: 'Agência e conta',
      link: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.BANK)
    }
  ]
};

export type BreadcrumbKeys = keyof typeof breadcrumb;

export const formatErrorMessage = (status: string) => {
  if (status === 'key_not_found') {
    return 'Chave pix não encontrada';
  }

  if (status === 'invalid_key_format') {
    return 'O formato da chave não é válido';
  }

  return 'Ocorreu um erro, tente novamente';
};
