import { useCallback } from 'react';

import Table, { TColumn } from 'components/Table';
import Text from 'components/Text';
import Money from 'components/Money';
import { ThemeColors } from 'types/global';
import * as S from './styles';
import { IStatementDetailTransaction } from 'services/statementService/types';
import { formatSimpleDate, formatCurrency } from 'utils/masks';

export type TableInstallmentsProps = {
  splits: IStatementDetailTransaction[];
  color?: ThemeColors;
  isRefunded?: boolean;
};

const TableInstallments = ({ splits, isRefunded }: TableInstallmentsProps) => {
  const checkBoxColor = useCallback(
    (isAnticipate: boolean) => {
      if (isRefunded) {
        return 'grayMedium';
      }

      return isAnticipate ? 'blue' : 'yellowDark';
    },
    [isRefunded]
  );

  const getStatus = useCallback(
    (isAnticipate: boolean) => {
      if (isRefunded) {
        return 'Cancelado';
      }

      return isAnticipate ? 'Antecipado' : 'À Receber';
    },
    [isRefunded]
  );

  return (
    <S.Wrapper>
      <S.DesktopView>
        <Table
          theadData={[
            'Disponível em',
            'Tipo',
            'Pagamento',
            'Valor bruto',
            'Valor líquido',
            'Status'
          ]}
          striped
          bordered
        >
          {splits.map(split => {
            const isAdvanced = split.advance ? true : false;

            return (
              <tr key={split.uuid}>
                <TColumn>
                  <Text color={isRefunded ? 'grayExtraMedium' : 'blackDark'}>
                    {split.releaseDate
                      ? formatSimpleDate(split.releaseDate)
                      : '----'}
                  </Text>
                </TColumn>
                <TColumn>
                  <Text
                    lineThrough={isRefunded}
                    color={isRefunded ? 'grayExtraMedium' : 'blackDark'}
                  >
                    Crédito
                  </Text>
                </TColumn>
                <TColumn verticalCenterContent>
                  <S.IconInstallmentApproved
                    color={checkBoxColor(isAdvanced)}
                  />
                  <Text
                    lineThrough={isRefunded}
                    color={isRefunded ? 'grayExtraMedium' : 'blackDark'}
                  >
                    {split.releaseInstallment}ª Parcela
                  </Text>
                </TColumn>
                <TColumn>
                  <Money
                    lineThrough={isRefunded}
                    color={isRefunded ? 'grayExtraMedium' : 'blackDark'}
                    value={formatCurrency(split.grossAmountInCents)}
                  />
                </TColumn>
                <TColumn>
                  <Money
                    lineThrough={isRefunded}
                    color={isRefunded ? 'grayExtraMedium' : 'blackDark'}
                    value={formatCurrency(
                      split.advance?.netAmountInCents || split.netAmountInCents
                    )}
                  />
                </TColumn>
                <TColumn>
                  <Text color={checkBoxColor(isAdvanced)}>
                    {getStatus(isAdvanced)}
                  </Text>
                </TColumn>
              </tr>
            );
          })}
        </Table>
      </S.DesktopView>

      <S.MobileView>
        <Table theadData={['Pagamento', 'Disponível em']} striped bordered>
          {splits.map(split => (
            <tr key={split.uuid}>
              <TColumn verticalCenterContent>
                <S.IconInstallmentApproved
                  color={checkBoxColor(split.advance ? true : false)}
                />
                <Text>{split.releaseInstallment}ª Parcela</Text>
              </TColumn>
              <TColumn>
                <Text>
                  {split.releaseDate
                    ? formatSimpleDate(split.releaseDate)
                    : '----'}
                </Text>
              </TColumn>
            </tr>
          ))}
        </Table>
      </S.MobileView>
    </S.Wrapper>
  );
};

export default TableInstallments;
