import { ReactNode } from 'react';

import { Button, Text, TemplateModal, Flex, Loading } from 'components';

import * as S from './styles';
import Divider from 'styles/shared/Divider';

export type ButtonModalType = {
  action: () => void;
  text: string;
};

export type ModalPixProps = {
  icon?: ReactNode;
  loading?: boolean;
  modalTitle: string;
  modalAction: () => void;
  title: string | ReactNode;
  subtitle: string | ReactNode;
  primaryButton?: ButtonModalType;
  secondaryButton?: ButtonModalType;
};

const ModalPix = ({
  icon,
  title,
  loading,
  subtitle,
  modalTitle,
  modalAction,
  primaryButton,
  secondaryButton
}: ModalPixProps) => {
  return (
    <TemplateModal title={modalTitle} closeModal={modalAction}>
      <S.ModalWrapper>
        <Text align="center" tag="h2" size="xxmedium" weight="bold">
          {title}
        </Text>

        {icon && (
          <S.ModalSuccessWrapperIcon role="figure">
            {icon}
          </S.ModalSuccessWrapperIcon>
        )}

        <Divider size="large" />

        <S.Description>
          <Text align="center" tag="h3" size="xxmedium">
            {subtitle}
          </Text>
        </S.Description>
        <Divider size="large" />

        {loading ? (
          <Loading />
        ) : (
          <Flex direction="column" mb="xlarge">
            {primaryButton && (
              <>
                <Button filled secondary onClick={primaryButton.action} center>
                  {primaryButton.text}
                </Button>

                <Divider />
              </>
            )}

            {secondaryButton && (
              <>
                <Button secondary onClick={secondaryButton.action} center>
                  {secondaryButton.text}
                </Button>
              </>
            )}
          </Flex>
        )}
      </S.ModalWrapper>
    </TemplateModal>
  );
};

export default ModalPix;
