import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    table {
      tr {
        display: revert;

        th {
          padding: ${theme.spacings.small} ${theme.spacings.xsmall};
          font-size: ${theme.font.sizes.xsmall};
          font-weight: ${theme.font.weights.medium};

          &:first-child {
            padding-left: ${theme.spacings.medium};
          }

          &:last-child {
            padding-right: ${theme.spacings.medium};
          }
        }
      }

      svg {
        color: ${theme.mode === 'light'
          ? theme.colors.grayIcon
          : theme.colors.white};
      }

      thead {
        display: none;

        ${customMedia.greaterThan('medium')`
          display: table-header-group;
        `}
      }
    }
  `}
`;
