import { useMemo, useState } from 'react';

import { ReactComponent as Spinner } from 'assets/images/pix/spinner.svg';

import { Button, Flex, InputValue, RangeSlider, Text } from 'components';
import Divider from 'styles/shared/Divider';

import { PixLimits } from '../types';

import * as S from './styles';

export interface OvernightProps {
  data: PixLimits;
  changePixLimits: (data: PixLimits) => void;
  updatePixLimit: () => void;
  loading?: boolean;
}

const Overnight = ({
  data,
  changePixLimits,
  updatePixLimit,
  loading
}: OvernightProps) => {
  const [inEditing, setInEditing] = useState(false);

  const initialValue = useMemo(() => {
    if (data) {
      if (data.increaseRequest?.monthlyLimit)
        return data.increaseRequest?.monthlyLimit.toString();
      if (data.monthly) return data.monthly.toString();
    }
    return '';
  }, [data]);

  const handleLimit = (value: number) => {
    changePixLimits({
      ...data,
      monthly: value,
      increaseRequest: {
        monthlyLimit: value,
        dailyLimit: value,
        scheduledTo: new Date().toISOString()
      }
    });
    setInEditing(false);
  };

  return (
    <>
      <Flex direction="column">
        <S.List>
          <S.ListItem>
            {
              'Para sua segurança, o limite de transferência para outras contas é limitado a  '
            }
            <Text
              weight="medium"
              tag="span"
              color="grayNeutralDark"
              size="medium"
            >
              R$ 1.000.
            </Text>
            {' no período de 20h às 6h.'}
          </S.ListItem>
          <S.ListItem>
            {'Você pode reduzir ou aumentar este valor, que será aprovado '}
            <Text
              weight="medium"
              tag="span"
              color="grayNeutralDark"
              size="medium"
            >
              24 horas após seu pedido.
            </Text>
            {'Esta restrição não é válida nas transferências para CNPJs.'}
          </S.ListItem>
          {/* <S.ListItem>
            Seus favoritos poderão receber valores acima de R$ 1.000 neste
            período, liberado 24 após o cadastro,desde que respeitado o seu
            limite total.
          </S.ListItem> */}
        </S.List>

        <Divider size="medium" />

        <Text
          size="medium"
          weight="medium"
          mb="small"
          mx="large"
          color="grayBlue"
        >
          Limite noturno
        </Text>

        <Flex alignItems="center" direction="column" mx="large">
          <InputValue
            label=""
            maxValue={data.approvedLimit?.toString() || undefined}
            disabledInput={false}
            initialValue={initialValue}
            onApplyValue={value => handleLimit(parseInt(value))}
            onEditing={() => setInEditing(true)}
            resetAmount={true}
            color="blue"
          />

          <RangeSlider
            changeValue={value => handleLimit(parseInt(value))}
            value={data.increaseRequest?.monthlyLimit || data.monthly}
            minValue={0}
            maxValue={data.approvedLimit}
          />

          <Divider size="large" />

          <Button
            filled
            disabled={inEditing}
            onClick={() => updatePixLimit()}
            role="save-button"
          >
            {loading ? <Spinner /> : 'Salvar alterações'}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default Overnight;
