import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  ispb: yup.string().required('Selecione a instituição'),
  agencyNumber: yup.number().required('Entre com o número da agência'),
  accountNumber: yup
    .string()
    .required()
    .test('len', 'Entre com o número da conta', val => val?.length !== 1)
    .required('Entre com o número da conta'),
  accountType: yup.string().required('Entre com o tipo da conta'),
  name: yup.string().required('Entre com o nome do destinatário'),
  documentNumber: yup
    .string()
    .required('Entre com o CPF ou CNPJ do destinatário')
});
