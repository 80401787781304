import { TutorialRequestKey } from '../components';
import * as S from '../../styles';
const Wapstore = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Wapstore
      </S.Title>

      <TutorialRequestKey name="Wapstore" showConfigurationMessage={false} />

      <S.Paragraph tag="p" color="grayBlue">
        Recebeu sua chave? Ótimo! Com a sua chave de integração será necessário
        abrir um chamado para o suporte da Wap.store.
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Na sua conta Wap.store basta clicar em <strong>“Ajuda”</strong> no canto
        inferior esquerdo. Em seguida clique em <strong>“Fale conosco”</strong>.
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Descreva sua solicitação como sendo integração Ame Digital e envie a
        chave da integração e o cashback negociado com a Ame. Caso não tenha
        valor de cashback negociado, apenas informar que não tem. Os analistas
        da Wap.store irão seguir com a integração.
      </S.Paragraph>
    </div>
  );
};
export default Wapstore;
