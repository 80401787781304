import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { Breadcrumb, Select, Text } from 'components';
import PATHS_ROUTES from 'routes/paths';

import {
  Wbuy,
  Xekout,
  Woocommerce,
  MagentoMetrocomm,
  Magento19,
  Magento20,
  Vtex,
  Dooca,
  Opencart,
  NovaOito,
  Conexus,
  NuvemShop,
  RequestKey,
  Irroba,
  Payhow,
  Precode,
  VNDA,
  Wapstore,
  BrasilNaWeb,
  DigitalSeller,
  Magazord
} from './platforms';

const breadCrumbItens = [
  { title: 'Integração', link: PATHS_ROUTES.APP.INTEGRATION.DEFAULT },
  { title: 'Tutoriais', link: PATHS_ROUTES.APP.INTEGRATION.TUTORIAL }
];
const platforms = [
  {
    value: 'wbuy',
    label: 'Wbuy',
    component: <Wbuy />
  },
  {
    value: 'xekout',
    label: 'Xekout',
    component: <Xekout />
  },
  {
    value: 'woocommerce-apiki',
    label: 'Woocommerce (Apiki)',
    component: <Woocommerce />
  },
  {
    value: 'metrocomm',
    label: 'Magento (Metrocomm)',
    component: <MagentoMetrocomm />
  },
  {
    value: 'magento-1-9',
    label: 'Magento 1.9',
    component: <Magento19 />
  },
  {
    value: 'magento-2-0',
    label: 'Magento 2.0',
    component: <Magento20 />
  },
  {
    value: 'vtex',
    label: 'VTEX',
    component: <Vtex />
  },
  {
    value: 'dooca',
    label: 'Dooca',
    component: <Dooca />
  },
  {
    value: 'codemarket',
    label: 'Opencart (CodeMarket)',
    component: <Opencart />
  },
  {
    value: 'youzoom',
    label: 'Nova Oito (YouZoom)',
    component: <NovaOito />
  },
  {
    value: 'conexus',
    label: 'Conexus',
    component: <Conexus />
  },
  {
    value: 'nuvem-shop',
    label: 'NuvemShop',
    component: <NuvemShop />
  },
  {
    value: 'h5-web',
    label: 'H5 Web',
    component: <RequestKey title="H5 Web" email="contato@h5web.com.br" />
  },
  {
    value: 'irroba',
    label: 'Irroba',
    component: <Irroba />
  },
  {
    value: 'n49',
    label: 'N49',
    component: <RequestKey title="N49" email="suporte@n49.com.br" />
  },
  {
    value: 'big-fish',
    label: 'Big Fish',
    component: (
      <RequestKey title="Big Fish" email="bruno@bigfishdigital.com.br" />
    )
  },
  {
    value: 'payhow',
    label: 'Payhow',
    component: <Payhow />
  },
  {
    value: 'precode',
    label: 'Precode',
    component: <Precode />
  },
  {
    value: 'vnda',
    label: 'VNDA',
    component: <VNDA />
  },
  {
    value: 'wapstore',
    label: 'Wapstore',
    component: <Wapstore />
  },
  {
    value: 'brasil-na-web',
    label: 'Brasil Na Web',
    component: <BrasilNaWeb />
  },
  {
    value: 'digital-seller',
    label: 'Digital Seller',
    component: <DigitalSeller />
  },
  {
    value: 'magazord',
    label: 'Magazord',
    component: <Magazord />
  }
];

export type OptionsType = {
  label: string;
  value: string | number;
  component?: ReactElement;
};

const IntegrationTutorial = () => {
  const navigate = useNavigate();
  const [selectedPlatform, setSelectedPlatform] = useState<OptionsType>({
    value: 'wbuy',
    label: 'Wbuy',
    component: <Wbuy />
  });

  return (
    <S.Container>
      <S.Wrapper>
        <Breadcrumb links={breadCrumbItens} />

        <S.SelectContainer>
          <Text size="large" weight="bold">
            Qual é a sua plataforma e-commerce?
          </Text>
          <Select
            name="platforms"
            disabled={false}
            options={platforms}
            value={selectedPlatform}
            onChange={value => setSelectedPlatform(value)}
          />
        </S.SelectContainer>

        <S.Btn
          filled
          onClick={() =>
            navigate(PATHS_ROUTES.APP.INTEGRATION.GENERATE_CREDENTIAL)
          }
        >
          Gerar códigos
        </S.Btn>
        <S.Body>{selectedPlatform.component}</S.Body>
        <div>
          <p>
            Pronto! Fácil né? Após seguir os passos acima, a Ame Digital estará
            disponível como forma de pagamento no site da sua loja. Agora seus
            clientes poderão pagar as compras com a Ame escaneando o QR Code que
            estará nas opções de pagamento!
          </p>

          <p>
            Quer vender muito mais e melhor com Ame? Não deixe de conferir nossa
            aba
            <strong> Vender</strong> para ter acesso a materiais que vão deixar
            seu site super atrativo para os cliente Ame e nossa aba de{' '}
            <strong>Treinamento</strong> onde encontrará vídeos para conhecer e
            explorar todas as funcionalidades da sua conta Ame.
          </p>

          <p>
            Ficou com alguma dúvida ou precisa de ajuda no seu processo de
            integração? Nos envie um email para
            <strong> parceria.digital@amedigital.com</strong>
          </p>

          <p>Boas Vendas!!</p>
        </div>
      </S.Wrapper>
    </S.Container>
  );
};

export default IntegrationTutorial;
