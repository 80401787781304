import { useNavigate } from 'react-router-dom';

import { Flex, ModalChange, Text } from 'components';

import { useBank } from 'contexts/Bank';

import PATHS_ROUTES from 'routes/paths';

import { formatData } from './constants';

import * as S from './styles';

export type ModalEditProps = {
  onClose: () => void;
  cancelAction: () => void;
};

const ModalEdit = ({ onClose, cancelAction }: ModalEditProps) => {
  const navigate = useNavigate();
  const { editingAccount } = useBank();

  const data = formatData(editingAccount!);

  const handleEditButton = () => {
    navigate(PATHS_ROUTES.APP.BANK.ACCOUNT_EDIT);
  };

  return (
    <ModalChange
      buttonAction={handleEditButton}
      onClose={onClose}
      title="Editar conta"
      buttonText="Editar"
      subtitle="Confirme os dados da conta cadastrada"
      cancelAction={cancelAction}
      cancelText="Excluir"
    >
      {data.map(({ field1, field2, id }) => (
        <Flex key={id} alignItems="baseline" justifyContent="space-between">
          <LabelText label={field1.label} text={field1.text} />
          <LabelText label={field2.label} text={field2.text} />
        </Flex>
      ))}
    </ModalChange>
  );
};

type LabelTextProps = {
  label: string;
  text: string;
};

const LabelText = ({ label, text }: LabelTextProps) => {
  return (
    <S.LabelTextWrapper>
      <Text size="xsmall" weight="medium">
        {label}
      </Text>
      <Text>{text}</Text>
    </S.LabelTextWrapper>
  );
};

export default ModalEdit;
