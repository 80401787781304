import { ReactComponent as IconPhone } from 'assets/images/pix/icon-mobile-outline.svg';
import { ReactComponent as IconDocument } from 'assets/images/pix/icon-documents-cpf.svg';
import { ReactComponent as IconEmail } from 'assets/images/pix/icon-mail-outline.svg';
import { ReactComponent as IconVirtualAddress } from 'assets/images/pix/icon-virtual-address.svg';

import PATHS_ROUTES from 'routes/paths';
import { pixKeysType, pixTypes } from 'utils/pix';

export const cardItems = [
  {
    title: 'Número de celular',
    subtitle: 'Cadastre uma chave com o celular',
    icon: {
      element: IconPhone,
      id: 'phone'
    },
    path: PATHS_ROUTES.APP.PIX.KEYS.PHONE_KEY,
    type: pixKeysType.PHONE_NUMBER as pixTypes
  },
  {
    title: 'CNPJ',
    subtitle: 'Cadastre uma chave com o CNPJ',
    icon: {
      element: IconDocument,
      id: 'document'
    },
    path: PATHS_ROUTES.APP.PIX.KEYS.REGISTER_CNPJ,
    type: pixKeysType.CORPORATE_TAX_ID as pixTypes
  },
  {
    title: 'Endereço de e-mail',
    subtitle: 'Cadastre uma chave com seu e-mail',
    icon: {
      element: IconEmail,
      id: 'email'
    },
    path: PATHS_ROUTES.APP.PIX.KEYS.EMAIL_KEY,
    type: pixKeysType.EMAIL as pixTypes
  },
  {
    title: 'Chave aleatória',
    subtitle: 'Crie uma chave aleatória para a conta Ame',
    icon: {
      element: IconVirtualAddress,
      id: 'key'
    },
    path: PATHS_ROUTES.APP.PIX.KEYS.RANDOM_KEY,
    type: pixKeysType.EVP as pixTypes
  }
];

export const breadcrumbItems = [
  {
    link: PATHS_ROUTES.APP.PIX.DEFAULT,
    title: 'Pix'
  },
  {
    link: '#',
    title: 'Cadastrar chave'
  }
];
