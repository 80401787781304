import { Settings } from 'react-slick';
import { Link } from 'react-router-dom';

import { ReactComponent as SvgIconAvailableBalance } from 'assets/images/icon-available-balance.svg';
import { ReactComponent as SvgIconFutureBalance } from 'assets/images/icon-future-balance.svg';
import { ReactComponent as SvgIconTotalBalance } from 'assets/images/icon-total-balance.svg';
import theme from 'styles/theme';
import { CardBalance, Slider, Text } from 'components';
import PATHS_ROUTES from 'routes/paths';
import { useAuth } from 'contexts/Auth';

export type SliderBalanceProps = {
  cashBalance: number;
  totalBalance: number;
  totalFutureCredit: number;
};

const SliderBalance = ({
  cashBalance,
  totalBalance,
  totalFutureCredit
}: SliderBalanceProps) => {
  const { permissions } = useAuth();

  const SliderBalanceSettings: Settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };

  return (
    <Slider settings={SliderBalanceSettings}>
      {permissions?.includes('RESUMO_SALDO_TOTAL') && (
        <CardBalance
          icon={<SvgIconTotalBalance color={theme.colors.blue} />}
          title="Saldo total"
          totalAmount={totalBalance + totalFutureCredit}
          description="Total de saldo disponível mais saldo futuro"
        />
      )}

      {permissions?.includes('RESUMO_SALDO_DISPONIVEL') && (
        <CardBalance
          icon={<SvgIconAvailableBalance color={theme.colors.blue} />}
          title="Saldo disponível"
          totalAmount={cashBalance}
          description="Valor disponível para transferência"
        />
      )}

      {permissions?.includes('RESUMO_SALDO_FUTURO') && (
        <CardBalance
          icon={<SvgIconFutureBalance color={theme.colors.blue} />}
          title="Saldo futuro"
          totalAmount={totalFutureCredit}
          totalType="down"
          description="Vendas realizadas mas não liquidadas"
          rightAction={
            permissions.includes('RESUMO_ANTECIPAR') ? (
              <Link to={PATHS_ROUTES.APP.DASHBOARD.ANTICIPATE_FULL}>
                <Text color="blue" size="small" mt="xsmall" weight="medium">
                  Antecipar
                </Text>
              </Link>
            ) : undefined
          }
        />
      )}
    </Slider>
  );
};

export default SliderBalance;
