import { useCallback, useMemo, useState } from 'react';

import {
  Breadcrumb,
  Flex,
  TemplateShortContent,
  Text,
  Button,
  Input
} from 'components';

import { ReactComponent as IconPix } from 'assets/images/pix/icon-pix.svg';
import Divider from 'styles/shared/Divider';
import { ValidationCode } from '../components/ValidationCode';

import { breadcrumbItems } from './constants';
import RegisterKeyModals from '../components/RegisterKeyModals';
import { useAuth } from 'contexts/Auth';
import { usePixRegister } from 'contexts/Pix/PixRegister';
import { PixRegisterData } from 'contexts/Pix/PixRegister/types';

const EmailKey = () => {
  const { user } = useAuth();
  const {
    loading,
    status,
    setStatus,
    handleCreatePixKey,
    sendCodeValidation,
    featureToggle
  } = usePixRegister();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [validationCode, setValidationCode] = useState<string>('');
  const [validationCodeActived, setValidationCodeActived] =
    useState<boolean>(false);

  const emailInputValue = useMemo(() => {
    return user?.email || '';
  }, [user]);

  const validationFeatureToogle = () => {
    if (featureToggle.enabled) {
      callSendCodeValidation();
      setValidationCodeActived(true);
    } else {
      setOpenModal(true);
    }
  };

  const submitKey = useCallback(
    data => {
      const body: PixRegisterData = data.validationCode
        ? {
            key: data.email,
            validationCode: data.validationCode,
            keyType: 'EMAIL',
            reason: 'USER_REQUESTED'
          }
        : {
            key: data.email,
            keyType: 'EMAIL',
            reason: 'USER_REQUESTED'
          };

      handleCreatePixKey(body);
    },
    [handleCreatePixKey]
  );

  const callSendCodeValidation = () => {
    sendCodeValidation({ key: emailInputValue, keyType: 'EMAIL' });
  };

  const closeModal = () => {
    setStatus('initial');
    setOpenModal(false);
  };

  return (
    <>
      <Breadcrumb links={breadcrumbItems} />

      <Divider size="xlarge" />

      <TemplateShortContent>
        <Flex alignItems="center">
          <IconPix />

          <Text ml="small" tag="h1" size="large" weight="medium">
            Minhas Chaves
          </Text>
        </Flex>

        <Divider size="large" />

        {!validationCodeActived && (
          <Flex direction="column">
            <Text tag="h2" weight="bold" size="large">
              Cadastrar chave com seu endereço de e-mail
            </Text>

            <Divider size="medium" />

            <Input
              name="seu-email"
              label="Seu E-mail"
              value={emailInputValue}
              disabled
            />

            <Divider size="medium" />

            <Button
              center
              filled
              onClick={() => validationFeatureToogle()}
              role="button-register-email"
            >
              Cadastrar chave Pix
            </Button>
          </Flex>
        )}

        {validationCodeActived && (
          <ValidationCode
            sendCodeValidation={() => callSendCodeValidation}
            changeValue={value => setValidationCode(value)}
            onMatch={() => setOpenModal(true)}
            loading={loading}
            description="Digite abaixo o código que enviamos para o e-mail cadastrado na sua conta"
          />
        )}

        {openModal && (
          <RegisterKeyModals
            status={status}
            createdKey={emailInputValue}
            submitKey={() =>
              submitKey(
                validationCodeActived
                  ? { email: emailInputValue, validationCode }
                  : { email: emailInputValue }
              )
            }
            onClose={closeModal}
            loading={loading}
            validationCode={validationCode}
          />
        )}
      </TemplateShortContent>
    </>
  );
};

export default EmailKey;
