import styled, { css } from 'styled-components';

export const Wrapper = styled.input`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.grayExtraMedium};
    border-radius: ${theme.border.smallRadius};
    width: 38.5rem;
    height: 10rem;
    font-size: ${theme.font.sizes.xxlarge};
    font-weight: ${theme.font.weights.medium};
    text-align: center;
    letter-spacing: 4rem;
    padding-left: 2rem;
    text-align: center;
  `}
`;
