import { ReactComponent as IconCart } from 'assets/images/icon-cart.svg';
import { ReactComponent as IconTransaction } from 'assets/images/icon-conciliation-transaction.svg';

import { Text, CardInfo, Checkbox } from 'components';
import Margin from 'styles/shared/Margin';

import useWindowSize from 'hooks/useWindowSize';
import { useCredentials } from 'contexts/Credentials';

const OptionsType = () => {
  const [width] = useWindowSize();
  const { data, setConciliatonType } = useCredentials();

  return (
    <Margin mt="medium" mb="large" mx={width < 768 ? 'xsmall' : 'xlarge'}>
      <Text tag="h3" size="large" weight="medium">
        Tipos de credenciais
      </Text>
      <Text tag="p" color="grayDark">
        Selecione uma opção pra sua integração:
      </Text>

      <Margin mt="large">
        <CardInfo
          text="Conciliação"
          textColor="blue"
          action={() => setConciliatonType('CONCILIATION')}
          icon={<IconCart />}
          rightContent={
            <Checkbox
              value="1"
              type="radio"
              checked={data.conciliationType === 'CONCILIATION'}
            />
          }
          active={data.conciliationType === 'CONCILIATION'}
        >
          Selecione para gerar as credenciais de acesso
        </CardInfo>

        <CardInfo
          text="Transacional"
          textColor="blue"
          action={() => setConciliatonType('TRANSACTIONAL')}
          icon={<IconTransaction />}
          rightContent={
            <Checkbox
              value="2"
              type="radio"
              checked={data.conciliationType === 'TRANSACTIONAL'}
            />
          }
          active={data.conciliationType === 'TRANSACTIONAL'}
        >
          Selecione para gerar as credenciais de acesso
        </CardInfo>
      </Margin>
    </Margin>
  );
};

export default OptionsType;
