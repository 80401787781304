import { ButtonHTMLAttributes, ReactNode } from 'react';

import * as S from './styles';

import { ThemeColors } from 'types/global';

import { ReactComponent as IconReceive } from 'assets/images/icon-receive.svg';

export type ButtonBackPageProps = {
  children?: string | ReactNode;
  color?: ThemeColors;
  IconComponent?: JSX.Element;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonBackPage = ({
  children,
  color = 'blue',
  IconComponent,
  ...props
}: ButtonBackPageProps) => (
  <S.Wrapper color={color} {...props}>
    <S.IconBackArrow />
    {!!children && <span>{children}</span>}
    <S.WrapperIconStatement data-testid="WrapperIconStatement" color={color}>
      {IconComponent || <IconReceive />}
    </S.WrapperIconStatement>
  </S.Wrapper>
);

export default ButtonBackPage;
