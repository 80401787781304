import { useCallback } from 'react';
import { FormikValues } from 'formik';

import { cnpjMask } from 'utils/masks';
import { FieldType } from './useDeeplinkData';
import { useUploadDocuments } from 'hooks/use-seller-upload';
import useMutate from 'hooks/useMutate';
import endpoints from 'services/endpoints';

type SubmitProps = {
  fields: FieldType[];
  document?: string;
  token: string;
  onError: (message: string) => void;
  onSuccess: () => void;
};

type UploadProps = {
  fields: FieldType[];
  document?: string;
};

function parseFieldsToBody(fields: FieldType[], values: FormikValues) {
  return {
    fieldList: fields.map(({ type, name }) => ({
      informationType: name,
      value: type === 'file' ? 'DONE' : values[name]
    }))
  };
}

function parseFieldsToFormData(fields: FieldType[], values: FormikValues) {
  return fields
    .filter(({ type }) => type === 'file')
    .reduce((form, field) => {
      form.append(field.uploadKey!, values[field.name]);

      return form;
    }, new FormData());
}

function useDeeplinkUpload({ fields, document }: UploadProps) {
  const { upload } = useUploadDocuments();

  return useCallback(
    async (values: FormikValues) => {
      return upload(cnpjMask(document!), parseFieldsToFormData(fields, values));
    },
    [fields, document, upload]
  );
}

export function useDeeplinkSubmit({
  fields,
  document,
  token,
  onError,
  onSuccess
}: SubmitProps) {
  const { mutate } = useMutate();
  const handleUpload = useDeeplinkUpload({
    fields,
    document
  });

  return useCallback(
    async (values: FormikValues) => {
      const hasFile = fields.some(({ type }) => type === 'file');

      if (hasFile) {
        try {
          await handleUpload(values);
        } catch (error) {
          onError(
            'Ocorreu um erro ao enviar seus documentos, tente novamente mais tarde.'
          );

          return;
        }
      }

      const body = parseFieldsToBody(fields, values);

      return new Promise(resolve => {
        mutate({
          endpoint: `${endpoints.deeplink}/${token}`,
          method: 'put',
          body,
          onSuccess: data => {
            onSuccess();
            resolve(data);
          },
          onFailure: () => {
            onError(
              'Ocorreu um erro ao enviar seus dados, tente novamente mais tarde.'
            );

            resolve(null);
          }
        });
      });
    },
    [fields, handleUpload, onError, mutate, token, onSuccess]
  );
}
