import * as Yup from 'yup';
import { fieldNames } from 'pages/Accreditation/shared/form';
import { checkCPF, checkCellPhoneNumber } from 'utils/validations';
import { checkBornDateIsValid } from 'utils/checkBornDateIsValid';

export const validation = Yup.object().shape({
  [fieldNames.name]: Yup.string().required('Nome completo obrigatório'),
  [fieldNames.cpf]: Yup.string()
    .required('CPF obrigatório')
    .test('is valid cpf', 'CPF inválido', value => !!value && checkCPF(value)),
  [fieldNames.ownerPhone]: Yup.string()
    .required('Celular obrigatório')
    .test(
      'is valid phone',
      'Celular inválido',
      value => !!value && checkCellPhoneNumber(value)
    ),
  [fieldNames.birthday]: Yup.string()
    .required('Data inválida')
    .test('Data inválida', value =>
      value ? checkBornDateIsValid(value) : false
    ),
  [fieldNames.email]: Yup.string()
    .required('E-mail obrigatório')
    .email('E-mail inválido'),
  [fieldNames.confirmEmail]: Yup.string()
    .required('Confirmar e-mail obrigatório')
    .email('E-mail inválido')
    .test(
      'is different',
      'Os e-mails devem ser iguais',
      async (value, { parent }) => {
        try {
          await Yup.string().required().email().validate(value);

          return value === parent[fieldNames.email];
        } catch {
          return false;
        }
      }
    )
});
