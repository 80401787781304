import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacings.medium};

    button {
      margin-bottom: ${theme.spacings.small};
    }
  `}
`;
