import styled from 'styled-components';

import customMedia from 'styles/media-query';

import Card from 'styles/shared/Card';

export const CardWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${customMedia.greaterThan('medium')`
      flex: 1 1 49%;

      &:nth-child(odd) {
        margin-right: 2%;
      }
    `}

  svg {
    margin-top: 0.5rem;
  }

  ${Card} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
