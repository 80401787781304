import styled, { css, DefaultTheme } from 'styled-components';

type WrapperProps = {
  active?: boolean;
  pointer?: boolean;
  filled?: boolean;
  large?: boolean;
};

const wapperModifiers = {
  active: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.blue};
    color: ${theme.colors.blue};
  `,

  pointer: () => css`
    cursor: pointer;
  `,

  filled: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.blue};
    color: ${theme.colors.blue};
  `,

  activeFilled: (theme: DefaultTheme) => css`
    background: ${theme.colors.blue};
    color: ${theme.colors.white};
  `,

  large: (theme: DefaultTheme) => css`
    min-width: 3.5rem;
    min-height: 3.5rem;
    font-size: ${theme.font.sizes.xxmedium};
  `
};

export const Wrapper = styled.button<WrapperProps>`
  ${({ theme, active, filled, pointer, large }) => css`
    padding: 0;
    min-width: 2.3rem;
    min-height: 2.3rem;
    border-radius: 0.8rem;
    border: 1px solid ${theme.colors.grayMedium};
    color: ${theme.colors.grayMedium};
    font-size: ${theme.font.sizes.small};
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: ${theme.spacings.xsmall};
    margin-right: ${theme.spacings.xsmall};

    ${active && wapperModifiers.active(theme)};
    ${filled && wapperModifiers.filled(theme)};
    ${active && filled && wapperModifiers.activeFilled(theme)};
    ${pointer && wapperModifiers.pointer()};
    ${large && wapperModifiers.large(theme)};

    &:disabled {
      border: 1px solid ${theme.colors.grayMedium};
      color: ${theme.colors.grayMedium};
      cursor: default;
    }
  `}
`;
