import styled, { css } from 'styled-components';

type WrapperProps = {
  isLoading: boolean;
};

const WrapperModifiers = {
  loading: () => css`
    height: 400px;
  `
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ isLoading }) => css`
    ${isLoading && WrapperModifiers.loading()}
  `}
`;

export const Message = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.grayDark};
    margin-top: ${theme.spacings.xlarge};
    text-align: center;
  `};
`;
