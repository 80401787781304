import { CardHalfItemsBackground, CardProfile, Text } from 'components';
import Divider from 'styles/shared/Divider';
import { useScreenSell } from 'contexts/ScreenSell';
import { materialsData } from './constants';
import { Column, Row } from 'styles/shared/Grid';

function Step2() {
  const { materialType } = useScreenSell();

  return (
    <>
      <Text weight="medium" tag="h2" size="large">
        {materialsData[materialType].mainTitle.text}{' '}
        <Text weight="medium" tag="span" size="large" color="blue">
          {materialsData[materialType].mainTitle.blueText}{' '}
        </Text>
        :)
      </Text>

      <Divider size="medium" />

      <Text tag="h3" size="medium" weight="medium">
        {materialsData[materialType].title}
      </Text>

      <Divider size="small" />

      <Row>
        <Column xs={12} sm={12} lg={6}>
          <a
            href={materialsData[materialType].card1.link}
            download
            target="_blank"
            rel="noreferrer"
          >
            <CardProfile
              image={materialsData[materialType].card1.image}
              title={materialsData[materialType].card1.title}
              description={materialsData[materialType].card1.description}
            />
          </a>
        </Column>

        <Column xs={12} sm={12} lg={6}>
          <a
            href={materialsData[materialType].card2.link}
            download
            target="_blank"
            rel="noreferrer"
          >
            <CardProfile
              image={materialsData[materialType].card2.image}
              title={materialsData[materialType].card2.title}
              description={materialsData[materialType].card2.description}
            />
          </a>
        </Column>
      </Row>

      <Divider size="small" />

      <CardHalfItemsBackground
        left={
          <img
            src={materialsData[materialType].card3.image}
            alt="Imagem de informação"
          />
        }
        right={
          <ul>
            {materialsData[materialType].card3.texts.map(item => (
              <li key={item}>
                <Text color="white">{item}</Text>
              </li>
            ))}
          </ul>
        }
        filledBgColor="blueStrongDark"
      />
    </>
  );
}

export default Step2;
