import { formatSimpleDate } from 'utils/masks';

import { ReactComponent as IconCalendar } from 'assets/images/icon-calendar-outline.svg';

import * as S from './styles';

export type CalendarDateProps = {
  date: string;
};

function CalendarDate({ date }: CalendarDateProps) {
  return (
    <S.Wrapper>
      <IconCalendar />{' '}
      <strong>{formatSimpleDate(date, 'MMMM/YYYY').toUpperCase()}</strong>
    </S.Wrapper>
  );
}

export default CalendarDate;
