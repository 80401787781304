import { BreadcrumbLink } from 'components/Breadcrumb';

import PATHS_ROUTES from 'routes/paths';

import { PIX_OUT_KEYS_TYPES } from 'utils/pix';

import { breadcrumbItems } from '../constants';

export const breadcrumb: BreadcrumbLink[] = [
  ...breadcrumbItems,
  {
    title: 'Favoritos',
    link: PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.FAVORITES)
  }
];
