import * as types from './types';
import endpoints from './endpoints.json';
import api from '../api';

import { outputTypes, outputItensTypes, claimStatusTypes } from 'utils/pix';

export async function getPixKey(key: string) {
  const url = endpoints.key.replace('{key}', key);

  const result = await api.get<types.IPixKeyData>(url);

  return result.data;
}

export async function getFinancialInstitutions() {
  const url = endpoints.financialInstitutions;

  const result = await api.get<types.IGetFinancialInstitutionsData[]>(url);

  return result.data;
}

export async function pixOutPayment(data: types.ISetPaymentBody) {
  const url = endpoints.payment;

  const result = await api.post(url, data);

  return result.data;
}

export async function getSettings() {
  const url = endpoints.settings;

  const result = await api.get(url);

  return result.data;
}

export async function setSettings(data: types.ISettingsBodyAndResponse) {
  const url = endpoints.settings;

  const result = await api.post(url, data);

  return result.data;
}

export async function getFaq() {
  const url = endpoints.faq;

  const result = await api.get(url);

  return result.data;
}

export async function getListKeys(offset: number, limit: number) {
  const url = endpoints.listKeys;

  const result = await api.get<types.IDictKeyData[]>(url, {
    params: {
      limit,
      offset
    }
  });

  return result.data;
}

export async function getQRCode(
  key: string,
  output?: outputTypes,
  city?: string
) {
  const url = endpoints.pixQRCode;

  const params: types.IQRCodeParams = { key };
  params.output = output || outputItensTypes.PNG;
  params.city = city || 'SP';

  const result = await api.get<types.IQRCodePNGResponse | string>(url, {
    params: { ...params }
  });

  return result.data;
}

export async function setKey(data: types.ISetRegisterKey) {
  const url = endpoints.registerKey;

  const result = await api.post(url, data);

  return result.data;
}

export async function deleteKey() {
  const url = endpoints.deleteKey;

  const result = await api.delete(url);

  return result.data;
}

export async function getClaimList(claimer: boolean, status: claimStatusTypes) {
  const url = endpoints.pixClaim;

  const params = { claimer, status };

  const result = await api.get(url, {
    params: { ...params }
  });

  return result.data;
}

export async function cancelClaim(id: string, body: types.IClaimUpdateBody) {
  const url = endpoints.cancelClaim.replace('{id}', id);

  const result = await api.post(url, body);

  return result.data;
}

export async function confirmClaim(id: string, body: types.IClaimUpdateBody) {
  const url = endpoints.confirmClaim.replace('{id}', id);

  const result = await api.post(url, body);

  return result.data;
}

export async function getLimits() {
  const url = endpoints.pixLimits;

  const result = await api.get(url);

  return result.data;
}

export async function putLimits(body: types.IPutLimitsBody) {
  const url = endpoints.pixLimits;

  const result = await api.put(url, body);

  return result.data;
}

export async function limitsCalculate(body: types.IPutLimitsBody) {
  const url = endpoints.pixLimitsCalculate;

  const result = await api.put(url, body);

  return result.data;
}
