import { FormikProps, FormikValues } from 'formik';

export const getErrorMessage = (
  name: string,
  form: FormikProps<FormikValues>
) => {
  if (form.touched[name] && form.errors[name]) {
    return form.errors[name]?.toString();
  }
};
