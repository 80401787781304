import { useMemo, useCallback, useState } from 'react';

import { Input, Select, Text, Button } from 'components';

import { SelectOption } from 'components/Select';

import * as S from './styles';

import { checkCPF } from 'utils/validations';
import { Data } from '../../types';
import { removeSpecialCharacters } from 'utils/masks';
import { GroupBeneath } from 'services/groupService';
import { checkBornDateIsValid } from 'utils/checkBornDateIsValid';

export type Step1Props = {
  initialData: Data;
  setData: (values: Data) => void;
  nextStep: () => void;
  handleChangeInput: (event: { target: HTMLInputElement }) => void;
  groups?: GroupBeneath[];
};

const Step1 = ({
  nextStep,
  initialData,
  setData,
  handleChangeInput,
  groups
}: Step1Props) => {
  const [cpfErrorMessage, setCPFErrorMessage] = useState<string | undefined>();
  const [bornDateErrorMessage, setBornDateErrorMessage] = useState<string>();

  const disableButton = useMemo<boolean>(
    () =>
      !initialData.groupId ||
      !initialData.name ||
      !checkBornDateIsValid(initialData.birthday) ||
      !checkCPF(
        removeSpecialCharacters(initialData.documentRequest.documentNumber) ||
          ''
      ) ||
      initialData.birthday.length < 10,
    [
      initialData.birthday,
      initialData.documentRequest.documentNumber,
      initialData.groupId,
      initialData.name
    ]
  );

  const handleIsValidDate = useCallback(
    (event: { target: HTMLInputElement }) =>
      setBornDateErrorMessage(
        !checkBornDateIsValid(event.target.value) ? 'Data inválida' : ''
      ),
    [setBornDateErrorMessage]
  );

  const handleIsValidCPF = useCallback(
    (event: { target: HTMLInputElement }) =>
      setCPFErrorMessage(
        !checkCPF(removeSpecialCharacters(event.target.value))
          ? 'CPF Inválido'
          : ''
      ),
    [setCPFErrorMessage]
  );

  const handleSelectAccount = useCallback(
    (value: SelectOption) =>
      setData({ ...initialData, groupId: value.value.toString() }),
    [initialData, setData]
  );

  const handleChangeDocument = useCallback(
    (event: { target: HTMLInputElement }) =>
      setData({
        ...initialData,
        documentRequest: {
          documentType: 'CPF',
          documentNumber: removeSpecialCharacters(event.target.value)
        }
      }),
    [initialData, setData]
  );

  const groupOptions: SelectOption[] = groups?.length
    ? groups?.map(x => {
        return {
          label: x.groupName,
          value: x.id
        };
      })
    : [];

  return (
    <S.Wrapper>
      <Text color="grayBlue" size="xxmedium" weight="medium">
        Adicionar membro
      </Text>

      <Text color="grayDark" size="small">
        Preencha os dados à baixo para adicionar um novo membro a equipe. Vamos
        começar pelos dados pessoais.
      </Text>

      <S.FormContainer>
        <Select
          name="groupId"
          label="Conta pertencente"
          placeholder="Em qual conta deseja adicionar?"
          options={groupOptions}
          value={groupOptions.find(x => x.value === initialData.groupId)}
          onChange={handleSelectAccount}
        />

        <Input
          name="document"
          label="CPF"
          maskType="CPF"
          value={initialData.documentRequest.documentNumber}
          onChange={handleChangeDocument}
          onBlur={handleIsValidCPF}
          errorMessage={cpfErrorMessage}
        />

        <Input
          name="name"
          label="Nome"
          value={initialData.name}
          onChange={handleChangeInput}
        />

        <Input
          name="birthday"
          label="Data de nascimento"
          maskType="DATE"
          value={initialData.birthday}
          onChange={handleChangeInput}
          onBlur={handleIsValidDate}
          errorMessage={bornDateErrorMessage}
        />
      </S.FormContainer>

      <Button
        secondary
        center
        onClick={nextStep}
        disabled={disableButton}
        large
        title={disableButton ? 'Preencha todos os dados' : ''}
      >
        Prosseguir
      </Button>
    </S.Wrapper>
  );
};

export default Step1;
