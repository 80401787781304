import styled from 'styled-components';

export const Wrapper = styled.nav`
  width: 100%;
  display: inline-block;

  button {
    display: inline-block;
    margin-right: 1rem;
  }
`;
