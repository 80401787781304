import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

import { Text, Flex } from 'components';

export const Wrapper = styled.div``;

export const SectionSubtitleTitle = styled(Text)`
  max-width: 35rem;
`;

export const CardContainer = styled(Flex)`
  flex-direction: column;

  ${customMedia.greaterThan('medium')`
    flex-direction: row;
  `};
`;

export const WrapperIcon = styled.div`
  ${({ theme }) => css`
    height: 3.3rem;
    width: 3.3rem;

    margin: ${theme.spacings.xsmall} ${theme.spacings.medium}
      ${theme.spacings.xsmall} ${theme.spacings.xsmall};

    svg {
      color: ${theme.colors.blue};
      fill: ${theme.colors.blue};

      height: 100%;
      width: auto;
    }
  `}
`;
