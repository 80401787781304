import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const FormContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.large};
    margin-bottom: 10rem;
  `}
`;
