import { ReactNode } from 'react';

import { ProfilePicture, Text } from 'components';
import { ThemeColors } from 'types/global';

import { maskDocument } from 'utils/masks';

import * as S from './styles';

export type CardAccountProps = {
  title: string;
  subtitle?: string;
  document?: string | number;
  profileType: string;
  imgSrc?: string;
  selected?: boolean;
  hidePicture?: boolean;
  filled?: boolean;
  children?: ReactNode;
  subTitleColor?: ThemeColors;
};

export const CardAccount = ({
  title,
  subtitle,
  document,
  imgSrc = '',
  selected = false,
  hidePicture = false,
  filled,
  subTitleColor = 'grayDark',
  children
}: CardAccountProps) => {
  return (
    <S.Wrapper selected={selected} filled={filled}>
      <div className="column-left">
        {!hidePicture && (
          <ProfilePicture image={imgSrc} name={title} width={6} height={5} />
        )}

        <S.Content hidePicture={hidePicture}>
          <Text tag="h5" color="blackDark" size="medium" weight="medium">
            {title}
          </Text>

          {(!!subtitle || !!document) && (
            <Text tag="span" color={subTitleColor} size="xsmall">
              {!!document && !subtitle
                ? `CNPJ: ${maskDocument(document, 'CNPJ')}`
                : subtitle}
            </Text>
          )}
        </S.Content>
      </div>

      {!!children && <div className="column-right">{children}</div>}
    </S.Wrapper>
  );
};

export default CardAccount;
