import { Routes, Route, Navigate } from 'react-router-dom';

import {
  LoginAuth,
  LoginForgotPassword,
  LoginNewPassword,
  LoginAuthValidate
} from 'pages/Login';

import PATHS_ROUTES from 'routes/paths';
import {
  PageAccreditation,
  PageAccreditationDigital,
  PageAccreditationPhysical,
  PageDeepLink
} from 'pages';

import { TemplateChallenge } from 'components';

const AuthRoutes = () => (
  <TemplateChallenge>
    <Routes>
      <Route path={PATHS_ROUTES.AUTH.LOGIN} element={<LoginAuth />} />

      <Route
        path={PATHS_ROUTES.AUTH.VALIDATE}
        element={<LoginAuthValidate />}
      />
      <Route
        path={PATHS_ROUTES.AUTH.FORGOT_PASSWORD}
        element={<LoginForgotPassword />}
      />
      <Route
        path={PATHS_ROUTES.AUTH.NEW_PASSWORD}
        element={<LoginNewPassword />}
      />
      <Route
        path={PATHS_ROUTES.AUTH.FIRST_PASSWORD}
        element={<LoginNewPassword firstPassword />}
      />
      <Route
        path={PATHS_ROUTES.AUTH.ACCREDITATION.DEFAULT}
        element={<PageAccreditation />}
      />
      <Route
        path={PATHS_ROUTES.AUTH.ACCREDITATION.PHYSICAL}
        element={<PageAccreditationPhysical />}
      />
      <Route
        path={PATHS_ROUTES.AUTH.ACCREDITATION.DIGITAL}
        element={<PageAccreditationDigital />}
      />

      <Route path={PATHS_ROUTES.AUTH.DEEPLINK} element={<PageDeepLink />} />

      <Route
        path="*"
        element={<Navigate to={PATHS_ROUTES.AUTH.LOGIN} replace />}
      />
    </Routes>
  </TemplateChallenge>
);

export default AuthRoutes;
