import { TemplateAccreditation } from 'components';

import { AccreditationStepsProvider } from 'contexts/AccreditationSteps';

import AccreditationSteps from 'pages/Accreditation/shared/AccreditationSteps';
import {
  Step3,
  Step4,
  Step5,
  Step6,
  StepUploadFiles,
  validation3,
  validation4,
  validation5,
  validation6,
  validationUploadFiles
} from 'pages/Accreditation/shared/steps';

import { Step1, Step2, validation1, validation2 } from './components';

const pages = [
  {
    label: 'Sobre a empresa',
    steps: [
      {
        Step: Step1,
        validation: validation1
      },
      {
        Step: Step2,
        validation: validation2
      }
    ]
  },
  {
    label: 'Sobre você',
    steps: [
      {
        Step: Step3,
        validation: validation3
      },
      {
        Step: Step4,
        validation: validation4
      },
      {
        Step: Step5,
        validation: validation5
      }
    ]
  },
  {
    label: 'Sua conta',
    steps: [
      {
        Step: Step6,
        validation: validation6
      },
      {
        Step: StepUploadFiles,
        validation: validationUploadFiles
      }
    ]
  }
];

function Physical() {
  return (
    <TemplateAccreditation>
      <AccreditationStepsProvider pages={pages}>
        <AccreditationSteps merchantType="PHYSICAL" />
      </AccreditationStepsProvider>
    </TemplateAccreditation>
  );
}

export default Physical;
