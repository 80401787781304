import { ReactNode } from 'react';

import { ThemeColors, ThemeFontSizes } from 'types/global';

import Text from 'components/Text';
import Money from 'components/Money';

import { Column, NumberColumns } from 'styles/shared/Grid';

import * as S from './styles';

export type CardValueProps = {
  labelTop?: string;
  labelBottom?: string;
  description?: string;
  color?: ThemeColors;
  value: number;
  valueSize?: ThemeFontSizes;
  loading?: boolean;
  loadingText?: string;
  loadingLabel?: string;
  loadingDescription?: string;
  contentColumnSize?: NumberColumns;
  children?: ReactNode;
  disabled?: boolean;
  lineThrough?: boolean;
};

export const CardValue = ({
  labelTop,
  labelBottom,
  description,
  color,
  value,
  valueSize = 'large',
  loading = false,
  loadingText = 'Carregando',
  loadingLabel,
  loadingDescription,
  contentColumnSize = 6,
  children,
  disabled = false,
  lineThrough = false
}: CardValueProps) => {
  const valueColumnSize = (12 - contentColumnSize) as NumberColumns;

  return (
    <>
      <S.Wrapper disabled={disabled}>
        <S.Content>
          <Column xs={valueColumnSize} className="column-left">
            {!!labelTop && (
              <Text tag="span" size="xsmall">
                {labelTop}
              </Text>
            )}
            {loading ? (
              <Text color={color} size={valueSize} weight="medium">
                {loadingText}...
              </Text>
            ) : (
              <Money
                color={color}
                size={valueSize}
                value={value}
                weight="medium"
                lineThrough={lineThrough}
              />
            )}
            {loading && !!loadingLabel && (
              <Text tag="span" color={color} size="xsmall">
                {loadingLabel}
              </Text>
            )}
            {!!labelBottom && !loading && (
              <Text tag="span" color={color} size="xsmall">
                {labelBottom}
              </Text>
            )}
          </Column>

          <Column xs={contentColumnSize} className="column-right">
            {children}
          </Column>
        </S.Content>
      </S.Wrapper>
      {loading && !!loadingDescription && (
        <Text size="small" color="grayDark">
          {loadingDescription}
        </Text>
      )}
      {!!description && !loading && (
        <Text size="small" color="grayDark">
          {description}
        </Text>
      )}
    </>
  );
};

export default CardValue;
