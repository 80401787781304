export const cardInfoDataOne = [
  '1- Copie o link do QR Code',
  '2- Compartilhe com o cliente',
  '3- Peça para o cliente ler seu QR Code no app da Ame!'
];

export const cardInfoDataTwo = [
  '1- Baixe o QR Code;',
  '2- Abra o arquivo e escolha o formato de impressão A4;',
  '3- Pronto! É só imprimir e deixar o QR Code visível para seus clientes!'
];
