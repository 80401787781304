import React, { useContext } from 'react';
import { Button, Text, InputMoney } from 'components';
import Divider from 'styles/shared/Divider';
import * as S from './styles';
import { PixContext } from '../..';

const SetValueAndInformations = ({
  handleGenerateQRCode
}: {
  handleGenerateQRCode: () => void;
}) => {
  const pixContext = useContext(PixContext);
  const { pixValue, pixIdentifier, pixDescription, handleInputInformation } =
    pixContext;

  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        Informações do QR Code
      </Text>

      <Divider size="medium" />

      <InputMoney
        name="value"
        label="Digite o valor (opcional)"
        value={pixValue}
        onChange={value => handleInputInformation('value', value)}
      />

      <S.InputText
        name="identifier"
        placeholder="Informe aqui"
        label="Identificador do pagamento (opcional)"
        value={pixIdentifier}
        onChange={el => handleInputInformation('identifier', el.target.value)}
      />

      <S.InputText
        name="description"
        placeholder="Informe aqui"
        label="Descrição do pagamento (opcional)"
        value={pixDescription}
        onChange={el => handleInputInformation('description', el.target.value)}
      />

      <Divider size="medium" />

      <Button
        center
        filled
        onClick={() => {
          handleGenerateQRCode();
        }}
        data-testid="button-generate-qr-code"
      >
        Gerar QR Code
      </Button>
    </>
  );
};

export default SetValueAndInformations;
