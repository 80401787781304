import { Text } from 'components';
import PATHS_ROUTES from 'routes/paths';

export const breadcrumbItems = [
  {
    link: PATHS_ROUTES.APP.PIX.DEFAULT,
    title: 'Pix'
  },
  {
    link: '#',
    title: 'Meus limites Pix'
  }
];

export const messagesSuccess = {
  CASHOUT: (
    <Text size="medium" align="center">
      {'Seu limite do Pix para transações de '}

      <Text size="medium" weight="bold" tag="span">
        {'mesmo CNPJ'}
      </Text>

      {', foi atualizado.'}
    </Text>
  ),
  TRANSFER: (
    <Text size="medium" align="center">
      {'Seu limite do Pix para transações de '}

      <Text size="medium" weight="bold" tag="span">
        {'outro CNPJ'}
      </Text>

      {', foi atualizado.'}
    </Text>
  ),
  PURCHASE: (
    <Text size="medium" align="center">
      {'Seu limite do Pix para transações de '}

      <Text size="medium" weight="bold" tag="span">
        {'conta Ame'}
      </Text>

      {', foi atualizado.'}
    </Text>
  ),
  NIGHT_PERIOD: (
    <Text size="medium" align="center">
      {
        'O aumento do seu limite Pix já foi feito e estará disponível entre 24h e 48h.'
      }
    </Text>
  )
};
