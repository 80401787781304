import styled from 'styled-components';
import customMedia from 'styles/media-query';

export const ModalWrapper = styled.div`
  ${customMedia.greaterThan('medium')`
    height: 50rem;
  `}
`;

export const ModalSuccessWrapperIcon = styled.div`
  width: 21.5rem;
  margin: auto;
`;

export const Description = styled.div`
  ${customMedia.greaterThan('medium')`
    margin: 0 4rem;
  `}

  ${customMedia.lessThan('small')`
    br {
      display: none;
    }
  `}
`;
