import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

import { ReactComponent as IconPaymentApproved } from 'assets/images/icon-payment-approved.svg';

import { TableInstallmentsProps } from '.';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    th {
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.weights.medium};
    }

    td:first-child,
    th:first-child {
      padding-left: ${theme.spacings.medium};
    }

    td:last-child,
    th:last-child {
      padding-right: ${theme.spacings.medium};
    }
  `}
`;

export const DesktopView = styled.div`
  display: none;

  ${customMedia.greaterThan('large')`
    display: block;
  `}
`;

export const MobileView = styled.div`
  display: block;

  ${customMedia.greaterThan('large')`
    display: none;
  `}
`;

type IconInstallmentType = Omit<TableInstallmentsProps, 'splits'>;

export const IconInstallmentApproved = styled(
  IconPaymentApproved
)<IconInstallmentType>`
  ${({ theme, color }) => css`
    color: ${theme.colors[color!]};
    margin-right: ${theme.spacings.xsmall};
  `}
`;
