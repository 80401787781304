/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError, AxiosRequestConfig } from 'axios';
import * as React from 'react';
import api from 'services/api';

type Method = 'post' | 'put' | 'delete';

type MutateState = {
  data: any;
  loading: boolean;
  error: string | null;
  status: number | null;
};

type MutateRequest = {
  body?: any;
  method?: Method;
  endpoint: string;
  errorMessage?: string;
  onSuccess?: (data?: any) => void;
  onFailure?: (axiosError?: AxiosError) => void;
  config?: Omit<AxiosRequestConfig, 'method' | 'data' | 'url'>;
};

const useMutate = () => {
  const [state, setState] = React.useState<MutateState>({
    data: null,
    status: null,
    error: null,
    loading: false
  });

  const clearError = () =>
    setState(({ ...prev }) => ({ ...prev, error: null }));

  const mutate = React.useCallback((request: MutateRequest) => {
    setState(({ ...prev }) => ({ ...prev, loading: true }));

    const {
      body,
      config,
      endpoint,
      errorMessage,
      method = 'post',
      onSuccess = () => null,
      onFailure = () => null
    } = request;

    api(endpoint, { method, data: body, ...config })
      .then(({ data, status }) => {
        setState(({ ...prev }) => ({ ...prev, data, status, loading: false }));
        onSuccess(data);
      })
      .catch((axiosError: AxiosError) => {
        const newState = {
          status: 0,
          error: ''
        };

        try {
          const { status = 0, data } = axiosError.response || {};

          newState.status = status;
          newState.error = data.message;
        } catch {
          newState.error = errorMessage ?? 'Falha ao realizar a operação';
        }

        setState(({ ...prev }) => ({ ...prev, ...newState, loading: false }));
        onFailure(axiosError);
      });
  }, []);

  return { state, mutate, clearError };
};

export default useMutate;
