import { useEffect, useMemo } from 'react';
import useTimer from 'hooks/useTimer';
import { useFormikContext } from 'formik';
import { useSendCode } from './use-send-sms-code';
import { Button, CodeInput, Flex, Loading, Text, TimeText } from 'components';
import { StepProps } from 'contexts/AccreditationSteps/types';
import { AccreditationValues } from 'pages/Accreditation/shared/form';
import { removeSpecialCharacters, maskPhoneNumber } from 'utils/masks';
import { ReactComponent as IconAlert } from 'assets/images/icon-alert.svg';

import * as S from './styles';

const Step4 = ({ names, goToPreviousStep }: StepProps) => {
  const form = useFormikContext<AccreditationValues>();

  const { setFieldError, setFieldTouched } = form;

  const { time, restartTimer } = useTimer({ decrement: true });

  const rawPhone = removeSpecialCharacters(form.values[names.ownerPhone]);

  const phone = useMemo(() => maskPhoneNumber(rawPhone), [rawPhone]);

  const { resend, error, loading } = useSendCode(rawPhone);

  const showLoading = loading || form.isSubmitting;

  const handleMatch = async (code: string) => {
    form.setFieldValue(names.validatePhone, code, false);

    setTimeout(form.handleSubmit);
  };

  useEffect(() => {
    if (error) {
      goToPreviousStep();
      setFieldTouched(names.ownerPhone, true, false);
      setFieldError(names.ownerPhone, error);
    }
  }, [
    error,
    setFieldError,
    setFieldTouched,
    goToPreviousStep,
    names.ownerPhone
  ]);

  return (
    <>
      {showLoading && <Loading fullContent fullWidth />}

      <S.Title>
        Hora de validar sua conta <S.HighlightText>:)</S.HighlightText>
      </S.Title>

      <S.Subtitle>
        Digite o código que enviamos por SMS para seu número de celular
      </S.Subtitle>

      <S.PhoneWrapper>
        <S.Phone>{phone}</S.Phone>

        <Button type="button" tertiary onClick={goToPreviousStep}>
          <strong>Editar</strong>
        </Button>
      </S.PhoneWrapper>

      <Flex alignItems="center" direction="column" mt="xlarge">
        <CodeInput
          name={names.validatePhone}
          id={names.validatePhone}
          onChangeValue={value =>
            form.setFieldValue(names.validatePhone, value, false)
          }
          onMatch={handleMatch}
          onBlur={form.handleBlur}
        />

        <Text color="red" size="xsmall">
          {form.errors[names.validatePhone]}
        </Text>

        <TimeText
          time={time}
          resendCode={() => {
            resend();
            restartTimer();
          }}
        />
      </Flex>

      <Flex
        alignItems="center"
        direction="column"
        justifyContent="center"
        mt="xlarge"
      >
        <IconAlert />

        <S.AlertText>
          Usamos o número de celular para validar sua identidade. Você não vai
          precisar dele para entrar na sua conta, ok? :)
        </S.AlertText>
      </Flex>
    </>
  );
};

export default Step4;
