/* eslint-disable no-console */
import { useParams } from 'react-router-dom';
import Ame from 'ame-super-app-web';
import { useEffect, useState } from 'react';
import './styles.css';
import Loading from '../../components/Loading';
import { ErrorMessage, Flex } from 'components';
import { getUserFriendlyErrorMessage } from '../../utils/miniapp';

type ParamsTypes = {
  slug: string;
  context: string;
};

const CONTAINER_SELECTOR = 'main';
const PLATFORM_FRAME_SELECTOR = '#ame-platform-frame';

const MiniAppResolver = () => {
  const { slug, context } = useParams<ParamsTypes>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorApplicationLoad, setErrorApplicationLoad] = useState<string>('');

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const container = document.querySelector(CONTAINER_SELECTOR);

      if (!container || !slug) {
        return;
      }

      executeMiniAppViewAdapter();

      try {
        await Ame.openMiniApp({
          container,
          slug,
          context
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e) {
          setErrorApplicationLoad(e.message);
        }
        console.error('error on loading mini-app', e);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [slug, context]);

  useEffect(() => {
    return () => {
      executeMiniAppViewAdapter(false);
      const iframe = document.querySelector(PLATFORM_FRAME_SELECTOR);
      if (!iframe) {
        return;
      }

      iframe.remove();
    };
  }, []);

  if (errorApplicationLoad) {
    const errorMessage = getUserFriendlyErrorMessage(errorApplicationLoad);
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        {...{ style: { height: '80vh' } }}
      >
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Flex>
    );
  }

  return <div>{loading && <Loading fullContent />}</div>;
};

const executeMiniAppViewAdapter = (enable = true) => {
  const mainContainer = document.querySelector('main');
  if (mainContainer) {
    const altClassName = 'mainAlt';
    const mainContainerClassList = mainContainer.classList;
    if (enable) {
      mainContainerClassList.remove(altClassName);
      mainContainerClassList.add(altClassName);
    } else {
      mainContainerClassList.remove(altClassName);
    }
  }
};

export default MiniAppResolver;
