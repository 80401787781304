import PATHS_ROUTES from 'routes/paths';

export const breadcrumbItems = [
  {
    link: PATHS_ROUTES.APP.PIX.DEFAULT,
    title: 'Pix'
  },
  {
    link: '#',
    title: 'Receber com QR code'
  }
];

export const modalStatusMessages = {
  title: {
    200: 'QR Code gerado com sucesso :)',
    400: 'Erro de processamento',
    503: 'Transação não concluída',
    504: 'Transação não concluída',
    500: 'Falha de comunicação'
  },
  description: {
    200: 'Os dados preenchidos estarão visíveis para o pagador ao ler o QR Code.',
    400: 'Tivemos um erro ao concluir sua transação. Mas não se preocupe, você pode tentar novamente. Vamos lá?',
    503: 'O tempo de processamento da transação já acabou, mas não se preocupe, você pode tentar novamente. Vamos lá?',
    504: 'O tempo de processamento da transação já acabou, mas não se preocupe, você pode tentar novamente. Vamos lá?',
    500: 'Tivemos uma falha de comunicação com o sistema. Mas, não se preocupe, você pode tentar novamente. Vamos lá?'
  },
  button: {
    200: 'Entendi',
    400: 'Tentar novamente',
    503: 'Tentar novamente',
    504: 'Tentar novamente',
    500: 'Tentar novamente'
  }
};

export const initialMutate = {
  state: {
    data: null,
    loading: false,
    error: null,
    status: null
  },
  mutate: () => null,
  clearError: () => null
};

export const initialContext = {
  pixValue: '',
  pixIdentifier: '',
  pixDescription: '',
  pngQRCodeRequest: initialMutate,
  rawQRCodeRequest: initialMutate,
  handleInputInformation: () => null,
  handlePageNavigation: () => null
};

export const errorCodes = [200, 400, 503, 504];
