import PermissionsImg from '../assets/nuvem-shop1.png';
import NuvemIntegrationImg from '../assets/nuvem-shop2.png';
import DoneImg from '../assets/nuvem-shop3.png';
import { TutorialGenerateKeys } from '../components';
import * as S from '../../styles';
const NuvemShop = () => {
  return (
    <div>
      <S.Title tag="h1" size="xlarge" weight="bold">
        NuvemShop
      </S.Title>

      <TutorialGenerateKeys name="Nuvemshop" />

      <S.Paragraph tag="p" color="grayBlue">
        Acesse a página da Ame na Loja de Aplicativos Nuvem através do endereço{' '}
        <S.Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.nuvemshop.com.br/loja-aplicativos-nuvem/ame-digital"
        >
          Instalar o aplicativo Ame Digital
        </S.Link>
        .
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Clique em Instalar aplicativo, leia as informações de permissões da
        ferramenta e estando de acordo, clicar em Aceitar e começar a usar.
      </S.Paragraph>

      <S.ImgWrapper>
        <img src={PermissionsImg} alt="Permissões do aplicativo Ame" />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Você será redirecionado para a página de integração da Ame com a
        Nuvemshop, click em <strong>“Já tenho as chaves”</strong> e insira as
        chaves criadas nos passos anteriores.
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={NuvemIntegrationImg}
          alt="Página de integração Ame e NuvemShop"
        />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Ao adicionar as suas chaves, a Ame já estará visível no seu painel de
        administração, em Configurações {'>'} Meios de Pagamento e você já
        estará com o meio de pagamento ativo em sua loja.
      </S.Paragraph>

      <S.ImgWrapper>
        <img src={DoneImg} alt="Meios de Pagamento no site da NuvemShop" />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Pronto! Fácil né? Agora seus clientes poderão pagar as compras com a Ame
        escaneando o QR Code que estará nas opções de pagamento!
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Quer vender muito mais e melhor com Ame? Não deixe de conferir nossa aba
        <strong> Vender</strong> para ter acesso a materiais que vão deixar seu
        site super atrativo para os cliente Ame e nossa aba de{' '}
        <strong>Treinamento</strong> onde encontrará vídeos para conhecer e
        explorar todas as funcionalidades da sua conta Ame.
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Ficou com alguma dúvida ou precisa de ajuda no seu processo de
        integração? Nos envie um email para
        <strong> parceria.digital@amedigital.com</strong>
      </S.Paragraph>

      <S.Paragraph tag="p" color="grayBlue">
        Boas Vendas!!
      </S.Paragraph>
    </div>
  );
};

export default NuvemShop;
