import useRequest from 'hooks/useRequest';
import { useMemo } from 'react';
import endpoints from 'services/endpoints';
import { Platform } from '../../form';

type FeeType = 'INDIVIDUAL' | 'CITY' | 'PARTNERSHIP' | 'PLATFORM';

interface Fee {
  id: string;
  anticipationFee: string;
  creditCardFee: string;
  dailiesLiquid: string;
  debitFee: string;
  sevenToTwelveFee: string;
  twoToSixFee: string;
  receiveAdvance?: boolean;
}

interface IndividualFee extends Fee {
  individualFeeId: string;
  individualType: string;
}

interface GetData {
  loading?: boolean;
  platform?: Fee[];
  partnerData?: Fee[];
  cityData?: Fee[];
  feeData?: IndividualFee[];
}

type DataReturn = {
  fees: Fee[];
  type: FeeType;
};

function getData({
  platform,
  loading,
  partnerData,
  cityData,
  feeData
}: GetData): DataReturn {
  if (loading) {
    return {
      fees: [],
      type: 'INDIVIDUAL'
    };
  }

  if (platform?.length) {
    return {
      fees: platform,
      type: 'PLATFORM'
    };
  }

  if (cityData?.length) {
    return {
      fees: cityData,
      type: 'CITY'
    };
  }

  if (partnerData?.length) {
    return {
      fees: partnerData,
      type: 'PARTNERSHIP'
    };
  }

  if (feeData?.length) {
    return {
      fees: feeData.map(fee => ({
        ...fee,
        id: fee.individualFeeId
      })),
      type: 'INDIVIDUAL'
    };
  }

  return {
    fees: [],
    type: 'INDIVIDUAL'
  };
}

export function useAccreditationFee({
  platform,
  partnership,
  city,
  uf
}: {
  platform?: Platform;
  partnership?: string;
  city?: string;
  uf?: string;
}) {
  const platformFees = useMemo(() => {
    if (!platform) return [];

    return [
      {
        ...platform,
        id: platform.platformAcronym
      }
    ];
  }, [platform]);

  const {
    data: cityData,
    loading: cityLoading,
    error: cityError
  } = useRequest<Fee[]>({
    url: platform
      ? null
      : `${endpoints.accreditationCityTax}?city=${city}&uf=${uf}`
  });

  const {
    data: partnerData,
    loading: partnerLoading,
    error: partnerError
  } = useRequest<Fee[]>({
    url: partnership
      ? `${endpoints.accreditationPartnership}/${partnership}`
      : null
  });

  const {
    data: feeData,
    loading: feeLoading,
    error: feeError
  } = useRequest<IndividualFee[]>({
    url: !partnership ? endpoints.accreditationFee : null
  });

  const loading = cityLoading || partnerLoading || feeLoading;

  const data = getData({
    loading,
    platform: platformFees,
    partnerData,
    cityData,
    feeData
  });

  return {
    ...data,
    partnerError,
    cityError,
    feeError,
    loading
  };
}
