import { ReactNode } from 'react';

import Text from 'components/Text';

import * as S from './styles';
import { ThemeColors } from 'types/global';

import { ReactComponent as IconArrowRight } from 'assets/images/icon-big-arrow-right.svg';

export type CardInfoProps = {
  icon?: JSX.Element;
  arrowIcon?: boolean;
  text: string;
  action?: () => void;
  children?: ReactNode;
  infoText?: string | null;
  rightContent?: ReactNode;
  active?: boolean;
  large?: boolean;
  disabled?: boolean;
  textColor?: ThemeColors;
};

export const CardInfo = ({
  icon,
  text,
  action,
  active,
  infoText,
  children,
  arrowIcon,
  rightContent,
  large,
  disabled,
  textColor
}: CardInfoProps) => {
  return (
    <S.CardWrapper
      onClick={action}
      isClicked={!!action}
      isActive={!!active}
      large={large}
      disabled={disabled}
    >
      <S.Content>
        {icon}
        <div>
          <Text size="medium" weight="medium" color={textColor}>
            {text}
          </Text>
          {children}
        </div>
      </S.Content>
      {arrowIcon && !infoText && !rightContent && <IconArrowRight />}
      {infoText && (
        <Text size="xsmall" color="blue" weight="bold">
          {infoText}
        </Text>
      )}
      {rightContent && !infoText && <>{rightContent}</>}
    </S.CardWrapper>
  );
};

export default CardInfo;
