import { useEffect, useState } from 'react';

import endpoints from 'services/endpoints';
import useMutate from 'hooks/useMutate';
import { AxiosError } from 'axios';

export function useSessionToken() {
  const [axiosError, setError] = useState<null | AxiosError>(null);
  const [hasSession, setHasSession] = useState(false);
  const { mutate, state } = useMutate();

  useEffect(() => {
    mutate({
      endpoint: endpoints.tokenSession,
      onSuccess: () => setHasSession(true),
      onFailure: error => setError(error!)
    });
  }, [mutate]);

  return { ...state, axiosError, hasSession };
}
