import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Column } from 'styles/shared/Grid';
import * as S from '../EditContent/styles';

export type TemplateShortContentTypes = {
  backLink?: string;
  backTitle?: string;
  children: ReactNode;
};

export const TemplateShortContent = ({
  backLink,
  backTitle,
  children
}: TemplateShortContentTypes) => (
  <>
    {backLink && backTitle && (
      <S.Wrapper backLink={backLink}>
        <Link to={backLink}>
          <S.IconBack title={backTitle} />
        </Link>
      </S.Wrapper>
    )}

    <Column offsetMd={3} sm={9} md={6}>
      {children}
    </Column>
  </>
);

export default TemplateShortContent;
