import { ReactNode } from 'react';

import * as S from './styles';
import { Column, Container, Row } from 'styles/shared/Grid';

import { Header } from 'components';

import { ReactComponent as IconHelpOutline } from 'assets/images/icon-help-outline.svg';

export type AuthLayoutProps = {
  children: ReactNode;
};

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <div>
      <Header>
        <a
          href="https://www.amedigital.com/duvidas/plus"
          target="_blank"
          rel="noreferrer"
        >
          <IconHelpOutline />
        </a>
      </Header>

      <S.Content>
        <Container>
          <Row>
            <Column xs={12}>{children}</Column>
          </Row>
        </Container>
      </S.Content>
    </div>
  );
};

export default AuthLayout;
