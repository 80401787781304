import { useMemo, useEffect, useState, useCallback } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate
} from 'react-router-dom';

import { TemplateChallenge, TemplateApp, ModalUpdateUser } from 'components';

import Welcome from 'pages/Welcome';
import Dashboard from 'pages/Dashboard';
import AnticipateFull from 'pages/Dashboard/AnticipateFull';
import Sell from 'pages/Sell';
// import Promotion from 'pages/Promotion';
// import Leadership from 'pages/Leadership';
import UpdateUser from 'pages/UpdateUser';

import {
  Integration,
  GenerateCredential,
  IntegrationTutorial
} from 'pages/Integration';

import {
  AccountProfile,
  AccountPlanData,
  AccountEditInstallments,
  //AccountEditPhoto,
  AccountUpdatePassword,
  AccountUpdateName,
  AccountUpdateBirthday,
  AccountUpdatePhone
  // AccountPrivacy,
  // AccountDocuments
} from 'pages/Account';

// import {
//   AccountsAffiliate,
//   AccountsAffiliates,
//   AccountsMyAccounts,
//   AccountsGroups,
//   AccountsGroupDependents
// } from 'pages/Accounts';

import {
  StatementAnticipate,
  StatementDetail,
  StatementList,
  StatementRefund
} from 'pages/Statements';

import {
  // Team,
  // Teams,
  // TeamAdd,
  TeamMember,
  TeamMembers,
  //TeamAddGroup,
  TeamAddMember,
  // TeamAllMembers,
  // TeamSubMembers,
  TeamMemberPermission,
  TeamMemberResetPassword
} from 'pages/Team';

import {
  Withdraw,
  AccountRegistration,
  BankSelect,
  BankList
} from 'pages/Bank';

// import { Options, CancelAccount } from 'pages/Privacy';

import { ReportCreate, ReportList } from 'pages/Report';
import { TrainingPlayer, TrainingList } from 'pages/Training';

import {
  AgencyAccount,
  Pix,
  PixFaq,
  PixRegisterKeys,
  PixTax,
  PixKeys,
  PixRegisterCNPJ,
  PixRegisterPhone,
  PixRegisterEmail,
  PixRegisterRandom,
  PixRequests,
  PixMyQRCode,
  PixMyLimits,
  PixTransfer,
  PixTransferKey,
  PixTransferBank,
  PixReceiveQR,
  Restrict
} from 'pages/Pix';

import { useAuth } from 'contexts/Auth';
import { ScreenSellProvider } from 'contexts/ScreenSell';
// import { ScreenDashboardProvider } from 'contexts/ScreenDashboard';
import { BankProvider } from 'contexts/Bank';
import { ReportProvider } from 'contexts/Report';
import { PixProvider } from 'contexts/Pix';
import { ReportListProvider } from 'contexts/Report/List';
import { PixRegisterProvider } from 'contexts/Pix/PixRegister';
import { PixOutFluxProvider } from 'contexts/Pix/PixOutFlux';
import { TeamProvider } from 'contexts/Team';
import { MemberProvider } from 'contexts/Team/Member';
import { MemberGroupProvider } from 'contexts/Team/MemberGroup';
import { CredentialsProvider } from 'contexts/Credentials';

import { PIX_OUT_KEYS_TYPES } from 'utils/pix';

import { ICardAuthenticationData } from 'services/orchestratorService/types';
import { getCardAuthentication } from 'services/orchestratorService';

import PATHS_ROUTES from 'routes/paths';
import MiniAppResolver from 'pages/MiniApp';

const Redirect = (params: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(params.to);
  }, [navigate, params.to]);
  return null;
};

const AppRoutes = () => {
  const { user, updateUser, permissions } = useAuth();
  const location = useLocation();
  const [orchestratorCardAuthentication, setOrchestratorCardAuthentication] =
    useState<ICardAuthenticationData>();

  const callCardAuthentication = useCallback(async () => {
    const request = await getCardAuthentication('PIX');
    setOrchestratorCardAuthentication(request);
  }, []);

  useEffect(() => {
    if (permissions?.includes('PIX')) {
      callCardAuthentication();
    }
  }, [permissions, callCardAuthentication]);

  const showUpdateUserModal = useMemo(
    () => updateUser && location.pathname !== PATHS_ROUTES.APP.UPDATE_USER,
    [location.pathname, updateUser]
  );

  return (
    <TemplateChallenge>
      {showUpdateUserModal && <ModalUpdateUser />}

      <Routes>
        <Route path={PATHS_ROUTES.APP.UPDATE_USER} element={<UpdateUser />} />
        <Route element={<TemplateApp />}>
          <Route path={PATHS_ROUTES.APP.WELCOME} element={<Welcome />} />
          {permissions?.includes('MINHA_CONTA') && (
            <>
              <Route
                path={PATHS_ROUTES.APP.ACCOUNT.PROFILE}
                element={<AccountProfile />}
              />
              {user?.userType === 'ADMIN' && (
                <>
                  <Route
                    path={PATHS_ROUTES.APP.ACCOUNT.PLAN_DATA}
                    element={<AccountPlanData />}
                  />
                  {permissions?.includes('MINHA_CONTA_EDITAR') && (
                    <Route
                      path={PATHS_ROUTES.APP.ACCOUNT.EDIT_INSTALLMENTS}
                      element={<AccountEditInstallments />}
                    />
                  )}
                </>
              )}
              <Route
                path={PATHS_ROUTES.APP.ACCOUNT.UPDATE_PASSWORD}
                element={<AccountUpdatePassword />}
              />
              <Route
                path={PATHS_ROUTES.APP.ACCOUNT.UPDATE_NAME}
                element={<AccountUpdateName />}
              />
              <Route
                path={PATHS_ROUTES.APP.ACCOUNT.UPDATE_BIRTHDAY}
                element={<AccountUpdateBirthday />}
              />
              <Route
                path={PATHS_ROUTES.APP.ACCOUNT.UPDATE_PHONE}
                element={<AccountUpdatePhone />}
              />
              {/* <Route
                path={PATHS_ROUTES.APP.ACCOUNT.EDIT_PHOTO}
                element={<AccountEditPhoto />}
              />
              <Route
                path={PATHS_ROUTES.APP.ACCOUNT.PRIVACY}
                element={<AccountPrivacy />}
              /> */}
              {/* <Route
                path={PATHS_ROUTES.APP.ACCOUNT.UPLOAD_DOCUMENTS}
                element={<AccountDocuments />}
              />
              <Route
                path={PATHS_ROUTES.APP.ACCOUNTS.GROUP}
                element={<AccountsGroups />}
              />
              <Route
                path={PATHS_ROUTES.APP.ACCOUNTS.GROUP_EDIT()}
                element={<AccountsGroupDependents />}
              />
              <Route
                path={PATHS_ROUTES.APP.ACCOUNTS.DEFAULT}
                element={<AccountsMyAccounts />}
              />

              <Route
                path={PATHS_ROUTES.APP.ACCOUNTS.ACCOUNTS}
                element={<AccountsAffiliates />}
              />
              <Route
                path={PATHS_ROUTES.APP.ACCOUNTS.EDIT()}
                element={<AccountsAffiliate />}
              /> */}
            </>
          )}

          {/* PRIVACY */}
          {/* <Route
            path={PATHS_ROUTES.APP.PRIVACY.DEFAULT}
            element={<Options />}
          />

          <Route
            path={PATHS_ROUTES.APP.PRIVACY.ACTION.CANCEL_ACCOUNT}
            element={
              <ScreenDashboardProvider>
                <CancelAccount />
              </ScreenDashboardProvider>
            }
          /> */}

          {/* TEAMS */}
          {/* <Route
            path={PATHS_ROUTES.APP.TEAM.DEFAULT}
            element={
              <TeamProvider>
                <Team />
              </TeamProvider>
            }
          />
          <Route path={PATHS_ROUTES.APP.TEAM.TEAMS} element={<Teams />} />
          <Route
            path={PATHS_ROUTES.APP.TEAM.ADD.DEFAULT}
            element={<TeamAdd />}
          />
          <Route
            path={PATHS_ROUTES.APP.TEAM.ADD.GROUP}
            element={<TeamAddGroup />}
          />
          <Route
            path={PATHS_ROUTES.APP.TEAM.LEADERSHIP()}
            element={
              <MemberProvider>
                <MemberGroupProvider>
                  <Leadership />
                </MemberGroupProvider>
              </MemberProvider>
            }
          /> */}
          {permissions?.includes('MEMBROS') && (
            <>
              <Route
                path={PATHS_ROUTES.APP.TEAM.MEMBER()}
                element={
                  <TeamProvider>
                    <MemberProvider>
                      <MemberGroupProvider>
                        <TeamMember />
                      </MemberGroupProvider>
                    </MemberProvider>
                  </TeamProvider>
                }
              />
              <Route
                path={PATHS_ROUTES.APP.TEAM.MEMBERS}
                element={
                  <TeamProvider>
                    <TeamMembers />
                  </TeamProvider>
                }
              />
              {/* <Route
                path={PATHS_ROUTES.APP.TEAM.SUBMEMBERS()}
                element={<TeamSubMembers />}
              /> */}
              {/* <Route
                path={PATHS_ROUTES.APP.TEAM.TEAM_MEMBERS()}
                element={<TeamAllMembers />}
              /> */}
              {permissions?.includes('MEMBROS_EDITAR') && (
                <>
                  <Route
                    path={PATHS_ROUTES.APP.TEAM.ADD.MEMBER}
                    element={<TeamAddMember />}
                  />
                  {/* <Route
                    path={PATHS_ROUTES.APP.TEAM.EDIT_PHOTO()}
                    element={<AccountEditPhoto />}
                  /> */}
                  <Route
                    path={PATHS_ROUTES.APP.TEAM.PERMISSIONS()}
                    element={
                      <MemberProvider>
                        <TeamMemberPermission />
                      </MemberProvider>
                    }
                  />
                  <Route
                    path={PATHS_ROUTES.APP.TEAM.RESET_PASSWORD()}
                    element={<TeamMemberResetPassword />}
                  />
                </>
              )}
            </>
          )}

          {/* DASHBOARD  */}
          {permissions?.includes('RESUMO') && (
            <>
              {permissions?.includes('RESUMO_ANTECIPAR') && (
                <Route
                  path={PATHS_ROUTES.APP.DASHBOARD.ANTICIPATE_FULL}
                  element={<AnticipateFull />}
                />
              )}
              <Route
                path={PATHS_ROUTES.APP.DASHBOARD.DEFAULT}
                element={<Dashboard />}
              />
            </>
          )}

          {/* Report  */}
          {permissions?.includes('RELATORIOS') && (
            <>
              <Route
                path={PATHS_ROUTES.APP.REPORT.DEFAULT}
                element={
                  <ReportListProvider>
                    <ReportList />
                  </ReportListProvider>
                }
              />

              <Route
                path={PATHS_ROUTES.APP.REPORT.CREATE}
                element={
                  <ReportProvider>
                    <ReportCreate />
                  </ReportProvider>
                }
              />
            </>
          )}

          {/* BANK */}
          <Route
            path={PATHS_ROUTES.APP.BANK.LIST}
            element={
              <BankProvider>
                <BankList />
              </BankProvider>
            }
          />

          <Route
            path={PATHS_ROUTES.APP.BANK.WITHDRAW}
            element={
              <BankProvider>
                <Withdraw />
              </BankProvider>
            }
          />

          <Route
            path={PATHS_ROUTES.APP.BANK.SELECT}
            element={
              <BankProvider>
                <BankSelect />
              </BankProvider>
            }
          />

          <Route
            path={PATHS_ROUTES.APP.BANK.ACCOUNT_REGISTRATION}
            element={
              <BankProvider>
                <AccountRegistration />
              </BankProvider>
            }
          />

          <Route
            path={PATHS_ROUTES.APP.BANK.ACCOUNT_EDIT}
            element={
              <BankProvider>
                <AccountRegistration edit />
              </BankProvider>
            }
          />

          {/* INTEGRATION  */}
          {permissions?.includes('INTEGRACAO') && (
            <>
              <Route
                path={PATHS_ROUTES.APP.INTEGRATION.DEFAULT}
                element={<Integration />}
              />

              {/* <Route
              path={PATHS_ROUTES.APP.INTEGRATION.CREDENTIALS_LOT}
              element={<CrendentialsInLot />}
            /> */}

              <Route
                path={PATHS_ROUTES.APP.INTEGRATION.GENERATE_CREDENTIAL}
                element={
                  <CredentialsProvider>
                    <GenerateCredential />
                  </CredentialsProvider>
                }
              />
              <Route
                path={PATHS_ROUTES.APP.INTEGRATION.TUTORIAL}
                element={<IntegrationTutorial />}
              />
            </>
          )}

          {/* SELL  */}
          {permissions?.includes('VENDER') && (
            <Route
              path={PATHS_ROUTES.APP.SELL}
              element={
                <ScreenSellProvider>
                  <Sell />
                </ScreenSellProvider>
              }
            />
          )}

          {/* <Route path={PATHS_ROUTES.APP.PROMOTION} element={<Promotion />} /> */}

          {/* STATEMENTS */}
          {permissions?.includes('EXTRATO') && (
            <>
              <Route
                path={PATHS_ROUTES.APP.STATEMENT.LIST}
                element={<StatementList />}
              />
              <Route
                path={PATHS_ROUTES.APP.STATEMENT.DETAILS()}
                element={<StatementDetail />}
              />
              {permissions?.includes('EXTRATO_CANCELAR') && (
                <Route
                  path={PATHS_ROUTES.APP.STATEMENT.REFUND()}
                  element={<StatementRefund />}
                />
              )}
              <Route
                path={PATHS_ROUTES.APP.STATEMENT.ANTICIPATE()}
                element={<StatementAnticipate />}
              />
            </>
          )}

          {/* PIX */}
          {orchestratorCardAuthentication?.statusType === 'APPROVED' && (
            <>
              <Route
                path={PATHS_ROUTES.APP.PIX.DEFAULT}
                element={
                  <PixProvider>
                    <Pix />
                  </PixProvider>
                }
              />
              <Route
                path={PATHS_ROUTES.APP.PIX.AGENCYACCOUNT}
                element={
                  <PixProvider>
                    <AgencyAccount />
                  </PixProvider>
                }
              />
              <Route path={PATHS_ROUTES.APP.PIX.FAQ} element={<PixFaq />} />
              <Route path={PATHS_ROUTES.APP.PIX.TAX} element={<PixTax />} />
              {permissions?.includes('PIX_CHAVES') && (
                <>
                  <Route
                    path={PATHS_ROUTES.APP.PIX.KEYS.DEFAULT}
                    element={
                      <PixProvider>
                        <PixKeys />
                      </PixProvider>
                    }
                  />
                  <Route
                    path={PATHS_ROUTES.APP.PIX.KEYS.REGISTER}
                    element={
                      <PixProvider>
                        <PixRegisterKeys />
                      </PixProvider>
                    }
                  />
                  <Route
                    path={PATHS_ROUTES.APP.PIX.KEYS.RANDOM_KEY}
                    element={
                      <PixRegisterProvider>
                        <PixRegisterRandom />
                      </PixRegisterProvider>
                    }
                  />
                  <Route
                    path={PATHS_ROUTES.APP.PIX.KEYS.EMAIL_KEY}
                    element={
                      <PixRegisterProvider>
                        <PixRegisterEmail />
                      </PixRegisterProvider>
                    }
                  />
                  <Route
                    path={PATHS_ROUTES.APP.PIX.KEYS.PHONE_KEY}
                    element={
                      <PixRegisterProvider>
                        <PixRegisterPhone />
                      </PixRegisterProvider>
                    }
                  />
                  <Route
                    path={PATHS_ROUTES.APP.PIX.KEYS.REGISTER_CNPJ}
                    element={
                      <PixRegisterProvider>
                        <PixRegisterCNPJ />
                      </PixRegisterProvider>
                    }
                  />
                  <Route
                    path={PATHS_ROUTES.APP.PIX.KEYS.REQUESTS}
                    element={
                      <PixRegisterProvider>
                        <PixRequests />
                      </PixRegisterProvider>
                    }
                  />
                </>
              )}
              <Route
                path={PATHS_ROUTES.APP.PIX.MY_QRCODE}
                element={
                  <PixProvider>
                    <PixMyQRCode />
                  </PixProvider>
                }
              />
              {permissions?.includes('PIX_LIMITES') && (
                <Route
                  path={PATHS_ROUTES.APP.PIX.MY_LIMITS}
                  element={<PixMyLimits />}
                />
              )}
              {permissions?.includes('PIX_OUT') && (
                <>
                  <Route
                    path={PATHS_ROUTES.APP.PIX.TRANSFER.DEFAULT}
                    element={
                      <PixOutFluxProvider>
                        <PixTransfer />
                      </PixOutFluxProvider>
                    }
                  />

                  {/* <Route

              path={PATHS_ROUTES.APP.PIX.TRANSFER.KEY(PIX_OUT_KEYS_TYPES.FAVORITES)}
              component={PixTransferFavorites}
            /> */}
                  <Route
                    path={PATHS_ROUTES.APP.PIX.TRANSFER.KEY(
                      PIX_OUT_KEYS_TYPES.BANK
                    )}
                    element={
                      <PixOutFluxProvider>
                        <PixTransferBank />
                      </PixOutFluxProvider>
                    }
                  />
                  <Route
                    path={PATHS_ROUTES.APP.PIX.TRANSFER.KEY()}
                    element={
                      <PixOutFluxProvider>
                        <PixTransferKey />
                      </PixOutFluxProvider>
                    }
                  />
                </>
              )}
              <Route
                path={PATHS_ROUTES.APP.PIX.RECEIVE_QR}
                element={<PixReceiveQR />}
              />
            </>
          )}
          {orchestratorCardAuthentication?.statusType !== 'APPROVED' && (
            <Route path={PATHS_ROUTES.APP.PIX.DEFAULT} element={<Restrict />} />
          )}

          {/* Training */}
          <Route
            path={PATHS_ROUTES.APP.TRAINING.HOME}
            element={<TrainingPlayer />}
          />

          <Route
            path={PATHS_ROUTES.APP.TRAINING.LIST}
            element={<TrainingList />}
          />

          {/* MINI_APP */}
          {/* {permissions?.includes('APLICATIVOS_CONTROLE') && ( */}
          <>
            <Route
              path={PATHS_ROUTES.APP.MINI_APP.BASEPATH}
              element={<Redirect to={PATHS_ROUTES.APP.MINI_APP.DEFAULT} />}
            />
            <Route
              path={PATHS_ROUTES.APP.MINI_APP.PATTERN_SLUG}
              element={<MiniAppResolver />}
            />
            <Route
              path={PATHS_ROUTES.APP.MINI_APP.PATTERN_SLUG_CONTEXT}
              element={<MiniAppResolver />}
            />
          </>
          {/* )} */}

          {/* Training */}
          {permissions?.includes('TREINAMENTOS_PADRAO') && (
            <>
              <Route
                path={PATHS_ROUTES.APP.TRAINING.HOME}
                element={<TrainingPlayer />}
              />

              <Route
                path={PATHS_ROUTES.APP.TRAINING.LIST}
                element={<TrainingList />}
              />
            </>
          )}

          {/* REDIRECT UNMAPPED ROUTES */}
          {permissions?.includes('RESUMO') ? (
            <Route
              path="*"
              element={
                <Navigate to={PATHS_ROUTES.APP.DASHBOARD.DEFAULT} replace />
              }
            />
          ) : (
            <Route
              path="*"
              element={<Navigate to={PATHS_ROUTES.APP.WELCOME} replace />}
            />
          )}
        </Route>
      </Routes>
    </TemplateChallenge>
  );
};

export default AppRoutes;
