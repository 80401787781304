import { ReactNode } from 'react';

import Text from 'components/Text';

import { ReactComponent as IconWarning } from 'assets/images/icon-alert.svg';

import * as S from './styles';

export type WarningBoxProps = {
  title?: string;
  block?: boolean;
  children?: ReactNode;
};

const WarningBox = ({ title, block = false, children }: WarningBoxProps) => (
  <S.Wrapper block={block}>
    <S.TitleWrapper>
      <IconWarning />
      <Text size="small" weight="medium">
        {title}
      </Text>
    </S.TitleWrapper>
    <S.Content>{children}</S.Content>
  </S.Wrapper>
);

export default WarningBox;
