import { Text } from 'components';

import { ReactComponent as Image } from 'assets/images/pix/onboarding-2.svg';

import * as S from './styles';
import Margin from 'styles/shared/Margin';

const Welcome = () => {
  return (
    <S.CardWrapper>
      <Margin my="large">
        <Text weight="medium" color="grayIcon" size="xlarge" align="center">
          Bem-vindo(a) à <b>Ame Empresas</b>! :)
        </Text>
      </Margin>
      <Margin my="large">
        <S.WrapperImage role="figure">
          <Image />
        </S.WrapperImage>
      </Margin>
    </S.CardWrapper>
  );
};

export default Welcome;
