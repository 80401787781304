import styled, { css } from 'styled-components';

export const List = styled.ul`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.large};
    margin-top: ${theme.spacings.large};
    list-style: none;

    li:not(:last-child) {
      margin-bottom: ${theme.spacings.medium};
    }
  `}
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    color: ${theme.colors.grayNeutralDark};
    font-size: ${theme.font.sizes.medium};
  `}
`;
