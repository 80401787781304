import * as Yup from 'yup';

const isValidType = ({ type }: File) =>
  type.startsWith('image') || type === 'application/pdf';

const isValidFiles = (files: File | FileList) => {
  if (files instanceof File) {
    return isValidType(files);
  }

  return Array.from(files || []).every(isValidType);
};

export type Field = {
  name: string;
  type: 'text' | 'file';
  validations: {
    required: string;
    isValidFiles?: string;
  };
};

function getValidation(field: Field) {
  if (field.type === 'file') {
    return Yup.mixed()
      .required(field.validations.required)
      .test('isValidFiles', field.validations.isValidFiles!, isValidFiles);
  }

  return Yup.string().required(field.validations.required);
}

export function getSchema(fields?: Field[]) {
  return fields
    ?.map(field => ({ [field.name]: getValidation(field) }))
    .reduce((schema, validation) => schema.shape(validation), Yup.object());
}
