import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.grayDark};
    font-size: ${theme.font.sizes.xsmall};

    svg {
      margin-right: ${theme.spacings.small};
    }
  `}
`;
