import * as React from 'react';
import Link from 'components/Link';
import Button, { ButtonProps } from 'components/Button';

export type ModalActionProps = {
  to?: string;
  text?: string;
  asLink?: boolean;
  onClick?: React.MouseEventHandler;
} & Pick<ButtonProps, 'filled' | 'tertiary'>;

const ModalAction = ({
  to,
  onClick,
  text = 'OK',
  filled = true,
  asLink = false,
  tertiary = false
}: ModalActionProps) => (
  <Button
    center
    filled={filled}
    tertiary={tertiary}
    {...(asLink ? { to, as: Link } : { onClick })}
  >
    {text}
  </Button>
);

export default ModalAction;
