import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

export type LinkProps = {
  replace?: boolean;
  children?: React.ReactNode;
  id?: string;
} & Pick<RouterLinkProps, 'to' | 'onClick' | 'className' | 'target'>;

const Link = ({
  to,
  replace,
  children,
  id,
  className,
  target,
  onClick
}: LinkProps) => (
  <RouterLink
    to={to}
    replace={replace}
    id={id}
    className={className}
    onClick={onClick}
    target={target}
    data-testid="ame-link"
  >
    {children}
  </RouterLink>
);

export default Link;
