import { useNavigate } from 'react-router-dom';

import PATHS_ROUTES from 'routes/paths';

import { Button, Loading, Text } from 'components';
import { ModalStepArrow } from 'components/Modals/';
import { useCallback, useState } from 'react';
import Divider from 'styles/shared/Divider';
import { modalItem } from './constants';

import useRequest from 'hooks/useRequest';
import useMutate from 'hooks/useMutate';

import * as S from './styles';
import endpoints from 'services/endpoints';
import { ISettingsBodyAndResponse } from 'services/pixService/types';
import { getPixSettings, setPixSettings } from 'utils/storage';
import { useAuth } from 'contexts/Auth';

function usePixSettings() {
  const { user } = useAuth();
  const { mutate } = useMutate();

  const { data, loading, error } = useRequest<ISettingsBodyAndResponse>({
    url: endpoints.settings,
    options: { revalidateOnFocus: false }
  });

  const saveSettings = useCallback(() => {
    const settings = {
      pix: {
        brCode: true,
        tutorial: true
      }
    };

    mutate({
      endpoint: endpoints.settings,
      body: settings
    });

    user && setPixSettings(user?.id, settings);
  }, [mutate, user]);

  if (error && user) {
    const settings = getPixSettings(user.id);

    return {
      data: settings,
      saveSettings
    };
  }

  return {
    loading,
    data,
    saveSettings
  };
}

const FirstAccess = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [modal, setModal] = useState(true);

  const Icon = modalItem[step].image;

  const { data, loading, saveSettings } = usePixSettings();

  if (loading) {
    return <Loading />;
  }

  if (data?.pix.tutorial || !modal) {
    return null;
  }

  const handleSaveSettings = () => {
    saveSettings();
    setModal(false);
  };

  const handleClickButton = () => {
    handleSaveSettings();
    navigate(PATHS_ROUTES.APP.PIX.KEYS.DEFAULT);
  };

  return (
    <ModalStepArrow
      itemsLength={modalItem.length}
      onClose={handleSaveSettings}
      changeStep={setStep}
      title="Pix na Ame"
    >
      <S.Info>
        <Icon data-testid={`image-${step}`} />
        <Text size="large" tag="h2" mb="small" weight="bold">
          <span
            dangerouslySetInnerHTML={{
              __html: modalItem[step].title
            }}
          />
        </Text>

        {modalItem[step].subtitle && (
          <Text size="medium" tag="h3" mb="small" weight="bold" align="center">
            <span
              dangerouslySetInnerHTML={{
                __html: modalItem[step].subtitle || ''
              }}
            />
          </Text>
        )}

        {modalItem[step].description.map(description => (
          <Text
            key={description}
            tag="p"
            size="small"
            color="grayNeutralDark"
            align="center"
            mb="small"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: description
              }}
            />
          </Text>
        ))}
      </S.Info>

      <Divider />
      {step === modalItem.length - 1 && (
        <Button filled onClick={handleClickButton}>
          Cadastrar chave Pix
        </Button>
      )}

      <Divider />
    </ModalStepArrow>
  );
};

export default FirstAccess;
