import { ReactNode } from 'react';

import { Text, InitialName } from 'components';

import * as S from './styles';

import { maskDocument } from 'utils/masks';

export type CardTeamMemberProps = {
  name: string;
  userType: string;
  document: string;
  selected?: boolean;
  children?: ReactNode;
};

export const CardTeamMember = ({
  name,
  document,
  selected = false,
  children
}: CardTeamMemberProps) => {
  return (
    <S.Wrapper selected={selected}>
      <InitialName name={name} size="large" fullWidth variant="square" />

      <S.ContentWrapper>
        <Text tag="p" color="blackDark" size="xsmall" weight="medium">
          {name}
        </Text>
        <Text tag="span" color="grayDark" size="xsmall">
          {maskDocument(document, 'CPF')}
        </Text>

        {children}
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default CardTeamMember;
