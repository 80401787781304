import { useState, useRef, ReactNode } from 'react';

import { Text } from 'components';
import * as S from './styles';

export type AccordionProps = {
  title: string;
  children?: ReactNode;
};

const Accordion = ({ title, children }: AccordionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showContent, setShowContent] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  return (
    <S.Wrapper>
      <S.Title
        onClick={() => {
          if (ref.current) setContentHeight(ref.current.offsetHeight);
          setShowContent(!showContent);
        }}
      >
        <Text
          weight="bold"
          size="xmedium"
          color={showContent ? 'blue' : 'grayNeutralBrandDarkest'}
        >
          {title}
        </Text>

        <S.Arrow showContent={showContent} />
      </S.Title>
      {!showContent && <S.Divider mt="small" mb="medium" />}
      <S.Content showContent={showContent} contentHeight={contentHeight}>
        <div ref={ref}>{children}</div>
      </S.Content>
    </S.Wrapper>
  );
};

export default Accordion;
