import { useCallback, useMemo } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { endpoints } from 'services/sessionService';

import {
  Loading,
  Button,
  InputField,
  Text,
  TemplateClean,
  TemplateCloseContent,
  ModalStatus,
  Warning
} from 'components';

import { Row, Column } from 'styles/shared/Grid';
import { Divider } from 'styles/shared/Divider';

import PATHS_ROUTES from 'routes/paths';
import useMutate from 'hooks/useMutate';

import { validationSchema } from './validationSchema';

interface Texts {
  title: string;
  subtitle?: string;
  password: string;
  confirmPassword: string;
  action: string;
  modal: {
    title: string;
    description: string;
    content: string;
  };
}

interface Props {
  firstPassword?: boolean;
}

const texts = {
  default: {
    title: 'Escolha sua nova senha :)',
    password: 'Digite sua nova senha',
    confirmPassword: 'Confirme sua nova senha',
    action: 'Alterar senha',
    modal: {
      title: 'Redefinição de senha',
      description:
        'Agora é só entrar na sua conta Ame Plus com a nova senha que você criou, beleza?',
      content: 'Eba, senha alterada! ;)'
    }
  },
  firstPassword: {
    title: 'Que bom ter você aqui :)',
    subtitle: 'Crie uma senha para ter acesso à sua conta',
    password: 'Digite sua senha',
    confirmPassword: 'Confirme sua senha',
    action: 'Criar senha',
    modal: {
      title: 'Definição de senha',
      description:
        'Agora é só entrar na sua conta Ame Plus com a senha que você criou, beleza?',
      content: 'Eba, senha cadastrada! ;)'
    }
  }
};

interface NewPasswordFormValues {
  password: string;
  passwordConfirmation: string;
}

export const initialValues: NewPasswordFormValues = {
  password: '',
  passwordConfirmation: ''
};

function NewPassword({ firstPassword }: Props) {
  const { search } = useLocation();

  const { state, mutate } = useMutate();

  const { loading, status, error } = state;

  const token = useMemo(() => {
    const params = new URLSearchParams(search);

    return params.get('token');
  }, [search]);

  const labels: Texts = firstPassword ? texts.firstPassword : texts.default;

  const handleSubmit = useCallback(
    ({
      password: newPassword,
      passwordConfirmation: newPasswordCheck
    }: NewPasswordFormValues) => {
      mutate({
        endpoint: endpoints.newPassword,
        method: 'post',
        body: {
          newPassword,
          newPasswordCheck
        },
        config: {
          params: { token }
        }
      });
    },
    [mutate, token]
  );

  if (!token) {
    return <Navigate to={PATHS_ROUTES.AUTH.LOGIN} replace />;
  }

  return (
    <>
      {loading && <Loading fullContent fullWidth />}
      <TemplateClean>
        <TemplateCloseContent
          closeLink={PATHS_ROUTES.AUTH.LOGIN}
          closeTitle="Voltar"
        >
          <Text tag="h1" size="xxmedium" weight="medium">
            {labels.title}
          </Text>

          {labels.subtitle && (
            <Text tag="h2" size="xxmedium">
              {labels.subtitle}
            </Text>
          )}

          <Divider />

          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ values, dirty, handleChange }) => (
              <Form>
                <InputField
                  name="password"
                  type="password"
                  placeholder={labels.password}
                  onChange={handleChange}
                  value={values.password}
                />

                <Divider size="small" />

                <InputField
                  name="passwordConfirmation"
                  type="password"
                  placeholder={labels.confirmPassword}
                  onChange={handleChange}
                  value={values.passwordConfirmation}
                />

                <Divider size="large" />

                {!!error && (
                  <Text color="red" size="xsmall" mb="medium">
                    {status === 401
                      ? 'Ocorreu um erro ao tentar atualizar a senha'
                      : error}
                  </Text>
                )}

                <Warning>
                  Sua senha deve ter números, letras e no <br /> mínimo 8
                  caracteres.
                </Warning>

                <Divider size="xlarge" />

                <Row justifyContentXs="center">
                  <Column xs={8}>
                    <Button
                      type="submit"
                      disabled={!dirty || loading}
                      fullWidth
                      filled
                    >
                      {labels.action}
                    </Button>
                  </Column>
                </Row>
              </Form>
            )}
          </Formik>
        </TemplateCloseContent>

        {status === 200 && (
          <ModalStatus
            variant="success"
            title={labels.modal.title}
            description={labels.modal.description}
            action={{
              asLink: true,
              filled: false,
              tertiary: true,
              to: PATHS_ROUTES.AUTH.LOGIN,
              text: 'Entrar no Ame Sua Conta'
            }}
          >
            <Text size="large" weight="bold" align="center">
              {labels.modal.content}
            </Text>
          </ModalStatus>
        )}
      </TemplateClean>
    </>
  );
}

export default NewPassword;
