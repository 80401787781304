import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: ${theme.spacings.large};
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    flex: 1;
    padding-top: ${theme.spacings.xsmall};

    h4 {
      margin-bottom: -${theme.spacings.xsmall};
    }

    ${customMedia.greaterThan('large')`
      margin-left: ${theme.spacings.large};
    `}
  `}
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;

  button {
    height: initial;
  }
`;
