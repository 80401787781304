import Image1 from '../assets/wbuy1.png';
import Image2 from '../assets/wbuy2.png';
import { TutorialRequestKey } from '../components';
import * as S from '../../styles';
function Wbuy() {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Wbuy
      </S.Title>

      <TutorialRequestKey name="Wbuy" />

      <S.Paragraph tag="p" color="grayBlue">
        Na sua conta Wbuy, entre no menu lateral, acesse
        <strong> {'Configurações > Configurações de recebimentos'}</strong>
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image1}
          alt="Menu lateral destacando Configurações e Configurações de recebimentos"
        />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Na página de configurações de recebimentos encontre a
        <strong> Ame Digital</strong>, e em seguida, clique em
        <strong> Configurar.</strong> Caso a Ame não esteja aparecendo nas
        configurações de recebimento, poderá instalar o aplicativo na sua
        plataforma Wbuy através do link{' '}
        <S.Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.wbuy.com.br/aplicativos/ame"
        >
          Instale Ame na sua loja virtual - wBuy Plataforma de e-commerce
        </S.Link>
      </S.Paragraph>

      <S.ImgWrapper>
        <img src={Image2} alt="Seção ame digital com configurar" />
      </S.ImgWrapper>

      <S.Paragraph tag="p" color="grayBlue">
        Agora basta preencher o campo com a chave de integração que recebeu e
        clicar em <strong>Gravar</strong>.
      </S.Paragraph>
    </div>
  );
}

export default Wbuy;
