export default {
  APP: {
    MINI_APP: {
      BASEPATH: '/app',
      DEFAULT: '/app/ame-empresas-app-store',
      PATTERN_SLUG: '/app/:slug',
      PATTERN_SLUG_CONTEXT: '/app/:slug/:context',
      ACTIVATION_PATH: '/app/'
    },
    ACCOUNT: {
      PROFILE: '/minha-conta',
      EDIT_PHOTO: '/minha-conta/editar-foto',
      UPDATE_PASSWORD: '/minha-conta/editar-senha',
      UPDATE_NAME: '/minha-conta/editar-nome',
      UPDATE_BIRTHDAY: '/minha-conta/editar-data-nascimento',
      UPDATE_PHONE: '/minha-conta/editar-telefone',
      UPLOAD_DOCUMENTS: '/minha-conta/documentos',
      PLAN_DATA: '/minha-conta/dados-do-plano',
      PRIVACY: '/minha-conta/privacidade',
      EDIT_INSTALLMENTS: '/minha-conta/editar-parcelamento'
    },
    ACCOUNTS: {
      DEFAULT: '/minhas-contas',
      EDIT: (id = ':id') => `/minhas-contas/${id}`,
      GROUP: '/minhas-contas/grupos',
      ACCOUNTS: '/minhas-contas/contas',
      GROUP_EDIT: (id = ':id') => `/minhas-contas/grupos/${id}`
    },
    TEAM: {
      DEFAULT: '/equipe',
      TEAMS: '/equipe/times',
      MEMBERS: '/equipe/membros',
      TEAM_MEMBERS: (teamId = ':teamId') => `/equipe/time/${teamId}/membros`,
      MEMBER: (id = ':id') => `/equipe/membro/${id}`,
      EDIT_PHOTO: (id = ':id') => `/equipe/membro/${id}/editar-foto`,
      LEADERSHIP: (id = ':id') => `/equipe/membro/${id}/leadership`,
      PERMISSIONS: (id = ':id') => `/equipe/membro/${id}/permissions`,
      RESET_PASSWORD: (id = ':id') => `/equipe/membro/${id}/reset-password`,
      SUBMEMBERS: (id = ':id') => `/equipe/membro/${id}/subordinados`,
      ADD: {
        DEFAULT: '/equipe/adicionar',
        MEMBER: '/equipe/adicionar/membro',
        GROUP: '/equipe/adicionar/grupo'
      }
    },
    WELCOME: '/welcome',
    DASHBOARD: {
      DEFAULT: '/resumo',
      ANTICIPATE_FULL: `/resumo/antecipar/total`
    },
    REPORT: {
      DEFAULT: '/relatorio',
      CREATE: '/relatorio/criar'
    },
    BANK: {
      LIST: '/banco',
      WITHDRAW: '/banco/retirar',
      SELECT: '/banco/selecionar',
      ACCOUNT_REGISTRATION: '/banco/cadastro-de-contas',
      ACCOUNT_EDIT: '/banco/atualizar-conta'
    },
    INTEGRATION: {
      DEFAULT: '/integracao',
      CREDENTIALS_LOT: '/integracao/credenciais-em-lote',
      GENERATE_CREDENTIAL: '/integracao/gerar-credencial',
      TUTORIAL: '/integracao/tutorial'
    },
    SELL: '/vender',
    STATEMENT: {
      LIST: '/extrato',
      DETAILS: (id = ':id') => `/extrato/${id}`,
      REFUND: (id = ':id') => `/extrato/${id}/cancelar`,
      ANTICIPATE: (id = ':id') => `/extrato/${id}/antecipar`
    },
    PROMOTION: '/promocoes',
    PIX: {
      DEFAULT: '/pix',
      KEYS: {
        DEFAULT: '/pix/chaves',
        REGISTER: '/pix/chaves/cadastrar',
        EMAIL_KEY: '/pix/chaves/cadastrar/email',
        PHONE_KEY: '/pix/chaves/cadastrar/telefone',
        REGISTER_CNPJ: '/pix/chaves/cadastrar/cnpj',
        RANDOM_KEY: '/pix/chaves/cadastrar/chave-aleatoria',
        REQUESTS: '/pix/chaves/portabilidade-e-reivindicacao'
      },
      AGENCYACCOUNT: '/pix/agencia-e-conta',
      FAQ: '/pix/faq',
      TAX: '/pix/tarifas',
      MY_QRCODE: '/pix/meu-qrcode',
      MY_LIMITS: '/pix/meus-limites',
      TRANSFER: {
        DEFAULT: '/pix/transferir',
        KEY: (keyType = ':keyType') => `/pix/transferir/${keyType}`
      },
      RECEIVE_QR: '/pix/receber/qr'
    },
    UPDATE_USER: '/atualizar-cadastro',
    TRAINING: {
      HOME: '/treinamento',
      LIST: '/treinamento/ver-todos'
    },
    PRIVACY: {
      DEFAULT: '/privacidade',
      ACTION: {
        PERMITIONS_NOTIFICATIONS: '/permicao-notificacao',
        PRIVACY_TERMS: '/politica-privacidade',
        CANCEL_ACCOUNT: '/encerrar-conta'
      }
    },
    SCHEDULES: {
      DEFAULT: '/agendamentos'
    },
    DEPOSIT: '/app/dados-de-deposito'
  },
  AUTH: {
    LOGIN: '/',
    VALIDATE: '/validar',
    FORGOT_PASSWORD: '/esqueceu-a-senha',
    NEW_PASSWORD: '/nova-senha',
    FIRST_PASSWORD: '/primeiro-acesso',
    DEEPLINK: '/corrigir-dados',
    ACCREDITATION: {
      DEFAULT: '/cadastro',
      PHYSICAL: '/cadastro/fisico',
      DIGITAL: '/cadastro/digital'
    }
  }
} as const;
