import { useCallback, useEffect, useMemo, useState } from 'react';
import useRequest from 'hooks/useRequest';
import queryString from 'query-string';
import endpoints from 'services/endpoints';
import useMutate from 'hooks/useMutate';

import { ModalSuccessType, PixLimits, PixLimitsTransactionType } from './types';
import { breadcrumbItems, messagesSuccess } from './constant';

import {
  Breadcrumb,
  ModalChange,
  ModalStatus,
  SegmentControl,
  Text
} from 'components';
import Divider from 'styles/shared/Divider';
import SameOwnership from './SameOwnership';
import OtherOwnership from './OtherOwnership';
import AmeAccount from './AmeAccount';
import Overnight from './Overnight';
import { Item as AccountTypes } from 'components/SegmentControl';
import FirstAccess from './components/FirstAccess';

import * as S from './styles';

const PixMyLimits = () => {
  const {
    mutate,
    state: { loading: loadingUpdatePix }
  } = useMutate();

  const [selectedItem, setSelectedItem] = useState<PixLimits | null>(null);
  const [openModalAccess, setOpenModalAccess] = useState<boolean>(true);
  const [modalSuccess, setModalSuccess] = useState<ModalSuccessType>({
    active: false,
    message: null
  });
  const [dataChangedAlert, setDataChangedAlert] = useState({
    active: false,
    type: ''
  });

  const { data: dataLimits, revalidate } = useRequest<PixLimits[]>({
    url: queryString.stringifyUrl({
      url: endpoints.pixLimits
    })
  });

  const changeSelectedItem = useCallback(
    (value: PixLimitsTransactionType) => {
      const currentItem = dataLimits?.find(x => {
        return x.transactionType === selectedItem?.transactionType;
      });

      if (
        currentItem?.monthly === selectedItem?.monthly ||
        currentItem?.increaseRequest?.monthlyLimit === selectedItem?.monthly
      ) {
        setSelectedItem(
          dataLimits?.find(x => {
            return x.transactionType === value;
          }) || null
        );
      } else {
        setDataChangedAlert({
          active: true,
          type: value
        });
      }
    },
    [dataLimits, selectedItem]
  );

  const updatePixLimit = useCallback(() => {
    mutate({
      method: 'put',
      endpoint: endpoints.pixLimits,
      body: {
        requestedLimit: selectedItem?.monthly,
        transactionType: selectedItem?.transactionType
      },
      onSuccess: () => {
        setDataChangedAlert({
          active: false,
          type: ''
        });

        setModalSuccess({
          active: true,
          message: messagesSuccess[selectedItem?.transactionType || 'CASHOUT']
        });

        revalidate();
      }
    });
  }, [mutate, revalidate, selectedItem]);

  const accountTypes = useMemo<AccountTypes[]>(
    () => [
      {
        label: 'Mesmo CNPJ',
        active: selectedItem?.transactionType === 'CASHOUT',
        action: () => changeSelectedItem('CASHOUT')
      },
      {
        label: 'Outro CNPJ',
        active: selectedItem?.transactionType === 'TRANSFER',
        action: () => changeSelectedItem('TRANSFER')
      },
      {
        label: 'Conta Ame',
        active: selectedItem?.transactionType === 'PURCHASE',
        action: () => changeSelectedItem('PURCHASE')
      },
      {
        label: 'Noturno',
        active: selectedItem?.transactionType === 'NIGHT_PERIOD',
        action: () => changeSelectedItem('NIGHT_PERIOD')
      }
    ],
    [changeSelectedItem, selectedItem]
  );

  useEffect(() => {
    if (dataLimits?.length && !selectedItem) setSelectedItem(dataLimits[0]);
  }, [dataLimits, selectedItem]);

  const ignoreChanges = () => {
    setSelectedItem(
      dataLimits?.find(x => {
        return x.transactionType === dataChangedAlert.type;
      }) || null
    );
    setDataChangedAlert({
      active: false,
      type: ''
    });
  };

  return (
    <>
      {openModalAccess && (
        <FirstAccess handleClose={() => setOpenModalAccess(false)} />
      )}

      <Breadcrumb links={breadcrumbItems} />

      <Divider size="xlarge" />

      <S.Wrapper mx="xlarge" direction="column">
        <Text tag="h2" size="xxmedium" weight="medium" color="grayBlue">
          Meus limites Pix
        </Text>

        <Text tag="p" size="medium" color="grayNeutralDark" mb="small">
          Selecione o tipo de conta e altere seus limites
        </Text>

        <SegmentControl small items={accountTypes} />

        <S.Wrapper mx="xlarge">
          {selectedItem?.transactionType === 'CASHOUT' && (
            <SameOwnership
              data={selectedItem}
              changePixLimits={value => setSelectedItem(value)}
              updatePixLimit={() => updatePixLimit()}
              loading={loadingUpdatePix}
            />
          )}

          {selectedItem?.transactionType === 'TRANSFER' && (
            <OtherOwnership
              data={selectedItem}
              changePixLimits={value => setSelectedItem(value)}
              updatePixLimit={() => updatePixLimit()}
              loading={loadingUpdatePix}
            />
          )}

          {selectedItem?.transactionType === 'PURCHASE' && (
            <AmeAccount
              data={selectedItem}
              changePixLimits={value => setSelectedItem(value)}
              updatePixLimit={() => updatePixLimit()}
              loading={loadingUpdatePix}
            />
          )}

          {selectedItem?.transactionType === 'NIGHT_PERIOD' && (
            <Overnight
              data={selectedItem}
              changePixLimits={value => setSelectedItem(value)}
              updatePixLimit={() => updatePixLimit()}
              loading={loadingUpdatePix}
            />
          )}
        </S.Wrapper>
      </S.Wrapper>

      {dataChangedAlert.active && (
        <ModalChange
          title="Aplicar alteração?"
          buttonText="Aplicar"
          cancelText="Ignorar"
          onClose={() => ignoreChanges()}
          cancelAction={() => ignoreChanges()}
          buttonAction={() => updatePixLimit()}
        >
          <Text size="medium">
            Quer aplicar a alteração antes de ir para outra seção?
          </Text>
        </ModalChange>
      )}

      {modalSuccess.active && (
        <ModalStatus
          variant="success"
          title={'Meus limites Pix'}
          description={modalSuccess.message}
          onClose={() =>
            setModalSuccess({
              active: false,
              message: ''
            })
          }
          action={{
            text: 'Ok',
            onClick: () =>
              setModalSuccess({
                active: false,
                message: ''
              })
          }}
        >
          <Text size="large" weight="bold" align="center">
            {'Limite alterado'}

            <Text color="blue" size="large" weight="bold" tag="span" mx="small">
              :)
            </Text>
          </Text>
        </ModalStatus>
      )}
    </>
  );
};

export default PixMyLimits;
