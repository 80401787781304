import { InputHTMLAttributes } from 'react';

import { ReactComponent as IconDefault } from 'assets/images/icon-search.svg';

import * as S from './styles';

export type InputSearchProps = {
  IconComponent?: JSX.Element;
} & InputHTMLAttributes<HTMLInputElement>;

const InputSearch = ({
  IconComponent = <IconDefault />,
  ...rest
}: InputSearchProps) => {
  return (
    <S.Wrapper>
      {IconComponent}
      <S.Input autoComplete="off" {...rest} />
    </S.Wrapper>
  );
};

export default InputSearch;
