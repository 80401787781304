import PATHS_ROUTES from 'routes/paths';
import { Row, Column } from 'styles/shared/Grid';
import { Text, Button, CardAccount, CardGroup, Flex, Link } from 'components';
import { ReactComponent as IconAdd } from 'assets/images/icon-add-outline.svg';

import accounts, { groups } from './__mocks__/accounts';
import * as S from './styles';

function Accounts() {
  return (
    <div>
      <Flex justifyContent="space-between" alignItems="center" mb="small">
        <Text tag="h2" size="xxmedium" weight="medium">
          Minhas contas
        </Text>

        <Button
          as={Link}
          secondary
          noBorder
          icon={<IconAdd />}
          to={PATHS_ROUTES.APP.ACCOUNTS.GROUP}
        >
          Novo Grupo
        </Button>
      </Flex>

      <Flex mb="small" direction="column">
        <Text tag="h3" size="xmedium" weight="medium">
          Grupos
        </Text>

        <Text tag="h4" size="small" color="grayDark" weight="regular">
          Esses são todos os seus grupos de lojas
        </Text>
      </Flex>

      <Row>
        {groups.map(group => (
          <Column key={group.id} lg={4}>
            <CardGroup {...group} onSeeMoreClick={() => {}} />
          </Column>
        ))}
      </Row>

      <Flex justifyContent="center" mb="small">
        <Button
          as={Link}
          secondary
          noBorder
          to={PATHS_ROUTES.APP.ACCOUNTS.GROUP}
        >
          Ver todos os grupos
        </Button>
      </Flex>

      <Flex mb="small" direction="column">
        <Text tag="h3" size="xmedium" weight="medium">
          Contas
        </Text>

        <Text tag="h4" size="small" color="grayDark" weight="regular">
          Essas são as demais contas subordinadas à{' '}
          <Text tag="strong" size="small" color="grayDark" weight="medium">
            BR Principal
          </Text>
        </Text>
      </Flex>

      <Row>
        {accounts.map(account => (
          <Column key={account.id} lg={4}>
            <CardAccount hidePicture {...account}>
              <Link to={PATHS_ROUTES.APP.ACCOUNTS.EDIT(account.id)}>
                <S.Arrow />
              </Link>
            </CardAccount>
          </Column>
        ))}
      </Row>

      <Flex justifyContent="center">
        <Button
          as={Link}
          secondary
          noBorder
          to={PATHS_ROUTES.APP.ACCOUNTS.ACCOUNTS}
        >
          Ver todas as contas
        </Button>
      </Flex>
    </div>
  );
}

export default Accounts;
