import { createPortal } from 'react-dom';
import * as S from './styles';
import { useAppLayout } from 'components/Templates/AppLayout';
import { useTheme } from 'styled-components';

export type LoadingProps = {
  color?: 'white' | 'blue';
  fullPage?: boolean;
  fullContent?: boolean;
  fullWidth?: boolean;
};

const getElement = (id: string): HTMLElement => {
  let element = document.getElementById(id);

  if (!element) {
    element = document.createElement('div');
    element.setAttribute('id', id);
    document.body.appendChild(element);
  }

  return element;
};

const Loading = ({
  color = 'blue',
  fullPage = false,
  fullContent = false,
  fullWidth = false
}: LoadingProps) => {
  const { layout } = useTheme();
  const { sidebarOpened } = useAppLayout();
  const navSize = sidebarOpened
    ? layout.sidebar.sizeOpened
    : layout.sidebar.sizeClosed;

  const content = (
    <S.Wrapper role="progressbar" color={color} fullPage={fullPage}>
      <S.Line />
      <S.Line />
      <S.Line />
    </S.Wrapper>
  );

  return fullContent
    ? createPortal(
        <S.WrapperPortal
          style={{
            '--nav-size': fullWidth ? '0px' : navSize
          }}
        >
          {content}
        </S.WrapperPortal>,
        getElement('loading')
      )
    : content;
};

export default Loading;
