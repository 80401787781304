import * as types from './types';
import endpoints from './endpoints.json';
import api from '../api';

export async function setOrders(body: types.ISetOrdersBody) {
  const url = endpoints.orders;

  const bodyStringify = JSON.stringify(body, (_key, val) =>
    val === '' ? 'Compra online' : val
  );

  const result = await api.post<types.ISetOrdersSuccess>(
    url,
    JSON.parse(bodyStringify)
  );

  return result.data;
}

export async function setOrdersNoValue(body: types.ISetOrdersBody) {
  const url = endpoints.ordersNoValue;

  const result = await api.post<types.ISetOrdersSuccess>(url, body);

  return result.data;
}
