import * as S from './styles';

export type ProfileTypeProps = {
  label: string;
  isSelected?: boolean;
  onSelect: () => void;
};

const ProfileType = ({
  label,
  isSelected = false,
  onSelect
}: ProfileTypeProps) => (
  <S.Wrapper isSelected={isSelected} data-testid="profile-type">
    <p>{label}</p>
    <S.Button type="button" onClick={onSelect}>
      {isSelected ? 'Selecionado' : 'Selecionar'}
    </S.Button>
  </S.Wrapper>
);

export default ProfileType;
