import { useState, useCallback, useEffect } from 'react';
import moment from 'moment';

import TemplateModal from 'components/Templates/Modal';
import CardSectionOptions from 'components/Cards/SectionOptions';
import Button from 'components/Button';
import { Input, DateRange } from 'components';

import { Categories, Filters, PeriodFilter } from 'utils/newStatementHelper';
import useDateRange from 'hooks/useDateRange';

import { filterItems, periodOptions } from '../../constants';
import * as S from './styles';

type FilterModalProps = {
  filters: Filters;
  onApplyFilters: (filters: Filters) => void;
  onCloseModal: () => void;
};
type FilterType = keyof Filters;

const FilterModal = ({
  filters,
  onApplyFilters,
  onCloseModal
}: FilterModalProps) => {
  const [dateModal, setDateModal] = useState(false);
  const [filtersSelected, setFiltersSelected] = useState<Filters>({
    ...filters
  });

  const { dates, resetDates, ...dateParams } = useDateRange({
    initialValues: {
      startDate: filters.periodDates?.startDate || null,
      endDate: filters.periodDates?.endDate || null,
      focusedInput: 'startDate'
    },
    rangeDays: 30
  });

  const handleItems = useCallback(
    (type: FilterType, value: string | Categories | PeriodFilter | null) => {
      setFiltersSelected(() => {
        if (
          type !== 'periodDates' &&
          Array.isArray(filtersSelected[type]) &&
          typeof value === 'string'
        ) {
          const isAdding = !filtersSelected[type].includes(value);

          if (isAdding) {
            return {
              ...filtersSelected,
              [type]: [...(filtersSelected[type] as Array<string>), value]
            } as Filters;
          }

          return {
            ...filtersSelected,
            [type]: (filtersSelected[type] as Array<string>).filter(
              filter => filter !== value
            )
          } as Filters;
        }

        return {
          ...filtersSelected,
          [type]: value
        } as Filters;
      });
    },
    [filtersSelected]
  );

  const handleChangePeriod = (options: string) => {
    let period: PeriodFilter | null;

    if (options === 'FILTRAR') {
      resetDates();
      setDateModal(true);
    } else {
      period = {
        startDate: moment().subtract(options, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      };
    }

    setFiltersSelected(prevState => ({
      ...prevState,
      period: options,
      periodDates: period
    }));
  };

  useEffect(() => {
    if (dateModal && dates.startDate !== '' && dates.endDate !== '') {
      handleItems('periodDates', {
        startDate: dates.startDate,
        endDate: dates.endDate
      });
      setDateModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateModal, dates]);

  const handleApplyFilters = () => {
    const aux = { ...filtersSelected };

    if (filtersSelected.period === '') {
      aux.periodDates = null;
    }

    onApplyFilters(aux);
  };

  return (
    <>
      <TemplateModal title="Filtrar" closeModal={onCloseModal}>
        <S.Content>
          <h5>Selecione os filtros que deseja aplicar</h5>

          <Input
            name="user"
            type="text"
            label="Busca por NSU"
            value={filtersSelected.nsu}
            onChange={e => handleItems('nsu', e.target.value)}
          />

          <S.Period>
            <CardSectionOptions
              key="period"
              title="Período"
              options={periodOptions}
              optionSelected={filtersSelected.period}
              onOptionSelected={option => handleChangePeriod(option)}
            />
            {filtersSelected.period === 'FILTRAR' && dateModal && (
              <S.Container>
                <S.ContainerArrow />
                <S.Wrapper>
                  <DateRange
                    staticParams={dateParams}
                    onOutsideClick={() => setDateModal(false)}
                    daySize={38}
                  />
                </S.Wrapper>
              </S.Container>
            )}
          </S.Period>

          {filterItems.map(item => {
            return (
              <CardSectionOptions
                key={item.type}
                title={item.text}
                options={item.cards}
                optionSelected={filtersSelected[item.type]}
                onOptionSelected={option => handleItems(item.type, option)}
              />
            );
          })}

          <Button fullWidth center onClick={handleApplyFilters}>
            Aplicar
          </Button>
        </S.Content>
      </TemplateModal>
    </>
  );
};

export default FilterModal;
