import { ReactNode } from 'react';

import { Text } from 'components';

import { ReactComponent as IconWarning } from 'assets/images/icon-alert.svg';

import * as S from './styles';

export type WarningProps = {
  children: string | ReactNode;
};

const Warning = ({ children }: WarningProps) => (
  <S.Wrapper>
    <IconWarning />

    <Text align="center" mt="small">
      {children}
    </Text>
  </S.Wrapper>
);

export default Warning;
