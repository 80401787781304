import { InputProps } from 'components/Input';

import { ThemeColors } from 'types/global';

import { maskOnlyNumbers } from 'utils/masks';

import * as S from './styles';

export type InputMoneyProps = {
  color?: ThemeColors;
  onChange: (value: string) => void;
} & Pick<InputProps, 'name' | 'label' | 'value' | 'errorMessage'>;

const InputMoney = ({
  color = 'blue',
  name,
  label,
  value,
  errorMessage,
  onChange
}: InputMoneyProps) => (
  <S.InputMoney
    {...{ color, name, label, value, errorMessage }}
    placeholder="R$ 0,00"
    onChange={e => onChange && onChange(maskOnlyNumbers(e.target.value))}
    maskType="CURRENCY"
    onlyNumbers
    noBorder
  />
);

export default InputMoney;
