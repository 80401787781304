import * as S from './styles';

export type ToggleProps = {
  isChecked?: boolean;
  onCheck: (isChecked: boolean) => void;
  disabled?: boolean;
};

const Toggle = ({
  isChecked = false,
  onCheck,
  disabled = false
}: ToggleProps) => (
  <S.Wrapper>
    <input
      type="checkbox"
      checked={isChecked}
      onChange={() => onCheck(!isChecked)}
      disabled={disabled}
    />
    <span aria-hidden="true" />
  </S.Wrapper>
);

export default Toggle;
