import { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useToggle from 'hooks/useToggle';
import PATHS_ROUTES from 'routes/paths';

import { Step1, Step2, Step3, Step4 } from './steps';
import ModalAddMember from './ModalAddMember';
import {
  TemplatePaginationStep,
  TemplateCloseContent,
  ModalStatus,
  Text
} from 'components';
import { Data } from './types';
import { registerMember } from 'services/memberService';
import useRequest from 'hooks/useRequest';
import endpoints from 'services/endpoints';
import { useAuth } from 'contexts/Auth';
import { GroupBeneath } from 'services/groupService';
import { invertDate } from 'utils/masks';

const TeamAddMember = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingAddMember, setLoadingAddMember] = useState(false);
  const modalAddMember = useToggle();
  const modalSuccess = useToggle();
  const modalError = useToggle();
  const navigate = useNavigate();

  const { user } = useAuth();
  const { data: groups } = useRequest<GroupBeneath[]>({
    url: user?.groupId
      ? endpoints.groupBeneath.replace(':id', user?.groupId)
      : null
  });

  const [data, setData] = useState<Data>({
    groupId: '',
    name: '',
    userType: 'VENDEDOR',
    profile: 'onboarder',
    birthday: '',
    documentRequest: {
      documentType: 'CPF',
      documentNumber: ''
    },
    emailRequest: {
      emailType: '',
      email: ''
    },
    phoneRequest: {
      phoneType: '',
      number: ''
    },
    addressRequest: {
      addressType: 'BILLING',
      alias: '',
      address: '',
      number: '',
      district: '',
      additionalInfo: '',
      city: '',
      zipCode: '',
      state: '',
      countryCode: 'BRA'
    },
    permissionIds: [],
    profileId: '',
    userTypeLabel: ''
  });

  const changeData = useCallback((values: Data) => {
    setData(prevValues => ({ ...prevValues, ...values }));
  }, []);

  const handleChangeInput = useCallback(
    (event: { target: HTMLInputElement }) =>
      setData(prevValues => ({
        ...prevValues,
        [event.target.name]: event.target.value
      })),
    []
  );

  const handleSubmit = async () => {
    setLoadingAddMember(true);
    try {
      await registerMember({
        ...data,
        birthday: invertDate(data.birthday)
      });

      modalAddMember.toggle();
      modalSuccess.toggle();

      navigate(PATHS_ROUTES.APP.TEAM.MEMBERS);
    } catch (error) {
      modalAddMember.toggle();
      modalError.toggle();
    }
    setLoadingAddMember(false);
  };

  const steps = useMemo(
    () => [
      <Step1
        key="1"
        initialData={{ ...data }}
        setData={changeData}
        nextStep={() => setCurrentPage(2)}
        handleChangeInput={handleChangeInput}
        groups={groups}
      />,
      <Step2
        key="2"
        initialData={{ ...data }}
        nextStep={() => setCurrentPage(3)}
        setData={changeData}
      />,
      <Step3
        key="3"
        initialData={{ ...data }}
        nextStep={() => setCurrentPage(4)}
        setData={changeData}
      />,
      <Step4
        key="4"
        initialData={{ ...data }}
        setData={changeData}
        nextStep={() => modalAddMember.toggle()}
      />
    ],
    [changeData, data, groups, handleChangeInput, modalAddMember]
  );

  return (
    <TemplateCloseContent
      closeLink={PATHS_ROUTES.APP.TEAM.MEMBERS}
      closeTitle="Voltar para minhas contas"
    >
      <TemplatePaginationStep
        steps={steps}
        currentPage={currentPage}
        prevPageItemClick={page => setCurrentPage(page)}
      />

      {modalAddMember.isActive && (
        <ModalAddMember
          onCloseModal={modalAddMember.toggle}
          onConfirm={handleSubmit}
          masterAccount={{
            name: groups?.find(x => x.id === data.groupId)?.groupName || ''
          }}
          member={{
            name: data.name!,
            userTypeLabel: data.userTypeLabel,
            imgSrc: ''
          }}
          loading={loadingAddMember}
        />
      )}

      {modalSuccess.isActive && (
        <ModalStatus
          variant="success"
          title="Adicionar membro"
          onClose={modalSuccess.toggle}
          action={{ onClick: modalSuccess.toggle }}
          description="Um e-mail foi enviado para o membro, com os dados de login e os passos para cadastrar uma senha."
        >
          <Text size="small" color="grayDark">
            O novo membro foi adicionado com sucesso.
          </Text>
        </ModalStatus>
      )}

      {modalError.isActive && (
        <ModalStatus
          variant="error"
          title="Adicionar membro"
          onClose={modalError.toggle}
          action={{ onClick: modalError.toggle }}
        >
          <Text size="small" color="grayDark">
            Ocorreu um erro ao cadastrar o novo membro.
          </Text>
        </ModalStatus>
      )}
    </TemplateCloseContent>
  );
};

export default TeamAddMember;
