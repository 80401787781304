import styled, { css, DefaultTheme } from 'styled-components';

type WrapperType = {
  horizontalCenterContent?: boolean;
  verticalCenterContent?: boolean;
  isGroup?: boolean;
};

const WrapperModifiers = {
  verticalCenterContent: () => css`
    & > div {
      align-items: center;
    }
  `,

  horizontalCenterContent: () => css`
    & > div {
      justify-content: center;
    }
  `,

  isGroup: (theme: DefaultTheme) => css`
    color: ${theme.colors.grayDark};

    svg {
      color: ${theme.colors.grayDark};
    }

    strong {
      color: ${theme.colors.grayDark};
    }
  `
};

export const Wrapper = styled.td<WrapperType>`
  ${({ theme, horizontalCenterContent, verticalCenterContent, isGroup }) => css`
    font-size: ${theme.font.sizes.small};
    display: table-cell;

    & > div {
      display: flex;
      flex-wrap: wrap;
    }

    ${verticalCenterContent && WrapperModifiers.verticalCenterContent()}
    ${horizontalCenterContent && WrapperModifiers.horizontalCenterContent()}
    ${isGroup && WrapperModifiers.isGroup(theme)}

    a {
      line-height: initial;
    }

    & > svg {
      margin-right: ${theme.spacings.small};
    }
  `}
`;
