import { useNavigate } from 'react-router-dom';
import { ModalAlert, Text } from 'components';
import PATHS_ROUTES from 'routes/paths';

import { useAuth } from 'contexts/Auth';

const Restrict = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <ModalAlert
      title="Acesso Restrito"
      buttonText="Voltar"
      onClose={() => navigate(PATHS_ROUTES.APP.DASHBOARD.DEFAULT)}
      buttonAction={() => navigate(PATHS_ROUTES.APP.DASHBOARD.DEFAULT)}
    >
      <Text tag="p" size="medium">
        {user?.userType === 'ADMIN' ? (
          <>
            Funcionalidade não disponível para sua empresa. Em caso de dúvidas,
            entre em contato com o{' '}
            <a
              href="https://www.amedigital.com/duvidas-plus"
              target="_blank"
              rel="noreferrer"
            >
              <Text tag="span" color="blackDark" weight="medium">
                SAC.
              </Text>
            </a>
          </>
        ) : (
          'Funcionalidade disponível somente para administradores da conta.'
        )}
      </Text>
    </ModalAlert>
  );
};

export default Restrict;
