import styled, { css } from 'styled-components';
import { Card } from 'styles/shared/Card';

export const Wrapper = styled.div``;

export const CardWrapper = styled(Card)`
  border-radius: 0;

  ${({ theme }) => css`
    padding: ${theme.spacings.large};

    b {
      color: ${theme.colors.blueLightest};
    }

    :hover {
      box-shadow: ${theme.shadow.default};
    }
  `}
`;

export const WrapperImage = styled.div`
  text-align: center;
`;
