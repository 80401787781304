import styled, { css } from 'styled-components';

import { Overlay } from 'components/Templates/Modal/styles';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ${Overlay} {
      z-index: ${theme.layers.alwaysOnTop};
    }
  `}
`;
