import * as S from './styles';

import PageItem from '../Item';

export type PageNumbersProps = {
  numberPages: number;
  currentPage: number;
  onClick?: (page: number) => void;
};

const PageNumbers = ({
  numberPages = 1,
  currentPage = 1,
  onClick
}: PageNumbersProps) => {
  return (
    <S.Wrapper>
      {new Array(numberPages).fill(null).map((_, idx) => {
        const page = idx + 1;
        const customStyle =
          page < currentPage ? { cursor: 'pointer' } : undefined;

        return (
          <PageItem
            key={idx}
            action={() => !!onClick && onClick(page)}
            active={currentPage === page}
            customStyles={customStyle}
          >
            {page}
          </PageItem>
        );
      })}
    </S.Wrapper>
  );
};

export default PageNumbers;
