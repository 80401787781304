import iconClose from 'assets/images/icon-close.svg';

import Arrow from 'components/Icons/Arrow';

import { Text } from 'components';

import { ThemeLayers } from 'types/global';

import * as S from './styles';

export type TemplateModalProps = {
  title: string;
  subtitle?: string;
  layer?: ThemeLayers;
  children?: React.ReactNode;
  onBackButton?: () => void;
  closeModal?: () => void;
  showCloseIcon?: boolean;
};

export function TemplateModal({
  title,
  subtitle,
  layer,
  children,
  closeModal,
  onBackButton,
  showCloseIcon = true
}: TemplateModalProps) {
  return (
    <>
      <S.Overlay onClick={closeModal} />
      <S.Wrapper layer={layer}>
        <S.Container>
          <S.Header>
            <S.HeaderContent>
              <S.TitleContainer>
                {!!onBackButton && (
                  <S.ArrowButton onClick={onBackButton}>
                    <Arrow />
                  </S.ArrowButton>
                )}
                <Text tag="h4" size="xxmedium" weight="medium" color="grayBlue">
                  {title}
                </Text>
              </S.TitleContainer>
              {!!closeModal && showCloseIcon && (
                <button onClick={closeModal} title="Fechar">
                  <img src={iconClose} alt={`Fechar ${title}`} />
                </button>
              )}
            </S.HeaderContent>
            {subtitle && (
              <Text tag="h5" color="grayNeutralDark">
                {subtitle}
              </Text>
            )}
          </S.Header>
          <S.Content>{children}</S.Content>
        </S.Container>
      </S.Wrapper>
    </>
  );
}

export default TemplateModal;
