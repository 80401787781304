import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { useAuth } from 'contexts/Auth';

import { LoginProps } from 'services/sessionService/types';

import {
  Loading,
  Button,
  Input,
  Text,
  Link,
  TemplateAuth,
  DividerText
} from 'components';

import { Container, Row, Column } from 'styles/shared/Grid';
import { Divider } from 'styles/shared/Divider';

import { getApiError } from 'utils/errors';

import PATHS_ROUTES from 'routes/paths';

import * as S from './styles';
import { validationSchema } from './validationSchema';

function LoginAuth() {
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: ({ username, password }) => {
      formik.setSubmitting(true);

      setError(null);

      handleLogin({ username, password });
    }
  });

  const handleLogin = async ({ username, password }: LoginProps) => {
    setLoading(true);

    try {
      await signIn({
        username,
        password
      });

      navigate(PATHS_ROUTES.AUTH.VALIDATE);
    } catch (error) {
      const { code, message } = getApiError(error, {
        message: 'Ocorreu um erro ao efetuar o login'
      });

      if (code !== 403) {
        setError(message);
        setLoading(false);
      }
    }
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent) => {
      if (error) setError(null);

      formik.handleChange(e);
    },
    [error, formik]
  );

  return (
    <>
      {loading && <Loading fullContent fullWidth />}
      <TemplateAuth>
        <Container>
          <Row justifyContentXs="center">
            <Column xs={10}>
              <S.Card>
                <Text tag="h1" weight="bold" size="xxlarge">
                  Ame Sua Conta{' '}
                  <Text tag="span" color="blue" weight="bold" size="xxlarge">
                    :)
                  </Text>
                </Text>

                <Text>Entre e gerencie suas vendas, equipe e grupos</Text>

                <Divider />

                <form onSubmit={formik.handleSubmit}>
                  <Input
                    name="username"
                    placeholder="Digite o seu usuário"
                    onChange={handleChange}
                    value={formik.values.username}
                    errorMessage={formik.errors.username}
                  />

                  <Divider size="small" />

                  <Input
                    name="password"
                    placeholder="Digite a sua senha"
                    type="password"
                    onChange={handleChange}
                    value={formik.values.password}
                    errorMessage={formik.errors.password}
                  />

                  <Divider />

                  {!!error && (
                    <Text color="red" size="xsmall" mb="medium">
                      {error}
                    </Text>
                  )}

                  <Row justifyContentXs="center">
                    <Column xs={10} md={5}>
                      <Button
                        as={Link}
                        to={PATHS_ROUTES.AUTH.FORGOT_PASSWORD}
                        tertiary
                        center
                      >
                        Esqueci minha senha
                      </Button>

                      <Divider size="large" />

                      <Button type="submit" disabled={loading} fullWidth filled>
                        Entrar
                      </Button>
                    </Column>
                  </Row>
                </form>
              </S.Card>

              <DividerText size="large">ou</DividerText>

              <S.RowAccreditation justifyContentXs="center">
                <Column xs={10} md={5}>
                  <Text align="center" mb="xsmall">
                    Não tem conta?
                  </Text>

                  <Button
                    as={Link}
                    secondary
                    fullWidth
                    to={PATHS_ROUTES.AUTH.ACCREDITATION.DEFAULT}
                  >
                    Cadastrar
                  </Button>
                </Column>
              </S.RowAccreditation>
            </Column>
          </Row>
        </Container>
      </TemplateAuth>
    </>
  );
}

export default LoginAuth;
