import styled, { css } from 'styled-components';

import { Card } from 'styles/shared/Card';

type ArrowProps = {
  open: boolean;
};

type ChildrenProps = {
  height: number;
} & ArrowProps;

const modifierCardChildren = {
  open: (height: number) => css`
    overflow: auto;
    height: ${height}px;
  `
};

const modifierArrowDirection = {
  open: () => css`
    transform: rotate(180deg);
    margin-left: 1rem;
  `
};

export const Wrapper = styled.button`
  ${({ onClick }) => css`
    width: 100%;

    cursor: ${onClick ? 'pointer' : 'default'};
  `}
`;

export const CardWrapper = styled(Card)`
  padding: 1rem;
  margin: 1rem 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg,
  img {
    margin-right: 1rem;
  }
`;

export const Arrow = styled.button<ArrowProps>`
  ${({ theme, open }) => css`
    padding: 0.5rem 0;
    cursor: pointer;

    svg {
      transform: rotate(0deg);
      transition: all ${theme.transition.default};

      ${open && modifierArrowDirection.open()}
    }
  `}
`;

export const Children = styled.div<ChildrenProps>`
  ${({ theme, open, height }) => css`
    transition: all ${theme.transition.default};
    padding-left: 1rem;
    overflow: hidden;
    height: 0;

    ${open && modifierCardChildren.open(height)}
  `}
`;
