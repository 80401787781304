import styled, { css, DefaultTheme } from 'styled-components';
import customMedia from 'styles/media-query';

export type ContainerProps = {
  fullWidth?: boolean;
};

const containerModifiers = {
  fullWidth: (theme: DefaultTheme) => css`
    ${customMedia.greaterThan('small')`
      max-width: ${theme.grid.sizes.small};
    `}

    ${customMedia.greaterThan('medium')`
      max-width: ${theme.grid.sizes.medium};
    `}

    ${customMedia.greaterThan('large')`
      max-width: ${theme.grid.sizes.large};
    `}
  `
};

export const Container = styled.div<ContainerProps>`
  ${({ theme, fullWidth }) => css`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;

    ${!fullWidth && containerModifiers.fullWidth(theme)}
  `}
`;

export default Container;
