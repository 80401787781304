import PATHS_ROUTES from 'routes/paths';
import { Row, Column } from 'styles/shared/Grid';
import { CardGroup, TemplateSearchContent } from 'components';
import { ReactComponent as IconAdd } from 'assets/images/icon-add-outline.svg';

import { groups } from './__mocks__/accounts';
import { useNavigate } from 'react-router-dom';

const breadcrumbProps = {
  links: [
    {
      title: 'Minhas contas',
      link: '/minhas-contas'
    },
    {
      title: 'Grupos',
      link: '/minhas-contas/grupos'
    }
  ]
};

const actions = [
  {
    to: PATHS_ROUTES.APP.ACCOUNTS.GROUP,
    text: 'Novo Grupo',
    icon: <IconAdd />
  }
];

function Accounts() {
  const navigate = useNavigate();

  return (
    <TemplateSearchContent
      breadcrumbProps={breadcrumbProps}
      actions={actions}
      inputSearchProps={{
        placeholder: 'Digite nome, grupo ou CNPJ'
      }}
      paginationNavProps={{
        previousAction: () => {},
        nextAction: () => {}
      }}
    >
      <Row>
        {groups.map(group => (
          <Column key={group.id} lg={4}>
            <CardGroup
              small
              {...group}
              onSeeMoreClick={() => {
                navigate(PATHS_ROUTES.APP.ACCOUNTS.GROUP_EDIT(group.id));
              }}
            />
          </Column>
        ))}
      </Row>
    </TemplateSearchContent>
  );
}

export default Accounts;
