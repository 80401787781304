/* eslint-disable @typescript-eslint/no-explicit-any */
export type BffError = {
  status?: string;
  message: string;
  code?: number;
  type?: string;
};

const DEFAULT_ERROR: BffError = {
  status: 'unknown',
  message: 'Ocorreu um erro desconhecido',
  type: 'UnknownError'
};

export const getApiError = (error: any, defaultError?: BffError): BffError => {
  try {
    const {
      response: {
        data: { status, message, type },
        status: code
      }
    } = error;

    if (!message) {
      throw new Error('Message is undefined');
    }

    return { status, message, code, type };
  } catch (e) {
    return { ...DEFAULT_ERROR, ...defaultError, code: error?.response?.status };
  }
};
