import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';

import { Button, Flex, Text, IconArrow, Money, CardInfo } from 'components';
import { Column, Row } from 'styles/shared/Grid';
import Margin from 'styles/shared/Margin';
import Card from 'styles/shared/Card';
import Divider from 'styles/shared/Divider';

import { ReactComponent as IconWithdraw } from 'assets/images/icon-withdraw-outline.svg';
import { ReactComponent as IconConfiguration } from 'assets/images/icon-configuration-outline.svg';
import { ReactComponent as IconStatement } from 'assets/images/icon-statement-outline.svg';
import { ReactComponent as IconTaxes } from 'assets/images/icon-taxes.svg';
import { ReactComponent as IconBankOutline } from 'assets/images/icon-bank-outline.svg';
import { ReactComponent as IconReceive } from 'assets/images/icon-receive.svg';
import { ReactComponent as IconHelpOutline } from 'assets/images/icon-help-outline.svg';
import { ReactComponent as IconLimitOutline } from 'assets/images/icon-limit-outline.svg';

import PATHS_ROUTES from 'routes/paths';

import { usePix } from 'contexts/Pix';
import { useAuth } from 'contexts/Auth';

import FirstAccess from '../FirstAccess';
import { CardQRCode } from '../components';
import { ModalReceiveQRFirstAccess } from '../ReceiveQR/components';

const Pix = () => {
  const navigate = useNavigate();
  const { permissions } = useAuth();
  const {
    balance: { data: balanceData },
    keysList: { data: keys }
  } = usePix();
  const [openReceiveQRCodeModal, setOpenReceiveQRCodeModal] = useState(false);

  const handleSelectCard = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const [pixBoxItems, setPixBoxItems] = useState([
    {
      id: 'box1',
      text: 'Transações',
      items: [
        {
          title: 'Receber com QR Code',
          icon: {
            src: <IconReceive />,
            alt: 'Ícone de receber com qr code'
          },
          action: () => setOpenReceiveQRCodeModal(true),
          disable: !keys || !keys.length
        }
      ]
    },
    {
      id: 'box2',
      text: 'Informações Pix',
      items: [
        {
          title: 'Extrato Pix',
          icon: {
            src: <IconStatement />,
            alt: 'Ícone de extrato pix'
          },
          action: () => {},
          disable: true
        },
        {
          title: 'Tarifas Pix na Ame',
          icon: {
            src: <IconTaxes />,
            alt: 'Ícone de tarifas pix'
          },
          action: () => handleSelectCard(PATHS_ROUTES.APP.PIX.TAX),
          disable: false
        }
      ]
    },
    {
      id: 'box3',
      items: [
        {
          title: 'Minha agência e conta Pix',
          icon: {
            src: <IconBankOutline />,
            alt: 'Ícone de agência e conta pix'
          },
          action: () => handleSelectCard(PATHS_ROUTES.APP.PIX.AGENCYACCOUNT),
          disable: false
        },
        {
          title: 'O que é Pix?',
          icon: {
            src: <IconHelpOutline />,
            alt: 'Ícone de o que é pix'
          },
          action: () => handleSelectCard(PATHS_ROUTES.APP.PIX.FAQ),
          disable: false
        }
      ]
    }
  ]);

  const permissionsItems = useMemo(() => {
    return {
      pixKeyTransfer: {
        title: 'Transferir',
        icon: {
          src: <IconWithdraw />,
          alt: 'Ícone de tranferir com chave pix'
        },
        action: () => handleSelectCard(PATHS_ROUTES.APP.PIX.TRANSFER.DEFAULT),
        disable: !keys || !keys.length
      },
      pixKeys: {
        title: 'Minhas chaves',
        icon: {
          src: <IconConfiguration />,
          alt: 'Ícone de minhas chaves'
        },
        action: () => handleSelectCard(PATHS_ROUTES.APP.PIX.KEYS.DEFAULT),
        disable: false
      },
      pixLimits: {
        title: 'Meus limites Pix',
        icon: {
          src: <IconLimitOutline />,
          alt: 'Ícone de meus limites pix'
        },
        action: () => handleSelectCard(PATHS_ROUTES.APP.PIX.MY_LIMITS),
        disable: false
      }
    };
  }, [handleSelectCard, keys]);

  const updatePixBoxItems = useCallback((index, item) => {
    setPixBoxItems(box => {
      if (box[index].items.find(it => it.title === item.title)) return box;
      const boxCopy = box.slice();
      boxCopy[index].items = [...boxCopy[index].items, item];
      return boxCopy;
    });
  }, []);

  useEffect(() => {
    if (permissions) {
      if (permissions.includes('PIX_OUT')) {
        updatePixBoxItems(0, permissionsItems.pixKeyTransfer);
      }
      if (permissions.includes('PIX_CHAVES')) {
        updatePixBoxItems(1, permissionsItems.pixKeys);
      }
      if (permissions.includes('PIX_LIMITES')) {
        updatePixBoxItems(2, permissionsItems.pixLimits);
      }
    }
  }, [permissions, updatePixBoxItems, permissionsItems]);

  return (
    <>
      <FirstAccess />
      {openReceiveQRCodeModal && (
        <ModalReceiveQRFirstAccess
          handleClose={() => setOpenReceiveQRCodeModal(false)}
          handleContinue={() =>
            handleSelectCard(PATHS_ROUTES.APP.PIX.RECEIVE_QR)
          }
        />
      )}
      <Margin mb="small">
        <Text weight="medium">Saldo Ame</Text>
      </Margin>
      <Row>
        <Column xs={12} sm={12} lg={12}>
          <Card active>
            <S.CardContainer
              justifyContent="space-between"
              wrap="nowrap"
              alignItems="center"
            >
              <Column xs={12} sm={4} lg={4}>
                <Flex direction="column">
                  <Text tag="p" color="white" size="medium">
                    Saldo disponível
                  </Text>

                  <Flex alignItems="baseline">
                    <Money
                      tag="p"
                      color="white"
                      size="xlarge"
                      value={balanceData!.cashBalance}
                    />
                  </Flex>
                </Flex>
              </Column>

              <Column xs={12} sm={4} lg={4}>
                <Flex direction="column" alignItems="center" my="medium">
                  <S.SectionSubtitleTitle
                    tag="p"
                    color="white"
                    size="small"
                    align="center"
                  >
                    Saldo de depósitos e transferências recebidas. Você pode
                    transferir para sua conta bancária :)
                  </S.SectionSubtitleTitle>
                </Flex>
              </Column>

              {permissions?.includes('EXTRATO') && (
                <Column xs={12} sm={4} lg={4}>
                  <Flex justifyContent="flex-end">
                    <Button
                      filled
                      filledBgColor="white"
                      filledColor="blue"
                      onClick={() =>
                        handleSelectCard(PATHS_ROUTES.APP.STATEMENT.LIST)
                      }
                      fullWidthMobile
                    >
                      <Flex justifyContent="space-between" alignItems="end">
                        <Margin mr="small">Ver detalhes</Margin>

                        <IconArrow color="blue" direction="right" />
                      </Flex>
                    </Button>
                  </Flex>
                </Column>
              )}
            </S.CardContainer>
          </Card>
        </Column>
      </Row>

      <Row>
        <Column xs={12} sm={12} lg={12}>
          <CardQRCode />
        </Column>
      </Row>

      <Row>
        {pixBoxItems.map(box => (
          <Column xs={12} sm={4} lg={4} key={box.id}>
            <Text weight="medium" size="small">
              {box.text}
            </Text>
            {!box.text && <Divider size="medium" />}
            {box.items.map(card => (
              <CardInfo
                key={card.title}
                text={card.title}
                icon={<S.WrapperIcon>{card.icon.src}</S.WrapperIcon>}
                action={card.action}
                rightContent={<IconArrow direction="right" />}
                disabled={card.disable}
              />
            ))}
          </Column>
        ))}
      </Row>
    </>
  );
};

export default Pix;
