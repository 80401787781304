import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import * as S from './styles';

import PATHS_ROUTES from 'routes/paths';

import endpoints from 'services/endpoints';
import { PlanDataResponse } from 'services/userService';
import { setAutomaticAdvance } from 'services/advanceService';
import IconInfo from 'assets/images/icon-info.png';
import useRequest from 'hooks/useRequest';

import { useAuth } from 'contexts/Auth';

import { formatCurrency } from 'utils/masks';
import {
  ErrorMessage,
  Text,
  TemplateCloseContent,
  Loading,
  Warning,
  Flex,
  ToggleLabel,
  ModalInfo
} from 'components';
import Divider from 'styles/shared/Divider';
import Margin from 'styles/shared/Margin';
import ModalContent from './modalContent';

const AccountPlanData = () => {
  const [flagLoading, setFlagLoading] = useState(false);
  const [flagError, setFlagError] = useState('');
  const [toggleModal, setToggleModal] = useState(false);
  const { permissions } = useAuth();
  const { data, error, isValidating, revalidate } =
    useRequest<PlanDataResponse>({
      url: endpoints.planData,
      options: {
        revalidateOnFocus: false
      }
    });

  const handleReceiveAdvance = useCallback(async () => {
    if (data) {
      try {
        setFlagLoading(true);
        const canReceiveAdvance = !data.card.receiveAdvance;
        await setAutomaticAdvance(canReceiveAdvance);
        await revalidate();
      } catch (error) {
        setFlagError('Não foi possível alterar a antecipação automática');
      } finally {
        setFlagLoading(false);
      }
    }
  }, [data, revalidate]);

  if (error) {
    return (
      <ErrorMessage
        message="Ocorreu um erro ao carregar os dados, atualize a página e tente
      novamente."
      />
    );
  }

  if (!data || isValidating || flagLoading) {
    return <Loading fullContent />;
  }

  return (
    <TemplateCloseContent
      closeLink={PATHS_ROUTES.APP.ACCOUNT.PROFILE}
      closeTitle="Voltar para o perfil"
    >
      <Text tag="h2" size="xxmedium" weight="medium" mb="medium">
        Dados do plano
      </Text>

      <Text tag="h4" size="xsmall" weight="bold" mb="xsmall">
        Prazo de liquidação
      </Text>

      <Text size="medium" mb="medium">{`${data.card.releaseTime} dia(s)`}</Text>

      <Text tag="h4" size="xsmall" weight="bold" mb="xsmall">
        Taxa de transação (cartão)
      </Text>

      <Text size="medium" mb="medium">
        {formatCurrency(data.card.takeRate, false)}%
      </Text>

      <Flex justifyContent="space-between" alignItems="center">
        <div>
          <Text tag="h4" size="xsmall" weight="bold" mb="xsmall">
            Parcelamento
          </Text>

          <Text size="medium">Até {data.card.maxInstallment}x</Text>
        </div>
        {permissions?.includes('MINHA_CONTA_EDITAR') && (
          <div>
            <Link to={PATHS_ROUTES.APP.ACCOUNT.EDIT_INSTALLMENTS}>
              <Text color="blue" size="small" weight="medium">
                Alterar
              </Text>
            </Link>
          </div>
        )}
      </Flex>

      <Text tag="h4" size="xsmall" weight="bold" mt="medium" mb="xsmall">
        Taxa de parcelamento de 2x a 6x
      </Text>

      <Text size="medium" mb="medium">
        {formatCurrency(data.card.installments.feeValue2_6, false)}%
      </Text>

      {data.card.installments.feeValue7_12 > 0 && (
        <>
          <Text tag="h4" size="xsmall" weight="bold" mb="xsmall">
            Taxa de parcelamento de 7x a 12x
          </Text>

          <Text size="medium" mb="medium">
            {formatCurrency(data.card.installments.feeValue7_12, false)}%
          </Text>
        </>
      )}

      <Text tag="h4" size="xsmall" weight="bold" mb="xsmall">
        Cashback Ame
      </Text>

      <Text size="medium" mb="small">
        {formatCurrency(data.card.cashbackValue, false)}%
      </Text>

      {data.card.maxInstallment > 1 && (
        <>
          <ToggleLabel
            title={`Antecipação automática (${formatCurrency(
              data.card.takeRateAdvance,
              false
            )}%)`}
            subtitle="Receba o total de suas vendas parceladas em até um dia."
            onCheck={handleReceiveAdvance}
            isChecked={data.card.receiveAdvance}
            showBorder={false}
          />
          {!!flagError && (
            <Text size="small" color="red">
              {flagError}
            </Text>
          )}
        </>
      )}

      <Divider size="large" />

      <S.InfoIcon onClick={() => setToggleModal(!toggleModal)}>
        <Flex alignItems="center">
          <img src={IconInfo} alt="Regras de parcelamento de vendas" />
          <Margin ml="small">
            <span> Regras de parcelamento de vendas</span>
          </Margin>
        </Flex>
      </S.InfoIcon>

      <Divider size="xlarge" />

      <Warning>
        Quer mudar alguma condição do seu plano? Acesse nossa{' '}
        <a
          href="https://www.amedigital.com/duvidas-plus"
          target="_blank"
          rel="noreferrer"
        >
          <Text tag="span" color="blue">
            Central de ajuda
          </Text>
        </a>
      </Warning>
      {toggleModal && (
        <ModalInfo
          onClose={() => setToggleModal(false)}
          title="Regras de vendas parceladas"
          subtitle="Vendas parceladas"
          content={ModalContent}
        />
      )}
    </TemplateCloseContent>
  );
};

export default AccountPlanData;
