import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { Settings } from 'react-slick';
import { Slider, Text, Flex, Button } from 'components';
import { PositionTypes } from 'components/Button';

import Margin from 'styles/shared/Margin';

import { ReactComponent as IconListBlue } from 'assets/images/icon-list-blue.svg';
import { ReactComponent as IconStatementOutline } from 'assets/images/icon-statement-outline.svg';

import PATHS_ROUTES from 'routes/paths';

const SliderCard = () => {
  const navigate = useNavigate();
  const SliderSettings: Settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };

  const buttonStyle = {
    position: 'absolute' as PositionTypes,
    bottom: '3rem',
    width: '20rem'
  };

  return (
    <Slider settings={SliderSettings}>
      <S.Wrapper>
        <Flex direction="column">
          <Flex>
            <Margin mr="small">
              <IconStatementOutline />
            </Margin>
            <Text tag="h3" size="large" weight="bold">
              Credenciais
            </Text>
          </Flex>
          <Margin mt="small" mb="large">
            <Text size="small" color="grayDark">
              Gere as credenciais para integrar seu site, seu app ou sua loja
              física.
            </Text>
          </Margin>
          <Button
            {...buttonStyle}
            filled
            onClick={() =>
              navigate(PATHS_ROUTES.APP.INTEGRATION.GENERATE_CREDENTIAL)
            }
          >
            Gerar credenciais
          </Button>
        </Flex>
      </S.Wrapper>

      {/* <S.Wrapper>
        <Flex direction="column">
          <Flex>
            <Margin mr="small">
              <IconStatementOutline />
            </Margin>
            <Text tag="h3" size="large" weight="bold">
              Credenciais em lote
            </Text>
          </Flex>
          <Margin mt="small" mb="large">
            <Text size="small" color="grayDark">
              Faça download de suas credenciais geradas em lote.
            </Text>
          </Margin>
          <Button
            filled
            onClick={() =>
              navigate(PATHS_ROUTES.APP.INTEGRATION.CREDENTIALS_LOT)
            }
          >
            Baixar
          </Button>
        </Flex>
      </S.Wrapper> */}

      <S.Wrapper>
        <Flex direction="column">
          <Flex>
            <Margin mr="small">
              <IconListBlue />
            </Margin>
            <Text tag="h3" size="large" weight="bold">
              Tutoriais
            </Text>
          </Flex>
          <Margin mt="small" mb="large">
            <Text size="small" color="grayDark">
              Visite nossa página de tutoriais para saber como fazer as
              integrações com seu site.
            </Text>
          </Margin>
          <Button
            {...buttonStyle}
            filled
            onClick={() => navigate(PATHS_ROUTES.APP.INTEGRATION.TUTORIAL)}
          >
            Ver Tutoriais
          </Button>
        </Flex>
      </S.Wrapper>
    </Slider>
  );
};

export default SliderCard;
