import { ReactNode } from 'react';

import { Text } from 'components';

export type STEP_TYPES = 'info' | 'confirm_success' | 'cancel_success' | 'fail';

export interface IModalObject {
  title: string;
  subtitle: string | ReactNode;
  buttonText1: string;
  buttonText2?: string;
}

type formatModalObj = {
  OWNERSHIP: { [key: string]: IModalObject };
  PORTABILITY: { [key: string]: IModalObject };
  fail: IModalObject;
};

const portabilityInfoSubtitle = (key: string) => {
  return (
    <>
      Recebemos o pedido de portabilidade da chave{' '}
      <Text tag="span" color="blue" size="xxmedium">
        {key}
      </Text>
      . Tem certeza que deseja transferir a chave para outra conta? Após a
      confirmação, a chave não estará mais disponível para você usar na Ame.
    </>
  );
};

const ownershipInfoSubtitle = (key: string) => {
  return (
    <>
      Recebemos um pedido de reivindicação de posse da chave{' '}
      <Text tag="span" color="blue" size="xxmedium">
        {key}
      </Text>
      . Isso significa que outra pessoa deseja cadastrar essa chave em uma conta
      que não é sua. <br /> Você aceita transferir a posse da chave?
      <Text tag="p" color="red" align="center" size="xmedium" mt="small">
        *Para continuar usando a chave, você precisa validar ela novamente em
        até 14 dias.
      </Text>
    </>
  );
};

const ownershipCancelSuccessSubtitle = (key: string) => {
  return (
    <>
      O processo de reivindicação de posse da chave{' '}
      <Text tag="span" color="blue" size="xxmedium">
        {key}
      </Text>{' '}
      foi recusado. O atual dono da chave decidiu que quer continuar usando ela.
      Mas não se preocupe, você pode usar outras chaves ou cadastrar novas
      sempre que quiser!
    </>
  );
};

export const TEXT_MODALS: formatModalObj = {
  OWNERSHIP: {
    info: {
      title: 'Reivindicação de posse',
      subtitle: ownershipInfoSubtitle,
      buttonText1: 'Continuar com a chave',
      buttonText2: 'Aceitar transferência'
    },
    confirm_success: {
      title: 'Reivindicação concluída',
      subtitle:
        'A chave foi transferida para outra pessoa. Para continuar usando o Pix na Ame, você pode usar outras chaves ou cadastrar novas sempre que quiser :)',
      buttonText1: 'Entendi'
    },
    cancel_success: {
      title: 'Reivindicação recusada',
      subtitle: ownershipCancelSuccessSubtitle,
      buttonText1: 'Entendi'
    }
  },
  PORTABILITY: {
    info: {
      title: 'Portabilidade de chave',
      subtitle: portabilityInfoSubtitle,
      buttonText1: 'Usar chave na Ame',
      buttonText2: 'Fazer portabilidade'
    },
    confirm_success: {
      title: 'Portabilidade concluída',
      subtitle:
        'A chave foi transferida para outra conta. Para continuar a utilizar o Pix na Ame, você pode usar outras chaves ou cadastrar novas sempre que quiser :)',
      buttonText1: 'Entendi'
    },
    cancel_success: {
      title: 'Portabilidade recusada',
      subtitle:
        'A chave não foi transferida para sua conta Ame pois não recebemos a confirmação da portabilidade no prazo de 7 dias. Você pode tentar mais uma vez ou cadastrar um outro tipo de chave em “Minhas chaves”.',
      buttonText1: 'Entendi'
    }
  },
  fail: {
    title: 'Falha de comunicação',
    subtitle:
      'Tivemos uma falha de comunicação com o sistema, mas não se preocupe, você pode tentar novamente. Vamos lá?',
    buttonText1: 'Entendi'
  }
};
