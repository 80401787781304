import { useState, useCallback } from 'react';
import useMutate from 'hooks/useMutate';

import { ReactComponent as Spinner } from 'assets/images/pix/spinner.svg';

import { Button, Flex, InputValue, RangeSlider, Text } from 'components';
import CardLimit from '../CardLimit';

import { PixLimits } from '../../types';
import * as S from '../../styles';

import endpoints from 'services/endpoints';

export interface DefaultTransactionProps {
  data: PixLimits;
  changePixLimits: (data: PixLimits) => void;
  updatePixLimit: () => void;
  title: string;
  loading?: boolean;
}

const DefaultTransaction = ({
  data,
  changePixLimits,
  updatePixLimit,
  title,
  loading
}: DefaultTransactionProps) => {
  const { mutate, state } = useMutate();
  const [inEditing, setInEditing] = useState(false);
  const [value, setValue] = useState(
    data.increaseRequest?.monthlyLimit || data.monthly
  );

  const handleLimit = useCallback(
    (newLimit?: number) => {
      if (newLimit) setValue(newLimit);

      mutate({
        method: 'put',
        endpoint: endpoints.pixLimitsCalculate,
        body: {
          requestedLimit: newLimit || value,
          transactionType: data.transactionType
        },
        onSuccess: response => {
          changePixLimits({ ...response });
          setInEditing(false);
        }
      });
    },
    [changePixLimits, data.transactionType, mutate, value]
  );

  return (
    <>
      <S.WrapperTransaction mt="large">
        <Flex justifyContent="center">
          <InputValue
            label=""
            maxValue={data.approvedLimit.toString()}
            disabledInput={false}
            initialValue={value.toString()}
            onApplyValue={newLimit => handleLimit(parseInt(newLimit))}
            onEditing={() => setInEditing(true)}
            resetAmount={true}
            color="blue"
          />

          <RangeSlider
            changeValue={newLimit => setValue(parseInt(newLimit))}
            value={value}
            minValue={0}
            maxValue={data.approvedLimit}
            onEditing={isEditing => {
              setInEditing(isEditing);
              if (!isEditing) handleLimit(value);
            }}
          />
        </Flex>

        <Text
          color="grayNeutralBrandDarkest"
          mt="medium"
          size="small"
          weight="medium"
        >
          {title}
        </Text>
        <CardLimit
          value={data.increaseRequest?.monthlyLimit || data.monthly}
          description="Mensal"
          type="send"
          loading={state.loading}
        />
        <CardLimit
          value={data.increaseRequest?.dailyLimit || data.daily}
          description="Diário"
          type="send"
          loading={state.loading}
        />

        <Text
          color="grayNeutralBrandDarkest"
          mt="medium"
          size="small"
          weight="medium"
        >
          Recebimentos
        </Text>
        <CardLimit
          value="Limite livre"
          description="Transações ilimitadas todos os dias ;)"
          type="receive"
        />

        <Flex mt="large" justifyContent="center">
          <Button
            filled
            disabled={inEditing}
            onClick={() => updatePixLimit()}
            role="save-button"
          >
            {loading ? <Spinner /> : 'Salvar alterações'}
          </Button>
        </Flex>
      </S.WrapperTransaction>
    </>
  );
};

export default DefaultTransaction;
