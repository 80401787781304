import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import * as S from './styles';
import PATHS_ROUTES from 'routes/paths';
import {
  ErrorMessage,
  Text,
  Money,
  Button,
  ModalAlert,
  ModalStatus,
  CardValue,
  TemplateCloseContent,
  Loading
} from 'components';

import useRequest from 'hooks/useRequest';
import useToggle from 'hooks/useToggle';
import { Divider } from 'styles/shared/Divider';
import { Row, Column } from 'styles/shared/Grid';
import endpoints from 'services/endpoints';
import { GetStatementDetailsResponse } from 'services/statementService';
import { PlanDataResponse } from 'services/userService';

import {
  getAdvancePreview,
  advanceIndividual,
  GetAdvancePreviewResponse
} from 'services/advanceService';
import { formatCurrency } from 'utils/masks';

type ParamsTypes = {
  id: string;
};

function StatementAnticipate() {
  const successModal = useToggle();
  const alertModal = useToggle();
  const navigate = useNavigate();
  const { id } = useParams<ParamsTypes>();

  const [previewData, setPreviewData] = useState<GetAdvancePreviewResponse>();
  const [previewDataError, setPreviewDataError] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmError, setConfirmError] = useState('');

  const { data: planData, error: planDataError } = useRequest<PlanDataResponse>(
    {
      url: endpoints.planData,
      options: {
        revalidateOnFocus: false
      }
    }
  );
  const { data, error } = useRequest<GetStatementDetailsResponse>({
    url: id ? endpoints.statementDetails.replace('{id}', id) : null,
    options: {
      revalidateOnFocus: false
    }
  });

  useEffect(() => {
    if (data && planData && !previewData) {
      if (data.canAnticipate && !planData.card.receiveAdvance) {
        getAdvancePreview(data.id)
          .then(response => {
            setPreviewData(response.data);
          })
          .catch(() => {
            setPreviewDataError('Ocorreu um erro ao carregar os dados');
          });
      } else {
        navigate(PATHS_ROUTES.APP.STATEMENT.DETAILS(id));
      }
    }
  }, [data, navigate, id, planData, previewData]);

  const navigateToDetail = useCallback(() => {
    navigate(PATHS_ROUTES.APP.STATEMENT.DETAILS(id));
  }, [navigate, id]);

  const handleConfirmAnticipate = useCallback(() => {
    if (data) {
      setConfirmLoading(true);
      advanceIndividual(data.id, data.referenceOrderUuid)
        .then(() => {
          successModal.toggle();
        })
        .catch(error => {
          setConfirmError(error.message);
          alertModal.toggle();
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    }
  }, [alertModal, data, successModal]);

  if (!!error || !!planDataError || !!previewDataError) {
    return (
      <ErrorMessage
        message="Ocorreu um erro ao carregar os dados, atualize a página e tente
        novamente."
      />
    );
  }

  if (!data || !planData || !previewData) {
    return <Loading fullContent />;
  }

  return (
    <>
      {confirmLoading && <Loading fullContent />}
      <TemplateCloseContent
        closeLink={PATHS_ROUTES.APP.STATEMENT.DETAILS(id)}
        closeTitle="Voltar para o extrato"
      >
        <Row>
          <Column xs={12}>
            <Text tag="h2" size="xxmedium" weight="medium">
              Antecipação
            </Text>
            <Text size="medium" darkColor="white">
              Antecipe suas vendas e receba em até 1 dia útil.
            </Text>
            <Divider />
          </Column>
        </Row>
        <Divider />

        <Row>
          <Column xs={12}>
            <Text size="xsmall" weight="medium" darkColor="white">
              Valor disponível
            </Text>
            <Money
              value={previewData.calculatedAmountMdr}
              weight="medium"
              size="xxxlarge"
              color="grayDark"
            />
          </Column>
        </Row>

        <Divider size="medium" />
        <Row>
          <Column xs={12}>
            <S.TaxValue>
              <div>
                <Text size="xsmall" weight="medium">
                  Taxa de antecipação
                </Text>
                <Text size="small">
                  ({formatCurrency(previewData.advanceTax)}% a.m.)
                </Text>
              </div>
              <Money color="red" value={previewData.calculatedTax * -1} />
            </S.TaxValue>
          </Column>
        </Row>

        <Divider size="large" />
        <Row>
          <Column xs={12}>
            <CardValue
              value={previewData.calculatedAmountAdvance}
              valueSize="xxlarge"
              color="blue"
              labelTop="Total a ser antecipado"
              labelBottom="Valor com taxas aplicadas"
              contentColumnSize={5}
            >
              <Button secondary onClick={handleConfirmAnticipate}>
                Solicitar
              </Button>
            </CardValue>
          </Column>
        </Row>
      </TemplateCloseContent>

      {alertModal.isActive && (
        <ModalAlert
          title="Antecipação"
          buttonText="OK"
          onClose={alertModal.toggle}
          buttonAction={alertModal.toggle}
        >
          <Text size="medium">{confirmError}</Text>
        </ModalAlert>
      )}

      {successModal.isActive && (
        <ModalStatus
          variant="success"
          title="Antecipação concluída"
          onClose={navigateToDetail}
          action={{ onClick: navigateToDetail }}
        >
          <Text size="medium">
            A antecipação foi realizada com sucesso e estará disponível em até
            <strong> 24h em sua conta.</strong>
          </Text>
        </ModalStatus>
      )}
    </>
  );
}

export default StatementAnticipate;
