import {
  CardInfo,
  TemplateCloseContent,
  Text,
  ToggleLabel,
  Warning
} from 'components';
import Divider from 'styles/shared/Divider';

import { ReactComponent as IconChat } from 'assets/images/icon-chat.svg';
import { ReactComponent as IconEmail } from 'assets/images/icon-email.svg';
import { ReactComponent as IconLock } from 'assets/images/icon-lock-shadow.svg';
import { ReactComponent as IconNotification } from 'assets/images/icon-notification.svg';

import PATHS_ROUTES from 'routes/paths';
import useToggle from 'hooks/useToggle';

function Privacy() {
  const toggleSms = useToggle();
  const toggleEmail = useToggle();
  const toggleNotification = useToggle();

  return (
    <>
      <TemplateCloseContent
        closeLink={PATHS_ROUTES.APP.ACCOUNT.PROFILE}
        closeTitle="Voltar para minha conta"
      >
        <Text size="xxmedium" weight="bold" tag="h2">
          Privacidade
        </Text>

        <Divider size="small" />

        <Text size="small" weight="medium" tag="h3">
          Gerencie o recebimento das novidades e promoções
        </Text>

        <ToggleLabel
          title="Receber por  SMS"
          subtitle="Receba novidades e promoções por SMS no seu celular"
          onCheck={toggleSms.toggle}
          isChecked={toggleSms.isActive}
          icon={<IconChat />}
        />

        <ToggleLabel
          title="Receber por  e-mail"
          subtitle="Receba novidades e promoções no seu e-mail cadastrado na Ame"
          onCheck={toggleEmail.toggle}
          isChecked={toggleEmail.isActive}
          icon={<IconEmail />}
        />

        <ToggleLabel
          title="Receber por notificação"
          subtitle="Receba novidades e promoções por notificação no seu celular"
          onCheck={toggleNotification.toggle}
          isChecked={toggleNotification.isActive}
          icon={<IconNotification />}
        />

        <Divider size="medium" />

        <a
          href="https://www.amedigital.com/politica-de-privacidade"
          target="_blank"
          rel="noreferrer"
        >
          <CardInfo text="Política de privacidade" icon={<IconLock />} />
        </a>

        <Divider size="large" />

        <Warning>
          <Text size="xsmall" align="center" tag="span">
            Ao desativar as novidades e promoções, você continua recebendo
            notificações de transações e segurança, ok?
          </Text>
        </Warning>
      </TemplateCloseContent>
    </>
  );
}

export default Privacy;
