import { useCallback, useEffect } from 'react';

import useMutate from 'hooks/useMutate';
import endpoints from 'services/endpoints';

export function useSendCode(phone: string) {
  const { mutate, state } = useMutate();

  const send = useCallback(() => {
    mutate({
      endpoint: endpoints.validationSms,
      body: {
        phone: `+55${phone}`
      }
    });
  }, [mutate, phone]);

  useEffect(() => {
    send();
  }, [send]);

  return {
    resend: send,
    ...state
  };
}
