import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 58rem;

    b {
      color: ${theme.colors.blue};
    }

    ${customMedia.lessThan('small')`
      width: 100%;
      padding-bottom: 2rem;
      height: 100%;
    `}
  `}
`;
