import styled from 'styled-components';
import customMedia from 'styles/media-query';

import { Flex } from 'components';
import { Container } from 'styles/shared/Grid';

export const MainContainer = styled(Container)`
  max-width: 490px;
`;

export const IconCLose = styled(Flex)`
  margin-bottom: 2rem;

  svg {
    cursor: pointer;
  }
`;

export const MobileCenter = styled(Flex)`
  flex: 1;

  ${customMedia.lessThan('medium')`
    flex: inherit;
    align-items: center;
    justify-content: center;
    
    p {
      text-align: center;
    }
  `}
`;
