export interface Platform {
  id: string;
  anticipationFee: string;
  creditCardFee: string;
  dailiesLiquid: string;
  debitFee: string;
  platformAcronym: string;
  platformName: string;
  receiveAdvance: boolean;
  sendIp: boolean;
  sevenToTwelveFee: string;
  twoToSixFee: string;
}

export type AccreditationValues = typeof initialValues & {
  platform?: Platform;
  docDeclaration?: File;
  docSocial?: File;
  sendDocuments?: boolean;
};

export type FieldNames = typeof fieldNames;
export type PartnershipTypes = Array<{
  label: string;
  value: '' | 'shopping' | 'associacao';
}>;

export const partnershipTypes = [
  {
    label: 'Não tenho parceria',
    value: ''
  },
  {
    label: 'Shopping',
    value: 'shopping'
  },
  {
    label: 'Associação',
    value: 'associacao'
  },
  {
    label: 'Revendedor',
    value: 'revendedor'
  }
] as PartnershipTypes;

export const fieldNames = {
  cnpj: 'cnpj',
  social: 'social',
  partnershipType: 'partnership-type',
  partnership: 'partner',
  cep: 'cep',
  street: 'street',
  neighborhood: 'neighborhood',
  city: 'city',
  state: 'state',
  number: 'number',
  complement: 'complement',
  name: 'owner-name',
  cpf: 'owner-cpf',
  ownerPhone: 'owner-phone',
  validatePhone: 'validate-phone',
  username: 'username',
  email: 'email',
  confirmEmail: 'confirm-email',
  validateEmail: 'validate-email',
  news: 'news',
  terms: 'terms',
  feeType: 'feeType',
  feeId: 'feeId',
  installment: 'installment',
  website: 'website',
  platformId: 'platformId',
  platform: 'platform',
  docDeclaration: 'docDeclaration',
  docSocial: 'docSocial',
  sendDocuments: 'sendDocuments',
  segment: 'segment',
  receiveAdvance: 'receiveAdvance',
  birthday: 'birthday',
  alias: 'alias'
};

export const initialValues = {
  [fieldNames.cnpj]: '',
  [fieldNames.social]: '',
  [fieldNames.partnership]: '',
  [fieldNames.cep]: '',
  [fieldNames.street]: '',
  [fieldNames.neighborhood]: '',
  [fieldNames.city]: '',
  [fieldNames.state]: '',
  [fieldNames.number]: '',
  [fieldNames.complement]: '',
  [fieldNames.name]: '',
  [fieldNames.cpf]: '',
  [fieldNames.ownerPhone]: '',
  [fieldNames.validatePhone]: '',
  [fieldNames.username]: '',
  [fieldNames.email]: '',
  [fieldNames.confirmEmail]: '',
  [fieldNames.validateEmail]: '',
  [fieldNames.news]: 'true',
  [fieldNames.terms]: '',
  [fieldNames.feeType]: '',
  [fieldNames.feeId]: '',
  [fieldNames.installment]: '',
  [fieldNames.website]: '',
  [fieldNames.segment]: '',
  [fieldNames.birthday]: '',
  [fieldNames.alias]: ''
};
