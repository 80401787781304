import api from '../api';

import endpoints from 'services/endpoints';
import { removeSpecialCharacters } from 'utils/masks';
import { Data } from 'pages/Team/AddMember/types';
import { GetMemberResponse, GetMemberGroupResponse } from './types';

export async function getAddress(cep: string) {
  const url = endpoints.accreditationCep.replace(
    ':cep',
    removeSpecialCharacters(cep)
  );

  const result = await api.get(url);

  return result.data;
}

export async function getProfilePermissions(id: string) {
  const url = endpoints.profilePermissions.replace('{profileId}', id);

  const result = await api.get(url);

  return result.data;
}

export async function registerMember(data: Data) {
  const url = endpoints.registerMember;

  const result = await api.post(url, data);

  return result.data;
}

export async function editMember(
  id: string,
  data: Pick<GetMemberResponse, 'name' | 'birthday' | 'active'>
) {
  const url = endpoints.member.replace('{memberId}', id);

  const result = await api.put(url, data);

  return result.data;
}

export async function editMemberGroup(
  id: string,
  data: GetMemberGroupResponse
) {
  const url = endpoints.memberGroup.replace('{memberId}', id);

  const result = await api.put(url, data);

  return result.data;
}

export async function editMemberPermissions(id: string, data: string[]) {
  const url = endpoints.memberPermissions.replace('{memberId}', id);

  const result = await api.put(url, data);

  return result.data;
}
