import useRequest from 'hooks/useRequest';
import endpoints from 'services/endpoints';
import { Platform } from 'pages/Accreditation/shared/form';

interface PlatformOption extends Platform {
  value: string;
  label: string;
}

function getOptions(data?: Platform[]): PlatformOption[] | undefined {
  return data?.map(platform => ({
    ...platform,
    label: platform.platformName,
    value: platform.platformAcronym
  }));
}

export function usePlatforms() {
  const { data, ...rest } = useRequest<Platform[]>({
    url: endpoints.accreditationPlatforms
  });

  return {
    ...rest,
    data: getOptions(data)
  };
}
