import { useCallback, useState } from 'react';

import { ReactComponent as Spinner } from 'assets/images/pix/spinner.svg';

import { Flex, Text, CodeInput, TimeText, Button } from 'components';
import Divider from 'styles/shared/Divider';

import useTimer from 'hooks/useTimer';

type ValidationCodeProps = {
  changeValue: (value: string) => void;
  sendCodeValidation: () => void;
  onBackButton: () => void;
  description: string;
  loading: boolean;
};

export default function ValidationCode({
  changeValue,
  sendCodeValidation,
  onBackButton,
  description,
  loading
}: ValidationCodeProps) {
  const [code, setCode] = useState<string>('');
  const { time, restartTimer } = useTimer({ decrement: true });

  const resendCode = useCallback(() => {
    restartTimer();
    sendCodeValidation();
  }, [restartTimer, sendCodeValidation]);

  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        Código de Validação
      </Text>

      <Text tag="p" size="small" mt="small">
        {description}
      </Text>

      <Divider size="medium" />

      <Flex
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
        mb="medium"
        ml={loading ? 'large' : undefined}
      >
        <CodeInput
          name="code"
          onChangeValue={value => setCode(value)}
          onMatch={value => changeValue(value)}
          value={code}
        />

        {loading && (
          <>
            <Divider direction="horizontal" data-testid="loading" />
            <Spinner />
          </>
        )}
      </Flex>

      <Flex justifyContent="center" direction="column" alignItems="center">
        <TimeText time={time} resendCode={resendCode} />
        <Button tertiary center onClick={onBackButton}>
          Voltar
        </Button>
      </Flex>
    </>
  );
}
