import PATHS_ROUTES from 'routes/paths';

export const breadcrumbItems = [
  {
    link: PATHS_ROUTES.APP.PIX.DEFAULT,
    title: 'Pix'
  },
  {
    link: PATHS_ROUTES.APP.PIX.KEYS.REGISTER,
    title: 'Cadastrar chave Pix'
  },
  {
    link: '#',
    title: 'Número de celular'
  }
];
