import * as Yup from 'yup';
import { FieldNames, PartnershipTypes } from 'pages/Accreditation/shared/form';

export type StepProps = {
  partnershipTypes: PartnershipTypes;
  names: FieldNames;
  hasSession?: boolean;
  goToPreviousStep: () => void;
  goToNextStep: () => void;
  goToNextPage: () => void;
  onFinish?: () => void;
};

export type AccreditationData = {
  pageLabels: string[];
  pages: Pages;
  pagePercent: number;
  allSteps: PageStep[];
  currentPage: Page;
  currentStep: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  isFirstStep: boolean;
  isLastStep: boolean;
};

export type AccreditationActions = {
  goToNextPage(): void;
  goToNextStep(): void;
  goToPreviousStep(): void;
  goToPreviousPage(): void;
};

export type AccreditationSteps = AccreditationData & AccreditationActions;

export type PageStep = {
  Step: (props: StepProps) => JSX.Element;
  validation?: Yup.AnySchema;
};

export type Page = {
  label: string;
  steps: PageStep[];
};

export type Pages = Page[];

export type Actions = {
  type: Types;
};

export enum Types {
  nextPage,
  nextStep,
  previousStep,
  previousPage
}
