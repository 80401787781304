import { ReactNode } from 'react';

import theme from 'styles/theme';
import { MarginProps } from 'styles/shared/Margin';

import { ThemeColors, ThemeFontSizes, ThemeFontWeights } from 'types/global';

import * as S from './styles';

type Tag = 'p' | 'span' | 'strong' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
type Overflow =
  | 'hidden'
  | 'visible'
  | 'clip'
  | 'scroll'
  | 'auto'
  | 'initial'
  | 'inherit';
type TextOverflow = 'clip' | 'ellipsis' | 'string' | 'initial' | 'inherit';
type WhiteSpace =
  | 'normal'
  | 'nowrap'
  | 'pre'
  | 'pre-wrap'
  | 'pre-line'
  | 'break-spaces';

export type TextProps = {
  tag?: Tag;
  size?: ThemeFontSizes;
  color?: ThemeColors;
  weight?: ThemeFontWeights;
  align?: 'left' | 'center' | 'right';
  lineThrough?: boolean;
  children?: string | ReactNode;
  darkColor?: ThemeColors;
  transform?:
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'none'
    | 'full-width'
    | 'inherit'
    | 'initial'
    | 'unset';
  overflow?: Overflow;
  textOverflow?: TextOverflow;
  whiteSpace?: WhiteSpace;
  maxWidth?: string;
} & MarginProps;

const Text = ({
  tag,
  size = 'medium',
  color = 'blackDark',
  weight = 'regular',
  align = 'left',
  lineThrough = false,
  darkColor,
  transform = 'none',
  overflow = 'visible',
  textOverflow = 'clip',
  whiteSpace = 'normal',
  maxWidth = '100%',
  ...props
}: TextProps) => {
  if (!darkColor && theme.colors[color] === theme.colors.blackDark) {
    darkColor = 'white';
  }

  return (
    <S.Wrapper
      as={tag}
      size={size}
      textColor={color}
      weight={weight}
      align={align}
      lineThrough={lineThrough}
      darkColor={darkColor}
      transform={transform}
      overflow={overflow}
      textOverflow={textOverflow}
      whiteSpace={whiteSpace}
      maxWidth={maxWidth}
      {...props}
    />
  );
};

export default Text;
