import { Text } from 'components';
import styled, { css } from 'styled-components';
import { Column } from 'styles/shared/Grid';

export const Title = styled(Text).attrs({
  tag: 'h1',
  weight: 'bold',
  size: 'xxlarge'
})`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.large};
    margin-bottom: ${theme.spacings.small};
  `}
`;

export const Subtitle = styled(Text).attrs({
  tag: 'h2',
  size: 'xxmedium'
})`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.large};
  `}
`;

export const CardColumn = styled(Column)`
  ${({ theme }) => css`
    margin: ${theme.spacings.small};
  `}
`;

export const CardText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.blue};
  `}
`;
