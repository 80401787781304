import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';
import Card from 'styles/shared/Card';

import { Flex } from 'components';
import { ReactComponent as IconRightNext } from 'assets/images/icon-right-next.svg';

export const CardContainer = styled(Flex)`
  flex-direction: column;

  ${customMedia.greaterThan('medium')`
    flex-direction: row;
  `};
`;

export const AddPixKey = styled(Card)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 10.6rem;
    width: 10.6rem;
    margin-bottom: 0;

    background-color: ${theme.colors.grayNeutralLight};
    cursor: pointer;

    svg {
      color: ${theme.mode === 'light'
        ? theme.colors.grayNeutralDark
        : theme.colors.white};
    }
  `}
`;

export const IconRight = styled(IconRightNext)`
  ${({ theme }) => css`
    color: ${theme.colors.blue};
  `}
`;

export const QRCode = styled.img`
  height: 10.6rem;
  width: 10.6rem;
`;
