import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    b {
      color: ${theme.colors.blue};
    }

    ${customMedia.lessThan('small')`
      svg {
        width: 100%;
      }
    `}
  `}
`;
