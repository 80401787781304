import * as types from './types';
import endpoints from './endpoints.json';
import api from '../api';

export * from './types';

export function getMassiveCredentials(merchantId: string) {
  const url = endpoints.listMassiveCredentials.replace(
    '{merchantId}',
    merchantId
  );

  return api.get<types.GetMassiveCredentialsInfoResponse>(url);
}

export function downloadCredential(fileId: string) {
  const url = endpoints.downloadCredential.replace('{fileId}', fileId);

  return api.get(url);
}

export function getCredentialList() {
  const url = endpoints.credentialList;

  return api.get<types.CredentialListInfoResponse>(url);
}

export function disableCredentials(clientIds: string[]) {
  const url = endpoints.credentialList;

  return api.delete(url, { data: clientIds });
}

export function createCredential(params: types.CreateCredentialBody) {
  const url = endpoints.credentialList;

  return api.post(url, { data: params });
}

export function tutorialData() {
  const url = endpoints.tutorial;

  return api.get(url);
}
