import { ReactNode } from 'react';
import clsx from 'clsx';

import * as S from './styles';

import Card from 'styles/shared/Card';

type Option = {
  value: string;
  description: React.ReactNode | string;
  icon?: ReactNode;
};

export type CardSectionOptionsProps = {
  title: string;
  options: Array<Option>;
  optionSelected: string | string[];
  onOptionSelected: (option: string) => void;
};

export const CardSectionOptions = ({
  title,
  options,
  optionSelected,
  onOptionSelected
}: CardSectionOptionsProps) => (
  <S.Wrapper>
    <p>{title}</p>

    <S.CardOptions>
      {options.map(option => (
        <button
          key={option.value}
          onClick={() => {
            let opt = '';

            if (optionSelected !== option.value) {
              opt = option.value;
            }

            onOptionSelected(opt);
          }}
        >
          <Card
            contentCenter
            className={clsx({
              active:
                typeof optionSelected === 'string'
                  ? optionSelected === option.value
                  : optionSelected.includes(option.value)
            })}
          >
            {option.icon ? option.icon : option.description}
          </Card>
          {option.icon && <span>{option.description}</span>}
        </button>
      ))}
    </S.CardOptions>
  </S.Wrapper>
);

export default CardSectionOptions;
