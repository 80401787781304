const regexUrl =
  // eslint-disable-next-line
  /^((https?):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const checkCPF = (cpf: string) => {
  const newCpf = cpf.replace(/[^\d]+/g, '').padStart(11, '0');

  if (newCpf.length > 11) {
    return false;
  }

  let numeros;
  let digitos;
  let soma;
  let resultado;
  let digitosIguais;
  digitosIguais = 1;

  for (let i = 0; i < newCpf.length - 1; i++)
    if (newCpf.charAt(i) !== newCpf.charAt(i + 1)) {
      digitosIguais = 0;
      break;
    }
  if (!digitosIguais) {
    numeros = newCpf.substring(0, 9);
    digitos = newCpf.substring(9);
    soma = 0;
    for (let i = 10; i > 1; i--) soma += parseInt(numeros.charAt(10 - i)) * i;
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;
    numeros = newCpf.substring(0, 10);
    soma = 0;
    for (let i = 11; i > 1; i--) soma += parseInt(numeros.charAt(11 - i)) * i;
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;
    return true;
  }
  return false;
};

export const checkCNPJ = (cnpj: string) => {
  const newCnpj = cnpj.replace(/[^\d]+/g, '').padStart(14, '0');

  if (newCnpj.length > 14) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (
    newCnpj === '00000000000000' ||
    newCnpj === '11111111111111' ||
    newCnpj === '22222222222222' ||
    newCnpj === '33333333333333' ||
    newCnpj === '44444444444444' ||
    newCnpj === '55555555555555' ||
    newCnpj === '66666666666666' ||
    newCnpj === '77777777777777' ||
    newCnpj === '88888888888888' ||
    newCnpj === '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = newCnpj.length - 2;
  let numeros = newCnpj.substring(0, tamanho);
  const digitos = newCnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0))) return false;

  tamanho += 1;
  numeros = newCnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(1))) return false;

  return true;
};

export const checkPhoneNumber = (phone: string): boolean => {
  const regex = /^\(?([0-9]{2})\)?[- ]?([0-9]{4,5})[- ]?([0-9]{4})$/;
  return regex.test(phone);
};

export const checkCellPhoneNumber = (phone: string): boolean => {
  const regex = /^(?:\()[1-9]{2}(?:\))\s[9][0-9]{4}(?:-)[0-9]{4}$/;
  return regex.test(phone);
};

export const checkCEP = (cep: string): boolean => {
  const regex = /^\(?([0-9]{5})\)?[-]?([0-9]{3})$/;
  return !!cep && regex.test(cep);
};

export const checkEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isDocumentValid = (documentNumber: string): boolean => {
  return checkCPF(documentNumber) || checkCNPJ(documentNumber);
};

export const isImageValid = (filename: string) => {
  return /\.(jpe?g|png)$/i.test(filename);
};

export const isValidUrl = (url: string) => regexUrl.test(url);
