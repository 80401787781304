import { Button, Flex, TemplateModal, Text } from 'components';

import { ReactComponent as OnboardingPixOut } from 'assets/images/pix/onboarding-pixout.svg';

export type FirstAccessProps = {
  handleClose: () => void;
};

const ModalFirstAccess = ({ handleClose }: FirstAccessProps) => {
  return (
    <TemplateModal title="Transferência com chave Pix" closeModal={handleClose}>
      <Flex alignItems="center" direction="column" mx="xlarge">
        <OnboardingPixOut />

        <Text size="xmedium" weight="bold" align="center" mb="medium">
          <Text tag="span" size="xmedium" weight="bold" color="blue">
            Transferir dinheiro
          </Text>{' '}
          ou{' '}
          <Text tag="span" size="xmedium" weight="bold" color="blue">
            fazer pagamentos
          </Text>{' '}
          com chave Pix é tão fácil quanto mandar uma mensagem no celular ;)
        </Text>

        <Text
          size="xsmall"
          tag="p"
          align="center"
          color="grayNeutralDark"
          mx="medium"
          mb="large"
        >
          Encontre a chave desejada ou digite os dados da conta bancária. Ah,
          não esquece de revisar as informações antes de confirmar, ok? Vamos
          lá!
        </Text>

        <Button filled onClick={handleClose}>
          Continuar
        </Button>
      </Flex>
    </TemplateModal>
  );
};

export default ModalFirstAccess;
