import { useContext, useMemo, useState } from 'react';
import useWindowSize from 'hooks/useWindowSize';

import { ReactComponent as IconQRCode } from 'assets/images/icon-qr-code.svg';
import { ReactComponent as IconCopy } from 'assets/images/icon-copy-outline.svg';

import Divider from 'styles/shared/Divider';
import { Button, CardInfo, Flex, Text, Popover } from 'components';
import * as S from './styles';

import moment from 'moment';
import { useAuth } from 'contexts/Auth';
import { copyToClipBoard } from 'utils/copyToClipboard';

import { PixContext } from '../..';

const ShareQRCode = () => {
  const [width] = useWindowSize();
  const pixContext = useContext(PixContext);
  const {
    handlePageNavigation,
    rawQRCodeRequest: rawData,
    pngQRCodeRequest: keyData,
    pixIdentifier
  } = pixContext;
  const { user } = useAuth();
  const { data: qrcode } = keyData.state;
  const [copied, setCopied] = useState(false);

  const encodedQRCode = useMemo(() => {
    if (qrcode && qrcode.data)
      return btoa(
        qrcode.data.reduce(
          (data: string, byte: number) => data + String.fromCharCode(byte),
          ''
        )
      );

    return null;
  }, [qrcode]);

  const rawQRCode = useMemo(() => {
    return rawData.state?.data || '';
  }, [rawData]);

  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        Compartilhe ou mostre o QR Code para o pagador :)
      </Text>

      <Divider size="small" />

      <Flex alignItems="center" justifyContent="center">
        <S.QRCodeWrapper>
          <S.QRCodeTopBlueBorder />

          <S.QRCode src={`data:image/*;base64,${encodedQRCode}`} />

          <S.QRCodeBottomBlueBorder />
        </S.QRCodeWrapper>
      </Flex>

      <Divider size="medium" />

      <Text tag="p" size="medium" align="center">
        {pixIdentifier}
      </Text>

      <Divider size="medium" />

      <CardInfo
        text="Baixar QR Code"
        icon={
          <S.WrapperIcon>
            <IconQRCode />
          </S.WrapperIcon>
        }
        action={() => {
          fetch(`data:image/*;base64,${encodedQRCode}`)
            .then(resp => resp.blob())
            .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const anchorElement = document.createElement('a');

              anchorElement.style.display = 'none';
              anchorElement.href = url;
              anchorElement.download = `${user?.name.replaceAll(
                ' ',
                '-'
              )}-${moment().format('YYYY-MM-DD')}.png`;

              document.body.appendChild(anchorElement);
              anchorElement.click();
              window.URL.revokeObjectURL(url);
            });
        }}
      />

      <S.PopoverWrapper>
        <Popover
          direction={width < 768 ? 'bottom' : 'right'}
          text="Texto Copiado!"
          show={copied}
        >
          <CardInfo
            text="Copiar código Pix"
            icon={
              <S.WrapperIcon>
                <IconCopy />
              </S.WrapperIcon>
            }
            action={() => {
              copyToClipBoard(setCopied, rawQRCode);
            }}
          />
        </Popover>
      </S.PopoverWrapper>

      <Divider size="small" />

      <Button
        center
        filled
        filledBgColor="transparent"
        filledColor="blue"
        onClick={() => handlePageNavigation(0)}
      >
        Gerar novo QR Code
      </Button>
    </>
  );
};

export default ShareQRCode;
