import React from 'react';
import { Button, Input, Text } from 'components';
import Divider from 'styles/shared/Divider';

const CNPJInput = ({
  documentInputValue,
  handleSetProceedWithRegistration
}: {
  documentInputValue: string;
  handleSetProceedWithRegistration: () => void;
}) => {
  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        Cadastrar chave com o número do seu CNPJ
      </Text>

      <Divider size="medium" />

      <Input
        name="CNPJ"
        label="Seu CNPJ"
        maskType="CNPJ"
        onlyNumbers
        value={documentInputValue}
        placeholder="00.000.000/0000-00"
        disabled
      />

      <Divider size="large" />

      <Button
        filled
        center
        onClick={handleSetProceedWithRegistration}
        disabled={!documentInputValue}
        role="button-proceed-with-registration"
      >
        Cadastrar Chave Pix
      </Button>
    </>
  );
};

export default CNPJInput;
