import moment from 'moment';
import 'moment/locale/pt-br';
import VMasker from 'vanilla-masker';
import { COUNTRY_CODES, CountryCodesT } from 'utils/constants';

moment.locale('pt-BR');

export const cpfMask = (value: string | number): string => {
  return VMasker.toPattern(value, '999.999.999-99');
};

export const cnpjMask = (value: string | number): string => {
  return VMasker.toPattern(value, '99.999.999/9999-99');
};

export const lowerLettersMask = (value: string): string => {
  const maskedValueArray: string[] | null = value
    .trim()
    .toLowerCase()
    .match(/[a-z]/g);

  return maskedValueArray?.length ? maskedValueArray.join('') : '';
};

export const minifyLettersMask = (value: string): string => {
  const maskedValueArray = value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s/g, '')
    .replace(/[^a-zA-Z0-9]/g, '');

  return maskedValueArray;
};

export const lowerAlphanumericMask = (value: string): string => {
  const maskedValueArray: string[] | null = value
    .trim()
    .toLowerCase()
    .match(/[a-z0-9]/g);

  return maskedValueArray?.length ? maskedValueArray.join('') : '';
};

export const maskDocument = (
  documentNumber: string | number,
  documentType: string
): string => {
  return documentType === 'CPF'
    ? cpfMask(documentNumber)
    : cnpjMask(documentNumber);
};

export const cepMask = (value: string | number): string => {
  return VMasker.toPattern(value, '99999-999');
};

export const dateMask = (value: string | number): string => {
  return VMasker.toPattern(value, '99/99/9999');
};

export const maskOnlyNumbers = (value: string | number): string => {
  return VMasker.toNumber(value).replace(/[^\d]/g, '');
};

export const maskPhoneNumber = (phone: string | number): string => {
  const mask =
    VMasker.toNumber(phone).length === 10
      ? '(99) 9999-9999'
      : '(99) 99999-9999';

  return VMasker.toPattern(phone, mask);
};

export const maskCellPhoneNumber = (phone: string | number): string => {
  const mask = '(99) 99999-9999';

  return VMasker.toPattern(phone, mask);
};

export const maskDynamicDocument = (value: string | number): string => {
  const mask =
    VMasker.toNumber(value).length <= 11
      ? '999.999.999-99'
      : '99.999.999/9999-99';

  return VMasker.toPattern(value, mask);
};

export const formatCurrency = (value: string | number, showUnit = true) => {
  const options = {
    precision: 2,
    separator: ',',
    delimiter: '.',
    unit: showUnit ? 'R$ ' : ''
  };

  const negative = Number(value) < 0;

  return `${negative ? '- ' : ''}${VMasker.toMoney(value, options)}`;
};

export const formatCentsToMoney = (value: string | number) => {
  return parseFloat(maskOnlyNumbers(value)) / 100;
};

export const formatAccount = (value: string): string => {
  const valueNumbers = maskOnlyNumbers(value.replace(/-/g, ''));

  if (valueNumbers.trim().length > 1) {
    return (
      valueNumbers.substr(0, valueNumbers.length - 1) +
      '-' +
      valueNumbers.substr(-1)
    );
  }

  return valueNumbers;
};

export const formatInput = {
  CPF: cpfMask,
  CNPJ: cnpjMask,
  DATE: dateMask,
  PHONE: maskPhoneNumber,
  CELLPHONE: maskCellPhoneNumber,
  CURRENCY: formatCurrency,
  CEP: cepMask,
  ACCOUNT: formatAccount,
  DOCUMENT: maskDynamicDocument,
  LOWER_LETTERS: lowerLettersMask,
  LOWER_ALPHANUMERIC: lowerAlphanumericMask
};

export const invertDate = (value: string): string => {
  if (value.length === 10) {
    const numbersDate = value.split('/');
    return `${numbersDate[2]}-${numbersDate[1]}-${numbersDate[0]}`;
  }

  return '';
};

export const formatSimpleDate = (
  date: string | Date,
  format = 'DD/MM/YYYY'
) => {
  return moment(date).format(format);
};

export const getInitialsNameAndSurname = (name: string) => {
  let initials = '';
  const nameSplit = name.split(' ');
  const arrSize = nameSplit.length;

  initials = nameSplit[0].charAt(0);

  if (arrSize > 1) {
    initials += nameSplit[arrSize - 1].charAt(0);
  }

  return initials.toUpperCase();
};

export const removeSpecialCharacters = (value: string) => {
  return value.replace(/[^\w\s]/gi, '');
};

export const removeMoneyFormat = (value: string) => {
  return value.replace('R$ ', '').replace(',', '').replace(/\./g, '');
};

export const getCodeAndMaskByPhoneNumber = (phoneNumber: string) => {
  let phone = phoneNumber;
  let mask =
    VMasker.toNumber(phone).length === 10
      ? '(99) 9999-9999'
      : '(99) 99999-9999';
  let code = '+55';

  if (phone.includes('+')) {
    let country: CountryCodesT;

    const filteredCountry = COUNTRY_CODES.filter(item =>
      phone.includes(item.code)
    );
    if (filteredCountry.length) {
      country = filteredCountry[0];
      code = country.code;
      phone = phone.replace(code, '');

      if (typeof country.mask === 'string') {
        mask = country.mask.replaceAll('#', '9');
      } else {
        for (let index = 0; index < country.mask.length; index++) {
          const maskReplaced = country.mask[index].replaceAll('#', '9');
          if (maskOnlyNumbers(maskReplaced).length === phone.length) {
            mask = maskReplaced;
            break;
          }
        }
      }
    }
  }

  return [code, mask];
};

export const formatMask = (value: string | number, mask: string): string => {
  return VMasker.toPattern(value, mask);
};
