import Card from 'styles/shared/Card';
import * as S from './styles';

export type CardIconProps = {
  icon: JSX.Element;
  label?: string;
  onClick: () => void;
};

export const CardIcon = ({ icon, label = '', onClick }: CardIconProps) => (
  <S.Wrapper onClick={onClick}>
    <Card>{icon}</Card>
    {label && <span>{label}</span>}
  </S.Wrapper>
);

export default CardIcon;
