import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Column, Row } from 'styles/shared/Grid';
import { Divider } from 'styles/shared/Divider';

import {
  Button,
  CardAccount,
  ProfilePicture,
  TemplateEditContent,
  TemplateShortContent,
  Text,
  Toggle,
  Input,
  Loading,
  ModalStatus
} from 'components';

import { useMember } from 'contexts/Team/Member';
import { useMemberGroup } from 'contexts/Team/MemberGroup';
import { useAuth } from 'contexts/Auth';

import useToggle from 'hooks/useToggle';

import PATHS_ROUTES from 'routes/paths';

import * as S from './styles';

import { GetMemberResponse } from 'services/memberService/types';
import { forgotPassword } from 'services/sessionService';
import { ForgotPasswordProps } from 'services/sessionService/types';
import { editMember } from 'services/memberService';

type ParamsTypes = {
  id: string;
};

import { formatSimpleDate } from 'utils/masks';

const TeamMember = () => {
  const [editing, setEditing] = useState(false);
  const [sendPasswordEmail, setSendPasswordEmail] = useState(true);

  const { member } = useMember();
  const { memberGroup } = useMemberGroup();
  const { permissions } = useAuth();

  const modalPasswordSuccess = useToggle();
  const modalPasswordError = useToggle();
  const modalEditingError = useToggle();

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [active, setActive] = useState<boolean | undefined>(undefined);
  const [birthday, setBirthday] = useState('');

  const { id } = useParams<ParamsTypes>();

  useEffect(() => {
    if (member) {
      setName(member.name);
      setActive(member.active);
      setBirthday(member.birthday);
    }
  }, [member]);

  const handleForgotPassword = async ({ username }: ForgotPasswordProps) => {
    try {
      await forgotPassword({ username });
      modalPasswordSuccess.toggle();
      setSendPasswordEmail(false);
    } catch (e) {
      modalPasswordError.toggle();
      setSendPasswordEmail(true);
    }
  };

  const handleSubmit = async () => {
    if (member && (name || typeof active === 'boolean')) {
      try {
        const data: Pick<GetMemberResponse, 'name' | 'birthday' | 'active'> = {
          name: name || member.name,
          birthday,
          active: !!active
        };
        await editMember(id as string, data);
        setEditing(false);
        navigate(PATHS_ROUTES.APP.TEAM.MEMBERS);
      } catch (err) {
        modalEditingError.toggle();
      }
    }
  };

  const content = member && memberGroup && (
    <>
      <S.ProfilePictureWrapper>
        <ProfilePicture
          size="large"
          name={member.name}
          image={member?.profilePicture}
        />

        <Text size="small" color="grayDark">
          Criado em {formatSimpleDate(member?.createdAt)}
        </Text>
      </S.ProfilePictureWrapper>

      <Divider size="large" />

      <Row>
        <Column xs={12}>
          <Input
            name="name"
            disabled={!editing}
            label="Nome"
            value={name}
            onChange={e => setName(e.target.value)}
          />

          <Input name="login" disabled label="Login" value={member.username} />

          <Input
            name="password"
            disabled
            action={() =>
              handleForgotPassword({
                username: member.username
              })
            }
            actionChildren={
              sendPasswordEmail && permissions?.includes('MEMBROS_EDITAR')
                ? 'Redefinir'
                : ''
            }
            label="Senha"
            value={sendPasswordEmail ? '*******' : 'Redefinida'}
          />

          <Input
            name="permissions"
            disabled
            action={() =>
              navigate(PATHS_ROUTES.APP.TEAM.PERMISSIONS(member.id))
            }
            actionChildren={
              permissions?.includes('MEMBROS_EDITAR') ? 'Editar' : ''
            }
            label="Permissionamento"
            value={member.userTypeLabel}
          />
        </Column>
      </Row>

      <Row>
        <Column xs={12}>
          <Text size="xsmall" weight="medium">
            Status
          </Text>

          <S.StatusWrapper>
            <Text color={active ? 'blue' : 'grayDark'} size="xmedium">
              {active ? 'Ativo' : 'Inativo'}
            </Text>

            {editing && (
              <Toggle
                onCheck={() => editing && setActive(!active)}
                isChecked={!!active}
              />
            )}
          </S.StatusWrapper>
        </Column>
      </Row>

      <Divider size="large" />

      {editing && (
        <>
          <Row>
            <Column offsetXs={2} xs={8}>
              <Button fullWidth filled onClick={handleSubmit}>
                Concluir
              </Button>
            </Column>
          </Row>

          <Divider size="large" />
        </>
      )}

      <Row>
        <Column xs={12}>
          <Text size="xmedium" weight="medium">
            Matriz
          </Text>

          <Text size="small" color="grayDark">
            Empresa responsável
          </Text>

          <Divider size="small" />

          <CardAccount
            title={memberGroup.groupName}
            document={memberGroup.document}
            profileType={member.userType}
          />
        </Column>
      </Row>

      {modalPasswordSuccess.isActive && (
        <ModalStatus
          variant="success"
          title="Redefinição de senha"
          onClose={modalPasswordSuccess.toggle}
          description="Será enviado um e-mail para o usuário, com as instruções necessárias para
          alterar a senha."
          action={{
            asLink: true,
            filled: false,
            tertiary: true,
            text: 'Ir para o início',
            to: PATHS_ROUTES.AUTH.LOGIN
          }}
        >
          <Text size="large" weight="bold" align="center">
            E-mail enviado <br /> com sucesso{' '}
            <Text size="large" weight="bold" tag="span" color="blue">
              ;)
            </Text>
          </Text>
        </ModalStatus>
      )}
      {modalPasswordError.isActive && (
        <ModalStatus
          variant="error"
          title="Redefinição de senha"
          onClose={modalPasswordError.toggle}
          description="Não foi possível enviar o e-mail de redefinição de senha."
          action={{
            asLink: true,
            filled: false,
            tertiary: true,
            text: 'Ir para o início',
            to: PATHS_ROUTES.AUTH.LOGIN
          }}
        >
          <Text size="large" weight="bold" align="center">
            Não foi possível enviar <br /> o e-mail de redefinição de senha.
          </Text>
        </ModalStatus>
      )}
      {modalEditingError.isActive && (
        <ModalStatus
          variant="error"
          title="Editar membro"
          onClose={modalEditingError.toggle}
          description="Não foi possível editar o membro"
          action={{
            asLink: true,
            filled: false,
            tertiary: true,
            text: 'Ir para o início',
            to: PATHS_ROUTES.AUTH.LOGIN
          }}
        >
          <Text size="large" weight="bold" align="center">
            Não foi possível editar <br /> o membro.
          </Text>
        </ModalStatus>
      )}
    </>
  );

  if (!member || !memberGroup) {
    return <Loading />;
  } else {
    if (permissions?.includes('MEMBROS_EDITAR')) {
      return (
        <TemplateEditContent
          backLink={PATHS_ROUTES.APP.TEAM.MEMBERS}
          backTitle="Voltar para Membros"
          onEdit={() => setEditing(!editing)}
          editing={editing}
        >
          {content}
        </TemplateEditContent>
      );
    } else {
      return (
        <TemplateShortContent
          backLink={PATHS_ROUTES.APP.TEAM.MEMBERS}
          backTitle="Voltar para Membros"
        >
          {content}
        </TemplateShortContent>
      );
    }
  }
};

export default TeamMember;
