import * as React from 'react';
import PATHS_ROUTES from 'routes/paths';

import { ReactComponent as IconMenuHome } from 'assets/images/menu/icon-home-outline.svg';
import { ReactComponent as IconMenuHomeActive } from 'assets/images/menu/icon-home-active.svg';
import { ReactComponent as IconMenuStatement } from 'assets/images/menu/icon-statement-outline.svg';
import { ReactComponent as IconMenuStatementActive } from 'assets/images/menu/icon-statement-active.svg';
import { ReactComponent as IconMenuSell } from 'assets/images/menu/icon-sell-outline.svg';
import { ReactComponent as IconMenuSellActive } from 'assets/images/menu/icon-sell-active.svg';
// import { ReactComponent as IconMenuPromotion } from 'assets/images/menu/icon-promotion-outline.svg';
// import { ReactComponent as IconMenuPromotionActive } from 'assets/images/menu/icon-promotion-active.svg';
// import { ReactComponent as IconMenuIntegration } from 'assets/images/menu/icon-integration-outline.svg';
// import iconMenuIntegrationActive from 'assets/images/menu/icon-integration-active.svg';
import { ReactComponent as IconMenuTeams } from 'assets/images/menu/icon-team-outline.svg';
import { ReactComponent as IconMenuTeamsActive } from 'assets/images/menu/icon-team-active.svg';
import { ReactComponent as IconMenuAccounts } from 'assets/images/menu/icon-accounts-outline.svg';
import { ReactComponent as IconMenuAccountsActive } from 'assets/images/menu/icon-accounts-active.svg';
import { ReactComponent as IconMenuAccount } from 'assets/images/menu/icon-account-outline.svg';
import { ReactComponent as IconMenuAccountActive } from 'assets/images/menu/icon-account-active.svg';
import { ReactComponent as IconMenuPix } from 'assets/images/menu/icon-pix-outline.svg';
import { ReactComponent as IconMenuPixActive } from 'assets/images/menu/icon-pix-active.svg';
import { ReactComponent as IconLogoutOutline } from 'assets/images/menu/icon-logout-outline.svg';
import { ReactComponent as IconReport } from 'assets/images/menu/icon-report-outline.svg';
import { ReactComponent as IconReportActive } from 'assets/images/menu/icon-report-active.svg';
import { ReactComponent as IconIntegration } from 'assets/images/menu/icon-integration-outline.svg';
import { ReactComponent as IconIntegrationActive } from 'assets/images/menu/icon-integration-active.svg';
import { ReactComponent as IconTraining } from 'assets/images/menu/icon-services-tech-outline.svg';
import { ReactComponent as IconTrainingActive } from 'assets/images/menu/icon-services-tech-solid.svg';
// import { ReactComponent as IconReport } from 'assets/images/menu/icon-report-outline.svg';
// import { ReactComponent as IconReportActive } from 'assets/images/menu/icon-report-active.svg';
import { ReactComponent as IconMenuDeposit } from 'assets/images/menu/icon-deposit.svg';
import { ReactComponent as IconMenuDepositActive } from 'assets/images/menu/icon-deposit-active.svg';

import { useAuth } from 'contexts/Auth';

export type Categories =
  | 'Controle financeiro'
  | 'Venda com Ame'
  | 'Minha empresa';

export const categories: { [key: string]: Categories } = {
  financialControl: 'Controle financeiro',
  sellWithAme: 'Venda com Ame',
  myCompany: 'Minha empresa'
};

export type ItemPosition = 'top' | 'bottom';

export type Item = {
  id: string;
  name: string;
  link: string;
  activationPath?: string;
  images: JSX.Element[];
  position?: ItemPosition;
  onClick?: () => void;
  category?: Categories;
  showIfIsAdmin?: boolean;
};

export type Items = Item & {
  hasSubMenu: boolean;
  subMenuItems?: Item[];
  activeMatch?: string[];
  hiddenWhenNotActive?: boolean;
};

export const items: Items[] = [
  {
    id: 'RESUMO',
    name: 'Resumo',
    link: PATHS_ROUTES.APP.DASHBOARD.DEFAULT,
    images: [<IconMenuHome key={1} />, <IconMenuHomeActive key={2} />],
    hasSubMenu: false,
    activeMatch: ['minhas-contas', 'GRUPO_DE_CONTAS', 'MEMBROS'],
    subMenuItems: [
      {
        id: 'RESUMO_MINHAS_CONTAS',
        name: 'Minhas contas',
        link: PATHS_ROUTES.APP.ACCOUNTS.DEFAULT,
        images: [
          <IconMenuAccounts key={1} />,
          <IconMenuAccountsActive key={2} />
        ]
      },
      {
        id: 'RESUMO_EQUIPE',
        name: 'Equipe',
        link: PATHS_ROUTES.APP.TEAM.DEFAULT,
        images: [<IconMenuTeams key={1} />, <IconMenuTeamsActive key={2} />]
      }
    ]
  },
  {
    id: 'MINHA_CONTA',
    name: 'Minha conta',
    link: PATHS_ROUTES.APP.ACCOUNT.PROFILE,
    images: [<IconMenuAccount key={1} />, <IconMenuAccountActive key={2} />],
    hasSubMenu: false
  },
  {
    id: 'RELATORIOS',
    name: 'Relatórios',
    link: PATHS_ROUTES.APP.REPORT.DEFAULT,
    images: [<IconReport key={1} />, <IconReportActive key={2} />],
    hasSubMenu: false
  },
  {
    id: 'PIX',
    name: 'PIX',
    link: PATHS_ROUTES.APP.PIX.DEFAULT,
    images: [<IconMenuPix key={1} />, <IconMenuPixActive key={2} />],
    hasSubMenu: false
  },
  {
    id: 'DEPOSITO',
    name: 'Depósito',
    link: PATHS_ROUTES.APP.DEPOSIT,
    images: [<IconMenuDeposit key={1} />, <IconMenuDepositActive key={2} />],
    hasSubMenu: false,
    showIfIsAdmin: true
  },
  {
    id: 'APLICATIVOS_CONTROLE',
    name: 'Aplicativos',
    link: PATHS_ROUTES.APP.MINI_APP.DEFAULT,
    activationPath: PATHS_ROUTES.APP.MINI_APP.ACTIVATION_PATH,
    images: [<IconIntegration key={1} />, <IconIntegrationActive key={2} />],
    hasSubMenu: false,
    //desabilita o menu Aplicativos no ambiente produtivo, até que a plataforma tenha um app inicial
    hiddenWhenNotActive: process.env.REACT_APP_AME_ENV === 'prod'
  },
  {
    id: 'TREINAMENTOS_PADRAO',
    name: 'Treinamentos',
    link: PATHS_ROUTES.APP.TRAINING.HOME,
    images: [<IconTraining key={1} />, <IconTrainingActive key={2} />],
    hasSubMenu: false
  },
  {
    id: 'INTEGRACAO',
    name: 'Integração',
    link: PATHS_ROUTES.APP.INTEGRATION.DEFAULT,
    images: [
      <IconMenuStatement key={1} />,
      <IconMenuStatementActive key={2} />
    ],
    hasSubMenu: false
  },
  {
    id: 'EXTRATO',
    name: 'Extrato',
    link: PATHS_ROUTES.APP.STATEMENT.LIST,
    images: [
      <IconMenuStatement key={1} />,
      <IconMenuStatementActive key={2} />
    ],
    hasSubMenu: false,
    category: categories.financialControl
  },
  {
    id: 'VENDER',
    name: 'Gerar QR Code',
    link: PATHS_ROUTES.APP.SELL,
    images: [<IconMenuSell key={1} />, <IconMenuSellActive key={2} />],
    hasSubMenu: false,
    category: categories.sellWithAme
  },
  // {
  //   id: 'PROMOCOES',
  //   name: 'Promoções',
  //   link: PATHS_ROUTES.APP.PROMOTION,
  //   images: [
  //     <IconMenuPromotion key={1} />,
  //     <IconMenuPromotionActive key={2} />
  //   ],
  //   hasSubMenu: false
  // },
  {
    id: 'MEMBROS',
    name: 'Membros',
    link: PATHS_ROUTES.APP.TEAM.MEMBERS,
    images: [<IconMenuTeams key={1} />, <IconMenuTeamsActive key={2} />],
    hasSubMenu: false,
    category: categories.myCompany
  },
  {
    id: 'LOGOUT',
    name: 'Sair',
    link: '#',
    images: [<IconLogoutOutline key={1} />, <IconLogoutOutline key={2} />],
    hasSubMenu: false,
    position: 'bottom'
  }
];

export const useMenuItems = (position: ItemPosition) => {
  const { signOut, permissions, user } = useAuth();
  const isAdmin = user?.userType === 'ADMIN';

  return React.useMemo(() => {
    const menuItems = [...items];
    const menuLogoutIndex = menuItems.findIndex(({ id }) => id === 'LOGOUT');
    const menuLogout = menuItems[menuLogoutIndex];
    menuItems[menuLogoutIndex] = { ...menuLogout, onClick: signOut };

    return menuItems.filter(
      item =>
        ((item.position ?? 'top') === position &&
          permissions?.includes(item.id)) ||
        ((item.position ?? 'top') === position && item.showIfIsAdmin && isAdmin)
    );
  }, [signOut, position, permissions, isAdmin]);
};
