import { ReactNode } from 'react';

import * as S from './styles';
import { Column, Container, Row } from 'styles/shared/Grid';

import { TemplateAuth } from 'components';

export type AuthCardLayoutProps = {
  children: ReactNode;
};

const AuthCardLayout = ({ children }: AuthCardLayoutProps) => (
  <TemplateAuth>
    <Container>
      <Row justifyContentXs="center">
        <Column xs={10}>
          <S.Card>{children}</S.Card>
        </Column>
      </Row>
    </Container>
  </TemplateAuth>
);

export default AuthCardLayout;
