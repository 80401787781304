/* eslint-disable @typescript-eslint/no-unused-vars */
import { ThemeColors } from 'types/global';
import Text from 'components/Text';
import Money from 'components/Money';

import * as S from './styles';

export type CapturedValueInfoProps = {
  title: string;
  value: number;
  color?: ThemeColors;
  lineThrough?: boolean;
};

const CapturedValueInfo = ({
  title,
  value,
  color = 'blue',
  lineThrough = false
}: CapturedValueInfoProps) => (
  <S.Wrapper>
    <S.LabelsWrapper>
      <Text size="xsmall">{title}</Text>
    </S.LabelsWrapper>
    <S.ValuesWrapper>
      <Money value={value} tag="span" color={color} lineThrough={lineThrough} />
    </S.ValuesWrapper>
  </S.Wrapper>
);

export default CapturedValueInfo;
