import React, { useContext } from 'react';
import { Button, CardAccordionPix, Text } from 'components';
import Divider from 'styles/shared/Divider';
import { IDictKeyData } from 'services/pixService/types';
import { PixContext } from '../..';

const ConfirmPixKey = ({ pixKey }: { pixKey?: IDictKeyData }) => {
  const pixContext = useContext(PixContext);
  const { handlePageNavigation } = pixContext;

  return (
    <>
      <Text tag="h2" weight="bold" size="large">
        Confirme os dados da sua chave :)
      </Text>

      <Divider size="small" />

      {!!pixKey && (
        <CardAccordionPix
          key={pixKey.key}
          date={pixKey.createdAt}
          keyType={pixKey.keyType}
          keyValue={pixKey.key}
        />
      )}

      <Divider size="medium" />

      <Button center filled onClick={() => handlePageNavigation(2)}>
        Os dados estão corretos
      </Button>

      <Divider size="small" />

      <Button
        center
        filled
        filledBgColor="transparent"
        filledColor="blue"
        onClick={() => handlePageNavigation(0)}
        data-testid="button-confirm-pix-key"
      >
        Alterar chave
      </Button>
    </>
  );
};

export default ConfirmPixKey;
