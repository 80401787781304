import { ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';
import { Link, Text } from 'components';
import * as S from './styles';

export type CardLinkProps = {
  title: string;
  subtitle?: string;
  filled?: boolean;
  center?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
  externalLink?: boolean;
} & Partial<Pick<LinkProps, 'to' | 'target'>>;

export const CardLink = ({
  title,
  subtitle,
  to,
  filled,
  center,
  children,
  icon,
  target,
  externalLink
}: CardLinkProps) => {
  const content = (
    <>
      <div className="column-left">
        {icon && <S.IconWrapper>{icon}</S.IconWrapper>}

        <div>
          <Text tag="h5" color="blackDark" size="medium" weight="medium">
            {title}
          </Text>

          {subtitle && (
            <Text tag="span" color="grayDark" size="xsmall">
              {subtitle}
            </Text>
          )}
        </div>
      </div>

      {!!children && <div className="column-right">{children}</div>}
    </>
  );

  return (
    <>
      {externalLink ? (
        <S.Wrapper filled={filled} center={center}>
          <a target={target} href={typeof to === 'string' ? to : '#'}>
            {content}
          </a>
        </S.Wrapper>
      ) : (
        <S.Wrapper
          as={Link}
          to={to ?? '#'}
          filled={filled}
          center={center}
          target={target}
        >
          {content}
        </S.Wrapper>
      )}
    </>
  );
};

export default CardLink;
