import { formatCurrency } from 'utils/masks';

import { ReactComponent as SvgIconUpCash } from 'assets/images/icon-up-cash.svg';
import { ReactComponent as SvgIconDownCash } from 'assets/images/icon-down-cash.svg';

import * as S from './styles';

export type CardBalanceProps = {
  icon: JSX.Element;
  title: string;
  totalAmount: number;
  totalType?: 'up' | 'down';
  description: string;
  active?: boolean;
  rightAction?: React.ReactNode;
};

const CardBalance = ({
  icon,
  title,
  totalAmount,
  totalType = 'up',
  description,
  active,
  rightAction
}: CardBalanceProps) => (
  <S.Wrapper active={active}>
    <S.HeaderInfo>
      <h5>
        {icon}

        {title}
      </h5>

      {rightAction}
    </S.HeaderInfo>

    <S.FooterInfo>
      <h3>
        <span>R$</span> {formatCurrency(totalAmount, false)}
        {totalType === 'up' ? <SvgIconUpCash /> : <SvgIconDownCash />}
      </h3>

      <p>{description}</p>
    </S.FooterInfo>
  </S.Wrapper>
);

export default CardBalance;
