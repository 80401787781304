import styled, { css } from 'styled-components';
import Card from 'styles/shared/Card';
import { Link } from 'react-router-dom';
import { rgba } from 'polished';

type WrapperProps = {
  abbreviated?: boolean;
};

const wrapperModifiers = {
  abbreviated: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `
};

export const Wrapper = styled(Card)<WrapperProps>`
  ${({ theme, abbreviated }) => css`
    max-width: 46.3rem;
    padding: ${theme.spacings.small};
    ${abbreviated && wrapperModifiers.abbreviated}
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    width: 4.3rem;
    height: 4.3rem;
    margin-right: 1rem;
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${rgba(theme.colors.blueLightest, 0.1)};

    & svg {
      color: ${theme.colors.blueLightest};
    }
  `}
`;

export const TeamName = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.blackDark};
    font-weight: ${theme.font.weights.bold};
  `}
`;

export const SeeMoreLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: 0.4rem;
    margin-right: 0.6rem;
    color: ${theme.colors.blueLightest};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.weights.bold};

    & svg {
      height: 2.4rem;
      width: 2.4rem;
      color: ${theme.colors.blueLightest};
    }
  `}
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-left: 0.6rem;

  & div {
    height: 4.2rem;
  }

  &:first-child {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const UserName = styled.p`
  ${({ theme }) => css`
    margin-left: 1rem;
    color: ${theme.colors.blackDark};
    font-size: ${theme.font.sizes.medium};

    & span {
      color: ${theme.colors.blueLightest};
    }
  `}
`;
