import * as Yup from 'yup';
import { fieldNames } from 'pages/Accreditation/shared/form';
import api from 'services/api';
import endpoints from 'services/endpoints';
import { removeSpecialCharacters } from 'utils/masks';

import { getApiError } from 'utils/errors';

const codeSchema = Yup.string()
  .required('Código obrigatório')
  .length(4, 'Código inválido');

export async function isValidCode(
  code?: string,
  phone?: string
): Promise<boolean> {
  if (!code || !phone) {
    return false;
  }

  await api.put(endpoints.validationCodeSms.replace(':code', code), {
    phone: `+55${removeSpecialCharacters(phone)}`
  });

  return true;
}

export const validation = Yup.object().shape({
  [fieldNames.validatePhone]: codeSchema.test(
    'is valid code',
    'Código inválido',
    async (value, { parent, createError }) => {
      try {
        await codeSchema.validate(value);

        return await isValidCode(value, parent[fieldNames.ownerPhone]);
      } catch (error) {
        const { code, message } = getApiError(error);

        if (code === 404) {
          return false;
        }

        if (message) {
          return createError({
            message: message
          });
        }

        return false;
      }
    }
  )
});
