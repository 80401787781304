import { ChangeEvent, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';

import Margin from 'styles/shared/Margin';
import Divider from 'styles/shared/Divider';
import { Column, Row } from 'styles/shared/Grid';

import useToggle from 'hooks/useToggle';

import {
  Flex,
  Select,
  Toggle,
  Checkbox,
  Loading,
  Text,
  ModalStatus
} from 'components';
import { SelectOption } from 'components/Select';

import { StepProps } from 'contexts/AccreditationSteps/types';

import { AccreditationValues, Platform } from 'pages/Accreditation/shared/form';

import { useAccreditationFee } from './use-accreditation-fee';

import { ReactComponent as IconInfo } from 'assets/images/icon-info-light.svg';

import * as S from './styles';

const installments = [
  {
    value: '1',
    label: `À vista`
  },
  ...[...new Array(11)].map((_, index) => ({
    value: (index + 2).toString(),
    label: `Até ${index + 2}x`
  }))
];

const PlatformType = 'PLATFORM';

const Step6 = ({ names }: StepProps) => {
  const form = useFormikContext<AccreditationValues>();

  const emailSuccessModal = useToggle(true);

  const { setFieldValue, setFieldError } = form;

  const { loading, fees, type } = useAccreditationFee({
    platform: form.values[names.platform] as unknown as Platform,
    partnership: form.values[names.partnership],
    city: form.values[names.city],
    uf: form.values[names.state]
  });

  const receiveAdvance = fees?.[0]?.receiveAdvance;
  const anticipationFee = fees?.[0]?.anticipationFee;

  const showAnticipationToggle = type !== PlatformType && anticipationFee;
  const showAnticipationInfo =
    type === PlatformType && receiveAdvance && anticipationFee;

  const installment = installments.find(
    type => type.value === form.values[names.installment]
  );

  const handleChangeFee = useCallback(
    (newValue: string) => {
      setFieldValue(names.feeId, newValue);
      setFieldValue(names.feeType, type);
      setFieldError(names.feeId, undefined);
    },
    [names, setFieldValue, setFieldError, type]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeFee(e.target.value);
  };

  const handleCheckProps = (name: string) => {
    const currentValue = form.values[name];

    return {
      isChecked: !!currentValue,
      onCheck: () => {
        form.setFieldError(name, undefined);
        form.setFieldValue(name, currentValue ? '' : 'true');
      }
    };
  };

  const handleInstallment = (type: SelectOption) => {
    form.setFieldError(names.installment, undefined);
    form.setFieldValue(names.installment, type.value);
  };

  useEffect(() => {
    if (fees?.length === 1) {
      handleChangeFee(fees[0].id);
    }
  }, [fees, handleChangeFee]);

  return (
    <>
      {emailSuccessModal.isActive && (
        <ModalStatus
          variant="success"
          action={{
            text: 'Continuar',
            onClick: emailSuccessModal.toggle
          }}
        >
          <Text weight="bold" size="large" align="center">
            E-mail validado com sucesso ;)
          </Text>
        </ModalStatus>
      )}

      {loading && <Loading fullContent fullWidth />}

      <S.Title>
        Configure o seu plano <S.HighlightText>:)</S.HighlightText>
      </S.Title>

      <S.Subtitle>Qual é a melhor opção para o seu negócio?</S.Subtitle>

      <Row>
        {fees.map(fee => (
          <Column key={fee.id} md={4}>
            <S.CardWrapper onClick={() => handleChangeFee(fee.id)}>
              <Flex alignItems="center" justifyContent="space-between">
                <div>
                  <S.CardTitle>Receber em {fee.dailiesLiquid}</S.CardTitle>

                  <S.CardSubtitle>Taxa de {fee.debitFee}</S.CardSubtitle>
                </div>

                <Checkbox
                  type="radio"
                  value={fee.id}
                  checked={fee.id === form.values[names.feeId]}
                  onChange={handleChange}
                />
              </Flex>
            </S.CardWrapper>
          </Column>
        ))}
      </Row>

      <Text color="red" size="xsmall">
        {form.errors[names.feeId]}
      </Text>

      <Margin mt="medium">
        <Select
          name="installments"
          label="Escolha o número de parcelas"
          placeholder=" "
          options={installments}
          value={installment}
          onChange={handleInstallment}
          errorMessage={form.errors[names.installment]}
        />
      </Margin>

      <Divider size="small" />

      {showAnticipationInfo && (
        <Flex mt="medium" alignItems="center" justifyContent="flex-end">
          <IconInfo />

          <S.AntecipationText size="xsmall" color="grayDark" ml="xsmall">
            Você receberá a antecipação automática das vendas por apenas
            <Text tag="span" size="xsmall" color="blueLight" weight="bold">
              {` ${anticipationFee} `}
            </Text>
            por parcela. Desative a antecipação quando quiser nas configurações
            da conta
          </S.AntecipationText>
        </Flex>
      )}

      {showAnticipationToggle && (
        <>
          <Flex mt="medium" justifyContent="flex-end">
            <Margin mr="medium">Ativar antecipação automática</Margin>

            <Toggle {...handleCheckProps(names.receiveAdvance)} />
          </Flex>

          <Flex mt="xsmall" alignItems="center" justifyContent="flex-end">
            <IconInfo />

            <S.AntecipationText size="xsmall" color="grayDark" ml="xsmall">
              As vendas parceladas ficam disponíveis automaticamente por apenas
              <Text tag="span" size="xsmall" color="blueLight" weight="bold">
                {` ${anticipationFee} `}
              </Text>
              de taxa
            </S.AntecipationText>
          </Flex>
        </>
      )}

      <Flex mt="medium" justifyContent="flex-end">
        <Margin mr="medium">
          Aceito receber e-mails e SMS com novidades sobre a Ame
        </Margin>

        <Toggle {...handleCheckProps(names.news)} />
      </Flex>

      <Flex mt="medium" justifyContent="flex-end">
        <Margin mr="medium">
          Aceito os{' '}
          <a
            target="_blank"
            href="https://www.amedigital.com/termo-e-condicoes-de-uso"
            rel="noreferrer"
          >
            <S.HighlightText color="blueLight">
              termos e condições
            </S.HighlightText>
          </a>
        </Margin>

        <Toggle {...handleCheckProps(names.terms)} />
      </Flex>

      <Flex mt="xsmall" justifyContent="flex-end">
        <Text color="red" size="xsmall">
          {form.errors[names.terms]}
        </Text>
      </Flex>

      <Margin mt="xlarge">
        <S.Action>Ir para dados da conta</S.Action>
      </Margin>
    </>
  );
};

export default Step6;
