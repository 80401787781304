import { useState } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { ReactComponent as IconUser } from 'assets/images/icon-profile-small.svg';
import { ReactComponent as IconDocument } from 'assets/images/icon-document-small.svg';
import { ReactComponent as IconBank } from 'assets/images/icon-bank-small.svg';
import { ReactComponent as IconClock } from 'assets/images/icon-clock-outline.svg';
import { ReactComponent as IconSuccess } from 'assets/images/icon-success.svg';
import { ReactComponent as IconError } from 'assets/images/icon-error.svg';
import { breadcrumb, BreadcrumbKeys } from '../Key/constants';

import {
  Breadcrumb,
  Button,
  Flex,
  Loading,
  ModalPix,
  TemplateShortContent,
  Text
} from 'components';
import Divider from 'styles/shared/Divider';
import Margin from 'styles/shared/Margin';
import Card from 'styles/shared/Card';

import { formatInput, maskOnlyNumbers } from 'utils/masks';
import { ISetPaymentBody } from 'services/pixService/types';
import { usePixOutFlux } from 'contexts/Pix/PixOutFlux';
import { useAuth } from 'contexts/Auth';
import PATHS_ROUTES from 'routes/paths';

import { modalMessages } from '../Value/constants';

type TransferConfirmationProps = {
  previousPage: () => void;
};

const maskDocument = (document?: string) =>
  document
    ? document.length <= 11
      ? `***.${formatInput['CPF'](document || '').substr(4, 6)}*-**`
      : `**.**${formatInput['CNPJ'](document || '').substr(5, 8)}**-**`
    : '';

const TransferConfirmation = ({ previousPage }: TransferConfirmationProps) => {
  let { keyType } = useParams<{ keyType: BreadcrumbKeys }>();

  const {
    pixOutRequestData,
    handleSetPixOutPayment,
    recipientData,
    recipientBankData,
    transferValue,
    transferDescription
  } = usePixOutFlux();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const date = new Date();
  const isToday =
    date.getHours() >= 8 && date.getHours() <= 17 && date.getMinutes() <= 59;

  const handleModalClose = (action?: 'redirect') => {
    setOpenModal(false);

    if (action === 'redirect') {
      navigate(PATHS_ROUTES.APP.PIX.DEFAULT);
    }
  };

  const formatPixData = (): ISetPaymentBody => {
    return {
      payments: [
        {
          amount: parseInt(transferValue),
          cashType: 'CASH'
        }
      ],
      pix: {
        key: recipientData?.key,
        description: transferDescription || undefined,
        endToEndId: recipientData?.endToEndId,
        documentNumber: maskOnlyNumbers(recipientData?.userTaxId || '')
      }
    };
  };

  const formatBankAccountData = (): ISetPaymentBody => {
    return {
      payments: [
        {
          amount: parseInt(transferValue),
          cashType: 'CASH'
        }
      ],
      pix: {
        ispb: recipientBankData?.ispb || '',
        institutionName:
          recipientData?.institutionName || recipientBankData?.institutionName,
        accountNumber: recipientBankData?.accountNumber || '',
        agencyNumber: recipientBankData?.agencyNumber || '',
        documentNumber: maskOnlyNumbers(
          recipientBankData?.documentNumber || ''
        ),
        name: recipientBankData?.name,
        accountType: recipientBankData?.accountType || 'CHECKING',
        holderType: recipientBankData?.holderType || 'CORPORATE',
        description: transferDescription || undefined
      }
    };
  };

  const handlePayment = async () => {
    handleSetPixOutPayment(
      keyType == 'bank' ? formatBankAccountData() : formatPixData()
    );
    setOpenModal(true);
  };

  if (recipientBankData) {
    keyType = 'bank';
  }

  if (!keyType || !breadcrumb[keyType]) {
    return <Navigate to={PATHS_ROUTES.APP.PIX.TRANSFER.DEFAULT} replace />;
  }

  return (
    <>
      {pixOutRequestData.loading && <Loading fullPage fullContent />}

      {!pixOutRequestData.loading && openModal && (
        <ModalPix
          modalTitle={modalMessages.title}
          title={
            pixOutRequestData.success
              ? modalMessages.success(keyType === 'bank').title
              : modalMessages.error(keyType === 'bank').title
          }
          modalAction={handleModalClose}
          loading={pixOutRequestData.loading}
          icon={pixOutRequestData.success ? <IconSuccess /> : <IconError />}
          subtitle={
            pixOutRequestData.success
              ? modalMessages.success(keyType === 'bank').subtitle
              : modalMessages.error(keyType === 'bank', pixOutRequestData.error)
                  .subtitle
          }
          primaryButton={
            pixOutRequestData.success
              ? {
                  action: () => {
                    handleModalClose('redirect');
                  },
                  text: modalMessages.success(keyType === 'bank').buttonText
                }
              : {
                  action: handleModalClose,
                  text: modalMessages.error(keyType === 'bank').buttonText
                }
          }
        />
      )}

      <Breadcrumb links={breadcrumb[keyType]} />

      <TemplateShortContent>
        <Margin my="large">
          <Text tag="h1" size="large" weight="medium">
            Confirme os dados da transação
          </Text>

          <Divider size="xsmall" />

          <Text tag="p" color="grayDark">
            Confirmação da transação
          </Text>

          <Divider size="medium" />

          <Margin mx="large">
            <Text tag="p" weight="bold" size="small">
              Valor da transação
            </Text>

            <Divider size="small" />

            <Text tag="p" weight="bold" color="grayDark" size="small">
              {formatInput.CURRENCY(transferValue)}
            </Text>

            <Divider size="medium" />

            <Text tag="p" weight="bold" size="small">
              Data do pagamento
            </Text>

            <Divider size="small" />

            <Card>
              <Flex alignItems="center">
                <IconClock />
                <Text tag="p" weight="bold" size="small" ml="small">
                  {isToday ? 'Hoje' : 'Amanhã'}
                </Text>
                <Text tag="p" size="small" ml="xsmall">
                  {isToday
                    ? moment().format('DD/MM/YYYY')
                    : moment().add(1, 'days').format('DD/MM/YYYY')}
                </Text>
              </Flex>
            </Card>

            <Divider size="small" />

            <Flex>
              <Flex direction="column" mt="small">
                <Text tag="p" size="small" weight="medium">
                  Quem vai receber:
                </Text>

                <Flex alignItems="center" mt="small">
                  <IconUser />
                  <Text tag="p" color="blue" ml="xsmall">
                    {recipientData?.userName || recipientBankData?.name}
                  </Text>
                </Flex>

                <Flex alignItems="center" my="xsmall">
                  <IconDocument />
                  <Text tag="p" color="grayDark" ml="xsmall">
                    {maskDocument(recipientData?.userTaxId) ||
                      maskDocument(
                        maskOnlyNumbers(recipientBankData?.documentNumber || '')
                      )}
                  </Text>
                </Flex>

                <Flex alignItems="center">
                  <IconBank />
                  <Text tag="p" color="grayDark" ml="xsmall">
                    {recipientData?.institutionName ||
                      recipientBankData?.institutionName}
                  </Text>
                </Flex>
              </Flex>

              <Flex direction="column" mt="small">
                <Text tag="p" size="small" weight="medium">
                  Dados do pagador:
                </Text>

                <Flex alignItems="center" mt="small">
                  <IconUser />
                  <Text tag="p" color="blue" ml="xsmall">
                    {user?.name}
                  </Text>
                </Flex>

                <Flex alignItems="center" my="xsmall">
                  <IconDocument />
                  <Text tag="p" color="grayDark" ml="xsmall">
                    {maskDocument(user?.documentNumber)}
                  </Text>
                </Flex>

                <Flex alignItems="center">
                  <IconBank />
                  <Text tag="p" color="grayDark" ml="xsmall">
                    Ame Digital
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Divider size="large" />

            <Button onClick={handlePayment} center filled>
              Confirmar transação
            </Button>

            <Button
              center
              filled
              filledBgColor="transparent"
              filledColor="blue"
              onClick={previousPage}
            >
              Voltar
            </Button>
          </Margin>
        </Margin>
      </TemplateShortContent>
    </>
  );
};

export default TransferConfirmation;
