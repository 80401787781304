import styled, { css } from 'styled-components';

import customMedia from 'styles/media-query';
import { Card as CardComponent } from 'styles/shared/Card';

export const Card = styled(CardComponent)`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium};

    ${customMedia.greaterThan('medium')`
      padding: ${theme.spacings.large};
    `}

    ${customMedia.lessThan('medium')`
      h1 {
        font-size: ${theme.font.sizes.xlarge};
      }
    `}
  `}
`;
