import { AccreditationValues } from '../../form';
import { useFormikContext } from 'formik';
import Divider from 'styles/shared/Divider';
import { Button, Flex, InputFile } from 'components';
import { StepProps } from 'contexts/AccreditationSteps/types';

import * as S from './styles';

function StepUploadFiles({ names }: StepProps) {
  const form = useFormikContext<AccreditationValues>();

  return (
    <>
      <S.Title>Que tal deixar sua conta completa?</S.Title>

      <S.Subtitle>
        Para uma conta completa e segura, envie as documentações abaixo. Você
        pode fazer isso em outro momento no seu perfil.
      </S.Subtitle>

      <InputFile
        label="Contrato social"
        alertMessage="Envie o documento em formato PDF ou JPG"
        name={names.docSocial}
        errorMessage={form.errors[names.docSocial]}
        onFilesChange={files => form.setFieldValue(names.docSocial, files)}
      />

      <Divider size="large" />

      <InputFile
        label="Declaração de plenos poderes"
        alertMessage="Envie o documento em formato PDF ou JPG"
        name={names.docDeclaration}
        errorMessage={form.errors[names.docDeclaration]}
        onFilesChange={files => form.setFieldValue(names.docDeclaration, files)}
      />

      <Divider size="medium" />

      <S.Action onClick={() => form.setFieldValue(names.sendDocuments, true)}>
        Enviar documentação
      </S.Action>

      <Flex justifyContent="center" mt="small">
        <Button
          type="submit"
          tertiary
          onClick={() => form.setFieldValue(names.sendDocuments, null)}
        >
          Pular etapa
        </Button>
      </Flex>
    </>
  );
}

export default StepUploadFiles;
