import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

import { Card } from 'styles/shared/Card';
import { Column } from 'styles/shared/Grid';

import { CardCompleteProfileProps } from './';

export const CloseButton = styled.button`
  ${({ theme }) => css`
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: ${theme.layers.base};

    svg {
      color: ${theme.mode === 'light' ? '#313647' : theme.colors.white};
    }
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    h4 {
      color: ${theme.mode === 'light'
        ? theme.colors.black
        : theme.colors.white};
      margin-bottom: ${theme.spacings.small};
    }

    p {
      color: ${theme.mode === 'light'
        ? theme.colors.grayDark
        : theme.colors.grayMedium};
      margin-bottom: ${theme.spacings.medium};
      font-size: ${theme.font.sizes.small};
      line-height: ${theme.font.lineHeight('small')};
    }

    button {
      display: none;
    }
  `}
`;

export const StatusColumn = styled(Column)`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: ${theme.spacings.large};
  `}
`;

export const InfoStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-right: ${theme.spacings.large};

    small {
      color: ${theme.mode === 'light'
        ? theme.colors.grayDark
        : theme.colors.white};
      font-size: ${theme.font.sizes.xsmall};
      line-height: ${theme.font.lineHeight('xsmall')};
      margin-bottom: -7px;
    }

    h5 {
      color: ${theme.mode === 'light'
        ? theme.colors.black
        : theme.colors.grayDark};
      display: flex;
      align-items: center;

      svg {
        display: none;
        margin-left: ${theme.spacings.xsmall};
      }
    }
  `}
`;

export const MobileColumn = styled(Column)`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.large};
  `}
`;

export const Wrapper = styled.div<CardCompleteProfileProps>`
  ${({ theme, aside }) => css`
    ${Card} {
      position: relative;
      padding-left: ${theme.spacings.small};
      padding-right: ${theme.spacings.small};
    }

    ${aside &&
    css`
      margin-top: ${theme.spacings.large};
      padding-left: ${theme.spacings.small};
      padding-right: ${theme.spacings.small};


      ${Card} {
        ${
          theme.mode === 'dark' &&
          css`
            background: ${theme.colors.grayBlue};
          `
        }
      }

      ${Info} {
        margin-top: ${theme.spacings.small};

        h4 {
          font-size: 1.8rem;
          margin-bottom: ${theme.spacings.xsmall};
        }

        p {
          margin-bottom: ${theme.spacings.small};
          font-size: ${theme.font.sizes.xsmall};
          line-height: ${theme.font.lineHeight('xsmall')};
        }
      }

      ${StatusColumn} {
        margin-left: 0;
        margin-right: 0;

        .CircularProgressbar {
          max-width: 115px;
        }
      }
      ${MobileColumn} {
        margin-top: ${theme.spacings.medium};
      }

      }
    `}

    ${!aside &&
    css`
      ${Card} {
        ${customMedia.greaterThan('large')`
        padding-left: ${theme.spacings.large};
        padding-right: ${theme.spacings.large};
      `}
      }

      ${Info} {
        ${customMedia.greaterThan('small')`
          button{
            display: block;
          }
        `}
      }

      ${StatusColumn} {
        ${customMedia.lessThan('small')`
          .CircularProgressbar {
            max-width: 150px;
          }
        `}

        ${customMedia.between('small', 'large')`
          display: none;
        `}

        ${customMedia.greaterThan('large')`
          .CircularProgressbar {
            max-width: 150px;
          }

          .d-center {
            justify-content: center;
          }
        `}
      }

      ${InfoStatus} {
        ${customMedia.greaterThan('large')`
          margin-right: ${theme.spacings.medium};

          h5 {
            svg {
              color: ${theme.mode === 'light' ? '#313647' : theme.colors.white};
              display: block;
            }
          }
        `};
      }

      ${MobileColumn} {
        ${customMedia.greaterThan('small')`
          display: none;
        `}
      }
    `};
  `}
`;
