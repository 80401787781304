import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

import { getSessionStorage } from 'utils/storage';

const { theme: themeMode } = getSessionStorage();

const isLight = themeMode === 'light';

export const headerHeight = 6.8;

export const Header = styled.header`
  ${({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    z-index: ${theme.layers.menu};
    width: 100%;
    background: ${isLight ? theme.colors.white : theme.colors.blackDark};
    padding: ${theme.spacings.medium};
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacings.medium};
    border-bottom: 1px solid
      ${isLight ? theme.colors.grayNeutralLight : theme.colors.blackDark};
    height: ${headerHeight}rem;

    ${customMedia.greaterThan('large')`
      padding: ${theme.spacings.medium} ${theme.spacings.medium};
    `};
  `};
`;

export const Logo = styled.img`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 20rem;
    left: ${theme.spacings.medium};
  `};
`;

export const LogoMobile = styled.button`
  ${customMedia.greaterThan('large')`
    display: none;
  `}
`;
