import { Text } from 'components';

import { ReactComponent as IconSuccess } from 'assets/images/icon-success.svg';
import { ReactComponent as IconError } from 'assets/images/icon-error.svg';

export const removeKeyItemsModal = () => [
  {
    title: 'Excluir chave Pix',
    subtitle: (
      <Text align="center" tag="span" size="xxmedium">
        Depois de excluir, a chave não fica mais <br /> disponível para receber
        pagamentos. Deseja <br /> excluir esta chave Pix?
      </Text>
    )
  },
  {
    title: (
      <Text align="center" tag="span" weight="bold" size="xxmedium">
        A chave foi excluida{' '}
        <Text tag="span" color="blue" align="center" weight="bold">
          :)
        </Text>
      </Text>
    ),
    subtitle: (
      <Text align="center" tag="span" size="xxmedium">
        Para continuar recebendo pagamentos com o Pix, <br /> escolha outra
        chave ou cadastre outras quando <br /> quiser em “Minhas chaves” :)
      </Text>
    ),
    icon: <IconSuccess />
  },
  {
    title: (
      <Text align="center" tag="span" weight="bold" size="xxmedium">
        Erro ao excluir chave{' '}
        <Text tag="span" color="red" align="center" weight="bold">
          :(
        </Text>
      </Text>
    ),
    subtitle: '',
    icon: <IconError />
  }
];

export const formatButtonData = (
  key: string,
  text: string,
  action: () => void
) => {
  return {
    [key]: {
      text,
      action
    }
  };
};
