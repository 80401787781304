import styled, { css, DefaultTheme } from 'styled-components';

import { Card } from 'styles/shared/Card';
import { Wrapper as InitialName } from 'components/InitialName/styles';
import { Wrapper as ProfilePicture } from 'components/ProfilePicture/styles';

type WrapperProps = {
  selected?: boolean;
  filled?: boolean;
};

const wrapperModifier = {
  selected: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.blue};
  `,
  filled: (theme: DefaultTheme) => css`
    background: ${theme.colors.blue};

    h5,
    span {
      color: ${theme.colors.white};
    }

    ${InitialName} {
      background: ${theme.colors.white};
      color: ${theme.colors.blue};
    }
  `
};

export const Wrapper = styled(Card)<WrapperProps>`
  ${({ theme, selected, filled }) => css`
    padding: ${theme.spacings.small};
    padding-right: ${theme.spacings.medium};
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacings.small};
    min-height: 7.5rem;

    .column-left {
      display: flex;
      align-items: center;
      flex: 1;

      ${ProfilePicture} {
        margin-right: ${theme.spacings.small};

        > div {
          font-size: ${theme.font.sizes.xxlarge};
        }
      }

      h5 {
        margin-bottom: -5px;
      }
    }

    .column-right {
      text-align: right;
    }

    ${selected && wrapperModifier.selected(theme)}
    ${filled && wrapperModifier.filled(theme)}
  `}
`;

type ContentProps = {
  hidePicture?: boolean;
};

const contentModifier = {
  hidePicture: (theme: DefaultTheme) => css`
    margin-left: ${theme.spacings.medium};
  `
};

export const Content = styled.div<ContentProps>`
  ${({ theme, hidePicture }) => css`
    ${hidePicture && contentModifier.hidePicture(theme)}
  `};
`;
