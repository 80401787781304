import PATHS_ROUTES from 'routes/paths';

export const breadcrumbItems = [
  {
    link: PATHS_ROUTES.APP.PIX.DEFAULT,
    title: 'Pix'
  },
  {
    link: '#',
    title: 'Meu QR Code Pix'
  }
];
