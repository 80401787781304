import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacings.medium};

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        padding: 0 ${theme.spacings.medium};
      }
    }
  `}
`;

export const SectionTitle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacings.small};
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      flex: 1;
    }

    button {
      font-weight: ${theme.font.weights.medium};
    }
  `}
`;

export const SeeAllWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacings.small};

    & a {
      color: ${theme.colors.blueCategoryColorSuccess};
    }
  `}
`;
