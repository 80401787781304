import { useCallback, useState } from 'react';

import { Button, TemplateModal, CardSectionOptions } from 'components';
import Divider from 'styles/shared/Divider';

import { filterItems } from './constants';
import { FilterType } from './types';
import { useReportList } from 'contexts/Report/List';
import { Filters } from 'contexts/Report/List/types';

type InfoModalProps = {
  closeModal: () => void;
};

export function FilterModal({ closeModal }: InfoModalProps) {
  const { selectedFilters, setSelectedFilters, setPage, setFinalReportList } =
    useReportList();
  const [filters, setFilters] = useState(selectedFilters);

  const handleFilterReport = () => {
    setSelectedFilters(filters);
    setPage(0);
    setFinalReportList([]);
    closeModal();
  };

  const handleSelectItem = useCallback(
    (type: FilterType, value: string) => {
      setFilters((prevValue: Filters) => {
        if (typeof prevValue[type] === 'string') {
          return {
            ...prevValue,
            [type]: value
          };
        }

        const isAdding = !filters[type].includes(value);

        if (isAdding) {
          return {
            ...prevValue,
            [type]: [...(prevValue[type] as Array<string>), value]
          };
        }

        return {
          ...prevValue,
          [type]: (prevValue[type] as Array<string>).filter(
            filter => filter !== value
          )
        };
      });
    },
    [filters]
  );

  return (
    <TemplateModal
      closeModal={closeModal}
      title="Filtrar"
      subtitle="Selecione os filtros que deseja aplicar"
    >
      {filterItems.map(item => (
        <CardSectionOptions
          key={item.text}
          title={item.text}
          options={item.cards.map(card => ({
            value: card.value,
            description: card.text
          }))}
          optionSelected={filters[item.type]}
          onOptionSelected={value => handleSelectItem(item.type, value)}
        />
      ))}

      <Divider />

      <Button large center filled onClick={handleFilterReport}>
        Aplicar
      </Button>
    </TemplateModal>
  );
}
