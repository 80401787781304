import { useState, useCallback, useEffect } from 'react';

import * as S from './styles';
import Divider from 'styles/shared/Divider';

import PermissionSection from './PermissionSection';
import { ProfileTypeList, Text, Button } from 'components';

import { ProfileTypes } from 'types/global';

import {
  MemberPermissions,
  ProfilePermissions
} from 'services/memberService/types';
import { getProfilePermissions } from 'services/memberService';

export type TemplateProfilePermissionsFormProps = {
  initialValues?: MemberPermissions;
  onConfirm: (values: MemberPermissions) => void;
  blockEditProfileType?: boolean;
};

const TemplateProfilePermissionsForm = ({
  initialValues,
  onConfirm,
  blockEditProfileType
}: TemplateProfilePermissionsFormProps) => {
  const [profileId, setProfileId] = useState(
    initialValues?.profileId as string
  );
  const [userType, setUserType] = useState<ProfileTypes | undefined>();
  const [userTypeLabel, setUserTypeLabel] = useState<string | undefined>();
  const [permissions, setPermissions] = useState(
    initialValues?.permissions as string[]
  );
  const [profilePermissions, setProfilePermissions] =
    useState<ProfilePermissions[]>();

  const callProfilePermissions = useCallback(async () => {
    if (profileId || initialValues?.profileId) {
      try {
        const profilePermissionsCall = await getProfilePermissions(
          profileId || (initialValues?.profileId as string)
        );
        setProfilePermissions(profilePermissionsCall);
      } catch (err) {
        setProfilePermissions(undefined);
      }
    }
  }, [profileId, initialValues]);

  useEffect(() => {
    callProfilePermissions();
  }, [callProfilePermissions]);

  const handleSelectPermission = (permissionIds: string[]) => {
    setPermissions(permissionIds);
  };

  const handleSubmitForm = useCallback(() => {
    !!onConfirm &&
      onConfirm({
        profileId,
        userType,
        userTypeLabel,
        permissions
      });
  }, [onConfirm, profileId, userType, userTypeLabel, permissions]);

  return (
    <S.Wrapper>
      <Text size="small" weight="medium">
        Perfil
      </Text>
      <Divider size="small" />

      <ProfileTypeList
        initialProfileSelected={initialValues?.profileId}
        onSelect={(id: string, type: ProfileTypes, label: string) => {
          setProfileId(id);
          setUserType(type);
          setUserTypeLabel(label);
        }}
        blockEditProfileType={blockEditProfileType}
      />

      <Divider size="large" />

      {profilePermissions?.map(p => (
        <div key={p.title}>
          <PermissionSection
            title={p.title}
            permissions={p.permissions}
            initialPermissions={initialValues?.permissions}
            onSelect={permissions => handleSelectPermission(permissions)}
          />

          <Divider size="medium" />
        </div>
      ))}

      <Button
        disabled={
          !profileId ||
          !profilePermissions ||
          !permissions ||
          permissions.length === 0
        }
        filled
        large
        center
        onClick={handleSubmitForm}
      >
        Concluir
      </Button>
    </S.Wrapper>
  );
};

export default TemplateProfilePermissionsForm;
