import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import useRequest from 'hooks/useRequest';

import endpoints from 'services/endpoints';

import * as types from './types';
import { GetMemberGroupResponse } from 'services/memberService/types';

const MemberGroupContext = createContext<types.ContextData | null>(null);

function MemberGroupProvider({ children }: types.ProviderData) {
  const { id } = useParams<{ id: string }>();
  const {
    data: memberGroupData,
    loading,
    error
  } = useRequest<GetMemberGroupResponse>({
    url: queryString.stringifyUrl({
      url: id
        ? endpoints.memberGroup.replace('{memberId}', id)
        : endpoints.memberGroup
    })
  });

  return (
    <MemberGroupContext.Provider
      value={{
        memberGroup: memberGroupData,
        loading,
        error
      }}
    >
      {children}
    </MemberGroupContext.Provider>
  );
}

function useMemberGroup(): types.ContextData {
  const context = useContext(MemberGroupContext);

  if (!context)
    throw new Error('useMember must be used within an MemberGroupProvider');

  return context;
}

export { useMemberGroup, MemberGroupProvider, MemberGroupContext };
