import { hotjar } from 'react-hotjar';

const environment = process.env.NODE_ENV || 'production';
const hjid = 3046875;
const hjsv = 6;

export const initializeHotjar = () => {
  if (environment === 'production') {
    hotjar.initialize(hjid, hjsv);
  }
};

export const initializeHotjarUserAPI = (
  userId: string,
  documentNumber: string
) => {
  if (environment === 'production') {
    hotjar.identify(userId, { documentNumber });
  }
};
