import { ReactComponent as IconReceive } from 'assets/images/pix/icon-receive.svg';
import { ReactComponent as IconSend } from 'assets/images/pix/icon-send.svg';

import PATHS_ROUTES from 'routes/paths';

import { claimItensTypes } from 'utils/pix';

export const cardItems = [
  {
    title: 'Solicitações que enviei',
    icon: {
      element: IconSend,
      id: 'phone'
    },
    path: PATHS_ROUTES.APP.PIX.KEYS.REQUESTS,
    state: { claim: claimItensTypes.SENT }
  },
  {
    title: 'Solicitações que recebi',
    icon: {
      element: IconReceive,
      id: 'document'
    },
    path: PATHS_ROUTES.APP.PIX.KEYS.REQUESTS,
    state: { claim: claimItensTypes.RECEIVED }
  }
];

export const breadcrumbItems = [
  {
    link: PATHS_ROUTES.APP.PIX.DEFAULT,
    title: 'Pix'
  },
  {
    link: '#',
    title: 'Minhas chaves'
  }
];
