import { ReactNode } from 'react';
import { ThemeColors } from 'types/global';

import * as S from './styles';

export type HalfItemsBackgroundProps = {
  left: ReactNode;
  right?: ReactNode;
  filledBgColor?: ThemeColors;
};

export const HalfItemsBackground = ({
  left,
  right,
  filledBgColor = 'transparent'
}: HalfItemsBackgroundProps) => {
  return (
    <S.CardWrapper filledBgColor={filledBgColor}>
      {left}
      {right}
    </S.CardWrapper>
  );
};

export default HalfItemsBackground;
