import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  oldPassword: yup.string().required('Senha atual obrigatória'),
  newPassword: yup
    .string()
    .required('Nova senha obrigatória')
    .min(8, 'No mínimo 8 caracteres')
    .max(50, 'No máximo 50 caracteres')
    .matches(
      /^(?=.*[A-Za-z])(?=.*[0-9])/,
      'A nova senha deve ter números e letras'
    )
    .notOneOf(
      [null, yup.ref('oldPassword')],
      'A nova senha precisa ser diferente da atual'
    ),
  confirmPassword: yup
    .string()
    .required('Confirmação de senha obrigatória')
    .min(8, 'No mínimo 8 caracteres')
    .max(50, 'No máximo 50 caracteres')
    .oneOf([null, yup.ref('newPassword')], 'As senhas precisam ser iguais')
});
