import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    background: ${theme.colors.blue};
    border-radius: 1.2rem;
    padding: ${theme.spacings.small};
    min-height: 7.3rem;
  `}
`;

export const Account = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > div {
      margin-right: ${theme.spacings.medium};
    }
  `}
`;

export const Counter = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-left: 10rem;

    span {
      display: block;
      width: 2.3rem;
      height: 2.3rem;
      text-align: center;
      margin-right: ${theme.spacings.xsmall};
      background: ${theme.colors.white};
      border-radius: 0.8rem;
    }
  `}
`;

export const ActionButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: ${theme.spacings.medium};
    margin-left: auto;

    button {
      padding: 0;
      width: 3.3rem;
      height: 3.3rem;

      svg {
        margin-top: 1px;
      }

      &:last-child {
        margin-left: ${theme.spacings.medium};
        background: ${theme.colors.blueLight};
      }
    }
  `}
`;
