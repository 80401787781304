import styled, { css, DefaultTheme } from 'styled-components';
import { transparentize } from 'polished';

import { TableProps } from './';

type WrapperProps = Omit<TableProps, 'theadData'>;

const modifiers = {
  striped: (theme: DefaultTheme) => css`
    tbody tr:nth-child(even) {
      background-color: ${theme.mode === 'light'
        ? transparentize(0.8, theme.colors.grayMedium)
        : theme.colors.blackDark};
    }
  `,
  bordered: (theme: DefaultTheme) => css`
    tr {
      border-bottom: 0.1rem solid
        ${theme.mode === 'light'
          ? transparentize(0.4, theme.colors.grayMedium)
          : theme.colors.black};
    }
  `
};

export const Wrapper = styled.table<WrapperProps>`
  ${({ theme, striped, bordered, size = 'small' }) => css`
    width: 100%;
    border-spacing: 2px;
    border: none;
    border-collapse: collapse;
    tr {
      border-bottom: 0.1rem solid transparent;
    }
    tr td,
    tr th {
      padding: ${theme.spacings[size]} ${theme.spacings[size]};
      text-align: left;
      color: ${theme.mode === 'light'
        ? theme.colors.blackDark
        : theme.colors.white};
    }
    th {
      font-weight: ${theme.font.weights.regular};
    }
    thead {
      display: table-header-group;
    }
    ${striped && modifiers.striped(theme)}
    ${bordered && modifiers.bordered(theme)}
  `}
`;
