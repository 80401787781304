import { TutorialRequestKey } from '../components';
import * as S from '../../styles';
const MagentoMetrocomm = () => {
  return (
    <div>
      <S.Title tag="h1" size="xlarge" weight="bold">
        Magento Metrocomm
      </S.Title>

      <TutorialRequestKey name="Magento" showConfigurationMessage={false} />

      <S.Paragraph tag="p" color="grayBlue">
        Recebeu sua chave? Ótimo! Com a sua chave de integração enviei um email
        para <strong>fabiano@metrocomm.com.br</strong> com cópia para
        <strong> parceria.digital@amedigital.com</strong>. Informar o CNPJ da
        empresa, site e a versão do seu sistema Magento. Você receberá toda a
        orientação para instalação do módulo da Ame Digital e como fazer a
        configuração de forma correta.
      </S.Paragraph>
    </div>
  );
};

export default MagentoMetrocomm;
