import iconSale from 'assets/images/icon-receive.svg';
import iconTransfer from 'assets/images/icon-transfer-outline.svg';
import iconRefund from 'assets/images/icon-receive-invert.svg';
import iconDeposit from 'assets/images/icon-deposit-outline.svg';

import { StatementContent } from 'services/statementService/types';
import { RequestParams } from 'types/global';
import moment from 'moment';

export type Categories =
  | 'sale'
  | 'refund'
  | 'payment'
  | 'cashback'
  | 'transfer'
  | 'deposit'
  | 'withdraw'
  | 'pix'
  | 'loan'
  | '';

export type Filters = {
  period: string;
  status: string;
  category: string[];
};

export const STATEMENT_STATUS = {
  ERROR: 'ERROR',
  CREATED: 'CREATED',
  HOLD: 'HOLD',
  CANCELED: 'CANCELED',
  DENIED: 'DENIED',
  DENIED_BY_RISK: 'DENIED_BY_RISK',
  SUCCESS: 'SUCCESS',
  REFUNDED: 'REFUNDED',
  AUTHORIZED: 'AUTHORIZED'
};

export type StatementStatus = keyof typeof STATEMENT_STATUS | string;

export const sortByDate = (a: StatementContent, b: StatementContent) =>
  new Date(b.date).getTime() - new Date(a.date).getTime();

export const STATEMENT_TRANSLATED_STATUS = {
  CANCELED: 'Cancelado',
  APPROVED: 'Aprovado',
  PROCESSING: 'Em processamento'
};

export type StatementTranslatedStatus =
  | 'Cancelado'
  | 'Aprovado'
  | 'Em processamento';

export const translateStatus = (status: StatementStatus, isSale: boolean) => {
  switch (status) {
    case STATEMENT_STATUS.ERROR:
    case STATEMENT_STATUS.CANCELED:
    case STATEMENT_STATUS.DENIED:
    case STATEMENT_STATUS.DENIED_BY_RISK:
      return STATEMENT_TRANSLATED_STATUS.CANCELED;
    case STATEMENT_STATUS.SUCCESS:
    case STATEMENT_STATUS.REFUNDED:
      return STATEMENT_TRANSLATED_STATUS.APPROVED;
    case STATEMENT_STATUS.AUTHORIZED:
      return isSale
        ? STATEMENT_TRANSLATED_STATUS.APPROVED
        : STATEMENT_TRANSLATED_STATUS.PROCESSING;
    default:
      return STATEMENT_TRANSLATED_STATUS.PROCESSING;
  }
};

export const STATEMENT_TYPE = {
  TRANSFER: 'TRANSFER',
  BANK_CASH_OUT: 'BANK_CASH_OUT',
  BANK_CASH_IN: 'BANK_CASH_IN',
  REFUND: 'REFUND',
  CASH_IN: 'CASH_IN',
  STORE_CASH_IN: 'STORE_CASH_IN',
  GIFT_CASH_IN: 'GIFT_CASH_IN',
  STORE_CASH_OUT: 'STORE_CASH_OUT',
  TAX: 'TAX',
  CASH_BACK: 'CASH_BACK',
  PAYMENT: 'PAYMENT',
  BILL_PAYMENT: 'BILL_PAYMENT',
  MOBILE_RECHARGE: 'MOBILE_RECHARGE'
};

export type StatementType = keyof typeof STATEMENT_TYPE | string;

export const STATEMENT_TRANSLATED_TYPE = {
  TRANSFER: 'Transferência',
  REFUND: 'Estorno',
  CASH_IN: 'Depósito',
  GIFT_CASH_IN: 'Incentivo',
  CASH_OUT: 'Saque',
  CASH_BACK: 'Cashback',
  SALE: 'Venda',
  PURCHASE: 'Compra',
  PAYMENT: 'Pagamento',
  BANK_CASH_OUT: 'Retirada'
};

export type StatementTranslatedType =
  | 'Transferência'
  | 'Estorno'
  | 'Depósito'
  | 'Incentivo'
  | 'Saque'
  | 'Cashback'
  | 'Venda'
  | 'Compra'
  | 'Pagamento';

export const STATEMENT_OPERATION_TYPE = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT'
};

export type StatementOperationType =
  | keyof typeof STATEMENT_OPERATION_TYPE
  | string;

export const translateTypes = (
  type: StatementType,
  operationType: StatementOperationType
) => {
  switch (type) {
    case STATEMENT_TYPE.TRANSFER:
    case STATEMENT_TYPE.BANK_CASH_IN:
      return STATEMENT_TRANSLATED_TYPE.TRANSFER;
    case STATEMENT_TYPE.REFUND:
      return STATEMENT_TRANSLATED_TYPE.REFUND;
    case STATEMENT_TYPE.CASH_IN:
    case STATEMENT_TYPE.STORE_CASH_IN:
      return STATEMENT_TRANSLATED_TYPE.CASH_IN;
    case STATEMENT_TYPE.GIFT_CASH_IN:
      return STATEMENT_TRANSLATED_TYPE.GIFT_CASH_IN;
    case STATEMENT_TYPE.STORE_CASH_OUT:
    case STATEMENT_TYPE.TAX:
      return STATEMENT_TRANSLATED_TYPE.CASH_OUT;
    case STATEMENT_TYPE.CASH_BACK:
      return STATEMENT_TRANSLATED_TYPE.CASH_BACK;
    case STATEMENT_TYPE.PAYMENT:
      return operationType === STATEMENT_OPERATION_TYPE.CREDIT
        ? STATEMENT_TRANSLATED_TYPE.SALE
        : STATEMENT_TRANSLATED_TYPE.PURCHASE;
    case STATEMENT_TYPE.BILL_PAYMENT:
    case STATEMENT_TYPE.MOBILE_RECHARGE:
      return STATEMENT_TRANSLATED_TYPE.PAYMENT;
    case STATEMENT_TYPE.BANK_CASH_OUT:
      return STATEMENT_TRANSLATED_TYPE.BANK_CASH_OUT;
    default:
      return '';
  }
};

export const groupedByDate = (statements: StatementContent[]) => {
  const grouped: Map<string, StatementContent[]> = new Map();

  statements.forEach(item => {
    const year = item.date.substr(0, 4);
    const month = item.date.substr(5, 2);

    const monthYearGroup = `${year}-${month}-01`;

    const groupedData = grouped.get(monthYearGroup);

    if (groupedData) {
      grouped.set(monthYearGroup, [...groupedData, item]);
    } else {
      grouped.set(monthYearGroup, [item]);
    }
  });

  return Array.from(grouped, ([date, items]) => ({ date, items }));
};

export const renderTypeIcon = (type: string) => {
  const iconProps = {
    width: 33,
    height: 33
  };

  switch (type) {
    case 'TRANSFER':
      return <img src={iconTransfer} {...iconProps} alt="Transferência" />;
    case 'PAYMENT':
      return <img src={iconSale} {...iconProps} alt="Venda" />;
    case 'REFUND':
      return <img src={iconRefund} {...iconProps} alt="Estorno" />;
    case 'BANK_CASH_OUT':
      return <img src={iconDeposit} {...iconProps} alt="Retirada" />;

    default:
      return null;
  }
};

export const getTypeByFilterCategory = (category: Categories[]) => {
  const types = {
    sale: {
      orderType: 'RELEASE',
      operationType: STATEMENT_OPERATION_TYPE.CREDIT
    },
    refund: {
      orderType: 'REFUND',
      operationType: STATEMENT_OPERATION_TYPE.DEBIT
    },
    payment: {
      orderType: 'PURCHASE,MOBILE_RECHARGE,BILL_PAYMENT',
      operationType: STATEMENT_OPERATION_TYPE.DEBIT
    },
    cashback: {
      orderType: 'CASH_BACK',
      operationType: STATEMENT_OPERATION_TYPE.CREDIT
    },
    transfer: {
      orderType: 'TRANSFER_BETWEEN_WALLETS,STORE_CASH_OUT',
      operationType: `${STATEMENT_OPERATION_TYPE.CREDIT},${STATEMENT_OPERATION_TYPE.DEBIT}`
    },
    deposit: {
      orderType:
        'BANK_CASH_IN,CASH_IN,STORE_CASH_IN,GIFT_CASHBACK_IN,GIFT_CASH_IN,GIFT_CASHBACK_IN',
      operationType: STATEMENT_OPERATION_TYPE.CREDIT
    },
    withdraw: {
      orderType: 'CASH_OUT',
      operationType: STATEMENT_OPERATION_TYPE.DEBIT
    },
    pix: {
      orderType: '',
      operationType: ''
    },
    loan: {
      orderType: '',
      operationType: ''
    },
    '': { orderType: '', operationType: '' }
  };

  const operationType = category.map(cat => types[cat].operationType);
  const orderType = category.map(cat => types[cat].orderType);

  return {
    operationType: operationType
      .filter((item, index) => {
        return operationType.indexOf(item) === index;
      })
      .join(','),
    orderType: orderType.join(',')
  };
};

export const convertFiltersToParams = (filters: Filters): RequestParams => {
  let params = {};

  if (filters.category) {
    params = {
      ...getTypeByFilterCategory(filters.category as Categories[])
    };
  }

  if (filters.status) {
    params = {
      ...params,
      status: filters.status
    };
  }

  if (filters.period) {
    const days = parseInt(filters.period);
    params = {
      ...params,
      startDate: moment().subtract(days, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    };
  }

  return params;
};

export const joinStatementsById = (
  data: StatementContent[],
  newData: StatementContent[]
) => {
  const newStatements = [...data];

  if (newData.length > 0) {
    newData.forEach(element => {
      if (
        !data.find(d => element.id === d.id) &&
        !newStatements.find(s => element.id === s.id)
      ) {
        newStatements.push(element);
      }
    });
  }

  return newStatements;
};

export const getQtdFiltersSelected = (filters: Filters) => {
  let count = 0;

  if (filters.category.length > 0) {
    count++;
  }

  if (filters.period !== '') {
    count++;
  }

  if (filters.status !== '') {
    count++;
  }

  return count;
};
