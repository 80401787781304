import { createContext, useState, useContext, useCallback } from 'react';
import useRequest from 'hooks/useRequest';
import queryString from 'query-string';

import {
  ContextData,
  ProviderData,
  PixStatus,
  SendCodeValidationData
} from './types';
import { IToggleFeatureData } from 'services/pixService/types';

import useMutate from 'hooks/useMutate';
import endpoints from 'services/endpoints';
import { registerKeyErrors, defaultFeatureToggle } from './constants';

import { getApiError } from 'utils/errors';

const PixRegisterContext = createContext<ContextData | null>(null);

function PixRegisterProvider({ children }: ProviderData) {
  const { mutate } = useMutate();
  const [status, setStatus] = useState<PixStatus>('initial');
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState('');
  const [keyType, setKeyType] = useState('');

  const featureToggle = useRequest<IToggleFeatureData>({
    url: endpoints.featureStatus.replace(
      '{feature}',
      'KEY_OWNERSHIP_VALIDATION'
    )
  });

  const handleCreatePixKey = useCallback(
    body => {
      setKey(body.key);
      setKeyType(body.keyType);
      setLoading(true);

      mutate({
        method: 'post',
        endpoint: endpoints.registerKey,
        body,
        onSuccess: data => {
          if (data.key) {
            setKey(data.key);
          }

          setStatus('success');
          setLoading(false);
        },
        onFailure: error => {
          setLoading(false);
          const { status } = getApiError(error);

          if (status === registerKeyErrors.keyExistsAnotherParticipant) {
            setStatus('portability');
            return;
          }

          if (status === registerKeyErrors.keyExistsOwnedByOtherPerson) {
            setStatus('claim');
            return;
          }

          setStatus('error');
        }
      });
    },
    [mutate]
  );

  const sendCodeValidation = useCallback(
    async (data: SendCodeValidationData) => {
      mutate({
        method: 'post',
        endpoint: queryString.stringifyUrl({
          url: endpoints.pixValidation.replace('{keyType}', data.keyType),
          query: {
            key: data.key
          }
        })
      });
    },
    [mutate]
  );

  const handleCreateClaim = useCallback(
    validationCode => {
      setLoading(true);
      mutate({
        method: 'post',
        endpoint: endpoints.pixClaim,
        body: {
          validationCode,
          keyType,
          key
        },
        onSuccess: () => {
          if (status === 'portability') setStatus('successPortability');
          else setStatus('successClaim');
          setLoading(false);
        },
        onFailure: () => {
          setLoading(false);
          setStatus('error');
        }
      });
    },
    [key, keyType, mutate, status]
  );

  return (
    <PixRegisterContext.Provider
      value={{
        key,
        loading,
        status,
        setStatus,
        handleCreatePixKey,
        sendCodeValidation,
        handleCreateClaim,
        featureToggle: featureToggle.data || defaultFeatureToggle
      }}
    >
      {children}
    </PixRegisterContext.Provider>
  );
}

function usePixRegister(): ContextData {
  const context = useContext(PixRegisterContext);

  if (!context)
    throw new Error(
      'usePixRegister must be used within an PixRegisterProvider'
    );

  return context;
}

export { usePixRegister, PixRegisterProvider };
