import PATHS_ROUTES from 'routes/paths';
import { BreadcrumbLink } from 'components/Breadcrumb';

export const breadcrumbItems: BreadcrumbLink[] = [
  {
    link: PATHS_ROUTES.APP.DASHBOARD.DEFAULT,
    title: 'Início'
  },
  {
    link: PATHS_ROUTES.APP.INTEGRATION.DEFAULT,
    title: 'Integração'
  },
  {
    link: '#',
    title: 'Credenciais em lote'
  }
];
