import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    button {
      padding: ${theme.spacings.small};
      margin-right: ${theme.spacings.small};
      margin-bottom: ${theme.spacings.small};

      &:last-child {
        margin-right: 0;
      }
    }
  `}
`;
