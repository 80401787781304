import styled, { css } from 'styled-components';
import { Link as LinkComponent } from 'react-router-dom';

export const Link = styled(LinkComponent)`
  ${({ theme }) => css`
    color: ${theme.colors.grayNeutralDark};
    margin: 0 ${theme.spacings.small} 0 0;
    font-size: ${theme.font.sizes.small};
    display: inline-flex;
    align-items: center;

    &:nth-last-of-type(2) {
      color: ${theme.colors.blue};
    }

    &:last-child {
      color: ${theme.colors.blackDark};
      font-size: ${theme.font.sizes.xxmedium};
      font-weight: bold;

      svg {
        display: none;
      }
    }

    svg {
      margin-left: ${theme.spacings.small};
    }
  `}
`;
