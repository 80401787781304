import { ReactNode } from 'react';

import { TemplateModal } from 'components';

import * as S from './styles';

import iconSuccess from 'assets/images/icon-success.svg';
import iconError from 'assets/images/icon-error.svg';
import ModalAction, { ModalActionProps } from '../Action';

export type ModalStatusProps = {
  children?: string | ReactNode;
  title?: string;
  description?: string | ReactNode;
  onClose?: () => void;
  action?: ModalActionProps;
  variant: 'success' | 'error';
};

const ModalStatus = ({
  children,
  title = '',
  description,
  onClose,
  action,
  variant
}: ModalStatusProps) => (
  <TemplateModal title={title} closeModal={onClose}>
    <S.Wrapper description={description}>
      {children}

      {variant === 'success' ? (
        <img src={iconSuccess} alt="Sucesso" />
      ) : (
        <img src={iconError} alt="Erro" />
      )}

      {!!description && <S.Description>{description}</S.Description>}

      {!!action && <ModalAction {...action} />}
    </S.Wrapper>
  </TemplateModal>
);

export default ModalStatus;
