import { TutorialGenerateKeys } from '../components';
import Image1 from '../assets/conexus1.png';
import * as S from '../../styles';
const Conexus = () => {
  return (
    <div>
      <S.Title tag="h1" weight="bold" size="xlarge">
        Conexus
      </S.Title>

      <TutorialGenerateKeys name="Conexus" />

      <S.Paragraph tag="p" color="grayBlue">
        Na sua conta Conexus, entre na aba <strong>Configurações</strong>,
        preencha os campos
        <strong> “Application Key”</strong> e
        <strong> “Application Token”</strong>, referentes ao
        <strong> Token do Ame</strong> e <strong>Senha do AME </strong>
        respectivamente no site da Conexus, com as chaves geradas nas etapas
        acima. Agora é só salvar.
      </S.Paragraph>

      <S.ImgWrapper>
        <img
          src={Image1}
          alt="Aba configurações com campos Token do Ame e Senha do AME"
        />
      </S.ImgWrapper>
    </div>
  );
};

export default Conexus;
