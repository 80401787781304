import styled, { css, DefaultTheme } from 'styled-components';
import customMedia from 'styles/media-query';

import { ThemeColors } from 'types/global';
import { getSessionStorage } from 'utils/storage';
import { ReactComponent as IconRightNext } from 'assets/images/icon-right-next.svg';

const { theme: themeMode } = getSessionStorage();

const isDarken = themeMode !== 'light';

type WrapperProps = {
  color?: ThemeColors;
};

export const Wrapper = styled.button<WrapperProps>`
  ${({ theme, color }) => css`
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: ${theme.border.radius};
    border-bottom-right-radius: ${theme.border.radius};
    font-weight: ${theme.font.weights.medium};
    font-size: ${theme.font.sizes.large};
    padding: ${theme.spacings.large} ${theme.spacings.medium};
    background: ${theme.colors[color!]};
    color: ${theme.colors.white};
    transition: all ${theme.transition.default};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: ${theme.spacings.large};

    &:disabled {
      cursor: not-allowed;
      color: ${theme.colors.grayDark};
      background: ${theme.colors.grayMedium};
    }

    span {
      margin-right: 2rem;
    }

    ${customMedia.greaterThan('large')`
        width: auto;
        padding: ${theme.spacings.small} ${theme.spacings.large}
          ${theme.spacings.small} ${theme.spacings.medium};
        border-radius: ${theme.border.radius};
        margin-bottom: 0;
    `}
  `}
`;

export const IconBackArrow = styled(IconRightNext)`
  ${({ theme }) => css`
    position: absolute;
    left: ${theme.spacings.medium};
    transform: rotate(180deg);
    color: ${theme.colors.white};

    ${customMedia.greaterThan('medium')`
        position: relative;
        left: 0;
    `}
  `}
`;

const modifierWrapperIconStatement = {
  darken: (theme: DefaultTheme) => css`
    border: 3px solid ${theme.colors.grayBlue};
  `
};

export const WrapperIconStatement = styled.div<WrapperProps>`
  ${({ theme, color }) => css`
    background: ${theme.colors[color!]};
    border: 3px solid ${theme.colors.grayLight};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.5rem;
    height: 4.5rem;
    position: absolute;
    margin: 0;
    bottom: -${theme.spacings.medium};

    ${customMedia.greaterThan('large')`
        position: relative;
        margin: 0 -6rem 0 0;
        bottom: 0;
    `}

    ${isDarken && modifierWrapperIconStatement.darken(theme)};
  `}
`;
