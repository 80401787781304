import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    border: 1px solid ${theme.colors.grayMedium};
    border-radius: 1.2rem;
    padding: ${theme.spacings.medium};
  `}
`;
