import styled, { css } from 'styled-components';

import { ReactComponent as IconQrCodeBorder } from 'assets/images/icon-qr-code-border.svg';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    overflow: hidden;
    z-index: 2;
    border-radius: ${theme.profile.radius.medium};
    width: 230px;
    height: 230px;
  `}
`;

export const IconWrapper = styled(IconQrCodeBorder)`
  position: absolute;
  z-index: 4;
`;

export const QrCodeImage = styled.img`
  position: absolute;
  left: 0;
  width: 280px;
  margin-left: -25px;
  margin-top: -25px;
`;
