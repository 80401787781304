import { ReactNode, useRef, useState, useEffect } from 'react';

import Text from 'components/Text';

import * as S from './styles';

import { ReactComponent as IconArrowTop } from 'assets/images/icon-arrow-up.svg';
import { Flex } from 'components';

export type CardAccordionProps = {
  icon?: JSX.Element;
  text: string;
  expanded?: boolean;
  onClick?: () => void;
  children: ReactNode;
};

export const CardAccordion = ({
  icon,
  text,
  expanded = false,
  onClick,
  children
}: CardAccordionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(expanded);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (open && ref?.current) {
      setContentHeight(ref.current.scrollHeight);

      return;
    }

    setContentHeight(0);
  }, [open, ref]);

  return (
    <S.Wrapper onClick={onClick}>
      <S.CardWrapper>
        <S.Content>
          <Flex alignItems="center">
            {icon}
            <Text size="medium" weight="medium">
              {text}
            </Text>
          </Flex>

          <S.Arrow
            data-testid="icon-button"
            open={open}
            onClick={e => {
              e.stopPropagation();

              setOpen(!open);
            }}
          >
            <IconArrowTop />
          </S.Arrow>
        </S.Content>

        <S.Children open={open} height={contentHeight} ref={ref}>
          {children}
        </S.Children>
      </S.CardWrapper>
    </S.Wrapper>
  );
};

export default CardAccordion;
