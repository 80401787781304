import styled from 'styled-components';

export const Wrapper = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    margin-right: 1rem;
  }

  svg path {
    fill: currentColor;
  }
`;
