export const copyToClipBoard = (
  action: (copy: boolean) => void,
  value: string
) => {
  navigator.clipboard.writeText(value);
  action(true);

  setTimeout(() => {
    action(false);
  }, 2000);
};

export const copyFromListToClipBoard = (
  action: (copy: string) => void,
  value: string
) => {
  navigator.clipboard.writeText(value);
  action(value);

  setTimeout(() => {
    action('');
  }, 2000);
};
