import { ReactNode } from 'react';

import { Button, TemplateModal, WarningAlert, Loading } from 'components';

import { Row, Column } from 'styles/shared/Grid';
import Divider from 'styles/shared/Divider';

import * as S from './styles';

export type ModalChangeProps = {
  children: string | ReactNode;
  title: string;
  subtitle?: string;
  buttonAction: () => void;
  cancelAction?: () => void;
  buttonText?: string;
  cancelText?: string;
  warning?: string;
  onClose: () => void;
  loadingRequest?: boolean;
};

const ModalChange = ({
  children,
  title,
  subtitle,
  buttonAction,
  cancelAction,
  buttonText = 'Sim',
  cancelText = 'Cancelar',
  warning,
  onClose,
  loadingRequest
}: ModalChangeProps) => (
  <TemplateModal
    title={title}
    subtitle={subtitle}
    closeModal={loadingRequest ? undefined : onClose}
  >
    <S.Wrapper>
      {children}

      <Divider size="large" />

      {!!warning && (
        <>
          <WarningAlert description={warning} />

          <Divider size="large" />
        </>
      )}

      <Row>
        <Column xs={12} md={8} offsetMd={2}>
          <Button fullWidth filled onClick={buttonAction}>
            {loadingRequest ? <Loading color="white" /> : buttonText}
          </Button>

          {!!cancelAction && (
            <Button
              disabled={loadingRequest}
              fullWidth
              secondary
              secondaryColor="red"
              onClick={cancelAction}
            >
              {cancelText}
            </Button>
          )}
        </Column>
      </Row>
    </S.Wrapper>
  </TemplateModal>
);

export default ModalChange;
