import styled, { css, DefaultTheme } from 'styled-components';
import { ThemeColors } from 'types/global';

type WrapperProps = {
  withMargin?: boolean;
};

type InputWrapperProps = {
  hasAction?: boolean;
};

type LabelProps = {
  floating?: boolean;
  isDisabled?: boolean;
};

type InputProps = {
  disabled?: boolean;
  noBorder?: boolean;
  isPasswordType?: boolean;
  color?: ThemeColors;
};

const wrapperModifiers = {
  withMargin: (theme: DefaultTheme) => css`
    margin-bottom: ${theme.spacings.medium};
  `
};

export const Wrapper = styled.div<WrapperProps>`
  margin-bottom: 0;

  ${({ theme, withMargin }) => css`
    ${withMargin && wrapperModifiers.withMargin(theme)};
  `}
`;

const labelModifiers = {
  floating: (theme: DefaultTheme) => css`
    top: 0rem;
    color: ${theme.colors.grayBlue};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.weights.medium};
  `,

  disabled: () => css`
    cursor: not-allowed;
  `
};

export const Label = styled.label<LabelProps>`
  ${({ theme, floating, isDisabled }) => css`
    display: block;
    position: relative;
    top: 2.6rem;
    color: ${theme.colors.grayNeutralDark};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.weights.medium};
    line-height: ${theme.font.lineHeight('xsmall')};
    cursor: pointer;

    ${floating && labelModifiers.floating(theme)}
    ${isDisabled && labelModifiers.disabled()};
  `}
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  ${({ theme, hasAction }) => css`
    position: relative;
    display: flex;
    align-items: center;

    svg {
      &:hover {
        opacity: 0.8;
      }
    }

    ${hasAction &&
    css`
      button {
        position: absolute;
        right: ${theme.spacings.xsmall};
      }
    `}
  `}
`;

const inputModifiers = {
  disabled: () => css`
    cursor: not-allowed;
    border-bottom: 0;
    opacity: 0.4;
  `,

  noBorder: () => css`
    border-bottom: 0;
  `,

  isPasswordType: (theme: DefaultTheme) => css`
    padding-right: ${theme.spacings.large};
  `
};

export const Input = styled.input<InputProps>`
  ${({ theme, disabled, noBorder, isPasswordType, color }) => css`
    border: 0;
    border-bottom: 2px solid ${theme.colors.grayMedium};
    color: ${color ? theme.colors[color] : theme.colors.blackDark};
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xmedium};
    background: ${theme.colors.transparent};
    outline: none;
    width: 100%;
    height: 35px;

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }

    ${disabled && inputModifiers.disabled()};
    ${noBorder && inputModifiers.noBorder()};
    ${isPasswordType && inputModifiers.isPasswordType(theme)};
  `}
`;
