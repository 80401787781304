import { Location } from 'history';
import ReactGA from 'react-ga';

const environment = process.env.NODE_ENV || 'production';
const ANALYTICS_ID = 'UA-202981712-1';

export const initializeAnalytics = () => {
  if (environment === 'production') {
    ReactGA.initialize(ANALYTICS_ID);
  }
};

export const setAnalyticsPageView = (location: Location) => {
  if (environment === 'production') {
    const { pathname, search } = window.location;
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(pathname + search);
  }
};

export const setAnalyticsEvent = (
  category: string,
  label: string,
  action: string
) => {
  if (environment === 'production') {
    ReactGA.event({
      category,
      label,
      action
    });
  }
};
