import { useNavigate } from 'react-router-dom';

import iconSuccess from 'assets/images/icon-success-rounded.svg';

import { TemplateModal, Flex, Text, Button } from 'components';

import { useCredentials } from 'contexts/Credentials';

import PATHS_ROUTES from 'routes/paths';

function ModalSuccess() {
  const { modal } = useCredentials();
  const navigate = useNavigate();

  const handleClose = () => {
    modal.toggle();
    navigate(PATHS_ROUTES.APP.INTEGRATION.DEFAULT);
  };

  return (
    <TemplateModal title="" closeModal={handleClose} showCloseIcon={false}>
      <Flex alignItems="center" justifyContent="center" direction="column">
        <img height={66} src={iconSuccess} />

        <Text weight="medium" size="xxlarge" align="center" mt="medium">
          Credencial gerada com <br /> sucesso!
        </Text>

        <Text
          weight="regular"
          size="large"
          align="center"
          mt="medium"
          mb="xlarge"
        >
          Você pode verificar sua credencial gerada,
          <br /> na tela inicial de integração.
        </Text>

        <Button onClick={handleClose} filled>
          Entendi
        </Button>
      </Flex>
    </TemplateModal>
  );
}

export default ModalSuccess;
