import styled, { css } from 'styled-components';

import Card from 'styles/shared/Card';

export const Wrapper = styled.button`
  ${({ theme }) => css`
    padding: 0;
    text-align: center;

    ${Card} {
      padding: ${theme.spacings.small};
      margin-bottom: ${theme.spacings.xsmall};

      svg {
        color: ${theme.mode === 'light' ? '#313647' : theme.colors.white};
      }
    }

    span {
      font-size: ${theme.font.sizes.xsmall};
      line-height: ${theme.font.lineHeight('xsmall')};
    }
  `}
`;
