import styled, { css } from 'styled-components';
import customMedia from 'styles/media-query';

import Card from 'styles/shared/Card';
import { fadeIn } from 'styles/animates';

import { TemplateModalProps } from '.';

export const Overlay = styled.button`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(49, 54, 71, 0.7);
    z-index: ${theme.layers.modal};
    animation: ${fadeIn} ${theme.transition.fast};
    overflow-x: hidden;
    cursor: default;
  `}
`;

type WrapperProps = Pick<TemplateModalProps, 'layer'>;

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, layer = 'modal' }) => css`
    position: fixed;
    width: 100%;
    max-height: 90%;
    max-width: 624px;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${theme.layers[layer]};
    animation: ${fadeIn} ${props => props.theme.transition.default};
    bottom: 0;
    left: 0;

    ${customMedia.greaterThan('medium')`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: inherit;
    `}
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    max-height: 90vh;
    padding: ${theme.spacings.medium} ${theme.spacings.medium};
    background: ${theme.colors.white};
    overflow-y: auto;

    ::-webkit-scrollbar-button {
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar {
      width: 16px;
    }

    ::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      background-clip: padding-box;
      border-radius: 20px;
      background-color: ${theme.colors.grayMedium};
    }

    ${customMedia.greaterThan('medium')`
      margin-left: ${theme.spacings.medium};
      margin-right: ${theme.spacings.medium};
      border-radius: 20px;
    `}

    @media (max-height: 610px) {
      margin: 0;
    }
  `}
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;

  img {
    width: 33px;
    height: 33px;
  }
`;

export const Header = styled.div`
  margin-bottom: ${props => props.theme.spacings.medium};
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    color: ${theme.colors.grayBlue};

    button {
      margin-right: ${props => props.theme.spacings.small};
    }
  `}
`;

export const ArrowButton = styled.button`
  background: transparent;
  padding-top: 7px;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grayBlue};

    ${Card} {
      border: 1px solid ${theme.colors.grayMedium};
      background: ${theme.colors.white};
      color: ${theme.colors.grayBlue};
    }
  `}
`;
