import { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

import * as S from './styles';

export type PageItemProps = {
  children: string | ReactNode;
  active?: boolean;
  disabled?: boolean;
  filled?: boolean;
  pointer?: boolean;
  large?: boolean;
  action: () => void;
  customStyles?: CSSProperties;
};

const PageItem = ({
  children,
  active,
  disabled,
  action,
  pointer,
  filled,
  large,
  customStyles
}: PageItemProps) => (
  <S.Wrapper
    pointer={pointer}
    filled={filled}
    active={active}
    large={large}
    disabled={disabled}
    onClick={action}
    style={customStyles}
  >
    {children}
  </S.Wrapper>
);

export default PageItem;
