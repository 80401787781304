// import { useEffect } from 'react';
// import { useParams } from 'react-router-dom';

import { ScreenOwnerProvider } from 'contexts/ScreenOwner';
import ContainerPage from './ContainerPage';

// type RouteParams = {
//   id?: string;
// };

function AccountsGroup() {
  // const params = useParams<RouteParams>();

  return (
    <ScreenOwnerProvider>
      <ContainerPage />
    </ScreenOwnerProvider>
  );
}

export default AccountsGroup;
