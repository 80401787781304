import styled, { css } from 'styled-components';

import customMedia from 'styles/media-query';
import {
  Row as RowComponent,
  Column as ColumnComponent
} from 'styles/shared/Grid';

export const Row = styled(RowComponent)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};
  `}
`;

export const CompositionTitle = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacings.medium} ${theme.spacings.small};
  `}
`;

export const Column = styled(ColumnComponent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${customMedia.greaterThan('large')`
      align-items: baseline;
  `};
`;

export const ColumnAsRow = styled(ColumnComponent)`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: ${theme.spacings.medium} -${theme.grid.gutter} 0;
    padding: 0;
  `}
`;

export const ButtonWrapper = styled(ColumnComponent)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const BackColumn = styled(ColumnComponent)`
  display: block;

  ${customMedia.greaterThan('large')`
    display: flex;
  `}
`;

export const StatementInfo = styled.div`
  display: table;
  margin: 0 auto;
  text-align: center;

  ${customMedia.greaterThan('large')`
    margin-left: 6rem;
    display: block;
    text-align: left;
  `}
`;
