import { Loading, TemplateSearchContent, Link, ErrorMessage } from 'components';
import { Row } from 'styles/shared/Grid';
import PATHS from 'routes/paths';

import { useVideos } from './use-videos';
import * as S from './styles';
import { useState } from 'react';

const breadcrumbProps = {
  links: [
    {
      title: 'Treinamentos',
      link: PATHS.APP.TRAINING.HOME
    },
    {
      title: 'Ver todos',
      link: PATHS.APP.TRAINING.LIST
    }
  ]
};

function TrainingList() {
  const [search, setSearch] = useState('');
  const { videos, error, loading } = useVideos(search);

  if (error) {
    return (
      <ErrorMessage>
        Ocorreu um erro ao carregar os dados. <br />
        Atualize a página para tentar novamente.
      </ErrorMessage>
    );
  }

  if (loading) {
    return <Loading fullContent />;
  }

  return (
    <TemplateSearchContent
      breadcrumbProps={breadcrumbProps}
      inputSearchProps={{
        placeholder: 'Qual vídeo você quer assistir?',
        value: search,
        onChange: event => setSearch(event.target.value)
      }}
    >
      <Row>
        {videos.map(video => (
          <S.VideoColumn key={video.id} xs={6} sm={4} md={3}>
            <Link to={`${PATHS.APP.TRAINING.HOME}?video=${video.id}`}>
              <S.Card title={video.title} image={video.thumbUrl} />
            </Link>
          </S.VideoColumn>
        ))}
      </Row>
    </TemplateSearchContent>
  );
}

export default TrainingList;
