import { useCallback, useMemo, useState } from 'react';

import { ModalPix } from 'components';

import { formatButtonData, removeKeyItemsModal } from './constants';
import useMutate from 'hooks/useMutate';
import endpoints from 'services/endpoints';

export type ModalRemoveKeyProps = {
  handleClose: () => void;
  keyValue: string;
};

const ModalRemoveKey = ({ handleClose, keyValue }: ModalRemoveKeyProps) => {
  const [step, setStep] = useState(0);
  const {
    state: { error, loading },
    mutate
  } = useMutate();

  const handleRemoveKey = useCallback(() => {
    mutate({
      method: 'delete',
      endpoint: endpoints.deleteKey,
      config: {
        params: {
          key: keyValue
        }
      },
      onSuccess: () => setStep(1),
      onFailure: () => setStep(2)
    });
  }, [keyValue, mutate]);

  const buttonsData = useMemo(
    () => [
      {
        ...formatButtonData('primaryButton', 'Excluir chave', handleRemoveKey),
        ...formatButtonData('secondaryButton', 'Cancelar', handleClose)
      },
      {
        ...formatButtonData('secondaryButton', 'Entendi', handleClose)
      },
      {
        ...formatButtonData('secondaryButton', 'Entendi', handleClose),
        subtitle: error
      }
    ],
    [error, handleClose, handleRemoveKey]
  );

  return (
    <ModalPix
      modalAction={handleClose}
      modalTitle="Minhas chaves"
      {...removeKeyItemsModal()[step]}
      {...buttonsData[step]}
      loading={loading}
    />
  );
};

export default ModalRemoveKey;
