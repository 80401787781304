import InputField from 'components/InputField';
import { StepProps } from 'contexts/AccreditationSteps/types';
import { useFormikContext } from 'formik';
import { AccreditationValues } from 'pages/Accreditation/shared/form';

import * as S from './styles';

const Step3 = ({ names }: StepProps) => {
  const form = useFormikContext<AccreditationValues>();

  const getErrorMessage = () => {
    if (form.touched[names.ownerPhone]) return form.errors[names.ownerPhone];
  };

  return (
    <>
      <S.Title>
        Conta pra gente sobre você <S.HighlightText>:)</S.HighlightText>
      </S.Title>

      <S.Subtitle>
        Precisamos dos dados do responsável pela loja, sócio ou alguém com
        plenos poderes
      </S.Subtitle>

      <InputField name={names.name} label="Seu nome completo" />

      <InputField name={names.cpf} label="Seu CPF" maskType="CPF" />

      <InputField
        name={names.birthday}
        label="Sua data de nascimento"
        maskType="DATE"
      />

      <InputField
        name={names.ownerPhone}
        label="Seu número de celular"
        maskType="CELLPHONE"
        errorMessage={getErrorMessage()}
      />

      <InputField
        name={names.email}
        type="email"
        label="Seu e-mail de acesso à conta"
      />

      <InputField
        name={names.confirmEmail}
        type="email"
        label="Confirme o e-mail"
      />

      <S.Action>Ir para validação</S.Action>
    </>
  );
};

export default Step3;
