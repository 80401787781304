import styled, { css } from 'styled-components';

import { Card } from 'styles/shared/Card';

export const Wrapper = styled(Card)`
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
  width: 100%;

  img {
    height: 130px;
  }
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.small};

    span {
      display: block;
      padding-top: 0.5rem;
      padding-bottom: 2rem;
      line-height: 1.4;
    }
  `}
`;
