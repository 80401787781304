import styled from 'styled-components';

export const Wrapper = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    width: 3.3rem;
    height: 3.3rem;

    &:first-child {
      margin-right: 2rem;
    }

    &:first-child {
      svg {
        margin-left: 2px;
      }
    }
  }

  svg path {
    fill: currentColor;
  }
`;
