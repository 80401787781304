import { TutorialRequestKey } from '../components';
import * as S from '../../styles';
function DigitalSeller() {
  return (
    <div>
      <S.Title>Digital Seller</S.Title>

      <TutorialRequestKey name="Digital Seller" />

      <S.Paragraph tag="p" color="grayBlue">
        Você deverá acessar o Painel Administrativo da sua loja, em seguida
        <strong> Pagamentos {'->'} Selecionar Ame</strong>. Aparecerão campos
        que precisa preencher (atenção ao preencher os campos, pois se houver
        alguma informação incorreta, ocorrerá erro na integração):
      </S.Paragraph>

      <S.List>
        <li>Situação: Habilitado.</li>
        <li>Access Token: Campo de preenchimento pela Digital Seller.</li>
        <li>
          Cliente ID: é a chave de integração recebida nas etapas anteriores.
        </li>
        <li>Título da forma de pagamento: Ame</li>
        <li>Ambiente de aplicação: Produção</li>
        <li>Campo para nº de endereço: N</li>
        <li>Campo para Complemento de endereço: Complemento</li>
        <li>Região geográfica: Todas as regiões</li>
        <li>Ordenação: 99</li>
        <li>Habilitar log: Desativado</li>
      </S.List>

      <S.Paragraph tag="p" color="grayBlue">
        Após o preenchimento correto das informações clique para Salvar e
        informe a Digital Seller pelo suporte pelo whatsapp disponibilizado para
        você, cliente Digital Seller.
      </S.Paragraph>
    </div>
  );
}

export default DigitalSeller;
